import React, {ReactElement} from "react";
import {useTranslation} from "react-i18next";
import cx from "classnames";
import styles from "./Box.module.scss";
import Edit from "../Edit";

type Props = {
    title?: string;
    edit?: boolean;
    canEdit?: boolean;
    onEdit?: (...args: any[]) => any;
    children: ReactElement;
    contentClassName?: string;
};

function Box({
    title = "",
    canEdit = false,
    edit = false,
    children,
    onEdit = () => {},
    contentClassName
}: Props): ReactElement {
    const {t} = useTranslation();

    return (
        <div className={styles.Root}>
            <div className={styles.Heading}>
                <h4 className={styles.Title}>{title}</h4>

                {canEdit && (
                    <div>
                        {!edit && (
                            <Edit
                                onClick={onEdit}
                                what={t("amend_b_edit")}
                            />
                        )}
                    </div>
                )}
            </div>

            <div className={cx(styles.Content, contentClassName)}>
                {children}
            </div>
        </div>
    );
}

export default Box;
