import React, {ReactElement, useCallback} from "react";
import baseURL from "api/constants/baseURL";
import {useTranslation} from "react-i18next";
import styles from "./PaymentInfo.module.scss";
import useLocaleDateTimeFormat, {
    LocaleDateFormatTypeEnum
} from "../../../utils/Internationalization/useLocaleDateTimeFormat";
import {
    PaymentTransferTypePropTypes, WebDiscountUsage,
    WebInvoicePropTypes
} from "proptypes/PropTypeObjects";
import getPriceWithCurrency, {getPriceWithCurrencySymbol} from "@hotelston_web_frontend_utils/currency/getPriceWithCurrency";
import useDefaultFeatureEnabled from "../../../../utils/hooks/useDefaultFeatureEnabled";
import SkeletonLoader from "../../../utils/SkeletonLoader/SkeletonLoader";
import ResponsiveTable from "../../../base/ResponsiveTable";
import {TableRow} from "../../../base/ResponsiveTable/ResponsiveTable.base";
import getTranslationKeyByPaymentTransferTypeEnum from "../../../../utils/getTranslationKeyByPaymentTransferTypeEnum";
import {convertToServerTime} from "utils/dateUtils";
import EditableRetailPrice from "./EditableRetailPrice";
import {useAppSelector} from "redux/hooks";
import createLuxonDate from "../../../../utils/date/createLuxonDate"
import Currency from "@hotelston_web_frontend_parent/constants/currency";

export type PaymentInfoProps = {
    paymentDeadline: number,
    creditCardFee?: number,
    paymentTransferType: PaymentTransferTypePropTypes,
    paymentPending?: number,
    retailPrice?: number,
    autoCancel: boolean,
    paidAmount: number,
    price: number,
    currency: Currency,
    id: number,
    invoices?: WebInvoicePropTypes[],
    proformaInvoices?: WebInvoicePropTypes[],
    creditNotes?: WebInvoicePropTypes[],
    penaltyNotes?: WebInvoicePropTypes[],
    compensationNotes?: WebInvoicePropTypes[],
    discountUsageHistory?: WebDiscountUsage
};

function PaymentInfo({
    paymentDeadline,
    paymentTransferType,
    paymentPending,
    retailPrice,
    autoCancel,
    paidAmount,
    price,
    creditCardFee,
    currency,
    id,
    invoices = [],
    proformaInvoices = [],
    creditNotes = [],
    penaltyNotes = [],
    compensationNotes = [],
    discountUsageHistory
}: PaymentInfoProps): ReactElement {
    const {t} = useTranslation();
    const dateFormat = useLocaleDateTimeFormat(LocaleDateFormatTypeEnum.DATE);

    const {
        featureEnabled,
        featuresLoaded
    } = useDefaultFeatureEnabled("advanceInvoiceView");

    const superAgent = useAppSelector((state) => state.auth.userData?.superAgent);

    const getTableData = useCallback((data: WebInvoicePropTypes[], name: string) => data.map(({
        date,
        amount,
        number
    }, i: number) => (
        <tr key={`${name}-${i}`}>
            <td>{createLuxonDate(convertToServerTime(date)).toFormat(dateFormat)}
            </td>
            <td>{name}: {number}</td>
            <td>{getPriceWithCurrencySymbol(amount, currency)}</td>
            {/*<td className={styles.ActionCell}>*/}
            {/*    <a rel="noopener noreferrer" target="_blank" className={styles.Action} href={`${baseURL}/booking/${id}/invoice/${number}`}>*/}
            {/*        {t("bs_pi_view")}*/}
            {/*    </a>*/}
            {/*</td>*/}
            <td className={styles.ActionCell}>
                <a
                    className={styles.Action}
                    href={`${baseURL}/booking/${id}/invoice/${number}?download=true`}
                    download={name}
                >
                    {t("bs_pi_download")}
                </a>
            </td>
        </tr>
    )), [currency, dateFormat, id, t]);

    const getTypeTranslated = useCallback((type?: string) => {
        switch (type) {
        case "PROFORMA_INVOICE":
            return t("bs_pi_proforma");
        case "PENALTY_NOTE":
            return t("bs_pi_penalty_note");
        case "CREDIT_NOTE":
            return t("bs_pi_credit_note");
        case "COMPENSATION_NOTE":
            return t("bs_pi_compensantion_note");
        case "OTHER":
        case "INVOICE":
        default:
            return t("bs_pi_invoice");
        }
    }, [t]);

    return (
        <div className={styles.Root}>
            <div className={styles.Heading}>{t("bs_pi_invoices")}</div>

            {!featuresLoaded && (
                <SkeletonLoader>
                    <div className={styles.SkeletonLoaderContainer}>
                        <div className={styles.SkeletonLoaderRow} data-skeleton-animated="true"/>
                        <div className={styles.SkeletonLoaderRow} data-skeleton-animated="true"/>
                        <div className={styles.SkeletonLoaderRow} data-skeleton-animated="true"/>
                    </div>
                </SkeletonLoader>
            )}

            {featuresLoaded && featureEnabled && (
                <ResponsiveTable
                    firstColumnSticky
                    className={styles.InvoiceTableAdvanced}
                    tHeadings={[
                        {
                            label: t("bs_pi_advanced_issue_date"),
                            element: <>{t("bs_pi_advanced_issue_date")}</>
                        },
                        {
                            label: t("bs_pi_advanced_type"),
                            element: <>{t("bs_pi_advanced_type")}</>
                        },
                        {
                            label: t("bs_pi_advanced_invoice_number"),
                            element: <>{t("bs_pi_advanced_invoice_number")}</>
                        },
                        {
                            label: t("bs_pi_advanced_sum"),
                            element: <>{t("bs_pi_advanced_sum")}</>
                        }
                    ]}
                    tRows={invoices?.map(({
                        date,
                        amount,
                        number,
                        type
                    }) => ({
                        actionable: false,
                        variant: "Normal",
                        tDatas: [
                            {
                                element: <>{createLuxonDate(convertToServerTime(date)).toFormat(dateFormat)}
                                </>
                            },
                            {element: <>{getTypeTranslated(type)}</>},
                            {element: <>{number}</>},
                            {element: <>{getPriceWithCurrencySymbol(amount, currency)}</>}
                        ],
                        actionTd: {
                            element: <a
                                className={styles.Action}
                                href={`${baseURL}/booking/${id}/invoice/${number}?download=true`}
                                download={type}
                            >
                                {t("bs_pi_download")}
                            </a>,
                            actionable: true
                        }
                    } as TableRow))}
                    withoutBorder
                />
            )}

            {featuresLoaded && !featureEnabled && (
                <table className={styles.InvoiceTable}>
                    <tbody>
                        {getTableData(proformaInvoices, t("bs_pi_proforma"))}
                        {getTableData(invoices, t("bs_pi_invoice"))}
                        {getTableData(creditNotes, t("bs_pi_credit_note"))}
                        {getTableData(penaltyNotes, t("bs_pi_penalty_note"))}
                        {getTableData(compensationNotes, t("bs_pi_compensantion_note"))}
                    </tbody>
                </table>
            )}

            {!proformaInvoices.length && !invoices.length && !creditNotes.length && !penaltyNotes.length && !compensationNotes.length && (
                <span>{t("bs_pi_no_invoices")}</span>
            )}

            <div
                style={{
                    marginTop: 20,
                    marginBottom: 20
                }}
                className="h-divider"
            />

            {/*{discountUsageHistory && (*/}
            {/*    <>*/}
            {/*        <div className={styles.Heading}>{bs_pi_discount_title}</div>*/}

            {/*        <table className={styles.InvoiceTable}>*/}
            {/*            <tbody>*/}
            {/*                <tr>*/}
            {/*                    <td>{createLuxonDate(discountUsageHistory.usageDate).toFormat(dateFormat)}</td>*/}
            {/*                    <td>{discountUsageHistory.code}</td>*/}
            {/*                    <td>{getPriceWithCurrencySymbol(discountUsageHistory.usedAmount || 0, currency)}</td>*/}
            {/*                </tr>*/}
            {/*            </tbody>*/}
            {/*        </table>*/}
            {/*    </>*/}
            {/*)}*/}

            {/*<div*/}
            {/*    style={{*/}
            {/*        marginTop: 20,*/}
            {/*        marginBottom: 20*/}
            {/*    }}*/}
            {/*    className="h-divider"*/}
            {/*/>*/}

            <div className={styles.Heading}>{t("bs_pi_payment")}</div>

            <div className={styles.Content}>
                <div className={styles.ContentLeft}>
                    <div className={styles.ContentItem}>
                        {t("bs_pi_payment_deadline")}:
                        <strong>{createLuxonDate(convertToServerTime(paymentDeadline)).toFormat(dateFormat)}
                        </strong>
                    </div>

                    <div className={styles.ContentItem}>
                        {t("bs_pi_auto_cancel")}:
                        <strong>{autoCancel ? t("bs_pi_yes") : t("bs_pi_no")}</strong>
                    </div>

                    <div className={styles.ContentItem}>
                        {t("bs_pi_payment_method")}:
                        <strong>{t(getTranslationKeyByPaymentTransferTypeEnum(paymentTransferType))}</strong>
                        {/*<strong>{getLabelFromEnum(paymentTransferType)}</strong>*/}
                    </div>
                </div>

                <div className={styles.ContentRight}>
                    <div className={styles.ContentItem}>
                        {t("bs_pi_price")}:
                        <strong>{getPriceWithCurrencySymbol(price, currency)}</strong>
                    </div>

                    {superAgent && (
                        <div className={styles.ContentItem}>
                            {t("bs_pi_paid")}:
                            <strong>{getPriceWithCurrencySymbol(paidAmount, currency)}</strong>
                        </div>
                    )}

                    {paymentTransferType === PaymentTransferTypePropTypes.CREDIT_CARD && creditCardFee !== undefined && superAgent ? (
                        <div className={styles.ContentItem}>
                            {t("bs_pi_credit_card_fee")}:
                            <strong>{getPriceWithCurrencySymbol(creditCardFee, currency)}</strong>
                        </div>
                    ) : undefined}

                    {paymentPending ? (
                        <div className={styles.ContentItem}>
                            {t("bs_pi_payment_pending")}:
                            <strong
                                className={(paymentPending > 0 ? styles.Red : styles.Green)}
                            >{getPriceWithCurrencySymbol(paymentPending, currency)}
                            </strong>
                        </div>
                    ) : undefined}

                    {retailPrice ? (
                        <EditableRetailPrice
                            bookingId={id}
                            className={styles.ContentItem}
                            retailPrice={retailPrice}
                            currency={currency}
                        />
                    ) : undefined}
                </div>
            </div>
        </div>
    );
}

export default PaymentInfo;
