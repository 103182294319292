export function getPayDue(salePrice: number, companyMarkup: number, discount: number | undefined, voucher: number, transactionFee: number | undefined, smsFee: number | undefined): number {
    return salePrice * companyMarkup + (smsFee || 0) - (discount || 0) - (voucher || 0) <= 0 ? 0 : salePrice * companyMarkup + (transactionFee || 0) + (smsFee || 0) - (discount || 0) - (voucher || 0);
}

export function getTotalWithTransactionFee(salePrice: number, companyMarkup: number, fixedTransactionFee: number, smsFee: number | undefined): number {
    return salePrice * companyMarkup + fixedTransactionFee + (smsFee || 0);
}

export function getDisplayTotal(displaySalePrice: number, companyMarkup: number, displayTransactionFee: number | undefined, displaySmsFee: number | undefined): number {
    return displaySalePrice * companyMarkup + (displayTransactionFee || 0) + (displaySmsFee || 0);
}

export function getTotal(salePrice: number, companyMarkup: number, smsFee: number | undefined): number {
    return salePrice * companyMarkup + (smsFee || 0);
}

export function isLabelBookingPrice(companyMarkup: number, transactionFee: number | undefined, smsFee: number | undefined, currencyEU: boolean): boolean {
    return !(companyMarkup !== 1 && !transactionFee && !smsFee && currencyEU);
}

export function isLabelRetailPrice(companyMarkup: number, transactionFee: number | undefined, smsFee: number | undefined, currencyEU: boolean): boolean {
    return companyMarkup === 1 && !transactionFee && !smsFee && currencyEU;
}