import {PaymentVoucherListResponse, PaymentVoucherValidateResponse} from "../proptypes/PropTypeObjects";
import {ConstrainedAxiosRequestConfig} from "./axios/constrainedAxiosRequestConfig";

export function getVouchersList(location = "en"): ConstrainedAxiosRequestConfig<PaymentVoucherListResponse> {
    return {
        method: "GET",
        url: `/app/company/vouchers?locale=${location}`,
        maxNumRequests: 1
    };
}

export function getPaymentVoucherByReference(reference: string, location = "en"): ConstrainedAxiosRequestConfig<PaymentVoucherValidateResponse> {
    return {
        method: "GET",
        url: `/app/company/vouchers/${reference}?locale=${location}`,
        maxNumRequests: 1
    };
}

export function getVoucherPDF(reference: string): ConstrainedAxiosRequestConfig<Blob> {
    return {
        method: "get",
        url: `/app/company/vouchers/download/${reference}`,
        timeout: 240000,
        responseType: "blob",
        headers: {
            "Content-Type": "application/pdf"
            //Accept: "application/json"
        },
        maxNumRequests: 1
    };
}

export function getVouchersXLS(location = "en"): ConstrainedAxiosRequestConfig<Blob> {
    return {
        method: "get",
        url: `/app/company/vouchers/xls?locale=${location}`,
        timeout: 240000,
        responseType: "blob",
        headers: {
            "Content-Type": "text/html;charset=utf-8"
            //Accept: "application/json"
        },
        maxNumRequests: 1
    };
}