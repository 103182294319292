import React, {ReactElement} from "react";
import {useTranslation} from "react-i18next";
import styles from "./PickupTimeSMSBox.module.scss";
import OrderSMSForm from "./OrderSMSForm";
import createLuxonDate from "../../../utils/date/createLuxonDate"
import {DateTime} from "luxon"

type Props = {
    smsOrdered?: boolean;
    smsReminderNumber?: string;
    smsLocale?: string;
    bookingCancelled: boolean;
    departureTransfer: boolean;
    departureDate?: DateTime;
};

function PickupTimeSMSBox({
    smsOrdered,
    smsReminderNumber,
    smsLocale,
    bookingCancelled,
    departureTransfer,
    departureDate
}: Props): ReactElement {
    const {t} = useTranslation();

    return (
        <>
            {!!smsOrdered && (
                <div className={styles.Root}>
                    <label className={styles.Heading}>
                        {t("ptsmsb_ptsmsb_sms_title")}
                        <span>&nbsp;<span className={styles.StatusFlag}>&nbsp;{t("ptsmsb_ptsmsb_sms_status")}</span></span>
                    </label>

                    <dl className={styles.Text}>
                        <dt>{t("ptsmsb_ptsmsb_phone")}:</dt>
                        <dd>{smsReminderNumber}</dd>

                        <dt>{t("ptsmsb_ptsmsb_language")}:</dt>
                        <dd>{smsLocale}</dd>
                    </dl>

                </div>
            )}

            {!smsOrdered && !bookingCancelled && departureTransfer && (!!departureDate && Math.floor(createLuxonDate(departureDate).diff(createLuxonDate(), "days").days) >= 2) && (
                <OrderSMSForm />
            )}
        </>
    );
}
export default PickupTimeSMSBox;
