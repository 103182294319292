import {
    CriteriaRoomPropTypes,
    HotelCoordinatesPropTypes,
    HotelSearchResultsFiltersPropTypes
} from "proptypes/PropTypeObjects";

export type QueryParamSerializer = (query: QueryParamsObject, param: string, object: any) => void;
export type QueryParamResolver = (paramName: string) => boolean;
export type QueryParamDeserializer = (query: QueryParamsObject, param: string) => unknown;
export type QueryParamSerializerDeserializerResolver = {
    serializer?: QueryParamSerializer,

    resolver?: QueryParamResolver,
    deserializer?: QueryParamDeserializer,

    array: boolean
};

export class QueryParamsObject {
    [key: string]: string;
}

// Possible query params object
export type UrlQueryParams = {
    // Airport id
    a?: number,
    // Hotel id
    h?: number,
    // City id
    c?: number,
    // Destination id
    di?: number,
    // Transfer Destination ID
    tdi?: string;
    // Origin id
    oi?: number,
    // Transfer Origin ID
    toi?: string;

    // Check in date
    in?: string | number,
    // Check out date
    out?: string | number,
    // Nationality input
    n?: string,
    // Destination input
    dest?: string,

    // Airport input
    ai?: string,
    // Venue input
    vi?: string,
    // Related booking
    rb?: string,

    // Arrival transfer
    at?: boolean,
    // Departure transfer
    dt?: boolean,
    oco?: HotelCoordinatesPropTypes,
    dco?: HotelCoordinatesPropTypes,

    // Hotel name filter
    hnf?: string,
    // Room type filter
    rtf?: string,
    // Price filter min
    p?: { min: number, max: number },
    // Board type filter
    btf?: string[] | number[],
    // Hotel category filter
    hcf?: string[] | number[],
    // Features filter
    ff?: string[],

    // Special offers checkbox
    sof?: boolean,
    // Member only checkbox
    mof?: boolean,
    // Only good cxl checkbox
    ogcf?: boolean,
    // Only refundable checkbox
    orf?: boolean,
    // Only non refundable checkbox
    onrf?: boolean,

    // List view
    v?: string,
    // Round trip
    rt?: boolean

    // Filters
    f?: HotelSearchResultsFiltersPropTypes,

    // Rooms
    r?: CriteriaRoomPropTypes[],
    // Property type
    pt?: string[],
    // Review rating
    rr?: number[],

    // Adults
    ad?: number,
    // Children array
    ch?: number[],

    // Sort by
    sb?: { label?: string | number, value?: string | number },
    // Page number
    pn?: number,
    // Size
    s?: number,
    // Locale
    l?: string,
    // Currency
    cr?: string,

    // Token
    token?: string,

    b?: string[],

    // Ignore Select best offers
    ign?: boolean,
    // Multiprovider multiroom search
    m?: boolean,
    // Log requests
    log?: boolean

    // My company flag when coming from the "Create billing details" notification
    createBillingDetails?: boolean;

    // My bookings payment direct link booking ref
    ref?: number;
};

export type BaseQueryParamsObject = {
    checkIn?: string | number;
    checkOut?: string | number;
    sortBy?: { label?: string | number; value?: string | number };
    pageNumber?: number;
    size?: number;
    locale?: string;
    currency?: string;
};

export interface MyBookingsQueryParams extends BaseQueryParamsObject {
    bookingReferences?: string[];
}

export interface DefaultStateQueryParams extends BaseQueryParamsObject {
    formData?: { clientNationality: string };
    nationalityInput?: string;
}