import React, {ReactElement, useCallback, useEffect} from "react"
import MainContainer from "components/base/MainContainer";
import isEqual from "lodash/isEqual";
import Spinner from "components/base/Loaders/Spinner";
import PaymentSummary from "./PaymentSummary";
import PaymentProviderRedirect from "../../../components/common/PaymentProviderRedirect/PaymentProviderRedirect";
import RedirectingModal from "../../../components/common/Modal/RedirectingModal";
import RequestingBookingWrapper from "../../../components/common/RequestingBookingWrapper/RequestingBookingWrapper";
import {Navigate} from "react-router-dom"
import {useCurrency, useLocale} from "components/utils/withLocalesAndCurrencies"
import usePaymentOptions from "components/base/Payment/PaymentType/usePaymentOptions"
import {makeTransferBookingRequest, updateBookingOptions} from "redux/actions/transferBooking.actions";
import {useAppDispatch, useAppSelector} from "redux/hooks";
import {ReduxPaymentTypePropTypes} from "proptypes/PropTypeRedux";
import PaymentContent from "components/common/Payment/PaymentContent/PaymentContent";
import usePaymentType from "components/utils/usePaymentType";

const PaymentContainer = (): ReactElement => {
    const currency = useCurrency();
    const locale = useLocale();

    const dispatch = useAppDispatch();

    const {
        paymentOptions
    } = usePaymentOptions();
    const loading = useAppSelector((state) => state.payment.loading);
    const requestingBooking = useAppSelector((state) => state.transferBooking.requestingBooking);
    const requestingValidation = useAppSelector((state) => state.transferBooking.requestingValidation);
    const route = useAppSelector((state) => state.transferBooking.data?.route);
    const providerPayment = useAppSelector((state) => state.payment.providerPayment);
    const bookingOptionsParams = useAppSelector((state) => state.transferBooking.bookingOptionsParams);

    useEffect(() => {
        if (!loading &&
            !requestingValidation &&
            !requestingBooking &&
            bookingOptionsParams &&
            !isEqual({
                locale,
                currency
            }, bookingOptionsParams)) {
            dispatch(updateBookingOptions());
        }
    }, [bookingOptionsParams, currency, dispatch, loading, locale, requestingBooking, requestingValidation]);

    const handleValidationAndMakeBooking = useCallback(() => {
        dispatch(makeTransferBookingRequest());
    }, [dispatch]);

    const handleSubmit = useCallback(() => {
        handleValidationAndMakeBooking();
    }, [handleValidationAndMakeBooking]);

    usePaymentType(ReduxPaymentTypePropTypes.TRANSFER);

    return (
        <MainContainer
            sidebar={
                !requestingValidation && !requestingBooking && paymentOptions && (
                    <PaymentSummary/>
                )
            }
            variants={{
                center: requestingValidation || requestingBooking
            }}
        >
            {requestingValidation && (
                <Spinner
                    size="50px"
                    style={{
                        width: "100%",
                        height: "calc(100vh - 60px)"
                    }}
                />
            )}

            {requestingBooking && (
                <RequestingBookingWrapper/>
            )}

            {(!requestingValidation && !requestingBooking) && (route === "CHANGES" || route === "NO_AVAILABILITY" || route === "DUPLICATE_BOOKINGS") ? (
                <Navigate
                    to={{
                        pathname: "/transfers/booking/changed"
                    }}
                />
            ) : (
                <>
                    {!!providerPayment && (
                        <PaymentProviderRedirect {...providerPayment}>
                            <RedirectingModal/>
                        </PaymentProviderRedirect>
                    )}

                </>
            )}

            {(!requestingValidation && !requestingBooking) && paymentOptions && (
                <PaymentContent
                    handleSubmit={handleSubmit}
                />
            )}
        </MainContainer>
    );
}

export default PaymentContainer;