import React, {ReactElement} from "react";
import styles from "./AppUnavailable.module.scss";
import UserButton from "../components/base/UserButton";
import {UserButtonVariant} from "../components/base/UserButton/UserButton";

const AppUnavailable = (): ReactElement => (
    <div className={styles.Root}>
        <div className={styles.LogoDescriptionContainer}>
            <div className={styles.Logo}>
                {/*<ActionIcon type={ActionIconType.ERROR} />*/}
                {/*<TimerIcon />*/}
                !
            </div>

            <div className={styles.Description}>
                We are very sorry, Hotelston.com is currently down for maintenance. Please give us a few minutes and Hotelston.com will be back online.
            </div>
        </div>

        <UserButton
            className={styles.Action}
            text="Try again"
            variant={UserButtonVariant.PRIMARY}
            buttonProps={{
                onClick: () => {
                    window.location.reload();
                }
            }}
        />
    </div>
);

export default AppUnavailable;