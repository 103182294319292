import React, {ReactElement, useCallback, useState} from "react";
import {useTranslation} from "react-i18next";
import cx from "classnames";
import SearchedItem from "./SearchedItem/SearchedItem";
import SearchedItemEmpty from "./SearchedItem/SearchedItemEmpty";
import {
    HotelSearchCriteriaPropTypes,
    RecentDestinationPropTypes,
    RecentSearchPropTypes
} from "../../../../../proptypes/PropTypeObjects";
import styles from "./SearchedItemsContainer.module.scss";
import useMobileSwitch from "../../../../../utils/hooks/useMobileSwitch";

export type SearchedItemsContainerProps = {
    recentSearches?: RecentSearchPropTypes[];
    startSearchFromData: (criteria: HotelSearchCriteriaPropTypes, destination: RecentDestinationPropTypes) => void;
};

const SearchedItemsContainer = ({
    recentSearches,
    startSearchFromData
}: SearchedItemsContainerProps): ReactElement => {
    const {t} = useTranslation();
    const {isMobile} = useMobileSwitch();
    const [mobileListVisible, setMobileListVisible] = useState<boolean>();

    const listRecents = recentSearches && recentSearches.map(({criteria, destination, id}, i) => (criteria && (
        <SearchedItem
            checkIn={criteria.checkIn}
            checkOut={criteria.checkOut}
            onClick={() => startSearchFromData(criteria, destination)}
            key={i}
            //id={i}
            destinationName={(destination.hotelName || destination.airportName || destination.radiusName
                ? `${destination.hotelName ? destination.hotelName : ""}${destination.airportName ? destination.airportName : ""}${destination.radiusName ? destination.radiusName : ""}`
                : "")}
            location={(destination.cityName || destination.countryName
                ? `${destination.cityName ? destination.cityName : ""}${destination.cityName && destination.countryName ? ", " : ""}${destination.countryName ? destination.countryName : ""}`
                : "")}
            rooms={criteria.rooms ? criteria.rooms.length : 0}
            paxs={criteria.rooms
                ? criteria.rooms.reduce((prev, curr) => curr.adults + curr.children.length + prev, 0)
                : 0}
            id={id}
        />
    ))).slice(0, 4);

    if (listRecents && listRecents.length !== 0 && listRecents.length < 4) {
        for (let i = listRecents?.length; i < 4; i++) {
            listRecents?.push(
                <SearchedItemEmpty key={`SearchedItemEmpty_${i}`} />
            );
        }
    }

    const onClickCallback = useCallback(() => {
        if (!isMobile) {
            return;
        }

        setMobileListVisible(!mobileListVisible);
    }, [isMobile, mobileListVisible]);

    return (
        <div className={cx(styles.Root, mobileListVisible && styles.RecentSearchContentVisible)}>
            <div
                className={styles.SearchRecentTop}
                onClick={onClickCallback}
            >
                <div className={styles.RecentSearchedTitle}>{t("h_s_sb_recent_searches_title")}</div>
            </div>

            <div className={styles.RecentSearchContent}>
                {listRecents}
            </div>
        </div>
    );
};

export default SearchedItemsContainer;