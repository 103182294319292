import React, {ReactElement, useCallback, useMemo} from "react";
import {useTranslation} from "react-i18next";
import styles from "components/common/Payment/PaymentContent/PaymentContent.module.scss";
import UserButton from "components/base/UserButton";
import {UserButtonVariant} from "components/base/UserButton/UserButton";
import {redirectPayInParts} from "redux/actions/payment.actions";
import {useAppDispatch, useAppSelector} from "redux/hooks";
import {ReduxPaymentTypePropTypes} from "proptypes/PropTypeRedux";
import {MultiPaymentBookingOptionsPropTypes, PaymentTransferTypePropTypes} from "proptypes/PropTypeObjects";

export type PayInPartsProps = {
    disabled?: boolean;
    paymentTransferType: PaymentTransferTypePropTypes;
}

const PayInParts = (props: PayInPartsProps): ReactElement | null => {
    const {
        disabled,
        paymentTransferType
    } = props;

    const {t} = useTranslation();
    const dispatch = useAppDispatch();

    const paymentType = useAppSelector((state) => state.payment.type);
    const bookingOptions = useAppSelector((state) => state.payment.bookingOptions);
    const activeOrders = useAppSelector((state) => state.multiPayment.activeOrders);
    const paymentOptions = useAppSelector((state) => state.payment.bookingOptions);

    const existingPayment = useMemo(() => {
        if (paymentType !== ReduxPaymentTypePropTypes.MULTIBOOKING) {
            return;
        }

        return (paymentOptions as MultiPaymentBookingOptionsPropTypes).existingSplitPricePayment;
    }, [paymentOptions, paymentType]);

    const currentPaymentPending = useMemo(() => {
        if (paymentType === ReduxPaymentTypePropTypes.MULTIBOOKING && activeOrders && activeOrders.length != 0) {
            return activeOrders.map((order) => order.paymentPending)
                .reduce((prev, curr) => prev + curr, 0);
        }

        if (bookingOptions) {
            return bookingOptions.paymentPending;
        }

        return 0;
    }, [activeOrders, bookingOptions, paymentType]);

    const payInPartsCallback = useCallback(() => {
        dispatch(redirectPayInParts(paymentTransferType));
    }, [dispatch, paymentTransferType]);

    if (!bookingOptions.allowSplitPayment) {
        return null;
    }

    if (!paymentType) {
        return null;
    }

    if ([ReduxPaymentTypePropTypes.SPLIT_PRICE_PART_PAYMENT, ReduxPaymentTypePropTypes.ANONYMOUS_MULTIBOOKING].includes(paymentType)) {
        return null;
    }

    if (currentPaymentPending <= paymentOptions.splitPriceMinAmount) {
        return null;
    }

    return (
        <div className={styles.PayInPartsContainer}>
            <UserButton
                text={existingPayment ? t("pc_pc_split_payment_resume") : t("pc_pc_split_payment_pay_in_parts")}
                variant={UserButtonVariant.SECONDARY}
                disabledButton={disabled}
                buttonProps={{
                    onClick: payInPartsCallback
                }}
            />
        </div>
    );
}

export default PayInParts;