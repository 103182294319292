import React, {ReactElement} from "react";
import {useTranslation} from "react-i18next";
import {connect, useSelector} from "react-redux";
import {matchPath} from "react-router-dom";
import {BreadcrumbSharedProps} from "../Breadcrumb/Breadcrumb.base";
import {RootState} from "../../../../../redux/store/store.init";
import CartProgress from "../CartProgress/CartProgress";

export type HotelBreadcrumbsProps = {
    destinationInput?: string;
} & BreadcrumbSharedProps;

const HotelBreadcrumbs = ({
    location,
    lastLocation,

    destinationInput
}: HotelBreadcrumbsProps): ReactElement => {
    const {t} = useTranslation();

    const isHotel = useSelector((state: RootState) => !!state.hotelSearch.formData.hotelId);
    const hotelName = useSelector((state: RootState) => isHotel && state.hotelSearchResults.hotelOffers[0]?.hotel?.name);

    let step: 1 | 2 | 3 | 4;
    if (matchPath("/hotels/booking", location.pathname)) {
        step = 2;
    } else if (
        matchPath("/hotels/booking/payment", location.pathname) ||
        matchPath("/hotels/booking/changed", location.pathname)
    ) {
        step = 3;
    } else {
        step = 4;
    }

    if (!matchPath("/hotels/*", location.pathname)) {
        return (
            <></>
        );
    }

    return (
        <CartProgress
            currentStep={step}
            stepOneLabel={t(isHotel ? "b_c_hotels_hotel_step_one" : "b_c_hotels_step_one", {destination: isHotel ? hotelName : destinationInput})}
            stepOneLink={lastLocation.pathname + lastLocation.search}
            stepTwoLabel={t("b_c_hotels_step_two")}
            stepTwoLink="/hotels/booking"
            stepThreeLabel={t("b_c_hotels_step_three")}
            stepThreeLink="/hotels/payment"
            stepFourLabel={t("b_c_hotels_step_four")}
        />
    );
};

const mapStateToProps = (root: RootState) => ({
    destinationInput: root.hotelSearch.destinationInput
});

export default connect(mapStateToProps, undefined)(HotelBreadcrumbs);