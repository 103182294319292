import React, {ReactElement} from "react";
import {Navigate, Route, Routes} from "react-router-dom";
import HeaderContainer from "../../components/base/Header/HeaderContainer";
import Footer from "../../components/base/Footer";
import Lefter from "../../components/base/Lefter/LefterContainer";
import Righter from "../../components/base/Righter";
import ErrorBoundary from "../../components/utils/ErrorBoundary/ErrorBoundary";
import PageTemplate from "../../components/base/PageTemplate/PageTemplate";
import ContactsContainer from "./ContactsContainer";
import PaymentInformation from "./PaymentInformation/PaymentInformation";

const Contacts = (): ReactElement => (
    <PageTemplate
        header={<HeaderContainer />}
        footer={<Footer />}
        lefter={<Lefter />}
        righter={<Righter />}
    >
        <ErrorBoundary>
            <Routes>
                <Route
                    path="/"
                    element={<ContactsContainer />}
                />

                <Route
                    path="/payment"
                    element={<PaymentInformation />}
                />

                <Route path="*" element={<Navigate to="/not-found" />} />
            </Routes>
        </ErrorBoundary>
    </PageTemplate>
);

export default Contacts;