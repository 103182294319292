import React, {ReactElement} from "react";
import withRouteInfo, {WithRouteInfo} from "components/utils/withRouteInfo";
import {connect, ConnectedProps} from "react-redux";
import HeaderComponent from "./Header";
import {toggleExpandedView} from "../../../redux/actions/auth.actions";
import {RootState} from "../../../redux/store/store.init";

type Props = ConnectedProps<typeof connector> & WithRouteInfo;

function HeaderContainer({
    disabledNav,
    toggleExpandedView,
    expandedModalView,
    modalView
}: Props): ReactElement {
    return (
        <HeaderComponent
            disabledNav={disabledNav}
            toggleExpandedView={toggleExpandedView}
            expandedModalView={expandedModalView}
            modalView={modalView}
        />
    );
}

const mapStateToProps = (state: RootState) => ({
    modalView: state.auth.modalView,
    expandedModalView: state.auth.expandedModalView
});

const connector = connect(mapStateToProps, {toggleExpandedView});
export default withRouteInfo(connector(HeaderContainer));