import {useEffect, useRef, useState} from "react"
import {useResizeDetector} from "react-resize-detector";

export enum MobileSwitchType {
    LOW_RES_DESKTOP = "LOW_RES_DESKTOP",
    MOBILE = "MOBILE"
}

const RESOLUTIONS = new Map<MobileSwitchType, number>([
    [MobileSwitchType.LOW_RES_DESKTOP, 1350],
    [MobileSwitchType.MOBILE, 992]
]);

export type UseMobileSwitchReturn = {
    isMobile: boolean;
};

const useMobileSwitch = (type = MobileSwitchType.MOBILE): UseMobileSwitchReturn => {
    const [isMobile, setIsMobile] = useState(false);
    const bodyRef = useRef<HTMLBodyElement>(document.querySelector("body"));

    const {ref} = useResizeDetector({
        targetRef: bodyRef,
        refreshRate: 100,
        handleWidth: true,
        handleHeight: false,
        refreshMode: "debounce",
        onResize: (width?: number) => {
            if (!width) {
                return;
            }

            const res = RESOLUTIONS.get(type)!;
            if (width <= res !== isMobile) {
                setIsMobile(width <= res);
            }
        }
    });

    useEffect(() => {
        ref.current = bodyRef.current;
    }, [ref]);

    return {
        isMobile
    };
};

export default useMobileSwitch;