import React, {ReactElement} from "react";
import {useTranslation} from "react-i18next";
import styles from "./Header.module.scss";

type Props = {
    id?: string;
};

function Header({
    id
}: Props): ReactElement {
    const {t} = useTranslation();

    return (
        <div className={styles.Root}>
            <h2 className={styles.Title}>{t("amend_h_amendments_title_with_id", {id})}</h2>

            {/*<UserButton*/}
            {/*    text={t("amend_h_transfer_amendmends_guide")}*/}
            {/*    variant={UserButtonVariant.SECONDARY}*/}
            {/*    customTag={CustomTagType.A}*/}
            {/*    buttonProps={{*/}
            {/*        href: "#",*/}
            {/*        download: "amendments-guide",*/}
            {/*        style: {*/}
            {/*            height: "50px",*/}
            {/*            width: "228px"*/}
            {/*        }*/}
            {/*    }}*/}
            {/*/>*/}
        </div>
    );
}

export default Header;
