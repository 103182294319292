import MapTypes from "redux/constants/map.constants";
import {GooglePlace} from "proptypes/PropTypeObjects";
import IAppActions from "../store/store.actions";
import {CommonActionReturnType} from "../store/store.init";
import {removeMapFilter} from "./hotelSearch.base.actions";

export const setMapCanBeLoaded = (mapCanBeLoaded: boolean): IAppActions => ({
    type: MapTypes.MAP_CAN_BE_LOADED,
    mapCanBeLoaded
});

export const toggleMapWidth = (): IAppActions => ({
    type: MapTypes.TOGGLE_MAP_WIDTH
});

export const changeZoom = (zoom: number): IAppActions => ({
    type: MapTypes.CHANGE_MAP_ZOOM,
    zoom
});

export const changeCenter = (center: google.maps.LatLng): IAppActions => ({
    type: MapTypes.CHANGE_MAP_CENTER,
    center
});

export const changePrecalculatedZoom = (zoom: number): IAppActions => ({
    type: MapTypes.CHANGE_PRECALCULATED_ZOOM,
    zoom
});

export const changePrecalculatedCenter = (center: google.maps.LatLng): IAppActions => ({
    type: MapTypes.CHANGE_PRECALCULATED_CENTER,
    center
});

export const changeMapTypeId = (mapTypeId: "roadmap" | "hybrid"): IAppActions => ({
    type: MapTypes.CHANGE_MAP_TYPE_ID,
    mapTypeId
});

export const changeDrawingMode = (drawingMode?: google.maps.drawing.OverlayType): IAppActions => ({
    type: MapTypes.CHANGE_DRAWING_MODE,
    drawingMode
});

export const toggleMobileDrawing = (): IAppActions => ({
    type: MapTypes.TOGGLE_MOBILE_DRAWING
});

export const toggleMapFilterBox = (showMapFilterBox: boolean): IAppActions => ({
    type: MapTypes.TOGGLE_MAP_FILTER_BOX,
    showMapFilterBox
});

export const setFigure = (figureName: google.maps.drawing.OverlayType, figure?: google.maps.Circle | google.maps.Polygon ): CommonActionReturnType => (dispatch, getState) => {
    const {polygon, circle} = getState().map;
    const {mapFilter} = getState().hotelSearchResults;

    if (!figure) {
        if (polygon && figureName === google.maps.drawing.OverlayType.POLYGON) {
            polygon?.setMap(null)
        }

        if (circle && figureName === google.maps.drawing.OverlayType.CIRCLE) {
            circle?.setMap(null)
        }
    }

    dispatch({type: MapTypes.SET_FIGURE, figureName, figure});

    if ((!polygon && !circle) && mapFilter) {
        dispatch(removeMapFilter(true, false));
    }
};

export const setPlaces = (places: GooglePlace[]): IAppActions => ({
    type: MapTypes.SET_PLACES,
    places
});
