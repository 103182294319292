import React, {ReactElement} from "react";
import {ReactComponent as SuccessIcon} from "assets/icons/check.svg";
import {ReactComponent as ErrorIcon} from "assets/icons/cross.svg";
import UserButton from "components/base/UserButton";
import cx from "classnames";
import styles from "./PageStatus.module.scss";
import {UserButtonVariant} from "../../base/UserButton/UserButton";

function getStatusIconAndClassName(status: any) {
    switch (status) {
    case "success":
    case "OK":
        return {
            className: cx(styles.Icon, styles.Success),
            icon: <SuccessIcon />
        };
    case "failure":
        return {
            className: cx(styles.Icon, styles.Error),
            icon: <ErrorIcon />
        };
    default:
        return {
            className: cx(styles.Icon, styles.Error),
            icon: <ErrorIcon />
        };
    }
}

function PageStatus({
    title,
    content,
    status = "",
    withActionButton = true,
    actionTitle = "",
    onAction = function () {},
    className
}: any): ReactElement {
    const {icon, className: statusClassName} = getStatusIconAndClassName(status);

    return (
        <div className={cx(styles.Root, className)}>
            <div className={statusClassName}>{icon}</div>

            <h2 className="mb-30">{title}</h2>

            <div className={styles.Content}>
                {content}
            </div>

            {withActionButton && (
                <UserButton
                    text={actionTitle}
                    variant={UserButtonVariant.PRIMARY}
                    buttonProps={{
                        onClick: onAction,
                        type: "button",
                        style: {
                            minHeight: "50px",
                            width: "200px"
                        }
                    }}
                />
            )}
        </div>
    );
}

export default PageStatus;
