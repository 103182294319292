import React, {ReactElement} from "react";
import {WebPaymentProvider} from "proptypes/PropTypeObjects";
import styles from "./PaymentProviderLogo.module.scss";
import payseraLogo from "assets/images/paymentprovider/payseraLogo.png";
import airwallexLogo from "assets/images/paymentprovider/airwallex.png";
import {useTranslation} from "react-i18next";

export type PaymentProviderLogoProps = {
    paymentProvider?: WebPaymentProvider;
}

const PaymentProviderLogo = ({
                                 paymentProvider = WebPaymentProvider.PAYSERA
                             }: PaymentProviderLogoProps): ReactElement | null => {
    const {t} = useTranslation();

    if (paymentProvider === WebPaymentProvider.PAYSERA) {
        return (
            <div className={styles.Root}>
                {t("pc_pc_forwarded_to")}{" "}
                <img src={payseraLogo} alt="paysera-logo"/>
            </div>
        )
    }

    if (paymentProvider === WebPaymentProvider.AIRWALLEX) {
        return (
            <div className={styles.Root}>
                {t("pc_pc_forwarded_to")}{" "}
                <img src={airwallexLogo} alt="airwallex-logo"/>
            </div>
        )
    }

    return null;
}

export default PaymentProviderLogo;