import * as hotelAPI from "api/hotelAPI";
import {HotelSearchCriteriaPropTypes, HotelSearchResult} from "proptypes/PropTypeObjects";
import {globalAxiosController} from "api/axios/globalAxiosController";
import {DETECTED_BROWSER_TYPE, DETECTED_DEVICE_TYPE} from "appBootstrap";
import Currency from "@hotelston_web_frontend_parent/constants/currency";

export default function getSearchResults(
    formData: HotelSearchCriteriaPropTypes,
    locale: string,
    currency: Currency,
    size: number
): Promise<HotelSearchResult> {
    return globalAxiosController.addRequest(
        hotelAPI.getSearchResults(
            formData,
            locale,
            currency,
            size,
            DETECTED_DEVICE_TYPE,
            DETECTED_BROWSER_TYPE
        )
    );
}
