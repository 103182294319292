import React, {ReactElement, useCallback, useState} from "react"
import styles from "./LinkGroup.module.scss"
import cx from "classnames"

export type LinkGroupProps = {
    initial?: boolean;
    groupLabel?: string;
    children: ReactElement[];
}

const LinkGroup = (props: LinkGroupProps): ReactElement => {
    const {
        initial,
        groupLabel,
        children
    } = props;

    const [open, setOpen] = useState(initial || false);

    const toggleOpen = useCallback(() => {
        setOpen(!open);
    }, [open]);

    return (
        <div className={cx(styles.Root, open && styles.GroupOpen)}>
            <a onClick={toggleOpen}>{groupLabel}</a>

            <div className={styles.GroupLinks}>
                {children}
            </div>
        </div>
    );
}

export default LinkGroup;