import React, {ReactElement} from "react";
import UserButton from "components/base/UserButton";
import cx from "classnames";
import styles from "./ItemsBoxWithAction.module.scss";
import {UserButtonVariant} from "../../../../components/base/UserButton/UserButton";
import ActionIcon, {ActionIconType} from "../../../../components/common/ActionIcon/ActionIcon";
import {BookingListItemPropTypes} from "../../../../proptypes/PropTypeObjects";
import IAppActions from "../../../../redux/store/store.actions";

export type ItemsBoxWithActionProps = {
    className?: string;
    emptyBoxLabel?: ReactElement;
    items?: BookingListItemPropTypes[];
    itemsTitle: string;
    mainActionLabel: string;

    onMainAction: (items: BookingListItemPropTypes[]) => void;
    onRemoveItem?: (item: BookingListItemPropTypes) => void;
    onCloseBox?: () => IAppActions;
};

function ItemsBoxWithAction({
    className,
    emptyBoxLabel,
    items = [],
    itemsTitle = "Title:",
    mainActionLabel = "Main action",

    onMainAction,
    onRemoveItem,
    onCloseBox
}: ItemsBoxWithActionProps): ReactElement {
    return (
        <div className={cx(styles.Root, className)}>
            {items.length === 0 && (
                <div className={styles.EmptyBoxLabel}>
                    {emptyBoxLabel}
                </div>
            )}

            {items.length > 0 && (
                <div className={styles.ItemsBody}>
                    <div className={styles.Title}>{itemsTitle}</div>

                    <div className={styles.ItemsWrapper}>
                        {items.map(({reference}, i) => (
                            <div
                                key={i}
                                className={styles.Item}
                            >
                                <span className={styles.ItemLabel}>{reference}</span>
                                <span
                                    className={styles.ItemRemove}
                                    onClick={() => {
                                        if (onRemoveItem) {
                                            onRemoveItem(items[i]);
                                        }
                                    }}
                                >
                                    <ActionIcon type={ActionIconType.CLOSE} />
                                </span>
                            </div>
                        ))}
                    </div>
                </div>
            )}

            <div className={styles.RightContainer}>
                <div
                    className={styles.CloseButton}
                    onClick={onCloseBox}
                >
                    <ActionIcon type={ActionIconType.CLOSE} />
                </div>

                {items.length > 0 && (
                    <UserButton
                        text={mainActionLabel}
                        variant={UserButtonVariant.PRIMARY}
                        className={styles.MainActionButton}
                        buttonProps={{
                            type: "button",
                            onClick: () => onMainAction(items)
                        }}
                    />
                )}
            </div>
        </div>
    );
}

export default ItemsBoxWithAction;
