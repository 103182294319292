import React, {ReactElement} from "react";
import styles from "./LineWithAction.module.scss";
import UserButton from "../../base/UserButton";
import {UserButtonVariant} from "../../base/UserButton/UserButton";

type Props = {
    label: string;
    onClick: () => void;
};

function LineWithAction({
    label,
    onClick
}: Props): ReactElement {
    return (
        <div className={styles.Root}>
            {/*<div className="h-divider" />*/}

            <UserButton
                text={label}
                variant={UserButtonVariant.SECONDARY}
                buttonProps={{
                    onClick
                }}
            />
        </div>
    );
}

export default LineWithAction;
