import React, {ReactElement} from "react";
import {useTranslation} from "react-i18next";
import {TFunction} from "i18next";
import titles from "assets/json/titles.json";
import _ from "lodash";
import createLuxonDate from "../../../utils/date/createLuxonDate"
import {LocalePropTypes, WebTransferAmendRequestAmendments} from "proptypes/PropTypeObjects";
import styles from "./Changes.module.scss";

function getValue(value: any, type: string, t: TFunction, locales? : LocalePropTypes[]) {
    switch (type) {
    case "PAX":
        return !!value && `${t(titles.titles.find((e) => e.value === value.title)?.label)} ${value.name} ${value.surname} `;
    case "FLIGHT_DATETIME_ARRIVAL":
    case "FLIGHT_DATETIME_DEPARTURE":
        return !!value && createLuxonDate(value).toFormat("yyyy-MM-dd HH:mm");
    case "CHILDREN":
        return !!value && (value === 0 ? "0" : t("mb_ta_ta_CHILDREN_VALUE", {count: value.length, ages: value.join(", ")}));
    case "SMS_LANGUAGE":
        return locales.find((locale) => locale.lang === value)["name"];
    default:
        return value;
    }
}

interface Any {
    [key: string]: unknown;
}

type Props = {
    oldValues: WebTransferAmendRequestAmendments | undefined;
    newValues: WebTransferAmendRequestAmendments | undefined;
    locales: LocalePropTypes[] | undefined;
};

export default function Changes({
    oldValues,
    newValues,
    locales
}: Props): ReactElement {
    const {t} = useTranslation();

    if (!oldValues || !newValues || _.isEmpty(newValues)) {
        return (<p>{t("mb_ta_ta_no_changes")}</p>);
    }

    return (
        <>
            {
                Object.keys(newValues).map((key, i) => (
                    <div key={i} className={styles.Root}>
                        <table>
                            <thead>
                                <tr>
                                    <th colSpan={2}>{t("mb_ta_ta_" + key)}</th>
                                </tr>
                            </thead>

                            <tbody>
                                <tr>
                                    <td>
                                        <dl className={styles.infoWrapper}>
                                            <dt className={styles.label}>{t("mb_ta_c_from")}:</dt>
                                            <dd>{getValue(oldValues[key], key, t, locales)}</dd>
                                        </dl>
                                    </td>

                                    <td>
                                        <dl className={styles.infoWrapper}>
                                            <dt className={styles.label}>{t("mb_ta_c_to")}:</dt>
                                            <dd>{getValue(newValues[key], key, t, locales)}</dd>
                                        </dl>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                ))
            }
        </>
    );
}
