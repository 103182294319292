import React, {ReactElement} from "react";
import HeaderContainer from "components/base/Header/HeaderContainer";
import Footer from "components/base/Footer";
import Lefter from "components/base/Lefter/LefterContainer";
import Righter from "components/base/Righter";
import PageTemplate from "components/base/PageTemplate/PageTemplate";
import ErrorBoundary from "components/utils/ErrorBoundary/ErrorBoundary";
import SplitPayment from "views/Payment/SplitPayment/SplitPayment";

const SplitPaymentContainer = (): ReactElement => (
    <PageTemplate
        header={<HeaderContainer/>}
        footer={<Footer/>}
        lefter={<Lefter/>}
        righter={<Righter/>}
    >
        <ErrorBoundary>
            <SplitPayment/>
        </ErrorBoundary>
    </PageTemplate>
)

export default SplitPaymentContainer;