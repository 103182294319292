import {CurrencyTypes} from "../constants/currency.constants";
import getCurrencyAPI from "../../api/currencyAPI";
import {getQueryParamsState, replaceQueryParams} from "utils/url/queryParams";
import {CurrencyResult} from "proptypes/PropTypeObjects";
import {CommonActionReturnType} from "../store/store.init";
import IAppActions from "../store/store.actions";
import {globalAxiosController} from "api/axios/globalAxiosController";
import Currency from "@hotelston_web_frontend_parent/constants/currency";

function getCurrencyStart(): IAppActions {
    return ({
        type: CurrencyTypes.REQ_CURRENCIES
    });
}

function getCurrencySuccess(currencies: CurrencyResult): IAppActions {
    return ({
        type: CurrencyTypes.GET_CURRENCIES_SUCCESS,
        currencies
    });
}

function getCurrencyFailure(error: any): IAppActions {
    return ({
        type: CurrencyTypes.GET_CURRENCIES_FAILURE,
        error
    });
}

export function getCurrency(): CommonActionReturnType {
    return (dispatch) => {
        dispatch(getCurrencyStart());
        globalAxiosController.addRequest(getCurrencyAPI()).then(
            (data) => {
                dispatch(getCurrencySuccess(data));
            }
        ).catch(
            (error: any) => {
                // console.log(error);
                dispatch(getCurrencyFailure(error));
            }
        );
    };
}

function changeCurrency(currentCurrency: Currency): IAppActions {
    return ({
        type: CurrencyTypes.CHANGE_CURRENCY, currentCurrency
    });
}

export const changeCurrentCurrency = (currentCurrency: Currency, updateQueryParam = true): CommonActionReturnType => (dispatch) => {
    dispatch(changeCurrency(currentCurrency));

    if (updateQueryParam) {
        const urlCurrency = getQueryParamsState().currency;

        if (urlCurrency !== currentCurrency) {
            setTimeout(() => document.location.reload());
        }

        replaceQueryParams(
            undefined,
            {
                cr: currentCurrency
            }
        );
    }
};