import React, {ReactElement, useCallback, useMemo} from "react"
import DateRangePickerDay from "./DateRangePickerDay";
import styles from "./DateRangePickerMonth.module.scss";
import toTitleCase from "../../../../../utils/toTitleCase";
import cx from "classnames";
import _ from "lodash"
import {resetTime} from "../../../../../utils/dateUtils"
import {DateTime, Info, Interval} from "luxon"
import createLuxonDate from "../../../../../utils/date/createLuxonDate"

export type DateRangePickerMonthProps = {
    value: DateTime | null;
    from?: DateTime | null;
    until?: DateTime;
    nextMonth?: boolean;
    hover?: boolean;
    hoverUntil?: DateTime;

    validFrom?: DateTime;

    onDayMouseEnter?: (value: DateTime) => void;
    onDayMouseLeave?: (value: DateTime) => void;

    onSelectDay?: (value: DateTime) => void;
    className?: string;
};

const DateRangePickerMonth = (props: DateRangePickerMonthProps): ReactElement => {
    const {
        value = null,
        from,
        until,
        nextMonth,
        hover,
        hoverUntil,
        onSelectDay,
        onDayMouseEnter,
        onDayMouseLeave,
        validFrom,
        className
    } = props;

    const innerValue = nextMonth ? createLuxonDate(value).plus({month: 1}) : createLuxonDate(value);

    const onSelectDayCallback = useCallback((value: DateTime) => {
        if (onSelectDay) {
            onSelectDay(value);
        }
    }, [onSelectDay]);

    const buildDayElement = useCallback((day: DateTime) => {
        const active = (from && until && day.valueOf() >= from.valueOf() && day.valueOf() <= until.valueOf()) || Boolean((from && resetTime(day).equals(resetTime(from)) && !nextMonth));
        // const disabled = day.valueOf() < (validFrom?.valueOf() || 0) || day.month !== innerValue.month;
        const disabled = day.valueOf() < (validFrom?.valueOf() || 0);
        const dayInNextMonth = day.month !== innerValue.month;
        const range = from && until && Interval.fromDateTimes(from, until);
        const hoverRange = from && until && hover && hoverUntil && Interval.fromDateTimes(from, hoverUntil);

        return (
            <DateRangePickerDay
                key={day.toISO({includeOffset: false})}
                className={cx(
                    !disabled && from && until && day.equals(from) && styles.RangeStart,
                    !disabled && from && until && day.equals(until) && styles.RangeEnd,
                    !disabled && range && day.hasSame(innerValue, "month") && range.contains(day) && styles.InRange,
                    !disabled && hoverRange && hoverRange.contains(day) && styles.Hover,
                    dayInNextMonth && styles.NextMonth
                )}
                active={!disabled && active}
                label={day.toFormat("d")}
                value={day}
                onSelect={onSelectDayCallback}
                disabled={disabled}
                onMouseEnter={onDayMouseEnter}
                onMouseLeave={onDayMouseLeave}
            />
        );
    }, [from, hover, hoverUntil, innerValue, nextMonth, onDayMouseEnter, onDayMouseLeave, onSelectDayCallback, until, validFrom]);

    const days = useMemo(() => {
        const constructedDays: ReactElement[] = [];

        let ticker = innerValue.set({
            day: 1
        });

        let tickerUntil = ticker.plus({month: 1});

        if (tickerUntil.weekday !== 1) {
            tickerUntil.plus({day: 7 - tickerUntil.weekday});
        }

        if (ticker.weekday !== 1) {
            ticker = ticker.minus({day: ticker.weekday - 1});
        }

        constructedDays.push(
            <div className={styles.WeekRow} key={_.uniqueId()}>
                {Info.weekdays("short").map((weekDay) => <DateRangePickerDay className={styles.WeekLabel} key={weekDay} label={weekDay} disabled />)}
            </div>
        );

        let week: ReactElement[] = [];
        while (ticker.valueOf() <= tickerUntil.valueOf()) {
            const day = createLuxonDate(ticker);

            const dayElement = buildDayElement(day);
            week.push(dayElement);

            if (ticker.weekday === 7) {
                constructedDays.push((
                    <div key={_.uniqueId()} className={styles.WeekRow}>
                        {[...week]}
                    </div>
                ))

                week = [];
            }

            ticker = ticker.plus({day: 1});
        }

        if (week.length !== 0 && constructedDays.length !== 7) {
            while (week.length !== 7) {
                week.push(buildDayElement(ticker));
                ticker = ticker.plus({day: 1});
            }

            constructedDays.push((
                <div key={_.uniqueId()} className={styles.WeekRow}>
                    {[...week]}
                </div>
            ))

            week = [];
        }

        if (constructedDays.length !== 7) {
            constructedDays.push(<div key={_.uniqueId()} className={styles.WeekRow} />);
        }

        return constructedDays;
    }, [buildDayElement, innerValue]);

    return (
        <div className={cx(styles.Root, className)}>
            <div className={styles.Header}>{innerValue.get("year")} {toTitleCase(Info.months("long")[innerValue.get("month") - 1])}</div>

            {days}
        </div>
    );
}

export default DateRangePickerMonth;