import React, {ReactElement, Suspense, useEffect, useLayoutEffect, useState} from "react"
import {useTranslation} from "react-i18next";
import {FailedToLoadTranslationFileSubject} from "i18n"
import {RouterProvider} from "react-router-dom"
import "appBootstrap";
import Cookies from "js-cookie"
import {useAppDispatch, useAppSelector} from "redux/hooks"
import {checkLogin} from "redux/actions/auth.actions"
import {changeCurrentLocale} from "redux/actions/locale.actions";
import {globalAxiosController} from "api/axios/globalAxiosController";
import {getUserGeoFromIp} from "api/userAPI";
import {DEFAULT_WEB_VERSION_COOKIE_NAME} from "utils/cookie/cookie"
import RouteListener from "utils/RouteListener"
import {getQueryParams} from "utils/url/queryParams";
import createLuxonDate from "../utils/date/createLuxonDate"
import WebSocketWatcher from "components/utils/WebSocketWatcher"
import AuthComponentSwitch from "components/utils/AuthComponentSwitch"
import ErrorBoundary from "../components/utils/ErrorBoundary/ErrorBoundary"
import AxiosErrorModal from "components/utils/ErrorBoundary/AxiosErrorModal"
import AppSplashscreen from "./AppSplashscreen"
import router from "views/router/router"
import AppNoInternet from "./AppNoInternet";
import AppUnavailable from "./AppUnavailable"
import "./App.scss"

const App = (): ReactElement => {
    const [serverUnavailable, setServerUnavailable] = useState<boolean>(false);
    const [userHasInternet, setUserHasInternet] = useState<boolean>(true);
    const appDispatch = useAppDispatch();
    const localesWithTranslations = useAppSelector(state => state.locale?.locales.locales).map(locale => locale.lang);
    const checkedLogin = useAppSelector(state => state.auth.checkedLogin);
    const isLoggedInUser = useAppSelector(state => !!state.auth.userData);
    const currentLocale = useAppSelector(state => state.auth.userData?.locale?.lang);
    const {i18n} = useTranslation();

    useEffect(() => {
        appDispatch(checkLogin())
    }, [appDispatch])

    useEffect(() => {
        const callback = () => {
            setServerUnavailable(true)
        }

        FailedToLoadTranslationFileSubject.subscribe(callback)

        return () => {
            FailedToLoadTranslationFileSubject.unsubscribe(callback)
        }
    }, []);

    useEffect(() => {
        setUserHasInternet(navigator.onLine);

        const handleOnline = () => setUserHasInternet(true);
        const handleOffline = () => setUserHasInternet(false);

        window.addEventListener("online", handleOnline);
        window.addEventListener("offline", handleOffline);

        return () => {
            window.removeEventListener("online", handleOnline);
            window.removeEventListener("offline", handleOffline);
        };
    }, []);


    useLayoutEffect(() => {
        Cookies.set(DEFAULT_WEB_VERSION_COOKIE_NAME, "2", {
            expires: createLuxonDate().plus({day: 30}).toJSDate(),
            domain: "hotelston.com"
        });
    }, []);

    useEffect( () => {
        const {l} = getQueryParams();
        let isMounted = true;
        const checkGeoLocation = async () => {
            try {
                const isoCodeFromUserIp = (await globalAxiosController.addRequest(getUserGeoFromIp())).toLowerCase();
                const hasDefaultLanguage= localesWithTranslations.includes(isoCodeFromUserIp);

                if (isoCodeFromUserIp === currentLocale || localStorage.getItem("currentLocale")) {
                    return;
                }

                if (isMounted && !l && !!isoCodeFromUserIp && hasDefaultLanguage && (checkedLogin && !isLoggedInUser)) {
                    void i18n.changeLanguage(isoCodeFromUserIp);
                    appDispatch(changeCurrentLocale(isoCodeFromUserIp, false));
                }
            } catch (error) {
                console.error(error)
            }
        }
        void checkGeoLocation();

        return () => {isMounted = false;}
    }, [checkedLogin]);

    return (
        <Suspense
            fallback={<AppSplashscreen/>}
        >
            {serverUnavailable && (
                <AppUnavailable/>
            )}

            {!userHasInternet && (
                <AppNoInternet />
            )}

            <AuthComponentSwitch
                authorizedComponent={WebSocketWatcher}
                unauthorizedComponent={() => <></>}
            />

            <ErrorBoundary>
                <RouteListener>
                    <AxiosErrorModal>
                        <RouterProvider router={router}/>
                    </AxiosErrorModal>
                </RouteListener>
            </ErrorBoundary>
        </Suspense>
    )
}

export default App
