import React, {ReactElement} from "react";
import BasicDropdown from "components/base/Input/BasicDropdown/BasicDropdown";
import cx from "classnames";
import {useTranslation} from "react-i18next";
import styles from "./ChildrenDropdown.module.scss";
import {Option} from "../../../proptypes/PropTypeObjects";

type Props = {
    className?: string;
    dropDownValues: number[];
    sidebar?: boolean;
    label?: string;
    hotelView?: boolean;
    numbers: Option<number, string>[];
    childrenAges: Option<number, string>[];
    handleChildren: (ages: number[]) => void;
};

function ChildrenDropdown({
    className,
    dropDownValues,
    sidebar,
    label = "",
    hotelView,
    numbers,
    childrenAges,
    handleChildren
}: Props): ReactElement {
    const {t} = useTranslation();

    const createNewChildrenArray = (value: number) => {
        if (value > dropDownValues.length - 1) {
            handleChildren(
                Object.assign([], Array(value).fill(1), dropDownValues)
            );
        } else {
            handleChildren(dropDownValues.slice(0, value));
        }
    };

    const setChildrenAge = (value: number, i: number) => {
        handleChildren(Object.assign(dropDownValues, {[i]: value}));
    };

    // const emptyItems: ReactElement[] = [];
    // if (dropDownValues.length !== 0 && dropDownValues.length < 10) {
    //     for (let i = dropDownValues.length; i < 10; i++) {
    //         emptyItems.push(
    //             <div className={styles.ChildrenDropdownEmpty} key={`ChildrenDropdownEmpty_${i}`} />
    //         );
    //     }
    // }

    return (
        <>
            <div
                className={cx(
                    styles.upperRoot,
                    sidebar && styles.sidebar,
                    className
                )}
            >
                {!!label && <div className={styles.label}>{label}</div>}
                <div>
                    <BasicDropdown
                        required={true}
                        hideErrors={true}
                        className="children-dropdown"
                        dropDownValue={dropDownValues.length}
                        onDropdownChange={createNewChildrenArray}
                        dropDownOptions={numbers}
                    />
                </div>
            </div>

            {dropDownValues.length > 0 && (
                <div className={styles.bottomRoot}>
                    <div className={styles.label}>
                        {t("room_cd_children_dropdown_title")}:
                    </div>

                    {dropDownValues.map((value, i) => (
                        <BasicDropdown<number, string>
                            required={false}
                            hideErrors={true}
                            key={i}
                            className="children-dropdown"
                            dropDownValue={value}
                            doNotSearchForLabel
                            onDropdownChange={(value) => setChildrenAge(value, i)}
                            dropDownOptions={childrenAges}
                        />
                    ))}
                    {/*{dropDownValues.length < 10 && emptyItems}*/}
                </div>
            )}
        </>
    );
}

export default ChildrenDropdown;
