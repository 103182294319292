import React, {ReactElement} from "react";
import cx from "classnames";
import {useTranslation} from "react-i18next";
import styles from "./TransferPassengerBox.module.scss";
import titles from "../../../assets/json/titles.json";
import {PassengerPropTypes} from "../../../proptypes/PropTypeObjects";

function getAdultsInfo(adult: any, t: any) {
    return (
        <>
            <span>
                {adult != null && adult.title != null && t(titles.titles.find((e) => e.value === adult.title).label)}
            </span> {adult.firstname} {adult.lastname}
        </>
    );
}

type Props = {
    pax: number;
    adultsCount: number;
    childrenCount: number;
    leadPassenger?: PassengerPropTypes;
    withBox?: boolean;
    dotStyle?: boolean;
    children?: React.ReactNode;
};

function TransferPassengerBox({
    pax, adultsCount, childrenCount, leadPassenger, withBox = true, dotStyle = false, children
}: Props): ReactElement {
    const {t} = useTranslation();

    return (
        <div className={cx(styles.Root, withBox && styles.WithBox)}>
            <div className={styles.Heading}>
                {!children && (
                    <span>{t("tfib_tpb_passengers")}</span>
                )}

                {children}

                {/*<span>*/}
                {/*    {t("tfib_tpb_pax")}: {pax}*/}
                {/*</span>*/}
            </div>

            {!dotStyle && (
                <>
                    {!!leadPassenger && (
                        <div className={styles.Text}>
                            {t("tfib_tpb_lead_passenger")}: {getAdultsInfo(leadPassenger, t)}
                        </div>
                    )}

                    <div className={styles.Text}>
                        {t("tfib_tpb_adult")}: {adultsCount}
                    </div>

                    { childrenCount > 0 && (
                        <div className={styles.Text}>
                            {t("tfib_tpb_children")}: {childrenCount}
                        </div>
                    )}
                </>
            )}

            {dotStyle && (
                <dl className={styles.Text}>
                    <dt>{t("tfib_tpb_lead_passenger")}:</dt>
                    <dd>{getAdultsInfo(leadPassenger, t)}</dd>

                    <dt>{t("tfib_tpb_adult")}:</dt>
                    <dd>{adultsCount}</dd>

                    <dt>{t("tfib_tpb_children")}:</dt>
                    <dd>{childrenCount}</dd>
                </dl>
            )}
        </div>
    );
}

export default TransferPassengerBox;
