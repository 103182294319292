import React, { ReactElement, useMemo } from "react"
import {useTranslation} from "react-i18next"
import styles from "./PaymentAccount.module.scss"
import { WebBankAccount } from "proptypes/PropTypeObjects"

export type PaymentAccountProps = {
  account?: WebBankAccount
}

const PaymentAccount = (props: PaymentAccountProps): ReactElement => {
  const {
    account
  } = props

  const {t} = useTranslation();

  const paymentAccount = useMemo(() => {
    let usedAccount = account;
    if (!usedAccount) {
      usedAccount = {
        countryName: t("c_pd_payment_lt_name"),
        bankName: t("c_pd_payment_details_lt_bank"),
        bankAddress: t("c_pd_payment_details_lt_bank"),
        swiftCode: t("c_pd_payment_details_lt_swift"),
        recipientName: t("c_pd_payment_details_lt_name"),
        recipientAddress: t("c_pd_payment_details_lt_recipient_address"),
        iban: t("c_pd_payment_details_lt_recipient_iban"),
        currencies: t("c_pd_payment_details_lt_accepted_currencies")
      } as WebBankAccount
    }

    return usedAccount
  }, [account])

  return (
    <div className={styles.Root}>
      <h4>{paymentAccount.countryName}</h4>

      <dl>
        <dt>{t("c_pd_payment_details_bank_label")}</dt>
        <dd>{paymentAccount.bankName}</dd>

        <dt>{t("c_pd_payment_details_address_label")}</dt>
        <dd>{paymentAccount.bankAddress}</dd>

        <dt>{t("c_pd_payment_details_swift_label")}</dt>
        <dd>{paymentAccount.swiftCode}</dd>

        <dt>{t("c_pd_payment_details_recipient_name_label")}</dt>
        <dd>{paymentAccount.recipientName}</dd>

        <dt>{t("c_pd_payment_details_recipient_address_label")}</dt>
        <dd>{paymentAccount.recipientAddress}</dd>

        <dt>{t("c_pd_payment_details_recipient_iban_label")}</dt>
        <dd>{paymentAccount.iban}</dd>

        <dt>{t("c_pd_payment_details_accepted_currencies_label")}</dt>
        <dd>{paymentAccount.currencies}</dd>
      </dl>
    </div>
  )
}

export default PaymentAccount;