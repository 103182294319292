import ActionRedirectTypes from "../constants/actionRedirect.constants";
import IAppActions from "../store/store.actions";
import {ActionRedirectPropTypes, ReduxActionRedirectType} from "proptypes/PropTypeObjects";
import {CommonActionReturnType} from "../store/store.init";
import router from "views/router/router"

export const setRedirect = (type: ReduxActionRedirectType, payload: unknown): IAppActions => ({
    type: ActionRedirectTypes.SET_REDIRECT,
    redirectType: type,
    payload
});

export const redirectComplete = (): IAppActions => ({
    type: ActionRedirectTypes.REDIRECT_COMPLETE
});

export const redirectMounted = (): IAppActions => ({
    type: ActionRedirectTypes.REDIRECT_MOUNTED
});

export const processActionRedirect = (payload: ActionRedirectPropTypes): CommonActionReturnType => (dispatch) => {
    if (!payload.actionRedirect) {
        return;
    }

    dispatch(setRedirect(payload.actionRedirect, payload.actionRedirectPayload));

    if ([ReduxActionRedirectType.UNSIGNED_AGREEMENT, ReduxActionRedirectType.UNSIGNED_AGREEMENT_AFTER_BOOK].includes(payload.actionRedirect)) {
        router.navigate("/action/unsigned-agreement", {replace: true});
    }

    if (ReduxActionRedirectType.SUBAGENT_AGREEMENT_AFTER_BOOK === payload.actionRedirect) {
        router.navigate("/action/subagent-unsigned-agreement", {replace: true});
    }
};