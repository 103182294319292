import React, {
    CSSProperties,
    ReactElement
} from "react";
import cx from "classnames";
import {ReactComponent as BusIcon} from "assets/icons/bus.svg";
import styles from "./TransferRangeBox.module.scss";

export type TransferRangeBoxProps = {
    smallTextFrom?: string;
    smallTextTo?: string;
    bigTextFrom?: string;
    bigTextTo?: string;

    style?: CSSProperties;
    dashed?: boolean;
};

function TransferRangeBox({
    smallTextFrom,
    smallTextTo,
    bigTextFrom,
    bigTextTo,
    style = {},
    dashed = false
}: TransferRangeBoxProps): ReactElement {
    // console.log(bigTextFrom);

    return (
        <div style={style} className={cx(styles.Root, dashed && styles.Dashed)}>
            <div className={styles.Top}>
                <h4 className={styles.BigText}>{bigTextFrom}</h4>

                {dashed && <BusIcon />}
                {!dashed && <div className={styles.DashedLine} />}

                <h4 className={styles.BigText}>{bigTextTo}</h4>
            </div>

            <div className={styles.Bottom}>
                <p className={styles.SmallText}>{smallTextFrom}</p>

                <p className={styles.SmallText}>{smallTextTo}</p>
            </div>
        </div>
    );
}

export default TransferRangeBox;
