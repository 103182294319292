import React, {
    ComponentClass, ExoticComponent,
    FunctionComponent, HTMLProps, JSXElementConstructor, MutableRefObject, ReactElement
} from "react";
import {ButtonProps} from "reactstrap";

export enum CustomTagType {
    A = "a",
    FORM = "form",
    BUTTON = "button"
}

export type CustomTagProps = {
    elementTag: ComponentClass<any> | FunctionComponent<any> | CustomTagType,
    elementRef?: MutableRefObject<React.ReactElement | undefined>,
    children?: ReactElement | ReactElement[]
} & ButtonProps;

function CustomTag({
    elementTag,
    elementRef,
    children,
    ...rest
}: CustomTagProps): ReactElement {
    // @ts-ignore
    return React.createElement(elementTag, {...rest, ref: elementRef} as HTMLProps<unknown>, children);
}

export default CustomTag;
