import React, {
    ReactElement, RefObject, useCallback, useLayoutEffect, useState
} from "react"
import _ from "lodash";
import cx from "classnames";
import styles from "./ResponsiveTableHorizontalScrollbar.module.scss";
import useTrailingResizeDetector from "@hotelston_web_frontend_components/hooks/resize/useTrailingWindowResize"

export type ResponsiveTableHorizontalScrollbarProps = {
    tableContainerRef: RefObject<HTMLDivElement>,
    tableRef: RefObject<HTMLTableElement>,
    columns: number;
};

const ResponsiveTableHorizontalScrollbar = ({
    tableContainerRef,
    tableRef,
    columns
}: ResponsiveTableHorizontalScrollbarProps): ReactElement => {
    const [scrollBarHidden, setScrollBarHidden] = useState(false);
    const [scrollBarWidth, setScrollbarWidth] = useState<number>(0);

    const onResize = useCallback(() => {
        setScrollBarHidden(tableContainerRef.current?.scrollWidth === tableContainerRef.current?.clientWidth);
        setScrollbarWidth(tableContainerRef.current?.scrollWidth || 0);
    }, [tableContainerRef]);

    // useResizeDetector({
    //     targetRef: tableContainerRef,
    //     onResize,
    //     refreshMode: "debounce",
    //     refreshRate: 50
    // });

    useTrailingResizeDetector({
        targetRefs: [tableRef, tableContainerRef],
        onResize
    });

    useLayoutEffect(() => {
        onResize();
    }, [columns, onResize]);

    return (
        <div
            className={cx(
                styles.Root,
                scrollBarHidden && styles.Hidden
            )}
            onScroll={(evt) => {
                if (tableContainerRef.current) {
                    _.set(tableContainerRef.current, "scrollLeft", evt.currentTarget.scrollLeft);
                }
            }}
        >
            <div
                className={styles.Scrollbar}
                style={{
                    width: scrollBarWidth
                }}
            />
        </div>
    );
};

export default ResponsiveTableHorizontalScrollbar;