import {ChangeEvent} from "react";
import {LastLocationKey, LocationBaseKey} from "redux/reducers/actions/router.reducer.actions";
import {HotelBookingTypes} from "redux/constants/hotelBooking.constants";
import HotelSearchTypes from "redux/constants/hotelSearch.constants";
import {setBooking} from "./bookingDetails.actions";
import {getHotelBookingOptions, makeHotelBooking, validateBooking} from "api/hotelBookingAPI";
import {
    BookingOptionsParams,
    HotelAvailabilityCriteriaPropTypes,
    HotelBookingContent,
    MakeHotelBookingRequest,
    MakeHotelBookingResponse,
    PaymentTransferTypePropTypes,
    SelectedRadiosElementType
} from "proptypes/PropTypeObjects";
import {CommonActionReturnType, RootState} from "redux/store/store.init";
import IAppActions from "redux/store/store.actions";
import setLastLocation, {toggleDisabledNav} from "./router.actions";
import {HotelBookingRoute} from "redux/reducers/hotelBooking.reducer";
import {processActionRedirect} from "./actionRedirect.actions";
import hasRedirect from "redux/services/actionRedirect.services";
import router from "views/router/router";
import {
    parseHotelPaymentOptions,
    processTransactionFeeUpdate, requestPay, requestPayFailure,
    requestPaymentOptions, requestPaySuccess,
    setPaymentLinkToken,
    setProviderPayment,
    updateCustomVoucher,
    updateDiscountData,
    updateVoucherData,
    updateVoucherSum
} from "./payment.actions"
import {rememberTransactionFee} from "./pricing.actions";
import {globalAxiosController} from "api/axios/globalAxiosController";
import {searchUpdate} from "./hotelSearch.actions";
import {DETECTED_BROWSER_TYPE, DETECTED_DEVICE_TYPE} from "appBootstrap";
import {Path} from "history";

function getBookingOptionsStart(hotelAvailabilityCriteria: HotelAvailabilityCriteriaPropTypes, bookingOptionsParams: BookingOptionsParams): IAppActions {
    return ({
        type: HotelBookingTypes.GET_OPTIONS,
        hotelAvailabilityCriteria,
        bookingOptionsParams
    });
}

function getBookingOptionsSuccess(data: HotelBookingContent): IAppActions {
    return ({
        type: HotelBookingTypes.GET_OPTIONS_SUCCESS,
        data
    });
}

function getBookingOptionsFailure(error: string[]): IAppActions {
    return ({
        type: HotelBookingTypes.GET_OPTIONS_FAILURE,
        error
    });
}

export function getBookingOptions(hotelId: number, roomsData: SelectedRadiosElementType[]): CommonActionReturnType {
    return (dispatch, getState) => {
        const {
            checkIn, checkOut, clientNationality, rooms, cityId
        } = getState().hotelSearch.formData;

        const locale = getState().locale.currentLocale;
        const currency = getState().currency.currentCurrency;

        const hotelAvailabilityCriteria = {
            checkIn,
            checkOut,
            clientNationality,
            hotelId,
            ...(cityId && {cityId}),
            rooms: roomsData.map(({
                roomId,
                roomTypeId,
                boardTypeId,
                roomNo,
                cxlDate,
                price,
                displayPrice,
                cancellationRules
            }) => ({
                ...rooms[roomNo],
                roomId,
                roomTypeId,
                boardTypeId,
                price,
                displayPrice,
                cancellationRules,
                displayBookingTermsCXL: "",
                displayBookingTermsRemarks: "",
                ...(cxlDate && {cxlDate})
            }))
        } as HotelAvailabilityCriteriaPropTypes;

        dispatch(requestPaymentOptions());
        dispatch(setLastLocation(router.state.location, LocationBaseKey.HOTELS, LastLocationKey.LAST_SEARCH_RESULTS_LOCATION));
        dispatch(clearBookingData());
        dispatch(getBookingOptionsStart(hotelAvailabilityCriteria, {
            locale,
            currency
        }));

        router.navigate("/hotels/booking");

        globalAxiosController.addRequest(getHotelBookingOptions(hotelAvailabilityCriteria, locale, currency, DETECTED_BROWSER_TYPE, DETECTED_DEVICE_TYPE))
            .then((data) => {
                if (hasRedirect(data)) {
                    dispatch(processActionRedirect(data));
                }

                dispatch(getBookingOptionsSuccess(data));
                dispatch(parseHotelPaymentOptions(data));
                dispatch(processTransactionFeeUpdate(data.bookingOptions));
            }).catch((error: string[]) => {
            // console.log(error);
                dispatch(getBookingOptionsFailure(error));
            });
    };
}

function updateOptionsStart(bookingOptionsParams: BookingOptionsParams): IAppActions {
    return ({
        type: HotelBookingTypes.UPDATE_OPTIONS,
        bookingOptionsParams
    });
}

function updateOptionsSuccess(data: HotelBookingContent): IAppActions {
    return ({
        type: HotelBookingTypes.UPDATE_OPTIONS_SUCCESS,
        data
    });
}

function updateBookingOptionsFailure(error: string[]): IAppActions {
    return ({
        type: HotelBookingTypes.UPDATE_OPTIONS_FAILURE,
        error
    });
}

export function updateBookingOptions(): CommonActionReturnType {
    return (dispatch, getState) => {
        const hotelAvailabilityCriteria = getState().hotelBooking.data?.hotelAvailabilityCriteria;
        const locale = getState().locale.currentLocale;
        const currency = getState().currency.currentCurrency;

        if (!hotelAvailabilityCriteria) {
            return;
        }

        dispatch(updateOptionsStart({
            locale,
            currency
        }));

        router.navigate("/hotels/booking", {replace: true});

        globalAxiosController.addRequest(getHotelBookingOptions(hotelAvailabilityCriteria, locale, currency, DETECTED_BROWSER_TYPE, DETECTED_DEVICE_TYPE))
            .then((data) => {
                if (hasRedirect(data)) {
                    dispatch(processActionRedirect(data));
                }

                dispatch(updateOptionsSuccess(data));
                dispatch(processTransactionFeeUpdate(data.bookingOptions));
            }).catch((error: any) => {
            // console.log(error);
                dispatch(updateBookingOptionsFailure(error));
            });
    };
}

function makeHotelBookingStart(): IAppActions {
    return ({
        type: HotelBookingTypes.REQUEST_HOTEL_BOOKING
    });
}

function makeHotelBookingSuccess(data: MakeHotelBookingResponse): IAppActions {
    return ({
        type: HotelBookingTypes.HOTEL_BOOKING_SUCCESS,
        data
    });
}

function makeHotelBookingFailure(error: string[]): IAppActions {
    return ({
        type: HotelBookingTypes.HOTEL_BOOKING_FAILURE,
        error
    });
}

function makeHotelBookingChanges(data: MakeHotelBookingResponse): IAppActions {
    return ({
        type: HotelBookingTypes.HOTEL_BOOKING_CHANGES,
        data
    });
}

function prepareMakeHotelBookingData(getState: () => RootState): MakeHotelBookingRequest {
    const currency = getState().currency.currentCurrency;
    const {data} = getState().hotelBooking;
    const {currentCompanyMarkupIndex} = getState().auth;
    const {
        paymentProvider,
        paymentTransferTypeEnum,
        selectedPaymentLink,
        customInvoice,
        invoiceRecipient,
        // transactionFee,
        appliedWalletAmount,
        customVoucher,
        appliedDiscountAmount,
        bookingOptions,
        agreeTerms,
        splitPricePartSize,
        selectedSplitPricePart
    } = getState().payment;
    const {
        transactionFee
    } = getState().pricing;

    const hotelAmount = getState().auth.userData?.companyMarkups[currentCompanyMarkupIndex || 0]?.hotelAmount || 1;
    // WTF?
    // const displaySalePrice = data?.hotelAvailabilityCriteria?.rooms.reduce((prev, {displayPrice}) => prev + Number(displayPrice), 0) || 0;
    const displaySalePrice = bookingOptions?.displaySalePrice || 0;
    const appliedDiscountCode = getState().payment.appliedDiscountCode;

    let paymentTransferType: PaymentTransferTypePropTypes | undefined = paymentTransferTypeEnum;
    if (paymentTransferTypeEnum === PaymentTransferTypePropTypes.SUPERAGENT_PAYMENT) {
        paymentTransferType = bookingOptions?.afterDeadline ? PaymentTransferTypePropTypes.PAY_LATER_AFTER_CXL : PaymentTransferTypePropTypes.PAY_LATER;
    }

    return {
        hotelAvailabilityCriteria: data?.hotelAvailabilityCriteria,
        hotelBookingInfo: data?.hotelBookingInfo,
        bookingInfo: {
            ...data?.bookingInfo,
            agreeTerms,
            paymentProvider,
            paymentTransferTypeEnum: paymentTransferType,
            selectedPaymentLink,
            customInvoice,
            invoiceRecipient,
            appliedWalletAmount,
            paymentVouchers: customVoucher.use,
            appliedDiscount: appliedDiscountAmount,
            discountCode: appliedDiscountCode?.valid ? appliedDiscountCode?.code : undefined,
            splitPartSize: splitPricePartSize,
            splitPartNo: selectedSplitPricePart?.order
        },
        retailPrice: {
            amount: ((displaySalePrice * hotelAmount) + ((transactionFee || 0) * hotelAmount)),
            currency
        }
    } as MakeHotelBookingRequest;
}

export function makeHotelBookingRequest(replaceUrl = false): CommonActionReturnType {
    return (dispatch, getState) => {
        const locale = getState().locale.currentLocale;
        const {transactionFee} = getState().payment;
        const makeHotelBookingData = prepareMakeHotelBookingData(getState);

        dispatch(requestPay())
        dispatch(makeHotelBookingStart());
        dispatch(toggleDisabledNav(true));
        dispatch(rememberTransactionFee(transactionFee));

        globalAxiosController.addRequest(makeHotelBooking(makeHotelBookingData, locale, makeHotelBookingData.retailPrice.currency))
            .then((response) => {
                if (response.route === HotelBookingRoute.CHANGES || response.route === HotelBookingRoute.NO_AVAILABILITY || response.route === HotelBookingRoute.DUPLICATE_BOOKINGS) {
                    if (response.bookingOptions) {
                        dispatch(processTransactionFeeUpdate(response.bookingOptions));
                    }

                    dispatch(makeHotelBookingChanges(response));
                    dispatch(requestPaySuccess())
                } else if (response.route === HotelBookingRoute.VALIDATION_ERRORS) {
                    dispatch(makeHotelBookingFailure(response.errors));
                    dispatch(requestPayFailure())
                    return;
                } else {
                    dispatch(makeHotelBookingSuccess(response));

                    if (response.providerPayment) {
                        dispatch(setProviderPayment(response.providerPayment));
                    }

                    dispatch(requestPaySuccess())
                }

                if (response && response.bookingDetails && !response.errors && response.route === "OK" && !response.providerPayment) {
                    dispatch(updateDiscountData(0));
                    dispatch(updateVoucherData([]));
                    dispatch(updateVoucherSum({all: 0, used: 0}));
                    dispatch(updateCustomVoucher({active: [], original: [], use: []}));

                    dispatch(setBooking(response.bookingDetails.id, {
                        ...response.bookingDetails,
                        showBusinessCredentials: response.showBusinessCredentials
                    }));

                    if (response.paymentLinkToken) {
                        dispatch(setPaymentLinkToken(response.paymentLinkToken));
                        router.navigate("/booking/success/payment-link", {replace: replaceUrl});
                        return;
                    } else {
                        router.navigate("/booking/success", {replace: replaceUrl});
                    }
                }
            }).catch((error: string[]) => {
            // console.log(error);
                dispatch(makeHotelBookingFailure(error));
                dispatch(requestPayFailure())
            }).finally(() => {
                dispatch(toggleDisabledNav(false));
            });
    };
}

function validateBookingStart(): IAppActions {
    return ({
        type: HotelBookingTypes.REQUEST_VALIDATE_BOOKING
    });
}

function validateBookingSuccess(data: MakeHotelBookingResponse): IAppActions {
    return ({
        type: HotelBookingTypes.VALIDATE_BOOKING_SUCCESS,
        data
    });
}

function validateBookingFailure(error: string[]): IAppActions {
    return ({
        type: HotelBookingTypes.VALIDATE_BOOKING_FAILURE,
        error
    });
}

export function validateBookingStep(step: number): CommonActionReturnType {
    return (dispatch, getState) => {
        const locale = getState().locale.currentLocale;
        const makeHotelBookingData = prepareMakeHotelBookingData(getState);

        dispatch(validateBookingStart());
        dispatch(toggleDisabledNav(true));

        globalAxiosController.addRequest(validateBooking(makeHotelBookingData, locale, makeHotelBookingData.retailPrice.currency, DETECTED_BROWSER_TYPE, DETECTED_DEVICE_TYPE, step))
            .then((data) => {
                dispatch(validateBookingSuccess(data));
                dispatch(toggleDisabledNav(false));

                if (data && !data.errors && data.route === HotelBookingRoute.OK) {
                    if (step === 1) {
                        router.navigate("/hotels/booking/payment");
                    } else {
                        router.navigate("/hotels/booking/confirm");
                    }
                }
            }).catch((error: any) => {
            // console.log(error);
                dispatch(toggleDisabledNav(false));
                dispatch(validateBookingFailure(error));
            });
    };
}

function savePassengerDetails(): IAppActions {
    return ({
        type: HotelBookingTypes.SAVE_PASSENGER_DETAILS
    });
}

function initSearch(): IAppActions {
    return ({
        type: HotelSearchTypes.INIT_SEARCH
    });
}

export function cancelAndSearchAgain(route: string | Path = "/hotels/search", saveDetails = false): CommonActionReturnType {
    return (dispatch) => {
        if (saveDetails) {
            dispatch(savePassengerDetails());
        }

        dispatch(initSearch());

        let newRouterPath = "/hotels/search";
        if (typeof route === "string") {
            newRouterPath = route;
        } else if (route) {
            newRouterPath = route.pathname;
        }

        if (newRouterPath === "/hotels/search") {
            dispatch(searchUpdate(true, false, false, true));
        } else {
            router.navigate(newRouterPath);
        }
    };
}

export function clearValidationData(): IAppActions {
    return ({type: HotelBookingTypes.CLEAR_VALIDATION_DATA});
}

export function clearBookingData(): IAppActions {
    return ({type: HotelBookingTypes.CLEAR_BOOKING_DATA});
}

export function refillPassengerDetails(): IAppActions {
    return ({type: HotelBookingTypes.REFILL_PASSENGER_DETAILS});
}

function acceptChanges(): IAppActions {
    return ({
        type: HotelBookingTypes.ACCEPT_BOOKING_CHANGES
    });
}

export function acceptBookingChanges(canMakeHotelBookingRequest = false): CommonActionReturnType {
    return (dispatch) => {
        dispatch(acceptChanges());

        if (canMakeHotelBookingRequest) {
            dispatch(makeHotelBookingRequest());
        } else {
            router.navigate("/hotels/booking", {replace: true});
        }
    };
}

function confirmDuplicate(): IAppActions {
    return ({
        type: HotelBookingTypes.CONFIRM_DUPLICATE_BOOKING
    });
}

export function confirmDuplicateBooking(): CommonActionReturnType {
    return (dispatch, getState) => {
        if (getState().hotelBooking.firstTimeChanges) {
            router.navigate("/hotels/booking/payment", {replace: true});
        } else {
            dispatch(makeHotelBookingRequest());
        }

        dispatch(confirmDuplicate());
    };
}

export function handleContactInfo({target: {value, name}}: ChangeEvent<HTMLInputElement>): IAppActions {
    return ({
        type: HotelBookingTypes.HANDLE_CONTACT_INFO,
        value,
        name
    });
}

export function handleLateArrival(lateArrivalTime: string): IAppActions {
    return ({
        type: HotelBookingTypes.HANDLE_LATE_ARRIVAL,
        lateArrivalTime
    });
}

export function handleMemo({target: {value}}: ChangeEvent<HTMLInputElement>): IAppActions {
    return ({
        type: HotelBookingTypes.HANDLE_MEMO,
        value
    });
}

export function handleSpecialRequests({target: {value, name}}: {
    target: { value: string | boolean, name: string }
}): IAppActions {
    return ({
        type: HotelBookingTypes.HANDLE_SPECIAL_REQUESTS,
        value,
        name
    });
}

export function handleAdults({
    target: {
        value,
        name
    }
}: ChangeEvent<HTMLInputElement>, roomNo: number, personIndex: number): IAppActions {
    return ({
        type: HotelBookingTypes.HANDLE_ADULTS,
        value,
        name,
        roomNo,
        personIndex
    });
}

export function handleChildren({
    target: {
        value,
        name
    }
}: ChangeEvent<HTMLInputElement>, roomNo: number, personIndex: number): IAppActions {
    return ({
        type: HotelBookingTypes.HANDLE_CHILDREN,
        value,
        name,
        roomNo,
        personIndex
    });
}

export function handleCheckInLeadPassengerName(leadPassengerName: string): IAppActions {
    return ({
        type: HotelBookingTypes.HANDLE_CHECK_IN_LEAD_PASSENGER_NAME,
        name: leadPassengerName
    });
}

export function handleCheckInLeadPassengerPhone(phone: string): IAppActions {
    return ({
        type: HotelBookingTypes.HANDLE_CHECK_IN_LEAD_PASSENGER_PHONE_NUMBER,
        phone: phone
    });
}

export function handleCheckInArrivalTime(checkInArrivalTime: string | undefined): IAppActions {
    return ({
        type: HotelBookingTypes.HANDLE_CHECK_IN_ARRIVAL_TIME,
        checkInArrivalTime
    });
}

export function handleCheckInPhoneCountryCode(code: string): IAppActions {
    return ({
        type: HotelBookingTypes.HANDLE_CHECK_IN_PHONE_COUNTRY_CODE,
        countryCode: code
    });
}

export function toggleCheckInEnterLater(): IAppActions {
    return ({
        type: HotelBookingTypes.TOGGLE_CHECK_IN_ENTER_LATER
    });
}

export function toggleFlightDetails(enabled: boolean): IAppActions {
    return ({
        type: HotelBookingTypes.TOGGLE_FLIGHT_DETAILS,
        enabled
    });
}

export function handleFlightAirportFromCode(code: string, departure: boolean): IAppActions {
    return ({
        type: HotelBookingTypes.HANDLE_FLIGHT_DETAILS_CODE_FROM,
        departure,
        code
    });
}

export function handleFlightAirportToCode(code: string, departure: boolean): IAppActions {
    return ({
        type: HotelBookingTypes.HANDLE_FLIGHT_DETAILS_CODE_TO,
        departure,
        code
    });
}

export function handleFlightDate(date: string, departure: boolean): IAppActions {
    return ({
        type: HotelBookingTypes.HANDLE_FLIGHT_DETAILS_DATE,
        departure,
        date
    });
}

export function handleFlightTime(time: string, departure: boolean): IAppActions {
    return ({
        type: HotelBookingTypes.HANDLE_FLIGHT_DETAILS_TIME,
        departure,
        time
    });
}

export function handleFlightNumber(number: string, departure: boolean): IAppActions {
    return ({
        type: HotelBookingTypes.HANDLE_FLIGHT_DETAILS_NUMBER,
        departure,
        number
    });
}