import React, {
    memo, useCallback
} from "react";
import {useTranslation} from "react-i18next";
import createBlobAndDownload from "utils/createBlobAndDownload";
import {ReactComponent as DownloadIcon} from "assets/icons/download.svg";
import _ from "lodash";
import styles from "./VouchersPage.module.scss";
import {PaymentVoucherPropTypes} from "proptypes/PropTypeObjects";
import {getVoucherPDF, getVouchersXLS} from "api/voucherAPI";
import {
    TableHeading
} from "components/base/ResponsiveTable/ResponsiveTable.base";
import {getPriceWithCurrencySymbol} from "@hotelston_web_frontend_utils/currency/getPriceWithCurrency";
import Tippy from "../../../../components/common/Tippy/Tippy";
import {useLocale} from "components/utils/withLocalesAndCurrencies";
import TablePageTemplate, {TablePageTableRow} from "../../../../components/base/TablePageTemplate/TablePageTemplate";
import createLuxonDate from "../../../../utils/date/createLuxonDate"
import {TFunction} from "i18next";
import Currency from "@hotelston_web_frontend_parent/constants/currency";
import globalAxiosController from "api/axios/globalAxiosController"

const headingsTexts = {
    reference: "voucher_no",
    issueDate: "issue_date",
    validTo: "expire_date",
    amount: "sum",
    unusedAmount: "balance",
    usedFor: "used"
    // download: "download"
};

function getValue(value: string | number | string[], key: string, id: number, rowInfo: {reference: string}[], getRowPDF: (reference: string) => void, t: TFunction) {
    switch (key) {
    case "unusedAmount":
        return (<div style={{textAlign: "right"}}>{getPriceWithCurrencySymbol(value as number, Currency.EUR)}</div>);
    case "amount":
        return (<div style={{textAlign: "right"}}>{getPriceWithCurrencySymbol(value as number, Currency.EUR)}</div>);
    case "issueDate":
        return (
            <div style={{textAlign: "right"}}>{value ? createLuxonDate(value).toFormat("yyyy-MM-dd") : ""}</div>
        );
    case "validTo":
        return (
            <div style={{textAlign: "right"}}>{value ? createLuxonDate(value).toFormat("yyyy-MM-dd") : ""}</div>
        );
    case "download":
        return (
            <span className={styles.DownloadButton} onClick={() => getRowPDF(rowInfo[id].reference)}>
                <div className={styles.Text}>{t("vo_t_download")}</div>
                <DownloadIcon className={styles.DownloadIcon} />
            </span>
        );
    case "usedFor":
        if ((value as string[]).length > 1) {
            return (
                <Tippy
                    trigger="click"
                    interactive
                    hideOnClick
                    // open={true}
                    content={(
                        <div className={styles.Tooltip}>
                            {(value as string[]).map((item, key) => (key > 0 ? <div key={key}>{item}</div> : <div />))}
                        </div>
                    )}
                >
                    <span className={styles.TooltipSpan}>{`${(value as string[])[0]},`}
                        <div className={styles.TooltipButton}>{`+${(value as string[]).length - 1}`}</div>
                    </span>
                </Tippy>
            );
            // return `${value[0]}, +${value.length - 1}`
        }
        return (value as string[])[0];
    default:
        return (<span>{value}</span>);
    }
}

export type TableProps = {
    rowInfo?: PaymentVoucherPropTypes[]
    balance: number;
    loaded?: boolean;
};

function VouchersPage({
    rowInfo,
    balance,
    loaded
}: TableProps) {
    const {t} = useTranslation();

    const getRowPDF = useCallback((reference: string) => {
        void globalAxiosController.addRequest(getVoucherPDF(reference))
            .then((res) => {
                createBlobAndDownload(res, "application/pdf", `${reference}.pdf`, true);
            });
    }, []);

    const columnsSorted = [
        {
            key: "reference",
            field: "reference",
            enabled: true,
            order: 0
        }, {
            key: "issueDate",
            field: "issueDate",
            enabled: true,
            order: 1
        }, {
            key: "validTo",
            field: "validTo",
            enabled: true,
            order: 2
        }, {
            key: "amount",
            field: "amount",
            enabled: true,
            order: 3
        }, {
            key: "unusedAmount",
            field: "unusedAmount",
            enabled: true,
            order: 4
        }, {
            key: "usedFor",
            field: "usedFor",
            enabled: true,
            order: 5
        }, {
            key: "download",
            field: "download",
            enabled: false,
            order: 6
        }
    ];

    const firstColumnSticky = columnsSorted.length > 1;
    const tHeadings = columnsSorted.map(({
        field,
        enabled
    }) => ({
        element: _.get(headingsTexts, field) ? t("vo_t_" + _.get(headingsTexts, field)) : "",
        sortable: enabled,
        label: _.get(headingsTexts, field) ? t("vo_t_" + _.get(headingsTexts, field)) : "",
        style: field !== "reference" && field !== "usedFor" ? {display: "block", textAlign: "right"} : undefined
    }) as TableHeading);

    const tRows = rowInfo?.map((data: any, key: number) => ({
        variant: "Normal",
        tDatas: columnsSorted.map(({field}) => ({
            element: (getValue(_.get<any, string>(data, field), field, key, rowInfo, getRowPDF, t)),
            actionable: false,
            rawValue: _.get<any, string>(data, field)
        }))
    })) as TablePageTableRow[];

    // useEffect(() => {
    //     if (rowInfo && rowInfo.length > 0) {
    //         handlePageNr(pageNr, rowInfo, rowInfo);
    //     }
    // }, [rowInfo]);
    //
    // useEffect(() => {
    //     handlePageNr(pageNr, sortedResponse);
    // }, [pageSize]);

    const balanceDec = (balance - Math.floor(balance)).toFixed(2);
    const locale = useLocale();

    const exportVouchers = useCallback(() => {
        void globalAxiosController.addRequest(getVouchersXLS(locale))
            .then((res) => {
                createBlobAndDownload(
                    res,
                    "application/vnd.ms-excel",
                    "vouchers.xls",
                    true
                );
            });
    }, [locale]);

    return (
        <TablePageTemplate
            title={(
                <>
                    <h1>{`${t("vo_vp_total_vouchers")}:`}</h1>

                    <h1 className={styles.Discount}>
                        {getPriceWithCurrencySymbol(balance, Currency.EUR).split(".")[0]}

                        <span className={styles.DiscountGrey}>
                            {`.${balance === 0 ? "00" : ((balanceDec + "").split(".")[1])}`}
                        </span>
                    </h1>
                </>
            )}
            actions={(
                <div className={styles.DownloadButton} onClick={exportVouchers}>
                    <span>{t("vo_t_export_xls")}</span>
                    <DownloadIcon
                        className={styles.DownloadIcon}
                    />
                </div>
            )}
            tHeadings={tHeadings}
            tRows={tRows}
            tableSettings={{
                className: styles.Table,
                firstColumnSticky,
                transparent: true
            }}
            loading={!rowInfo}
        />
    );
}

export default memo(VouchersPage);
