import React, {ReactElement} from "react";
import CustomMarkers from "./CustomMarkers";
import OneActiveSubject from "../../../../../../utils/generic/oneActiveSubject";
import MapMarkerProps from "views/Hotels/SearchResults/DetailedView/MapContainer/MapMarkerProps"

export type MarkererClustererProps = {
    dataForMarkers?: Map<number, MapMarkerProps>;
    singleMarkerSubject: OneActiveSubject<void, number>;
};

const MarkererClusterer = ({
    dataForMarkers,
    singleMarkerSubject
}: MarkererClustererProps): ReactElement => (
    <CustomMarkers
        dataForMarkers={dataForMarkers || new Map()}
        markersSubject={singleMarkerSubject}
    />
);

export default React.memo(MarkererClusterer);