import {LoginErrorType} from "../proptypes/PropTypeObjects";

export default class LoginError extends Error {
    public type: LoginErrorType;

    constructor(type: LoginErrorType) {
        super(type);
        this.type = type;
        this.name = "LoginError";
    }
}