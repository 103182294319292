import React, {ReactElement, useCallback, useMemo, useRef, useState} from "react"
import "react-image-gallery/styles/css/image-gallery.css"
import ImageGallery, {ReactImageGalleryItem} from "react-image-gallery"
import {useTranslation} from "react-i18next"
import "./LazyImageSlider.scss"
import {Link} from "react-router-dom"
import {ReactComponent as NoPhoto} from "assets/images/no-photo-placeholder.svg"
import _ from "lodash"
import {ReactComponent as CollapseIcon} from "../../../assets/icons/collapse.svg"
import {ReactComponent as ExpandIcon} from "../../../assets/icons/expand.svg"
import ImageThatDisappearsOnError from "../ImageThatDisappearsOnError"
import ActionIcon, {ActionIconType} from "../ActionIcon/ActionIcon"
import {ImageLoadingTypeEnum} from "../ImageThatDisappearsOnError/ImageThatDisappearsOnError"
import isAppleWebkit from "utils/detection/isAppleWebkit"
import cx from "classnames"

export type LazyImageSliderImage = {
    original?: string,
    thumbnail?: string,
    renderItem: () => ReactElement,
    renderThumbInner: () => ReactElement
};

export type LazyImageSliderGalleryImage = ReactImageGalleryItem & {
    renderItem: () => ReactElement,
    renderItemInner: () => ReactElement,
    renderThumbnail: () => ReactElement,

    renderedValue: ReactElement,
    index: number
};

export type LazyImageSliderLink = {
    pathname: string;
    search: string;
};

export type LazyImageSliderProps = {
    images?: LazyImageSliderImage[];
    showThumbnails?: boolean;
    showFullscreenButton?: boolean;
    showImageMessage?: boolean,
    className?: string;
    link?: LazyImageSliderLink;
    disableKeyDown?: boolean;
};

export const LazyImageSlider = (props: LazyImageSliderProps): ReactElement => {
    const {
        images,
        link,
        showFullscreenButton,
        showImageMessage,
        className,
        disableKeyDown = true
    } = props;
    let {showThumbnails} = props;
    const [warnAboutImages, setWarnAboutImages] = useState<boolean>(true);
    const [index, setIndex] = useState<number>(0);
    const imageGalleryRef = useRef<any>(null);
    const {t} = useTranslation();

    const appleWebkit = isAppleWebkit();

    if (showThumbnails === undefined) {
        showThumbnails = true;
    }

    const galleryImages = useMemo<ReactImageGalleryItem[]>(() => {
        const itemRenderer = (item: LazyImageSliderGalleryImage) => {
            const total = galleryImages.length;

            if (!item || !imageGalleryRef.current) {
                return null;
            }

            if (item.original && item.renderedValue) {
                return item.renderedValue;
            }

            const currIndex = item.index;
            const galleryIndex = imageGalleryRef.current.getCurrentIndex();

            if (currIndex !== galleryIndex &&
                !(currIndex === 0 && galleryIndex === 1) &&
                !(currIndex === total && galleryIndex === total - 1) &&
                !(galleryIndex !== 0 && currIndex === +galleryIndex - 1) &&
                !(galleryIndex !== total && currIndex === +galleryIndex + 1)) {
                return null;
            }

            if (!item.renderedValue) {
                let renderedValue;
                if (item.original && link) {
                    renderedValue = (
                        <div className="image-gallery-image"><Link target="_blank" to={link}><ImageThatDisappearsOnError
                            loading={appleWebkit ? ImageLoadingTypeEnum.EAGER : ImageLoadingTypeEnum.LAZY}
                            src={item.original} alt=""
                        />
                                                             </Link>
                        </div>);
                } else if (item.original) {
                    renderedValue = (<div className="image-gallery-image"><ImageThatDisappearsOnError
                        loading={appleWebkit ? ImageLoadingTypeEnum.EAGER : ImageLoadingTypeEnum.LAZY}
                        src={item.original} alt=""
                    />
                                     </div>);
                } else {
                    renderedValue = item.renderItemInner();
                }

                _.set(item, "renderedValue", renderedValue);
            }

            return item.renderedValue;
        };

        if (!images || images.length === 0) {
            return [{
                original: "",
                thumbnail: "",
                renderItem: () => <NoPhoto/>,
                renderThumbInner: undefined
            }] as ReactImageGalleryItem[];
        }

        return images.map((item, index) => ({
            original: item.original,
            thumbnail: item.thumbnail,
            renderItem: itemRenderer,
            renderItemInner: item.renderItem,
            renderThumbInner: item.renderThumbInner,

            rendered: false,
            index: index
        })) as ReactImageGalleryItem[];
    }, [images, link]);

    const renderLeftNav = useCallback((onClick: React.MouseEventHandler<HTMLElement>, disabled: boolean) => (
        <button type="button" className="image-gallery-custom-left-nav" disabled={disabled} onClick={onClick}/>
    ), []);

    const renderRightNav = useCallback((onClick: React.MouseEventHandler<HTMLElement>, disabled: boolean) => (
        <button type="button" className="image-gallery-custom-right-nav" disabled={disabled} onClick={onClick}/>
    ), []);

    const renderFullscreenButton = useCallback((onClick: React.MouseEventHandler<any>, isFullscreen: any) => (
        isFullscreen ? (
            <CollapseIcon className="Collapse image-gallery-fullscreen-button" onClick={onClick}/>
        ) : (
            <ExpandIcon className="expand image-gallery-fullscreen-button" onClick={onClick}/>
        )
    ), []);

    // const renderCustomControls = useCallback(() => {
    //     if (warnAboutImages) {
    //         return (
    //             <div className="image-gallery-custom-message">
    //                 <span className="message-text">
    //                     {t("h_h_h_hotel_room_photos_notice")}
    //                 </span>
    //                 <span onClick={() => setWarnAboutImages(false)} className="close">
    //                     <ActionIcon type={ActionIconType.CLOSE} />
    //                 </span>
    //             </div>
    //         );
    //     }
    //     return undefined;
    // }, [t, warnAboutImages]);

    const thumbnailRendererInner = useCallback((image: ReactImageGalleryItem) => (
        <div className="image-gallery-thumbnail-inner">
            <ImageThatDisappearsOnError
                className="image-gallery-thumbnail-image"
                loading={appleWebkit ? ImageLoadingTypeEnum.EAGER : ImageLoadingTypeEnum.LAZY}
                src={image.thumbnail} alt=""
            />
        </div>
    ), [appleWebkit]);

    return (
        <>
            <ImageGallery
                additionalClass={className}
                showIndex={false}
                startIndex={index}
                onBeforeSlide={(newIndex) => setIndex(newIndex)}
                infinite
                lazyLoad
                showThumbnails={showThumbnails && !_.isEmpty(images)}
                showFullscreenButton={!!showFullscreenButton}
                showPlayButton={false}
                showNav
                isRTL={false}
                disableKeyDown={disableKeyDown}
                autoPlay={false}
                thumbnailPosition="bottom"
                slideDuration={0}
                renderFullscreenButton={showFullscreenButton ? renderFullscreenButton : undefined}
                renderLeftNav={renderLeftNav}
                renderRightNav={renderRightNav}
                renderThumbInner={thumbnailRendererInner}
                // renderCustomControls={showImageMessage ? renderCustomControls : undefined}
                items={galleryImages}
                stopPropagation={true}
                ref={(ref: any) => {
                    imageGalleryRef.current = ref;
                }}
            />

            {showImageMessage && warnAboutImages && (
                <div className={cx("image-gallery-custom-message")}>
                    <span className="message-text">
                        {t("h_h_h_hotel_room_photos_notice")}
                    </span>

                    <span onClick={() => setWarnAboutImages(false)} className="close">
                        <ActionIcon type={ActionIconType.CLOSE}/>
                    </span>
                </div>
            )}
        </>
    );
};