import React, {
    ReactElement, useReducer, useRef, useState
} from "react";
import Input from "components/base/Input";
import CustomCheckBox from "components/base/Input/CustomCheckBox";
import {ReactComponent as SendMailIcon} from "assets/icons/send mail.svg";
import {useTranslation} from "react-i18next";
import ConfirmationModal from "components/common/ConfirmationModal";
import {usePinnedOffersSendEmailAction} from "components/utils/withPinnedOffers";
import styles from "./SendEmailModal.module.scss";
import {
    EmailHotelPinnedOffers,
    HotelSearchCriteriaPropTypes,
    RoomOfferPropTypes
} from "proptypes/PropTypeObjects";
import OutlineInput from "../../../base/Input/TextInput/OutlinedInput";
import {EMAIL_PATTERN} from "utils/validateForm";
import useSelectedMarkup from "../../../../utils/hooks/useSelectedMarkup";
import Currency from "@hotelston_web_frontend_parent/constants/currency";

type ActionState = {
    senderEmail: string,
    recipientEmail: string,
    sendCopy: boolean,
    subject: string,
    message: string,
    includePrice: boolean
};

type ActionProps =
    | { type: "HANDLE_INPUT", name: string, value: string | boolean }
    ;

function reducer(state: ActionState, action: ActionProps): ActionState {
    const {type, name, value} = action;
    switch (type) {
    case "HANDLE_INPUT":
        return {
            ...state,
            [name]: value
        };
    default:
        return state;
    }
}

const initialData = {
    senderEmail: "",
    recipientEmail: "",
    sendCopy: false,
    subject: "",
    message: "",
    includePrice: false
} as ActionState;

type Props = {
    onSent: (...args: any[]) => any;
    onBack: (...args: any[]) => any;
    requestBody: {
        companyMarkup?: number;
        criteria: HotelSearchCriteriaPropTypes;
        currency: Currency;
        hotelOffers: {
            hotelId: number;
            roomOffers: RoomOfferPropTypes[][]
        }[]
    };

    senderEmail?: string;
    subject: string;
    destination: string;
};

function SendEmailModal({
    onSent,
    onBack,
    requestBody,
    senderEmail = "",
    subject,
    destination
}: Props): ReactElement {
    const {t} = useTranslation();
    const {isNETPrice} = useSelectedMarkup();

    const [validateStaredSender, setValidateStartedSender] = useState(false);
    const [validateSender, setValidateSender] = useState(false);
    const inputRefSender = useRef<OutlineInput>(null);

    const [validateStared, setValidateStarted] = useState(false);
    const [validate, setValidate] = useState(false);
    const inputRef = useRef<OutlineInput>(null);

    const {requesting, errors, sendEmail} = usePinnedOffersSendEmailAction(onSent);

    const [localFormData, dispatch] = useReducer(reducer, {
        ...initialData,
        senderEmail,
        subject,
        message: t("pin_sem_sem_message_default", {destination: destination}),
        includePrice: false
    });

    const handleInput = ({
        target: {name, value}
    }: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        dispatch({type: "HANDLE_INPUT", name, value});
    };

    const handleCheckBox = ({
        target: {name, checked}
    }: React.ChangeEvent<HTMLInputElement>) => dispatch({type: "HANDLE_INPUT", name, value: checked});

    const title = (
        <div className={styles.header}>
            <SendMailIcon />
            <div className={styles.title}>{t("pin_sem_sem_send_by_email")}</div>
        </div>
    );

    const content = (
        <div className={styles.Root}>
            <Input
                inputComponentRef={inputRefSender}
                variants={{labelPosition: "outlined"}}
                fullHeight={false}
                inputProps={{
                    name: "senderEmail",
                    autoComplete: "email",
                    required: true,
                    placeholder: t("pin_sem_sem_sender_email"),
                    onChange: (e) => {
                        handleInput(e);
                        if (!validateStaredSender) {
                            setValidateStartedSender(true);
                            setTimeout(() => {
                                setValidateSender(true);
                                inputRefSender.current?.updateValidity();
                            }, 5000);
                        }
                    },
                    type: validate ? "email" : "text",
                    value: localFormData.senderEmail
                }}
            />

            <div className={styles.InputWithCheckbox}>
                <Input
                    inputComponentRef={inputRef}
                    variants={{labelPosition: "outlined"}}
                    fullHeight={false}
                    inputProps={{
                        name: "recipientEmail",
                        autoComplete: "email",
                        required: true,
                        pattern: validate ? EMAIL_PATTERN : undefined,
                        placeholder: t("pin_sem_sem_recipient_email"),
                        onChange: (e) => {
                            handleInput(e);
                            if (!validateStared) {
                                setValidateStarted(true);
                                setTimeout(() => {
                                    setValidate(true);
                                    inputRef.current?.updateValidity();
                                }, 5000);
                            }
                        },
                        type: validate ? "email" : "text",
                        value: localFormData.recipientEmail
                    }}
                />

                <div className={styles.CheckboxWrapper}>
                    <CustomCheckBox
                        label={t("pin_sem_sem_send_copy_to_myself")}
                        inputProps={{
                            name: "sendCopy",
                            checked: localFormData.sendCopy,
                            onChange: handleCheckBox
                        }}
                    />
                </div>
            </div>

            <Input
                fullHeight={false}
                variants={{labelPosition: "outlined"}}
                inputProps={{
                    name: "subject",
                    autoComplete: "on",
                    required: true,
                    placeholder: t("pin_sem_sem_email_subject"),
                    onChange: handleInput,
                    value: localFormData.subject
                }}
            />

            <textarea
                onChange={handleInput}
                value={localFormData.message}
                name="message"
                placeholder={t("pin_sem_sem_message")}
            />

            {!isNETPrice && (
                <CustomCheckBox
                    label={t("pin_sem_sem_export_include_price")}
                    className={styles.IncludePriceCheckBox}
                    inputProps={{
                        name: "includePrice",
                        checked: localFormData.includePrice,
                        onChange: handleCheckBox
                    }}
                />
            )}
        </div>
    );

    return (
        <ConfirmationModal
            onConfirm={() => sendEmail({
                ...requestBody,
                emailDetails: localFormData
            } as EmailHotelPinnedOffers, localFormData.includePrice)}

            onDecline={onBack}
            config={{
                variants: {
                    type: "normal"
                },
                title,
                content,
                isForm: true,
                validateForm: true,
                declineButtonText: t("pin_sem_sem_back_button"),
                confirmButtonText: t("pin_sem_sem_send_description_button"),
                confirmButtonStyle: {
                    height: "40px"
                },
                declineButtonStyle: {
                    height: "40px"
                },
                errors,
                spinner: requesting
            }}
        />
    );
}

export default SendEmailModal;
