import {useTranslation} from "react-i18next";
import React, {ReactElement} from "react";
import {Credentials} from "../../../../../proptypes/PropTypeObjects";
import styles from "./YourInformation.module.scss";

export default function YourInformation({
    companyName,
    city,
    companyCode,
    country,
    email,
    phone,
    postalCode,
    signee,
    signeePosition,
    street
}: Credentials): ReactElement {
    const {t} = useTranslation();

    return (
        <div className={styles.Root}>
            <h4 className={styles.Heading}>{t("mc_a_a_your_information")}</h4>

            <dl className={styles.Box}>
                <dt>{t("mc_a_a_companyName")}:</dt>
                <dd>{companyName}</dd>

                <dt>{t("mc_a_a_companyNumber")}:</dt>
                <dd>{companyCode}</dd>

                <dt>{t("mc_a_a_address")}:</dt>
                <dd>
                    {street && street + ", "}{" "}
                    {postalCode && postalCode + ", "}{" "}
                    {city && city + ", "}{" "}
                    {country && country}
                </dd>

                <dt>{t("mc_a_a_phone")}:</dt>
                <dd>{phone}</dd>

                <dt>{t("mc_a_a_email")}:</dt>
                <dd>{email}</dd>

                <dt>{t("mc_a_a_signee")}:</dt>
                <dd>{signee}</dd>

                <dt>{t("mc_a_a_signeePosition")}:</dt>
                <dd>{signeePosition}</dd>
            </dl>
        </div>
    );
}