import React, {ReactElement} from "react"
import PageTemplate from "components/base/PageTemplate/PageTemplate";
import HeaderContainer from "components/base/Header";
import Righter from "components/base/Righter";
import Lefter from "components/base/Lefter";
import Footer from "components/base/Footer";
import {Route, Routes} from "react-router-dom"
import MyBookingsContainer from "./MyBookingsContainer";
import BookingContainer from "./Booking";
import Overpayment from "./Overpayment";
import ReconcileContainer from "./Reconcile";
import PaymentContainer from "./Payment";
import PaymentStatus from "./Payment/PaymentStatus";
import TransferAmendments from "./TransferAmendments";
import ErrorBoundary from "../../components/utils/ErrorBoundary/ErrorBoundary";
import PaymentLinkContainer from "../PaymentStatus/PaymentLink/PaymentLinkContainer";
import SplitPaymentContainer from "views/MyBookings/SplitPayment/SplitPaymentContainer";

const MyBookings = (): ReactElement => (
    <PageTemplate
        header={<HeaderContainer/>}
        footer={<Footer/>}
        lefter={<Lefter/>}
        righter={<Righter/>}
    >
        <ErrorBoundary>
            <Routes>
                <Route
                    path="/"
                    element={<MyBookingsContainer/>}
                />

                <Route
                    path="/booking/:id"
                    element={<BookingContainer/>}
                />

                <Route
                    path="/booking/:id/amendments/transfer"
                    element={<TransferAmendments/>}
                />

                <Route
                    path="/booking/:id/amendments/hotel"
                    element={<TransferAmendments/>}
                />

                <Route
                    path="/booking/:id/overpayment"
                    element={<Overpayment/>}
                />

                <Route
                    path="/booking/:id/overpayment/reconcile"
                    element={<ReconcileContainer/>}
                />

                <Route
                    path="/booking/:id/payment/:status"
                    element={<PaymentStatus/>}
                />

                <Route
                    path="/booking/:id/payment/:status/payment-link"
                    element={<PaymentLinkContainer/>}
                />

                <Route
                    path="/booking/:id/payment"
                    // element={allowedInPaymentPages ? <PaymentContainer /> : <Navigate to="/my-bookings" />}
                    element={<PaymentContainer/>}
                />

                <Route
                    path="/booking/:id/split-payment"
                    element={<SplitPaymentContainer/>}
                />

                <Route
                    path="/payment/:status"
                    element={<PaymentStatus/>}
                />

                <Route
                    path="/payment/:status/payment-link"
                    element={<PaymentLinkContainer/>}
                />

                <Route
                    path="/payment"
                    element={<PaymentContainer/>}
                />

                <Route
                    path="/booking/split-payment"
                    element={<SplitPaymentContainer/>}
                />

                {/*<Route*/}
                {/*    path="/my-bookings/booking/:id/payment"*/}
                {/*    element={allowedInPaymentPages ? <Navigate to="/my-bookings/payment" /> : <Navigate to={lastBookingLocation} />}*/}
                {/*/>*/}
            </Routes>
        </ErrorBoundary>
    </PageTemplate>
)

export default MyBookings;
