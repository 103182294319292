import {ReduxHotelSearchPropTypes} from "../../proptypes/PropTypeRedux"
import HotelSearchTypes from "../constants/hotelSearch.constants"
import HotelSearchActions from "./actions/hotelSearch.reducer.actions"
import createLuxonDate from "../../utils/date/createLuxonDate"
import {DETECTED_BROWSER_TYPE, DETECTED_DEVICE_TYPE} from "appBootstrap";

export const INITIAL_STATE = {
    submitedSearch: true,
    isSearching: false,
    isUpdating: false,
    showHotelId: false,
    error: undefined,
    currentDestination: undefined,
    destinationInput: "",
    stateFormData: {
        checkIn: createLuxonDate().plus({day: 7}).toFormat("yyyy-MM-dd"),
        checkOut: createLuxonDate().plus({day: 10}).toFormat("yyyy-MM-dd"),
        rooms: [
            {
                adults: 2,
                children: []
            }
        ],
        clientNationality: undefined
    },
    formData: {
        checkIn: createLuxonDate().plus({day: 7}).toFormat("yyyy-MM-dd"),
        checkOut: createLuxonDate().plus({day: 10}).toFormat("yyyy-MM-dd"),
        clientNationality: undefined,
        hotelId: undefined,
        cityId: undefined,
        airportId: undefined,
        radius: undefined,
        rooms: [
            {
                adults: 2,
                children: []
            }
        ],
        browserType: DETECTED_BROWSER_TYPE,
        deviceType: DETECTED_DEVICE_TYPE
    },
    formParams: {
        logRequests: undefined,
        multiprovider: undefined,
        ignoreSelectBestOffers: undefined
    },
    recentSearches: [],
    savedSearches: [],
} as ReduxHotelSearchPropTypes

// update search after search to do not show searching screen START_SEARCH_UPDATE , isUpdating used in searchResults reducer?
export default (state = INITIAL_STATE, action: HotelSearchActions): ReduxHotelSearchPropTypes => {
    switch (action.type) {
    case HotelSearchTypes.INIT_SEARCH:
        return {
            ...INITIAL_STATE,
            stateFormData: {
                ...state.stateFormData,
                hotelId: state.stateFormData?.hotelId ? state.stateFormData?.hotelId : undefined,
                clientNationality: state.stateFormData.clientNationality,
                checkIn: state.stateFormData.checkIn,
                checkOut: state.stateFormData.checkOut
            },
            formData: {
                ...state.formData,
                hotelId: state.formData?.cityId ? state.formData?.hotelId : undefined,
                clientNationality: state.formData.clientNationality,
                checkIn: state.formData.checkIn,
                checkOut: state.formData.checkOut
            },
            nationalityInput: state.nationalityInput,
            destinationInput: state.destinationInput,
            currentDestination: state.currentDestination,
            error: undefined
            // formData:{
            //     ...INITIAL_STATE.formData,
            //     clientNationality:state.formData.clientNationality
            // },
            // nationalityInput:state.nationalityInput
        }
    case HotelSearchTypes.SUBMIT_SEARCH:
        return {
            ...state,
            submitedSearch: true
        }
    case HotelSearchTypes.SET_HOTEL_ID:
        return {
            ...state,
            stateFormData: {
                ...state.stateFormData,
                hotelId: action.hotelId
            },
            formData: {
                ...state.formData,
                hotelId: action.hotelId
            }
        }

    case HotelSearchTypes.START_SEARCH:
        return {
            ...state,
            formData: {...state.formData, ...action.stateFormData},
            isSearching: true
        }
    case HotelSearchTypes.UPDATE_SEARCH:
        return {
            ...state,
            formData: {...state.formData, ...action.stateFormData},
            isSearching: true,
            isUpdating: true
        }
    case HotelSearchTypes.GET_RECENT_SEARCHES:
        return {
            ...state,
            recentSearches: action.recentSearches || []
        }
    case HotelSearchTypes.GET_SAVED_SEARCHES:
        return {
            ...state,
            savedSearches: action.savedSearches || []
        }
    case HotelSearchTypes.GET_DATA_SUCCESS:
        return {
            ...state,
            isSearching: false,
            isUpdating: false,
            error: undefined
        }
    case HotelSearchTypes.GET_DATA_FAILURE:
        return {
            ...state,
            error: action.error,
            isSearching: false,
            isUpdating: false
        }
    case HotelSearchTypes.CHANGE_CURRENT_DESTINATION:
        return {
            ...state,
            currentDestination: action.currentDestination
        }
    case HotelSearchTypes.HANDLE_FORM_DATA:
        return {
            ...state,
            submitedSearch: false,
            formData: action.formData
        }
    case HotelSearchTypes.UPDATE_FORM_DATA:
        return {
            ...state,
            submitedSearch: false,
            stateFormData: action.stateFormData
        }
    case HotelSearchTypes.HANDLE_INPUT_CHANGE:
        return {
            ...state,
            submitedSearch: false,
            [action.inputName]: action.inputValue
        }
    case HotelSearchTypes.ADD_ROOM:
        return {
            ...state,
            submitedSearch: false,
            stateFormData: {
                ...state.stateFormData,
                rooms: state.stateFormData.rooms.length < 8 ? [
                    ...state.stateFormData.rooms,
                    {
                        adults: 2,
                        children: []
                    }
                ] : state.stateFormData.rooms
            }
        }
    case HotelSearchTypes.UPDATE_STATE_FORM:
        return {
            ...state,
            submitedSearch: false,
            stateFormData: {
                ...state.stateFormData,
                ...action.stateFormData
            }
        }

    case HotelSearchTypes.REMOVE_ROOM:
        return {
            ...state,
            submitedSearch: false,
            stateFormData: {
                ...state.stateFormData,
                rooms: state.stateFormData.rooms.filter((val, i) => i !== action.index)
            }
        }
    case HotelSearchTypes.IGNORE_SELECT_BEST_OFFERS_CHANGE:
        return {
            ...state,
            formParams: {
                ...state.formParams,
                ignoreSelectBestOffers: action.checked
            }
        }
    case HotelSearchTypes.MULTIPROVIDER_CHANGE:
        return {
            ...state,
            formParams: {
                ...state.formParams,
                multiprovider: action.checked
            }
        }
    case HotelSearchTypes.LOG_REQUESTS_CHANGE:
        return {
            ...state,
            formParams: {
                ...state.formParams,
                logRequests: action.checked
            }
        }
    case HotelSearchTypes.TOGGLE_HOTEL_ID:
        return {
            ...state,
            showHotelId: action.showHotelId
        }
    default:
        return state
    }
};
