import React, {Component} from "react";
import BasicDropdown from "components/base/Input/BasicDropdown/BasicDropdown";
import classNames from "classnames";
import {WithTranslation, withTranslation} from "react-i18next";
import ChildrenDropdown from "./ChildrenDropdown";
import styles from "./Roomate.module.scss";
import ActionIcon, {ActionIconType} from "../ActionIcon/ActionIcon";
import {Option} from "../../../proptypes/PropTypeObjects";

const adultNumbers = [
    {value: 1, label: "1"},
    {value: 2, label: "2"},
    {value: 3, label: "3"},
    {value: 4, label: "4"},
    {value: 5, label: "5"},
    {value: 6, label: "6"}
];

const childrenNumbers = [
    {value: 0, label: "0"},
    {value: 1, label: "1"},
    {value: 2, label: "2"},
    {value: 3, label: "3"}
];

const maxChildrenAge = 17;

type Props = WithTranslation & {
    data: { adults: number, children: number[] }
    index: number;
    count: number;
    handleRemoveRoom: (index: number) => void;
    sidebar?: boolean,
    hotelView?: boolean,
    handleChildrenChange: ((i: number, children: number[]) => void);
    handleAdultsChange: ((i: number, adults: number) => void);
    className?: string;
};

class Roomate extends Component<Props> {
    handleChildren = (children: number[]) => {
        const {handleChildrenChange, index} = this.props;

        handleChildrenChange(index, children);
    };

    handleAdults = (adults: any) => {
        const {handleAdultsChange, index} = this.props;

        handleAdultsChange(index, adults);
    };

    render() {
        const {
            data,
            index,
            count,
            handleRemoveRoom,
            sidebar,
            hotelView,
            t,

            className
        } = this.props;
        const roomateClass = classNames(
            styles.root,
            sidebar && styles.sidebar,
            hotelView && styles.hotelView,
            className
        );

        // @ts-expect-error ts-migrate(2554) FIXME: Expected 1-3 arguments, but got 0.
        const childrenAges = Array(maxChildrenAge + 1).fill().map((_, i) => ({
            value: i,
            label: t("room_r_years_old", {count: i, age: i})
        })) as Option<number, string>[];

        return (
            <div className={roomateClass}>
                <div className={styles.title}>
                    {count > 1 && (
                        <span
                            onClick={() => handleRemoveRoom(index)}
                            className={styles.removeItem}
                        >
                            <ActionIcon type={ActionIconType.CLOSE} />
                        </span>
                    )}
                    {t("room_r_room")} {index + 1}
                </div>
                <div className={styles.pax}>
                    <div className={styles.adults}>
                        <div className={styles.label}>{sidebar ? t("room_r_no_adults") + ": " : t("room_r_num_of_adults") + ": "}</div>
                        <BasicDropdown
                            required={true}
                            hideErrors={true}
                            onDropdownChange={this.handleAdults}
                            className="adults-dropdown"
                            dropDownValue={data.adults}
                            dropDownOptions={adultNumbers}
                        />
                    </div>
                    <ChildrenDropdown
                        className={styles.children}
                        sidebar={sidebar}
                        label={sidebar ? t("room_r_no_children") + ": " : t("room_r_num_of_children") + ": "}
                        hotelView={hotelView}
                        handleChildren={this.handleChildren}
                        dropDownValues={data.children}
                        numbers={childrenNumbers}
                        childrenAges={childrenAges}
                    />
                </div>
            </div>
        );
    }
}

export default React.memo(withTranslation()(Roomate));
