import React, {ReactElement} from "react";
import {useTranslation} from "react-i18next";
import {DocumentFile} from "../../../../../proptypes/PropTypeObjects";
import useLocaleDateTimeFormat, {LocaleDateFormatTypeEnum} from "../../../../../components/utils/Internationalization/useLocaleDateTimeFormat";
import styles from "./DownloadFiles.module.scss";
import baseUrl from "../../../../../api/constants/baseURL";
import {ReactComponent as DownloadIcon} from "../../../../../assets/icons/download.svg";
import createLuxonDate from "../../../../../utils/date/createLuxonDate"

export default function DownloadFile({fileName, id, uploaded}: DocumentFile): ReactElement {
    const {t} = useTranslation();
    const dateTimeFormat = useLocaleDateTimeFormat(LocaleDateFormatTypeEnum.DATE_TIME);

    return (
        <div className={styles.Root}>
            <h4 className={styles.Heading}>{t("mc_a_a_download_file")}</h4>

            <dl className={styles.Box}>
                <dt>{t("mc_a_a_file")}:</dt>
                <dd>
                    {fileName}
                    <a rel="noopener noreferrer" target="_blank" className={styles.Download} href={`${baseUrl}/company/document/file/${id}`} download={fileName}>
                        <span>{t("mc_a_a_download")}</span>
                        <DownloadIcon />
                    </a>
                </dd>

                <dt>{t("mc_a_a_created")}:</dt>
                <dd>{createLuxonDate(uploaded).toFormat(dateTimeFormat)}</dd>
            </dl>
        </div>
    );
}