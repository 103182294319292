import React, {ReactElement, useEffect, useState} from "react";
import {shallowEqual} from "react-redux";
import {getOverpaymentOptions, handleUseOverpayment} from "redux/actions/overpayment.actions";
import InfoBox from "components/common/InfoBox";
import {ReactComponent as PiggyIcon} from "assets/icons/piggy bank.svg";
import {ReactComponent as WalletIcon} from "assets/icons/wallet.svg";
import UserButton from "components/base/UserButton";
import withConfirmation, {WithConfirmation} from "components/utils/withConfirmation";
import Spinner from "components/base/Loaders/Spinner";
import {Trans, useTranslation} from "react-i18next";
import {TFunction} from "i18next";
import _ from "lodash";
import styles from "./Overpayment.module.scss";
import {OverPaymentTypeEnumPropTypes} from "proptypes/PropTypeObjects";
import {useAppDispatch, useAppSelector} from "redux/hooks";
import {UserButtonVariant} from "components/base/UserButton/UserButton";
import {getPriceWithCurrencySymbol} from "@hotelston_web_frontend_utils/currency/getPriceWithCurrency";
import BasicDropdown from "../../../components/base/Input/BasicDropdown/BasicDropdown";
import {useNavigate, useParams} from "react-router-dom"
import Currency from "@hotelston_web_frontend_parent/constants/currency";

export function getOverpaymentInfo(type: OverPaymentTypeEnumPropTypes, t: TFunction, bankAccount?: string): {
    title: string,
    name: string
} {
    switch (type) {
    case "TO_HOTELSTON_WALLET":
        return {
            title: t("mb_op_overpayment_to_hotelston_wallet"),
            name: t("mb_op_hotelston_wallet")
        };
    case "RECONCILE":
        return {
            title: t("mb_op_reconcile_overpayment"),
            name: t("mb_op_reconcile")
        };
    case "REFUND":
        return {
            title: t("mb_op_refund_overpayment"),
            // Lol wtf is this:
            // name: t("mb_op_bank_account")
            name: bankAccount || t("mb_op_bank_account")
        };
    default:
        return {
            title: "",
            name: ""
        };
    }
}

type Props = WithConfirmation;

function Overpayment({setModal}: Props): ReactElement {
    const {t} = useTranslation();

    const {id} = useParams();
    const navigate = useNavigate();

    const lastBookingLocation = useAppSelector((state) => state.router.lastLocations.myBookings.lastBookingLocation ||
        "/my-bookings", shallowEqual);

    const {
        requestingOptions,
        requestingUse,
        overpaymentOptions
    } = useAppSelector((state) => state.overpayment, shallowEqual);

    let amount;
    let currency;
    let overPaymentTypes;
    let paymentAccounts;

    if (overpaymentOptions) {
        amount = overpaymentOptions.amount;
        currency = overpaymentOptions.currency;
        overPaymentTypes = overpaymentOptions.overPaymentTypes;
        paymentAccounts = overpaymentOptions.paymentAccounts || [];
    } else {
        amount = 0;
        currency = Currency.EUR;
        overPaymentTypes = [] as OverPaymentTypeEnumPropTypes[];
        paymentAccounts = [] as string[];
    }

    const dispatch = useAppDispatch();
    const [overPaymentType, setOverpaymentType] = useState<OverPaymentTypeEnumPropTypes | undefined>(undefined);
    const [paymentAccount, setPaymentAccount] = useState<string | undefined>(undefined);

    useEffect(() => {
        dispatch(getOverpaymentOptions(Number(id)));
    }, []);

    const amountFormed = getPriceWithCurrencySymbol(amount, currency);

    const paymentAccountsFormed = paymentAccounts.map((acc) => ({
        value: acc,
        label: acc
    }));

    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if (!overPaymentType) {
            return;
        }
        if (overPaymentType === "RECONCILE") {
            navigate(`/my-bookings/booking/${id}/overpayment/reconcile`);
            return;
        }
        const {title, name} = getOverpaymentInfo(overPaymentType, t, paymentAccount);

        setModal({
            onConfirm: () => dispatch(handleUseOverpayment(Number(id), {
                overPaymentType: overPaymentType,
                ...(overPaymentType === "REFUND" && {paymentAccount})
            })),
            onDecline: () => false,
            config: {
                variants: {
                    type: "warn"
                },
                title,
                content: (
                    <span>
                        <Trans
                            i18nKey="mb_op_overpayment_decision_notice"
                            values={{
                                amountFormed,
                                name,
                                id
                            }}
                        >
                            You have decided to transfer your{" "}
                            <strong>{amountFormed}</strong>
                            overpayment for booking TF
                            {id}
                            to your
                            <strong>{name}</strong>.
                            Please confirm your decision or press cancel if you have changed your mind.
                        </Trans>
                    </span>)
            }
        });
    };

    return (
        <div className={styles.Root}>
            {requestingOptions || requestingUse ? (
                <Spinner
                    size="50px"
                    style={{
                        width: "100%",
                        height: "calc(100vh - 120px)"
                    }}
                />
            ) : (
                <form noValidate onSubmit={handleSubmit}>
                    <div className={styles.IconContainer}>
                        <div className={styles.Icon}>
                            <PiggyIcon/>
                        </div>
                    </div>

                    <div className={styles.Box}>
                        <h4 className={styles.Title}>{t("mb_op_overpayment")}</h4>

                        <h4 className={styles.Number}>{amountFormed}</h4>

                        <div className={styles.Note}>{t("mb_op_overpayment_please_decide_notice", {
                            amountFormed,
                            id
                        })}
                        </div>

                        {overPaymentTypes.includes("TO_HOTELSTON_WALLET") && (
                            <InfoBox
                                style={{
                                    marginBottom: 10
                                }}
                                active={overPaymentType === "TO_HOTELSTON_WALLET"}
                                title={t("mb_op_add_to_hotelston_wallet")}
                                message={t("mb_op_add_to_hotelston_wallet_message")}
                                customIcon={<WalletIcon/>}
                                miniIcon
                                onClick={() => {
                                    setOverpaymentType(overPaymentType === "TO_HOTELSTON_WALLET" ? undefined : "TO_HOTELSTON_WALLET");
                                    return true;
                                }}
                                type="custom"
                            >
                                <div className={styles.Content}>
                                    <div className={styles.ContentNote}>
                                        {t("mb_op_transfer_amount_to_hotelston_wallet", {amountFormed})}
                                    </div>
                                </div>
                            </InfoBox>
                        )}

                        {overPaymentTypes.includes("RECONCILE") && (
                            <InfoBox
                                style={{
                                    marginBottom: 10
                                }}
                                active={overPaymentType === "RECONCILE"}
                                title={t("mb_op_reconcile")}
                                message={t("mb_op_overpayment_reconcile_message")}
                                customIcon={<WalletIcon/>}
                                miniIcon
                                onClick={() => {
                                    setOverpaymentType(overPaymentType === "RECONCILE" ? undefined : "RECONCILE");
                                    return true;
                                }}
                                type="custom"
                            >
                                <div className={styles.Content}>
                                    <div className={styles.ContentNote}>
                                        {t("mb_op_overpayment_reconcile_pay_for_another_bookings", {amountFormed})}
                                    </div>
                                </div>
                            </InfoBox>
                        )}

                        {overPaymentTypes.includes("REFUND") && (
                            <InfoBox
                                style={{
                                    marginBottom: 0
                                }}
                                active={overPaymentType === "REFUND"}
                                title={t("mb_op_refund")}
                                message={t("mb_op_refund_overpayment_message")}
                                customIcon={<WalletIcon/>}
                                miniIcon
                                onClick={() => {
                                    setOverpaymentType(overPaymentType === "REFUND" ? undefined : "REFUND");
                                    return true;
                                }}
                                type="custom"
                            >
                                <div className={styles.Content}>
                                    {!_.isEmpty(paymentAccountsFormed) && (
                                        <>
                                            <div
                                                className={styles.ContentNote}
                                            >{t("mb_op_please_select_bank_account")}
                                            </div>
                                            <BasicDropdown<string, string>
                                                className={styles.Dropdown}
                                                // required={true}
                                                hideErrors={true}
                                                dropDownOptions={paymentAccountsFormed || []}
                                                label={t("mb_op_select_bank_account") + ":"}
                                                onDropdownChange={(val) => setPaymentAccount(val)}
                                                dropDownValue={paymentAccount}
                                            />
                                        </>
                                    )}
                                </div>
                            </InfoBox>
                        )}
                    </div>

                    {overPaymentTypes.length > 0 && (
                        <div className={styles.ButtonsWrapper}>
                            <UserButton
                                text={t("mb_op_cancel")}
                                variant={UserButtonVariant.SECONDARY}
                                buttonProps={{
                                    onClick: () => navigate(lastBookingLocation),
                                    type: "button"
                                }}
                            />

                            <UserButton
                                text={t("mb_op_continue")}
                                variant={UserButtonVariant.PRIMARY}
                                disabledButton={!overPaymentType}
                                buttonProps={{
                                    type: "submit"
                                }}
                            />
                        </div>
                    )}
                </form>
            )}
        </div>
    );
}

export default withConfirmation(Overpayment);
