import React, {ReactElement, useCallback, useEffect, useMemo, useState} from "react";
import useCountries from "components/utils/useCountries";
import {useTranslation} from "react-i18next";
import {CountryPropTypes} from "proptypes/PropTypeObjects";
import StandaloneDropdownWithSearch, {
    DropdownWithSearchOption
} from "components/base/Input/DropdownWithSearch/StandaloneDropdownWithSearch";

type Props = {
    className?: string;
    onCountryChange: (country?: CountryPropTypes) => void;
    onCountryInputChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
    currentCountryName?: string;
    countryInputName: string;
    onFocus?: (e: any) => void;
    required?: boolean;
    disabled?: boolean;
    label?: string;
    setDefaultCountry?: boolean;
};

export default function CountriesDropdown({
    onCountryChange = function (country?: CountryPropTypes) {
    },
    onCountryInputChange = function (e: React.ChangeEvent<HTMLInputElement>) {
    },

    currentCountryName = undefined,
    countryInputName = "country",
    onFocus,
    required,
    className,
    disabled,
    label,
    setDefaultCountry = true
}: Props): ReactElement {
    const [canSetDefaultCountry, setCanSetDefaultCountry] = useState(!currentCountryName && setDefaultCountry);
    const {countries, defaultCountry} = useCountries();
    const countriesOptions = useMemo(
        () => countries.map(({id, name}) => new DropdownWithSearchOption(name, id)),
        [countries]
    );

    const [currCountry, setCurrCountry] = useState<number>(0);

    useEffect(() => {
        if (defaultCountry && canSetDefaultCountry) {
            setCanSetDefaultCountry(false);
            onCountryChange(defaultCountry);
            setCurrCountry(defaultCountry.id);
        }
    }, [defaultCountry, canSetDefaultCountry, onCountryChange]);

    useEffect(() => {
        if (currentCountryName) {
            const found = countries.find((country) => country.name === currentCountryName);

            if (found) {
                onCountryChange(found);
                setCurrCountry(found.id);
            }
        }
    }, [countries, currentCountryName, onCountryChange]);

    const {t} = useTranslation();

    const onDropdownChangeCallback = useCallback((value: DropdownWithSearchOption<number>) => {
        onCountryChange(countries.find((country) => country.id === value.value));
    }, [countries, onCountryChange]);

    return (
        <StandaloneDropdownWithSearch
            small
            className={className}
            inputProps={{
                required: required,
                value: currentCountryName,
                name: countryInputName,
                placeholder: label || t("crs_cd_select_country"),
                onChange: onCountryInputChange,
                onFocus: onFocus,
                disabled
            }}
            onValueChange={onDropdownChangeCallback}
            options={countriesOptions}
            currentValue={currCountry}
        />
    );
}
