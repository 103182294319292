import React, {ReactElement} from "react";
import {useTranslation} from "react-i18next";
import ConfirmationModal from "./ConfirmationModal";

export type SuccessModalProps = {
    onClose: () => void;
    successMessage: ReactElement | string;
};

export default function SuccessModal({
    onClose,
    successMessage
}: SuccessModalProps): ReactElement {
    const {t} = useTranslation();

    return (
        <ConfirmationModal
            onConfirm={onClose}
            config={{
                variants: {
                    type: "success",
                    withoutDeclineButton: true
                },
                title: t("cm_sm_success"),
                content: successMessage,
                confirmButtonText: t("cm_sm_close")
            }}
        />
    );
}
