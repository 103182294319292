import React, {ReactElement} from "react";
import InfoBox from "components/common/InfoBox";
import {Link} from "react-router-dom";
import cx from "classnames";
import styles from "./NotificationsDateBox.module.scss";

export function NotificationsDateBoxItem({
    heading,
    body,
    time,
    link,
    setAsRead,
    unread
}: any): ReactElement {
    return (
        <div className={cx(styles.Item, unread && styles.Unread)}>
            <div className={styles.Time}>{time}</div>
            {link ? (
                <Link onClick={() => unread && setAsRead(true)} to={link}>
                    <InfoBox
                        className={styles.InfoBox}
                        title={heading}
                        message={body}
                        type="warn"
                    />
                </Link>
            ) : (
                <InfoBox
                    onClick={() => unread && setAsRead(true)}
                    title={heading}
                    message={body}
                    type="warn"
                />
            )}
        </div>
    );
}

export default function NotificationsDateBox({
    date,
    children
}: any): ReactElement {
    return (
        <div className={styles.Root}>
            <div className={styles.Date}>{date}</div>
            {children}
        </div>
    );
}
