import IAppActions from "../store/store.actions";
import ModalsTypes from "../constants/modals.constants";
import {ReduxModalType} from "../../proptypes/PropTypeObjects";

export function toggleModal(modal?: ReduxModalType, scrollLock = true): IAppActions {
    return {
        type: ModalsTypes.TOGGLE_MODAL,
        modal,
        scrollLock
    };
}

export function toggleScrollLock(scrollLocked: boolean): IAppActions {
    return {
        type: ModalsTypes.TOGGLE_SCROLL_LOCK,
        locked: scrollLocked
    };
}