import React, {
    ReactElement, useCallback, useEffect, useRef
} from "react"
import {Link} from "react-router-dom"
import logoutIcon from "assets/icons/logout.svg"
import systemIcon from "assets/icons/it.svg"
import managementIcon from "assets/icons/key.svg"
import {useTranslation} from "react-i18next"
import cx from "classnames"
import styles from "./UserLefter.module.scss"
import BasicDropdown from "../../Input/BasicDropdown/BasicDropdown"
import EditUserDetails from "./EditUserDetails"
import {
    CompanyMarkupsPropTypes, CompanyPropTypes, CompanyUserPropTypes
} from "proptypes/PropTypeObjects"
import logoImg from "../../../../assets/images/logoHotel.svg"
import {ReactComponent as BarsIcon} from "../../../../assets/icons/bars.svg"
import ActionIcon, {ActionIconType} from "../../../common/ActionIcon/ActionIcon"
import {getPriceWithCurrencySymbol} from "@hotelston_web_frontend_utils/currency/getPriceWithCurrency"
import useClickOutside from "../../../../utils/hooks/useClickOutside"
import oldAppUrl from "../../../../api/constants/oldAppUrl"
import {useLocale} from "../../../utils/withLocalesAndCurrencies"
import SkeletonLoader from "../../../utils/SkeletonLoader/SkeletonLoader"
import useWindowResize from "utils/hooks/useWindowResize"
import Cookies from "js-cookie"
import {DEFAULT_WEB_VERSION_COOKIE_NAME} from "utils/cookie/cookie"
import createLuxonDate from "../../../../utils/date/createLuxonDate"
import {useAppSelector} from "redux/hooks"
import Currency from "@hotelston_web_frontend_parent/constants/currency";

type Props = {
    bookings?: number;
    currency: Currency;
    company: CompanyPropTypes;
    companyMarkups: CompanyMarkupsPropTypes[];
    companyUser: CompanyUserPropTypes;
    wallet?: number;
    walletEnabled?: boolean;
    discountEnabled: boolean;
    currentCompanyMarkupIndex?: number;
    vouchers?: number;
    discounts?: number;
    balance?: number;
    expandedView: boolean;
    expandedModalView: boolean;
    toggleExpandedView: (expanded?: boolean) => void;
    changeCurrentCompanyMarkupIndex: (i?: number) => void;
    minCollapseWidth?: 1290;
    modalView: boolean;
    handleModalView: (modal: boolean) => void;
    forceModal?: boolean;
    subAgent: boolean;
};

const UserLefter = ({
    bookings,
    currency,
    company,
    companyMarkups,
    companyUser,
    wallet,
    walletEnabled,
    discountEnabled,
    currentCompanyMarkupIndex,
    changeCurrentCompanyMarkupIndex,
    toggleExpandedView,
    vouchers,
    discounts,
    balance,
    expandedView,
    expandedModalView,
    modalView, subAgent,

    minCollapseWidth = 1290,
    handleModalView,
    forceModal
}: Props): ReactElement => {
    const {t} = useTranslation()
    const locale = useLocale()
    const containerRef = useRef<HTMLDivElement>(null)

    const ownUser = useAppSelector((state) => state.auth.userData?.companyUser?.ownUser || false);
    const isAdmin = useAppSelector((state) => state.auth.userData?.companyUser.admin || false);
    const devEnv: boolean = process.env.REACT_APP_ENV == "development";


    const companyMarkupOptions = companyMarkups &&
        companyMarkups.map(({name}) => ({
            value: name,
            label: name
        }))

    let companyMarkupValue
    if (currentCompanyMarkupIndex !== undefined) {
        companyMarkupValue = companyMarkupOptions[currentCompanyMarkupIndex] && companyMarkupOptions[currentCompanyMarkupIndex].value
    }

    const handleResize = useCallback((initial: boolean) => {
        if (initial && forceModal) {
            handleModalView(true)
        }

        if (window.innerWidth > minCollapseWidth) {
            //console.log("expanding");
            if (!forceModal && modalView) {
                handleModalView(false)
                toggleExpandedView(true)
            }
        } else {
            if (!modalView) {
                handleModalView(true)
            }

            if (!modalView && expandedView) {
                toggleExpandedView(false)
            }
        }
    }, [expandedView, forceModal, handleModalView, minCollapseWidth, modalView, toggleExpandedView])

    const handleEventResize = useCallback(() => {
        handleResize(false)
    }, [handleResize])

    const handleClickOutside = useCallback(() => {
        if (modalView && expandedModalView) {
            toggleExpandedView(false)
        }
    }, [expandedModalView, modalView, toggleExpandedView])

    useClickOutside(containerRef, handleClickOutside)

    useWindowResize({
        listener: handleEventResize,
        method: "throttle"
    })

    useEffect(() => {
        handleModalView(!!forceModal)
    }, [forceModal, handleModalView])

    useEffect(() => {
        handleResize(true)
    }, [handleResize])

    const changeCompanyMarkup = useCallback((val?: string | number, label?: string | number, i?: number) => changeCurrentCompanyMarkupIndex(i), [changeCurrentCompanyMarkupIndex])
    const toggleExpandedViewCallback = useCallback(() => {
        toggleExpandedView()
    }, [toggleExpandedView])

    const onTransitionLinkClickCallback = useCallback(() => {
        Cookies.set(DEFAULT_WEB_VERSION_COOKIE_NAME, "1", {
            expires: createLuxonDate()
                .plus({day: 30})
                .toJSDate(),
            domain: "hotelston.com"
        })
    }, [])

    return (
        <div
            ref={containerRef}
            className={cx(styles.UserLefter, modalView ? styles.UserModalWrapper : styles.UserLefterWrapper, devEnv && styles.HasDevNotificationBar)}
        >
            {/*{userMenu && modalView && (*/}
            {/*    <>*/}
            {/*        <div onClick={() => toggleExpandedView()} className="user-menu-placeholder" />*/}

            {/*    </>*/}
            {/*)}*/}
            <div
                className={cx(
                    styles.PanelCollapse,
                    !((modalView && expandedModalView) || (!modalView && expandedView)) && styles.PanelClose,
                    modalView && styles.ModalView
                )}
            >
                <div className={styles.LefterWrapper}>
                    {modalView && (
                        <div className={styles.Heading}>
                            <div
                                className={styles.Collapse}
                                onClick={toggleExpandedViewCallback}
                                style={{cursor: "pointer"}}
                            >
                                <BarsIcon
                                    className={styles.UserBars}
                                />

                                <div
                                    className={styles.CloseIcon}
                                >
                                    <ActionIcon type={ActionIconType.CLOSE} />
                                </div>
                            </div>

                            <div className={styles.Brand}>
                                <Link to="/">
                                    <img src={logoImg} alt="logo" />
                                </Link>
                            </div>
                        </div>
                    )}

                    {isAdmin ? (
                        <Link
                            className={styles.IssueTitle}
                            to="/my-company"
                        >
                            {company && company.name}
                        </Link>
                    ) : (
                        <a className={styles.IssueTitle}>
                            {company && company.name}
                        </a>
                    )}

                    <div className={styles.IssueItems}>
                        <EditUserDetails companyUser={companyUser} />

                        <div className={cx(styles.IssueItem, styles.Decorated)}>
                            <p className={styles.IssueName}>{t("ul_ul_markup")}</p>

                            {companyMarkups && (
                                <>
                                    {companyMarkupValue === undefined && (
                                        <SkeletonLoader className={styles.MarkupLoading}>
                                            <div data-skeleton-animated="true" />
                                        </SkeletonLoader>
                                    )}

                                    {companyMarkupValue !== undefined && (
                                        <BasicDropdown
                                            required={true}
                                            hideErrors={true}
                                            noBorders={true}
                                            dropDownOptions={companyMarkupOptions}
                                            onDropdownChange={changeCompanyMarkup}
                                            dropDownValue={companyMarkupValue}
                                            className="BasicDropdown"
                                            listStyle={{
                                                left: -50,
                                                minWidth: 150
                                            }}
                                        />
                                    )}
                                </>
                            )}
                        </div>

                        <Link className={cx(styles.IssueItem, styles.Decorated)} to="/my-bookings">
                            <p className={styles.IssueName}>{t("ul_ul_bookings")}</p>
                            {bookings && (
                                <p className={styles.IssueValue}>{bookings}</p>
                            )}
                        </Link>
                        {walletEnabled !== undefined && !balance && !subAgent && (
                            <Link className={cx(styles.IssueItem, styles.Decorated)} to="/wallet">
                                <p className={styles.IssueName}>{t("ul_ul_wallet")}</p>

                                <p className={cx(styles.IssueValue, !walletEnabled && styles.IssueValueActivate)}>
                                    {walletEnabled ? (
                                        <>{getPriceWithCurrencySymbol(wallet || 0, currency)}</>
                                    ) : (<>{t("ul_ul_activate_wallet")}</>)}
                                </p>
                            </Link>
                        )}

                        {vouchers !== undefined && vouchers !== null ? (
                            <Link className={cx(styles.IssueItem, styles.Decorated)} to="/vouchers">
                                <p className={styles.IssueName}>{t("ul_ul_vouchers")}</p>
                                <p className={styles.IssueValue}>
                                    {getPriceWithCurrencySymbol(vouchers, currency)}
                                </p>
                            </Link>
                        ) : ""}

                        {discountEnabled ? (
                            <Link className={cx(styles.IssueItem, styles.Decorated)} to="/discounts">
                                <p className={styles.IssueName}>{t("ul_ul_discounts")}</p>
                                <p className={styles.IssueValue}>
                                    {getPriceWithCurrencySymbol(discounts || 0, currency, 2)}
                                </p>
                            </Link>
                        ) : ""}

                        {balance !== undefined && balance !== null ? (
                            <div className={cx(styles.IssueItem, styles.Decorated)}>
                                <p className={styles.IssueName}>{t("ul_ul_balance")}</p>
                                <p className={styles.IssueValue} style={{color: balance > 0 ? "#434752" : "#EC4331"}}>
                                    {getPriceWithCurrencySymbol(balance, currency)}
                                </p>
                            </div>
                        ) : ""}
                    </div>

                    <div className={styles.IssueItems}>
                        {ownUser && (
                            <div className={styles.IssueItem}>
                                <a
                                    href={`${oldAppUrl}/management/login.jsf`}
                                    className={styles.IssueNameSet}
                                >
                                    <img src={managementIcon} className={styles.LogOut} alt="log-out" />

                                    <span>Go to management</span>
                                </a>

                                <span className={styles.IssueValue}>
                                    <div className={styles.GreenArrow} />
                                </span>
                            </div>
                        )}

                        <div className={styles.IssueItem}>
                            <a
                                onClick={onTransitionLinkClickCallback} href={`${oldAppUrl}?lang=${locale}`}
                                className={styles.IssueNameSet}
                            >
                                <img src={systemIcon} className={styles.LogOut} alt="log-out" />

                                <span>{t("h_t_old_app_transition_label")}</span>
                            </a>

                            <span className={styles.IssueValue}>
                                <div className={styles.GreenArrow} />
                            </span>
                        </div>

                        <div className={styles.IssueItem}>
                            <Link to="/logout" className={styles.IssueNameSet}>
                                <img src={logoutIcon} className={styles.LogOut} alt="log-out" />

                                <span>{t("ul_ul_log_out")}</span>
                            </Link>

                            <span className={styles.IssueValue}>
                                <div className={styles.GreenArrow} />
                            </span>
                        </div>
                    </div>

                    <div className={styles.ActionsContainer}>
                        <div />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default React.memo(UserLefter)
