import React, {
    ReactElement, TouchEvent, useRef, useState
} from "react";
import _ from "lodash";
import {useGoogleMap} from "@react-google-maps/api";
import styles from "./MobileDrawer.module.scss";
import MapControl from "../../../../../../components/common/GoogleMap/MapControl";

export type MobileDrawerProps = {
    onDrawEnd: (polygon: google.maps.Polygon | undefined) => void;
};

function MobileDrawer({
    onDrawEnd
}: MobileDrawerProps): ReactElement {
    const touch = useRef<boolean>(false);
    const prevCanvasPoint = useRef<{ x: number, y: number }>();
    const canvas = useRef<HTMLCanvasElement | null>(null);
    const [capturedPoints, setCapturedPoints] = useState<google.maps.LatLng[]>([]);
    const drawerDiv = useRef<HTMLDivElement | null>(null);
    // const [visualPoints, setVisualPoints] = useState<ReactElement[]>();
    const mapRef = useGoogleMap();

    const buildPolygonFromPoints = (): google.maps.Polygon | undefined => new google.maps.Polygon({
        paths: new google.maps.MVCArray(capturedPoints)
    });

    const buildSingleVisualPoint = _.debounce((x: number, y: number) => {
        if (!canvas.current) {
            return;
        }

        const canvasContext = canvas.current?.getContext("2d");
        if (!canvasContext) {
            return;
        }

        if (!prevCanvasPoint.current) {
            prevCanvasPoint.current = {x, y};
            return;
        }

        canvasContext.imageSmoothingEnabled = true;
        canvasContext.imageSmoothingQuality = "high";
        canvasContext.strokeStyle = "#63C55E";
        canvasContext.lineWidth = 4;
        canvasContext.beginPath();

        canvasContext.moveTo(prevCanvasPoint.current?.x, prevCanvasPoint.current.y);
        canvasContext.lineTo(x, y);
        canvasContext.stroke();

        prevCanvasPoint.current = {x, y};
    }, 50);

    const buildVisualPointRef = (e: TouchEvent<HTMLDivElement>) => {
        if (!e || !e.touches || !drawerDiv.current) {
            return;
        }

        const {clientHeight, clientWidth} = drawerDiv.current;
        const x = Math.max(Math.min(e.touches[0].clientX, clientWidth), 0);
        const y = Math.max(Math.min(e.touches[0].clientY, clientHeight), 0);
        buildSingleVisualPoint(x, y);
    };

    const isMinimumDistance = (lastPoint: google.maps.LatLng, newPoint: google.maps.LatLng): boolean => {
        if (!mapRef) {
            return false;
        }

        const mapBounds = mapRef.getBounds();
        if (!mapBounds) {
            return false;
        }

        const currViewportLongitudeDistance = Math.abs(mapBounds.getNorthEast().lng() - mapBounds.getSouthWest().lng());
        const currViewportLatitudeDistance = Math.abs(mapBounds.getSouthWest().lat() - mapBounds.getNorthEast().lat());
        const deltaMinimum = (Math.sqrt((currViewportLatitudeDistance ** 2) + (currViewportLongitudeDistance ** 2)) / 100);

        const currPointLongitudeDistance = Math.abs(lastPoint.lng() - newPoint.lng());
        const currPointLatitudeDistance = Math.abs(lastPoint.lat() - newPoint.lat());
        const pointDelta = Math.sqrt((currPointLatitudeDistance ** 2) + (currPointLongitudeDistance ** 2)) / 2;

        return pointDelta >= deltaMinimum;
    };

    const dragCallback = (e: TouchEvent<HTMLDivElement>) => {
        if (!drawerDiv.current || !e.touches || !mapRef || !touch.current) {
            return;
        }

        const mapBounds = mapRef.getBounds();
        if (!mapBounds) {
            return;
        }

        const {clientHeight, clientWidth} = drawerDiv.current;
        const pointX = e.touches[0].clientX;
        const pointY = e.touches[0].clientY;

        // calculate aproximate latitude and longitude using the bounding rectangle
        let lng = mapBounds.getSouthWest().lng();
        let lat = mapBounds.getNorthEast().lat();
        const latDiff = _.toNumber(Math.abs(mapBounds.getNorthEast().lat() - mapBounds.getSouthWest().lat()).toPrecision(13));
        const lngDiff = _.toNumber(Math.abs(mapBounds.getNorthEast().lng() - mapBounds.getSouthWest().lng()).toPrecision(13));
        const ratioX = _.toNumber((Math.max(Math.min(pointX, clientWidth), 0) / clientWidth).toPrecision(13));
        const ratioY = _.toNumber((Math.max(Math.min(pointY, clientHeight), 0) / clientHeight).toPrecision(13));

        lat -= _.toNumber((latDiff * ratioY).toPrecision(13));
        lng += _.toNumber((lngDiff * ratioX).toPrecision(13));

        const newPoint = new google.maps.LatLng(lat, lng);
        if (capturedPoints.length === 0 || isMinimumDistance(capturedPoints[capturedPoints.length - 1], newPoint)) {
            setCapturedPoints([
                ...capturedPoints,
                newPoint
            ]);
        }
    };

    return (
        <div
            className={styles.Root}
            ref={drawerDiv}
            onTouchStart={(e) => {
                e.stopPropagation();
                e.preventDefault();

                touch.current = true;
            }}
            onTouchEnd={(e) => {
                e.stopPropagation();
                e.preventDefault();

                if (!capturedPoints || capturedPoints.length < 3) {
                    return;
                }

                touch.current = false;
                onDrawEnd(buildPolygonFromPoints());
            }}
            onTouchMove={(e: TouchEvent<HTMLDivElement>) => {
                e.stopPropagation();
                e.preventDefault();

                e.persist();
                dragCallback(e);
                buildVisualPointRef(e);
            }}
        >
            {/*{visualPoints}*/}
            <canvas width={drawerDiv.current?.clientWidth} height={drawerDiv.current?.clientHeight} ref={canvas} />
        </div>
    );
}

export default MobileDrawer;