export default function copyToClipboard(url: string, callback: any): void {
    try {
        navigator.clipboard.write([new ClipboardItem({"text/plain": new Blob([url], {type: "text/plain"})})]).then(() => {
            callback(true);
        }).catch(() => {
            callback(false);
        });
    } catch (err) {
        // Old-school fallback (mostly for Firefox)
        const textField = document.createElement("textarea");
        textField.innerText = url;
        document.body.appendChild(textField);
        textField.select();
        document.execCommand("copy");
        textField.remove();
        callback(true);
    }
}