import React, {useCallback, useEffect, useState} from "react";
import _ from "lodash";
import {
    ChildBubblePair,
    SharedPredicateFactoryOption,
    SharedPredicateFactoryProps,
    SharedPredicateFactoryReturn
} from "../SearchPredicates.base";
import DummyBubble from "./DummyBubble";

export type RegexBubbleFactoryProps = {
    partialMatch: RegExp;
    fullMatch: RegExp;
    labelPrefix?: string;

    maxLength?: number
} & SharedPredicateFactoryProps;

export default function useRegexBubbleFactory({
    collectPipeline,
    addSubject,
    removeSubject,
    searchString,

    labelPrefix,
    partialMatch,
    fullMatch,
    maxLength
}: RegexBubbleFactoryProps): SharedPredicateFactoryReturn {
    const [options, setOptions] = useState<SharedPredicateFactoryOption[]>([]);

    const fullMatchParser = useCallback((val: string) => {
        const newOptions: SharedPredicateFactoryOption[] = [];
        const fullMatches = [...val.matchAll(fullMatch)];

        if (val && fullMatches) {
            fullMatches.forEach((fullyMatchedString) => {
                const reference = fullyMatchedString[0].toUpperCase();

                newOptions.push({
                    label: <>{labelPrefix} <b>{reference}</b></>,
                    onClick: () => void addSubject.next(({
                        child: (<DummyBubble
                            collectPipeline={collectPipeline}
                            key={reference}
                            label={reference}
                            value={reference}
                            onRemove={() => void removeSubject.next(reference)}
                        />),
                        reference,

                        fromPos: fullyMatchedString.index,
                        strLen: fullyMatchedString[0].length
                    } as ChildBubblePair))
                });
            });
        }

        return [newOptions, fullMatches];
    }, [addSubject, collectPipeline, fullMatch, labelPrefix, removeSubject]);

    const partialMatchParser = useCallback((fullMatches: RegExpMatchArray[], val: string) => {
        const newOptions: SharedPredicateFactoryOption[] = [];
        const partialMatches = [...val.matchAll(partialMatch)];

        if (val && partialMatches) {
            partialMatches
                .filter((partiallyMatchedString) => fullMatches && !fullMatches.find((fullyMatchedString) => partiallyMatchedString[0] === fullyMatchedString[0]))
                .forEach((partiallyMatchedString) => {
                    newOptions.push({
                        label: (<>{labelPrefix} <b>{partiallyMatchedString[0].toUpperCase()}</b>{_.repeat("-", Math.min((maxLength || 9) - partiallyMatchedString[0].length, (maxLength || 9) - 3))}</>)
                    });
                });
        }

        return newOptions;
    }, [labelPrefix, maxLength, partialMatch]);

    useEffect(() => {
        const [fullMatchOptions, fullMatches] = fullMatchParser(searchString) as [SharedPredicateFactoryOption[], RegExpMatchArray[]];
        const partialMatchOptions = partialMatchParser(fullMatches, searchString);

        setOptions([...fullMatchOptions, ...partialMatchOptions]);
    }, [addSubject, collectPipeline, fullMatch, fullMatchParser, labelPrefix, maxLength, partialMatch, partialMatchParser, removeSubject, searchString]);

    const manualAddCallback = useCallback((val: string) => {
        const [fullMatchOptions, matches] = fullMatchParser(val) as [SharedPredicateFactoryOption[], RegExpMatchArray[]];

        matches.forEach((match) => {
            const reference = match[0].toUpperCase();

            void addSubject.next(({
                child: (<DummyBubble
                    collectPipeline={collectPipeline}
                    key={reference}
                    label={reference}
                    value={reference}
                    onRemove={() => void removeSubject.next(reference)}
                />),
                reference,

                fromPos: match.index,
                strLen: match[0].length
            } as ChildBubblePair));
        });
    }, [addSubject, collectPipeline, fullMatchParser, removeSubject]);

    return {
        options,
        manualAddCallback
    };
}