import getDistanceBetweenCoordinates from "./getDistanceBetweenCoordinates";

export default function getAverageDistance(
    hotels: { coordinates: { latitude: number, longitude: number } }[],
    maxDistanceFromAverage: number,
    centerLat: number,
    centerLon: number
): number {
    let totalAverageDist = 0;
    let distanceCount = 0;

    for (let i = 0, len = hotels.length; i < len; ++i) {
        const dist = getDistanceBetweenCoordinates(centerLat, centerLon, hotels[i].coordinates.latitude, hotels[i].coordinates.longitude);

        if (dist > maxDistanceFromAverage) {
            continue;
        }

        totalAverageDist += dist;
        distanceCount += 1;
    }

    return totalAverageDist / distanceCount;
}