export enum DestinationsTypes {
    REQ_DEST = "destinations/REQUEST_DESTINATIONS",
    GET_DEST_SUCCESS = "destinations/GET_DESTINATIONS_SUCCESS",
    GET_DEST_FAILURE = "destinations/GET_DESTINATIONS_FAILURE",

    REQ_TRANSFER_DEST = "destinations/REQUEST_TRANSFER_DESTINATIONS",
    GET_TRANSFER_DEST_SUCCESS = "destinations/GET_TRANSFER_DESTINATIONS_SUCCESS",
    GET_TRANSFER_DEST_FAILURE = "destinations/GET_TRANSFER_DESTINATIONS_FAILURE",

    REQ_TRANSFER_ORIGIN = "destinations/REQUEST_TRANSFER_ORIGIN",
    GET_TRANSFER_ORIGIN_SUCCESS = "destinations/GET_TRANSFER_ORIGIN_SUCCESS",
    GET_TRANSFER_ORIGIN_FAILURE = "destinations/GET_TRANSFER_ORIGIN_FAILURE"
}

export default DestinationsTypes;
