import React, {ReactElement, useCallback} from "react"
import styles from "./Payment.module.scss"
import MainContainer from "components/base/MainContainer"
import PaymentContent from "components/common/Payment/PaymentContent/PaymentContent"
import {useAppDispatch, useAppSelector} from "redux/hooks"
import PaymentSummary from "./PaymentSummary/PaymentSummary"
import Spinner from "components/base/Loaders/Spinner"
import {Navigate} from "react-router-dom"
import {payNonAuth} from "redux/actions/payment.actions"
import TokenExpired from "./TokenExpired/TokenExpired"

export type PaymentProps = {
    token?: string;
}

const Payment = (props: PaymentProps): ReactElement => {
    const {
        token
    } = props;

    const dispatch = useAppDispatch();

    const loadingPaymentOptions = useAppSelector((state) => state.payment.loading);
    const anonymousPaymentOptionsFailure = useAppSelector((state) => state.payment.anonymousOptionsFailure);

    const handleSubmit = useCallback((e: React.SyntheticEvent) => {
        e.stopPropagation();
        e.preventDefault();

        if (!token) {
            return;
        }

        dispatch(payNonAuth(token));
    }, [dispatch, token]);

    if (!token) {
        return (
            <Navigate to="/login"/>
        );
    }

    return (
        <div className={styles.Root}>
            <MainContainer
                className={styles.MainContainer}
                sidebar={!loadingPaymentOptions && !anonymousPaymentOptionsFailure && (
                    <PaymentSummary/>
                )}
            >
                {anonymousPaymentOptionsFailure && (
                    <TokenExpired/>
                )}

                {!loadingPaymentOptions && !anonymousPaymentOptionsFailure && (
                    <PaymentContent
                        handleSubmit={handleSubmit}
                    />
                )}

                {loadingPaymentOptions && (
                    <Spinner size={50}/>
                )}
            </MainContainer>
        </div>
    );
}

export default Payment;