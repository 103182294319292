import React, {ReactElement, useEffect, useState} from "react"
import copyToClipboard from "utils/copyToClipboard";
import {ReactComponent as CopyIcon} from "assets/icons/link 2.svg";
import {useTranslation} from "react-i18next";
import cx from "classnames";
import styles from "./CopyToClipboard.module.scss";

export type CopyToClipboardProps = {
    url: string;
    className?: string;
};

function CopyToClipboard({
    url = "",
    className
}: CopyToClipboardProps): ReactElement {
    const {t} = useTranslation();

    const [copied, setCopied] = useState(false);

    useEffect(() => {
        if (copied) {
            setTimeout(() => {
                setCopied(false);
            }, 5000);
        }
    }, [copied]);

    return (
        <div className={cx(styles.root, className)}>
            {
                copied
                    ? <span>{t("ctc_ctc_copied")}!</span>
                    : <span onClick={() => copyToClipboard(url, setCopied)}><CopyIcon /> {t("ctc_ctc_copy_link")}</span>
            }
        </div>
    );
}

export default CopyToClipboard;
