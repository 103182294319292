import React from "react";
import {useTranslation} from "react-i18next";
import {
    SharedPredicateFactoryProps,
    SharedPredicateFactoryReturn
} from "../SearchPredicates.base";
import useRegexBubbleFactory from "../Shared/useRegexBubbleFactory";

const PARTIAL_INVOICE_REFERENCE_REGEX = /\b(AHI|ATI|CHI|CTI|HAI|TAI|CPN|TCPN|CCN|TCCN)[0-9]{1}[0-9]{0,8}(-\d)?\b/gi;
const INVOICE_REFERENCE_REGEX = /\b(AHI|ATI|CHI|CTI|HAI|TAI|CPN|TCPN|CCN|TCCN)[0-9]{9}\b/gi;

export default function useBookingInvoiceNumberBubbleFactory({
    collectPipeline,
    addSubject,
    removeSubject,
    searchString
}: SharedPredicateFactoryProps): SharedPredicateFactoryReturn {
    const {t} = useTranslation();

    const {options} = useRegexBubbleFactory({
        collectPipeline,
        addSubject,
        removeSubject,
        searchString,

        partialMatch: PARTIAL_INVOICE_REFERENCE_REGEX,
        fullMatch: INVOICE_REFERENCE_REGEX,
        labelPrefix: t("s_p_c_invoice_number_search"),
        maxLength: 11
    });

    return {
        options
    };
}
