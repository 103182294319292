import React, {
    useReducer, useState, useCallback, ReactElement
} from "react";
import Input from "components/base/Input";
import Edit from "components/common/Edit";
import ConfirmationModal from "components/common/ConfirmationModal";
import {ReactComponent as EditIcon} from "assets/icons/edit.svg";
import cx from "classnames";
import SuccessModal from "components/common/ConfirmationModal/SuccessModal";
import {useTranslation} from "react-i18next";
import {useUpdateMarkupAction} from "components/utils/useCompanyData";
import styles from "./EditMarkup.module.scss";
import {CompanyMarkupsPropTypes} from "../../../proptypes/PropTypeObjects";

type ActionProps =
    { type: "HANDLE_INPUT", name: string, value: string | number }
    ;

type StateProps = CompanyMarkupsPropTypes;

function reducer(state: StateProps, action: ActionProps) {
    const {type, name, value} = action;
    switch (type) {
    case "HANDLE_INPUT":
        return {
            ...state,
            [name]: value
        };
    default:
        return state;
    }
}

type EditMarkupModalProps = {
    onConfirm: (companyMarkup: CompanyMarkupsPropTypes) => void;
    onDecline: () => void;
    companyMarkup: CompanyMarkupsPropTypes;
    spinner: boolean;
    errors?: string[];
};

function EditMarkupModal({
    onConfirm,
    onDecline,
    companyMarkup,
    spinner,
    errors
}: EditMarkupModalProps) {
    const {t} = useTranslation();

    const [localFormData, dispatch] = useReducer(reducer, companyMarkup);

    const handleInput = ({
        target: {name, value, type}
    }: React.ChangeEvent<HTMLInputElement>) => {
        // console.log(name, value, type);
        dispatch({
            type: "HANDLE_INPUT",
            name,
            value: type === "number" ? (value ? Number(value) : "") : value
        });
    };

    // const onHotelAmountChanged = useCallback((newAmount: string) => {
    //     dispatch({
    //         type: "HANDLE_INPUT",
    //         name: "hotelAmount",
    //         value: newAmount ? Number(newAmount) : ""
    //     });
    // }, []);

    // const onTransferAmountChanged = useCallback((newAmount: string) => {
    //     dispatch({
    //         type: "HANDLE_INPUT",
    //         name: "transferAmount",
    //         value: newAmount ? Number(newAmount) : ""
    //     });
    // }, []);

    const content = (
        <div className={styles.content}>
            <div className="mb-20">
                <Input
                    fullHeight
                    variants={{labelPosition: "outlined"}}
                    inputProps={{
                        name: "name",
                        placeholder: t("mc_edm_em_name"),
                        required: true,
                        onChange: handleInput,
                        value: localFormData.name
                    }}
                    className="mb-20"
                />
            </div>

            {/*<NumberInput*/}
            {/*    className="mb-20"*/}
            {/*    value={(localFormData.hotelAmount || 0).toFixed(2)}*/}
            {/*    onChange={onHotelAmountChanged}*/}
            {/*    inputProps={{*/}
            {/*        placeholder: t("mc_edm_em_hotel_percent"),*/}
            {/*        type: "number",*/}
            {/*        required: true,*/}
            {/*        min: 0,*/}
            {/*        max: 100*/}
            {/*    }}*/}
            {/*/>*/}

            {/*<NumberInput*/}
            {/*    className="mb-60"*/}
            {/*    onChange={onTransferAmountChanged}*/}
            {/*    value={(localFormData.transferAmount || 0).toFixed(2)}*/}
            {/*    inputProps={{*/}
            {/*        placeholder: t("mc_edm_em_transfer_percent"),*/}
            {/*        type: "number",*/}
            {/*        required: true,*/}
            {/*        min: 0,*/}
            {/*        max: 100*/}
            {/*    }}*/}
            {/*/>*/}

            <Input
                fullHeight
                className="mb-20"
                variants={{labelPosition: "outlined"}}
                inputProps={{
                    name: "hotelAmount",
                    type: "number",
                    required: true,
                    step: "0.01",
                    min: 0,
                    placeholder: t("mb_am_hotel_percent"),
                    onChange: handleInput,
                    value: localFormData.hotelAmount
                }}
            />

            <Input
                fullHeight
                className="mb-60"
                variants={{labelPosition: "outlined"}}
                inputProps={{
                    required: true,
                    name: "transferAmount",
                    type: "number",
                    step: "0.01",
                    min: 0,
                    placeholder: t("mb_am_transfer_percent"),
                    onChange: handleInput,
                    value: localFormData.transferAmount
                }}
            />
        </div>
    );

    return (
        <ConfirmationModal
            onConfirm={() => onConfirm(localFormData)}
            onDecline={onDecline}
            config={{
                variants: {
                    type: "normal"
                },
                title: t("mc_edm_em_edit_markup"),
                //customHeader,
                content,
                isForm: true,
                validateForm: true,
                confirmButtonText: t("mc_edm_em_save_changes"),
                declineButtonText: t("mc_edm_em_cancel"),
                spinner,
                errors
            }}
        />
    );
}

type Props = {
    onlyIcon: boolean;
    className?: string;
    companyMarkup: CompanyMarkupsPropTypes;
    updateCompanyMarkup: (companyMarkup: CompanyMarkupsPropTypes) => void;
};

function EditMarkup({
    onlyIcon,
    className,
    companyMarkup,
    updateCompanyMarkup
}: Props): ReactElement {
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [successModal, setSuccessModal] = useState(false);

    const onSuccess = useCallback(
        (companyMarkup: CompanyMarkupsPropTypes) => {
            setModalIsOpen(false);
            setSuccessModal(true);
            updateCompanyMarkup(companyMarkup);
        },
        [updateCompanyMarkup]
    );

    const {t} = useTranslation();

    const {
        requesting,
        errors,
        updateMarkup: updateMarkupAction,
        clearState
    } = useUpdateMarkupAction(onSuccess);

    return (
        <>
            {modalIsOpen && (
                <EditMarkupModal
                    companyMarkup={companyMarkup}
                    onConfirm={(companyMarkup: CompanyMarkupsPropTypes) => {
                        updateMarkupAction(companyMarkup);
                        updateCompanyMarkup(companyMarkup);
                    }}
                    onDecline={() => setModalIsOpen(false)}
                    errors={errors}
                    spinner={requesting}
                />
            )}

            {successModal && (
                <SuccessModal
                    onClose={() => setSuccessModal(false)}
                    successMessage={t("mc_edm_em_success_message_update_markup")}
                />
            )}

            {onlyIcon ? (
                <div
                    className={cx(styles.root, className)}
                    onClick={() => {
                        clearState();
                        setModalIsOpen(true);
                    }}
                >
                    <EditIcon />
                </div>
            ) : (
                <Edit
                    className={className}
                    onClick={() => {
                        clearState();
                        setModalIsOpen(true);
                    }}
                    what={t("mc_edm_em_edit_markup_button").toLowerCase()}
                    style={{
                        fontWeight: 600,
                        lineHeight: "1.5rem"
                    }}
                />
            )}
        </>
    );
}

export default EditMarkup;
