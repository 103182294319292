import {
    TypedUseSelectorHook,
    useDispatch,
    useSelector
} from "react-redux";
import {Dispatch} from "redux";
import {AppDispatch, IAppDispatch, RootState} from "./store/store.init";
import IAppActions from "./store/store.actions";

// Use throughout your app instead of plain `useDispatch` and `useSelector`
//export const useAppDispatch = <R>(): (action: IAppActions | ThunkAction<R, IAppState, any, IAppActions>) => void => useDispatch<IAppDispatch>();

export const useAppDispatch = (): Dispatch<IAppActions> & IAppDispatch => useDispatch<AppDispatch>();

// probably nothing to do
//export const appConnect = <TStateProps = {}, TDispatchProps = {}, TOwnProps = {}, State = RootState>(mapStateToProps: MapStateToPropsParam<TStateProps, TOwnProps, State>, mapDispatchToProps: ResolveThunks<TDispatchProps>) => connect(mapStateToProps, mapDispatchToProps);
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;