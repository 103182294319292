import React, {ReactElement, useCallback, useEffect} from "react";
import _ from "lodash";
import {SharedPredicateProps} from "../SearchPredicates.base";
import BasePredicate from "../BasePredicate";
import styles from "./DummyBubble.module.scss";
import {BookingSearchCriteriaPropTypes} from "proptypes/PropTypeObjects";

export default function DummyBubble<T>({
    collectPipeline,
    label,
    value,
    onRemove,

    leftoverText = false,
    onClick
}: SharedPredicateProps<T>): ReactElement {
    const formBuilderCallback = useCallback((data: BookingSearchCriteriaPropTypes | null) => ({
        ...data,
        textSearch: (data?.textSearch)?.trim() + " " + value
    }), [value]);

    useEffect(() => {
        collectPipeline.subscribe(formBuilderCallback);

        return () => {
            collectPipeline.unsubscribe(formBuilderCallback);
        };
    }, [collectPipeline, formBuilderCallback]);

    return (
        <BasePredicate className={styles.Root} leftoverText={leftoverText} reference={_.toString(value)} onRemove={onRemove} onClick={onClick}>
            <span>
                {label}
            </span>
        </BasePredicate>
    );
}