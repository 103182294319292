import unionBy from "lodash/unionBy";
import {ReduxWebSocketPropTypes} from "../../proptypes/PropTypeRedux";
import WebSocketTypes from "../constants/webSocket.constants";
import WebSocketActions from "./actions/websocket.reducer.actions";

const initialState = {
    notificationsData: undefined,
    sendMessage: undefined
} as ReduxWebSocketPropTypes;

export default (state = initialState, action: WebSocketActions): ReduxWebSocketPropTypes => {
    switch (action.type) {
    case WebSocketTypes.SET_NOTIFICATIONS_DATA:
        return {
            ...state,
            notificationsData: {
                ...state.notificationsData,
                ...action.notificationsData,
                notifications: unionBy(
                    action.notificationsData.notifications,
                    state.notificationsData
                        ? state.notificationsData.notifications
                        : [],
                    "id"
                ).filter(({status}) => status !== "DELETED")
            }
        };
    case WebSocketTypes.RESET_NOTIFICATIONS_DATA:
        return {
            ...state,
            notificationsData: undefined
        };
    case WebSocketTypes.SET_SEND_MESSAGE:
        return {
            ...state,
            sendMessage: action.sendMessage
        };
    default:
        return state;
    }
};
