import React from "react";
import {Trans, WithTranslation, withTranslation} from "react-i18next";
import UserButton from "components/base/UserButton";
import withConfirmation, {WithConfirmation} from "components/utils/withConfirmation";
import {UserButtonVariant} from "components/base/UserButton/UserButton";
import styles from "./ButtonConfirmNonrefModal.module.scss";

type Props = WithConfirmation & WithTranslation & {
    customText?: string;
    customSubText?: string;
    customButtonLabel?: string;
    disabledButton?: boolean;
};

const ButtonConfirmNonrefModal = (props: Props) => {
    const {
        customText,
        customSubText,
        customButtonLabel,
        disabledButton,
        t,
        setModal
    } = props;

    return (
        <UserButton
            text={
                customButtonLabel || t("price_p_continue")
            }
            variant={UserButtonVariant.PRIMARY}
            disabledButton={disabledButton}
            buttonProps={{
                onClick: () => setModal({
                    onConfirm: () => true,
                    onDecline: () => false,
                    config: {
                        isForm: true,
                        variants: {
                            type: "warn"
                        },
                        title: t("pc_bcm_after_cxl_title"),
                        content: (
                            <>
                                {customText && (
                                    <span className={styles.Note}>
                                        {customText}
                                    </span>
                                ) || (
                                    <span className={styles.Note}>
                                        <Trans i18nKey="pc_bcm_after_cxl_note">
                                            <b>ATTENTION!</b> This reservation has passed free cancellation deadline, therefore the payment must be completed and money should be debited to
                                            our bank account before we can confirm this booking. Prices and/or availability may change until then.
                                            Alternatively, you may choose one of the instant payment options and get the booking confirmation immediately.
                                        </Trans>
                                    </span>
                                )}

                                {customSubText && (
                                    <span className={styles.Note}>
                                        {customSubText}
                                    </span>
                                )}
                            </>
                        ),
                        confirmButtonText: t("pc_bcm_confirm"),
                        declineButtonText: t("pc_bcm_cancel")
                    }
                }),
                type: "button",
                style: {
                    width: "160px"
                }
            }}
        />
    );
};
export default withTranslation()(withConfirmation(ButtonConfirmNonrefModal));