import React, {CSSProperties, ReactElement} from "react";
import cx from "classnames";
import styles from "./Item.module.scss";

export type AmmendmentItemProps = {
    edit?: boolean;
    inputEl?: ReactElement;
    label?: string;
    value?: string | number | boolean;
    containerStyle?: CSSProperties;
    inputContainerStyle?: CSSProperties;
    labelWithInputEl?: boolean;
    labelStyle?: CSSProperties;
    inputContainerClassName?: string;
    inline?: boolean;
};

export default function Item({
    edit,
    inputEl,
    label = "",
    value,
    containerStyle,
    inputContainerStyle,
    labelWithInputEl,
    labelStyle = {},
    inputContainerClassName,
    inline = false
}: AmmendmentItemProps): ReactElement {
    if (edit && inline && inputEl) {
        return (
            <>
                <label style={labelStyle}>{label}</label>

                {inputEl}
            </>
        );
    }

    if (edit && labelWithInputEl) {
        return (
            <div className={cx(styles.Root, styles.WithoutLabel, inputContainerClassName)}>
                {inputEl}
            </div>
        );
    }

    if (edit && inputEl) {
        return (
            <div className={cx(styles.Root, styles.CustomInputEl, inputContainerClassName)} style={containerStyle}>
                <label style={labelStyle}>{label}</label>

                <div className={styles.CustomInputElContainer} style={inputContainerStyle}>
                    {inputEl}
                </div>
            </div>
        );
    }

    return (
        <dl className={styles.Root}>
            {(!edit || !labelWithInputEl) && <dt style={edit ? labelStyle : undefined} className={styles.Label}>{label}</dt>}

            {edit ? inputEl : <dd className={styles.Value}>{value}</dd>}
        </dl>
    );
}
