import {Trans, useTranslation} from "react-i18next";
import React, {ReactElement, useCallback, useState} from "react";
import {PaymentTransferTypePropTypes, WebBankAccount} from "proptypes/PropTypeObjects";
import InfoBox from "components/common/InfoBox";
import types from "utils/paymentTransferTypes";
import {ReactComponent as BankIcon} from "assets/icons/bank.svg";
import styles from "components/common/Payment/PaymentContent/PaymentContent.module.scss";
import BasicDropdown from "components/base/Input/BasicDropdown/BasicDropdown";
import Truncate from "components/base/Truncate";
import {useAppDispatch, useAppSelector} from "redux/hooks"
import {calculateTransactionFee} from "redux/actions/pricing.actions";

type Props = {
    paymentTransferTypeEnum?: PaymentTransferTypePropTypes;
    afterDeadline?: boolean;
    toggleSelectedPayment: (paymentType: PaymentTransferTypePropTypes, keepSelected?: boolean) => void;
    instantPaymentAfterCXLAvailable?: boolean;
    emailSupport?: string;
    emergencyPhone?: string
    paymentDeadlineDate?: string;
    paymentDeadlineTime?: string;
    cancellationDate?: string;
    cancellationTime?: string;
    bankTransferBanks: WebBankAccount[],
    children: ReactElement
};

function BankTransferBlock({
                               paymentTransferTypeEnum,
                               afterDeadline,

                               toggleSelectedPayment,
                               instantPaymentAfterCXLAvailable,
                               emailSupport,
                               emergencyPhone,
                               paymentDeadlineDate,
                               paymentDeadlineTime,
                               cancellationDate,
                               cancellationTime,

                               bankTransferBanks,

                               children
                           }: Props): ReactElement {
    const {t} = useTranslation();
    const dispatch = useAppDispatch();

    const bankTransferBanksSorted = bankTransferBanks.sort((o1, o2) => o1.countryName.localeCompare(o2.countryName));

    const bankTransferBanksFormed = bankTransferBanksSorted.map(({countryName, bankName}: WebBankAccount) => ({
        label: `${countryName} (${bankName})`,
        value: bankName
    }));

    const userCountryId = useAppSelector((state) => state.auth.userData?.countryId);

    const bankTransferBanksIndexInitial = bankTransferBanksSorted
        .findIndex(({
                        countryId
                    }) => userCountryId === countryId);

    const [bankTransferBanksIndex, setBankTransferBanksIndex] = useState<number>(bankTransferBanksIndexInitial !== -1 ? bankTransferBanksIndexInitial : 0);

    const clickCallback = useCallback(() => {
        toggleSelectedPayment(types[afterDeadline ? "after" : "before"].transfer);
        dispatch(calculateTransactionFee());

        return false;
    }, [afterDeadline, dispatch, toggleSelectedPayment]);

    return (
        <InfoBox
            style={{
                marginBottom: 15
            }}
            active={paymentTransferTypeEnum === types[afterDeadline ? "after" : "before"].transfer}
            title={t("pc_pc_bank_transfer")}
            message={t("pc_pc_bank_transfer_promotional_message")}
            customIcon={<BankIcon/>}
            // miniIcon
            onClick={clickCallback}
            type="custom"
        >
            <div className={styles.Option}>
                <div className={styles.OptionText}>
                    {afterDeadline ? (
                        <>
                            <p>{t("pc_pc_ptt_bank_tansfer_after_cxl")}</p>
                            <p>
                                <Trans i18nKey="pc_pc_ptt_bank_tansfer_after_cxl_attention">
                                    <b>ATTENTION!</b> This
                                    reservation has passed free
                                    cancellation deadline, therefore
                                    the payment must be completed or
                                    we need to receive the proof of
                                    the bank transfer before we can
                                    confirm this booking. Prices
                                    and/or availability may change
                                    until then.
                                </Trans>
                            </p>
                            {instantPaymentAfterCXLAvailable && (
                                <p>
                                    {t("pc_pc_ptt_bank_tansfer_after_cxl_attention3")}
                                </p>
                            )}
                            <p>
                                {t("pc_pc_ptt_bank_tansfer_after_cxl_attention2", {
                                    email: emailSupport,
                                    phone: emergencyPhone
                                })}
                            </p>
                        </>
                    ) : (
                        <>
                            <p>
                                {t("pc_pc_ptt_bank_tansfer", {
                                    date: paymentDeadlineDate,
                                    time: paymentDeadlineTime
                                })}
                            </p>
                            <p>
                                <Trans
                                    i18nKey="pc_pc_ptt_bank_tansfer_attention"
                                    values={{
                                        cancellationDate,
                                        cancellationTime,
                                        paymentDate: paymentDeadlineDate,
                                        paymentTime: paymentDeadlineTime
                                    }}
                                >
                                    <b>Attention!</b>
                                    <br/>
                                    The reservation will be
                                    cancelled automatically on{" "}
                                    {cancellationDate}{" "}
                                    {cancellationTime} in case the
                                    payment is not completed before{" "}
                                    {paymentDeadlineDate}{" "}
                                    {paymentDeadlineTime}.
                                </Trans>
                            </p>
                        </>
                    )}
                </div>

                {/*{bankTransferBanksFormed.length === 1 && (*/}
                {/*    <div className={styles.SingleSelect}>*/}
                {/*        <label>{t("pc_pc_select_bank_single")}</label>*/}
                {/*        {bankTransferBanksFormed[bankTransferBanksIndex].label}*/}
                {/*    </div>*/}
                {/*)}*/}

                {bankTransferBanksFormed.length > 1 && (
                    <div className={styles.Select}>
                        <BasicDropdown
                            required={true}
                            hideErrors={true}
                            dropDownOptions={bankTransferBanksFormed}
                            label={t("pc_pc_select_bank") + ":"}
                            onDropdownChange={(val: unknown, label: unknown, i?: number) => setBankTransferBanksIndex(i || 0)}
                            dropDownValue={bankTransferBanksFormed[bankTransferBanksIndex].label}
                            className="simple"
                        />
                    </div>
                )}

                {bankTransferBanks[bankTransferBanksIndex] && (
                    <Truncate
                        ellipsis={(
                            <span className={styles.TruncateMoreLess}>
                                {t("pc_pc_see_more")}
                            </span>
                        )}
                        lines={3}
                    >
                        <dl className={styles.BankDetails}>
                            <dt>{t("pc_pc_bank_address")}:</dt>
                            <dd>{bankTransferBanks[bankTransferBanksIndex].bankAddress}</dd>

                            <dt>{t("pc_pc_swift_code")}:</dt>
                            <dd>{bankTransferBanks[bankTransferBanksIndex].swiftCode}</dd>

                            <dt>{t("pc_pc_recipient_name")}:</dt>
                            <dd>{bankTransferBanks[bankTransferBanksIndex].recipientName}</dd>

                            <dt>{t("pc_pc_recipient_address")}:</dt>
                            <dd>{bankTransferBanks[bankTransferBanksIndex].recipientAddress}</dd>

                            <dt>{t("pc_pc_recipient_iban")}:</dt>
                            <dd>{bankTransferBanks[bankTransferBanksIndex].iban}</dd>

                            <dt>{t("pc_pc_currencies_accepted")}:</dt>
                            <dd>{bankTransferBanks[bankTransferBanksIndex].currencies}</dd>
                        </dl>
                    </Truncate>
                )}

                <div
                    style={{
                        marginTop: 30,
                        marginBottom: 30
                    }}
                    className="h-divider"
                />
                {children}
            </div>
        </InfoBox>
    );
}

export default BankTransferBlock;