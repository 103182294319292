import React, {ReactElement} from "react"
import {useTranslation} from "react-i18next"
import BankTransferBlock from "./BankTransferBlock"
import types from "utils/paymentTransferTypes"
import ButtonConfirmNonrefModal from "components/base/Payment/ButtonConfirmNonRefModal/ButtonConfirmNonrefModal"
import Pricing from "components/common/Pricing/Pricing"
import {SharedPaymentTypeProps} from "../SharedPaymentTypeProps"
import usePaymentOptions from "../usePaymentOptions"

export type BankTransferProps = SharedPaymentTypeProps;

const BankTransfer = (props: BankTransferProps): ReactElement => {
    const {
        toggleSelectedPayment,
        paymentTransferTypeEnum
    } = props;

    const {t} = useTranslation();

    const {
        paymentOptions,
        discount,
        voucherSum
    } = usePaymentOptions();

    const bankTransfer = paymentOptions?.paymentTransferTypes.includes(types[paymentOptions?.afterDeadline ? "after" : "before"].transfer);

    if (!bankTransfer || !paymentOptions) {
        return (<></>);
    }

    const {
        bankTransferBanks,
        afterDeadline,
        allowToBook,
        sanctionedCompany,
        salePrice,
        displaySalePrice,
        smsFee,
        displaySmsFee,
        paymentPending,
        paymentDeadlineDate,
        paymentDeadlineTime,

        cancellationDate,
        cancellationTime,

        emailSupport,
        emergencyPhone,
        instantPaymentAfterCXLAvailable
    } = paymentOptions;

    return (
        <BankTransferBlock
            key="bank-transfer"
            bankTransferBanks={bankTransferBanks || []}
            afterDeadline={afterDeadline}
            toggleSelectedPayment={toggleSelectedPayment}
            paymentDeadlineDate={paymentDeadlineDate}
            paymentDeadlineTime={paymentDeadlineTime}
            paymentTransferTypeEnum={paymentTransferTypeEnum}
            instantPaymentAfterCXLAvailable={instantPaymentAfterCXLAvailable}
            cancellationDate={cancellationDate}
            cancellationTime={cancellationTime}
            emailSupport={emailSupport}
            emergencyPhone={emergencyPhone}
        >
            <Pricing
                customButton={afterDeadline ? <ButtonConfirmNonrefModal/> : undefined}
                withButton
                customButtonLabel={t("pc_pc_book_now")}
                disabledButton={!allowToBook || sanctionedCompany}
                greyLabels
                salePrice={salePrice}
                displaySalePrice={displaySalePrice}
                smsFee={smsFee}
                displaySmsFee={displaySmsFee}
                discount={discount}
                voucher={voucherSum.used}
                paymentPending={paymentPending}
            />
        </BankTransferBlock>
    );
}

export default BankTransfer;