import {ReduxOverpaymentPropTypes} from "../../proptypes/PropTypeRedux";
import OverpaymentTypes from "../constants/overpayment.constants";
import OverpaymentActionProps from "./actions/overpayment.reducer.actions";

export const INITIAL_STATE = {
    error: undefined,
    requestingOptions: true,
    requestingUse: false,
    overpaymentOptions: undefined
} as ReduxOverpaymentPropTypes;

export default (state = INITIAL_STATE, action: OverpaymentActionProps): ReduxOverpaymentPropTypes => {
    switch (action.type) {
    case OverpaymentTypes.REQ_OPTIONS:
        return {
            ...state,
            requestingOptions: true
        };
    case OverpaymentTypes.REQ_OPTIONS_SUCCESS:
        return {
            ...state,
            overpaymentOptions: action.overpaymentOptions,
            requestingOptions: false
        };
    case OverpaymentTypes.REQ_OPTIONS_FAILURE:
        return {
            ...state,
            error: action.error,
            requestingOptions: false
        };
    case OverpaymentTypes.REQ_USE:
        return {
            ...state,
            requestingUse: true
        };
    case OverpaymentTypes.REQ_USE_SUCCESS:
        return INITIAL_STATE;
    case OverpaymentTypes.REQ_USE_FAILURE:
        return {
            ...state,
            error: action.error,
            requestingUse: false
        };

    default:
        return state;
    }
};