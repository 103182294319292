enum AuthTypes {
    REQUEST_LOGIN = "auth/REQUEST_LOGIN",
    LOGIN_SUCCESS = "auth/LOGIN_SUCCESS",
    LOGIN_FAILURE = "auth/LOGIN_FAILURE",
    LOGIN_ERROR = "auth/LOGIN_ERROR",
    INSUFFICIENT_AUTHENTICATION = "auth/INSUFFICIENT_AUTHENTICATION",
    CHECKED_LOGIN = "auth/CHECKED_LOGIN",
    REQUEST_LOGOUT = "auth/REQUEST_LOGOUT",
    LOGOUT_SUCCESS = "auth/LOGOUT_SUCCESS",
    LOGOUT_FAILURE = "auth/LOGOUT_FAILURE",
    MERGE_USER_DATA = "auth/MERGE_USER_DATA",
    CHANGE_CURR_COMP_MARK_INDEX = "auth/CHANGE_CURR_COMP_MARK_INDEX",
    SET_WALLET_TO_ENABLED = "auth/SET_WALLET_TO_ENABLED",
    TOGGLE_EXPANDED_VIEW = "auth/TOGGLE_EXPANDED_VIEW",
    HANDLE_MODAL_VIEW = "auth/HANDLE_MODAL_VIEW",
    LOGIN_ABORTED_WITH_ERROR = "auth/LOGIN_ABORTED_WITH_ERROR",
    ROLES_UPDATE = "auth/ROLES_UPDATE",
    ACCEPT_TERMS_AND_CONDITIONS = "auth/ACCEPT_TERMS_AND_CONDITIONS",
    ON_401_SET_LAST_ROUTE = "auth/ON_401_SET_LAST_ROUTE",
    REDIRECTED_AFTER_401 = "auth/REDIRECTED_AFTER_401"
}

export default AuthTypes;