import React, {ReactElement} from "react"
import {useTranslation} from "react-i18next"
import Pricing from "components/common/Pricing/Pricing"
import CreditCardBlock from "./CreditCardBlock"
import {SharedPaymentTypeProps} from "../SharedPaymentTypeProps"
import usePaymentOptions from "../usePaymentOptions"
import types from "utils/paymentTransferTypes"
import PayInParts from "components/common/Payment/PaymentContent/PaymentComponents/PayInParts/PayInParts";
import PaymentProviderLogo from "components/base/Payment/PaymentProviderLogo/PaymentProviderLogo";

export type CreditCardProps = SharedPaymentTypeProps;

const CreditCard = (props: CreditCardProps): ReactElement => {
    const {
        toggleSelectedPayment,
        paymentTransferTypeEnum
    } = props;

    const {t} = useTranslation();

    const {
        paymentOptions,
        discount,
        voucherSum,
        paymentPromotion
    } = usePaymentOptions();

    const creditCard = paymentOptions?.paymentTransferTypes.includes(types[paymentOptions?.afterDeadline ? "after" : "before"].creditCard);
    if (!paymentOptions || !creditCard) {
        return (
            <></>
        );
    }

    const {
        afterDeadline,
        freeCreditCard,
        paymentDeadlineDate,
        paymentDeadlineTime,
        displaySalePrice,
        allowToBook,
        sanctionedCompany,
        salePrice,
        smsFee,
        displaySmsFee,
        paymentPending,
        creditCardPaymentProvider
    } = paymentOptions;

    return (
        <CreditCardBlock
            key="credit-card"
            paymentTransferTypeEnum={paymentTransferTypeEnum}
            afterDeadline={afterDeadline}
            freeCreditCard={freeCreditCard}
            paymentDeadlineDate={paymentDeadlineDate}
            paymentDeadlineTime={paymentDeadlineTime}
            toggleSelectedPayment={toggleSelectedPayment}
            paymentPromotion={paymentPromotion}
            displaySalePrice={displaySalePrice}
        >
            <Pricing
                withButton
                customElementAboveButton={(
                    <PaymentProviderLogo paymentProvider={creditCardPaymentProvider}/>
                )}
                secondaryButton={(
                    <PayInParts paymentTransferType={paymentTransferTypeEnum}/>
                )}
                customButtonLabel={t("pc_pc_pay_now")}
                disabledButton={!allowToBook || sanctionedCompany || !paymentOptions.allowInstantPaymentMethod}
                greyLabels
                salePrice={salePrice}
                displaySalePrice={displaySalePrice}
                smsFee={smsFee}
                displaySmsFee={displaySmsFee}
                discount={discount}
                voucher={voucherSum.used}
                paymentPending={paymentPending}
            />
        </CreditCardBlock>
    );
}

export default CreditCard;