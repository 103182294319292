enum MyBookingsTypes {
    RESET_DATA = "myBookings/RESET_DATA",
    RESET_FORM_DATA = "myBookings/RESET_FORM_DATA",

    START_SEARCH = "myBookings/START_SEARCH",
    SEARCH_SUCCESS = "myBookings/SEARCH_SUCCESS",
    SEARCH_FAILURE = "myBookings/SEARCH_FAILURE",

    REQ_BOOKING_OPTIONS = "myBookings/REQ_BOOKING_OPTIONS",
    GET_BOOKING_SEARCH_OPTIONS_SUCCESS = "myBookings/GET_BOOKING_OPTIONS_SUCCESS",
    GET_BOOKING_OPTIONS_FAILURE = "myBookings/GET_BOOKING_OPTIONS_FAILURE",

    TOGGLE_ADVANCED_FILTERS = "myBookings/TOGGLE_ADVANCED_FILTERS",

    MODIFY_COL_REQUEST = " myBookings/MODIFY_COL_REQUEST",
    MODIFY_COL_FAILED = " myBookings/REQ_COL_ACTION_FAILED",
    MODIFY_COL_SUCCESS = "myBookings/ADD_COLUMN",

    HANDLE_FORM_DATA = "myBookings/HANDLE_FORM_DATA",

    HANDLE_SORT = "myBookings/HANDLE_SORT",

    HANDLE_PAGE_NR = "myBookings/HANDLE_PAGE_NR",
    HANDLE_PAGE_SIZE = "myBookings/HANDLE_PAGE_SIZE",

    HANDLE_QUICK_FILTERS = "myBookings/HANDLE_QUICK_FILTERS"
}

export default MyBookingsTypes;