import React, {ReactElement} from "react"
import styles from "./PaymentSuccess.module.scss"
import {useTranslation} from "react-i18next"
import MainContainer from "../../../components/base/MainContainer"
import PageStatus from "../../../components/common/PageStatus"

const PaymentSuccess = (): ReactElement => {
    const {t} = useTranslation();

    return (
        <div
            className={styles.Root}
        >
            <MainContainer className={styles.MainContainer}>
                <PageStatus
                    title={t("pc_pc_pl_payment_successful_header")}
                    content={t("pc_pc_pl_payment_successful")}
                    withActionButton={false}
                    status="success"
                    className={styles.SuccessMessage}
                />
            </MainContainer>
        </div>
    );
}

export default PaymentSuccess;