import React, {ReactElement} from "react";
import styles from "./Content.module.scss";

function Content({
    children
}: any): ReactElement {
    return (
        <div className={styles.root}>
            {children}
        </div>
    );
}

export default Content;
