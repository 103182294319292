import {PaymentTransferTypePropTypes} from "../proptypes/PropTypeObjects";

export default function getTranslationKeyByPaymentTransferTypeEnum(paymentTransferType?: PaymentTransferTypePropTypes): string {
    if (!paymentTransferType) {
        return "undefined";
    }
    switch (paymentTransferType) {
    case PaymentTransferTypePropTypes.TRANSFER:
        return "pc_pc_bank_transfer";
    case PaymentTransferTypePropTypes.TRANSFER_AFTER_CXL:
        return "pc_pc_bank_transfer";
    case PaymentTransferTypePropTypes.BANK_LINK:
        return "pc_pc_internet_bank";
    case PaymentTransferTypePropTypes.BANK_LINK_AFTER_CXL:
        return "pc_pc_internet_bank";
    case PaymentTransferTypePropTypes.CREDIT_CARD:
        return "pc_pc_credit_card";
    case PaymentTransferTypePropTypes.CREDIT_CARD_AFTER_CXL:
        return "pc_pc_credit_card";
    case PaymentTransferTypePropTypes.HOTELSTON_WALLET:
        return "pc_pc_hotelston_wallet";
    case PaymentTransferTypePropTypes.HOTELSTON_WALLET_AFTER_CXL:
        return "pc_pc_hotelston_wallet";
    case PaymentTransferTypePropTypes.PAY_LATER:
        return "pc_pc_pay_later";
    case PaymentTransferTypePropTypes.PAY_LATER_AFTER_CXL:
        return "pc_pc_pay_later";
    case PaymentTransferTypePropTypes.CREDIT_LINE:
        return "pc_pc_credit_line";
    case PaymentTransferTypePropTypes.CREDIT_LINE_AFTER_CXL:
        return "pc_pc_credit_line";
    case PaymentTransferTypePropTypes.MIP:
        return "pc_pc_internet_bank";
    case PaymentTransferTypePropTypes.MIP_AFTER_CXL:
        return "pc_pc_internet_bank";
    case PaymentTransferTypePropTypes.PAYMENT_LINK:
        return "pc_pc_payment_link";
    case PaymentTransferTypePropTypes.PAYMENT_LINK_AFTER_CXL:
        return "pc_pc_payment_link";
    case PaymentTransferTypePropTypes.APPLE_PAY:
        return "pc_pc_apple_pay";
    case PaymentTransferTypePropTypes.APPLE_PAY_AFTER_CXL:
        return "pc_pc_apple_pay";
    default:
        return paymentTransferType as string;
    }
}