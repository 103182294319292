import IAppActions from "../store/store.actions"
import PricingTypes from "../constants/pricing.constants"
import {CommonActionReturnType} from "../store/store.init"
import {
    PaymentTransferTypePropTypes, WebBankLinkOptionPropTypes,
    WebPaymentProvider
} from "proptypes/PropTypeObjects"
import {getTransactionFee} from "api/pricingAPI"
import {globalAxiosController} from "api/axios/globalAxiosController";
import {ReduxPaymentTypePropTypes} from "proptypes/PropTypeRedux";
import {paymentPendingAfterConversionsSelector} from "redux/selectors/payment.selector";
import Currency from "@hotelston_web_frontend_parent/constants/currency";

export const requestTransactionFee = (): IAppActions => ({
    type: PricingTypes.REQ_TRANSACTION_FEE
});

export const setTransactionFee = (fee: number, displayFee: number): IAppActions => ({
    type: PricingTypes.GET_TRANSACTION_FEE,
    fee,
    displayFee
});

export const calculateTransactionFee = (): CommonActionReturnType => (dispatch, getState) => {
    const paymentType = getState().payment.type;
    if (!paymentType) {
        return;
    }

    const paymentTransferType = getState().payment.paymentTransferTypeEnum;
    if (
        !paymentTransferType ||
        (paymentTransferType === PaymentTransferTypePropTypes.TRANSFER || paymentTransferType === PaymentTransferTypePropTypes.TRANSFER_AFTER_CXL) ||
        (paymentTransferType === PaymentTransferTypePropTypes.PAY_LATER || paymentTransferType === PaymentTransferTypePropTypes.PAY_LATER_AFTER_CXL) ||
        (paymentTransferType === PaymentTransferTypePropTypes.PAYMENT_LINK || paymentTransferType === PaymentTransferTypePropTypes.PAYMENT_LINK_AFTER_CXL) ||
        (paymentTransferType === PaymentTransferTypePropTypes.CREDIT_LINE || paymentTransferType === PaymentTransferTypePropTypes.CREDIT_LINE_AFTER_CXL) ||
        (paymentTransferType === PaymentTransferTypePropTypes.HOTELSTON_WALLET || paymentTransferType === PaymentTransferTypePropTypes.HOTELSTON_WALLET_AFTER_CXL) ||
        paymentTransferType === PaymentTransferTypePropTypes.SUPERAGENT_PAYMENT
    ) {
        dispatch(setTransactionFee(0, 0));
        return;
    }

    // const paymentType = getState().payment.type;
    const currency = getState().currency.currentCurrency;
    const markups = getState().auth.userData?.companyMarkups || [];
    const currentSelectedMarkup = getState().auth.currentCompanyMarkupIndex || 0;
    const paymentPending = paymentPendingAfterConversionsSelector(getState().payment);
    const selectedPaymentLink = getState().payment.selectedPaymentLink;

    dispatch(getTransactionFeeByPrice(paymentPending, selectedPaymentLink, paymentTransferType));

    let transactionFee: number;
    let displayTransactionFee: number;
    if (
        [
            PaymentTransferTypePropTypes.BANK_LINK,
            PaymentTransferTypePropTypes.BANK_LINK_AFTER_CXL
        ].includes(paymentTransferType) &&
        selectedPaymentLink
    ) {
        transactionFee = selectedPaymentLink.fee;
        displayTransactionFee = selectedPaymentLink.displayFee;
    } else {
        transactionFee = getState().pricing.transactionFee || getState().payment.transactionFee;
        displayTransactionFee = getState().pricing.displayTransactionFee || getState().payment.displayTransactionFee;
    }

    let markup: number;
    switch (paymentType) {
    case ReduxPaymentTypePropTypes.HOTEL:
        markup = markups[currentSelectedMarkup].hotelAmount;
        break;
    case ReduxPaymentTypePropTypes.TRANSFER:
        markup = markups[currentSelectedMarkup].transferAmount;
        break;
    default:
        markup = 1;
    }

    const withOtherCurrencyAndMarkup = currency !== Currency.EUR || markup !== 1;
    if (withOtherCurrencyAndMarkup) {
        dispatch(setTransactionFee(transactionFee, displayTransactionFee));
    } else {
        dispatch(setTransactionFee(transactionFee, transactionFee));
    }
}

export const rememberTransactionFee = (transactionFee: number): IAppActions => ({
    type: PricingTypes.REMEMBER_TRANSACTION_FEE,
    transactionFee
});

export const transactionFeeError = (): IAppActions => ({
    type: PricingTypes.ERR_TRANSACTION_FEE
});

export const setLastRequestedPrice = (price: number): IAppActions => ({
    type: PricingTypes.SET_LAST_REQUESTED_PRICE,
    price
});

export const getTransactionFeeByPrice = (
    price: number,
    selectedPaymentLink?: WebBankLinkOptionPropTypes,
    paymentTransferType?: PaymentTransferTypePropTypes
): CommonActionReturnType => (dispatch, getState) => {
    const {
        loading,
        lastSalePrice
    } = getState().pricing;

    const {
        currentCurrency
    } = getState().currency;

    if (price === 0) {
        return;
    }

    const {
        bookingOptions
    } = getState().payment;

    if (!bookingOptions || loading || lastSalePrice === price) {
        return;
    }

    let paymentProvider: WebPaymentProvider | undefined;
    if (paymentTransferType && [PaymentTransferTypePropTypes.CREDIT_CARD, PaymentTransferTypePropTypes.CREDIT_CARD_AFTER_CXL].includes(paymentTransferType)) {
        paymentProvider = bookingOptions.creditCardPaymentProvider;
    }

    if (
        paymentTransferType &&
        [
            PaymentTransferTypePropTypes.BANK_LINK,
            PaymentTransferTypePropTypes.BANK_LINK_AFTER_CXL,
            PaymentTransferTypePropTypes.MIP,
            PaymentTransferTypePropTypes.MIP_AFTER_CXL
        ].includes(paymentTransferType)
    ) {
        if (!selectedPaymentLink) {
            return;
        } else {
            setTransactionFee(selectedPaymentLink.fee, selectedPaymentLink.displayFee);
            return;
        }
    }

    dispatch(requestTransactionFee());
    globalAxiosController.addRequest(getTransactionFee(price, currentCurrency, selectedPaymentLink, paymentProvider, paymentTransferType))
        .then((response) => {
            if (response && response.fee) {
                dispatch(setTransactionFee(response.fee, response.displayFee));
                dispatch(setLastRequestedPrice(price));
            }
        }).catch(() => {
            dispatch(transactionFeeError());
        });
}