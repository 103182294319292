import {ReduxTransferSearchResults} from "../../proptypes/PropTypeRedux";
import TransferSearchResultsTypes from "../constants/transferSearchResults.constants";
import TransferSearchResultsActions from "./actions/transferSearchResults.reducer.actions";

export const INITIAL_STATE = {
    transferOffers: [],
    firstTimeMounted: false,
    allResultsCount: 0,
    size: 10,
    pageSizeOptions: [
        {
            value: 50,
            label: 50
        },
        {
            value: 25,
            label: 25
        },
        {
            value: 10,
            label: 10
        }
    ],
    pageNumber: 1,
    sortBy: {
        value: "price",
        label: "Price"
    },
    sortByOptions: [
        {
            value: "price"
        },
        {
            value: "transferName"
        }
    ]
} as ReduxTransferSearchResults;

export default (state = INITIAL_STATE, action: TransferSearchResultsActions): ReduxTransferSearchResults => {
    switch (action.type) {
    case TransferSearchResultsTypes.FIRST_TIME_MOUNTED: {
        return {
            ...state,
            firstTimeMounted: true
        };
    }

    case TransferSearchResultsTypes.SEARCH_RESULTS_RECIEVED:
        return {
            ...state,
            transferOffers: action.transferOffers
        };
    case TransferSearchResultsTypes.CHANGE_ALL_RESULTS_COUNT:
        return {
            ...state,
            allResultsCount: action.allResultsCount
        };
    case TransferSearchResultsTypes.RESET_ALL_DATA:
        return {...INITIAL_STATE};

    case TransferSearchResultsTypes.CHANGE_PAGE_NUMBER:
        return {
            ...state,
            pageNumber: action.pageNumber
        };
    case TransferSearchResultsTypes.CHANGE_SORT_VALUE:
        return {
            ...state,
            sortBy: action.sortBy
        };
    case TransferSearchResultsTypes.CHANGE_PAGE_SIZE: {
        return {
            ...state,
            size: action.size
        };
    }

    default:
        return state;
    }
};
