import React, {ReactElement} from "react"
import HeaderContainer from "../../../components/base/Header/HeaderContainer"
import Footer from "../../../components/base/Footer"
import Lefter from "../../../components/base/Lefter/LefterContainer"
import Righter from "../../../components/base/Righter"
import ErrorBoundary from "../../../components/utils/ErrorBoundary/ErrorBoundary"
import PageTemplate from "../../../components/base/PageTemplate/PageTemplate"
import PaymentLinkContainer from "./PaymentLinkContainer"

const PaymentLink = (): ReactElement => (
    <PageTemplate
        header={<HeaderContainer />}
        footer={<Footer />}
        lefter={<Lefter />}
        righter={<Righter />}
    >
        <ErrorBoundary>
            <PaymentLinkContainer />
        </ErrorBoundary>
    </PageTemplate>
)

export default PaymentLink;