import {ClientBookingStatusPropTypes} from "../proptypes/PropTypeObjects";
import {resetTime, todayWithoutTime} from "./dateUtils";
import createLuxonDate from "./date/createLuxonDate"

export default function canAmendBooking(status: ClientBookingStatusPropTypes, serviceStartDate: number): boolean {
    if (status !== "CONFIRMED") {
        return false;
    }

    // if (booking.getTransferBooking() == null && booking.getOffsiteTransferBooking() == null) {
    //     return false;
    // }

    return resetTime(createLuxonDate(serviceStartDate)) > resetTime(todayWithoutTime());
}
