import React, {ReactElement} from "react";

import {ReactComponent as PlaneIcon} from "assets/icons/plane departs.svg";
import {ReactComponent as BusIcon} from "assets/icons/bus.svg";
import {ReactComponent as UndergroundIcon} from "assets/icons/underground.svg";
import {ReactComponent as RestaurantIcon} from "assets/icons/restaurant.svg";
import {ReactComponent as ParkIcon} from "assets/icons/park.svg";
import {ReactComponent as LocationIcon} from "assets/icons/location.svg";
import {ReactComponent as TrainStationIcon} from "assets/icons/train_station.svg";
import {ReactComponent as SubwayIcon} from "assets/icons/location/subway.svg";
import {ReactComponent as UniversityIcon} from "assets/icons/location/university.svg";
import {ReactComponent as ChurchIcon} from "assets/icons/location/church.svg";
import {ReactComponent as LibraryIcon} from "assets/icons/location/library.svg";
import {ReactComponent as ShoppingBagIcon} from "assets/icons/location/shopping_bag.svg";
import {ReactComponent as MuseumIcon} from "assets/icons/location/museum.svg";
import {ReactComponent as TicketIcon} from "assets/icons/location/ticket.svg";
import {ReactComponent as HospitalIcon} from "assets/icons/location/hospital.svg";
import {ReactComponent as InformationIcon} from "assets/icons/location/information.svg";
import {ReactComponent as CityCenterIcon} from "assets/icons/location/city-center.svg";
import {ReactComponent as BeerIcon} from "assets/icons/location/bar.svg";
import {ReactComponent as NightlifeIcon} from "assets/icons/location/nightlife.svg";
import {ReactComponent as BeachIcon} from "assets/icons/location/beach.svg";
import {ReactComponent as AnchorIcon} from "assets/icons/location/anchor.svg";
import {ReactComponent as GolfIcon} from "assets/icons/location/golf.svg";
import {ReactComponent as TheaterIcon} from "assets/icons/location/theater.svg";
import {ReactComponent as StorefrontIcon} from "assets/icons/location/storefront.svg";
import {ReactComponent as RiverIcon} from "assets/icons/location/river.svg";
import {ReactComponent as FishIcon} from "assets/icons/location/fish.svg";
import {ReactComponent as TaxiIcon} from "assets/icons/location/taxi.svg";
import {ReactComponent as AttractionIcon} from "assets/icons/location/attraction.svg";
import {ReactComponent as StadiumIcon} from "assets/icons/location/stadium.svg";
import {ReactComponent as SwimmingIcon} from "assets/icons/location/swimming.svg";
import {ReactComponent as HorseshoeIcon} from "assets/icons/location/horse_head.svg";
import {ReactComponent as GalleryIcon} from "assets/icons/location/gallery.svg";
import {ReactComponent as CityIcon} from "assets/icons/location/city.svg";

import styles from "./Location.module.scss";
import {HotelDistancePropTypes} from "../../../../proptypes/PropTypeObjects";

export type LocationProps = {
    distances: HotelDistancePropTypes[];
};

function Location({
    distances
}: LocationProps): ReactElement {
    const getLocationIcon = (name: string) => {
        switch (name.trim()) {
        case "Airport":
            return (
                <PlaneIcon className="mr-2" />
            );
        case "Central Train Station":
        case "Train Station":
        case "Station":
            return (
                <TrainStationIcon className="mr-2" />
            );
        case "Subway Station":
            return (
                <SubwayIcon className="mr-2" />
            );
        case "Underground Station":
            return (
                <UndergroundIcon className="mr-2" />
            );
        case "Public Transport":
        case "Bus Stop":
        case "Bus Station":
            return (
                <BusIcon className="mr-2" />
            );
        case "Fair Center":
        case "Exhibition Center":
        case "Cinema":
        case "Theater/cinema":
        case "Exhibition Centre":
            return (
                <TicketIcon className="mr-2" />
            );
        case "Tourist Center":
            return (
                <InformationIcon className="mr-2" />
            );
        case "Center":
            return (
                <CityCenterIcon className="mr-2" />
            );
        case "Restaurants":
            return (
                <RestaurantIcon className="mr-2" />
            );
        case "Park":
            return (
                <ParkIcon className="mr-2" />
            );
        case "Stadium":
            return (
                <StadiumIcon className="mr-2" />
            );
        case "Amusement Park":
        case "Attraction":
            return (
                <AttractionIcon className="mr-2" />
            );
        case "Taxi":
            return (
                <TaxiIcon className="mr-2" />
            );
        case "Pub":
        case "Bar":
        case "Bar / Pub":
            return (
                <BeerIcon className="mr-2" />
            );
        case "Entertainment District":
        case "Nightlife":
        case "Disco / Club":
        case "Nightclub":
            return (
                <NightlifeIcon className="mr-2" />
            );
        case "Beach":
            return (
                <BeachIcon className="mr-2" />
            );
        case "Gallery":
            return (
                <GalleryIcon className="mr-2" />
            );
        case "Marina":
        case "Port":
            return (
                <AnchorIcon className="mr-2" />
            );
        case "Horse ride":
            return (
                <HorseshoeIcon className="mr-2" />
            );
        case "University":
            return (
                <UniversityIcon className="mr-2" />
            );
        case "Golf Course":
        case "Golf":
            return (
                <GolfIcon className="mr-2" />
            );
        case "Aquarium":
            return (
                <FishIcon className="mr-2" />
            );
        case "Watersports":
        case "Water park":
            return (
                <SwimmingIcon className="mr-2" />
            );
        case "City":
            return (
                <CityIcon className="mr-2" />
            );
        case "Monastery":
        case "Church":
            return (
                <ChurchIcon className="mr-2" />
            );
        case "Clinic":
        case "Doctor":
        case "Pharmacy":
        case "Medical facility":
        case "Dialysis":
        case "Hospital":
            return (
                <HospitalIcon className="mr-2" />
            );
        case "Library":
            return (
                <LibraryIcon className="mr-2" />
            );
        case "Shopping":
            return (
                <ShoppingBagIcon className="mr-2" />
            );
        case "Theater":
            return (
                <TheaterIcon className="mr-2" />
            );
        case "Market":
            return (
                <StorefrontIcon className="mr-2" />
            );
        case "Lake":
        case "Sea":
        case "River":
            return (
                <RiverIcon className="mr-2" />
            );
        case "Museum":
            return (
                <MuseumIcon className="mr-2" />
            );
        default:
            return (
                <LocationIcon className="mr-2" />
            );
        }
    };

    return (
        <div className={styles.Root}>
            {distances.map(({
                name,
                value,
                description
            }: HotelDistancePropTypes) => (((name === "Interesting place" || name === "Place of interest") && !description) ? undefined : (
                <div key={name} className={styles.LocationItem}>
                    <div className={styles.Icon}>{getLocationIcon(name)}</div>
                    <div className={styles.Description}>{value + " " + name + (description ? " (" + description + ")" : "")}</div>
                </div>
            )))}
        </div>
    );
}

export default Location;
