import React, {ReactElement} from "react";

import {ReactComponent as PlaneIcon} from "assets/icons/plane departs.svg";
import {ReactComponent as BusIcon} from "assets/icons/bus.svg";
import {ReactComponent as UndergroundIcon} from "assets/icons/underground.svg";
import {ReactComponent as RestaurantIcon} from "assets/icons/restaurant.svg";
import {ReactComponent as ParkIcon} from "assets/icons/park.svg";
import {ReactComponent as LocationIcon} from "assets/icons/location.svg";

import {HotelDistancePropTypes} from "../../../../../proptypes/PropTypeObjects";
import styles from "./Location.module.scss";

export type LocationProps = {
    distances: HotelDistancePropTypes[];
};

function Location({
    distances
}: LocationProps): ReactElement {
    const renderLocationItem = (icon: ReactElement, text: string, key: string) => (
        <div key={key} className={styles.Root}>
            {icon}
            {text}
        </div>
    );

    return (
        <>
            {
                distances.map(({
                    name,
                    value
                }, i) => {
                    const text = value + " " + name;

                    switch (name) {
                    case "Airport":
                        return renderLocationItem((<PlaneIcon />), text, name);
                    case "Train Station":
                    case "Central Train Station":
                    case "Station":
                    case "Underground Station":
                        return renderLocationItem((<UndergroundIcon />), text, name);
                    case "Bus Station":
                        return renderLocationItem((<BusIcon />), text, name);
                    case "Restaurants":
                        return renderLocationItem((<RestaurantIcon />), text, name);
                    case "Park":
                        return renderLocationItem((<ParkIcon />), text, name);
                    default:
                        return renderLocationItem((<LocationIcon />), text, name);
                    }
                })
            }
        </>
    );
}

export default Location;
