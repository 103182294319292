import _ from "lodash";
import {CriteriaRoomPropTypes} from "../../../proptypes/PropTypeObjects";
import {setUrlParamArray, SIMPLE_ARRAY_PARAM_RESOLVER} from "../queryParamsUtils";
import {QueryParamSerializerDeserializerResolver, QueryParamsObject} from "../queryParamsTypes";

const RoomSerializer: QueryParamSerializerDeserializerResolver = {
    serializer: (query: QueryParamsObject, param: string, object: CriteriaRoomPropTypes[]) => {
        if (!param) {
            return;
        }

        if (!object || _.isEmpty(object)) {
            return;
        }

        const serialized = object.map(((objProp) => {
            const {adults, children} = objProp;
            if (!children || _.isEmpty(children)) {
                return _.toString(adults) + "|";
            }

            return _.toString(objProp.adults) + "|" + objProp.children.join(",");
        }));

        setUrlParamArray(query, param, serialized);
    },

    resolver: SIMPLE_ARRAY_PARAM_RESOLVER("r"),
    deserializer: (query: QueryParamsObject, param: string): CriteriaRoomPropTypes => {
        const val = query[param];
        const split = (val).split("|");
        const adults = +split[0];

        let children: number[] | undefined;
        if (!_.isEmpty(split[1])) {
            children = split[1]?.split(",")?.map((child) => _.toNumber(child));
        } else {
            children = [];
        }

        return {adults, children} as CriteriaRoomPropTypes;
    },
    array: true
};

export default RoomSerializer;