import React, {ReactElement} from "react";
import {useTranslation} from "react-i18next";
import {BreadcrumbSharedProps} from "../Breadcrumb/Breadcrumb.base";
import Breadcrumb from "../Breadcrumb/Breadcrumb";
import {matchPath} from "react-router-dom"

const ContactsBreadcrumbs = ({
    location,
    lastLocation
}: BreadcrumbSharedProps): ReactElement => {
    const {t} = useTranslation();

    if (!matchPath("/contacts/*", location.pathname)) {
        return (
            <></>
        );
    }

    return (
        <Breadcrumb
            location={location}
            lastLocation={lastLocation}
            previousLocationLabel={t("b_c_contacts")}
            crumbs={[
                {pathName: "/contacts/payment", child: t("b_c_contacts_payment")}
            ]}
        />
    );
};

export default ContactsBreadcrumbs;