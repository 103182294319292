import React, {ReactElement} from "react";
import {Transition} from "react-transition-group";

export enum SlideInSideEnum {
    TOP = "top",
    BOTTOM = "bottom",
    LEFT = "left",
    RIGHT = "right"
}

export type SlideInProps = {
    children: ReactElement | ReactElement[];
    side: SlideInSideEnum;
    inProp?: boolean;
    duration?: number;
    timeout?: number;
};

const SlideIn = ({
    children,
    side,
    inProp = false,
    duration = 300,
    timeout = 0
}: SlideInProps): ReactElement => {
    const defaultStyle = {
        transition: `transform ${duration}ms ease-in-out`,
        willChange: "transform"
    };

    const transform = new Map<SlideInSideEnum, { transform: string }>([
        [SlideInSideEnum.TOP, {transform: "translateY(-100%)"}],
        [SlideInSideEnum.BOTTOM, {transform: "translateY(100%)"}],
        [SlideInSideEnum.LEFT, {transform: "translateX(100%)"}],
        [SlideInSideEnum.RIGHT, {transform: "translateX(-100%)"}]
    ]);

    const transitionStyles = new Map<string, { transform: string }>([
        ["entering", transform.get(side)!],
        ["entered", {transform: "translate(0, 0)"}],
        ["exiting", {transform: "translate(0, 0)"}],
        ["exited", transform.get(side)!]
    ]);

    return (
        <Transition
            in={inProp}
            timeout={timeout}
        >
            {(state) => (
                <div style={{
                    ...defaultStyle,
                    ...transitionStyles.get(state)
                }}
                >
                    {children}
                </div>
            )}
        </Transition>
    );
};

export default SlideIn;