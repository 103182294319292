import React, {ReactElement} from "react";
import {useTranslation} from "react-i18next";
import {ReconfirmationDetailsPropTypes} from "proptypes/PropTypeObjects";
import styles from "./ReconfirmationDetails.module.scss";
import useLocaleDateTimeFormat, {
    LocaleDateFormatTypeEnum
} from "../../../../utils/Internationalization/useLocaleDateTimeFormat";
import createLuxonDate from "../../../../../utils/date/createLuxonDate"

export type ReconfirmationDetailsProps = {
    reconfirmation: ReconfirmationDetailsPropTypes;
};

export default function ReconfirmationDetails({reconfirmation}: ReconfirmationDetailsProps): ReactElement {
    const {t} = useTranslation();
    const dateFormat = useLocaleDateTimeFormat(LocaleDateFormatTypeEnum.DATE);

    return (
        <div className={styles.Root}>
            <p className={styles.Header}>{t("r_d_header")}</p>

            <dl>
                {reconfirmation.reconfirmDate && (
                    <>
                        <dt>{t("r_d_reconfirmation_date")}:</dt>
                        <dd>{createLuxonDate(reconfirmation.reconfirmDate).toFormat(dateFormat)}</dd>
                    </>
                )}

                {reconfirmation.propertyReferenceNumber && (
                    <>
                        <dt>{t("r_d_reconfirmation_property_number")}:</dt>
                        <dd>{reconfirmation.propertyReferenceNumber}</dd>
                    </>
                )}
            </dl>
        </div>
    );
}