import React, {ReactElement} from "react";
import HotelModal from "./HotelModal";
import {HotelPropTypes} from "../../../proptypes/PropTypeObjects";
import Spinner from "../../base/Loaders/Spinner";

type HotelContainerProps = {
    toggleHotel: (open: boolean) => void;
    hotel?: HotelPropTypes;
};

const HotelContainer = (props: HotelContainerProps): ReactElement => {
    const {
        toggleHotel,
        hotel
    } = props;

    if (!hotel) {
        return (
            <Spinner
                size={25}
            />
        );
    }

    return (
        <HotelModal
            data={{
                hotel
            }}
            toggleHotel={toggleHotel}
        />
    );
};

export default HotelContainer;
