import getDistanceBetweenCoordinates from "./getDistanceBetweenCoordinates";

export default function getStandardDeviation(
    hotels: { coordinates: { latitude: number, longitude: number } }[],
    maxDistanceFromAverage: number,
    avarageDistance: number,
    centerLat: any,
    centerLon: any
): number {
    let stdDevDist = 0;
    let count = 0;

    for (let i = 0, len = hotels.length; i < len; ++i) {
        const dist = getDistanceBetweenCoordinates(centerLat, centerLon, hotels[i].coordinates.latitude, hotels[i].coordinates.longitude);

        if (dist > maxDistanceFromAverage) {
            continue;
        }

        stdDevDist += (dist - avarageDistance) * (dist - avarageDistance);
        count += 1;
    }

    stdDevDist /= (count - 1);

    return Math.sqrt(stdDevDist);
}