import React, {ReactElement, useCallback, useState} from "react";
import {ReactComponent as DelIcon} from "assets/icons/delete.svg";
import cx from "classnames";
import ConfirmationModal from "components/common/ConfirmationModal";
import SuccessModal from "components/common/ConfirmationModal/SuccessModal";
import {useTranslation} from "react-i18next";
import {useDeleteMarkupAction} from "components/utils/useCompanyData";
import styles from "./DeleteMarkup.module.scss";

function DeleteMarkup({
    className,
    deleteCompanyMarkup,
    markupId
}: any): ReactElement {
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [successModal, setSuccessModal] = useState(false);
    const [deletedId, setDeletedId] = useState<number>();

    const onSuccess = useCallback((id: React.SetStateAction<number | undefined>) => {
        // deleteCompanyMarkup(id);
        setDeletedId(id);
        setModalIsOpen(false);
        setSuccessModal(true);
    }, []);

    const {
        requesting,
        errors,
        deleteMarkup: deleteMarkupAction,
        clearState
    } = useDeleteMarkupAction(onSuccess);

    const {t} = useTranslation();

    return (
        <div className={cx(className, styles.root)}>
            {modalIsOpen && (
                <ConfirmationModal
                    onConfirm={() => deleteMarkupAction(markupId)}
                    onDecline={() => setModalIsOpen(false)}
                    config={{
                        variants: {
                            type: "error"
                        },
                        title: t("mc_edm_dm_delete_markup"),
                        content: (
                            <span>
                                {t("mc_edm_dm_confimation_question_delete_markup")}
                            </span>
                        ),
                        errors,
                        spinner: requesting
                    }}
                />
            )}

            {successModal && (
                <SuccessModal
                    onClose={() => {
                        setSuccessModal(false);
                        deleteCompanyMarkup(deletedId);
                    }}
                    successMessage={t("mc_edm_dm_success_message_delete_user")}
                />
            )}

            <DelIcon onClick={() => {
                clearState();
                setModalIsOpen(true);
            }}
            />
        </div>
    );
}

export default DeleteMarkup;
