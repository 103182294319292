import React, {ReactElement} from "react"
import {useTranslation} from "react-i18next"
import styles from "./TokenExpired.module.scss"
import InfoBox from "../../../../components/common/InfoBox"

export const TokenExpired = (): ReactElement => {
    const {t} = useTranslation();

    return (
        <div className={styles.Root}>
            <h1>{t("pc_pc_pl_payment_link_expired_header")}</h1>

            <InfoBox
                type="error"
                title={t("pc_pc_pl_payment_link_expired")}
            />
        </div>
    )
};

export default TokenExpired;