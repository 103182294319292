import {Location} from "react-router-dom"
import IAppActions from "redux/store/store.actions"
import AuthTypes from "redux/constants/auth.constants"
import {CommonActionReturnType} from "redux/store/store.init"
import router from "views/router/router"

function on401SetLocation(location: Location): IAppActions {
    return ({
        type: AuthTypes.ON_401_SET_LAST_ROUTE,
        location
    });
}

export function setRedirectedAfter401(val: boolean): IAppActions {
    return ({
        type: AuthTypes.REDIRECTED_AFTER_401,
        redirectedAfter401: val
    })
}

export function on401Error(oldLocation?: Location, route?: string): CommonActionReturnType {
    return (dispatch, getState) => {
        dispatch(on401SetLocation(oldLocation || router.state.location));

        const currLocation = router.state.location.pathname;
        const alreadyRedirected = getState().auth.redirectedAfter401;
        if (currLocation !== route && !alreadyRedirected) {
            if (route) {
                router.navigate(route);
                dispatch(setRedirectedAfter401(true));
            }
        }
    };
}