import React, {CSSProperties, ReactElement, ReactNode} from "react";
import cx from "classnames";
import styles from "./MainContainer.module.scss";

type Props = {
    sidebar?: ReactNode,
    children?: ReactNode,
    className?: string,
    containerClassName?: string,

    variants?: {
        fluid?: boolean,
        style?: CSSProperties,
        mainStyle?: CSSProperties,
        sidebarStyle?: CSSProperties,
        center?: boolean
    }
};

function MainContainer({
    sidebar,
    children,
    className,
    containerClassName,

    variants = {
        fluid: false,
        style: {},
        mainStyle: {},
        sidebarStyle: {},
        center: false
    }
}: Props): ReactElement {
    return (
        <div
            style={variants.style}
            className={cx(
                styles.Root,
                variants.fluid && styles.Fluid,
                variants.center && styles.CenterContent,
                className
            )}
        >
            <div className={cx(styles.Container, containerClassName)}>
                <div
                    className={cx(
                        styles.Main,
                        sidebar && styles.WithSidebar
                    )}
                    style={variants.mainStyle}
                >
                    {children}
                </div>

                {sidebar && (
                    <div
                        style={variants.sidebarStyle}
                        className={styles.Sidebar}
                    >
                        <div>
                            {sidebar}
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
}

export default MainContainer;
