import React, {ReactElement} from "react";
import cx from "classnames";
import styles from "./SkeletonLoader.module.scss";

export type SkeletonLoaderProps = {
    rows?: number;
    children?: ReactElement | (ReactElement | undefined)[];
    rowHeight?: number;
    className?: string;
};

export default function SkeletonLoader({
    rows = 1,
    children,
    className
}: SkeletonLoaderProps): ReactElement {
    const rowElements: ReactElement[] = [];
    if (!children) {
        for (let i = 0; i < rows; i++) {
            rowElements.push((
                <div
                    style={{
                        width: ((100 / rows) * (i + 1)) + "%"
                    }}
                    className={styles.Row}
                />
            ));
        }
    }

    return (
        <div className={cx(styles.Root, className)}>
            {children || rowElements}
        </div>
    );
}