import {createSelectorCreator, defaultMemoize} from "reselect";
import {isEqual} from "lodash";
import * as sort from "../../utils/sortFunction";
import {TransferOfferPropTypes} from "../../proptypes/PropTypeObjects";
import {ReduxTransferSearchResults} from "../../proptypes/PropTypeRedux";

const createDeepEqualSelector = createSelectorCreator(
    defaultMemoize,
    isEqual
); // for deep copy

const sizeSelector = (state: ReduxTransferSearchResults) => state.size;
const pageNumberSelector = (state: ReduxTransferSearchResults) => state.pageNumber;
const sortBySelector = (state: ReduxTransferSearchResults) => state.sortBy.value;
const transferOffersSelector = (state: ReduxTransferSearchResults) => state.transferOffers;

type VisibleTransferOffersSelector = (state: ReduxTransferSearchResults) => TransferOfferPropTypes[] | undefined;
export const visibleTransferOffersSelector = (): VisibleTransferOffersSelector => createDeepEqualSelector(
    [
        transferOffersSelector,
        sortBySelector,
        pageNumberSelector,
        sizeSelector
    ],
    (transferOffers, sortBy, pageNumber, size) => {
        const sortedTransfers = getSortedTransfers(transferOffers || null, sortBy);
        return getPage(sortedTransfers || null, size, pageNumber);
    }

);

const getPage = (transferOffers: TransferOfferPropTypes[] | null, size: number, page: number): TransferOfferPropTypes[] | undefined => {
    if (!transferOffers) {
        return undefined;
    }
    return transferOffers.slice((page - 1) * size, page * size);
};

export const getPagesCount = (resultsCount: number, size: number): number => (Math.ceil(resultsCount / size) > 0 ? Math.ceil(resultsCount / size) : 1);

const getSortedTransfers = (transfers: TransferOfferPropTypes[] | null, sortBy: string): TransferOfferPropTypes[] | undefined => {
    if (!transfers) {
        return undefined;
    }
    switch (sortBy) {
    case "price":
        return transfers.sort((a: TransferOfferPropTypes, b: TransferOfferPropTypes) => sort.compareNumbers(a.displaySalePrice, b.displaySalePrice));
    case "transferName":
        return transfers.sort((a: TransferOfferPropTypes, b: TransferOfferPropTypes) => sort.localeCompare(a.transferType.name, b.transferType.name));
    default:
        return transfers;
    }
};
