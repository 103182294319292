import {ReduxPaymentPropTypes} from "proptypes/PropTypeRedux";
import {createSelector} from "reselect";
import _ from "lodash";

export const paymentPendingSelector = (state: ReduxPaymentPropTypes): number => state.bookingOptions?.paymentPending || state.bookingOptions?.salePrice || 0;
const appliedDiscountSelector = (state: ReduxPaymentPropTypes): number => state.appliedDiscountAmount || 0;
const appliedVoucherSelector = (state: ReduxPaymentPropTypes): number => state.voucherSum.used || 0;
const appliedWalletSelector = (state: ReduxPaymentPropTypes): number => state.appliedWalletAmount || 0;
const smsFeeSelector = (state: ReduxPaymentPropTypes): number => state.bookingOptions?.displaySmsFee || 0;
const discountCodeSelector = (state: ReduxPaymentPropTypes): number => state.appliedDiscountCode?.discountSum || 0;

type PaymentPendingAfterConversions = (state: ReduxPaymentPropTypes) => number;
export const paymentPendingAfterConversionsSelector: PaymentPendingAfterConversions = createSelector(
    paymentPendingSelector,
    appliedDiscountSelector,
    appliedVoucherSelector,
    appliedWalletSelector,
    smsFeeSelector,
    discountCodeSelector,
    (paymentPending, discount, voucher, wallet, smsFee, discountCode) => {
        let paymentPendingAfterConv = paymentPending;
        [discount, voucher, wallet, smsFee, discountCode].filter((fee) => !!fee)
            .filter((fee) => _.round(fee, 2) > 0)
            .forEach((fee) => {
                paymentPendingAfterConv -= fee;
            });

        return paymentPendingAfterConv;
    }
);