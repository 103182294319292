import React, {ReactElement} from "react"
import Pricing from "components/common/Pricing"
import {useTranslation} from "react-i18next"
import cx from "classnames"
import styles from "./PaymentSummary.module.scss"
import {BookingListItemPropTypes} from "proptypes/PropTypeObjects"
import useLocaleDateTimeFormat, {
    LocaleDateFormatTypeEnum
} from "components/utils/Internationalization/useLocaleDateTimeFormat"
import {getPriceWithCurrencySymbol} from "@hotelston_web_frontend_utils/currency/getPriceWithCurrency"
import createLuxonDate from "../../../utils/date/createLuxonDate"
import usePaymentOptions from "components/base/Payment/PaymentType/usePaymentOptions";
import useDefaultFeatureEnabled from "utils/hooks/useDefaultFeatureEnabled";
import {useNavigate} from "react-router-dom";

export type BookingSummaryProps = {
    activeOrders: BookingListItemPropTypes[],
    paymentPending: number,
    splitPaymentEnabled?: boolean
};

function BookingSummary({
    activeOrders = [],
    paymentPending
}: BookingSummaryProps): ReactElement {
    const {t} = useTranslation();
    const navigate = useNavigate();
    const {featureEnabled} = useDefaultFeatureEnabled("split-price");

    const {
        discount,
        voucherSum
    } = usePaymentOptions();

    const dateFormat = useLocaleDateTimeFormat(LocaleDateFormatTypeEnum.DATE);

    return (
        <div className={styles.Root}>
            <h3 className={styles.Title}>
                {t("mb_ps_booking_summary")}
                {/*<Link className={styles.BackLink} to="/my-bookings">*/}
                {/*    {t("mb_ps_back_to_booking")}*/}
                {/*</Link>*/}
            </h3>

            {activeOrders.length === 1 && activeOrders.map(({
                id,
                currency: curr,
                price,
                paymentPending,
                serviceName,
                serviceStartDate,
                serviceEndDate,
                leadPassenger
            }) => (
                <div key={id} className={cx(styles.Box, styles.Single)}>
                    <h4>{t("mb_ps_booking_id", {id: id})}</h4>

                    <dl>
                        <dt>{t("mb_ps_single_type_service")}</dt>
                        <dd>{serviceName}</dd>

                        <dt>{t("mb_ps_single_lead_passenger")}</dt>
                        <dd>{leadPassenger?.title} {leadPassenger?.firstname} {leadPassenger?.lastname}</dd>

                        <dt>{t("mb_ps_single_service_start")}</dt>
                        <dd>{createLuxonDate(serviceStartDate).toFormat(dateFormat)}</dd>

                        <dt>{t("mb_ps_single_service_end")}</dt>
                        <dd>{createLuxonDate(serviceEndDate).toFormat(dateFormat)}</dd>

                        <dt>{t("mb_ps_single_price")}</dt>
                        <dd>{getPriceWithCurrencySymbol(paymentPending, curr)}</dd>
                    </dl>
                </div>
            ))}

            {activeOrders.length > 1 && activeOrders.map(({
                id, currency: curr, price, paymentPending
            }) => (
                <div key={id} className={styles.Box}>
                    <span>{t("mb_ps_booking_id", {id: id})}</span>

                    <span>
                        {getPriceWithCurrencySymbol(paymentPending, curr, 2)}
                    </span>
                </div>
            ))}

            <div
                style={{
                    marginTop: 30,
                    marginBottom: 30
                }}
                className="h-divider"
            />

            <Pricing
                displaySalePrice={paymentPending}
                greyLabels
                salePrice={paymentPending}
                paymentPending={paymentPending}
                discount={discount}
                voucher={voucherSum.used}
            />
        </div>
    );
}

export default BookingSummary;
