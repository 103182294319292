import React, {ReactElement} from "react";
import cx from "classnames";
import {connect} from "react-redux";
import _ from "lodash";
import styles from "./SearchResultsLefter.module.scss";
import {RootState} from "../../../../redux/store/store.init";
import {ReactComponent as CrossIcon} from "../../../../assets/icons/cross.svg";
import {toggleModal} from "../../../../redux/actions/modals.actions";
import {ReduxModalType} from "../../../../proptypes/PropTypeObjects";
import Modal from "../../../common/Modal";

export type SearchResultsLefterProps = {
    children?: ReactElement[] | ReactElement;
    style?: React.CSSProperties;

    modal?: ReduxModalType;
    toggleModal: (modal: ReduxModalType) => void;
};

function SearchResultsLefter({
    children,
    style = {},

    modal,
    toggleModal
}: SearchResultsLefterProps): ReactElement {
    if (_.eq(modal, ReduxModalType.FILTERS)) {
        return (
            <Modal>
                <div className={cx(styles.SearchResultsLefter, styles.ModalActive)}>
                    <CrossIcon
                        className={styles.CloseModalIcon}
                        onClick={() => toggleModal(ReduxModalType.FILTERS)}
                    />

                    <div style={style} className={styles.LefterWrapper}>
                        {children}
                    </div>
                </div>
            </Modal>
        );
    }

    return (
        <div className={styles.SearchResultsLefter}>
            <CrossIcon
                className={styles.CloseModalIcon}
                onClick={() => toggleModal(ReduxModalType.FILTERS)}
            />

            <div style={style} className={styles.LefterWrapper}>
                {children}
            </div>
        </div>
    );
}

const mapStateToProps = (state: RootState) => ({
    modal: state.modals.currentActiveModal
});

const connector = connect(mapStateToProps, {toggleModal});
export default connector(SearchResultsLefter);