enum TransferSearchResultsTypes {
    FIRST_TIME_MOUNTED = "transferSearchResults/FIRST_TIME_MOUNTED",

    RESET_ALL_DATA = "transferSearchResults/RESET_ALL_DATA",

    SEARCH_RESULTS_RECIEVED = "transferSearchResults/SEARCH_RESULTS_RECIEVED",

    // pagination
    CHANGE_PAGE_NUMBER = "transferSearchResults/CHANGE_PAGE_NUMBE",
    CHANGE_PAGE = "transferSearchResults/CHANGE_PAGE",

    //change sort
    CHANGE_SORT_VALUE = "transferSearchResults/CHANGE_SORT_VALUE",

    //change items per page

    CHANGE_PAGE_SIZE = "transferSearchResults/CHANGE_PAGE_SIZE",
    CHANGE_ALL_RESULTS_COUNT = "transferSearchResults/CHANGE_ALL_RESULTS_COUNT"
}

export default TransferSearchResultsTypes;