import { ReduxContactsPropTypes } from "proptypes/PropTypeRedux"
import ContactsActionTypes from "redux/reducers/actions/contacts.reducer.actions"
import ContactsTypes from "redux/constants/contacts.constants"

const INITIAL_STATE = {} as ReduxContactsPropTypes;

const contacts = (state = INITIAL_STATE, action: ContactsActionTypes) => {
    switch (action.type) {
        case ContactsTypes.REQ_PAYMENT_ACCOUNTS:
            return {
                ...state,
                loading: true
            };
        case ContactsTypes.SET_PAYMENT_ACCOUNTS:
            return {
                ...state,
                loading: false,
                paymentAccounts: action.resp
            }
        case ContactsTypes.ERR_PAYMENT_ACCOUNTS:
            return {
                ...state,
                loading: false
            };
        default:
            return state;
    }
}

export default contacts