import React, {
    ReactElement, useCallback, useReducer, useState
} from "react";
import cx from "classnames";
import Input from "components/base/Input";
import CustomCheckBox from "components/base/Input/CustomCheckBox";
import Edit from "components/common/Edit";
import ConfirmationModal from "components/common/ConfirmationModal";
import {ReactComponent as EditIcon} from "assets/icons/edit.svg";
// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module 'assets/images/photo.JPG' or it... Remove this comment to see the full error message
import photoIconSrc from "assets/images/photo.JPG";
import {useTranslation} from "react-i18next";
import SuccessModal from "components/common/ConfirmationModal/SuccessModal";
import {useUpdateCompanyDetailsAction} from "components/utils/useCompanyData";
import styles from "./EditCompanyDetails.module.scss";
import {EMAIL_PATTERN, PHONE_PATTERN} from "../../../utils/validateForm";
import {CompanyDetails} from "../../../proptypes/PropTypeObjects";
import useDefaultFeatureEnabled from "../../../utils/hooks/useDefaultFeatureEnabled";

type ActionProps =
    | {type: "HANDLE_INPUT", name: string, value: string | boolean}
    | {type: "HANDLE_COUNTRY", country: string, countryId: number}
;

function reducer(state: CompanyDetails, action: ActionProps): CompanyDetails {
    switch (action.type) {
    case "HANDLE_INPUT":
        return {
            ...state,
            [action.name]: action.value
        };
    case "HANDLE_COUNTRY":
        return {
            ...state,
            countryId: action.countryId
        };
    default:
        return state;
    }
}

type EditModalProps = {
    companyDetails: CompanyDetails;
    onConfirm: (localFormData: CompanyDetails) => void;
    onDecline: () => void;
    errors?: string[];
    spinner: boolean;
    hasSignedMainAgreement: boolean;
};

function EditCompanyDetailsModal({
    onConfirm,
    onDecline,
    companyDetails,
    errors,
    spinner,
    hasSignedMainAgreement
}: EditModalProps): ReactElement {
    const {t} = useTranslation();

    const [localFormData, dispatch] = useReducer(reducer, companyDetails);
    const {featureEnabled: amadeusEnabled} = useDefaultFeatureEnabled("amadeusLine");

    const handleInput = ({
        target: {name, value}
    }: React.ChangeEvent<HTMLInputElement>) => dispatch({
        type: "HANDLE_INPUT",
        name,
        value
    });

    const content = (
        <div className={styles.Content}>
            <Input
                fullHeight
                variants={{labelPosition: "outlined"}}
                inputProps={{
                    type: "text",
                    name: "companyName",
                    required: true,
                    placeholder: t("mc_ecd_company_name"),
                    onChange: handleInput,
                    value: localFormData.companyName,
                    disabled: hasSignedMainAgreement
                }}
            />

            <Input
                className="mb-40"
                fullHeight
                variants={{labelPosition: "outlined"}}
                helperText={t("mc_ecd_company_email_helper")}
                inputProps={{
                    type: "email",
                    name: "email",
                    required: true,
                    pattern: EMAIL_PATTERN,
                    placeholder: t("mc_ecd_company_email"),
                    onChange: handleInput,
                    value: localFormData.email
                }}
            />
            <Input
                className="mb-60"
                fullHeight
                variants={{labelPosition: "outlined"}}
                helperText={t("mc_ecd_company_phone_number_helper")}
                inputProps={{
                    type: "tel",
                    name: "phone",
                    required: true,
                    pattern: PHONE_PATTERN,
                    placeholder: t("mc_ecd_company_phone_number"),
                    onChange: handleInput,
                    value: localFormData.phone
                }}
            />

            {amadeusEnabled && (
                <>
                    <h4 className="mb-20 text-center">{t("mc_ecd_company_amadeus_settings")}</h4>
                    <Input
                        className="mb-60"
                        fullHeight
                        variants={{labelPosition: "outlined"}}
                        //helperText={t("mc_ecd_company_amadeus_supplier_code_helper")}
                        inputProps={{
                            type: "text",
                            name: "amadeusSupplierCode",
                            placeholder: t("mc_ecd_company_amadeus_supplier_code"),
                            onChange: handleInput,
                            value: localFormData.amadeusSupplierCode
                        }}
                    />
                </>
            )}
        </div>
    );

    return (
        <ConfirmationModal
            onConfirm={() => onConfirm(localFormData)}
            onDecline={onDecline}
            config={{
                variants: {
                    type: "normal"
                },
                title: t("mc_ecd_edit_company_details"),
                content,
                isForm: true,
                validateForm: true,
                confirmButtonText: t("mc_ecd_save_changes"),
                declineButtonText: t("mc_ecd_cancel_button"),
                errors,
                spinner
            }}
        />
    );
}

type Props = {
    companyDetails: CompanyDetails;
    updateCompanyDetails: (companyDetails: CompanyDetails) => void;
    hasSignedMainAgreement: boolean;
    className: string;
};

function EditCompanyDetails({
    companyDetails,
    updateCompanyDetails,
    hasSignedMainAgreement,
    className
}: Props): ReactElement {
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [successModal, setSuccessModal] = useState(false);
    const onlyIcon = false;
    const onSuccess = useCallback((companyDetails: CompanyDetails) => {
        setModalIsOpen(false);
        setSuccessModal(true);
        updateCompanyDetails(companyDetails);
    }, [updateCompanyDetails]);

    const {t} = useTranslation();

    const {
        requesting,
        errors,
        updateCompanyDetails: updateCompanyDetailsAction
    } = useUpdateCompanyDetailsAction(onSuccess);

    return (
        <div className={styles.Root}>
            {modalIsOpen && (
                <EditCompanyDetailsModal
                    companyDetails={companyDetails}
                    onConfirm={(localFormData) => { updateCompanyDetailsAction(localFormData); }}
                    onDecline={() => { setModalIsOpen(false); }}
                    errors={errors}
                    spinner={requesting}
                    hasSignedMainAgreement={hasSignedMainAgreement}
                />
            )}
            {successModal && (
                <SuccessModal
                    onClose={() => setSuccessModal(false)}
                    successMessage={t("mc_ecd_success_message_company_details")}
                />
            )}
            {onlyIcon ? (
                <div
                    className={className}
                    onClick={() => setModalIsOpen(true)}
                    style={{
                        cursor: "pointer",
                        display: "inline-block",
                        height: "12px",
                        width: "12px"
                    }}
                >
                    <EditIcon />
                </div>
            ) : (
                <Edit
                    className={className}
                    onClick={() => setModalIsOpen(true)}
                    what={t("mc_ecd_edit_details_button")
                        .toLowerCase()}
                    style={{
                        fontWeight: 600,
                        lineHeight: "22px",
                        fontSize: "12px"
                    }}
                />
            )}
        </div>
    );
}

export default EditCompanyDetails;
