import React, {ReactElement} from "react";
import cx from "classnames";
import {useTranslation} from "react-i18next";
import Tippy from "../Tippy/Tippy";
import styles from "./MultiSelectBoxes.module.scss";

function MultiSelectBoxes({
    className,
    activeValues,
    values,
    onBoxSelect,
    label = ""
}: any): ReactElement {
    const {t} = useTranslation();

    return (
        <div className={cx(styles.Root, className)}>
            {label && <span className={styles.Label}>{label}</span>}

            {values.map((box: any, i: number) => {
                const multiSelectClass = cx(
                    styles.Item,
                    ((activeValues.length === 0 && box.value === "All") || activeValues.includes(box.value)) && styles.Active
                );

                if (box.tooltipText) {
                    return (
                        <Tippy key={i} content={<div>{t(box.tooltipText)}</div>}>
                            <span
                                key={i}
                                onClick={() => onBoxSelect(box.value, !activeValues.includes(box.value))}
                                className={multiSelectClass + " " + className}
                            >
                                {t(box.label)}
                            </span>
                        </Tippy>
                    );
                }

                return (
                    <span
                        key={i}
                        onClick={() => onBoxSelect(box.value, !activeValues.includes(box.value))}
                        className={multiSelectClass + " " + className}
                    >
                        {t(box.label)}
                    </span>
                );
            })}
        </div>
    );
}

export default MultiSelectBoxes;
