import React, {memo, useCallback, useEffect, useMemo, useState} from "react"
import ResponsiveTable from "components/base/ResponsiveTable"
import PageControl from "components/common/PageControl"
import {ReactComponent as HotelIcon} from "assets/icons/bed_sign.svg"
import {ReactComponent as TransferIcon} from "assets/icons/transfer_bus.svg"
import {ReactComponent as PlusIcon} from "assets/icons/plus big.svg"
import {Trans, useTranslation} from "react-i18next";
import Tippy from "../../../components/common/Tippy/Tippy"
import {ReactComponent as DownloadIcon} from "assets/icons/download.svg"
import _ from "lodash"
import EditColumns from "../EditColumns/EditColumns"
import i18n from "../../../i18n"
import styles from "./MyBookingList.module.scss"
import {
    BookingListItemPropTypes,
    BookingTypePropTypes,
    ClientBookingStatusPropTypes,
    ColumnPropTypes,
    SortColumnPropTypes
} from "proptypes/PropTypeObjects"
import useLocaleDateTimeFormat, {
    LocaleDateFormatTypeEnum
} from "../../../components/utils/Internationalization/useLocaleDateTimeFormat"
import IAppActions from "../../../redux/store/store.actions"
import ItemsBoxWithAction from "./ItemsBoxWithAction/ItemsBoxWithAction"
import useMobileSwitch from "../../../utils/hooks/useMobileSwitch"
import {getPriceWithCurrencySymbol} from "@hotelston_web_frontend_utils/currency/getPriceWithCurrency"
import Spinner from "../../../components/base/Loaders/Spinner"
import {TableRowProps} from "components/base/ResponsiveTable/TableRow/TableRow"
import {TableRowVariant} from "components/base/ResponsiveTable/ResponsiveTable.base"
import {convertToServerTime} from "utils/dateUtils"
import {CustomTagType} from "components/utils/CustomTag"
import UserButton, {UserButtonVariant} from "../../../components/base/UserButton/UserButton"
import baseURL from "../../../api/constants/baseURL"
import CustomCheckBox from "../../../components/base/Input/CustomCheckBox"
import {useAppSelector} from "redux/hooks"
import Input from "../../../components/base/Input"
import {NavigateFunction, useNavigate} from "react-router-dom"
import createLuxonDate from "../../../utils/date/createLuxonDate";
import {TFunction} from "i18next";
import Currency from "@hotelston_web_frontend_parent/constants/currency";

const headingsTexts = {
    pay: "pay",
    reference: "booking_ref",
    serviceName: "name_of_booking",
    createDate: "create_date",
    cxlDate: "cxl",
    serviceStartDate: "service_start",
    serviceEndDate: "service_end",
    leadPassenger: "lead_passenger",
    contactPerson: "contact_person",
    price: "price",
    paymentPending: "pending",
    status: "booking_status"
};

function getBookingTypeIcon(bookingType: string) {
    switch (bookingType) {
    case "HOTEL":
        return <HotelIcon className={styles.SVGIcon}/>;
    case "TRANSFER":
        return <TransferIcon className={styles.SVGIcon}/>;
    default:
        return undefined;
    }
}

function getHeaderStyle(key: any) {
    switch (key) {
    case "createDate":
    case "serviceStartDate":
    case "serviceEndDate":
    case "price":
    case "paymentPending":
    case "cxlDate":
        return ({
            display: "block",
            textAlign: "right"
        });
    default:
        return {};
    }
}

function getValue(
    value: any,
    key: string,
    {currency, bookingType, bookingStatus}: {
        currency: Currency,
        bookingType: BookingTypePropTypes,
        bookingStatus: ClientBookingStatusPropTypes
    },
    dateFormat: string,
    t: TFunction
) {
    switch (key) {
    case "serviceName":
        return (
            <Tippy content={value}>
                <div className={styles.NameOfService}>
                    {getBookingTypeIcon(bookingType)}
                    {value}
                </div>
            </Tippy>
        );
    case "createDate":
        // return (<div className={styles.DateCell}>{moment(value).format(FALLBACK_DATE_TIME_SECONDS_FORMAT)}</div>);
        return (<div
            className={styles.DateCell}
                >{createLuxonDate(convertToServerTime(value as string)).toFormat(dateFormat)}
                </div>);
    case "cxlDate":
        return (<div
            className={styles.DateCell}
                >{createLuxonDate(convertToServerTime(value as string)).toFormat(dateFormat)}
                </div>);
    case "serviceStartDate":
        return (<div
            className={styles.DateCell}
                >{createLuxonDate(convertToServerTime(value as string)).toFormat(dateFormat)}
                </div>);
    case "serviceEndDate":
        return (<div
            className={styles.DateCell}
                >{createLuxonDate(convertToServerTime(value as string)).toFormat(dateFormat)}
                </div>);
    case "leadPassenger":
        return `${value.firstname} ${value.lastname}`;
    case "contactPerson":
        return `${value.firstname} ${value.lastname}`;
    case "price":
        return (<div className={styles.CurrencyCell}>{getPriceWithCurrencySymbol(value as number, currency)}</div>);
    case "paymentPending": {
        const paymentPending = (value as number);

        return (
            <div
                className={styles.CurrencyCell}
                style={{
                    color: paymentPending > 0 ? "#EC4331" : "#63c55e"
                }}
            >
                {getPriceWithCurrencySymbol(paymentPending, currency, 2)}
            </div>
        );
    }
    case "status": {
        const status = (value as ClientBookingStatusPropTypes);

        switch (status) {
        case "REJECTED":
            return <div style={{color: "#EC4331"}}>{t("mb_mbl_" + status.toLowerCase())}</div>;
        default:
            return i18n.t("mb_mbl_" + status.toLowerCase());
        }
    }
    default:
        return value;
    }
}

type RowProps = {
    removeActiveOrder?: (activeOrder: BookingListItemPropTypes) => IAppActions
    addActiveOrder?: (activeOrder: BookingListItemPropTypes) => IAppActions
    multipleOrders?: boolean;
    activeOrders?: BookingListItemPropTypes[];
    data: BookingListItemPropTypes;
    companyCurrency: Currency;
    navigate: NavigateFunction;
    variants: {
        withHeader?: boolean;
        withPayAction?: boolean;
    };
};

function getRowVariantAndAction({
    removeActiveOrder,
    addActiveOrder,
    multipleOrders,
    activeOrders = [],
    data,
    companyCurrency,
    navigate,
    variants
}: RowProps): { variant: TableRowVariant, action?: () => void } {
    if (variants.withPayAction) {
        return {
            variant: "Normal" as TableRowVariant
        };
    }
    if (multipleOrders && addActiveOrder && removeActiveOrder) {
        if (data.paymentPending > 0 && companyCurrency === data.currency) {
            for (let i = 0; i < activeOrders.length; i++) {
                if (data.reference === activeOrders[i].reference) {
                    return {
                        variant: "Remove" as TableRowVariant,
                        action: () => removeActiveOrder(activeOrders[i])
                    };
                }
            }
            return {
                variant: "Add" as TableRowVariant,
                action: () => addActiveOrder(data)
            };
        }
        return {
            variant: "Disabled" as TableRowVariant
        };
    }
    return {
        variant: "Link" as TableRowVariant,
        action: () => navigate(`/my-bookings/booking/${data.id}`)
    };
}

function getSortVariant(sort: any, key: any) {
    if (sort && sort.key === key) {
        return sort.order;
    }
    return undefined;
}

function handleSortChange(sort: any, key: any, callback: any) {
    if (sort && sort.key !== key) {
        callback({
            key,
            order: "DESC"
        });
    } else {
        callback({
            key,
            order: sort ? (sort.order === "DESC" ? "ASC" : "DESC") : "DESC"
        });
    }
}

type Props = {
    bookings: BookingListItemPropTypes[];
    activeOrders?: BookingListItemPropTypes [];
    companyCurrency: Currency;
    bookingsCount: number;
    variants?: {
        withHeader?: boolean;
        withPayAction?: boolean;
    };
    columns: ColumnPropTypes[];
    multipleOrders?: boolean;
    sort?: SortColumnPropTypes;
    pageSize: number;
    pageNr: number;
    handlePageNr: (pageNr: number) => void;
    handlePageSize: (pageSize: number) => void
    handleReconcilePay?: (bookingToPayId: number) => void;
    toggleMultipleOrders?: () => IAppActions
    removeActiveOrder?: (activeOrder: BookingListItemPropTypes) => IAppActions
    addActiveOrder?: (activeOrder: BookingListItemPropTypes) => IAppActions
    getMultiPaymentOptions?: (redirect?: boolean, redirectTo?: string) => void

    getBookingExcel?: () => Promise<void>;
    handleSort: (sort: any) => void;

    loading?: boolean;
};

function MyBookingList({
    variants = {
        withHeader: true
    }, companyCurrency,
    bookings,
    bookingsCount,
    columns,
    multipleOrders,
    activeOrders,
    sort,
    pageSize,
    pageNr,
    getBookingExcel,
    handleSort,
    handlePageNr,
    handlePageSize,
    handleReconcilePay,
    toggleMultipleOrders,
    removeActiveOrder,
    addActiveOrder,
    getMultiPaymentOptions,

    loading
}: Props) {
    const {t} = useTranslation();
    const dateFormat = useLocaleDateTimeFormat(LocaleDateFormatTypeEnum.DATE);
    const {isMobile} = useMobileSwitch();
    const navigate = useNavigate();

    const userEmail = useAppSelector((state) => state.auth.userData?.companyUser?.email);

    const pagesCount = Math.ceil(bookingsCount / pageSize);
    const [exportingXls, setExportingXls] = useState<boolean>(false);
    const [autosize, setAutosize] = useState<boolean>(false);
    const [sendEmail, setSendEmail] = useState<boolean>(false);
    const [customEmail, setCustomEmail] = useState<string>(userEmail || "");

    const columnsSorted = useMemo<ColumnPropTypes[]>(() => columns
        .sort((a: ColumnPropTypes, b: ColumnPropTypes) => a.order - b.order)
        .filter(({enabled}: ColumnPropTypes) => isMobile || enabled), [columns, isMobile]);

    useEffect(() => {
        setAutosize(columnsSorted && columnsSorted.length !== 0 && columnsSorted.length < 10);
    }, [columnsSorted]);

    const firstColumnSticky = columnsSorted.length > 1;

    const tHeadings = columnsSorted.map(({key, field}) => ({
        style: getHeaderStyle(field),
        element: t("mb_mbl_" + headingsTexts[field]),
        sortable: true,
        sortVariant: getSortVariant(sort, key),
        label: t("mb_mbl_" + headingsTexts[field]),
        onClick: () => handleSortChange(sort, key, handleSort)
    }));

    const exportExcelCallback = useCallback(() => {
        if (!getBookingExcel) {
            return;
        }

        setExportingXls(true);
        getBookingExcel().then(() => {
            setExportingXls(false);
        }).catch(() => {
            setExportingXls(false);
        });
    }, [getBookingExcel]);

    const tRows = bookings.map((data: BookingListItemPropTypes) => {
        const {variant, action} = getRowVariantAndAction({
            removeActiveOrder,
            addActiveOrder,
            multipleOrders,
            activeOrders,
            data,
            companyCurrency,
            navigate,
            variants
        });

        return {
            variant: (variants.withPayAction ? (data.paymentPending > 0 ? "Add" : "Disabled") : variant || "Normal"),
            actionable: !!action || (variants.withPayAction && data.paymentPending > 0),
            onClick: action || (variants.withPayAction && handleReconcilePay && data.paymentPending > 0 ? () => handleReconcilePay(data.id) : undefined),
            // actionTd: variants.withPayAction ? {
            //     element: (
            //         <span
            //             className={cx(styles.PayButton, data.paymentPending <= 0 && styles.PayButtonDisabled)}
            //             onClick={handleReconcilePay ? () => {
            //                 if (data.paymentPending > 0) {
            //                     ;
            //                 }
            //             } : () => {}}
            //         >
            //             {t("mb_mbl_pay")}
            //         </span>),
            //     actionable: true
            // } : undefined,
            tDatas: columnsSorted.map(({field}: { field: string }) => ({
                element: (
                    <>
                        {/* @ts-ignore */}
                        {getValue(data[field], field, {
                            currency: data.currency,
                            bookingType: data.bookingType,
                            bookingStatus: data.status
                        }, dateFormat, t)}
                    </>),
                actionable: false,
                onClick: function () {
                }
            }))
        } as TableRowProps;
    });

    return (
        <div className={styles.Root}>
            {variants.withHeader && (
                <div className={styles.Header}>
                    <h1>{t("mb_mbl_bookings")}</h1>
                    <div className={styles.HeaderControls}>
                        <span
                            onClick={exportExcelCallback}
                            className={styles.HeaderControl}
                        >
                            {exportingXls && (<Spinner className={styles.XlsSpinner} size={15}/>)}
                            {t("mb_mbl_export_xls")}
                        </span>

                        <div className={styles.HeaderSeparator}/>

                        <Tippy content={<span>{t("mb_mbl_select_bookings_tooltip")}</span>}>
                            <span className={styles.HeaderControl} onClick={toggleMultipleOrders}>
                                {t("mb_mbl_select_bookings")}
                            </span>
                        </Tippy>

                        <div className={styles.HeaderSeparator}/>

                        <EditColumns columns={columns || []}/>
                    </div>
                </div>
            )}

            {multipleOrders && (
                <ItemsBoxWithAction
                    itemsTitle={t("mb_mbl_select_bookings") + ":"}
                    mainActionLabel={`${t("mb_mbl_pay")} ${getPriceWithCurrencySymbol(activeOrders?.reduce((accumulator: any, obj: any) => (obj).paymentPending + accumulator, 0) || 0, companyCurrency)}`}
                    onMainAction={() => {
                        if (getMultiPaymentOptions) {
                            getMultiPaymentOptions(true);
                        }
                    }}
                    items={activeOrders}
                    onCloseBox={toggleMultipleOrders}
                    onRemoveItem={removeActiveOrder}
                    emptyBoxLabel={(
                        <Trans i18nKey="mb_mbl_pay_for_multiple_orders_placeholder">
                            Use <PlusIcon/> to select multiple bookings and pay
                            for them with one payment
                        </Trans>
                    )}
                />
            )}

            {multipleOrders && (activeOrders?.length || 0) > 1 && (
                <div className={styles.PaymentOrderContainer}>
                    <div className={styles.InputsContainer}>
                        <label>{t("mb_mbl_need_payment_order_question")}</label>

                        <CustomCheckBox
                            label={t("mb_mbl_payment_order_also_email")}
                            inputProps={{
                                checked: sendEmail,
                                onChange: (e) => setSendEmail(e.target.checked)
                            }}
                        />
                    </div>

                    {sendEmail && (
                        <Input
                            className={styles.EmailInput}
                            variants={{
                                labelPosition: "outlined"
                            }}
                            inputProps={{
                                type: "email",
                                value: customEmail,
                                onChange: (e) => setCustomEmail(e.target.value)
                            }}
                        />
                    )}

                    <UserButton
                        customTag={CustomTagType.A}
                        text={t("mb_mbl_payment_order_link")}
                        variant={UserButtonVariant.PRIMARY}
                        disabledButton={sendEmail && _.isEmpty(customEmail)}
                        buttonProps={{
                            href: sendEmail && _.isEmpty(customEmail) ? undefined : `${baseURL}/booking/paymentOrder?bookings=${activeOrders?.map((e: {
                                id: any;
                            }) => e.id).join(",")}${sendEmail ? `&email=${customEmail}` : ""}`,
                            download: "paymentVoucher.pdf"
                        }}
                        customIcon={<DownloadIcon/>}
                    />
                </div>
            )}

            <ResponsiveTable
                autoSize={autosize}
                firstColumnSticky={firstColumnSticky}
                tHeadings={tHeadings}
                tRows={tRows}
                mobileVariant="Accordion"
                loading={loading}
            />

            <PageControl
                pagesCount={pagesCount}
                pageNumber={pageNr}
                size={pageSize}
                pageSizeOptions={[
                    {
                        value: 25,
                        label: 25
                    },
                    {
                        value: 50,
                        label: 50
                    },
                    {
                        value: 100,
                        label: 100
                    }
                ]}
                handleChangePage={handlePageNr}
                handlePageSizeChange={handlePageSize}
                reverse
                totalCount={bookingsCount}
                totalCountKey="mb_mbl_total_items"
            />
        </div>
    );
}

export default memo(MyBookingList);
