import React, {ReactElement, useState} from "react";
import Input from "components/base/Input";
import ConfirmationModal from "components/common/ConfirmationModal";
import cx from "classnames";
import {Trans, useTranslation} from "react-i18next";
import styles from "./EnterPinModal.module.scss";
import {InsufficientAuthenticationType} from "../../../proptypes/PropTypeObjects";

type Props = {
    onConfirm: (pin: string) => void;
    email: string;
    onDecline: () => void;
    errors?: string[];
    spinner: boolean;
    pinLoginType: InsufficientAuthenticationType;
};

function EnterPinModal({
    onConfirm,
    onDecline,
    email,
    errors,
    spinner,
    pinLoginType

}: Props): ReactElement {
    const {t} = useTranslation();
    const [pin, setPin] = useState("");

    const content = (
        <div className={styles.content}>

            <div className={styles.upperSection}>
                {pinLoginType === InsufficientAuthenticationType.UNKNOWN_IP && (
                    <p className={cx(styles.heading, "mb-20")}>{t("login_epm_head_unknown_login")}</p>
                )}
                {pinLoginType === InsufficientAuthenticationType.WEAK_PASSWORD_EXPIRED && (
                    <div className={cx(styles.heading, "mb-20")}>
                        <Trans i18nKey="login_epm_head_weak_password">
                            <p>We couldn’t help but notice that your password is not strong enough to meet new Hotelston password security requirements. For security reasons we need to confirm your identity. We’ve sent you the login confirmation code to your email address. Please enter it below.</p>
                            <p>You can change your password to a more secure one right after you confirm your log-in.</p>
                        </Trans>
                    </div>
                )}
                <div className={cx("d-flex", "mb-20")}>
                    <Input
                        fullHeight
                        variants={{labelPosition: "outlined"}}
                        inputProps={{
                            type: "text",
                            name: "email",
                            required: true,
                            disabled: true,
                            value: email
                        }}
                    />
                </div>
                <div className={cx("d-flex", "mb-20")}>
                    <Input
                        fullHeight
                        variants={{labelPosition: "outlined"}}
                        //validator={validateName}
                        inputProps={{
                            type: "text",
                            name: "pin",
                            required: true,
                            placeholder: t("login_epm_pin"),
                            value: pin,
                            onChange: (e) => {
                                if (e) {
                                    setPin(e.target.value);
                                }
                            }
                        }}
                    />
                </div>

            </div>

        </div>
    );

    return (
        <ConfirmationModal
            onConfirm={() => onConfirm(pin)}
            onDecline={() => onDecline()}
            config={{
                variants: {
                    type: "normal"
                },
                title: t("login_epm_title"),
                //customHeader,
                content,
                isForm: true,
                validateForm: true,
                confirmButtonText: t("login_epm_confirm"),
                declineButtonText: t("login_epm_cancel"),
                errors,
                errorTitle: t("login_emp_error_title"),
                spinner,
                autocomplete: false,
                disableFade: true
            }}
        />
    );
}

export default EnterPinModal;