import React, {ReactElement} from "react";
import cx from "classnames";
import {HotelSearchResultsFiltersPropTypes} from "../../../../../proptypes/PropTypeObjects";
import CustomCheckBox from "../../../../../components/base/Input/CustomCheckBox";
import styles from "./FeaturesFilter.module.scss";
import {useTranslation} from "react-i18next"

type FeaturesFilterProps = {
    onBoxSelect: (value: number, isActive: boolean) => void;
    filters: HotelSearchResultsFiltersPropTypes;
    values: {value: number, label: string}[]
};

const FeaturesFilter = (props: FeaturesFilterProps): ReactElement => {
    const {
        filters: {featuresFilter}, onBoxSelect: handleFeatures, values: features
    } = props;

    const {t} = useTranslation();

    return (
        <div className={cx(styles.Root)}>
            <div className={cx(styles.Title)}>{t("h_s_sb_features")}</div>
            {features.map((feature) => (
                <CustomCheckBox
                    key={feature.value}
                    label={feature.label}
                    inputProps={{
                        checked: featuresFilter.includes(feature.value),
                        onChange: (e) => handleFeatures(feature.value, e.target.checked)
                    }}
                />
            ))}
        </div>
    );
};

export default FeaturesFilter;