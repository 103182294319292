export enum BookingDetailsTypes {
    GET_DETAILS = "bookingDetails/GET_DETAILS",
    GET_DETAILS_SUCCESS = "bookingDetails/GET_DETAILS_SUCCESS",
    GET_DETAILS_FAILURE = "bookingDetails/GET_DETAILS_FAILURE",
    UPDATE_AMENDMENT_DATA = "bookingDetails/UPDATE_AMENDMENT_DATA",
    SET_BOOKING_DATA = "bookingDetails/SET_BOOKING_DATA",
    REQ_SET_MEMO = "bookingDetails/REQ_SET_MEMO",
    SET_MEMO_SUCCESS = "bookingDetails/SET_MEMO_SUCCESS",
    SET_FLIGHT_DETAILS_SUCCESS = "bookingDetails/SET_FLIGHT_DETAILS_SUCCESS",
    SET_MEMO_FAILURE = "bookingDetails/SET_MEMO_FAILURE",
    SET_SPECIAL_REQUESTS = "bookingDetails/SET_SPECIAL_REQUESTS",
    RESET_DETAILS = "bookingDetails/RESET_DETAILS",
    SET_SHOW_CREDENTIALS_TO_FALSE = "bookingDetails/SET_SHOW_CREDENTIALS_TO_FALSE",

    UPDATE_RETAIL_PRICE = "bookingDetails/UPDATE_RETAIL_PRICE",

    CANCEL_BOOKING = "bookingDetails/CANCEL_BOOKING",
    CANCEL_BOOKING_SUCCESS = "bookingDetails/CANCEL_BOOKING_SUCCESS",
    CANCEL_BOOKING_FAILURE = "bookingDetails/CANCEL_BOOKING_FAILURE"
}

export default BookingDetailsTypes;