import React, { ReactElement, useEffect } from "react"
import { Trans, useTranslation } from "react-i18next"
import styles from "./PaymentInformation.module.scss"
import { useAppDispatch, useAppSelector } from "redux/hooks"
import setLastLocation from "redux/actions/router.actions"
import { LastLocationKey, LocationBaseKey } from "redux/reducers/actions/router.reducer.actions"
import { fetchPaymentAccounts } from "redux/actions/contacts.actions"
import Spinner from "@hotelston_web_frontend_components/components/spinner/Spinner"
import PaymentAccount from "views/Contacts/PaymentInformation/PaymentAccount/PaymentAccount"

const PaymentInformation = (): ReactElement => {
  const { t } = useTranslation()

  const dispatch = useAppDispatch()

  const loadingAccounts = useAppSelector(
    (state) => state.contacts.loading,
  )
  const paymentAccounts = useAppSelector(
    (state) => state.contacts.paymentAccounts,
  )

  useEffect(() => {
    dispatch(fetchPaymentAccounts())
  }, [])

  useEffect(() => {
    const set = setLastLocation({
      pathname: "/contacts",
      search: "",
      hash: "",
      state: "",
      key: "",
    }, LocationBaseKey.CONTACTS, LastLocationKey.LAST_CONTACTS_LOCATION)

    if (set) {
      dispatch(set)
    }
  }, [dispatch])

  return (
    <div className={styles.Root}>
      <h1>{t("c_pd_payment_details")}</h1>

      <p>
        <Trans i18nKey="c_pd_payment_details_description">
          Please use one of the following bank accounts to pay for reservations at Hotelston.com.
          <span>Always include booking reference in a subject and make a payment in the currency listed below!</span>
          Failing to do that may result in reservation being cancelled.
        </Trans>
      </p>

      {loadingAccounts ? (
        <Spinner
          size={50}
        />
      ) : (
        <>
          {paymentAccounts?.length > 0 ? (
            <>
              {paymentAccounts.map((paymentAccount) => (
                <PaymentAccount account={paymentAccount} />
              ))}
            </>
          ) : (
            <PaymentAccount />
          )}
        </>
      )}

      <p>
        <Trans i18nKey="c_pd_payment_details_bottom_notice">
          In case you have any questions regarding payments or invoices, please contact our accounting department:
          <a href="mailto:accounting@hotelston.com">accounting@hotelston.com</a>
        </Trans>
      </p>
    </div>
  )
}

export default PaymentInformation