import React, {ReactElement} from "react";
import {connect, shallowEqual} from "react-redux";
import PageStatus from "components/common/PageStatus";
import {Trans, useTranslation} from "react-i18next";
import {TFunction} from "i18next";
import {useAppSelector} from "../../../redux/hooks";
import {RootState} from "../../../redux/store/store.init";
import {useNavigate, useParams} from "react-router-dom"

function getTitle(status: string | undefined): string {
    switch (status) {
    case "success":
    case "SUCCESS":
        return "mb_pst_payment_success";
    default:
        return "mb_pst_encountered_error";
    }
}

function getActionTitle(status: string | undefined, bookingReferences?: string[]): string {
    switch (status) {
    case "success":
    case "SUCCESS":
        return (!bookingReferences || bookingReferences.length === 0 || (bookingReferences?.length || 1) > 1) ? "mb_pst_go_back_to_booking_multiple" : "mb_pst_go_back_to_booking";
    default:
        return (!bookingReferences || bookingReferences.length === 0 || (bookingReferences?.length || 1) > 1) ? "mb_pst_go_back_to_booking_multiple" : "mb_pst_go_back_to_booking";
    }
}

function getContentKey(status: string | undefined, translate: TFunction, bookingReferences?: string[]) {
    switch (status) {
    case "success":
    case "SUCCESS":
        return (!bookingReferences || bookingReferences.length === 0 || (bookingReferences?.length || 1) > 1)
            ? translate("mb_pst_payment_success_page_status_message_multiple")
            : translate("mb_pst_payment_success_page_status_message", {reference: (bookingReferences || [])[0] || ""});
    default:
        return (!bookingReferences || bookingReferences.length === 0 || (bookingReferences?.length || 1) > 1)
            ? translate("mb_pst_payment_failure_page_status_message_multiple")
            : translate("mb_pst_payment_failure_page_status_message", {reference: (bookingReferences || [])[0] || ""});
    }
}

type PaymentStatusProps = {
    bookingReferences?: string[];
};

function PaymentStatus({bookingReferences}: PaymentStatusProps): ReactElement {
    const {status} = useParams<{status: string}>();
    const navigate = useNavigate();
    const lastBookingLocation = useAppSelector((state) => state.router.lastLocations.myBookings.lastBookingLocation ||
        "/my-bookings", shallowEqual);
    const {t} = useTranslation();

    const activeOrders = useAppSelector((state) => state.multiPayment.activeOrders);

    let statusRoute: string;
    if (bookingReferences && bookingReferences.length > 1) {
        statusRoute = "/my-bookings?" + bookingReferences.map((reference, index) => `b${index}=${reference}`).join("&");
    } else if (bookingReferences && bookingReferences.length === 1) {
        statusRoute = `/my-bookings/booking/${bookingReferences[0].replace("TF", "")}`;
    } else {
        statusRoute = lastBookingLocation;
    }

    return (
        <PageStatus
            title={t(getTitle(status), {reference: bookingReferences?.length === 1 ? bookingReferences[0] : undefined})}
            actionTitle={t(getActionTitle(status, bookingReferences || activeOrders?.map((order) => order.reference)))}
            status={status}
            onAction={() => navigate(statusRoute)}
            content={getContentKey(status, t, bookingReferences || activeOrders?.map((order) => order.reference))}
        />
    );
}

const mapStateToProps = ((root: RootState) => ({
    bookingReferences: root?.bookingDetails?.bookingReferences || (root?.bookingDetails?.data?.id ? [`TF${root?.bookingDetails?.data?.id}`] : undefined)
}));

export default connect(mapStateToProps, undefined)(PaymentStatus);
