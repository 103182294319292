enum Currency {
    EUR = "EUR",
    USD = "USD",
    GBP = "GBP",
    AUD = "AUD",
    CAD = "CAD",
    CHF = "CHF",
    CZK = "CZK",
    DKK = "DKK",
    JPY = "JPY",
    NOK = "NOK",
    PLN = "PLN",
    RUB = "RUB",
    SEK = "SEK",
    LVL = "LVL",
    BYR = "BYR",
    AED = "AED",
    AFN = "AFN",
    ALL = "ALL",
    AMD = "AMD",
    ARS = "ARS",
    AZN = "AZN",
    BAM = "BAM",
    BDT = "BDT",
    BGN = "BGN",
    BHD = "BHD",
    BYN = "BYN",
    BOB = "BOB",
    BRL = "BRL",
    CLP = "CLP",
    CNY = "CNY",
    COP = "COP",
    DJF = "DJF",
    DZD = "DZD",
    EGP = "EGP",
    ETB = "ETB",
    GEL = "GEL",
    GHS = "GHS",
    GNF = "GNF",
    HKD = "HKD",
    HRK = "HRK",
    HUF = "HUF",
    IDR = "IDR",
    YER = "YER",
    ILS = "ILS",
    INR = "INR",
    IQD = "IQD",
    IRR = "IRR",
    ISK = "ISK",
    JOD = "JOD",
    KES = "KES",
    KGS = "KGS",
    KRW = "KRW",
    KWD = "KWD",
    KZT = "KZT",
    LBP = "LBP",
    LKR = "LKR",
    MAD = "MAD",
    MDL = "MDL",
    MGA = "MGA",
    MYR = "MYR",
    MKD = "MKD",
    MNT = "MNT",
    MUR = "MUR",
    MXN = "MXN",
    MZN = "MZN",
    NGN = "NGN",
    NZD = "NZD",
    PAB = "PAB",
    PEN = "PEN",
    PHP = "PHP",
    PKR = "PKR",
    QAR = "QAR",
    RON = "RON",
    RSD = "RSD",
    SAR = "SAR",
    SGD = "SGD",
    SYP = "SYP",
    THB = "THB",
    TJS = "TJS",
    TMT = "TMT",
    TND = "TND",
    TRY = "TRY",
    TWD = "TWD",
    TZS = "TZS",
    UAH = "UAH",
    UYU = "UYU",
    UZS = "UZS",
    VEF = "VEF",
    VND = "VND",
    XAF = "XAF",
    XOF = "XOF",
    ZAR = "ZAR",
    OMR = "OMR",
    LTL = "LTL"
}

export default Currency;