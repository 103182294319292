import types from "../constants/auth.constants";
import {ReduxAuthPropTypes} from "../../proptypes/PropTypeRedux";
import {
    UserDetailsPropTypes
} from "../../proptypes/PropTypeObjects";
import AuthActionTypes from "./actions/auth.reducer.actions";

export const INITIAL_STATE = {
    checkedLogin: false,
    loggingIn: false,
    loggingOut: false,
    error: undefined,
    loginError: undefined,
    pinLoginType: undefined,
    userData: undefined,
    currentCompanyMarkupIndex: undefined,
    expandedView: true,
    modalView: true,
    expandedModalView: false,
    redirectedAfter401: false
} as ReduxAuthPropTypes;

const auth = (state = INITIAL_STATE, action: AuthActionTypes): ReduxAuthPropTypes => {
    switch (action.type) {
    case types.REQUEST_LOGIN:
        return {
            ...state, error: undefined, loggingIn: true, loginError: undefined
        };
    case types.LOGIN_SUCCESS:
        // eslint-disable-next-line no-case-declarations
        let existingMarkupIndex; const markupId = action.userData?.companyUser?.currentMarkupId;
        if (!state.currentCompanyMarkupIndex && action.userData?.companyUser?.currentMarkupId && markupId !== undefined) {
            const found = action.userData.companyMarkups.find((existingMarkup) => existingMarkup.id === markupId);

            if (found) {
                existingMarkupIndex = action.userData.companyMarkups.indexOf(found);
            } else {
                existingMarkupIndex = 0;
            }
        }

        return {
            ...state,
            userData: action.userData,
            loggingIn: false,
            checkedLogin: true,
            currentCompanyMarkupIndex: existingMarkupIndex !== undefined ? existingMarkupIndex : state.currentCompanyMarkupIndex
        };
    case types.MERGE_USER_DATA:
        return {
            ...state,
            userData: {
                ...state.userData,
                ...action.userData,
                defaultFeatures: action.userData.defaultFeatures || state.userData?.defaultFeatures || [],
                companyUser: {
                    ...state.userData?.companyUser,
                    ...action.userData?.companyUser,
                    // this flag doesn't change
                    ownUser: state.userData?.companyUser.ownUser
                }
            } as UserDetailsPropTypes
        };
    case types.LOGIN_FAILURE:
        return {...state, loginError: action.loginError, loggingIn: false};
    case types.LOGIN_ERROR:
        return {...state, error: action.error, loggingIn: false};
    case types.INSUFFICIENT_AUTHENTICATION:
        return {...state, pinLoginType: action.loginType, loggingIn: false};
    case types.CHECKED_LOGIN:
        return {...state, loggingIn: false, checkedLogin: true};
    case types.REQUEST_LOGOUT:
        return {...state, error: undefined, loggingOut: true};
    case types.LOGOUT_SUCCESS:
        return {
            ...INITIAL_STATE,
            locationBeforeLogin: state.locationBeforeLogin
        };
    case types.LOGIN_ABORTED_WITH_ERROR:
        return {...INITIAL_STATE, loginError: action.loginError};
    case types.LOGOUT_FAILURE:
        return {...state, loggingOut: false, userData: undefined};
    case types.SET_WALLET_TO_ENABLED:
        return {...state, userData: {...state.userData!, walletEnabled: true}};
    case types.CHANGE_CURR_COMP_MARK_INDEX:
        return {
            ...state,
            userData: {
                ...state.userData,
                // @ts-ignore
                companyUser: {
                    ...state.userData?.companyUser,
                    currentMarkupId: state.userData?.companyMarkups[action.currentCompanyMarkupIndex || 0]?.id || undefined
                }
            },
            currentCompanyMarkupIndex: action.currentCompanyMarkupIndex !== undefined ? action.currentCompanyMarkupIndex : state.currentCompanyMarkupIndex
        };
    case types.TOGGLE_EXPANDED_VIEW:
        return {
            ...state,
            expandedView: state.modalView ? state.expandedView : (action.expandedView != null ? action.expandedView : !state.expandedView),
            expandedModalView: state.modalView ? (action.expandedView != null ? action.expandedView : !state.expandedModalView) : state.expandedModalView
        };
    case types.HANDLE_MODAL_VIEW:
        return {
            ...state,
            modalView: action.modalView != null ? action.modalView : !state.modalView,
            expandedView: action.modalView ? false : state.expandedView,
            expandedModalView: action.modalView ? false : state.expandedModalView
        };
    case types.ROLES_UPDATE:
        return {
            ...state,
            userData: {
                ...state.userData,
                roles: action.roles
            } as UserDetailsPropTypes
        };
    case types.ACCEPT_TERMS_AND_CONDITIONS:
        return {
            ...state,
            userData: {
                ...state.userData,
                termsAndConditionsAccepted: action.accepted
            } as UserDetailsPropTypes
        };
    case types.ON_401_SET_LAST_ROUTE:
        return {
            ...state,
            locationBeforeLogin: action.location
        };
    default:
        return state;
    }
};

export default auth;