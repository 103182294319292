import ConstrainedAxiosRequestConfig from "api/axios/constrainedAxiosRequestConfig"

export default function sendLogs(error: string): ConstrainedAxiosRequestConfig<void, { error: string }> {
    return {
        method: "post",
        url: "/app/logger/",
        data: {
            error
        },
        headers: {
            Accept: "application/json"
        },
        maxNumRequests: 1
    };
}
