import React, {
    ReactElement, useCallback, useReducer, useRef, useState
} from "react";
import {useTranslation} from "react-i18next";
import titles from "assets/json/titles.json";
import {
    EMAIL_PATTERN, PHONE_PATTERN, validateMarkup, validateName
} from "utils/validateForm";
import {
    CompanyDetails,
    CountryPropTypes,
    PersonTitlePropTypes,
    RegisterCompanyRequest
} from "proptypes/PropTypeObjects";
import Input from "components/base/Input";
import ConfirmationModal from "components/common/ConfirmationModal";
import LineWithAction from "components/common/LineWithAction/LineWithAction";
import CountriesDropdown from "components/base/Input/CustomReactSelect/CountriesDropdown";
import CustomCheckBox from "components/base/Input/CustomCheckBox";
import BasicDropdown from "components/base/Input/BasicDropdown/BasicDropdown";
import SuccessModal from "components/common/ConfirmationModal/SuccessModal";
import OutlineInput from "components/base/Input/TextInput/OutlinedInput";
import PasswordWithRepeat from "components/common/PasswordWithRepeat";
import {useCreateSubagentAction} from "components/utils/useSubagentsData";
import cx from "classnames";
import styles from "./AddSubagent.module.scss";

type ActionProps =
    | {type: "HANDLE_COMPANY_DETAILS_INPUT", name: string, value: string | boolean}
    | {type: "HANDLE_TITLE", title: PersonTitlePropTypes}
    | {type: "HANDLE_COMPANY_USER_INPUT", name: string, value: string}
    | {type: "HANDLE_PASSWORD", value: string}
    | {type: "HANDLE_COUNTRY", countryId: number}
    | {type: "TOGGLE_SUBSCRIBE_NEWSLETTER"}
    ;

function reducer(state: RegisterCompanyRequest, action: ActionProps): RegisterCompanyRequest {
    switch (action.type) {
    case "HANDLE_COMPANY_DETAILS_INPUT":
        return {
            ...state,
            companyDetails: {
                ...state.companyDetails,
                [action.name]: action.value
            }
        };

    case "HANDLE_TITLE":
        return {
            ...state,
            companyUser: {
                ...state.companyUser,
                title: action.title
            }
        };
    case "HANDLE_COMPANY_USER_INPUT":
        return {
            ...state,
            companyUser: {
                ...state.companyUser,
                [action.name]: action.value
            }
        };
    case "HANDLE_PASSWORD":
        return {
            ...state,
            password: action.value
        };
    case "HANDLE_COUNTRY":
        return {
            ...state,
            companyDetails: {
                ...state.companyDetails,
                countryId: action.countryId
            }
        };
    case "TOGGLE_SUBSCRIBE_NEWSLETTER":
        return {
            ...state,
            subscribeNewsletter: !state.subscribeNewsletter
        };
    default:
        return state;
    }
}

const initialData = {
    companyDetails: {
        id: undefined as unknown as number,
        companyName: "",
        countryId: 0,
        email: "",
        phone: "",
        photo: "",
        superAgentMarkup: undefined,
        amadeusSupplierCode: undefined
    },
    companyUser: {
        admin: true,
        firstname: "",
        lastname: "",
        title: "MR"
    },
    password: "",
    subscribeNewsletter: true
} as RegisterCompanyRequest;

type AddSubagentModalProps = {
    onConfirm: (subagent: RegisterCompanyRequest) => void;
    onDecline: () => void;
    errors?: string[];
    spinner: boolean;
};

function AddSubagentModal({
    onConfirm,
    onDecline,
    errors,
    spinner
}: AddSubagentModalProps): ReactElement {
    const {t} = useTranslation();

    const [validateStared, setValidateStarted] = useState(false);
    const [validate, setValidate] = useState(false);
    const inputRef = useRef<OutlineInput>(null);

    const [localFormData, dispatch] = useReducer(reducer, initialData);
    const [countryName, setCountryName] = useState("");

    const handleCountry = useCallback(
        (country?: CountryPropTypes) => {
            if (country) {
                setCountryName(country.name);
                dispatch({
                    type: "HANDLE_COUNTRY",
                    countryId: country.id
                });
            }
        },
        [dispatch]
    );

    const handleCompanyDetailsInput = useCallback(
        ({target: {name, value, type}}: {target: {name: string, value: any, type: string}}) => dispatch({
            type: "HANDLE_COMPANY_DETAILS_INPUT",
            name,
            value: type === "number" ? (value ? Number(value) : "") : value
        }),
        [dispatch]
    );

    const handleCompanyUserInput = useCallback(
        ({target: {name, value}}: {target: {name: string, value: any}}) => dispatch({
            type: "HANDLE_COMPANY_USER_INPUT",
            name,
            value
        }),
        [dispatch]
    );

    const handlePasswordInput = useCallback(
        (e?: {target: {value: any;}}) => {
            if (!e) return;

            const value = e?.target?.value;
            dispatch({
                type: "HANDLE_PASSWORD",
                value
            });
        },

        [dispatch]
    );

    const handleTitle = useCallback(
        (title: PersonTitlePropTypes) => dispatch({
            type: "HANDLE_TITLE",
            title
        }),
        [dispatch]
    );

    const toggleSubscribeNewsletter = useCallback(
        () => dispatch({
            type: "TOGGLE_SUBSCRIBE_NEWSLETTER"
        }),
        [dispatch]
    );

    const content = (
        <div className={styles.content}>
            <div className={cx(styles.section, "mb-60")}>
                <div className={cx(styles.heading, "mb-20")}>
                    {t("sa_as_company_details")}
                </div>
                <Input
                    fullHeight
                    variants={{labelPosition: "outlined"}}
                    inputProps={{
                        name: "companyName",
                        placeholder: t("sa_as_company_name"),
                        required: true,
                        onChange: handleCompanyDetailsInput,
                        value: localFormData.companyDetails.companyName
                    }}
                />
                <CountriesDropdown
                    required={true}
                    className="mb-20"
                    currentCountryName={
                        countryName
                    }
                    onCountryChange={handleCountry}
                    onCountryInputChange={(e) => setCountryName(e.target.value)}
                    countryInputName="country"
                />

                <Input
                    fullHeight
                    variants={{labelPosition: "outlined"}}
                    validator={e => validateMarkup(e)}
                    inputProps={{
                        name: "superAgentMarkup",
                        placeholder: t("sa_as_markup"),
                        required: true,
                        onChange: handleCompanyDetailsInput,
                        value:
                            localFormData.companyDetails.superAgentMarkup
                    }}
                />

            </div>
            <div className={cx(styles.section, "mb-60")}>
                <div className={cx(styles.heading, "mb-20")}>
                    {t("sa_as_personal_details")}
                </div>
                <div className={cx("d-flex", "mb-10")}>
                    <BasicDropdown
                        fullHeight
                        required={true}
                        onDropdownChange={handleTitle}
                        className={styles.titleDropdown}
                        dropDownValue={localFormData.companyUser.title}
                        dropDownOptions={titles.titles.map(({
                            label,
                            value
                        }: {label: string, value: string}) => ({
                            value: value as unknown as PersonTitlePropTypes,
                            label: t(label) as unknown as string
                        }))}
                        placeholder={t("sa_as_title")}
                    />
                    <Input
                        fullHeight
                        variants={{labelPosition: "outlined"}}
                        validator={(e) => validateName(e)}
                        inputProps={{
                            name: "firstname",
                            placeholder: t("sa_as_first_name"),
                            required: true,
                            onChange: (e) => {
                                handleCompanyUserInput(e);
                            },
                            value: localFormData.companyUser.firstname
                        }}
                    />
                </div>
                <Input
                    fullHeight
                    variants={{labelPosition: "outlined"}}
                    validator={(e) => validateName(e)}
                    inputProps={{
                        name: "lastname",
                        placeholder: t("sa_as_last_name"),
                        required: true,
                        onChange: (e) => {
                            handleCompanyUserInput(e);
                        },
                        value: localFormData.companyUser.lastname
                    }}
                />
                <Input
                    inputComponentRef={inputRef}
                    fullHeight
                    variants={{labelPosition: "outlined"}}
                    inputProps={{
                        name: "email",
                        placeholder: t("sa_as_email"),
                        required: true,
                        pattern: validate ? EMAIL_PATTERN : undefined,
                        onChange: (e) => {
                            handleCompanyDetailsInput(e);
                            if (!validateStared) {
                                setValidateStarted(true);
                                setTimeout(() => {
                                    setValidate(true);
                                    inputRef.current?.updateValidity();
                                }, 5000);
                            }
                        },
                        value: localFormData.companyDetails.email,
                        type: validate ? "email" : "text"
                    }}
                />
                <Input
                    fullHeight
                    variants={{labelPosition: "outlined"}}
                    inputProps={{
                        name: "phone",
                        placeholder: t("sa_as_phone_number"),
                        required: true,
                        pattern: PHONE_PATTERN,
                        onChange: handleCompanyDetailsInput,
                        value: localFormData.companyDetails.phone
                    }}
                />
                <div className={styles.checkboxWrapper}>
                    <CustomCheckBox
                        label={t("sa_as_subscribe_newsletter")}
                        inputProps={{
                            name: "subscribeNewsletter",
                            checked: localFormData.subscribeNewsletter,
                            onChange: toggleSubscribeNewsletter
                        }}
                    />
                </div>
            </div>
            <div className={cx(styles.heading, "mb-20")}>{t("sa_as_password")}</div>
            <PasswordWithRepeat
                onChange={handlePasswordInput}
                password={localFormData.password}
                passwordNeeded={true}
                t={t}
                className={styles.password}
            />

        </div>
    );

    return (
        <ConfirmationModal
            onConfirm={() => onConfirm(localFormData)}
            onDecline={onDecline}
            config={{
                errors,
                spinner,
                variants: {
                    type: "normal"
                },
                title: t("sa_as_add_subagent"),
                //customHeader,
                content,
                isForm: true,
                validateForm: true,
                confirmButtonText: t("sa_as_save"),
                declineButtonText: t("sa_as_cancel")
            }}
        />
    );
}

type Props = {
    addSubagent: (subagent: CompanyDetails) => void;
};
function AddSubagent({
    addSubagent
}: Props): ReactElement {
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [successModal, setSuccessModal] = useState(false);

    const {t} = useTranslation();

    const onSuccess = useCallback(
        (subagent: CompanyDetails) => {
            setModalIsOpen(false);
            setSuccessModal(true);
            addSubagent(subagent);
        },
        [addSubagent]
    );

    const {
        requesting,
        errors,
        createSubagent: createSubagentAction,
        clearState
    } = useCreateSubagentAction(onSuccess);

    return (
        <>
            {modalIsOpen && (
                <AddSubagentModal
                    onConfirm={(subagent: RegisterCompanyRequest) => {
                        createSubagentAction(subagent);
                    }}
                    onDecline={() => {
                        setModalIsOpen(false);
                    }}
                    errors={errors}
                    spinner={requesting}
                />
            )}
            {successModal && (
                <SuccessModal
                    onClose={() => setSuccessModal(false)}
                    successMessage={t("sa_as_success_message_add_subagent")}
                />
            )}
            <LineWithAction
                label={t("sa_as_add_new_subagent")}
                onClick={() => {
                    clearState();
                    setModalIsOpen(true);
                }}
            />
        </>
    );
}

export default AddSubagent;
