import React, {
    ReactElement, Reducer, useCallback, useEffect, useReducer, useState
} from "react";
import {activateWallet as activateWalletAPI, getWalletDetails} from "api/walletAPI";
import {setWalletToEnabled} from "redux/actions/auth.actions";
import {useTranslation} from "react-i18next";
import ActivateWallet from "./ActivateWallet";
import Transactions from "./WalletDetails/Transactions/Transactions";
import styles from "./Wallet.module.scss";
import {useAppDispatch, useAppSelector} from "redux/hooks";
import {useLocale} from "components/utils/withLocalesAndCurrencies";
import {WalletDetailsProps} from "proptypes/PropTypeObjects";
import globalAxiosController from "api/axios/globalAxiosController"

type StateProps = {
    isFetching: boolean;
    walletDetails?: WalletDetailsProps;
    error?: string;
};

const INITIAL_STATE: StateProps = {
    isFetching: false,
    walletDetails: undefined,
    error: undefined
};

type ActionProps = {type: "GET_WALLET_DETAILS"}
| {type: "GET_WALLET_DETAILS_SUCCESS", payload: WalletDetailsProps}
| {type: "GET_WALLET_DETAILS_FAILED", error: string};

const reducer: Reducer<StateProps, ActionProps> = (state = INITIAL_STATE, action: ActionProps) => {
    switch (action.type) {
    case "GET_WALLET_DETAILS":
        return {
            ...state,
            isFetching: true
        };
    case "GET_WALLET_DETAILS_SUCCESS":
        return {
            ...state,
            isFetching: false,
            walletDetails: action.payload,
            error: undefined
        };
    case "GET_WALLET_DETAILS_FAILED":
        return {
            ...state,
            isFetching: false,
            error: action.error
        };
    default:
        return state;
    }
};

export default function WalletContainer(): ReactElement {
    const walletEnabled = useAppSelector((state) => state.auth.userData?.walletEnabled);
    // const walletBalance = useAppSelector((state) => state.auth.userData?.wallet);

    const locale = useLocale();
    const [state, dispatch] = useReducer(reducer, INITIAL_STATE);

    const {
        isFetching,
        walletDetails,
        error
    } = state;

    const appDispatch = useAppDispatch();
    const [activatingWallet, setActivatingWallet] = useState(false);
    const [errors, setErrors] = useState<string[] | undefined>(undefined);
    const {t} = useTranslation();

    const activateWallet = useCallback(() => {
        setActivatingWallet(true);
        globalAxiosController.addRequest(activateWalletAPI(locale))
            .then((data) => {
                setActivatingWallet(false);
                if (data && data.success) {
                    setErrors(undefined);
                    appDispatch(setWalletToEnabled());
                } else {
                    setErrors(data && data.errors
                        ? data.errors
                        : [t("w_wd_wd_something_went_wrong")]);
                }
            })
            .catch((error: string) => {
                setErrors(error ? [String(error)] : [t("w_wd_wd_something_went_wrong")]);
                setActivatingWallet(false);
            });
    }, [appDispatch, locale, t]);

    useEffect(() => {
        let isMounted = true;
        dispatch({type: "GET_WALLET_DETAILS"});
        globalAxiosController.addRequest(getWalletDetails(locale)).then((data) => {
            if (isMounted) {
                if (!data) {
                    dispatch({
                        type: "GET_WALLET_DETAILS_FAILED",
                        error: t("w_wd_wd_something_went_wrong")
                    });
                } else {
                    dispatch({
                        type: "GET_WALLET_DETAILS_SUCCESS",
                        payload: data
                    });
                }
            }
        }).catch((error: string) => {
            // console.log(error);
            if (isMounted) {
                dispatch({
                    type: "GET_WALLET_DETAILS_FAILED",
                    error: t("w_wd_wd_something_went_wrong")
                });
            }
        });
        return () => {
            isMounted = false;
        };
    }, [locale, t]);

    if (walletEnabled) {
        return (
            <Transactions walletDetails={walletDetails} balance={walletDetails?.balance} />
        );
    }

    return (
        <div className={styles.Root}>
            <ActivateWallet
                activatingWallet={activatingWallet}
                activateWallet={activateWallet}
                errors={errors}
                setErrors={setErrors}
            />
        </div>
    );
}
