import React, {useCallback, useEffect, useReducer} from "react";
import MainContainer from "components/base/MainContainer";
import * as companyDocumentsAPI from "api/companyDocumentsAPI";
import {useTranslation} from "react-i18next";
import {useLocale} from "components/utils/withLocalesAndCurrencies";
import Spinner from "components/base/Loaders/Spinner";
import i18n from "i18n";
import UserButton from "components/base/UserButton";
import useIsMounted from "@hotelston_web_frontend_components/hooks/detection/useIsMounted";
import withConfirmation, {WithConfirmation} from "components/utils/withConfirmation";
import AgreementSidebar from "./AgreementSidebar/AgreementSidebar";
import styles from "./Agreement.module.scss";
import {
    CompanyDocument, DocumentDetails
} from "../../../../proptypes/PropTypeObjects";
import {UserButtonVariant} from "../../../../components/base/UserButton/UserButton";
import FailureModal from "../../../../components/common/ConfirmationModal/FailureModal";
import AgreementGeneralInfo from "./GeneralInfo/AgreementGeneralInfo";
import YourInformation from "./YourInformation/YourInformation";
import AgreementStatusHeader from "./AgreementStatusHeader/AgreementStatusHeader";
import UploadedFiles from "./UploadedFiles/UploadedFiles";
import DownloadFile from "./DownloadFiles/DownloadFiles";
import convertRomanNumeral from "../../../../utils/convertRomanNumeral";
import Annex from "./Annex/Annex";
import {useNavigate, useParams} from "react-router-dom"
import {globalAxiosController} from "api/axios/globalAxiosController";

export enum RequestType {
    "DOCUMENT" = 1,
    "DELETE" = 2,
    "CANCEL" = 3
}

type Props = {
    data: CompanyDocument;
    errors?: string[];
    requesting?: RequestType;
};

export const initialState = {
    data: undefined as unknown as CompanyDocument,
    requesting: RequestType.DOCUMENT,
    errors: undefined
};

export type ActionProps = { type: "CLEAR_ERRORS" }
    | { type: "CANCEL_SUCCESS" }
    | { type: "SET_REQUEST", payload: RequestType }
    | { type: "GET_DOCUMENT_SUCCESS", payload: CompanyDocument; }
    | { type: "SET_ERRORS", errors: string[]; }
    | { type: "DELETE_FILE_SUCCESS", payload: number; };

export const reducer = (state: Props, action: ActionProps): Props => {
    switch (action.type) {
    case "CLEAR_ERRORS":
        return {
            ...state,
            errors: undefined
        };
    case "CANCEL_SUCCESS":
        return {
            ...state,
            requesting: undefined
        };
    case "SET_REQUEST":
        return {
            ...state,
            errors: undefined,
            requesting: action.payload
        };
    case "GET_DOCUMENT_SUCCESS":
        return {
            ...state,
            data: action.payload,
            requesting: undefined
        };
    case "SET_ERRORS":
        return {
            ...state,
            errors: action.errors,
            requesting: undefined
        };
    case "DELETE_FILE_SUCCESS":
        return {
            ...state,
            data: {
                ...state.data,
                document: {
                    ...state.data.document,
                    files: state.data.document.files.filter(({id}) => id !== action.payload)
                }
            },
            requesting: undefined
        };
    default:
        return state;
    }
};

type AgreementProps = WithConfirmation;

function Agreement({setModal}: AgreementProps) {
    const {id} = useParams<{ id: string }>();
    const navigate = useNavigate();
    const {t} = useTranslation();
    const locale = useLocale();
    const isMounted = useIsMounted();
    const [state, dispatch] = useReducer(reducer, initialState);

    const getCompanyDocument = useCallback(() => {
        dispatch({
            type: "SET_REQUEST",
            payload: RequestType.DOCUMENT
        });
        globalAxiosController.addRequest(companyDocumentsAPI
            .getCompanyDocumentById(Number(id), locale))
            .then((data) => {
                if (isMounted) {
                    dispatch({
                        type: "GET_DOCUMENT_SUCCESS",
                        payload: data
                    });
                }
            })
            .catch((error: unknown) => {
                if (isMounted) {
                    dispatch({
                        type: "SET_ERRORS",
                        errors: [
                            String(error) ||
                            i18n.t("mc_a_a_something_went_wrong")
                        ]
                    });
                }
            });
    }, [id, locale, isMounted]);

    useEffect(() => {
        getCompanyDocument();
    }, [getCompanyDocument]);

    const {data, requesting, errors} = state;

    const deleteFile = (id: number) => {
        setModal({
            onConfirm: () => {
                dispatch({
                    type: "SET_REQUEST",
                    payload: RequestType.DELETE
                });
                globalAxiosController.addRequest(companyDocumentsAPI.deleteFileById(id))
                    .then((resData) => {
                        if (resData.success) {
                            if (data.document.files.length > 1) {
                                dispatch({
                                    type: "DELETE_FILE_SUCCESS",
                                    payload: id
                                });
                            } else {
                                getCompanyDocument();
                            }
                        } else {
                            dispatch({
                                type: "SET_ERRORS",
                                errors: resData.errors || [t("mc_a_a_something_went_wrong")]
                            });
                        }
                    })
                    .catch((error: unknown) => {
                        dispatch({
                            type: "SET_ERRORS",
                            errors: [String(error) || t("mc_a_a_something_went_wrong")]
                        });
                    });
            },
            onDecline: () => false,
            config: {
                variants: {
                    type: "error"
                },
                title: t("mc_a_a_delete_file"),
                content: (
                    <span>
                        {t("mc_a_a_confirmation_question_delete_file")}
                    </span>
                ),
                //errors,
                spinner: requesting === RequestType.DELETE
            }
        });
    };

    const uploadFiles = (files: File[]) => {
        const formData = new FormData();
        files.forEach((file: File) => {
            //let fileBlob = new Blob([file], { type: file.type });
            formData.append("file", file, file.name);
        });
        dispatch({
            type: "SET_REQUEST",
            payload: RequestType.DOCUMENT
        });
        globalAxiosController.addRequest(companyDocumentsAPI.uploadFiles(Number(id), formData))
            .then((data) => {
                if (data.success) {
                    getCompanyDocument();
                } else {
                    dispatch({
                        type: "SET_ERRORS",
                        errors: data.errors || [t("mc_a_a_something_went_wrong")]
                    });
                }
            })
            .catch((error: unknown) => {
                dispatch({
                    type: "SET_ERRORS",
                    errors: [String(error) || t("mc_a_a_something_went_wrong")]
                });
            });
    };

    const cancelDocVerification = (id: number) => {
        setModal({
            onConfirm: () => {
                //dispatch({ type: "DOCUMENT" });
                dispatch({
                    type: "SET_REQUEST",
                    payload: RequestType.CANCEL
                });
                globalAxiosController.addRequest(companyDocumentsAPI.cancelDocVerification(id))
                    .then((data) => {
                        if (isMounted) {
                            dispatch({
                                type: "CANCEL_SUCCESS"
                            });
                        }
                        if (data.success) {
                            getCompanyDocument();
                        } else {
                            dispatch({
                                type: "SET_ERRORS",
                                errors: data.errors || [t("mc_a_a_something_went_wrong")]
                            });
                        }
                    })
                    .catch((error: unknown) => {
                        dispatch({
                            type: "SET_ERRORS",
                            errors: [String(error) || t("mc_a_a_something_went_wrong")]
                        });
                    });
            },
            onDecline: () => false,
            config: {
                variants: {
                    type: "error"
                },
                title: t("mc_a_a_cancel_verification"),
                content: (
                    <span>
                        {t("mc_a_a_confirmation_question_cancel_doc_verification")}
                    </span>
                )
                //errors,
                // spinner: requesting === types.DELETE
            }
        });
    };

    return (
        <MainContainer
            sidebar={requesting !== RequestType.CANCEL && requesting !== RequestType.DOCUMENT && requesting !== RequestType.DELETE && state.data && (
                <AgreementSidebar
                    uploadFiles={uploadFiles}
                    agreementFile={data.document.agreement}
                    rejectionReason={data.rejectionReason}
                    name={data.document.nameText}
                    status={data.status}
                    signee={data.credentials && data.credentials.signee}
                    signeePosition={data.credentials && data.credentials.signeePosition}
                    cancelDocVerification={() => cancelDocVerification(Number(id))}
                    uploadedFiles={data.document.files}
                    deleteFile={deleteFile}
                />
            )}
        >
            {state.errors && (
                <FailureModal
                    failureMessage={state.errors.join(",")}
                    onClose={() => {
                        dispatch({
                            type: "CLEAR_ERRORS"
                        });
                    }}
                />
            )}

            {requesting && (
                <Spinner
                    size="50px"
                    style={{
                        width: "100%",
                        height: "calc(100vh - 120px)"
                    }}
                />
            )}

            {!requesting && state.data ? (
                <div className={styles.Root}>
                    <div className={styles.Header}>
                        <AgreementStatusHeader status={data.status} statusText={data.statusText}/>

                        <h1>{data.document.nameText}</h1>
                    </div>

                    <div className={styles.Body}>
                        {data.document && (<AgreementGeneralInfo {...data.document} statusText={data.statusText}/>)}

                        {data.credentials && (<YourInformation {...data.credentials} />)}

                        {data.annexes.sort((a, b) => convertRomanNumeral(b.annexNo) - convertRomanNumeral(a.annexNo))
                            .reverse()
                            .map(({
                                annexNo, subtypeText, details = [] as DocumentDetails[], subtype
                            }) => (
                                <Annex key={annexNo} subtypeText={subtypeText} details={details} annexNo={annexNo}/>))}

                        {data.document && data.document.agreement && (<DownloadFile {...data.document.agreement} />)}

                        {data.document && data.document.files && Boolean(data.document.files.length) && (
                            <UploadedFiles files={data.document.files} deleteFile={deleteFile}/>)}

                        <div className={styles.ButtonsContainer}>
                            <UserButton
                                text={t("mc_a_a_back_button")}
                                variant={UserButtonVariant.SECONDARY}
                                buttonProps={{
                                    type: "button",
                                    onClick: () => navigate("/my-company"),
                                    style: {
                                        width: 158
                                    }
                                }}
                            />
                        </div>
                    </div>
                </div>
            ) : undefined}
        </MainContainer>
    );
}

export default withConfirmation(Agreement);
