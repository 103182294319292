import React, {
    ReactElement, useCallback
} from "react";
import cx from "classnames";
import styles from "./BasePredicate.module.scss";
import {ReactComponent as CloseIcon} from "../../../../assets/icons/green-cross.svg";

export type BasePredicateProps = {
    children: ReactElement[] | ReactElement;
    reference: string;
    onRemove?: (reference: string) => void;

    leftoverText?: boolean;
    onClick?: () => void;

    className?: string;
};

export default function BasePredicate({
    children, reference, onRemove, leftoverText, onClick, className
}: BasePredicateProps): ReactElement {
    const removeCallback = useCallback(() => {
        if (onRemove) {
            onRemove(reference);
        }
    }, [onRemove, reference]);

    return (
        <div className={cx(styles.Root, leftoverText && styles.LeftoverText, className)} onClick={onClick}>
            {onRemove && (
                <div className={styles.CloseIconContainer} onClick={removeCallback}>
                    <CloseIcon />
                </div>
            )}

            {children}
        </div>
    );
}