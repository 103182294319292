import React, {useEffect, useState} from "react";
import {PhoneCode} from "../../proptypes/PropTypeObjects";
import {getPhoneCodes} from "../../api/countryAPI";
import {useLocale} from "./withLocalesAndCurrencies";
import {globalAxiosController} from "api/axios/globalAxiosController";

export type UsePhoneCodesReturn = {
    phoneCodes: PhoneCode[];
    error: boolean;
};

export function usePhoneCodes(): UsePhoneCodesReturn {
    const [phoneCodes, setPhoneCodes] = useState<PhoneCode[]>([]);
    const [error, setError] = useState<boolean>(false);
    const locale = useLocale();

    useEffect(() => {
        void globalAxiosController.addRequest(getPhoneCodes(locale))
            .then((result) => {
                const sorted = result.phoneCodes.sort((codeOne, codeTwo) => codeOne.name.localeCompare(codeTwo.name));
                setPhoneCodes(sorted);
            }).catch(() => {
                setError(true);
            });
    }, [locale]);

    return {
        phoneCodes,
        error
    };
}