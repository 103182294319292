import mimeTypes from "mime-types";
import {Crop} from "react-image-crop";
import _ from "lodash";

export default function getCroppedImgBlob(image: HTMLImageElement, crop: Crop, fileName: string): Promise<Blob> {
    const canvas = document.createElement("canvas");
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = crop.width || image.naturalWidth;
    canvas.height = crop.height || image.naturalHeight;
    const ctx = canvas.getContext("2d");

    if (ctx !== null && crop.x !== undefined && crop.y !== undefined && crop.width !== undefined && crop.height !== undefined) {
        ctx.drawImage(image, crop.x * scaleX, crop.y * scaleY, crop.width * scaleX, crop.height * scaleY, 0, 0, crop.width, crop.height);
    }
    return new Promise((resolve, reject) => {
        canvas.toBlob((blob) => {
            if (!blob) {
                //reject(new Error('Canvas is empty'));
                console.error("Canvas is empty");
                return;
            }
            _.set(blob, "name", fileName);
            resolve(blob);
        }, mimeTypes.lookup(fileName) || undefined);
    });
}
export function createImageBlobUrl(imgBlob: Blob): string | null {
    let fileUrl: string | null = null;
    //TODO probably remove, cause fireUrl is null, nothing to revoke
    window.URL.revokeObjectURL(fileUrl);
    fileUrl = window.URL.createObjectURL(imgBlob);
    return fileUrl;
}
