import {HotelSearchResultsFiltersPropTypes} from "proptypes/PropTypeObjects"
import {CommonActionReturnType} from "redux/store/store.init"
import IAppActions from "redux/store/store.actions"
import HotelSearchResultsTypes from "redux/constants/hotelSearchResults.constants"
import _ from "lodash"

export const handleFilters = ({
    hotelNameFilter,
    offerProviderFilter,
    roomTypeFilter,
    priceFilter,
    boardTypeFilter,
    hotelCategoryFilter,
    reviewRatingFilter,
    propertyTypeFilter,
    featuresFilter,
    specialOffersFilter,
    memberOnlyFilter,
    onlyGoodCXLFilter,
    onlyRefundableFilter,
    onlyNonRefundableFilter
}: HotelSearchResultsFiltersPropTypes): CommonActionReturnType => (dispatch, getState) => {
    const dispatchFilters = (filters: HotelSearchResultsFiltersPropTypes): IAppActions => ({
        type: HotelSearchResultsTypes.HANDLE_STATE_FILTERS,
        filters
    });
    const {maxAllowed} = getState().hotelSearchResults;
    const filters = {
        hotelNameFilter: _.isString(hotelNameFilter) ? hotelNameFilter : "",
        offerProviderFilter: _.isString(offerProviderFilter) ? offerProviderFilter : "",
        roomTypeFilter: _.isString(roomTypeFilter) ? roomTypeFilter : "",
        priceFilter: _.isObject(priceFilter) && !_.isEmpty(priceFilter)
            ? {
                min: priceFilter.min && priceFilter.min === 0 ? undefined : priceFilter.min,
                max: priceFilter.max && priceFilter.max === maxAllowed ? undefined : priceFilter.max
            }
            : {
                min: undefined,
                max: undefined
            },
        boardTypeFilter: _.isArray(boardTypeFilter) ? boardTypeFilter : [],
        reviewRatingFilter: reviewRatingFilter,
        hotelCategoryFilter: _.isArray(hotelCategoryFilter) ? hotelCategoryFilter : [],
        propertyTypeFilter: _.isArray(propertyTypeFilter) ? propertyTypeFilter : [],
        featuresFilter: _.isArray(featuresFilter) ? featuresFilter : [],
        specialOffersFilter: _.isBoolean(specialOffersFilter) ? specialOffersFilter : false,
        memberOnlyFilter: _.isBoolean(memberOnlyFilter) ? memberOnlyFilter : false,
        onlyGoodCXLFilter: _.isBoolean(onlyGoodCXLFilter) ? onlyGoodCXLFilter : false,
        onlyRefundableFilter: _.isBoolean(onlyRefundableFilter) ? onlyRefundableFilter : false,
        onlyNonRefundableFilter: _.isBoolean(onlyNonRefundableFilter) ? onlyNonRefundableFilter : false
    //isUpdating: _.isBoolean(isUpdating) ? isUpdating : false
    };
    dispatch(dispatchFilters(filters));
};