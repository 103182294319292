import React, {ReactElement} from "react";
import cx from "classnames";
import ActionWithIcon from "../ActionWithIcon/ActionWithIcon";
import styles from "./Edit.module.scss";

function Edit({
    what,
    ...rest
}: any): ReactElement {
    return (
        <ActionWithIcon
            {...rest}
            className={cx(styles.Root, rest.className)}
            title={`${what}`}
        />
    );
}

export default Edit;
