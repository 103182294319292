import React, {ReactElement, useMemo, useState} from "react"
import {useTranslation} from "react-i18next"
import {SharedPaymentTypeProps} from "../SharedPaymentTypeProps"
import usePaymentOptions from "../usePaymentOptions"
import types from "utils/paymentTransferTypes"
import PaymentLinkBlock from "./PaymentLinkBlock"
import CopyToClipboard from "components/common/CopyToClipboard"
import {useCurrency, useLocale} from "components/utils/withLocalesAndCurrencies"
import {useAppSelector} from "redux/hooks"
import styles from "./PaymentLink.module.scss"
import Pricing from "components/common/Pricing"
import ButtonConfirmNonrefModal from "../../ButtonConfirmNonRefModal/ButtonConfirmNonrefModal"
import {ReduxPaymentTypePropTypes} from "proptypes/PropTypeRedux"
import {MultiPaymentBookingOptionsPropTypes} from "proptypes/PropTypeObjects"

type PaymentLinkProps = SharedPaymentTypeProps & {};

const PaymentLink = (props: PaymentLinkProps): ReactElement => {
    const {
        toggleSelectedPayment,
        paymentTransferTypeEnum
    } = props;

    const {t} = useTranslation();
    const currency = useCurrency();
    const locale = useLocale();

    const [generating, setGenerating] = useState<boolean>(false);
    const [generatedPaymentLink, setGeneratedPaymentLink] = useState<string>();

    const {
        type,
        paymentOptions,
        discount,
        voucherSum
    } = usePaymentOptions();

    const paymentLink = paymentOptions?.paymentTransferTypes.includes(types[paymentOptions?.afterDeadline ? "after" : "before"].paymentLink);
    const activeOrders = useAppSelector((state) => state.multiPayment.activeOrders);

    const existingPaymentLinkToken = useMemo(() => {
        if (type !== ReduxPaymentTypePropTypes.MULTIBOOKING) {
            return null;
        }

        return (paymentOptions as MultiPaymentBookingOptionsPropTypes).existingPaymentLinkToken;
    }, [paymentOptions, type]);

    const builtPaymentLink = useMemo(
        () => `${window.location.origin}/payment/ext/${generatedPaymentLink || existingPaymentLinkToken}`,
        [existingPaymentLinkToken, generatedPaymentLink]
    );

    if (!paymentOptions || !paymentLink) {
        return (
            <></>
        );
    }

    const {
        cancellationTime,
        cancellationDate,
        afterDeadline,
        paymentDeadlineTime,
        paymentDeadlineDate,
        allowToBook,
        sanctionedCompany,
        salePrice,
        displaySalePrice,
        smsFee,
        displaySmsFee,
        paymentPending
    } = paymentOptions;

    return (
        <PaymentLinkBlock
            toggleSelectedPayment={toggleSelectedPayment}
            paymentTransferTypeEnum={paymentTransferTypeEnum}
            afterDeadline={paymentOptions?.afterDeadline}
        >
            <div className={styles.Root}>
                {/*{!generatedPaymentLink && (*/}
                {/*    <UserButton*/}
                {/*        customIcon={generating ? (<Spinner />) : undefined}*/}
                {/*        className={cx(styles.GenerateButton, generating && styles.GenerateButtonSpinner)}*/}
                {/*        text="Create payment link"*/}
                {/*        variant={UserButtonVariant.PRIMARY}*/}
                {/*        buttonProps={{*/}
                {/*            onClick: generateLink*/}
                {/*        }}*/}
                {/*    />*/}
                {/*)}*/}

                {existingPaymentLinkToken && (
                    <div className={styles.GeneratedLinkContainer}>
                        <p>{t("pc_pc_payment_link_already_generated")}</p>

                        <span>{builtPaymentLink}</span>

                        <CopyToClipboard
                            className={styles.LinkGenerated}
                            url={builtPaymentLink}
                        />
                    </div>
                )}

                <Pricing
                    customButton={afterDeadline ? <ButtonConfirmNonrefModal/> : undefined}
                    withButton
                    disabledButton={!allowToBook || sanctionedCompany || !!existingPaymentLinkToken}
                    greyLabels
                    salePrice={salePrice}
                    displaySalePrice={displaySalePrice}
                    smsFee={smsFee}
                    displaySmsFee={displaySmsFee}
                    discount={discount}
                    voucher={voucherSum.used}
                    paymentPending={paymentPending}
                />

                {/*{generatedPaymentLink && (*/}
                {/*    <>*/}
                {/*        <div className={styles.GeneratedLinkContainer}>*/}
                {/*            <span>*/}
                {/*                {builtPaymentLink}*/}
                {/*            </span>*/}

                {/*            <CopyToClipboard*/}
                {/*                className={styles.LinkGenerated}*/}
                {/*                url={builtPaymentLink}*/}
                {/*            />*/}
                {/*        </div>*/}

                {/*        <Pricing*/}
                {/*            customButton={afterDeadline ? <ButtonConfirmNonrefModal /> : undefined}*/}
                {/*            withButton*/}
                {/*            customButtonLabel="Get payment link"*/}
                {/*            disabledButton={!allowToBook || sanctionedCompany}*/}
                {/*            greyLabels*/}
                {/*            salePrice={salePrice}*/}
                {/*            displaySalePrice={displaySalePrice}*/}
                {/*            smsFee={smsFee}*/}
                {/*            displaySmsFee={displaySmsFee}*/}
                {/*            discount={discount}*/}
                {/*            voucher={voucherSum.used}*/}
                {/*            paymentPending={paymentPending}*/}
                {/*        />*/}
                {/*    </>*/}
                {/*)}*/}
            </div>
        </PaymentLinkBlock>
    );
}

export default PaymentLink;