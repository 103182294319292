import {WebBankLinkOptionPropTypes} from "../../proptypes/PropTypeObjects"
import _ from "lodash"

export function customPaymentTypeFilter(
    data: WebBankLinkOptionPropTypes[] | WebBankLinkOptionPropTypes,
    selected?: WebBankLinkOptionPropTypes
): WebBankLinkOptionPropTypes | undefined {
    if (!_.isArray(data) && data.id && data.id === selected?.id && data.mip === selected?.mip) {
        return data;
    }

    const arr = data as WebBankLinkOptionPropTypes[];
    for (let i = 0; i < arr.length; i++) {
        const o = customPaymentTypeFilter(arr[i], selected);

        if (o != null) {
            return o;
        }
    }

    return undefined;
}