import React, {
    ReactElement, useCallback, useLayoutEffect, useRef
} from "react";
import cx from "classnames";
import {useTranslation} from "react-i18next";
import {useDropzone} from "react-dropzone";
import {ReactComponent as NoPhotoPlaceholder} from "assets/images/no-photo-placeholder.svg";
import styles from "./ImageInput.module.scss";
import Tippy from "../Tippy/Tippy";

type Props = {
    onSelectFile: (selectedFiles: File[]) => void;
    placeholderImg?: string;
    className: string;
};

function ImageInput({
    onSelectFile,
    placeholderImg,
    className
}: Props): ReactElement {
    const {t} = useTranslation();
    const placeholderRef = useRef<HTMLDivElement>(null);

    const onDropAccepted = useCallback((selectedFiles: File[]) => {
        onSelectFile(selectedFiles);
    }, [onSelectFile]);

    const {getRootProps, getInputProps, isDragActive} = useDropzone({
        onDropAccepted,
        accept: {
            "image/gif": [".gif"],
            "image/jpeg": [".jpeg"],
            "image/png": [".png"]
        }
    });

    return (
        <div {...getRootProps({className: cx(styles.root, className)})} style={{height: "calc(145px + " + (placeholderRef.current?.clientHeight || 20) + "px)"}}>
            <input {...getInputProps()} />
            {(placeholderImg)
                ? (
                    <Tippy
                        placement="bottom"
                        content={(
                            <span className="semi-bold-text">{t("iiwc_ii_drag_edit")}</span>
                        )}
                    >
                        <div
                            className={cx(styles.imgPlaceholder)}
                            style={{
                                backgroundImage: `url("${placeholderImg}")`
                            }}
                        />
                    </Tippy>
                )
                : (
                    <div className={styles.dragNDrop}>
                        <div className={styles.placeholderText} ref={placeholderRef}>
                            {t("iiwc_ii_drag_upload")}
                        </div>

                        <NoPhotoPlaceholder />
                    </div>
                )}
        </div>
    );
}

export default ImageInput;
