import Cookies from "js-cookie";

export const DEFAULT_WEB_VERSION_COOKIE_NAME = "WebVersion";
export const DEFAULT_COOKIE_NAME = "HotelstonConsentCookie";
export const DEFAULT_COOKIE_OPTIONS = {
    domain: "hotelston.com"
};

export type HotelstonConsentCookie = {
    required: boolean;
    preferences: boolean;
    statistics: boolean;

    declined: boolean;

    agreedWithTermsAndConditions?: boolean;
};

export const DEFAULT_COOKIE_VALUE: HotelstonConsentCookie = {
    required: true,
    preferences: false,
    statistics: false,

    declined: false
};

export function getCookieValue(): HotelstonConsentCookie {
    const currCookie = Cookies.get(DEFAULT_COOKIE_NAME);
    return currCookie ? JSON.parse(currCookie) as HotelstonConsentCookie : DEFAULT_COOKIE_VALUE;
}

export function setCookieValue(newCookie: HotelstonConsentCookie): void {
    Cookies.set(DEFAULT_COOKIE_NAME, JSON.stringify(newCookie), DEFAULT_COOKIE_OPTIONS);
}