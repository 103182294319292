import React, {ReactElement, useRef} from "react";
import UserButton from "components/base/UserButton";
import {useTranslation} from "react-i18next";
import cx from "classnames";
import styles from "./Browse.module.scss";
import {UserButtonVariant} from "../UserButton/UserButton";

type Props = {
    className?: string;
    accept?: string;
    tipText?: string;
    buttonVariant: UserButtonVariant,
    onFilesSelected: (files: File[]) => void,
    disabled?: boolean
};

export default function Browse({
    className = "",
    accept = "image/jpeg, image/jpg, image/tiff, application/pdf, application/doc, application/docx",
    tipText = "No file selected. Supported formats: .pdf, .jpg, .jpeg, .tiff.",
    buttonVariant = UserButtonVariant.PRIMARY,
                                   disabled,
    onFilesSelected = function (files: File[]) {
    }
}: Props): ReactElement {
    const {t} = useTranslation();
    const fileInputRef = useRef<HTMLInputElement>(null);

    return (
        <div className={cx(styles.root, className)}>
            <UserButton
                text={t("br_browse")}
                variant={buttonVariant}
                disabledButton={disabled}
                buttonProps={{
                    disabled,
                    onClick: () => fileInputRef.current?.click()
                }}
            />
            <input
                disabled={disabled}
                style={{
                    display: "none"
                }}
                onChange={(e) => {
                    const files = e.target.files != null ? Array.from(e.target.files) : [];
                    return onFilesSelected(files);
                }}
                ref={fileInputRef}
                type="file"
                accept={accept}
                name="fileInput"
                multiple
            />
            <div className={styles.tip}>
                {tipText}
            </div>
        </div>
    );
}
