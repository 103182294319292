import {createSelector} from "reselect";
import uniqBy from "lodash/uniqBy";
import {HotelOfferPropTypes, PinnedOffer} from "../../proptypes/PropTypeObjects";
import {RootState} from "../store/store.init";

const pinnedOffers = (state: RootState) => state.pinnedOffers.pinnedOffers;
const pinnedHotels = (state: RootState) => state.pinnedOffers.pinnedHotels;
const hotelOffers = (state: RootState) => state.hotelSearchResults.hotelOffers;

type PinnedHotelsSelector = (state: RootState) => HotelOfferPropTypes[];
export const pinnedHotelsSelector = (pinnedOffer: PinnedOffer): PinnedHotelsSelector => createSelector(
    pinnedOffers,
    pinnedHotels,
    hotelOffers,
    (pinnedOffers: PinnedOffer[], pHotels, hOffers) => {
        const pinned = [...pinnedOffers, pinnedOffer];
        const hotels = uniqBy([...hOffers, ...pHotels], (item) => item.hotel.id);

        return hotels.filter((item) => pinned.some(({hotelId}) => item.hotel && item.hotel.id === hotelId));
    }
);

type PinnedHotelsRemovePinnedOffer = (state: RootState) => HotelOfferPropTypes[];
export const pinnedHotelsRemovePinnedOffer = (pinnedOffer: PinnedOffer): PinnedHotelsRemovePinnedOffer => createSelector(
    pinnedHotels,
    pinnedOffers,
    (pHotels, pOffers: PinnedOffer[]) => {
        const sameHotelIdsCount = pOffers.filter((po: PinnedOffer) => po.hotelId === pinnedOffer.hotelId).length;

        return sameHotelIdsCount > 1
            ? pHotels
            : pHotels.filter((item) => item.hotel.id !== pinnedOffer.hotelId);
    }
);
