import React, {ReactElement} from "react";
import PageTemplate from "components/base/PageTemplate/PageTemplate";
import HeaderContainer from "components/base/Header";
import Righter from "components/base/Righter";
import Lefter from "components/base/Lefter";
import Footer from "components/base/Footer";
import VouchersContainer from "./VouchersContainer";
import ErrorBoundary from "../../components/utils/ErrorBoundary/ErrorBoundary";

const Vouchers = (): ReactElement => (
    <PageTemplate
        header={<HeaderContainer />}
        footer={<Footer />}
        lefter={<Lefter />}
        righter={<Righter />}
        className="Wallet"
    >
        <ErrorBoundary>
            <VouchersContainer />
        </ErrorBoundary>
    </PageTemplate>
);

export default Vouchers;