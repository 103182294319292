import React, {PureComponent} from "react";
import onClickOutside from "react-onclickoutside";
import "./RighterDropdown.scss";

type State = {
    isOpen: boolean;
};
type Props = {
    options: {
        label?: number | string;
        value: number | string;
    }[];
    onDropdownChange: (...args: any[]) => any;
    dropDownValue?: number | string;
    placeholder?: string;
    className?: string;
};

class RighterDropdown extends PureComponent<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            isOpen: false
        };
    }

    // eslint-disable-next-line react/no-access-state-in-setstate
    handleOpen = (_: any) => this.setState({isOpen: !this.state.isOpen});

    handleClickOutside = (e: any) => {
        this.setState({isOpen: false});
    };

    render() {
        const {
            options,
            onDropdownChange,
            dropDownValue,
            placeholder,
            className = ""
        } = this.props;
        const List = options.map((val: any, i: any) => (
            <li
                className={dropDownValue === val.value ? "li-item current" : "li-item"}
                key={i}
                onClick={() => onDropdownChange(val.value, val.label)}
            >
                <span>{val.label}</span>
                <span className={this.state.isOpen && i === 0 ? "arrow-up" : ""} />
            </li>
        ));

        return (
            <div
                onClick={this.handleOpen}
                className={`Righter-Dropdown ${className}`}
            >
                <div className={`${this.state.isOpen ? "d-none" : "current"}`}><span>{dropDownValue || placeholder}</span><span className={!this.state.isOpen ? "arrow-down" : ""} /></div>
                <ul className={`${this.state.isOpen ? "dropdown" : "d-none"}`}>
                    {this.state.isOpen && List}
                </ul>
            </div>
        );
    }
}

export default onClickOutside(RighterDropdown);
