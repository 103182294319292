import React, {
    useRef, useState, useLayoutEffect, ReactElement, RefObject, useEffect
} from "react";

import ReactCrop, {Crop} from "react-image-crop";
import getCroppedImgBlob, {createImageBlobUrl} from "utils/getCroppedImgBlob";
import styles from "./CropImage.module.scss";

type Props = {
    src: string;
    fileName?: string;
    setCroppedImageUrl(croppedImgUrl: string): void;
    setCroppedImgBlob(blob: Blob): void;
    aspect: number;
};

function CropImage({
    src,
    fileName,
    setCroppedImageUrl,
    setCroppedImgBlob,
    aspect
}: Props): ReactElement {
    const [crop, setCrop] = useState<Crop>({
        width: 100,
        height: 100,
        x: 0,
        y: 0,
        unit: "%"
    });
    const [cropAspect, setCropAspect] = useState<number>(aspect);

    const [loaded, setLoaded] = useState<boolean>(false);

    const imageRef = useRef<HTMLImageElement>();
    useEffect(() => {
        const image = document.createElement("img");
        image.setAttribute("src", src);
        setTimeout(() => {
            if (crop && cropAspect) {
                const currentAspect = image.width / image.height;
                if (currentAspect > cropAspect) {
                    const newWidthInPx = (image.width / currentAspect) * cropAspect;
                    const newWidthPercent = (newWidthInPx / image.width) * 100;
                    const newCrop = {
                        ...crop,
                        height: 100,
                        x: 50 - (newWidthPercent / 2)
                    };
                    setCrop(newCrop);
                } else {
                    const newHeightInPx = (image.height / (1 / currentAspect)) * (1 / cropAspect);
                    const newHeightPercent = (newHeightInPx / image.height) * 100;
                    const newCrop = {
                        ...crop,
                        width: 100,
                        y: 50 - (newHeightPercent / 2)
                    };
                    setCrop(newCrop);
                }

                setLoaded(true);
            }
        }, 0);
    }, [src]);

    const onImageLoaded = (image: HTMLImageElement) => {
        imageRef.current = image;
    };

    const makeClientCrop = (crop: Crop) => {
        if (fileName && imageRef && imageRef.current && crop.width && crop.height) {
            void getCroppedImgBlob(
                imageRef.current,
                crop,
                fileName
            ).then((croppedImgBlob) => {
                setCroppedImgBlob(croppedImgBlob);
                const croppedImgUrl = createImageBlobUrl(croppedImgBlob);

                setCroppedImageUrl(croppedImgUrl);
            });

            // var fd = new FormData();
            // fd.append('fname', 'test.wav');
            // fd.append('data', soundBlob);
            // https://medium.com/@fakiolinho/handle-blobs-requests-with-axios-the-right-way-bb905bdb1c04
        }
    };

    return (
        <div
            className={styles.root}
        >
            {loaded && (
                <ReactCrop
                    crop={crop}
                    aspect={cropAspect}
                    onChange={(newCrop: Crop) => setCrop(newCrop)}
                    onComplete={makeClientCrop}
                >
                    <img src={src} alt={src} ref={onImageLoaded}/>
                </ReactCrop>
            )}
        </div>
    );
}

export default CropImage;