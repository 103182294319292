import React, {ReactElement} from "react";
import {useTranslation} from "react-i18next";
import {PaymentTransferTypePropTypes} from "proptypes/PropTypeObjects";
import InfoBox from "components/common/InfoBox";
import types from "utils/paymentTransferTypes";
import {ReactComponent as PayLaterIcon} from "assets/icons/pay later.svg";
import styles from "components/common/Payment/PaymentContent/PaymentContent.module.scss";
import {useAppDispatch} from "redux/hooks"
import {calculateTransactionFee} from "redux/actions/pricing.actions";

type Props = {
    paymentTransferTypeEnum?: PaymentTransferTypePropTypes;
    afterDeadline?: boolean;
    toggleSelectedPayment: (paymentType: PaymentTransferTypePropTypes, keepSelected?: boolean) => void;
    cancellationDate?: string;
    cancellationTime?: string;
    paymentDeadlineDate?: string;
    paymentDeadlineTime?: string;
    children: ReactElement;
};

function PayLaterBlock({
                           paymentTransferTypeEnum,
                           afterDeadline,
                           toggleSelectedPayment,
                           cancellationDate,
                           cancellationTime,
                           paymentDeadlineDate,
                           paymentDeadlineTime,
                           children

                       }: Props): ReactElement {
    const {t} = useTranslation();
    const dispatch = useAppDispatch();

    return (
        <InfoBox
            style={{marginBottom: 15}}
            active={paymentTransferTypeEnum === types[afterDeadline ? "after" : "before"].payLater}
            title={t("pc_pc_pay_later")}
            message={t("pc_pc_pay_later_pormotional_message")}
            customIcon={<PayLaterIcon/>}
            // miniIcon
            onClick={() => {
                toggleSelectedPayment(types[afterDeadline ? "after" : "before"].payLater);
                dispatch(calculateTransactionFee());

                return false;
            }}
            type="custom"
        >
            <div className={styles.Option}>
                <div className={styles.OptionText}>
                    {afterDeadline ? (<p>{t("pc_pc_ptt_pay_later_after_cxl")}</p>) : (
                        <p>
                            {t("pc_pc_ptt_pay_later", {
                                cancellationDate,
                                cancellationTime,
                                paymentDate: paymentDeadlineDate,
                                paymentTime: paymentDeadlineTime
                            })}
                        </p>
                    )}
                </div>

                <div
                    style={{
                        marginTop: 30,
                        marginBottom: 30
                    }}
                    className="h-divider"
                />

                {children}
            </div>
        </InfoBox>
    );
}

export default PayLaterBlock;