import React, {CSSProperties, ReactElement, ReactNode, RefObject, useEffect, useState} from "react";
import cx from "classnames";
import styles from "./Input.module.scss";
import OutlineInput from "./OutlinedInput"
import {useAppSelector} from "../../../../redux/hooks";

export type InputProps = {
    fullHeight?: boolean;
    compact?: boolean;
    className?: string;
    style?: CSSProperties;
    inputProps?: React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>;
    variants: {
        labelPosition: "outlined" | "inside";
        helperAndErrorTextPosition?: "bottom" | "right";
    };
    helperText?: string;
    validator?: (e: React.ChangeEvent<HTMLInputElement>) => void;
    customErrorMessage?: string;
    children?: ReactNode[] | ReactNode;
    inputComponentRef?: RefObject<OutlineInput>;
    highlightOnEmpty?: boolean;
    wrapsLabel?: boolean;
    trailingIcon?: string | ReactNode;
    tooltipText?: string;
};

function Input({
    fullHeight = true,
    compact = false,
    className = "",
    style = {},
    inputProps = {
        type: "text"
    },
    variants = {
        labelPosition: "outlined",
        helperAndErrorTextPosition: "bottom"
    },
    helperText,
    validator,
    customErrorMessage,
    children,
    inputComponentRef,
    highlightOnEmpty = false,
    wrapsLabel,
    trailingIcon,
    tooltipText
}: InputProps): ReactElement {
    const [highlighted, setHighlighted] = useState<boolean>(highlightOnEmpty);

    useEffect(() => {
        if (inputProps?.value) {
            setHighlighted(false);
        }
    }, [inputProps?.value]);

    let hintPosition = variants.helperAndErrorTextPosition;
    if (!hintPosition) {
        hintPosition = "bottom";
    }

    return (
        <div
            style={{
                ...style
            }}
            className={cx(
                styles.root,
                styles.noBorders,
                styles.inputWithBottomErrorsHints,
                inputProps?.disabled && styles.disabled,
                highlighted && styles.highlighted,
                className
            )}
        >
            <OutlineInput
                containerClassName={wrapsLabel ?  "wraps-label" : undefined}
                ref={inputComponentRef}
                customErrorMessage={customErrorMessage}
                validator={validator}
                labelPosition={variants.labelPosition}
                helperAndErrorTextPosition={hintPosition}
                inputProps={{...inputProps, style: {height: (compact ? "30px" : fullHeight ? "50px" : "40px")}}}
                style={{width: "100%"}}
                helperText={helperText}
                trailingIcon={trailingIcon}
                tooltipText={tooltipText}
            >
                {children}
            </OutlineInput>
        </div>
    );
}

export default Input;
