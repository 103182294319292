import React, {ReactElement} from "react";
import {Transition, TransitionStatus} from "react-transition-group";

export type FadeProps = {
    children: ReactElement | ReactElement[];
    inProp?: boolean;
    duration?: number;
    timeout?: number;
};

const Fade = ({
    children,
    inProp,
    duration = 300,
    timeout = 0
}: FadeProps): ReactElement => {
    const defaultStyle = {
        transition: `opacity ${duration}ms ease-in-out`,
        willChange: "opacity"
    };

    const transitionStyles: Map<TransitionStatus, { opacity: number }> = new Map([
        ["entering", {opacity: 0}],
        ["entered", {opacity: 1}],
        ["exiting", {opacity: 1}],
        ["exited", {opacity: 0}]
    ]);

    return (
        <Transition
            in={inProp}
            timeout={timeout}
        >
            {(state) => (
                <div style={{
                    ...defaultStyle,
                    ...transitionStyles.get(state)
                }}
                >
                    {children}
                </div>
            )}
        </Transition>
    );
};

export default Fade;