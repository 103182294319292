import React, {ReactElement, Reducer, useCallback, useEffect, useReducer, useState} from "react";
import {useTranslation} from "react-i18next";
import {setBookingSpecialRequests} from "redux/actions/bookingDetails.actions";
import getSpecialRequestText from "utils/getSpecialRequestText";
import specialRequestsAmendmentRequest from "api/hotelAmendmentAPI";
import _ from "lodash";
import SpecialRequestsForm from "../SpecialRequestsForm";
import Spinner from "../../base/Loaders/Spinner";
import InfoBox from "../InfoBox";
import styles from "./SpecialRequests.module.scss";
import {SpecialRequestsPropTypes} from "proptypes/PropTypeObjects";
import {useAppDispatch, useAppSelector} from "redux/hooks";
import {convertToServerTime, dateWithSetTime} from "utils/dateUtils";
import {DateTime} from "luxon"
import createLuxonDate from "utils/date/createLuxonDate"
import {globalAxiosController} from "api/axios/globalAxiosController";

type ActionState = {
    requesting: boolean;
    errors?: string[];
};

const initialState = {
    requesting: false,
    errors: undefined
} as ActionState;

type ActionProps =
    { type: "CLEAR_STATE" }
    | { type: "REQUEST_UPDATE" }
    | { type: "SUCCESS_UPDATE" }
    | { type: "FAILURE_UPDATE", errors: string[] }
    ;

const reducer = (state: ActionState, action: ActionProps): ActionState => {
    switch (action.type) {
    case "CLEAR_STATE":
        return {
            ...initialState
        };
    case "REQUEST_UPDATE":
        return {
            ...state,
            errors: undefined,
            requesting: true
        };
    case "SUCCESS_UPDATE":
        return {
            ...state,
            requesting: false
        };
    case "FAILURE_UPDATE":
        return {
            ...state,
            requesting: false,
            errors: action.errors
        };
    default:
        return state;
    }
};

type UseSpecialRequestsAction = ActionState & {
    updateSpecialRequests: (specialRequests: SpecialRequestsPropTypes) => void;
    clearState: () => void;
};

export function useSpecialRequestsAction(id: number): UseSpecialRequestsAction {
    const [state, dispatch] = useReducer(reducer, initialState);
    const reduxDispatch = useAppDispatch();
    const locale = useAppSelector((state) => state.locale.currentLocale);
    const {t} = useTranslation();

    const clearState = () => {
        dispatch({type: "CLEAR_STATE"});
    };

    const updateSpecialRequests = useCallback((specialRequests: SpecialRequestsPropTypes) => {
        dispatch({type: "REQUEST_UPDATE"});
        globalAxiosController.addRequest(specialRequestsAmendmentRequest(specialRequests, id, locale))
            .then((data) => {
                if (data && data.success) {
                    reduxDispatch(setBookingSpecialRequests(specialRequests));
                    dispatch({type: "SUCCESS_UPDATE"});
                } else {
                    dispatch({
                        type: "FAILURE_UPDATE",
                        errors: data && data.errors ? data.errors : [t("bs_sr_something_went_wrong")]
                    });
                }
            })
            .catch((error: unknown) => {
                dispatch({
                    type: "FAILURE_UPDATE",
                    errors: [error as string || t("bs_sr_something_went_wrong")]
                });
            });
    }, [id, locale, reduxDispatch, t]);
    return {
        ...state,
        updateSpecialRequests,
        clearState
    };
}

const formState = {
    babyCot: false,
    doubleBed: false,
    honeymoon: false,
    lateArrival: false,
    lateArrivalTime: undefined,
    nonSmoking: false,
    separateBeds: false,
    smoking: false,
    text: ""
} as SpecialRequestsPropTypes;

const formReducer = (state: SpecialRequestsPropTypes, action: any) => {
    const {
        type, name, value, lateArrivalTime, oldState, newState
    } = action;
    switch (type) {
    case "HANDLE_INPUT":
        return {
            ...state,
            [name]: value
        };
    case "HANDLE_LATE_ARRIVAL":
        return {
            ...state,
            lateArrivalTime
        };
    case "TOGGLE_LATE_ARRIVAL":
        return {
            ...state,
            lateArrival: !state.lateArrival
        };
    case "RESET_FORM":
        return oldState;
    case "UPDATE_FORM":
        return newState;
    default:
        return state;
    }
};
type SpecialRequestsFormProps = {
    state: SpecialRequestsPropTypes,
    handleSpecialRequests: (target: { target: { value: string | boolean, name: string } }) => void
    handleLateArrival: (hoursMinutes: string) => void
    toggleLateArrival: () => void, resetForm: () => void
};

export function useSpecialRequestsForm(specialRequests?: SpecialRequestsPropTypes, checkIn?: DateTime): SpecialRequestsFormProps {
    const [state, dispatch] = useReducer<Reducer<SpecialRequestsPropTypes, any>>(formReducer, {
        ...formState,
        ...specialRequests
    } as SpecialRequestsPropTypes);

    const resetForm = useCallback(() => dispatch({
        type: "RESET_FORM",
        oldState: {
            ...formState,
            ...specialRequests
        }
    }), [specialRequests]);

    useEffect(() => {
        resetForm();
    }, [resetForm, specialRequests]);

    const handleSpecialRequests = useCallback(({target: {value, name}}: any) => dispatch({
        type: "HANDLE_INPUT",
        name,
        value
    }), []);

    const handleLateArrival = useCallback((hoursMinutes: string) => {
        if (!checkIn) {
            return;
        }

        const hour = parseInt(hoursMinutes.split(":")[0], 10);
        const minute = parseInt(hoursMinutes.split(":")[1], 10);

        const lateArrivalTime = dateWithSetTime(checkIn, hour, minute);
        dispatch({
            type: "HANDLE_LATE_ARRIVAL",
            lateArrivalTime
        });
    }, [checkIn]);

    const toggleLateArrival = useCallback(() => dispatch({type: "TOGGLE_LATE_ARRIVAL"}), []);

    return {
        state,
        handleSpecialRequests,
        handleLateArrival,
        toggleLateArrival,
        resetForm
    };
}

type SpecialRequestsProps = {
    specialRequests?: SpecialRequestsPropTypes;
    id: number;
    checkIn?: DateTime;
    bookingStatus?: string;
};

function SpecialRequests({
    specialRequests,
    bookingStatus,
    id,
    checkIn
}: SpecialRequestsProps): ReactElement {
    const {t} = useTranslation();
    const [editing, setEditing] = useState(false);
    const specialRequestsFormed = getSpecialRequestText(specialRequests);
    const canEdit = Math.floor(createLuxonDate(convertToServerTime(checkIn?.toMillis())).diff(createLuxonDate(), "days").days) > 0 && bookingStatus === "CONFIRMED";
    const {
        state,
        handleSpecialRequests,
        handleLateArrival,
        toggleLateArrival,
        resetForm
    } = useSpecialRequestsForm(specialRequests, checkIn);

    const {
        requesting,
        errors,
        updateSpecialRequests,
        clearState
    } = useSpecialRequestsAction(id);

    const handleSave = useCallback(() => {
        setEditing(false);
        updateSpecialRequests(state);
        resetForm();
    }, [updateSpecialRequests, state, resetForm]);

    const handleClose = useCallback(() => {
        resetForm();
        setEditing(false);
    }, [resetForm]);

    if (!specialRequestsFormed && !canEdit) {
        return (<></>);
    }

    let lateArrivalTime: string | undefined;
    if (!DateTime.isDateTime(state.lateArrivalTime) && !Number.isNaN(state.lateArrivalTime)) {
        let test;
        if (specialRequests?.lateArrivalTime) {
            test = createLuxonDate(specialRequests?.lateArrivalTime);
        } else {
            test = createLuxonDate(dateWithSetTime(createLuxonDate(checkIn), 0, 0));
        }
        lateArrivalTime = createLuxonDate(dateWithSetTime(createLuxonDate(checkIn), test.hour, test.minute)).toFormat("HH:mm");
    } else if (state.lateArrivalTime) {
        lateArrivalTime = createLuxonDate(state.lateArrivalTime).toFormat("HH:mm");
    }

    return (
        <div className={styles.Root}>
            <p className={styles.Heading}>{t("bs_sr_special_request")}</p>

            <div className={styles.spinnerZone}>
                {errors && (
                    <InfoBox
                        style={{
                            marginBottom: 10
                        }}
                        title={t("bs_sr_something_went_wrong")}
                        message={errors.map((errKey: string) => t(errKey)).join(". ")}
                        type="warn"
                    />
                )}

                {requesting && (
                    <Spinner
                        size="50px"
                        style={{
                            width: "100%",
                            height: "120px"
                        }}
                    />
                )}

                {!requesting && editing && (
                    <SpecialRequestsForm
                        handleSpecialRequests={handleSpecialRequests}
                        handleLateArrival={handleLateArrival}
                        toggleLateArrival={toggleLateArrival}
                        {...state}
                        lateArrivalTime={lateArrivalTime}
                    >
                        <div>
                            <span className={styles.Edit} onClick={handleSave}>
                                {t("bs_sr_save_changes")}
                            </span>
                            <span className={styles.CancelEdit} onClick={handleClose}>
                                {t("bs_sr_cancel_button")}
                            </span>
                        </div>
                    </SpecialRequestsForm>
                )}

                {!requesting && !editing && (specialRequestsFormed && !_.isEmpty(specialRequestsFormed) ? (
                    <>
                        {specialRequestsFormed.map((specialRequest, i) => (<p key={i}>{specialRequest}</p>))}
                        {canEdit && (
                            <p
                                className={styles.Edit}
                                onClick={() => {
                                    clearState();
                                    setEditing(true);
                                }}
                            >
                                {t("bs_sr_edit_special_requests")}
                            </p>
                        )}
                    </>
                ) : (canEdit && (
                    <p
                        className={styles.Edit}
                        onClick={() => {
                            clearState();
                            setEditing(true);
                        }}
                    >
                        {t("bs_sr_add_special_requests")}
                    </p>
                )))}
            </div>
        </div>
    );
}

export default SpecialRequests;
