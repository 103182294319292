import React, {ReactElement, useEffect, useState} from "react";
import useKeyDetect from "components/utils/useKeyDetect";
import {ReactComponent as SendMailIcon} from "assets/icons/send mail.svg";
import UserButton from "components/base/UserButton";
import {useTranslation} from "react-i18next";
import Fade from "components/utils/Transitions/Fade";
import styles from "./EmailSent.module.scss";
import ModalWithOverlay from "../../Modal/ModalWithOverlay";
import Modal from "../../Modal";
import {UserButtonVariant} from "../../../base/UserButton/UserButton";
import ActionIcon, {ActionIconType} from "../../ActionIcon/ActionIcon";

type Props = {
    onClose: () => void;
};

function EmailSent({
    onClose
}: Props): ReactElement {
    const [fade, setFade] = useState(false);
    const fadeDuration = 300;

    const onCloseWithFade = () => {
        setFade(false);
        setTimeout(() => onClose(), fadeDuration);
        onClose();
    };

    useKeyDetect(
        27, onCloseWithFade
    );

    useEffect(() => {
        setFade(true);
    }, []);

    const {t} = useTranslation();

    return (
        <Modal>
            <Fade
                inProp={fade}
                duration={fadeDuration}
            >
                <ModalWithOverlay>
                    <div className={styles.root}>
                        <div
                            onClick={onCloseWithFade}
                            className={styles.closeBtn}
                        >
                            <ActionIcon type={ActionIconType.CLOSE} />
                        </div>
                        <div className={styles.header}>
                            <SendMailIcon />
                            <div className={styles.title}>{t("pin_sem_es_email_sent_success")}</div>
                        </div>
                        <UserButton
                            text={t("pin_sem_sem_go_back_button")}
                            variant={UserButtonVariant.SECONDARY}
                            buttonProps={{
                                onClick: onCloseWithFade,
                                type: "button",
                                style: {
                                    margin: "auto",
                                    height: "40px",
                                    width: "160px"
                                }
                            }}
                        />
                    </div>
                </ModalWithOverlay>
            </Fade>
        </Modal>
    );
}

export default EmailSent;
