import {Location} from "history";
import RouterTypes from "../../constants/router.constants";
import {MyAction} from "../../store/store.types";

export enum LocationBaseKey {
    MY_BOOKINGS = "myBookings",
    MY_COMPANY = "myCompany",
    HOTELS = "hotels",
    TRANSFERS = "transfers",
    CONTACTS = "contacts"
}

export enum LastLocationKey {
    LAST_BOOKING_LOCATION = "lastBookingLocation",
    LAST_SEARCH_RESULTS_LOCATION = "lastSearchResultsLocation",
    LAST_SEARCH_RESULTS_LISTING_LOCATION = "lastSearchResultsListingLocation",
    LAST_MY_COMPANY_LOCATION = "lastMyCompanyLocation",
    LAST_CONTACTS_LOCATION = "lastContactsLocation"
}

type RouterActions = MyAction<RouterTypes.SET_LAST_LOCATION, { location: Location, baseKey: LocationBaseKey, lastLocationKey: LastLocationKey }>
| MyAction<RouterTypes.TOGGLE_DISABLED_NAV, { disabledNav: boolean }>;

export default RouterActions;