import React, {ReactElement, useCallback, useEffect} from "react"
import PaymentContent from "components/common/Payment/PaymentContent/PaymentContent"
import MainContainer from "components/base/MainContainer"
import isEqual from "lodash/isEqual"
import {useTranslation} from "react-i18next"
import PaymentSummary from "./PaymentSummary"
import RedirectingModal from "components/common/Modal/RedirectingModal"
import RequestingBookingWrapper from "components/common/RequestingBookingWrapper/RequestingBookingWrapper"
import Spinner from "components/base/Loaders/Spinner"
import {Navigate} from "react-router-dom"
import {useCurrency, useLocale} from "components/utils/withLocalesAndCurrencies"
import usePaymentOptions from "components/base/Payment/PaymentType/usePaymentOptions"
import {useAppDispatch, useAppSelector} from "redux/hooks"
import {makeHotelBookingRequest, updateBookingOptions} from "redux/actions/hotelBooking.actions"
import PaymentProviderRedirect from "components/common/PaymentProviderRedirect/PaymentProviderRedirect";
import {ReduxPaymentTypePropTypes} from "proptypes/PropTypeRedux";
import usePaymentType from "components/utils/usePaymentType";

const PaymentContainer = (): ReactElement => {
    const {t} = useTranslation()

    const {
        paymentOptions
    } = usePaymentOptions()

    const dispatch = useAppDispatch();
    const currency = useCurrency();
    const locale = useLocale();

    usePaymentType(ReduxPaymentTypePropTypes.HOTEL);

    const providerPayment = useAppSelector((state) => state.payment.providerPayment)

    const {
        bookingOptionsParams,
        requestingBooking,
        requestingValidation,
        requestingOptions
    } = useAppSelector((state) => state.hotelBooking);
    const route = useAppSelector((state) => state.hotelBooking.data?.route || "");

    const handleValidationAndMakeBooking = useCallback(() => {
        dispatch(makeHotelBookingRequest())
    }, [dispatch])

    const handleSubmit = useCallback((e: React.SyntheticEvent) => {
        e.preventDefault()
        handleValidationAndMakeBooking()
    }, [handleValidationAndMakeBooking])

    // const calculateTotalPassengers = useCallback(() => {
    //     if (!hotelBookingInfo) {
    //         return 0
    //     }
    //
    //     let passengerCount = 0
    //     hotelBookingInfo.hotelBookingRoomInfo.forEach((room) => {
    //         passengerCount += room.adults?.length + room.children?.length
    //     })
    //
    //     return passengerCount
    // }, [hotelBookingInfo])

    useEffect(() => {
        if (!bookingOptionsParams) {
            return
        }

        if (
            !requestingOptions &&
            !requestingValidation &&
            !requestingBooking &&
            bookingOptionsParams &&
            !isEqual({
                locale,
                currency
            }, bookingOptionsParams)
        ) {
            dispatch(updateBookingOptions());
        }
    }, [bookingOptionsParams, currency, dispatch, locale, requestingBooking, requestingOptions, requestingValidation])

    return (
        <MainContainer
            sidebar={
                !requestingValidation && !requestingBooking && paymentOptions && (
                    <PaymentSummary/>
                )
            }
            variants={{
                center: requestingValidation || requestingBooking
            }}
        >
            {requestingValidation && (
                <Spinner
                    size="50px"
                    style={{
                        width: "100%",
                        height: "calc(100vh - 60px)"
                    }}
                />
            )}

            {/*{requestingBooking && hotel && (*/}
            {requestingBooking && (
                <RequestingBookingWrapper/>
            )}

            {(!requestingValidation && !requestingBooking) && (route === "CHANGES" || route === "NO_AVAILABILITY" || route === "DUPLICATE_BOOKINGS") ? (
                <Navigate
                    to={{
                        pathname: "/hotels/booking/changed"
                    }}
                />
            ) : (
                <>
                    {!!providerPayment && (
                        <PaymentProviderRedirect {...providerPayment}>
                            <RedirectingModal/>
                        </PaymentProviderRedirect>
                    )}
                </>
            )}

            {!requestingValidation && !requestingBooking && paymentOptions && (
                <PaymentContent
                    handleSubmit={handleSubmit}
                />
            )}
        </MainContainer>
    )
}

export default PaymentContainer