import {ReduxTransferSearch} from "proptypes/PropTypeRedux";
import TransferSearchTypes from "../constants/transferSearch.constants";
import TransferSearchActions from "./actions/transferSearch.reducer.actions";
import {resetTime} from "utils/dateUtils"
import createLuxonDate from "utils/date/createLuxonDate"

export const INITIAL_STATE = {
    submitedSearch: true,
    isSearching: false,
    isUpdating: false,
    error: undefined,
    saveSearch: false,
    airportInput: "",
    venueInput: "",
    bookRoundtrip: true,
    currentDestination: undefined,
    currentOrigin: undefined,
    formData: {
        adults: 2,
        arrivalTime: resetTime(createLuxonDate().plus({day: 7})).set({hour: 12}).toISO({includeOffset: false}),
        arrivalTransfer: true,
        children: [],
        departureTime: resetTime(createLuxonDate().plus({day: 8})).set({hour: 12}).toISO({includeOffset: false}),
        departureTransfer: true,
        destinationId: undefined,
        originId: undefined,
        relatedBooking: undefined
    },
    stateFormData: {
        adults: 2,
        arrivalTime: resetTime(createLuxonDate().plus({day: 7})).set({hour: 12}).toISO({includeOffset: false}),
        departureTime: resetTime(createLuxonDate().plus({day: 8})).set({hour: 12}).toISO({includeOffset: false}),
        children: []
    },
    recentSearches: [],
    savedSearches: []
} as ReduxTransferSearch;

// update search after search to do not show searching screen START_SEARCH_UPDATE , isUpdating used in searchResults reducer?
export default (state = INITIAL_STATE, action: TransferSearchActions): ReduxTransferSearch => {
    switch (action.type) {
    case TransferSearchTypes.INIT_SEARCH:
        return {
            //...INITIAL_STATE ,
            ...state,
            error: undefined
        };
    case TransferSearchTypes.SUBMIT_SEARCH:
        return {
            ...state,
            submitedSearch: true,
            formData: {...state.formData, ...state.stateFormData}
        };
    case TransferSearchTypes.START_SEARCH:
        return {
            ...state,
            formData: {...action.stateFormData, ...state.formData},
            isSearching: true
        };
    case TransferSearchTypes.UPDATE_SEARCH:
        return {
            ...state,
            formData: {
                ...action.stateFormData,
                ...state.formData,
                adults: action.stateFormData.adults || state.formData.adults || 2,
                children: action.stateFormData.children || state.formData.children || []
            },
            isSearching: true,
            isUpdating: true
        };
    case TransferSearchTypes.GET_RECENT_SEARCHES:
        return {
            ...state,
            recentSearches: action.recentSearches || []
        };
    case TransferSearchTypes.GET_SAVED_SEARCHES:
        return {
            ...state,
            savedSearches: action.savedSearches || []
        };
    case TransferSearchTypes.GET_DATA_SUCCESS:
        return {
            ...state,
            isSearching: false,
            isUpdating: false,
            error: undefined
        };
    case TransferSearchTypes.GET_DATA_FAILURE:
        return {
            ...state,
            error: action.error,
            isSearching: false,
            isUpdating: false
        };
    case TransferSearchTypes.HANDLE_FORM_DATA:
        return {
            ...state,
            submitedSearch: false,
            formData: {
                ...action.formData,
                adults: action.formData.adults || 2,
                children: action.formData.children || []
            }
        };
    case TransferSearchTypes.HANDLE_STATE_FORM_DATA:
        return {
            ...state,
            submitedSearch: false,
            stateFormData: {
                ...action.stateFormData,
                adults: action.stateFormData.adults || 2,
                children: action.stateFormData.children || []
            }
        };
    case TransferSearchTypes.TOGGLE_ROUND_TRIP: {
        return {
            ...state,
            bookRoundtrip: action.bookRoundtrip
        };
    }
    case TransferSearchTypes.HANDLE_INPUT_CHANGE:
        return {
            ...state,
            submitedSearch: false,
            [action.inputName]: action.inputValue
        };
    case TransferSearchTypes.HANDLE_SAVE_SEARCH:
        return {
            ...state,
            submitedSearch: false,
            saveSearch: action.saveSearch
        };
    case TransferSearchTypes.CHANGE_CURRENT_DESTINATION:
        return {
            ...state,
            [action.key]: action.value
        };
    default:
        return state;
    }
};
