type Consumer<T> = (payload: T | null) => T;

export default class Pipeline<T> {
    private consumers: Consumer<T | null>[] = [];

    public next(payload: T | null): T | null {
        let currValue = payload;
        this.consumers.forEach((consumer) => {
            currValue = consumer(currValue);
        });

        return currValue;
    }

    public collect(): T | null {
        let currValue: T | null = null;

        this.consumers.forEach((consumer) => {
            currValue = consumer(currValue);
        });

        return currValue;
    }

    public subscribe(consumer: Consumer<T | null>): void {
        if (!this.consumers.find((existingConsumer) => existingConsumer === consumer)) {
            this.consumers.push(consumer);
        }
    }

    public unsubscribe(consumer: Consumer<T | null>): void {
        if (this.consumers.find((existingConsumer) => existingConsumer === consumer)) {
            this.consumers.splice(this.consumers.indexOf(consumer), 1);
        }
    }
}