import React, {
    ReactElement, useCallback, useReducer
} from "react";
import {useTranslation} from "react-i18next";
import {markHotelAsFavorite, removeHotelFromFavorite} from "api/hotelAPI";
import {toggleHotelFavorite as toggleHotelFavoriteAction} from "redux/actions/hotelSearchResults.actions";
import {ReactComponent as HeartIcon} from "assets/icons/heart.svg";
import cx from "classnames";
import styles from "./FavoriteHotel.module.scss";
import {useAppDispatch, useAppSelector} from "../../../redux/hooks";
import {shallowEqual} from "react-redux";
import {globalAxiosController} from "api/axios/globalAxiosController";

type ActionState = {
    requesting: boolean;
    errors?: string[];
};

const initialState = {
    requesting: false,
    errors: undefined
} as ActionState;

const reducer = (state: ActionState, action: any) => {
    switch (action.type) {
    case "REQUEST_UPDATE":
        return {
            ...state,
            errors: null,
            requesting: true
        };
    case "SUCCESS_UPDATE":
        return {
            ...state,
            requesting: false
        };
    case "FAILURE_UPDATE":
        return {
            ...state,
            requesting: false,
            errors: action.errors
        };
    default:
        return state;
    }
};
type UseHotelFavorite = ActionState & {
    isFavorite: boolean,
    toggleHotelFavorite: () => void,
};

export function useHotelFavorite(id: number): UseHotelFavorite {
    const [state, dispatch] = useReducer(reducer, initialState);
    const reduxDispatch = useAppDispatch();
    const locale = useAppSelector((state) => state.locale.currentLocale);
    const isFavorite = useAppSelector((state) => state.hotelSearchResults.hotelOffers.findIndex((item) => item.hotel.id === id && item.favourite) > -1, shallowEqual);
    const {t} = useTranslation();

    const toggleHotelFavorite = useCallback(() => {
        dispatch({type: "REQUEST_UPDATE"});
        globalAxiosController.addRequest(isFavorite ? removeHotelFromFavorite(id, locale) : markHotelAsFavorite(id, locale))
            .then((data) => {
                if (data && data.success) {
                    reduxDispatch(toggleHotelFavoriteAction(id));
                    dispatch({type: "SUCCESS_UPDATE"});
                } else {
                    dispatch({
                        type: "FAILURE_UPDATE",
                        errors: [
                            (data && data.errors) || t("fh_fh_something_went_wrong")
                        ]
                    });
                }
            })
            .catch((error: any) => {
                // console.log(error);
                dispatch({
                    type: "FAILURE_UPDATE",
                    errors: [t("fh_fh_something_went_wrong")]
                });
            });
    }, [id, isFavorite, locale, reduxDispatch, t]);
    return {
        ...state,
        isFavorite,
        toggleHotelFavorite
    };
}

export default function FavoriteHotel({id, className, ...rest}: any): ReactElement {
    const {toggleHotelFavorite, isFavorite} = useHotelFavorite(id);
    return (
        <HeartIcon
            {...rest}
            className={cx(className, styles.root, isFavorite && styles.active)}
            onClick={toggleHotelFavorite}
        />
    );
}
