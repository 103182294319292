import React, {
    ReactElement, useCallback, useReducer, useState
} from "react";
import Input from "components/base/Input";
import ConfirmationModal from "components/common/ConfirmationModal";
import LineWithAction from "components/common/LineWithAction/LineWithAction";
import SuccessModal from "components/common/ConfirmationModal/SuccessModal";
import {useTranslation} from "react-i18next";
import {useAddMarkupAction} from "components/utils/useCompanyData";
import styles from "./AddMarkup.module.scss";
import {CompanyMarkupsPropTypes, CompanyMarkupsRequestPropTypes} from "../../../proptypes/PropTypeObjects";

type ActionProps =
     {type: "HANDLE_INPUT", name: string, value: string | number}
    ;

function reducer(state: CompanyMarkupsRequestPropTypes, action: ActionProps) {
    const {type, name, value} = action;
    switch (type) {
    case "HANDLE_INPUT":
        return {
            ...state,
            [name]: value
        };
    default:
        return state;
    }
}

const initialData = {
    name: "",
    hotelAmount: undefined,
    transferAmount: undefined
} as CompanyMarkupsRequestPropTypes;

type Props = {
    onConfirm: (markup: CompanyMarkupsRequestPropTypes) => void;
    onDecline: () => void;
    errors?: string[];
    spinner: boolean;
};

function AddMarkupModal({
    onConfirm,
    onDecline,
    errors,
    spinner
}: Props): ReactElement {
    const {t} = useTranslation();
    const [localFormData, dispatch] = useReducer(reducer, initialData);

    const handleInput = ({
        target: {name, value, type}
    }: React.ChangeEvent<HTMLInputElement>) => dispatch({
        type: "HANDLE_INPUT",
        name,
        value: type === "number" ? (value ? Number(value) : "") : value
    });

    const content = (
        <div className={styles.content}>
            <div className="mb-20">
                <Input
                    variants={{labelPosition: "outlined"}}
                    fullHeight
                    inputProps={{
                        name: "name",
                        placeholder: t("mb_am_name"),
                        required: true,
                        onChange: handleInput,
                        value: localFormData.name
                    }}
                    className="mb-20"
                />
            </div>

            <Input
                fullHeight
                className="mb-20"
                variants={{labelPosition: "outlined"}}
                inputProps={{
                    name: "hotelAmount",
                    type: "number",
                    required: true,
                    step: "0.01",
                    min: 0,
                    placeholder: t("mb_am_hotel_percent"),
                    onChange: handleInput,
                    value: localFormData.hotelAmount
                }}
            />

            <Input
                fullHeight
                className="mb-60"
                variants={{labelPosition: "outlined"}}
                inputProps={{
                    required: true,
                    name: "transferAmount",
                    type: "number",
                    step: "0.01",
                    min: 0,
                    placeholder: t("mb_am_transfer_percent"),
                    onChange: handleInput,
                    value: localFormData.transferAmount
                }}
            />
        </div>
    );

    return (
        <ConfirmationModal
            onConfirm={() => onConfirm(localFormData)}
            onDecline={onDecline}
            config={{
                variants: {
                    type: "normal"
                },
                title: t("mb_am_add_markup"),
                //customHeader,
                content,
                isForm: true,
                validateForm: true,
                confirmButtonText: t("mb_am_save"),
                declineButtonText: t("mb_am_cancel"),
                errors,
                spinner
            }}
        />
    );
}

type AddMarkupProps = {
    addMarkup: (companyMarkup: CompanyMarkupsPropTypes) => void;
};

function AddMarkup({
    addMarkup
}: AddMarkupProps): ReactElement {
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [successModal, setSuccessModal] = useState(false);

    const onSuccess = useCallback(
        (companyMarkup: CompanyMarkupsPropTypes) => {
            setModalIsOpen(false);
            setSuccessModal(true);
            addMarkup(companyMarkup);
        },
        [addMarkup]
    );

    const {t} = useTranslation();

    const {
        requesting,
        errors,
        addMarkup: addMarkupAction,
        clearState
    } = useAddMarkupAction(onSuccess);

    return (
        <>
            {modalIsOpen && (
                <AddMarkupModal
                    onConfirm={(companyMarkup) => {
                        addMarkupAction(companyMarkup);
                    }}
                    onDecline={() => {
                        setModalIsOpen(false);
                    }}
                    errors={errors}
                    spinner={requesting}
                />
            )}
            {successModal && (
                <SuccessModal
                    onClose={() => setSuccessModal(false)}
                    successMessage={t("mb_am_success_message_create_markup")}
                />
            )}
            <LineWithAction
                label={t("mb_am_create_new_markup")}
                onClick={() => {
                    setModalIsOpen(true);
                    clearState();
                }}
            />
        </>
    );
}

export default AddMarkup;
