import {useState, useEffect} from "react";
import dateToFromNowDaily from "../dateToFromNowDaily";
import {DateTime} from "luxon"
import createLuxonDate from "../date/createLuxonDate"
import createHumanDateString from "../date/createHumanDateString"

function getUpdateRate(ms: number) {
    if (ms < 3600000) return 60000;
    if (ms < 86400000) {
        return 3600000;
    }
    return 86400000;
}

function getDifferenceInMs(time: number) {
    return DateTime.now().diff(createLuxonDate(time), ["milliseconds"]).milliseconds;
}

type Props = {
    timeFormatted: string;
    fromNow: string;
    fromNowInDays: number;
};

type InProps = {
    time: number;
    outputFormat: string;
    displayDailyOnly?: boolean;
    realTimeUpdate?: boolean;
};

export default function useDynamicTimeUpdate({
    time,
    outputFormat,
    displayDailyOnly = false,
    realTimeUpdate = true
}: InProps): Props {
    const [diff, setDiff] = useState(getDifferenceInMs(time));

    useEffect(() => {
        const timeout = realTimeUpdate &&
            setTimeout(
                () => setDiff(getDifferenceInMs(time)),
                getUpdateRate(diff)
            );

        return () => {
            if (timeout) {
                clearTimeout(timeout);
            }
        };
    }, [diff, time, realTimeUpdate]);

    return {
        timeFormatted: createLuxonDate(time).toFormat(outputFormat),
        fromNow: displayDailyOnly
            ? dateToFromNowDaily(time, outputFormat)
            : createHumanDateString(createLuxonDate(time), createLuxonDate()),
        fromNowInDays: DateTime.fromFormat(createLuxonDate().toFormat("yyyy-MM-dd"), "yyyy-MM-dd").diff(
            DateTime.fromFormat(createLuxonDate(time).toFormat("yyyy-MM-dd"), "yyyy-MM-dd"), ["days"]
        ).days
    };
}
