import isPathClockwise from "./isPathClockwise";

export default function getPolygonInnerPaths(polygon: google.maps.Polygon): google.maps.LatLng[] {
    const polygonPaths: google.maps.LatLng[] = [];
    const len = polygon.getPath().getLength();

    if (isPathClockwise(polygon, polygon.getPath())) {
        for (let i = len - 1; i >= 0; i--) {
            polygonPaths.push(polygon.getPath()
                .getAt(i));
        }
    } else {
        for (let i = 0; i < len; i++) {
            polygonPaths.push(polygon.getPath()
                .getAt(i));
        }
    }

    return polygonPaths;
}