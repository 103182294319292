import React, {ReactElement} from "react";
import MainContainer from "components/base/MainContainer";
import PaymentSummary from "views/Transfers/Payment/PaymentSummary";
import SplitPaymentContent from "components/common/Payment/PaymentContent/SplitPaymentContent/SplitPaymentContent";
import usePaymentType from "components/utils/usePaymentType";
import {ReduxPaymentTypePropTypes} from "proptypes/PropTypeRedux";

const SplitPayment = (): ReactElement => {
    usePaymentType(ReduxPaymentTypePropTypes.TRANSFER);

    return (
        <MainContainer
            sidebar={(
                <PaymentSummary/>
            )}
        >
            <SplitPaymentContent/>
        </MainContainer>
    )
}

export default SplitPayment;