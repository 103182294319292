import React, {FormEvent, ReactElement, useCallback, useState} from "react";
import {useTranslation} from "react-i18next";
import {useAppDispatch, useAppSelector} from "redux/hooks";
import {applyDiscountCode, setDiscountCode} from "redux/actions/payment.actions";
import styles from "./DiscountCode.module.scss";
import Input from "components/base/Input";
import ExpandableBox from "../ExpandableBox/ExpandableBox";
import {ReduxPaymentTypePropTypes} from "proptypes/PropTypeRedux";
import {ReactComponent as CloseIcon} from "assets/icons/crossSlim.svg";

const DiscountCode = (): ReactElement => {
    const {t} = useTranslation();
    const dispatch = useAppDispatch();

    const [discountCodeInput, setDiscountCodeInput] = useState<string>("");
    const [loading, setLoading] = useState<boolean>(false);

    const paymentType = useAppSelector((state) => state.payment.type);
    const appliedDiscount = useAppSelector((state) => state.payment.appliedDiscountAmount);
    const discountCode = useAppSelector((state) => state.payment.appliedDiscountCode);

    const applyDiscountCallback = useCallback((evt: MouseEvent | FormEvent) => {
        evt.stopPropagation();
        evt.preventDefault();

        if (!discountCodeInput || discountCode?.valid) {
            return;
        }

        setLoading(true);

        dispatch(applyDiscountCode(discountCodeInput, (code) => {
            setLoading(false);

            if (code?.valid) {
                setDiscountCodeInput("");
            }
        }));
    }, [discountCode?.valid, discountCodeInput, dispatch]);

    const clearCodeCallback = useCallback(() => {
        dispatch(setDiscountCode(undefined));
    }, [dispatch]);

    if (
        appliedDiscount ||
        paymentType === ReduxPaymentTypePropTypes.MULTIBOOKING ||
        paymentType === ReduxPaymentTypePropTypes.ANONYMOUS_MULTIBOOKING ||
        paymentType === ReduxPaymentTypePropTypes.SPLIT_PRICE_PART_PAYMENT
    ) {
        return (
            <></>
        );
    }

    return (
        <ExpandableBox
            title={t("pc_pc_discount_code_title")}
            active={discountCode?.valid}
        >
            <form className={styles.Root} onSubmit={applyDiscountCallback}>
                <Input
                    className={styles.DiscountCodeInput}
                    variants={{
                        labelPosition: "inside",
                        helperAndErrorTextPosition: "bottom"
                    }}
                    inputProps={{
                        value: discountCodeInput,
                        onChange: (evt) => {
                            setDiscountCodeInput(evt.target.value.toUpperCase());
                        },
                        placeholder: t("pc_pc_discount_code_input_placeholder"),
                        disabled: discountCode?.valid
                    }}
                >
                    {discountCode?.valid && (
                        <span>{discountCode?.code} <CloseIcon onClick={clearCodeCallback}/> </span>
                    )}

                    <a onClick={applyDiscountCallback}>{t("pc_pc_discount_code_apply")}</a>
                </Input>

                {!loading && discountCode && discountCode.valid === false && (
                    <span>{t("pc_pc_discount_code_err_invalid")}</span>
                )}
            </form>
        </ExpandableBox>
    );
}

export default DiscountCode;