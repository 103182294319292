import Currency from "@hotelston_web_frontend_parent/constants/currency"

export default function getCurrencySymbol(currencyCode: Currency): string {
    switch (currencyCode) {
    case Currency.EUR:
        return "€";
    case Currency.GBP:
        return "£";
    case Currency.PLN:
        return "zł";
    case Currency.BGN:
        return "лв";
    case Currency.RON:
        return "lei";
    case Currency.USD:
        return "$";
    case Currency.SEK:
        return "kr";
    case Currency.NOK:
        return "kr";
    case Currency.RUB:
        return "₽";
    default:
        return currencyCode;
    }
}
