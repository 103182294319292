import React, {
    JSXElementConstructor,
    ReactElement,
    useCallback,
    useEffect,
    useState
} from "react"
import {GoogleMap, GoogleMapProps, Libraries, useLoadScript} from "@react-google-maps/api"
import {useTranslation} from "react-i18next";
import cx from "classnames";
import {setMapCanBeLoaded} from "redux/actions/map.actions";
import Spinner from "../../base/Loaders/Spinner";
import {useAppDispatch, useAppSelector} from "redux/hooks";
import styles from "./GoogleMapContainer.module.scss";
import {RootState} from "redux/store/store.init";

const locales = [
    {name: "English", lang: "en", googleLang: "en"},
    {name: "Italiano", lang: "it", googleLang: "it"},
    {name: "Pусский", lang: "ru", googleLang: "ru"},
    {name: "Polski", lang: "pl", googleLang: "pl"},
    {name: "Lietuvių", lang: "lt", googleLang: "lt"},
    {name: "Latviešu", lang: "lv", googleLang: "lv"},
    {name: "Eesti", lang: "ee", googleLang: "et"},
    {name: "Suomi", lang: "fi", googleLang: "fi"},
    {name: "Български", lang: "bg", googleLang: "be"},
    {name: "Français", lang: "fr", googleLang: "fr"},
    {name: "Español", lang: "es", googleLang: "es"},
    {name: "Svensk", lang: "se", googleLang: "sv"},
    {name: "Dansk", lang: "dk", googleLang: "da"},
    {name: "ελληνικά", lang: "gr", googleLang: "el"}
];

const apiKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;
const libraries: Libraries = ["places", "drawing", "geometry", "marker", "streetView", "core", "maps"];

export interface GoogleMapContainerProps extends GoogleMapProps {
    children?: ReactElement<any, string | JSXElementConstructor<any>>;
    coordinates?: { latitude: number, longitude: number };

    noResultsOverlay?: boolean;
    noResultsCustomOverlay?: ReactElement;

    googleMapContainerClassname?: string;
    spinner?: boolean;
}

const GoogleMapContainer = (props: GoogleMapContainerProps): ReactElement => {
    const {
        children,
        noResultsOverlay = false,
        noResultsCustomOverlay,
        mapContainerClassName,
        googleMapContainerClassname,
        coordinates,
        spinner
    } = props;
    const {t} = useTranslation();

    const dispatch = useAppDispatch();

    const [map, setMap] = useState<google.maps.Map>(undefined);
    const center = useAppSelector((state) => state.map.center);
    const zoom = useAppSelector((state) => state.map.zoom);

    const googleLang = useAppSelector((state: RootState) => {
        for (const element of locales) {
            if (element.lang === state.locale.currentLocale) {
                return element.googleLang;
            }
        }

        return "en";
    });

    const {isLoaded, loadError} = useLoadScript({
        googleMapsApiKey: apiKey || "",
        libraries: libraries,
        language: googleLang,
        id: "google-map-script",
        version: "quarterly"
        // preventGoogleFontsLoading: true
    });

    useEffect(() => {
        if (map) {
            // const usedZoom = zoom || map.getZoom() || 14;
            if (coordinates) {
                map.panTo({lat: coordinates.latitude, lng: coordinates.longitude} as google.maps.LatLngLiteral);
            } else if (center) {
                map.panTo(center);
            }

            // if (zoom && zoom !== map.getZoom()) {
            //     map.moveCamera({
            //         zoom
            //     });
            // }
        }
    }, [center, coordinates, map, zoom]);

    const onLoadCallback = useCallback((map: google.maps.Map) => {
        const {
            onLoad
        } = props;

        setMap(map);

        if (onLoad) {
            void onLoad(map);
        }
    }, [props]);

    useEffect(() => {
        if (loadError) {
            dispatch(setMapCanBeLoaded(false))
        }
    }, [dispatch, loadError])

    if (loadError) {
        return (
            <div className={styles.NoResultsMapPlaceholder}>{t("g_m_google_maps_load_error")}</div>
        );
    }

    if (!isLoaded) {
        return (<Spinner size={50}/>);
    }

    return (
        <div className={cx(styles.MapContainer, googleMapContainerClassname)}>
            {noResultsOverlay && (
                <div className={styles.NoResultsMapPlaceholder}>
                    <div>{t("g_m_no_results")}</div>
                    {noResultsCustomOverlay}
                </div>
            )}

            {spinner && (
                <div className={styles.MapSpinner} />
            )}

            <GoogleMap
                mapContainerStyle={{
                    height: "100%",
                    width: "100%",
                    zIndex: 1
                }}
                {...props}
                center={center}
                zoom={zoom}
                onLoad={onLoadCallback}
                mapContainerClassName={cx(mapContainerClassName, styles.MapContainer)}
                options={{
                    ...props?.options,
                    noClear: true,
                    streetViewControl: true,
                    gestureHandling: "greedy"
                }}
            >
                {children}
            </GoogleMap>
        </div>
    );
};

export default GoogleMapContainer;