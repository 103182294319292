import React, {ReactElement} from "react";
import cx from "classnames";
import styles from "./EditDeleteMarkup.module.scss";
import DeleteMarkup from "./DeleteMarkup";
import EditMarkup from "./EditMarkup";
import {CompanyMarkupsPropTypes} from "../../../proptypes/PropTypeObjects";
import Tippy from "../../../components/common/Tippy/Tippy";

type Props = {
    className?: string;
    companyMarkup: CompanyMarkupsPropTypes;
    deleteCompanyMarkup: (id: number) => void;
    updateCompanyMarkup: (companyMarkup: CompanyMarkupsPropTypes) => void;
    editTooltipText: string;
    deleteTooltipText: string;
};

function EditDeleteMarkup({
    className,
    companyMarkup,
    deleteCompanyMarkup,
    updateCompanyMarkup,
    editTooltipText,
    deleteTooltipText
}: Props): ReactElement {
    return (
        <div className={cx(styles.Root, className)}>
            <Tippy
                content={(
                    <span className={styles.Tooltip}>
                        {deleteTooltipText}
                    </span>
                )}
            >
                <div>
                    <DeleteMarkup
                        className={styles.DeleteMarkup}
                        deleteCompanyMarkup={deleteCompanyMarkup}
                        markupId={companyMarkup.id}
                    />
                </div>
            </Tippy>

            <Tippy
                content={(
                    <span className={styles.Tooltip}>
                        {editTooltipText}
                    </span>
                )}
            >
                <div>
                    <EditMarkup
                        onlyIcon
                        className={styles.EditMarkup}
                        updateCompanyMarkup={updateCompanyMarkup}
                        companyMarkup={companyMarkup}
                    />
                </div>
            </Tippy>
        </div>
    );
}

export default EditDeleteMarkup;
