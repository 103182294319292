import React, {useEffect, useMemo, useState} from "react"

const MAX_TICK_DELAY = 3200;
const MIN_TICK_DELAY = 100;
const INITIAL_TICK_DELAY = MAX_TICK_DELAY / 4;

class TrailingResizeObserver {
    CURR_TICK_DELAY: number = 0;

    Refs: Set<HTMLElement> = new Set<HTMLElement>();
    Listeners: (() => void)[] = [];
    HistoricalRefData = new Map<HTMLElement, { width: number, height: number }>();
    TimeoutId: NodeJS.Timeout | undefined;

    NativeResizeObserver = new ResizeObserver((evt) => {
        this.notifyListeners();

        // artificially awaken the ticker with its lowest possible delay val
        this.destroyTimer();
        this.buildGlobalTimer(MIN_TICK_DELAY);
    });

    notifyListeners() {
        this.Listeners.forEach(listener => listener());
    }

    onTickListener() {
        let changes: boolean = false;

        this.Refs.forEach((elem) => {
            const historicalData = this.HistoricalRefData.get(elem);

            const currWidth = elem.clientWidth;
            const currHeight = elem.clientHeight;
            if (historicalData) {
                const prevWidth = historicalData.width;
                const prevHeight = historicalData.height;

                const elemChanges = currWidth !== prevWidth || currHeight !== prevHeight;
                if (elemChanges) {
                    changes = true;

                    this.HistoricalRefData.set(elem, {width: currWidth, height: currHeight});
                }
            } else {
                changes = true;

                this.HistoricalRefData.set(elem, {width: currWidth, height: currHeight});
            }
        });

        if (changes) {
            this.notifyListeners();

            // Reduce tick time by half until minimum allowed value
            this.destroyTimer();
            this.buildGlobalTimer(Math.max(this.CURR_TICK_DELAY / 2, MIN_TICK_DELAY));
        } else if (this.CURR_TICK_DELAY === MAX_TICK_DELAY) {
            this.destroyTimer();
        } else {
            // No changes, gradually increase the timeout value to default
            this.destroyTimer();
            this.buildGlobalTimer(Math.min(this.CURR_TICK_DELAY * 2, MAX_TICK_DELAY));
        }
    }

    buildGlobalTimer(newDelay?: number) {
        this.CURR_TICK_DELAY = newDelay || MAX_TICK_DELAY;

        this.TimeoutId = setTimeout(() => {
            this.onTickListener();
        }, this.CURR_TICK_DELAY);
    }

    destroyTimer() {
        if (this.TimeoutId) {
            clearTimeout(this.TimeoutId);
        }
    }

    addListener(ref: HTMLElement, listener: () => void) {
        if (!this.Refs.has(ref)) {
            this.Refs = this.Refs.add(ref);
            this.Listeners.push(listener);
        }

        if (!this.TimeoutId) {
            this.buildGlobalTimer(INITIAL_TICK_DELAY);
        }
    }

    removeElement(ref: HTMLElement, listener: () => void) {
        this.Refs.delete(ref);
        this.Listeners.unshift(listener);

        if (this.Refs.size === 0 && this.TimeoutId) {
            this.destroyTimer();
        }
    }
}

export type UseTrailingResizeDetectorProps = {
    targetRef?: React.RefObject<HTMLElement>;
    targetRefs?: React.RefObject<HTMLElement>[];
    onResize: () => void;
};

const useTrailingResizeDetector = (props: UseTrailingResizeDetectorProps) => {
    const {
        targetRef,
        targetRefs,
        onResize
    } = props;

    const [isolatedHookObserver] = useState(new TrailingResizeObserver());

    const newElements = useMemo(() => {
        let newElements: (HTMLElement | null)[] | null = null;
        if (targetRef?.current) {
            newElements = [targetRef.current];
        } else if (targetRefs) {
            newElements = targetRefs.map(ref => ref.current).filter(elem => !!elem);
        }

        if (newElements) {
            newElements.forEach(elem => {
                if (elem) {
                    isolatedHookObserver.addListener(elem, onResize);
                    isolatedHookObserver.NativeResizeObserver.observe(elem);
                }
            });
        }

        return newElements;
    }, [isolatedHookObserver, onResize, targetRef, targetRefs]);

    useEffect(() => {
        const currCycleElems = newElements ? [...newElements] : null;
        if (currCycleElems) {
            currCycleElems.forEach(elem => {
                if (elem) {
                    isolatedHookObserver.addListener(elem, onResize);
                    isolatedHookObserver.NativeResizeObserver.observe(elem);
                }
            });
        }

        return () => {
            if (currCycleElems) {
                currCycleElems.forEach(elem => {
                    if (elem) {
                        isolatedHookObserver.removeElement(elem, onResize);
                        isolatedHookObserver.NativeResizeObserver.unobserve(elem);
                    }
                });
            }
        };
    }, [isolatedHookObserver, newElements, onResize]);

    useEffect(() => () => {
        // destroy old data upon unmount
        newElements?.forEach(elem => {
            if (elem) {
                isolatedHookObserver.removeElement(elem, onResize);
            }
        })
    }, [isolatedHookObserver, newElements, onResize])
}

export default useTrailingResizeDetector;