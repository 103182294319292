import React, {CSSProperties, ReactElement} from "react";
import "components/base/Loaders/Spinner/Spinner.scss";
import cx from "classnames";
import {isNumber} from "lodash"

type Props = {
    style?: CSSProperties,
    spinnerStyle?: CSSProperties,
    size?: string | number
    color?: string,
    thickness?: string | number
    className?: string;
};

export default function Spinner({
    style = {},
    spinnerStyle = {},
    size = "20px",
    color = "#33b150",
    thickness = "2px",
    className
}: Props): ReactElement {
    let spinnerSize = size;
    if (isNumber(spinnerSize)) {
        spinnerSize = spinnerSize + "px";
    }

    return (
        <div style={style} className={cx("Spinner", "spinner-wrapper", className)}>
            <div
                style={{
                    width: spinnerSize,
                    height: spinnerSize,
                    left: `calc(50% - ${spinnerSize} / 2)`,
                    borderTop: `${thickness} solid ${color}`,
                    ...spinnerStyle
                }}
                className="spinner"
            />
        </div>
    );
}