import React, {useReducer} from "react";
import ConfirmationModal from "components/common/ConfirmationModal";
import getDisplayName from "./getDisplayName";
import {ConfirmationModalConfigProps} from "../common/ConfirmationModal/ConfirmationModal";

type ActionStateProps = ConfirmationModalProps & {
    modalIsOpen: boolean,
};

export type ConfirmationModalProps = {
    onConfirm: () => void;
    onDecline: () => void;
    config: ConfirmationModalConfigProps;
};

type ActionProps =
    { type: "setModal", onConfirm: () => void, onDecline: () => void, config: ConfirmationModalConfigProps }
    | { type: "confirm" }
    | { type: "decline" }
    ;

type UseConfirmationProps = {
    modal: ActionStateProps,
    setModal: (props: ConfirmationModalProps) => void
};

export function useConfirmation(): UseConfirmationProps {
    const initialState = {
        modalIsOpen: false,
        onConfirm: function () {
        },
        onDecline: function () {
        },
        config: {}
    } as ActionStateProps;

    const reducer = (state: ActionStateProps, action: ActionProps): ActionStateProps => {
        switch (action.type) {
        case "setModal":
            return {
                modalIsOpen: true,
                onConfirm: action.onConfirm,
                onDecline: action.onDecline,
                config: action.config
            };
        case "confirm":
            return initialState;
        case "decline":
            return initialState;
        default:
            return state;
        }
    };

    const [state, dispatch] = useReducer(reducer, initialState);

    const setModal = ({
        onConfirm,
        onDecline,
        config
    }: ConfirmationModalProps) => {
        dispatch({
            type: "setModal",
            onConfirm: () => {
                dispatch({type: "confirm"});
                if (onConfirm) {
                    onConfirm();
                }
            },
            onDecline: () => {
                dispatch({type: "decline"});
                if (onDecline) {
                    onDecline();
                }
            },
            config
        });
    };

    return {modal: state, setModal} as { modal: ActionStateProps, setModal: (props: ConfirmationModalProps) => void };
}

function withConfirmation<P>(WrappedComponent: React.ComponentType<P>, config = {}): React.FC< Omit<P, keyof WithConfirmation>> {
    const HOComponent = ({...rest}) => {
        const {modal, setModal} = useConfirmation();

        return (
            <>
                {modal.modalIsOpen && (
                    <ConfirmationModal
                        onConfirm={modal.onConfirm}
                        onDecline={modal.onDecline}
                        config={modal.config}
                    />
                )}
                <WrappedComponent {...rest as P} setModal={setModal} />
            </>
        );
    };

    HOComponent.displayName = `withConfirmation(${getDisplayName(HOComponent)})`;

    return HOComponent;
}

export type WithConfirmation = {
    setModal: (props: ConfirmationModalProps) => void;
};
export default withConfirmation;
