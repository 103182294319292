import React, {ReactElement} from "react";
import {matchPath} from "react-router-dom";
import {connect} from "react-redux";
import {useTranslation} from "react-i18next";
import CartProgress from "../CartProgress/CartProgress";
import {BreadcrumbSharedProps} from "../Breadcrumb/Breadcrumb.base";
import {RootState} from "../../../../../redux/store/store.init";

export type TransfersBreadcrumbsProps = {
    destination?: string;
} & BreadcrumbSharedProps;

const TransfersBreadcrumbs = ({
    location,
    lastLocation,
    destination
}: TransfersBreadcrumbsProps): ReactElement => {
    const {t} = useTranslation();

    let step: 1 | 2 | 3 | 4;
    if (matchPath("/transfers/booking", location.pathname)) {
        step = 2;
    } else if (
        matchPath("/transfers/booking/payment", location.pathname) ||
        matchPath("/transfers/booking/changed", location.pathname)
    ) {
        step = 3;
    } else {
        step = 4;
    }

    if (!matchPath("/transfers/*", location.pathname)) {
        return (
            <></>
        );
    }

    return (
        <CartProgress
            currentStep={step}
            stepOneLabel={t("b_c_transfers_step_one", {destination})}
            stepOneLink={lastLocation.pathname + lastLocation.search}
            stepTwoLabel={t("b_c_transfers_step_two")}
            stepTwoLink="/transfers/booking"
            stepThreeLabel={t("b_c_transfers_step_three")}
            stepThreeLink="/transfers/payment"
            stepFourLabel={t("b_c_transfers_step_four")}
        />
    );
};

const mapStateToProps = (root: RootState) => ({
    destination: root.transferSearch.currentDestination?.name
});

export default connect(mapStateToProps, undefined)(TransfersBreadcrumbs);