import React, {ReactElement, useCallback} from "react";
import ImageInputWithCrop from "components/common/ImageInputWithCrop";
import {useUploadLogoAction} from "components/utils/useCompanyData";
import {useTranslation} from "react-i18next";
import cx from "classnames";
import styles from "./MyCompanyInfo.module.scss";
import EditCompanyDetails from "../EditCompanyDetails";
import EditBillingDetails from "../EditBillingDetails";
import {CompanyDetails, Credentials} from "../../../proptypes/PropTypeObjects";
import {useAppSelector} from "../../../redux/hooks"

type Props = {
    companyDetails: CompanyDetails;
    credentials: Credentials;
    updateLogo: (photo?: string) => void;
    updateCompanyDetails: (companyDetails: CompanyDetails) => void;
    updateBillingDetails: (credentials: Credentials) => void;
    hasSignedMainAgreement: boolean;
};

function MyCompanyInfo({
    companyDetails,
    credentials,
    updateLogo,
    updateCompanyDetails,
    updateBillingDetails,
    hasSignedMainAgreement
}: Props): ReactElement {
    const {
        companyName,
        countryId,
        email,
        phone,
        photo,
        id
    } = companyDetails;

    const subAgent = useAppSelector((state) => state.auth.userData?.subAgent);

    const onSuccess = useCallback(
        (file?: string) => {
            updateLogo(file);
        },
        [updateLogo]
    );

    const {
        uploadLogo: uploadLogoAction,
        deleteLogo: deleteLogoAction,
        updating: updatingLogo
    } = useUploadLogoAction(onSuccess);

    const {t} = useTranslation();

    return (
        <div className={styles.Root}>
            <h1 className={styles.Title}>{companyName}</h1>

            <div className={styles.MyCompanyInformationContainer}>
                <div className={styles.MyCompanyInformationTextItems}>
                    <div className={styles.MyCompanyInformationColumn}>
                        <h2 className={styles.Heading}>{t("mc_mci_company_details")}</h2>

                        <div className={styles.MyCompanyInformationTextBlock}>
                            <dl className={styles.MyCompanyInformationTextBlockItem}>
                                <dt>{t("mc_mci_project_name_id")}:</dt>
                                <dd>{id}</dd>
                            </dl>

                            <dl className={styles.MyCompanyInformationTextBlockItem}>
                                <dt>{t("mc_mci_company_name")}:</dt>
                                <dd>{companyName}</dd>

                                <dt>{t("mc_mci_e-mail_address")}:</dt>
                                <dd>{email}</dd>

                                <dt>{t("mc_mci_phone")}:</dt>
                                <dd>{phone}</dd>
                            </dl>
                        </div>

                        <EditCompanyDetails
                            className={styles.EditCompanyDetails}
                            companyDetails={companyDetails}
                            updateCompanyDetails={updateCompanyDetails}
                            hasSignedMainAgreement={hasSignedMainAgreement}
                        />
                    </div>

                    {!subAgent && (
                        <div className={styles.MyCompanyInformationColumn}>
                            <h2 className={styles.Heading}>{t("mc_mci_billing_details")}</h2>

                            {!!credentials.id && (
                                <div className={styles.MyCompanyInformationTextBlock}>
                                    <div className={styles.MyCompanyInformationTextBlockItem}>
                                        <dt>{t("mc_mci_companyName")}:</dt>
                                        <dd>{credentials.companyName}</dd>

                                        <dt>{t("mc_mci_address")}:</dt>
                                        <dd>
                                            {credentials.street && credentials.street + ", "}{" "}
                                            {credentials.postalCode && credentials.postalCode + ", "}{" "}
                                            {credentials.city && credentials.city + ", "}{" "}
                                            {credentials.country && credentials.country}
                                        </dd>
                                    </div>

                                    <div className={styles.MyCompanyInformationTextBlockItem}>
                                        <dt>{t("mc_mci_company_registration_code_number")}:</dt>
                                        <dd>{credentials.companyCode}</dd>

                                        {!credentials.nonVatPayer && (
                                            <>
                                                <dt>{t("mc_mci_vat_payer_number")}:</dt>
                                                <dd>{credentials.vatCode}</dd>
                                            </>
                                        )}

                                        <dt>{t("mc_mci_legal_representative")}:</dt>
                                        <dd>{credentials.signee}</dd>

                                        <dt>{t("mc_mci_representative_position")}:</dt>
                                        <dd>{credentials.signeePosition}</dd>
                                    </div>
                                </div>
                            )}

                            {!credentials.id && (
                                <div>
                                    <p>{t("mc_mci_create_credentials_hint")}</p>
                                </div>
                            )}

                            <EditBillingDetails
                                className={styles.EditBillingDetails}
                                credentials={credentials}
                                hasSignedMainAgreement={hasSignedMainAgreement}
                                updateBillingDetails={updateBillingDetails}
                            />
                        </div>
                    )}
                </div>

                <div className={cx(styles.CompanyImageContainer, !photo && styles.CompanyImageContainerEmpty)}>
                    <div className={styles.CompanyImageWrapper}>
                        <ImageInputWithCrop
                            aspect={148 / 96}
                            className={styles.CompanyImageInput}
                            onConfirm={(file) => uploadLogoAction(file)}
                            onDelete={() => deleteLogoAction()}
                            currentImageSrc={photo}
                            spinner={updatingLogo}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default MyCompanyInfo;
