import React, {FunctionComponent, ReactElement} from "react";
import {Link} from "react-router-dom";
import {useTranslation} from "react-i18next";
import UserButton from "components/base/UserButton";
import {scroller} from "react-scroll";
import styles from "./AmendmentsSideBox.module.scss";
import {
    BookingTypePropTypes,
    ClientBookingStatusPropTypes,
    WebTransferAmendmentPropTypes
} from "../../../proptypes/PropTypeObjects";
import canAmendBooking from "../../../utils/canAmendBooking";
import {UserButtonVariant} from "../../base/UserButton/UserButton";

type Props = {
    id: number;
    bookingType: BookingTypePropTypes;
    serviceStartDate: number;
    status: ClientBookingStatusPropTypes;
    amendments?: WebTransferAmendmentPropTypes[];
};

function AmendmentsSideBox({
    id,
    bookingType,
    serviceStartDate,
    status,
    amendments = []
}: Props): ReactElement {
    const {t} = useTranslation();

    const activeAmendments = amendments?.filter((amendment) => amendment.status !== "REJECTED" && amendment.status !== "CANCELLED");

    if (!canAmendBooking(status, serviceStartDate)) {
        return <></>;
    }

    if (activeAmendments && activeAmendments.length >= 1) {
        return (
            <div className={styles.Root}>
                <h3 className={styles.Title}>{t("bs_asb_amendments_title")}</h3>

                <div className={styles.Box}>
                    <div className={styles.Content}>
                        {activeAmendments.length === 1 && (<p>{t("bs_asb_amend_booking_active_single")}</p>)}
                        {activeAmendments.length > 1 && (<p>{t("bs_asb_amend_booking_active", {activeCount: amendments.length})}</p>)}
                    </div>

                    <div className={styles.ButtonsWrapper}>
                        <UserButton
                            text={t("bs_asb_view_amendments")}
                            variant={UserButtonVariant.PRIMARY}
                            buttonProps={{
                                onClick: () => scroller.scrollTo("amendmentsTable", {smooth: true, offset: -70, delay: 25})
                            }}
                        />
                    </div>
                </div>
            </div>
        );
    }

    return (
        <div className={styles.Root}>
            <h3 className={styles.Title}>{t("bs_asb_amendments_title")}</h3>

            <div className={styles.Box}>
                <div className={styles.Content}>
                    <p className={styles.Heading}>{t("bs_asb_amendments_heading")}</p>
                    <p>{t("bs_asb_amendments_notice")}</p>
                </div>

                <div className={styles.ButtonsWrapper}>
                    {/*<UserButton*/}
                    {/*    text={t("bs_asb_guide")}*/}
                    {/*    variant={UserButtonVariant.SECONDARY}*/}
                    {/*    customTag={CustomTagType.A}*/}
                    {/*    buttonProps={{*/}
                    {/*        href: "#",*/}
                    {/*        download: "amendments-guide"*/}
                    {/*    }}*/}
                    {/*/>*/}

                    <UserButton
                        text={t("bs_asb_amend_booking")}
                        variant={UserButtonVariant.PRIMARY}
                        customTag={Link}
                        buttonProps={{
                            to: `/my-bookings/booking/${id}/amendments/${
                                bookingType === "TRANSFER"
                                    ? "transfer"
                                    : "hotel"
                            }`
                        }}
                    />
                </div>
            </div>
        </div>
    );
}

export default AmendmentsSideBox;
