import {ReduxActionRedirectPropTypes} from "proptypes/PropTypeRedux";
import ActionRedirectActionTypes from "./actions/actionRedirect.reducer.actions";
import types from "redux/constants/actionRedirect.constants";

const INITIAL_STATE = {
    mounted: false,
    actionRedirect: undefined,
    actionRedirectPayload: undefined
} as ReduxActionRedirectPropTypes;

const actionRedirect = (state = INITIAL_STATE, action: ActionRedirectActionTypes): ReduxActionRedirectPropTypes => {
    switch (action.type) {
    case types.SET_REDIRECT:
        return {
            ...state,
            actionRedirect: action.redirectType,
            actionRedirectPayload: action.payload
        };
    case types.REDIRECT_MOUNTED:
        return {
            ...state,
            mounted: true
        };
    case types.REDIRECT_COMPLETE:
        return {
            ...state,
            mounted: false,
            actionRedirect: undefined,
            actionRedirectPayload: undefined
        };
    default:
        return state;
    }
};

export default actionRedirect;