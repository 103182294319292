export default function getPolygonBounds(polygon: google.maps.Polygon): google.maps.LatLngBounds {
    const {google} = window;
    const bounds = new google.maps.LatLngBounds();
    const paths = polygon.getPaths();
    let path;

    for (let i = 0; i < paths.getLength(); i++) {
        path = paths.getAt(i);
        for (let j = 0; j < path.getLength(); j++) {
            bounds.extend(path.getAt(j));
        }
    }

    return bounds;
}