import React, {
    CSSProperties,
    ReactElement,
    ReactNode, RefObject, useCallback,
    useRef
} from "react";
import cx from "classnames";
import {ReactComponent as WarningIcon} from "assets/icons/exclamation mark.svg";
import {ReactComponent as ErrorIcon} from "assets/icons/cross.svg";
import {ReactComponent as RefillIcon} from "assets/icons/refill.svg";
import {ReactComponent as InfoIcon} from "assets/icons/info.svg";
import {ReactComponent as SuccessIcon} from "assets/icons/check.svg";
import {scroller} from "react-scroll";
import _ from "lodash";
import styles from "./InfoBox.module.scss";

type Props = {
    children?: ReactNode
    className?: string,
    onClick?: () => boolean,
    type: string,
    customIcon?: ReactNode,
    title: ReactNode,
    message?: ReactNode,
    button?: ReactNode,
    active?: boolean,
    style?: CSSProperties,
    miniIcon?: boolean,
    id?: string;
    rootRef?: RefObject<HTMLDivElement>;
};

function getIcon(type: string, customIcon: ReactNode) {
    switch (type) {
    case "warn":
        return <WarningIcon />;
    case "error":
        return <ErrorIcon />;
    case "info":
        return <InfoIcon />;
    case "refill":
        return <RefillIcon />;
    case "custom":
        return customIcon && customIcon;
    case "success":
        return <SuccessIcon />;
    default:
        return false;
    }
}

function InfoBox({
    id,
    rootRef,
    children,
    className = "",
    onClick,
    type = "",
    customIcon,
    title = "",
    message,
    button = null,
    active = false,
    style = {},
    miniIcon
}: Props): ReactElement {
    const containerRef = useRef<HTMLDivElement | null>(null);//

    const containerId = id || _.uniqueId("InfoBox_");
    const iconClass = cx(miniIcon ? styles.MiniIcon : styles.Icon, {
        [styles.Info]: type === "info",
        [styles.Warn]: type === "warn",
        [styles.Error]: type === "error",
        [styles.Refill]: type === "refill"
    });

    const onClickCallback = useCallback(() => {
        if (onClick && !active) {
            onClick();

            if (containerRef.current) {
                scroller.scrollTo(containerRef.current.id, {smooth: true, offset: -70, delay: 25});
            }
        }
    }, [active, onClick]);

    const wrapperOnClickCallback = useCallback(() => {
        if (onClick && active) {
            onClick();
        }
    }, [active, onClick]);

    const icon = getIcon(type, customIcon);

    return (
        <>
            <div ref={rootRef} />

            <div
                ref={containerRef}
                id={containerId}
                style={style}
                className={cx(className, styles.Root, active && styles.Active, onClick && styles.WithAction)}
                onClick={onClickCallback}
            >
                <div
                    className={styles.Wrapper}
                    onClick={wrapperOnClickCallback}
                >
                    {icon && (
                        <div className={iconClass}>{getIcon(type, customIcon)}</div>
                    )}

                    <div className={styles.ContentContainer}>
                        <div className={styles.Content}>
                            {title && message && <h4 className={styles.Title}>{title}</h4>}
                            {title && !message && <p className={styles.Title}>{title}</p>}
                            {message && <div className={styles.Message}>{message}</div>}
                        </div>

                        {button && <div className={styles.Button}>{button}</div>}
                    </div>
                </div>

                {active && children && (
                    <div className={styles.Children}>{children}</div>
                )}
            </div>
        </>
    );
}

export default InfoBox;
