import React, {ReactElement, useCallback, useState} from "react";
import {useTranslation} from "react-i18next";
import cx from "classnames";
import Tippy from "../../Tippy/Tippy";
import {ReactComponent as InfoIcon} from "assets/icons/info.svg";
import {ReactComponent as CrossIcon} from "assets/icons/cross.svg";
import {ReactComponent as CheckIcon} from "assets/icons/check.svg";
import {getPriceWithCurrencySymbol} from "@hotelston_web_frontend_utils/currency/getPriceWithCurrency";
import styles from "./EditableRetailPrice.module.scss";
import Input from "../../../base/Input";
import {updateBookingRetailPrice} from "api/bookingDetailsAPI";
import {useAppDispatch} from "redux/hooks";
import {updateRetailPrice} from "redux/actions/bookingDetails.actions";
import {globalAxiosController} from "api/axios/globalAxiosController";
import Currency from "@hotelston_web_frontend_parent/constants/currency";

export type EditableRetailPriceProps = {
    bookingId: number;
    retailPrice: number;
    currency: Currency;
    className?: string;
};

const EditableRetailPrice = (props: EditableRetailPriceProps): ReactElement => {
    const {
        bookingId,
        retailPrice,
        currency,
        className
    } = props;

    const {t} = useTranslation();
    const dispatch = useAppDispatch();

    const [editing, setEditing] = useState<boolean>();
    const [localValue, setLocalValue] = useState<number>(retailPrice);

    const onEditCallback = useCallback(() => {
        setEditing(!editing);

        if (editing) {
            setLocalValue(retailPrice);
        }
    }, [editing, retailPrice]);

    const onSaveCallback = useCallback(() => {
        if (localValue && !Number.isNaN(localValue)) {
            globalAxiosController.addRequest(updateBookingRetailPrice(bookingId, localValue)).then((response) => {
                if (response) {
                    // success
                    dispatch(updateRetailPrice(localValue));
                }

                onEditCallback();
            }).catch(() => {
                onEditCallback();
            });
        }
    }, [bookingId, dispatch, localValue, onEditCallback]);

    return (
        <div className={cx(styles.Root, className)}>
            <Tippy content={(
                <span>
                    {t("bs_pi_retail_price_update_tooltip")}
                </span>
            )}
            >
                <span className={styles.RetailPriceLabel}>
                    {t("bs_pi_retail_price")}

                    <InfoIcon/>
                </span>
            </Tippy>

            {!editing && (
                <a onClick={onEditCallback}>{getPriceWithCurrencySymbol(retailPrice, currency)}</a>
            )}

            {editing && (
                <>
                    <Input
                        compact
                        variants={{
                            labelPosition: "outlined"
                        }}
                        className={styles.RetailPriceInput}
                        inputProps={{
                            type: "number",
                            min: 0,
                            step: 0.01,
                            value: localValue,
                            onChange: (e) => setLocalValue(+e.target.value)
                        }}
                    />

                    <span onClick={onEditCallback} className={styles.CancelButton}>
                        <CrossIcon/>
                    </span>

                    <span onClick={onSaveCallback} className={styles.SaveButton}>
                        <CheckIcon/>
                    </span>
                </>
            )}
        </div>
    );
};

export default EditableRetailPrice;