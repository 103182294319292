import {ItemModificationResponse, WalletDetailsProps, WalletTransactions} from "proptypes/PropTypeObjects";
import ConstrainedAxiosRequestConfig from "api/axios/constrainedAxiosRequestConfig"

export function getWalletDetails(locale: string): ConstrainedAxiosRequestConfig<WalletDetailsProps> {
    return {
        method: "get",
        url: "/app/wallet/",
        headers: {
            Accept: "application/json"
        },
        params: {
            locale
        },
        maxNumRequests: 1
    };
}

export function activateWallet(locale: string): ConstrainedAxiosRequestConfig<ItemModificationResponse> {
    return {
        method: "post",
        url: "/app/wallet/activate",
        data: null,
        headers: {
            "Content-Type": "application/json",
            Accept: "application/json"
        },
        params: {
            locale
        },
        maxNumRequests: 1
    };
}

export function refundWallet(locale: string): ConstrainedAxiosRequestConfig<ItemModificationResponse> {
    return {
        method: "post",
        url: "/app/wallet/refund",
        data: null,
        headers: {
            "Content-Type": "application/json",
            Accept: "application/json"
        },
        params: {
            locale
        },
        maxNumRequests: 1
    };
}

export function getWalletTransactions(locale: string, from?: string, to?: string): ConstrainedAxiosRequestConfig<WalletTransactions> {
    return {
        method: "get",
        url: "/app/wallet/transactions",
        headers: {
            Accept: "application/json"
        },
        params: {
            from,
            to,
            locale
        }
    };
}
