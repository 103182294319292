import React, {ReactElement} from "react";
import {useTranslation} from "react-i18next";
import cx from "classnames";
import {InvoiceRecipientPropTypes} from "../../../proptypes/PropTypeObjects";
import styles from "./BillingDetailsBox.module.scss";

type Props = {
    customInvoice?: boolean;
    invoiceRecipient: InvoiceRecipientPropTypes;
};

function BillingDetailsBox({customInvoice = false, invoiceRecipient}: Props): ReactElement {
    const {t} = useTranslation();

    return (
        customInvoice ? (
            <div className={cx(styles.Root)}>
                <div className={styles.Heading}>
                    {t("bdb_bdb_billing_details")}
                </div>

                <dl className={styles.Text}>
                    <dt>{t("bdb_bdb_company_name")}:</dt>
                    <dd>{invoiceRecipient.companyName}</dd>

                    <dt>{t("bdb_bdb_address")}:</dt>
                    <dd>{invoiceRecipient.address}</dd>

                    <dt>{t("bdb_bdb_company_code")}:</dt>
                    <dd>{invoiceRecipient.companyCode}</dd>

                    <dt>{t("bdb_bdb_vat_code")}:</dt>
                    <dd>{invoiceRecipient.vatCode}</dd>
                </dl>
            </div>
        ) : <></>
    );
}
export default BillingDetailsBox;
