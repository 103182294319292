import {AxiosRequestConfig, AxiosResponse} from "axios";
import axiosInstance from "./axiosInstance";
import CancelablePromise, {cancelable} from "cancelable-promise";
import handleRes, {handleError} from "../../utils/handleResponse";
import ConstrainedAxiosRequestConfig from "api/axios/constrainedAxiosRequestConfig"

class AxiosController {
    private requestAbortControllers: Map<string, AbortController[]> = new Map<string, AbortController[]>();
    private requestsInProgress: Map<string, CancelablePromise<AxiosResponse<unknown>>[]> = new Map<string, CancelablePromise<AxiosResponse<unknown>>[]>();

    public addRequest<T, D>(config: ConstrainedAxiosRequestConfig<T, D>): Promise<T> {
        const uniqueHash = this.generateAxiosRequestHash(config);
        const abortController = new AbortController();
        const request = cancelable(axiosInstance.request<T>({
            ...config,
            signal: abortController.signal
        }));

        const currRequests = this.requestsInProgress.get(uniqueHash);
        if (currRequests && config.maxNumRequests && currRequests.length >= config.maxNumRequests) {
            this.cancelRequest(uniqueHash);
        }

        this.onRequestStart(uniqueHash, abortController, request);

        return request
            .then(handleRes)
            .catch(handleError)
            .finally(() => this.onRequestComplete(uniqueHash));
    }

    private onRequestStart<T>(hash: string, controller: AbortController, originalPromise: CancelablePromise<AxiosResponse<T>>): void {
        if (this.requestAbortControllers.has(hash)) {
            this.requestAbortControllers.get(hash)?.push(controller);
        } else {
            this.requestAbortControllers.set(hash, [controller]);
        }

        if (this.requestsInProgress.has(hash)) {
            this.requestsInProgress.get(hash)?.push(originalPromise);
        } else {
            this.requestsInProgress.set(hash, [originalPromise]);
        }
    }

    private onRequestComplete(hash: string): void {
        const currentRequests = this.requestsInProgress.get(hash);
        const currentControllers = this.requestAbortControllers.get(hash);

        if (currentRequests) {
            void currentRequests.shift();
        }

        if (currentControllers) {
            void currentControllers.shift();
        }
    }

    private cancelRequest(hash: string): void {
        const currRequests = this.requestsInProgress.get(hash);
        const currentRequests = this.requestAbortControllers.get(hash);

        if (currRequests) {
            currRequests.shift()?.cancel();
        }

        if (currentRequests) {
            currentRequests.shift()?.abort();
        }
    }

    private generateAxiosRequestHash(config: AxiosRequestConfig): string {
        let newHash = "";
        newHash += "url:" + config.url;

        if (config.params) {
            newHash += "|params:" + JSON.stringify(config.params);
        }

        if (config.data) {
            newHash += "|data:" + JSON.stringify(config.data);
        }

        return newHash;
    }
}

export default AxiosController;