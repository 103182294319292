import _ from "lodash";
import * as userAPI from "../../api/userAPI";
import getTransferSearchResults from "../../api/transferAPI";
import {replaceQueryParams, setQueryParams} from "utils/url/queryParams";
import {getTransferPoint} from "api/destinationsAPI";
import {
    DestinationDestinationPropTypes,
    RecentDestinationPropTypes,
    TransferDestinationPropTypes,
    TransferSearchCriteriaPropTypes,
    TransferStateFormData
} from "proptypes/PropTypeObjects";
import TransferSearchTypes from "../constants/transferSearch.constants";
import {CommonActionReturnType} from "../store/store.init";
import IAppActions from "../store/store.actions";
import searchResultsRecieved from "./transferSearch.base.actions";
import router from "views/router/router";
import createLuxonDate from "../../utils/date/createLuxonDate"
import {resetTime} from "utils/dateUtils"
import {globalAxiosController} from "api/axios/globalAxiosController";

export const initSearch = (): CommonActionReturnType => (dispatch) => {
    dispatch({type: TransferSearchTypes.INIT_SEARCH});
    //dispatch(searchResults.resetAllData())
};

export const submitSearch = (): IAppActions => ({type: TransferSearchTypes.SUBMIT_SEARCH});
export const searchSuccess = (): IAppActions => ({
    type: TransferSearchTypes.GET_DATA_SUCCESS
});

export function getRecentSearches(): CommonActionReturnType {
    return (dispatch, getState) => {
        const locale = getState().locale.currentLocale;

        void globalAxiosController.addRequest(userAPI.getRecentSearch(locale))
            .then((recentSearches) => {
                dispatch({
                    type: TransferSearchTypes.GET_RECENT_SEARCHES,
                    recentSearches
                });
            });
    };
}

export function deleteRecentSearch(searchId: number): CommonActionReturnType {
    return (dispatch) => {
        void globalAxiosController.addRequest(userAPI.deleteRecentSearch(searchId))
            .then(() => dispatch(getRecentSearches()));
    };
}

export function putRecentSearch(): CommonActionReturnType {
    return (dispatch, getState) => {
        const {formData} = getState().transferSearch;
        const executed = createLuxonDate().toISO();
        void globalAxiosController.addRequest(userAPI.putRecentSearch(formData, executed))
            .then(() => dispatch(getRecentSearches()));
    };
}

export function getSavedSearches(): CommonActionReturnType {
    return (dispatch, getState) => {
        const locale = getState().locale.currentLocale;

        void globalAxiosController.addRequest(userAPI.getSavedSearch(locale))
            .then((savedSearches) => {
                dispatch({
                    type: TransferSearchTypes.GET_SAVED_SEARCHES,
                    savedSearches
                });
            });
    };
}

export function deleteSavedSearch(searchId: number): CommonActionReturnType {
    return (dispatch) => {
        void globalAxiosController.addRequest(userAPI.deleteSavedSearch(searchId))
            .then(() => dispatch(getSavedSearches()));
    };
}

export function putSavedSearch(): CommonActionReturnType {
    return (dispatch, getState) => {
        const {formData} = getState().hotelSearch;
        const executed = createLuxonDate().toISO();
        void globalAxiosController.addRequest(userAPI.putSavedSearch(formData, executed))
            .then(() => dispatch(getSavedSearches()));
    };
}

export function startSearchFromData(formData: TransferSearchCriteriaPropTypes, destination: DestinationDestinationPropTypes | RecentDestinationPropTypes): CommonActionReturnType {
    return async (dispatch) => {
        const destinationInput = destination.cityName || destination.countryName
            ? `${destination.cityName ? destination.cityName : ""}${destination.countryName ? ", " + destination.countryName : ""}`
            : destination.hotelName ? destination.hotelName : "";

        await dispatch(handleFormData(formData));
        // change current destina ir origin
        await dispatch(handleInputChange("destinationInput", destinationInput));
        dispatch(startSearch(false));
    };
}

export function startSearch(saveSearch = true): CommonActionReturnType {
    const start = (stateFormData: TransferStateFormData): IAppActions => ({
        type: TransferSearchTypes.START_SEARCH,
        stateFormData
    });
    const failure = (error: any): IAppActions => ({
        type: TransferSearchTypes.GET_DATA_FAILURE,
        error
    });

    return async (dispatch, getState) => {
        const newTransferSearch = getState().auth.userData?.defaultFeatures?.findIndex((feature) => feature === "web2NewTransferSearch") !== -1;

        await dispatch(submitSearch());
        const {
            formData,
            airportInput,
            venueInput,
            bookRoundtrip,
            stateFormData,
            currentOrigin,
            currentDestination
        } = getState().transferSearch;
        const {size, pageNumber, sortBy} = getState().transferSearchResults;
        const locale = getState().locale.currentLocale;
        const currency = getState().currency.currentCurrency;

        dispatch(start(stateFormData));

        let newFormData = {
            ...formData,
            arrivalTime: stateFormData.arrivalTime,
            departureTime: stateFormData.departureTime,
            children: stateFormData.children,
            adults: stateFormData.adults,
            originCoordinates: currentOrigin?.coordinates,
            transferOriginId: currentOrigin?.transferDestinationId,
            destinationCoordinates: currentDestination?.coordinates,
            transferDestinationId: currentDestination?.transferDestinationId
        } as TransferSearchCriteriaPropTypes;

        await setQueryParams("/transfers/search",
            {
                in: newFormData.arrivalTime || undefined,
                out: newFormData.departureTime || undefined,

                sb: sortBy,
                pn: pageNumber,
                s: size,
                l: locale,
                cr: currency,

                di: newFormData.destinationId,
                tdi: newFormData.transferDestinationId,
                oi: newFormData.originId,
                toi: newFormData.transferOriginId,
                oco: newFormData.originCoordinates,
                dco: newFormData.destinationCoordinates,

                ai: airportInput,
                vi: venueInput,
                rb: newFormData.relatedBooking,

                at: newFormData.arrivalTransfer,
                dt: newFormData.departureTransfer || false,
                rt: bookRoundtrip,

                ad: newFormData.adults,
                ch: newFormData.children
            });

        if (newTransferSearch && formData.departureTransfer && !formData.arrivalTransfer) {
            newFormData = {
                ...newFormData,
                transferOriginId: currentDestination.transferDestinationId,
                originCoordinates: currentDestination.coordinates,
                transferDestinationId: currentOrigin.transferDestinationId,
                destinationCoordinates: currentOrigin.coordinates
            };
        }

        globalAxiosController.addRequest(getTransferSearchResults(newFormData, locale, currency))
            .then(
                (data) => {
                    if (!data.transferOffers) {
                        dispatch(failure("err_search_no_transfer_results"));
                        throw new Error("err_search_no_transfer_results");
                    }
                    //saveSearch&&dispatch(putRecentSearch())
                    dispatch(searchResultsRecieved(
                        data.transferOffers
                    ));
                    dispatch(searchSuccess());
                }
            )
            .catch(
                (error: any) => {
                    // console.log(error, "----- error");
                    const errorText = error.message ? error.message : String(error);
                    dispatch(failure(errorText));
                    if (error.response && error.response.status && error.response.status !== 200) {
                        router.navigate(`/transfers/error/http${error.response.status}`);
                    } else {
                        router.navigate(`/transfers/error/${errorText}`);
                    }
                }
            );
    };
}

export function searchUpdate(doRedirect = true, updateQueryParams = true): CommonActionReturnType {
    const update = (stateFormData: TransferStateFormData): IAppActions => ({
        type: TransferSearchTypes.UPDATE_SEARCH,
        stateFormData
    });

    const failure = (error: any): IAppActions => ({
        type: TransferSearchTypes.GET_DATA_FAILURE,
        error
    });
    return (dispatch, getState) => {
        const {
            formData, airportInput, venueInput, bookRoundtrip, stateFormData, currentOrigin, currentDestination
        } = getState().transferSearch;
        const {size, pageNumber, sortBy} = getState().transferSearchResults;
        const locale = getState().locale.currentLocale;
        const currency = getState().currency.currentCurrency;

        const newTransferSearch = (getState().auth.userData?.defaultFeatures?.findIndex((feature) => feature === "web2NewTransferSearch") || -1) !== -1;

        dispatch(submitSearch());
        dispatch(update(stateFormData));
        // dispatch(updateStateFormData(formData))

        let newFormData = {
            ...formData,
            arrivalTime: stateFormData.arrivalTime,
            departureTime: stateFormData.departureTime,
            children: stateFormData.children,
            adults: stateFormData.adults
        } as TransferSearchCriteriaPropTypes;

        if (updateQueryParams) {
            void replaceQueryParams(
                "/transfers/search",
                {
                    in: newFormData.arrivalTime,
                    out: newFormData.departureTime,

                    sb: sortBy,
                    pn: pageNumber,
                    s: size,
                    l: locale,
                    cr: currency,

                    di: newFormData.destinationId,
                    dco: newFormData.destinationCoordinates,
                    tdi: newFormData.transferDestinationId,
                    oi: newFormData.originId,
                    toi: newFormData.transferOriginId,
                    oco: newFormData.originCoordinates,

                    ai: airportInput,
                    v: venueInput,
                    rb: newFormData.relatedBooking,

                    at: newFormData.arrivalTransfer,
                    dt: _.isNull(newFormData.departureTransfer) ? false : newFormData.departureTransfer,
                    rt: bookRoundtrip,

                    ad: newFormData.adults,
                    ch: newFormData.children
                }
            );
        }

        if (newTransferSearch && formData.departureTransfer && !formData.arrivalTransfer) {
            newFormData = {
                ...newFormData,
                transferOriginId: currentDestination.transferDestinationId,
                originCoordinates: currentDestination.coordinates,
                transferDestinationId: currentOrigin.transferDestinationId,
                destinationCoordinates: currentOrigin.coordinates
            };
        }

        globalAxiosController.addRequest(getTransferSearchResults(newFormData, locale, currency))
            .then(
                (data) => {
                    if (!data.transferOffers) {
                        throw new Error("err_search_no_transfer_results");
                    }
                    dispatch(searchResultsRecieved(
                        data.transferOffers
                    ));
                    dispatch(searchSuccess());
                }
            )
            .catch(
                (error) => {
                    // console.log(error, "----- error");
                    const errorText = error.message ? error.message : String(error);
                    dispatch(failure(errorText));
                    if (error.response && error.response.status && error.response.status !== 200) {
                        router.navigate(`/transfers/error/http${error.response.status}`);
                    } else {
                        router.navigate(`/transfers/error/${errorText}`);
                    }
                }
            );
    };
}

export const handleFormData = ({
    arrivalTime,
    departureTime,
    relatedBooking,
    destinationId,
    originId,
    children,
    adults,
    ...rest
}: TransferSearchCriteriaPropTypes): CommonActionReturnType => (dispatch, getState) => {
    const {
        bookRoundtrip,
        formData: {
            arrivalTransfer,
            departureTransfer
        }
    } = getState().transferSearch;

    const dispatchFormData = (formData: TransferSearchCriteriaPropTypes): IAppActions => ({
        type: TransferSearchTypes.HANDLE_FORM_DATA,
        formData
    });
    // const dispatchStateFormData = (stateFormData) =>({type:types.HANDLE_STATE_FORM_DATA, stateFormData})

    let tweakedArrivalTime = createLuxonDate(arrivalTime);
    let tweakedDepartureTime = createLuxonDate(departureTime);
    if (arrivalTime && tweakedArrivalTime.isValid && createLuxonDate().valueOf() > tweakedArrivalTime.valueOf()) {
        tweakedArrivalTime = createLuxonDate().plus({day: 1});
    }

    if (bookRoundtrip && departureTime && tweakedDepartureTime.isValid && tweakedArrivalTime.valueOf() > tweakedDepartureTime.valueOf()) {
        tweakedDepartureTime = tweakedArrivalTime.plus({day: 1});
    }

    const formData = {
        ...rest,
        arrivalTime: arrivalTime && tweakedArrivalTime.isValid ? tweakedArrivalTime.toISO({includeOffset: false}) : resetTime(createLuxonDate().plus({day: 7})).set({hour: 12}).toISO({includeOffset: false}),
        departureTime: departureTime && tweakedDepartureTime.isValid ? tweakedDepartureTime.toISO({includeOffset: false}) : resetTime(createLuxonDate().plus({day: 8})).set({hour: 12}).toISO({includeOffset: false}),
        relatedBooking: _.isString(relatedBooking) ? relatedBooking : undefined,
        destinationId: !_.isNaN(Number(destinationId)) ? destinationId : undefined,
        originId: !_.isNaN(Number(originId)) ? originId : undefined,
        children: _.isArray(children) ? children : [],
        adults: !_.isNaN(Number(adults)) ? adults : 1
    };
    dispatch(dispatchFormData(formData));
    // dispatch(dispatchFormData(formData))
};

export const toggleRoundTrip = (bookRoundtrip: boolean, setArrivalTransfer = true): CommonActionReturnType => (dispatch, getState) => {
    dispatch(({
        type: TransferSearchTypes.TOGGLE_ROUND_TRIP,
        bookRoundtrip
    }));

    if (bookRoundtrip) {
        dispatch(
            handleArrivalDepartureTransfer({
                arrivalTransfer: true,
                departureTransfer: true
            })
        );

        const {
            formData: {
                arrivalTime,
                departureTime
            },

            stateFormData,
            stateFormData: {
                arrivalTime: stateArrivalTime,
                departureTime: stateDepartureTime
            }
        } = getState().transferSearch;

        let arrTime = arrivalTime;
        let depTime = departureTime;
        let doDispatch = false;
        if (!arrTime) {
            arrTime = createLuxonDate().plus({day: 2}).toISO({includeOffset: false}) || "";
            doDispatch = true;
        }

        if (!depTime || createLuxonDate(depTime).equals(createLuxonDate(arrTime))) {
            depTime = createLuxonDate(arrTime).plus({day: 1}).toISO({includeOffset: false}) || "";
            doDispatch = true;
        }

        if (doDispatch) {
            dispatch(handleArrivalDepartureTime({
                arrivalTime: arrTime,
                departureTime: depTime
            }));
        }

        if (!stateArrivalTime || !stateDepartureTime) {
            dispatch({
                type: TransferSearchTypes.HANDLE_STATE_FORM_DATA,
                stateFormData: {
                    ...stateFormData,
                    arrivalTime: arrTime,
                    departureTime: depTime
                }
            })
        }
    } else if (setArrivalTransfer) {
        dispatch(
            handleArrivalDepartureTransfer({
                arrivalTransfer: true,
                departureTransfer: false
            })
        );
    }

    // if (!bookRoundtrip) {
    //     const {
    //         stateFormData: {
    //             arrivalTime,
    //             departureTime
    //         },
    //         formData: {
    //             arrivalTransfer,
    //             departureTransfer
    //         }
    //     } = getState().transferSearch;

    // if (arrivalTransfer && !departureTransfer) {
    //     dispatch(handleArrivalDepartureTime({
    //         arrivalTime: arrivalTime || dateWithSetTime(createLuxonDate().plus({day: 3}), 12, 0).toISO({includeOffset: false}) || "",
    //         departureTime: ""
    //     }));
    // }
    //
    // if (departureTransfer && !arrivalTransfer) {
    //     dispatch(handleArrivalDepartureTime({
    //         arrivalTime: "",
    //         departureTime: departureTime || dateWithSetTime(createLuxonDate().plus({day: 3}), 12, 0).toISO({includeOffset: false}) || ""
    //     }));
    // }
    // }
};

export const handleChildrenChange = (children: number[]): CommonActionReturnType => (dispatch, getState) => {
    const {stateFormData} = getState().transferSearch;
    dispatch(updateStateFormData(({
        ...stateFormData,
        children
    })));
};

export const handleAdultsChange = (adults: number): CommonActionReturnType => (dispatch, getState) => {
    const {stateFormData} = getState().transferSearch;
    dispatch(updateStateFormData(({
        ...stateFormData,
        adults
    })));
};

export const handleDestination = (destination?: TransferDestinationPropTypes): CommonActionReturnType => (dispatch, getState) => {
    const {
        formData,
        currentDestination
    } = getState().transferSearch;

    if (destination) {
        const fallbackCoords = currentDestination?.transferDestinationId === destination?.transferDestinationId ? currentDestination?.coordinates : undefined;

        dispatch(changeCurrentDestination("currentDestination", destination));
        dispatch(handleFormData({
            ...formData,
            destinationId: destination.id,
            destinationCoordinates: destination.coordinates || fallbackCoords,
            transferDestinationId: destination.transferDestinationId
        }));
    }
};

export const handleOrigin = (origin?: TransferDestinationPropTypes): CommonActionReturnType => (dispatch, getState) => {
    const {
        formData,
        currentOrigin
    } = getState().transferSearch;

    if (origin) {
        const fallbackCoords = currentOrigin?.transferDestinationId === origin?.transferDestinationId ? currentOrigin?.coordinates : undefined;

        dispatch(changeCurrentDestination("currentOrigin", origin));
        dispatch(handleFormData({
            ...formData,
            originId: origin.id,
            originCoordinates: origin.coordinates || fallbackCoords,
            transferOriginId: origin.transferDestinationId
        }));
    }
};

export const handleUpdateDestination = (): CommonActionReturnType => (dispatch, getState) => {
    const {
        destinationId,
        transferDestinationId
    } = getState().transferSearch.formData;
    const locale = getState().locale.currentLocale;

    if (!transferDestinationId && !destinationId) {
        return;
    }

    void globalAxiosController.addRequest(getTransferPoint(1, transferDestinationId || destinationId || 0, locale))
        .then((dest) => {
            dispatch(handleDestination(dest));
            dispatch(handleInputChange("venueInput", `${dest.name}, ${dest.countryName}`));
        });
};

export const handleUpdateOrigin = (): CommonActionReturnType => (dispatch, getState) => {
    const {
        originId,
        transferOriginId
    } = getState().transferSearch.formData;
    const locale = getState().locale.currentLocale;

    if (!transferOriginId && !originId) {
        return;
    }

    void globalAxiosController.addRequest(getTransferPoint(1, transferOriginId || originId || 0, locale))
        .then((origin) => {
            dispatch(handleOrigin(origin));
            dispatch(handleInputChange("airportInput", `${origin.iso ? origin.iso + ", " : ""}${origin.name}`));
        });
};

export const handleArrivalDepartureTransfer = ({
    arrivalTransfer,
    departureTransfer
}: {
    arrivalTransfer: boolean,
    departureTransfer: boolean
}): CommonActionReturnType => (dispatch, getState) => {
    const {formData} = getState().transferSearch;
    dispatch(handleFormData({
        ...formData,
        arrivalTransfer,
        departureTransfer,
        arrivalTime:
            arrivalTransfer
                ? formData.arrivalTime || formData.departureTime
                : resetTime(createLuxonDate().plus({day: 7})).set({hour: 12}).toISO({includeOffset: false}),
        departureTime:
            departureTransfer
                ? formData.departureTime || formData.arrivalTime
                : resetTime(createLuxonDate().plus({day: 8})).set({hour: 12}).toISO({includeOffset: false})
    }));
};

export const updateStateFormData = (stateFormData: TransferStateFormData): IAppActions => ({
    type: TransferSearchTypes.HANDLE_STATE_FORM_DATA,
    stateFormData
});

export const handleArrivalDepartureTime = ({
    arrivalTime,
    departureTime
}: {
    arrivalTime: string,
    departureTime: string
}): CommonActionReturnType => (dispatch, getState) => {
    const {
        stateFormData,
        bookRoundtrip
    } = getState().transferSearch;
    let time: string;
    if (bookRoundtrip && createLuxonDate(departureTime).valueOf() <= createLuxonDate(arrivalTime).valueOf()) {
        time = createLuxonDate(arrivalTime).plus({day: 1}).toISO({includeOffset: false}) || "";
    } else {
        time = departureTime;
    }

    dispatch(updateStateFormData({
        ...stateFormData,
        arrivalTime,
        departureTime: time
    }));
};

export function handleRelatedBooking(relatedBooking: string): CommonActionReturnType {
    return (dispatch, getState) => {
        const {formData} = getState().transferSearch;

        dispatch(handleFormData({
            ...formData,
            relatedBooking
        }));
    };
}

export const handleInputChange = (inputName: string, inputValue?: string): CommonActionReturnType => (dispatch) => dispatch({
    type: TransferSearchTypes.HANDLE_INPUT_CHANGE,
    inputName,
    inputValue
});

export const handleSaveSearch = (saveSearch: boolean): CommonActionReturnType => (dispatch) => dispatch({
    type: TransferSearchTypes.HANDLE_SAVE_SEARCH,
    saveSearch
});

export function changeCurrentDestination(key: string, value: TransferDestinationPropTypes): IAppActions {
    return ({
        type: TransferSearchTypes.CHANGE_CURRENT_DESTINATION,
        key,
        value
    });
}