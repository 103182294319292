import types from "../constants/bookingDetails.constants";
import {ReduxBookingDetailsPropTypes} from "proptypes/PropTypeRedux";
import {
    WebHotelBookingDetails,
    WebTransferBookingDetails
} from "proptypes/PropTypeObjects";
import BookingDetailsActionTypes from "./actions/bookingDetails.reducer.actions";

export const INITIAL_STATE = {
    data: undefined,
    bookingReferences: undefined,
    multiBookingData: undefined,
    multiBooking: false,
    requestingBookingDetails: true,
    loadedData: false,
    error: undefined,
    settingMemo: false,
    bookingDetailsParams: undefined,
    bookingId: undefined,
    paymentOrderId: undefined,
    requestingCancelBooking: false
} as ReduxBookingDetailsPropTypes;

export default (state = INITIAL_STATE, action: BookingDetailsActionTypes): ReduxBookingDetailsPropTypes => {
    switch (action.type) {
    case types.RESET_DETAILS:
        return INITIAL_STATE;
    case types.GET_DETAILS:
        return {
            ...state,
            bookingDetailsParams: action.bookingDetailsParams,
            paymentOrderId: action.paymentOrderId,
            bookingId: action.bookingId,
            error: undefined,
            requestingBookingDetails: true,
            multiBooking: false
        };
    case types.GET_DETAILS_SUCCESS:
        return {
            ...state,
            requestingBookingDetails: false,
            data: action.data,
            bookingReferences: action.bookingReferences,
            multiBooking: action.multiBooking
        };
    case types.GET_DETAILS_FAILURE:
        return {
            ...state,
            requestingBookingDetails: false,
            error: action.error
        };
    case types.SET_BOOKING_DATA:
        return {
            ...state,
            bookingId: action.bookingId,
            data: action.data,
            bookingDetailsParams: action.bookingDetailsParams,
            requestingBookingDetails: false
        };
    case types.UPDATE_AMENDMENT_DATA:
        return {
            ...state,
            data: {
                ...state.data,
                amendments: (state.data as WebTransferBookingDetails)?.amendments?.map((amendment) => (amendment.id === action.amendmentId
                    ? {
                        ...amendment,
                        status: action.amendmentStatus,
                        statusText: action.amendmentStatusText

                    }
                    : amendment))
            } as WebTransferBookingDetails | WebHotelBookingDetails
        };
    case types.REQ_SET_MEMO:
        return {
            ...state,
            settingMemo: true
        };
    case types.SET_MEMO_SUCCESS:
        return {
            ...state,
            settingMemo: false,
            data: {
                ...state.data,
                memo: action.memo
            } as WebTransferBookingDetails | WebHotelBookingDetails
        };
    case types.SET_MEMO_FAILURE:
        return {
            ...state,
            settingMemo: false
        };
    case types.SET_SPECIAL_REQUESTS:
        return {
            ...state,
            data: {
                ...state.data,
                specialRequests: action.specialRequests
            } as WebTransferBookingDetails | WebHotelBookingDetails
        };
    case types.SET_FLIGHT_DETAILS_SUCCESS:
        return {
            ...state,
            data: {
                ...state.data,
                hotelFlightDetails: action.flightDetails
            } as WebTransferBookingDetails | WebHotelBookingDetails
        };
    case types.SET_SHOW_CREDENTIALS_TO_FALSE:
        return {
            ...state,
            data: {
                ...state.data
                //showBusinessCredentials: false
            } as WebTransferBookingDetails | WebHotelBookingDetails
        };
    case types.UPDATE_RETAIL_PRICE: {
        return {
            ...state,
            data: {
                ...state.data,
                retailPrice: action.newAmount
            } as WebTransferBookingDetails | WebHotelBookingDetails
        };
    }
    case types.CANCEL_BOOKING:
        return {
            ...state,
            requestingCancelBooking: true
        };
    case types.CANCEL_BOOKING_SUCCESS:
        return {
            ...state,
            data: {
                ...state.data,
                status: "CANCELLED",
                paymentPending: 0
            } as WebTransferBookingDetails | WebHotelBookingDetails,
            requestingCancelBooking: false
        };
    case types.CANCEL_BOOKING_FAILURE:
        return {
            ...state,
            error: action.error,
            requestingCancelBooking: false
        };

    default:
        return state;
    }
};
