import React, {ReactElement, useCallback, useState} from "react";
import debounce from "lodash/debounce";
import {useTranslation} from "react-i18next";
import {ReactComponent as Search} from "assets/icons/search.svg";
import Input from "components/base/Input";

type Props = {
    tooltipText?: string;
    inputProps?: React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>;
    handleSearch: (searchText: string) => void;
    minInputLength?: number;
}

function SearchInput({tooltipText, inputProps, handleSearch, minInputLength = 3}: Props): ReactElement {
    const {t} = useTranslation();
    const [searchInnerInput, setSearchInnerInput] = useState<string>("");

    const debouncedSearch = useCallback(
        debounce((searchText: string) => {
            handleSearch(searchText);
        }, 300),
        [handleSearch]
    );

    const handleSearchInput = useCallback((value: string) => {
        setSearchInnerInput(value);

        if (value.length >= minInputLength) {
            debouncedSearch(value);
        } else {
            debouncedSearch("");
        }
    }, [handleSearch]);


    return (
        <Input
            tooltipText={tooltipText}
            variants={{labelPosition: "outlined"}}
            style={{marginBottom: 0}}
            fullHeight={false}
            inputProps={{
                ...inputProps,
                type: "text",
                autoComplete: "off",
                placeholder: t("si_si_search"),
                value: searchInnerInput,
                onChange: (e: React.ChangeEvent<HTMLInputElement>) => handleSearchInput(e.target.value)
            }}
            trailingIcon={<Search />}
        />
    );
}

export default SearchInput;