import React, {Component} from "react";
import {connect, ConnectedProps} from "react-redux";
import SavedBox from "./SavedBox";
import SavedItem from "./SavedItem";
import PageControl from "../../../../components/common/PageControl";
import {deleteSavedSearch, getSavedSearches, startSearchFromData} from "../../../../redux/actions/hotelSearch.actions";
import {RootState} from "../../../../redux/store/store.init";

type SavedBoxContainerState = {
    pageNumber: number;
    pageSize: number;
    pages: number;
};

type Props = ConnectedProps<typeof connector>;

class SavedBoxContainer extends Component<Props, SavedBoxContainerState> {
    constructor(props: Props) {
        super(props);
        this.state = {
            pageNumber: 1,
            pageSize: 3,
            pages: 1
        };
    }

    componentDidMount() {
        const {getSavedSearches} = this.props;
        getSavedSearches();
        this.getPagesCount();
    }

    componentDidUpdate({savedSearches, locale}: Props) {
        const {getSavedSearches} = this.props;
        if (savedSearches.length !== this.props.savedSearches.length) {
            this.getPagesCount();
        }
        if (locale !== this.props.locale) {
            getSavedSearches();
        }
    }

    handleChangePage = (pageNumber: number) => {
        this.setState({pageNumber});
    };

    handlePageSizeChange = (pageSize: number) => {
        this.setState({pageSize}, this.getPagesCount);
    };

    getPagesCount = () => {
        const {savedSearches} = this.props;
        const {pageSize} = this.state;
        const arrLength = savedSearches.length;
        const pages = Math.ceil(arrLength / pageSize);
        this.setState({pages});
    };

    render() {
        const {
            savedSearches, nationalities, deleteSavedSearch, startSearchFromData
        } = this.props;

        const {pageNumber, pageSize, pages} = this.state;
        return savedSearches.length > 0 && (
            <SavedBox>

                {savedSearches
                    .slice((pageNumber - 1) * pageSize, pageNumber * pageSize)
                    .map((item, i) => (
                        <SavedItem
                            key={`saved-item-${i}`}
                            data={{
                                ...item,
                                nationalities
                            }}
                            startSearchFromData={startSearchFromData}
                            deleteSavedSearch={deleteSavedSearch}
                        />
                    ))}
                <div>
                    <PageControl
                        handleChangePage={this.handleChangePage}
                        pagesCount={pages}
                        pageNumber={pageNumber}
                        size={pageSize}
                        pageSizeOptions={[{
                            value: 3,
                            label: 3
                        },
                        {
                            value: 4,
                            label: 4
                        },
                        {
                            value: 5,
                            label: 5
                        }
                        ]}
                        handlePageSizeChange={this.handlePageSizeChange}
                        reverse
                    />
                </div>
            </SavedBox>
        );
    }
}

const mapStateToProps = ({hotelSearch, nationality, locale}: RootState) => ({
    savedSearches: hotelSearch.savedSearches,
    nationalities: nationality.nationalities.nationalities,
    locale: locale.currentLocale
});
const connector = connect(mapStateToProps, {
    getSavedSearches,
    deleteSavedSearch,
    startSearchFromData
});
export default connector(SavedBoxContainer);
