import React, {ReactElement, useCallback, useState} from "react";
import cx from "classnames";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import Tippy from "components/common/Tippy/Tippy";
import {useSelector} from "react-redux";
import {ReactComponent as SettingsIcon} from "assets/icons/settings.svg";
import styles from "./EditDeleteUser.module.scss";
import DeleteUser from "./DeleteUser";
import {CompanyUserPropTypes, CompanyUserWithPassword} from "../../../proptypes/PropTypeObjects";
import {ReactComponent as EditIcon} from "../../../assets/icons/edit.svg";
import {ReactComponent as ChangePasswordIcon} from "../../../assets/icons/changePassword.svg";
import UserDetailsModal from "../../../components/common/UserDetailsModal";
import {UserDetailsModalType} from "../../../components/common/UserDetailsModal/UserDetailsModal";
import {RootState} from "../../../redux/store/store.init";
import SuccessModal from "../../../components/common/ConfirmationModal/SuccessModal";
import {useAppDispatch} from "../../../redux/hooks";
import {updateCompanyUser, updateCurrentUser} from "../../../redux/actions/company.actions";
import {useUpdateUserAction} from "../../../components/utils/useCompanyUsers"

type Props = {
    className?: string;
    deleteUser: (id: number) => void;
    companyUser: CompanyUserPropTypes
    editTooltipText?: string;
    deleteTooltipText?: string;
    changePasswordText?: string;
    viewAdvancedSettingsText?: string;
};

function EditDeleteUser({
    className,
    deleteUser,
    companyUser,
    editTooltipText,
    deleteTooltipText,
    changePasswordText,
    viewAdvancedSettingsText
}: Props): ReactElement {
    const {t} = useTranslation();
    const dispatch = useAppDispatch();

    const [changePasswordVisible, setChangePasswordVisible] = useState<boolean>(false);
    const [updatingPassword, setUpdatingPassword] = useState<boolean>(false);

    const [editUserModalVisible, setEditUserModalVisible] = useState<boolean>(false);
    const [updatingUser, setUpdatingUser] = useState<boolean>(false);

    const [successModal, setSuccessModal] = useState<boolean>();

    const currUser = useSelector((state: RootState) => state.auth.userData?.companyUser);

    const navigate = useNavigate();

    const {
        errors,
        updateUser
    } = useUpdateUserAction();

    const onSuccessUser = useCallback((user: CompanyUserWithPassword) => {
        setUpdatingUser(true);

        updateUser(user).then(() => {
            dispatch(updateCompanyUser(user));

            if (user.id === currUser?.id) {
                dispatch(updateCurrentUser(user));
            }

            setSuccessModal(true);
        }).catch((reason) => {
            // console.log(reason);
        }).finally(() => {
            setUpdatingUser(false);

            setTimeout(() => setEditUserModalVisible(false));
        });
    }, [currUser?.id, dispatch, updateUser]);

    const onSuccessPassword = useCallback((user: CompanyUserWithPassword) => {
        setUpdatingPassword(true);

        updateUser(user).then((value) => {
            setSuccessModal(true);
        }).catch((reason) => {
            // console.log(reason);
        }).finally(() => {
            setChangePasswordVisible(false);
            setUpdatingPassword(false);
        });
    }, [updateUser]);

    const onSuccessClose = useCallback(() => setSuccessModal(false), []);
    const viewAdvancedSettingsCallback = useCallback(() => navigate(`/my-company/user/${companyUser.id}`), [companyUser?.id, navigate]);
    const onUserDetailsModalDecline = useCallback(() => setEditUserModalVisible(false), []);
    const onChangePasswordDecline = useCallback(() => setChangePasswordVisible(false), []);
    const onChangePasswordClick = useCallback((e: React.MouseEvent<SVGSVGElement, MouseEvent>) => {
        e.stopPropagation();
        e.preventDefault();

        setChangePasswordVisible(true);
    }, []);
    const onEditClick = useCallback((e: React.MouseEvent<SVGSVGElement, MouseEvent>) => {
        e.stopPropagation();
        e.preventDefault();

        setEditUserModalVisible(true);
    }, []);

    return (
        <div className={cx(styles.Root, className)}>
            {successModal && (
                <SuccessModal
                    onClose={onSuccessClose}
                    successMessage={t("mc_edu_eud_success_message_user_details_update")}
                />
            )}

            {editUserModalVisible && companyUser && (
                <UserDetailsModal
                    onConfirm={onSuccessUser}
                    companyUser={companyUser}
                    onDecline={onUserDetailsModalDecline}
                    title={updatingUser ? t("mc_edu_eud_general_settings_updating") : t("mc_edu_eud_general_settings")}
                    errors={errors}
                    spinner={updatingUser}
                    variant={UserDetailsModalType.DETAILS}
                    canMakeAdmin={currUser?.admin}
                />
            )}

            {changePasswordVisible && companyUser && (
                <UserDetailsModal
                    onConfirm={onSuccessPassword}
                    companyUser={companyUser}
                    onDecline={onChangePasswordDecline}
                    title={companyUser.id !== currUser?.id ? t("mc_edu_cuv_temporary_password") : t("mc_edu_cuv_change_password")}
                    errors={errors}
                    spinner={updatingPassword}
                    variant={UserDetailsModalType.PASSWORD}
                    canMakeAdmin={currUser?.admin}
                    temporaryPassword={companyUser.id !== currUser?.id}
                    temporaryPasswordNotice={companyUser.id !== currUser?.id}
                />
            )}
            {companyUser.id === currUser?.id ? undefined : (
                <Tippy
                    content={(
                        <div className={styles.Tooltip}>
                            {deleteTooltipText}
                        </div>
                    )}
                >
                    <div>
                        <DeleteUser
                            className={styles.DeleteUser}
                            deleteUser={deleteUser}
                            userId={companyUser.id}
                        />
                    </div>
                </Tippy>
            )}

            <Tippy
                content={(
                    <div className={styles.Tooltip}>
                        {changePasswordText}
                    </div>
                )}
            >
                <div>
                    <ChangePasswordIcon
                        className={styles.ChangePassword}
                        onClick={onChangePasswordClick}
                    />
                </div>
            </Tippy>

            <Tippy
                content={(
                    <div className={styles.Tooltip}>
                        {editTooltipText}
                    </div>
                )}
            >
                <div>
                    <EditIcon
                        className={cx(styles.EditUser, className)}
                        onClick={onEditClick}
                    />
                </div>
            </Tippy>

            <Tippy
                content={(
                    <div className={styles.Tooltip}>
                        {viewAdvancedSettingsText}
                    </div>
                )}
            >
                <div>
                    <SettingsIcon
                        className={styles.ViewAdvancedSettings}
                        onClick={viewAdvancedSettingsCallback}
                    />
                </div>
            </Tippy>
        </div>
    );
}

export default EditDeleteUser;
