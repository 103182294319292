import * as bookingDetailsAPI from "../../api/bookingDetailsAPI";
import {OverPaymentOptionsResponsePropTypes, OverpaymentRequest} from "../../proptypes/PropTypeObjects";
import OverpaymentTypes from "../constants/overpayment.constants";
import {CommonActionReturnType} from "../store/store.init";
import IAppActions from "../store/store.actions";
import router from "views/router/router";
import {globalAxiosController} from "api/axios/globalAxiosController";

export function getOverpaymentOptions(id: number): CommonActionReturnType {
    const start = (): IAppActions => ({type: OverpaymentTypes.REQ_OPTIONS});
    const success = (overpaymentOptions: OverPaymentOptionsResponsePropTypes): IAppActions => ({
        type: OverpaymentTypes.REQ_OPTIONS_SUCCESS,
        overpaymentOptions
    });
    const failure = (error: any): IAppActions => ({
        type: OverpaymentTypes.REQ_OPTIONS_FAILURE,
        error
    });

    return (dispatch) => {
        dispatch(start());
        globalAxiosController.addRequest(bookingDetailsAPI.getOverpaymentOptions(id))
            .then((data) => {
                dispatch(success(data));
            })
            .catch((error: any) => dispatch(failure(error)));
    };
}

export function handleUseOverpayment(id: number, formData: OverpaymentRequest): CommonActionReturnType {
    const start = (): IAppActions => ({type: OverpaymentTypes.REQ_USE});
    const success = (): IAppActions => ({type: OverpaymentTypes.REQ_USE_SUCCESS});
    const failure = (error: any): IAppActions => ({
        type: OverpaymentTypes.REQ_OPTIONS_FAILURE,
        error
    });

    return (dispatch) => {
        dispatch(start());
        globalAxiosController.addRequest(bookingDetailsAPI.useOverpayment(id, formData))
            .then(() => {
                dispatch(success());
                router.navigate(`/my-bookings/booking/${id}`);
            })
            .catch((error: any) => {
                dispatch(failure(error));
                router.navigate(`/my-bookings/booking/${id}`);
            });
    };
}