import {QueryParamSerializerDeserializerResolver, QueryParamsObject} from "utils/url/queryParamsTypes";
import {POSSIBLE_ARRAY_PARAM_RESOLVER, setUrlParamPrimitive} from "utils/url/queryParamsUtils";
import _ from "lodash";
import {HotelCoordinatesPropTypes} from "proptypes/PropTypeObjects";

const CoordinatesSerializer = (paramName: string): QueryParamSerializerDeserializerResolver => ({
    serializer: (query: QueryParamsObject, param: string, object: HotelCoordinatesPropTypes) => {
        setUrlParamPrimitive(query, param, _.toString(object.longitude) + "|" + _.toString(object.latitude));
    },

    resolver: POSSIBLE_ARRAY_PARAM_RESOLVER([paramName]),
    deserializer: (query: QueryParamsObject) => {
        const stringVal = query[paramName];
        const split = stringVal.split("|");

        return {
            longitude: +split[0],
            latitude: +split[1]
        } as HotelCoordinatesPropTypes;
    },
    array: false
}) as QueryParamSerializerDeserializerResolver;

export default CoordinatesSerializer;