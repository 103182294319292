import {
    DiscountCodeValidityResponse,
    PaymentTransferTypePropTypes,
    TransactionFeeRequest,
    TransactionFeeResponse,
    WebBankLinkOptionPropTypes,
    WebPaymentProvider
} from "proptypes/PropTypeObjects"
import {ConstrainedAxiosRequestConfig} from "./axios/constrainedAxiosRequestConfig";
import Currency from "@hotelston_web_frontend_parent/constants/currency";

export function getTransactionFee(
    payDue: number,
    currency: Currency,
    webBankLinkOption?: WebBankLinkOptionPropTypes,
    paymentProvider?: WebPaymentProvider,
    paymentTransferType?: PaymentTransferTypePropTypes
): ConstrainedAxiosRequestConfig<TransactionFeeResponse, TransactionFeeRequest> {
    return {
        method: "post",
        url: `/app/pricing/transaction-fee/?currency=${currency}`,
        headers: {
            "Content-Type": "application/json",
            Accept: "application/json"
        },
        data: {
            payDue,
            webBankLinkOption,
            paymentProvider,
            paymentTransferType
        },
        maxNumRequests: 1
    };
}

export function checkDiscountCodeValidity(
    discountCode: string,
    salePrice: number,
    currency: Currency
): ConstrainedAxiosRequestConfig<DiscountCodeValidityResponse, { discountCode: string, salePrice: number }> {
    return {
        method: "post",
        url: `/app/pricing/discount-code/?currency=${currency}`,
        headers: {
            "Content-Type": "application/json",
            Accept: "application/json"
        },
        data: {
            discountCode,
            salePrice
        }
    };
}