enum PinnedOffersTypes {
    ADD_PINNED_OFFER = "pinnedOffers/ADD_PINNED_OFFER",
    REMOVE_PINNED_OFFER = "pinnedOffers/REMOVE_PINNED_OFFER",
    RESET_PINNED_OFFERS = "pinnedOffers/RESET_PINNED_OFFERS",

    REQ_PINNED_HOTELS = "pinnedOffers/REQ_PINNED_HOTELS",
    REQ_PINNED_HOTELS_SUCCESS = "pinnedOffers/REQ_PINNED_HOTELS_SUCCESS",
    REQ_PINNED_HOTELS_FAILURE = "pinnedOffers/REQ_PINNED_HOTELS_FAILURE",

    SET_SLIDE = "pinnedOffers/SET_SLIDE"
}

export default PinnedOffersTypes;