export default function isPathClockwise(polygon: google.maps.Polygon, path: google.maps.MVCArray<google.maps.LatLng>): boolean {
    const {google} = window;
    const self = polygon;

    if (path === null) throw new Error("Path is optional, but cannot be null");
    const toCalculatePath = self.getPath();

    if (!(toCalculatePath instanceof Array) && !(toCalculatePath instanceof google.maps.MVCArray)) {
        throw new Error("Path must be an Array or MVCArray");
    }

    // negative polygon areas have counter-clockwise paths
    const isCounterClockwise = google.maps.geometry.spherical.computeSignedArea(toCalculatePath) < 0;
    return !isCounterClockwise;
}