import {CompanyDetailsResponse} from "../../proptypes/PropTypeObjects";
import CompanyActionTypes from "./actions/company.reducer.actions";
import CompanyTypes from "../constants/company.constants";
import {ReduxCompanyPropTypes} from "../../proptypes/PropTypeRedux";

const INITIAL_STATE = {
    requestingCompanyDetails: true,
    error: undefined,
    data: undefined as unknown as CompanyDetailsResponse
} as ReduxCompanyPropTypes;

export default (state = INITIAL_STATE, action: CompanyActionTypes): ReduxCompanyPropTypes => {
    switch (action.type) {
    case CompanyTypes.REQ_COMPANY_DETAILS:
        return {
            ...state,
            error: undefined,
            requestingCompanyDetails: true
        };
    case CompanyTypes.REQ_COMPANY_DETAILS_SUCCESS:
        return {
            ...state,
            data: action.data,
            requestingCompanyDetails: false
        };
    case CompanyTypes.REQ_COMPANY_DETAILS_FAILURE:
        return {
            ...state,
            error: action.error,
            requestingCompanyDetails: false
        };
    case CompanyTypes.UPDATE_COMPANY_DETAILS:
        return {
            ...state,
            data: {
                ...state.data,
                companyDetails: action.companyDetails
            }
        };
    case CompanyTypes.UPDATE_CURRENT_USER:
        return {
            ...state,
            data: {
                ...state.data,
                currentUser: action.currentUser
            }
        };
    case CompanyTypes.UPDATE_BILLING_DETAILS:
        return {
            ...state,
            data: {
                ...state.data,
                credentials: action.credentials
            }
        };
    case CompanyTypes.ADD_COMPANY_MARKUP:
        return {
            ...state,
            data: {
                ...state.data,
                companyMarkups: [
                    action.companyMarkup,
                    ...state.data.companyMarkups
                ]
            }
        };
    case CompanyTypes.UPDATE_COMPANY_MARKUP:
        return {
            ...state,
            data: {
                ...state.data,
                companyMarkups: state.data.companyMarkups.map((cm) => (cm.id === action.companyMarkup.id ? action.companyMarkup : cm))
            }
        };
    case CompanyTypes.REMOVE_COMPANY_MARKUP:
        return {
            ...state,
            data: {
                ...state.data,
                companyMarkups: state.data.companyMarkups.filter((cm) => cm.id !== action.id)
            }
        };
    case CompanyTypes.ADD_USER:
        return {
            ...state,
            data: {
                ...state.data,
                companyUsers: [action.companyUser, ...state.data.companyUsers]
            }
        };
    case CompanyTypes.REMOVE_USER:
        return {
            ...state,
            data: {
                ...state.data,
                companyUsers: state.data.companyUsers.filter((cu) => cu.id !== action.id)
            }
        };
    case CompanyTypes.UPDATE_USER:
        return {
            ...state,
            data: {
                ...state.data,
                companyUsers: state.data.companyUsers.map((cu) => (cu.id === action.companyUser.id ? action.companyUser : cu))
            }
        };
    case CompanyTypes.UPDATE_LOGO:
        return {
            ...state,
            data: {
                ...state.data,
                companyDetails: {
                    ...state.data.companyDetails,
                    photo: action.photo
                }
            }
        };
    default:
        return state;
    }
};