import React, {ReactElement} from "react";
import {ReactComponent as EditIcon} from "assets/icons/edit.svg";
import {useTranslation} from "react-i18next";
import cx from "classnames";
import {Link} from "react-router-dom";
import {CompanyUserPropTypes} from "../../../../proptypes/PropTypeObjects";
import styles from "./UserLefter.module.scss";

type Props = {
    companyUser: CompanyUserPropTypes;
};

function EditPersonalDetails({
    companyUser
}: Props): ReactElement {
    const {t} = useTranslation();
    return (
        <>

            <div className={cx(styles.IssueItem, styles.Decorated)}>
                <p className={cx(styles.IssueName)}>{t("ul_eud_person")}</p>
                <p>
                    <Link style={{textAlign: "right"}} className={cx(styles.IssueValue)} to="/my-company/user">
                        {companyUser.firstname + " " + companyUser.lastname}
                        <EditIcon
                            width={13}
                            height={13}
                            style={{
                                display: "block",
                                marginLeft: 10
                            }}
                        />
                    </Link>
                </p>
            </div>
        </>
    );
}

export default EditPersonalDetails;
