export type ThumbnailSize = {
    width: number;
    height: number;
};

export const HOTELS_SLIDER_RESOLUTION: ThumbnailSize = {
    width: 1920,
    height: 1080
};

export const HOTEL_RESULTS_RESOLUTION: ThumbnailSize = {
    width: 560,
    height: 420
};

export const MAP_IMAGE_RESOLUTION: ThumbnailSize = {
    width: 165,
    height: 120
};

export const IMAGE_SLIDER_THUMBNAIL_RESOLUTION: ThumbnailSize = {
    width: 64,
    height: 64
};

export default function createResizedImageUrl(imgSrc: string, properties: ThumbnailSize): string {
    return imgSrc + "?w=" + properties.width + "&h=" + properties.height;
}