import React, {ReactElement} from "react";
import {ReactComponent as EurIcon} from "assets/icons/euro sign.svg";
import {Link} from "react-router-dom";
import {Trans, useTranslation} from "react-i18next";
import styles from "./Overpayment.module.scss";
import {getPriceWithCurrencySymbol} from "@hotelston_web_frontend_utils/currency/getPriceWithCurrency";
import Currency from "@hotelston_web_frontend_parent/constants/currency";

type Props = {
    overpayment: number;
    currency: Currency;
    id: number;
};

function Overpayment({overpayment, currency, id}: Props): ReactElement {
    const {t} = useTranslation();

    return (
        <div className={styles.Root}>
            <h3 className={styles.Title}>{t("bs_op_overpayment")}</h3>

            <div className={styles.Box}>
                <div className={styles.Icon}>
                    <EurIcon />
                </div>

                <div className={styles.Content}>
                    <p className={styles.Heading}>{t("bs_op_overpayment")} {getPriceWithCurrencySymbol(overpayment, currency)}</p>

                    <p>
                        <Trans i18nKey="bs_op_overpayment_notice">
                            You have overpayment for this booking.
                            <br />
                            To decide how to use it -{" "}
                            <Link
                                className="green-color"
                                to={`/my-bookings/booking/${id}/overpayment`}
                            >
                                <strong>click here</strong>
                            </Link>
                        </Trans>
                    </p>
                </div>
            </div>
        </div>
    );
}

export default Overpayment;
