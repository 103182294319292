import React, {
    ReactElement, useCallback, useMemo, useState
} from "react";
import {useTranslation} from "react-i18next";
import CookieConsent from "react-cookie-consent";
import styles from "./CookieConsentContainer.module.scss";
import {
    DEFAULT_COOKIE_NAME,
    DEFAULT_COOKIE_VALUE,
    getCookieValue,
    HotelstonConsentCookie, setCookieValue
} from "../../../utils/cookie/cookie"
import CustomCheckBox from "../../base/Input/CustomCheckBox";

const CookieConsentContainer = (): ReactElement => {
    const {t} = useTranslation();
    const currentCookie = getCookieValue();

    const [showConsentContainer, setShowConsentContainer] = useState<boolean>(!currentCookie.required || currentCookie.declined);
    const [customizeCookies, setCustomizeCookies] = useState<boolean>(false);
    const [cookieConsentData, setCookieConsentData] = useState<HotelstonConsentCookie>({
        ...currentCookie,
        required: true,
        declined: false
    });
    // const serializedCookie = JSON.stringify(cookieConsentData);

    const onCustomizeCookiesClick = useCallback(() => setCustomizeCookies(!customizeCookies), [customizeCookies]);

    const onAcceptCookies = useCallback(() => {
        setCookieValue(cookieConsentData);
    }, [cookieConsentData]);

    const onDeclineCookies = useCallback(() => {
        setCookieValue({
            ...DEFAULT_COOKIE_VALUE,
            declined: true
        });
        setShowConsentContainer(false);
    }, []);

    return (
        <div className={styles.Root}>
            <CookieConsent
                cookieName={DEFAULT_COOKIE_NAME}
                cookieValue={JSON.stringify({
                    required: true,
                    preferences: true,
                    statistics: true
                } as HotelstonConsentCookie)}
                disableStyles={true}
                containerClasses={styles.CookieBannerContainer}
                buttonWrapperClasses={styles.CookieBannerButtonContainer}
                declineButtonClasses={styles.CookieBannerDeclineButton}
                visible={showConsentContainer ? "show" : "hidden"}
                // buttonText={customizeCookies ? t("cookie_consent_accept_label") : t("cookie_consent_accept_all_label")}
                buttonText={t("cookie_consent_accept_all_label")}
                declineButtonText={t("cookie_consent_decline_label")}
                enableDeclineButton={true}
                declineCookieValue={JSON.stringify(cookieConsentData)}
                extraCookieOptions={{
                    domain: "hotelston.com"
                }}
                onAccept={onAcceptCookies}
                onDecline={onDeclineCookies}
            >
                <div className={styles.CookieConsentChildrenContainer}>{t("cookie_consent_text")}</div>

                {!customizeCookies && (
                    <a onClick={onCustomizeCookiesClick}>{t("cookie_consent_customize_choices")}</a>
                )}

                {customizeCookies && (
                    <>
                        <div className={styles.CookieTypeSelectContainer}>
                            <CustomCheckBox
                                label={t("cookie_consent_option_required")}
                                inputProps={{
                                    checked: true,
                                    readOnly: true
                                }}
                            />

                            <CustomCheckBox
                                label={t("cookie_consent_option_preferences")}
                                inputProps={{
                                    checked: cookieConsentData.preferences,
                                    onChange: (e) => setCookieConsentData({
                                        ...cookieConsentData,
                                        preferences: e.target.checked
                                    })
                                }}
                            />

                            <CustomCheckBox
                                label={t("cookie_consent_option_statistics")}
                                inputProps={{
                                    checked: cookieConsentData.statistics,
                                    onChange: (e) => setCookieConsentData({
                                        ...cookieConsentData,
                                        statistics: e.target.checked
                                    })
                                }}
                            />
                        </div>
                    </>
                )}

                {/*<div className={styles.CookieTypeSelectContainer}>*/}
                {/*    <CustomCheckBox*/}
                {/*        label={t("cookie_consent_option_required")}*/}
                {/*        inputProps={{*/}
                {/*            checked: true,*/}
                {/*            readOnly: true*/}
                {/*        }}*/}
                {/*    />*/}

                {/*    <CustomCheckBox*/}
                {/*        label={t("cookie_consent_option_preferences")}*/}
                {/*        inputProps={{*/}
                {/*            checked: cookieConsentData.preferences,*/}
                {/*            onChange: (e) => setCookieConsentData({*/}
                {/*                ...cookieConsentData,*/}
                {/*                preferences: e.target.checked*/}
                {/*            })*/}
                {/*        }}*/}
                {/*    />*/}

                {/*    <CustomCheckBox*/}
                {/*        label={t("cookie_consent_option_statistics")}*/}
                {/*        inputProps={{*/}
                {/*            checked: cookieConsentData.statistics,*/}
                {/*            onChange: (e) => setCookieConsentData({*/}
                {/*                ...cookieConsentData,*/}
                {/*                statistics: e.target.checked*/}
                {/*            })*/}
                {/*        }}*/}
                {/*    />*/}
                {/*</div>*/}
            </CookieConsent>
        </div>
    );
};

export default CookieConsentContainer;