import React, {ReactElement} from "react"
import {useTranslation} from "react-i18next"
import SharedPaymentTypeProps from "../SharedPaymentTypeProps"
import SuperAgentPaymentBlock from "./SuperAgentPaymentBlock"
import ButtonConfirmNonrefModal from "../../ButtonConfirmNonRefModal/ButtonConfirmNonrefModal"
import Pricing from "components/common/Pricing/Pricing"
import usePaymentOptions from "../usePaymentOptions"
import {useAppSelector} from "redux/hooks"
import {useCompanyCurrency, useCurrency} from "components/utils/withLocalesAndCurrencies"

const SuperAgentPayment = (props: SharedPaymentTypeProps): ReactElement => {
    const {
        paymentTransferTypeEnum,
        toggleSelectedPayment
    } = props

    const {t} = useTranslation()

    const currency = useCurrency()
    const companyCurrency = useCompanyCurrency()

    const isSubagent = useAppSelector((state) => state.auth.userData?.subAgent)

    const {
        paymentOptions,
        discount,
        voucherSum
    } = usePaymentOptions()

    if (!paymentOptions || !isSubagent) {
        return (
            <></>
        )
    }

    const {
        paymentDeadlineTime,
        paymentDeadlineDate,
        allowToBook,
        sanctionedCompany,
        salePrice,
        displaySalePrice,
        smsFee,
        displaySmsFee,
        paymentPending,
        superAgentName,
        superAgentUseBalance,
        afterDeadline
    } = paymentOptions

    if (superAgentUseBalance) {
        return (
            <></>
        );
    }

    return (
        <SuperAgentPaymentBlock
            paymentDeadlineTime={paymentDeadlineTime}
            paymentDeadlineDate={paymentDeadlineDate}
            paymentTransferType={paymentTransferTypeEnum}
            toggleSelectedPayment={toggleSelectedPayment}
        >
            <Pricing
                customButton={
                    afterDeadline && (
                        <ButtonConfirmNonrefModal
                            customText={t("pc_pc_superagent_payment_non_ref_warn", {superAgentName: superAgentName})}
                            customSubText={t("pc_pc_superagent_payment_non_ref_warn_2", {superAgentName: superAgentName})}
                            disabledButton={!allowToBook || sanctionedCompany}
                        />
                    )
                }
                withButton
                customButtonLabel={t("pc_pc_book_now")}
                disabledButton={!allowToBook || sanctionedCompany}
                greyLabels
                salePrice={salePrice}
                displaySalePrice={displaySalePrice}
                smsFee={smsFee}
                displaySmsFee={displaySmsFee}
                discount={discount}
                voucher={voucherSum.used}
                paymentPending={paymentPending}
            />
        </SuperAgentPaymentBlock>
    )
}

export default SuperAgentPayment