import React, {ReactElement, useCallback, useEffect} from "react";
import {useTranslation} from "react-i18next";
import {useAppDispatch, useAppSelector} from "redux/hooks";
import {RootState} from "redux/store/store.init";
import MainContainer from "components/base/MainContainer";
import styles from "./SubagentBillingDetails.module.scss";
import UserButton from "components/base/UserButton";
import {UserButtonVariant} from "components/base/UserButton/UserButton";
import router from "views/router/router";
import {redirectComplete, redirectMounted} from "redux/actions/actionRedirect.actions";
import {ReduxActionRedirectType} from "proptypes/PropTypeObjects";
import {Navigate} from "react-router-dom";
import useIsMounted from "@hotelston_web_frontend_components/hooks/detection/useIsMounted";
import Spinner from "components/base/Loaders/Spinner";

type PayloadType = {
    superAgentName: string;
}

const SubagentBillingDetails = (): ReactElement => {
    const {t} = useTranslation();
    const dispatch = useAppDispatch();

    const redirectType = useAppSelector((state) => state.actionRedirect.actionRedirect);
    const payload = useAppSelector((state: RootState) => state.actionRedirect.actionRedirectPayload as PayloadType);
    const isMounted = useIsMounted();

    const signLaterCallback = useCallback(() => {
        router.navigate(-1).then(() => {
            dispatch(redirectComplete());
        });
    }, [dispatch]);

    useEffect(() => {
        dispatch(redirectMounted());
    }, [dispatch]);

    if (!redirectType || ReduxActionRedirectType.SUBAGENT_AGREEMENT_AFTER_BOOK !== redirectType) {
        if (!isMounted) {
            return (
                <Navigate to="/"/>
            );
        } else {
            return (
                <Spinner
                    size="50px"
                    style={{
                        width: "100%",
                        height: "calc( 100vh - 60px)"
                    }}
                />
            );
        }
    }

    return (
        <MainContainer
            variants={{
                center: true
            }}
        >
            <div className={styles.Root}>
                <h1>{t("a_r_subagent_billing_details_header")}</h1>

                <p>
                    {t("a_r_subagent_billing_details_content", {
                        superAgentName: payload.superAgentName
                    })}
                </p>

                <UserButton
                    text={t("a_r_subagent_billing_details_back_to_results")}
                    variant={UserButtonVariant.PRIMARY}
                    buttonProps={{
                        onClick: signLaterCallback
                    }}
                />
            </div>
        </MainContainer>
    );
}

export default SubagentBillingDetails;