import React, {useCallback, useEffect, useState} from "react";
import {connect, ConnectedProps, shallowEqual} from "react-redux";
import {useOffersRequest, usePinnedOffers, usePinnedOffersGetPdfAction} from "components/utils/withPinnedOffers";
import useKeyDetect from "components/utils/useKeyDetect";
import parisImg from "assets/images/searchIntroImage.jpg";
import {useTranslation} from "react-i18next";
import * as bookingActions from "redux/actions/hotelBooking.actions";
import Fade from "components/utils/Transitions/Fade";
import cx from "classnames";
import HotelOffers from "./HotelOffers/HotelOffers";
import RoomOffer from "./RoomOffer";
import styles from "./PinnedOffersPreviewModal.module.scss";
import ModalWithOverlay from "../Modal/ModalWithOverlay";
import Modal from "../Modal";
import {useAppSelector} from "../../../redux/hooks";
import ActionIcon, {ActionIconType} from "../ActionIcon/ActionIcon";
import {RootState} from "../../../redux/store/store.init";
import UserButton from "../../base/UserButton";
import {UserButtonVariant} from "../../base/UserButton/UserButton";
import createBlobAndDownload from "../../../utils/createBlobAndDownload";
import Spinner from "../../base/Loaders/Spinner";
import createLuxonDate from "../../../utils/date/createLuxonDate"
import useSelectedMarkup from "../../../utils/hooks/useSelectedMarkup";
import ConfirmationModal from "../ConfirmationModal";

function PreviewLocation({destinationString, currentDestination}: any) {
    const {t} = useTranslation();
    return (
        <>
            {/*{currentDestination.image && (*/}
            {/*    <div className={styles.Location}>*/}
            {/*        <img*/}
            {/*            className={styles.LocationImg}*/}
            {/*            src={currentDestination && currentDestination.image ? currentDestination.image : parisImg}*/}
            {/*            alt="cover_photo"*/}
            {/*        />*/}
            {/*    </div>*/}
            {/*)}*/}

            <h2 className={styles.LocationTitle}>
                {/*{t("pin_popm_best_room_offers_in")}*/}
                <span className="capitalize">{destinationString}</span>
            </h2>
        </>
    );
}

type Props = ConnectedProps<typeof connector> & {
    onClose: () => void;
};

function PinnedOffersPreviewModal({onClose, data}: Props) {
    const {isNETPrice} = useSelectedMarkup();

    const [fade, setFade] = useState(false);
    const [exportingPdf, setExportingPdf] = useState(false);
    const [exportPDFModalVisible, setExportPDFModalVisible] = useState<boolean>(false);

    const fadeDuration = 300;

    const onCloseWithFade = () => {
        setFade(false);
        setTimeout(() => onClose(), fadeDuration);
    };

    const {t} = useTranslation();
    useKeyDetect(27, onCloseWithFade);

    const destinationInput = useAppSelector((state) => state.hotelSearch.destinationInput, shallowEqual);

    const checkIn = useAppSelector((state) => state.hotelSearch.formData.checkIn);
    const checkOut = useAppSelector((state) => state.hotelSearch.formData.checkOut);
    const nights = Math.floor(createLuxonDate(checkOut).diff(createLuxonDate(checkIn), "days").days);
    const currency = useAppSelector((state) => state.currency.currentCurrency, shallowEqual);
    const hotelAmount = useAppSelector((state) => state.auth.userData?.companyMarkups[state.auth.currentCompanyMarkupIndex || 0].hotelAmount, shallowEqual);

    const {pinnedOffers, toggleSlideIn, pinnedHotels} = usePinnedOffers();

    useEffect(() => {
        setFade(true);
    }, []);

    const {requestBody, subject} = useOffersRequest();

    const getPdf = usePinnedOffersGetPdfAction((pdfFile) => createBlobAndDownload(
        pdfFile,
        "application/pdf",
        subject + ".pdf",
        true
    ));

    const onExportPDFClick = useCallback((withPrice: boolean) => {
        onCloseWithFade();
        setExportingPdf(true);

        getPdf(requestBody, withPrice).then(() => {
            setExportingPdf(false);
        }).catch(() => {
            setExportingPdf(false);
        });
    }, [getPdf, onCloseWithFade, requestBody]);

    return (
        <>
            {exportPDFModalVisible && (
                <ConfirmationModal
                    onConfirm={() => onExportPDFClick(true)}
                    onDecline={() => onExportPDFClick(false)}
                    config={{
                        variants: {
                            type: "normal",
                            innerCloseButton: true
                        },
                        title: t("pin_popm_export_include_price_header"),
                        content: (
                            <>{t("pin_popm_export_include_price_explanation")}</>
                        ),
                        confirmButtonText: t("pin_popm_export_pdf_with_price_yes"),
                        declineButtonText: t("pin_popm_export_pdf_with_price_no")
                    }}
                />
            )}

            <Modal>
                <Fade inProp={fade} duration={fadeDuration}>
                    <ModalWithOverlay>
                        <div className={styles.Root}>
                            <div className={styles.Wrapper}>
                                <h3 className={styles.Title}>{t("pin_popm_preview")}</h3>

                                <span className={styles.CloseBtn} onClick={onCloseWithFade}>
                                    <ActionIcon type={ActionIconType.CLOSE}/>
                                </span>

                                <div className={styles.Content}>
                                    {pinnedHotels.length > 1 && (
                                        <PreviewLocation
                                            destinationString={destinationInput}
                                            currentDestination={data.currentDestination}
                                        />
                                    )}

                                    {pinnedHotels.length === 1 && (
                                        <div className={styles.OnlyOneIntro}>
                                            <h2>
                                                {/*{t("pin_popm_best_room_offers_in_single")}*/}
                                                {pinnedHotels[0].hotel.name}
                                            </h2>
                                        </div>
                                    )}

                                    {pinnedHotels.map((pinnedHotel, i) => (
                                        <HotelOffers
                                            key={`${i}-${pinnedHotel.hotel.id}`}
                                            hotel={pinnedHotel.hotel}
                                            onlyOne={pinnedHotels.length === 1}
                                        >
                                            {pinnedOffers
                                                .filter(({hotelId}) => pinnedHotel.hotel.id === hotelId)
                                                .map((pinnedOffer) => (
                                                    <RoomOffer
                                                        key={`${pinnedOffer.roomNo}-${pinnedOffer.roomId}`}
                                                        pinnedOffer={pinnedOffer}
                                                        room={pinnedHotel.roomOffers[pinnedOffer.roomNo].find(({roomId}) => pinnedOffer.roomId === roomId)}
                                                        currency={currency}
                                                        hotelAmount={hotelAmount}
                                                        nights={nights}
                                                    />
                                                ))}
                                        </HotelOffers>
                                    ))}

                                    <div className={styles.Actions}>
                                        <UserButton
                                            className={cx(styles.ExportPDF, exportingPdf && styles.ExportPDFActive)}
                                            text={t("pin_po_export_pdf")}
                                            variant={UserButtonVariant.WHITE}
                                            buttonProps={{
                                                onClick: () => {
                                                    if (isNETPrice) {
                                                        onExportPDFClick(false);
                                                    } else {
                                                        setExportPDFModalVisible(true);
                                                    }
                                                }
                                            }}
                                        >
                                            <Spinner className={styles.ExportPDFSpinner}/>
                                        </UserButton>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </ModalWithOverlay>
                </Fade>
            </Modal>
        </>
    );
}

const mapStateToProps = ({hotelSearch}: RootState) => ({
    data: hotelSearch
});
const connector = connect(mapStateToProps, bookingActions);
export default connector(PinnedOffersPreviewModal);
