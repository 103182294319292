import React, {
    ReactElement, useCallback, useEffect, useMemo, useState
} from "react";
import {MarkerClusterer, useGoogleMap} from "@react-google-maps/api";
import {Cluster, Clusterer} from "@react-google-maps/marker-clusterer";
import _ from "lodash";
import {useCurrency} from "components/utils/withLocalesAndCurrencies";
import OneActiveSubject, {OneActiveSubjectObserver} from "utils/generic/oneActiveSubject";
import CustomMarker from "./Marker/CustomMarker";
import useCalculatedMinPricesMap from "utils/hooks/useCalculatedMinPricesMap";
import {useAppSelector} from "redux/hooks";
import MapMarkerProps from "views/Hotels/SearchResults/DetailedView/MapContainer/MapMarkerProps"

export type CustomMarkersProps = {
    dataForMarkers: Map<number, MapMarkerProps>,
    markersSubject: OneActiveSubject<void, number>
};

function CustomMarkers(props: CustomMarkersProps): ReactElement {
    const {
        dataForMarkers,
        markersSubject
    } = props;

    const currency = useCurrency();

    const isUpdating = useAppSelector((state) => state.hotelSearchResults.isUpdating);
    const map = useGoogleMap();
    const [clusterer, setClusterer] = useState<Clusterer>(undefined);
    // const markerMinPrices: Map<number, number> = new Map<number, number>();
    const {map: markerMinPrices} = useCalculatedMinPricesMap({dataForMarkers});

    const singleMarkerSubject = useMemo(() => new OneActiveSubject<void, number>(), []);

    useEffect(() => {
        if (isUpdating || !map || !map.getBounds()) {
            return;
        }

        if (map && clusterer && map.getBounds()) {
            google.maps.event.addListenerOnce(
                map,
                "idle",
                () => clusterer?.repaint()
            );
        }
    }, [clusterer, isUpdating, map]);

    const drawOpacityOnClusters = useCallback(() => {
        if (!clusterer || !map || !map.getBounds()) {
            return;
        }

        const currentActive = markersSubject.getActive();
        const containsClass = (className: string, match: string) => className.indexOf(match) !== -1;
        let redraw = false;

        if (currentActive && clusterer) {
            clusterer.clusters?.forEach((cluster: Cluster) => {
                const marker = dataForMarkers.get(currentActive);

                if (!marker || !marker.coordinates) {
                    return;
                }

                const {
                    latitude: lat,
                    longitude: lng
                } = marker.coordinates;

                const containsPoint = cluster.bounds?.contains({lat, lng});

                if (containsPoint && !containsClass(cluster.clusterIcon.className, "cluster-opacity")) {
                    _.set(cluster.clusterIcon, "className", "cluster cluster-opacity");
                    redraw = true;
                } else if (!containsPoint && containsClass(cluster.clusterIcon.className, "cluster-opacity")) {
                    _.set(cluster.clusterIcon, "className", "cluster");
                    redraw = true;
                }
            });
        }

        if (redraw) {
            clusterer?.redraw();
        }
    }, [clusterer, dataForMarkers, markersSubject, map]);

    const cleanupClusters = useCallback(() => {
        if (!clusterer) {
            return;
        }

        const containsClass = (className: string, match: string) => className.indexOf(match) !== -1;

        let cleanupRedraw = false;

        clusterer.clusters?.forEach((cluster) => {
            if (containsClass(cluster.clusterIcon.className, "cluster-opacity")) {
                _.set(cluster.clusterIcon, "className", "cluster");
                cleanupRedraw = true;
            }
        });

        if (cleanupRedraw) {
            clusterer.redraw();
        }
    }, [clusterer]);

    useEffect(() => {
        const listener = {
            onPayload: (key) => {
                if (key) {
                    drawOpacityOnClusters();
                } else {
                    cleanupClusters();
                }
            },
            onClose: () => {}
        } as OneActiveSubjectObserver<number>;

        markersSubject.subscribeGlobal(listener);

        return () => {
            markersSubject.unsubscribeGlobal(listener);
        };
    }, [cleanupClusters, drawOpacityOnClusters, markersSubject]);

    const constructedMarkers = useCallback((clusterer: Clusterer) => (
        <>
            {Array.from(dataForMarkers.keys()).map((key) => {
                const {
                    id,
                    coordinates,
                    boardType,
                    roomType,
                    reviewRating
                } = dataForMarkers.get(key);

                const {
                    latitude: lat,
                    longitude: lng
                } = coordinates;

                return (
                    <CustomMarker
                        singleMarkerSubject={singleMarkerSubject}
                        hoverSubject={markersSubject}
                        boardType={boardType}
                        roomType={roomType}
                        reviewRating={reviewRating}
                        key={id}
                        id={String(id)}
                        lat={lat}
                        lng={lng}
                        clusterer={clusterer}
                        minPrices={markerMinPrices}
                        currency={currency}
                    />
                );
            })}
        </>
    ), [currency, dataForMarkers, markerMinPrices, markersSubject, singleMarkerSubject]);

    const onClusteringEndCallback = useCallback(() => {
        if (markersSubject.hasActive()) {
            drawOpacityOnClusters();
        } else {
            cleanupClusters();
        }
    }, [cleanupClusters, drawOpacityOnClusters, markersSubject]);

    const onLoadCallback = useCallback((clusterer: Clusterer) => setClusterer(clusterer), []);

    return (
        <MarkerClusterer
            onClusteringEnd={onClusteringEndCallback}
            onLoad={onLoadCallback}
            maxZoom={17}
            options={{
                imagePath: "/cluster/m",
                maxZoom: 17
            }}
        >
            {constructedMarkers}
        </MarkerClusterer>
    );
}

export default React.memo(CustomMarkers);