import {ReduxMapPropTypes} from "../../proptypes/PropTypeRedux";
import MapTypes from "../constants/map.constants";
import MapActionProps from "./actions/map.reducer.actions";

export const INITIAL_STATE = {
    zoom: 10,
    center: {lat: 0, lng: 0},
    precalculatedZoom: undefined,
    precalculatedCenter: undefined,
    fullWidthMap: false,
    drawingMode: undefined,
    mobileDrawing: false,
    polygon: undefined,
    circle: undefined,
    showMapFilterBox: false,
    mapCanBeLoaded: false,
    mapTypeId: "roadmap",
    places: []
} as ReduxMapPropTypes;

export default (state = INITIAL_STATE, action: MapActionProps): ReduxMapPropTypes => {
    switch (action.type) {
    case MapTypes.RESET_MAP:
        return {
            ...INITIAL_STATE,
            mapCanBeLoaded: state.mapCanBeLoaded,
            center: state.center
        };
    case MapTypes.TOGGLE_MAP_WIDTH:
        return {...state, fullWidthMap: !state.fullWidthMap};
    case MapTypes.MAP_CAN_BE_LOADED:
        return {...state, mapCanBeLoaded: action.mapCanBeLoaded};
    case MapTypes.CHANGE_MAP_ZOOM:
        return {...state, zoom: action.zoom};
    case MapTypes.CHANGE_MAP_CENTER:
        return {...state, center: action.center};
    case MapTypes.CHANGE_PRECALCULATED_ZOOM:
        return {...state, precalculatedZoom: action.zoom};
    case MapTypes.CHANGE_PRECALCULATED_CENTER:
        return {...state, precalculatedCenter: action.center};
    case MapTypes.CHANGE_MAP_TYPE_ID:
        return {...state, mapTypeId: action.mapTypeId};
    case MapTypes.CHANGE_DRAWING_MODE:
        return {...state, drawingMode: action.drawingMode};
    case MapTypes.TOGGLE_MOBILE_DRAWING:
        return {...state, mobileDrawing: !state.mobileDrawing};
    case MapTypes.SET_FIGURE:
        return {
            ...state,
            showMapFilterBox: action.figure ? state.showMapFilterBox : false,
            polygon: action.figureName === google.maps.drawing.OverlayType.POLYGON ? action.figure as google.maps.Polygon : undefined,
            circle: action.figureName === google.maps.drawing.OverlayType.CIRCLE ? action.figure as google.maps.Circle : undefined
        };
    case MapTypes.SET_PLACES:
        return {...state, places: action.places};
    case MapTypes.TOGGLE_MAP_FILTER_BOX:
        return {...state, showMapFilterBox: action.showMapFilterBox};
    default:
        return state;
    }
};
