import React, {CSSProperties, ReactElement, useState} from "react";
import cx from "classnames";
import styles from "./ActionButton.module.scss";

type ActionButtonProps = {
    className?: string;
    textBefore?: string;
    textAfter?: string;
    style?: CSSProperties;
    callback?: () => void;
    callbackArgs?: [];
}

function ActionButton({
    className,
    textBefore = "",
    textAfter = "",
    style = {},
    callback,
    callbackArgs = []
}: ActionButtonProps): ReactElement {
    const [usedAction, setUsedAction] = useState(false);

    const handleAction = () => {
        callback(...callbackArgs);
        setUsedAction(true);
    };

    return usedAction
        ? (
            <div
                style={style}
                className={cx(styles.root, styles.after, className)}
            >
                {textAfter}
            </div>
        )
        : (
            <button
                onClick={handleAction}
                style={style}
                className={cx(styles.root, className)}
            >
                {textBefore}
            </button>
        );
}

export default ActionButton;
