import React, {ReactElement} from "react";
import {useTranslation} from "react-i18next";
import useLocaleDateTimeFormat, {
    LocaleDateFormatTypeEnum
} from "../../../../components/utils/Internationalization/useLocaleDateTimeFormat";
import {useAppSelector} from "../../../../redux/hooks";
import styles from "./SplitPaymentSummary.module.scss";
import cx from "classnames";
import createLuxonDate from "../../../../utils/date/createLuxonDate";
import SplitPricePricing
    from "../../../../components/common/Payment/PaymentContent/SplitPaymentContent/SplitPricePricing/SplitPricePricing";

const SplitPaymentSummary = (): ReactElement => {
    const {t} = useTranslation();
    const dateFormat = useLocaleDateTimeFormat(LocaleDateFormatTypeEnum.DATE);

    const activeOrders = useAppSelector((state) => state.payment.splitPriceBookings || state.multiPayment.activeOrders || []);

    return (
        <div className={styles.Root}>
            <h3 className={styles.Title}>
                {t("pc_pc_split_sidebar_header")}
                {/*<Link className={styles.BackLink} to="/my-bookings">*/}
                {/*    {t("mb_ps_back_to_booking")}*/}
                {/*</Link>*/}
            </h3>

            {activeOrders.map(({
                                   id, serviceStartDate, serviceName
                               }) => (
                <div key={id} className={cx(styles.Box, styles.Single)}>
                    <h4>{t("pc_pc_split_sidebar_booking_reference", {id: id})}</h4>

                    <dl>
                        <dt>{t("pc_pc_split_sidebar_service_name")}</dt>
                        <dd>{serviceName}</dd>

                        {/*<dt>{t("mb_ps_single_lead_passenger")}</dt>*/}
                        {/*<dd>{leadPassenger?.title} {leadPassenger?.firstname} {leadPassenger?.lastname}</dd>*/}

                        <dt>{t("pc_pc_split_sidebar_service_start")}</dt>
                        <dd>{createLuxonDate(serviceStartDate).toFormat(dateFormat)}</dd>

                        {/*<dt>{t("mb_ps_single_service_end")}</dt>*/}
                        {/*<dd>{createLuxonDate(serviceEndDate).toFormat(dateFormat)}</dd>*/}
                    </dl>
                </div>
            ))}

            <div
                style={{
                    marginTop: 30,
                    marginBottom: 30
                }}
                className="h-divider"
            />

            <SplitPricePricing
                className={styles.Pricing}
            />
        </div>
    );
}

export default SplitPaymentSummary;