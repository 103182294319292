import React, {ReactElement, useCallback, useState} from "react";
import {useTranslation} from "react-i18next";
import cx from "classnames";
import styles from "./MemoBox.module.scss";
import Spinner from "../../base/Loaders/Spinner/Spinner";
import {setBookingMemo} from "../../../redux/actions/bookingDetails.actions";
import {useAppDispatch, useAppSelector} from "../../../redux/hooks";

type Props = {
    memo?: string;
    editable?: boolean;
};

function MemoBox({memo, editable = false}: Props): ReactElement {
    const {t} = useTranslation();
    const settingMemo = useAppSelector((state) => state.bookingDetails.settingMemo);
    const [editingMemo, setEditingMemo] = useState(false);
    const [memoText, setMemoText] = useState(memo);
    const dispatch = useAppDispatch();

    const handleMemoEdit = useCallback(() => {
        if (editingMemo) {
            dispatch(setBookingMemo(memoText));
        }
        setEditingMemo(!editingMemo);
    }, [dispatch, editingMemo, memoText]);

    if (!editable && !memo) return (<></>);

    return (
        <div className={styles.Root}>
            <p className={styles.Heading}>{t("mb_mb_memo")}</p>

            {settingMemo ? (
                <Spinner
                    size={20}
                    spinnerStyle={{
                        position: "initial",
                        display: "inline-flex",
                        justifyContent: "flex-start"
                    }}
                />
            ) : (
                <div className={styles.MemoContainer}>
                    <p className={cx(styles.Memo, !editingMemo && styles.EditingMemo)}>
                        {editingMemo ? (
                            <textarea
                                className={styles.MemoInput}
                                value={memoText}
                                onChange={(e) => setMemoText(e.target.value)}
                                placeholder={t("mb_mb_memo_input_placeholder")}
                            />
                        ) : (memo && <span className={styles.MemoText}>{memo}&nbsp;</span>)}

                        {editable && !memo && (
                            <a onClick={handleMemoEdit} className={styles.AddMemo}>{t("bs_bi_add_memo")}</a>
                        )}

                        {editable && memo && (
                            <a onClick={handleMemoEdit} className={cx(styles.Edit, !editingMemo && styles.WithIcon)}>
                                {editingMemo ? t("bs_bi_save_button") : t("bs_bi_edit")}
                            </a>
                        )}

                        {editingMemo && (
                            <a onClick={() => setEditingMemo(false)} className={styles.CancelEdit}>
                                {t("bs_bi_cancel_button")}
                            </a>
                        )}
                    </p>
                </div>
            )}

            {!memo && (<p className={styles.AddMemoDescription}>{t("bs_bi_add_memo_desc")}</p>)}
            {memo && (<p className={styles.MemoDescription}>{t("mb_mb_memo_desc")}</p>)}
        </div>
    );
}

export default MemoBox;
