import React, {ReactElement} from "react";
import "./Stars.scss";
import {ReactComponent as StarIcon} from "assets/icons/star.svg";

export default function Stars({starRating = 0, className = "", renderAllStars = true}: { starRating?: number, className?: string, renderAllStars?: boolean }): ReactElement {
    const stars = [1, 2, 3, 4, 5];

    if (starRating === 0) {
        return (<></>);
    }

    return (
        <div className={`Stars ${className}`}>
            {
                stars.map((val, i) => (i < starRating ? <StarIcon key={i} className="star-active" /> : renderAllStars && <StarIcon key={i} className="star" />))
            }
        </div>
    );
}