import React, {
    useEffect, useMemo, useState
} from "react";
import {useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import {
    SharedPredicateFactoryOption,
    SharedPredicateFactoryProps,
    SharedPredicateFactoryReturn
} from "../SearchPredicates.base";
import {RootState} from "../../../../../redux/store/store.init";
import {
    ClientBookingStatusKeyValuePropTypes
} from "../../../../../proptypes/PropTypeObjects";
import BookingStatusBubble from "./BookingStatusBubble";

type PossibleCombinationPair = {
    texts: string[];
    value: ClientBookingStatusKeyValuePropTypes;
};

export default function useBookingStatusBubbleFactory({
    collectPipeline,

    addSubject,
    removeSubject,

    searchString
}: SharedPredicateFactoryProps): SharedPredicateFactoryReturn {
    const {t} = useTranslation();
    const [options, setOptions] = useState<SharedPredicateFactoryOption[]>([]);
    const bookingStatuses = useSelector((state: RootState) => state.myBookings?.bookingOptions?.bookingStatuses);

    const possibleCombinations = useMemo<PossibleCombinationPair[]>(() => {
        if (!bookingStatuses) {
            return [];
        }

        return bookingStatuses.map((bookingStatus) => {
            const originalText = t(bookingStatus.key);
            const prefixes: string[] = [];

            for (let i = originalText.length; i > 2; i--) {
                prefixes.push(originalText.substring(0, i));
            }

            return {
                texts: prefixes,
                value: bookingStatus
            } as PossibleCombinationPair;
        });
    }, [bookingStatuses, t]);

    useEffect(() => {
        const newOptions: SharedPredicateFactoryOption[] = [];
        const lowerCaseSearchString = searchString.toLowerCase();

        possibleCombinations.forEach((combo) => {
            for (let i = 0; i < combo.texts.length; i++) {
                const index = lowerCaseSearchString.indexOf(combo.texts[i].toLowerCase());

                if (index !== -1) {
                    newOptions.push({
                        label: <>{t("s_p_c_booking_status_search")} <b>{combo.texts[0]}</b></>,
                        onClick: () => {
                            void addSubject.next({
                                child: (
                                    <BookingStatusBubble
                                        collectPipeline={collectPipeline}
                                        key={combo.value.value}
                                        label={combo.texts[0]}
                                        value={combo.value}
                                        onRemove={() => void removeSubject.next(combo.value.value)}
                                    />
                                ),
                                reference: combo.value.value,

                                fromPos: index,
                                strLen: combo.texts[i].length
                            });
                        }
                    });

                    break;
                }
            }
        });

        setOptions(newOptions);
    }, [addSubject, collectPipeline, possibleCombinations, removeSubject, searchString]);

    return {
        options: options
    };
}