import React, {ReactElement} from "react";
import cx from "classnames";
import {connect} from "react-redux";
import styles from "./PageTemplate.module.scss";
import {RootState} from "../../../redux/store/store.init";
import CookieConsentContainer from "../../utils/CookieConsentContainer/CookieConsentContainer";
import BreadcrumbsContainer from "../Header/BreadcrumbsContainer/BreadcrumbsContainer";
import withRouteInfo, {WithRouteInfo} from "../../utils/withRouteInfo";
import PostLoginModalsContainer from "./PostLoginModalsContainer/PostLoginModalsContainer";

export type PageTemplateProps = {
    header: ReactElement;
    footer: ReactElement;
    lefter: ReactElement;
    righter: ReactElement;
    children: ReactElement | ReactElement[];
    className?: string;

    scrollLocked: boolean;
} & WithRouteInfo;

const PageTemplate = ({
    header,
    footer,
    lefter,
    righter,
    children,
    className,

    scrollLocked,

    lastLocation
}: PageTemplateProps): ReactElement => {
    if (scrollLocked) {
        document.getElementsByTagName("body")[0].className = styles.ScrollLocked;
    } else {
        document.getElementsByTagName("body")[0].className = "";
    }

    const devEnv: boolean = process.env.REACT_APP_ENV == "development";

    return (
        <div
            className={cx(
                styles.PageTemplate,
                scrollLocked && styles.ScrollLocked,
                lastLocation && styles.WithBreadcrumbs,
                devEnv && styles.PageMainPaneWDevNotification,
                className
            )}
        >
            <BreadcrumbsContainer />

            <div className={styles.PageMainPane}>
                {header && <>{header}</>}

                {lefter && (
                    <div className={styles.PageLeftPane}>
                        {lefter}
                    </div>
                )}

                <div className={styles.PageMain}>
                    {children}
                </div>

                <CookieConsentContainer />
                <PostLoginModalsContainer />
            </div>

            {footer && (
                <div>
                    <div>{footer}</div>
                </div>
            )}

            {righter && (
                <div className={styles.PageRightPane}>
                    {righter}
                </div>
            )}
        </div>
    );
};

const mapStateToProps = (state: RootState) => ({
    scrollLocked: state.modals.scrollLocked
});

export default withRouteInfo(connect(mapStateToProps)(PageTemplate));
