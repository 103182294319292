import React, {ReactElement} from "react";
import styles from "./Pricing.module.scss";
import Currency from "@hotelston_web_frontend_parent/constants/currency";
import cx from "classnames";
import {getPriceWithCurrencySymbol} from "@hotelston_web_frontend_utils/currency/getPriceWithCurrency";

export type PricingElement = {
    label: string;
    amount: number;
    currency?: Currency;

    additionalSpaceBelow?: boolean;
}

export type PricingProps = {
    items: PricingElement[];
    className?: string;
};

const Pricing = (props: PricingProps): ReactElement => {
    const {
        items,
        className
    } = props;

    return (
        <table
            className={cx(styles.Root, className)}
        >
            {items.filter((item) => !!item).map((item, index) => (
                <tr
                    key={item.label}
                    className={cx(
                        styles.PricingElement,
                        item.additionalSpaceBelow && styles.PricingElementSpaced
                    )}
                >
                    <td>{item.label}:</td>

                    <td>{getPriceWithCurrencySymbol(item.amount, item.currency || Currency.EUR)}</td>
                </tr>
            ))}
        </table>
    );
}

export default Pricing;