import React, {MouseEvent, ReactElement, useCallback, useEffect, useRef, useState} from "react";
import useLocaleDateTimeFormat, {
    LocaleDateFormatTypeEnum
} from "../../../../utils/Internationalization/useLocaleDateTimeFormat";
import DateRangePickerMonth from "../Shared/DateRangePickerMonth";
import styles from "./SingleDatePicker.module.scss";
import cx from "classnames";
import {ReactComponent as TriangleIcon} from "../../../../../assets/icons/arrow.svg";
import useMobileSwitch from "../../../../../utils/hooks/useMobileSwitch";
import Modal from "../../../../common/Modal";
import useClickOutside from "../../../../../utils/hooks/useClickOutside";
import {DateTime} from "luxon";
import createLuxonDate from "../../../../../utils/date/createLuxonDate"
import {connect, ConnectedProps} from "react-redux"
import {toggleScrollLock} from "redux/actions/modals.actions"
import {ReactComponent as CloseModalIcon} from "assets/icons/arrow 2.svg";
import {todayWithoutTime} from "utils/dateUtils";
import {useTranslation} from "react-i18next";

export type SingleDatePickerProps = ConnectedProps<typeof connector> & {
    date?: DateTime | null;
    handleDateChange: (newDate: DateTime) => void;
    placeholder?: string;
    label?: string;
    validFrom?: DateTime;
    className?: string;
}

const SingleDatePicker = (props: SingleDatePickerProps): ReactElement => {
    const {
        date = null,
        placeholder,
        label,
        validFrom,
        handleDateChange,
        className,
        toggleScrollLock
    } = props;

    const {t} = useTranslation();

    const [selectTodayEnabled] = useState(!validFrom || validFrom <= todayWithoutTime());
    const [innerValue, setInnerValue] = useState<DateTime | null>(date);
    const [active, setActive] = useState<boolean>(false);

    const containerRef = useRef<HTMLDivElement>(null);

    const dateFormat = useLocaleDateTimeFormat(LocaleDateFormatTypeEnum.DATE);
    const {isMobile} = useMobileSwitch();

    const toggleActive = useCallback((evt: MouseEvent) => {
        evt.stopPropagation();
        evt.preventDefault();

        if (!active) {
            toggleScrollLock(true);
            setActive(!active);
        } else {
            toggleScrollLock(false);
        }
    }, [active, toggleScrollLock]);

    const onDateSelected = useCallback((value: DateTime) => {
        handleDateChange(value);
        setActive(false);
        toggleScrollLock(false);
    }, [handleDateChange, toggleScrollLock]);

    const previousMonthCallback = useCallback(() => {
        setInnerValue(createLuxonDate(innerValue || date).minus({month: 1}));
    }, [date, innerValue]);

    const nextMonthCallback = useCallback(() => {
        setInnerValue(createLuxonDate(innerValue || date).plus({month: 1}));
    }, [date, innerValue]);

    const closeCallback = useCallback(() => {
        setActive(false);
        toggleScrollLock(false);
    }, [toggleScrollLock]);

    const selectTodayCallback = useCallback(() => {
        const today = todayWithoutTime();

        onDateSelected(today);
        setInnerValue(today);
    }, [onDateSelected]);

    useClickOutside(containerRef, () => {
        setActive(false);
        toggleScrollLock(false);
    });

    useEffect(() => {
        if (date) {
            setInnerValue(date);
        }
    }, [date]);

    const element = (
        <div ref={containerRef} className={cx(styles.Root, active && styles.Active, className)}>
            {label && <label onClick={toggleActive}>{label}</label>}

            <a onClick={toggleActive}>{date?.toFormat(dateFormat) || placeholder}</a>

            {active && (
                <div className={styles.DatePicker}>
                    <div className={styles.DateRangePickerControls}>
                        <a onClick={previousMonthCallback}>
                            <TriangleIcon/>
                        </a>

                        <a onClick={nextMonthCallback}>
                            <TriangleIcon/>
                        </a>

                        <a
                            onClick={closeCallback}
                            className={styles.CloseIcon}
                        >
                            <CloseModalIcon/>
                        </a>
                    </div>

                    <DateRangePickerMonth
                        className={styles.DateSelect}
                        value={innerValue}
                        from={date}
                        onSelectDay={onDateSelected}
                        validFrom={validFrom}
                    />

                    {selectTodayEnabled && (
                        <div
                            className={styles.SelectTodayContainer}
                        >
                            <span
                                className={styles.SelectToday}
                                onClick={selectTodayCallback}
                            >
                                {t("d_r_p_select_today")}
                            </span>
                        </div>
                    )}
                </div>
            )}
        </div>
    );

    if (isMobile && active) {
        return (
            <Modal children={element}/>
        );
    }

    return element;
};

const connector = connect(undefined, {
    toggleScrollLock
});

export default connector(SingleDatePicker);