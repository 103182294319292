import {DetectedDeviceType} from "utils/detection/detectDeviceType";
import {DetectedBrowserType} from "utils/detection/detectBrowserType";
import {DETECTED_BROWSER_TYPE, DETECTED_DEVICE_TYPE} from "appBootstrap";

function isAppleWebkit(): boolean {
    // all browsers on iPhone and iPad are safari engine based, regardless of vendor
    if (DETECTED_DEVICE_TYPE === DetectedDeviceType.IPHONE || DETECTED_DEVICE_TYPE === DetectedDeviceType.IPAD) {
        return true;
    }

    return DETECTED_DEVICE_TYPE === DetectedDeviceType.MAC && DETECTED_BROWSER_TYPE === DetectedBrowserType.SAFARI;
}

export default isAppleWebkit;