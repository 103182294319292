import _ from "lodash";
import {AddressPropTypes} from "../proptypes/PropTypeObjects";

export default function getHotelAddress(address?: AddressPropTypes): string {
    if (!address) {
        return "";
    }

    if (address.fullAddress || !address.fullAddress.includes("null") || !address.street1 || address.street1.includes("null")) {
        return address.fullAddress;
    }

    return [address.street1, address.zip, address.city, address.country].filter((e) => !_.isEmpty(e)).join(", ");
}
