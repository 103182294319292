import {TFunction} from "i18next";
import React, {ReactElement} from "react";
import cx from "classnames";
import {HotelSearchResultsFiltersPropTypes} from "../../../../../proptypes/PropTypeObjects";
import CustomCheckBox from "../../../../../components/base/Input/CustomCheckBox";
import styles from "./BoardTypeFilter.module.scss";
import {useTranslation} from "react-i18next"

type BoardTypeFilterProps = {
    onBoxSelect: (value: number | string, isActive: boolean) => void;
    filters: HotelSearchResultsFiltersPropTypes;
    values: {value: string | number, label: string}[];
};

const BoardTypeFilter = (props: BoardTypeFilterProps): ReactElement => {
    const {
        filters: {boardTypeFilter}, onBoxSelect: handleBoardType, values: boardTypes
    } = props;

    const {t} = useTranslation();

    return (
        <div className={cx(styles.Root)}>
            <div className={cx(styles.Title)}>{t("h_s_sb_board_type")}</div>
            {boardTypes.map((boardType) => (
                <CustomCheckBox
                    key={boardType.value}
                    label={boardType.label}
                    inputProps={{
                        checked: boardTypeFilter.includes(boardType.value) || (boardTypeFilter.length === 0 && boardType.value === "All"),
                        onChange: (e) => handleBoardType(boardType.value, e.target.checked)
                    }}
                />
            ))}
        </div>
    );
};

export default BoardTypeFilter;