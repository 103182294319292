import ModalsActions from "./actions/modals.reducer.actions";
import types from "../constants/modals.constants";
import {ReduxModalsPropTypes} from "../../proptypes/PropTypeRedux";

export const INITIAL_STATE = {
    currentActiveModal: undefined,
    scrollLocked: false
} as ReduxModalsPropTypes;

const modals = (state = INITIAL_STATE, action: ModalsActions): ReduxModalsPropTypes => {
    switch (action.type) {
    case types.TOGGLE_MODAL:
        return {
            ...state,
            currentActiveModal: state.currentActiveModal === action.modal ? undefined : action.modal,
            scrollLocked: !!(action.modal && state.currentActiveModal !== action.modal && action.scrollLock)
        };
    case types.TOGGLE_SCROLL_LOCK:
        return {
            ...state,
            scrollLocked: action.locked
        };
    default:
        return state;
    }
};

export default modals;