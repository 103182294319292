import {
    ReactElement,
    ReactNode,
    useEffect,
    useRef,
    useState
} from "react";
import ReactDOM from "react-dom";
import {useGoogleMap} from "@react-google-maps/api";

export type MapControlProps = {
    children: ReactNode,
    dynamicControl?: boolean,
    position: number
};

export default function MapControl({children, position}: MapControlProps): ReactElement {
    const mapInstance = useGoogleMap();
    const controlDiv = useRef(document.createElement("div"));
    const [divIndex, setDivIndex] = useState<number>(0);

    useEffect(() => {
        mapInstance?.controls[position].push(controlDiv.current);
        setDivIndex(mapInstance?.controls[position]?.getArray()?.indexOf(controlDiv.current) || 0);

        return () => {
            mapInstance?.controls[position]?.removeAt(divIndex);
        };
    }, [divIndex, mapInstance?.controls, position]);

    return ReactDOM.createPortal(children, controlDiv.current as Element);
}