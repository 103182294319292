import React, {ReactElement} from "react";
import {useTranslation} from "react-i18next";
import {PaymentTransferTypePropTypes} from "proptypes/PropTypeObjects";
import InfoBox from "components/common/InfoBox";
import types from "utils/paymentTransferTypes";
import {ReactComponent as CreditLineIcon} from "assets/icons/credit line.svg";
import styles from "components/common/Payment/PaymentContent/PaymentContent.module.scss";
import {useAppDispatch} from "redux/hooks"
import {calculateTransactionFee} from "redux/actions/pricing.actions"

type Props = {
    paymentTransferTypeEnum?: PaymentTransferTypePropTypes;
    afterDeadline?: boolean;
    toggleSelectedPayment: (paymentType: PaymentTransferTypePropTypes, keepSelected?: boolean) => void;
    canUseCreditLine?: boolean;
    creditLineAmountBefore?: string;
    creditLineAmountAfter?: string;
    paymentPending?: number;
    paymentTransferTypes: PaymentTransferTypePropTypes[],
    instantPaymentAfterCXLAvailable?: boolean;
    creditLineConfirmationDate?: string;
    creditLineConfirmationTime?: string;
    children: ReactElement;
};

function CreditLineBlock({
                             paymentTransferTypeEnum,
                             afterDeadline,
                             toggleSelectedPayment,
                             canUseCreditLine,
                             creditLineAmountBefore,
                             creditLineAmountAfter,
                             paymentPending,
                             paymentTransferTypes,
                             instantPaymentAfterCXLAvailable,
                             creditLineConfirmationDate,
                             creditLineConfirmationTime,
                             children

                         }: Props): ReactElement {
    const {t} = useTranslation();
    const dispatch = useAppDispatch();

    return (
        <InfoBox
            style={{marginBottom: 15}}
            active={paymentTransferTypeEnum === types[afterDeadline ? "after" : "before"].creditLine}
            title={t("pc_pc_credit_line")}
            message={t("pc_pc_credit_line_promotional_message")}
            // miniIcon
            customIcon={<CreditLineIcon width={16} height={16}/>}
            onClick={() => {
                toggleSelectedPayment(types[afterDeadline ? "after" : "before"].creditLine);
                dispatch(calculateTransactionFee());

                return false;
            }}
            type="custom"
        >
            <div className={styles.Option}>
                <div className={styles.OptionText}>
                    {afterDeadline ? (canUseCreditLine ? (
                        <>
                            <p>
                                {t("pc_pc_ptt_credit_line_after_cxl", {
                                    before: creditLineAmountBefore,
                                    after: creditLineAmountAfter
                                })}
                            </p>
                            <p>
                                {t("pc_pc_ptt_credit_line_after_cxl_extra")}
                            </p>
                        </>
                    ) : (
                        <>
                            <InfoBox
                                style={{
                                    marginBottom: 20,
                                    marginTop: -10
                                }}
                                title={t("pc_pc_dont_have_enough_credit_error_message")}
                                message=""
                                type="warn"
                            />
                            <p>
                                {t("pc_pc_ptt_credit_line_after_cxl_no_money", {
                                    before: creditLineAmountBefore,
                                    pending: paymentPending
                                })}
                            </p>
                            <p>
                                {t("pc_pc_ptt_credit_line_after_cxl_no_money_extra")}
                            </p>
                            {paymentTransferTypes.length > 1 &&
                                (instantPaymentAfterCXLAvailable ? (
                                    <p>
                                        {t("pc_pc_ptt_credit_line_after_cxl_no_money_extra1")}
                                    </p>
                                ) : (
                                    <p>
                                        {t("pc_pc_ptt_credit_line_after_cxl_no_money_extra2")}
                                    </p>
                                ))}
                        </>
                    )) : (
                        <>
                            <p>
                                {t("pc_pc_ptt_credit_line", {
                                    date: creditLineConfirmationDate,
                                    time: creditLineConfirmationTime
                                })}
                            </p>
                            <p>
                                {t("pc_pc_ptt_credit_line_extra", {
                                    date: creditLineConfirmationDate,
                                    time: creditLineConfirmationTime
                                })}
                            </p>
                        </>
                    )}
                </div>
                <div
                    style={{
                        marginTop: 30,
                        marginBottom: 30
                    }}
                    className="h-divider"
                />

                {children}
            </div>
        </InfoBox>
    );
}

export default CreditLineBlock;
