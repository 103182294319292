import React, {ReactElement, useCallback, useEffect} from "react"
import {connect, ConnectedProps} from "react-redux";
import withPinnedOffers from "components/utils/withPinnedOffers";
import * as searchResultsActions from "../../../redux/actions/hotelSearchResults.actions";
import {putSavedSearch} from "../../../redux/actions/hotelSearch.actions";
import SearchResults from "./SearchResults";
import {RootState} from "../../../redux/store/store.init";
import {
    changeSort,
    handleChangePage
} from "../../../redux/actions/hotelSearch.base.actions";
import {Navigate, useParams} from "react-router-dom";

type SearchResultsContainerProps = ConnectedProps<typeof connector>;

const SearchResultsContainer = (props: SearchResultsContainerProps): ReactElement => {
    const {
        searchResults: {
            requestingDetails,
            hotelOffers,
            allResultsCount,
            pagesCount,
            pageNumber,
            sortByOptions,
            favoriteFilter,
            sortBy,
            size,
            pageSizeOptions,
            mapFilter,
            view
        },
        pinnedOffers: {
            slide: isActivePinnedOffers
        },
        handleChangePage,
        changePageSize,
        changeView,
        putSavedSearch,
        toggleHotelFavoriteFilter,
        changeSort,
        checkQueryParams
    } = props;

    const params = useParams();

    useEffect(() => {
        checkQueryParams();
    }, [checkQueryParams, params ]);

    const getHandleSortChange = useCallback((value: string, label: string) => {
        changeSort({
            value,
            label
        });
    }, [changeSort]);

    if (!location.search ) {
        return <Navigate to="/" />
    }
    return (
        <SearchResults
            requestingDetails={requestingDetails}
            sortBy={sortBy}
            sortByOptions={sortByOptions}
            hotelOffers={hotelOffers}
            allResultsCount={allResultsCount}
            handleSortChange={getHandleSortChange}
            handleChangeView={changeView}
            putSavedSearch={putSavedSearch}
            toggleHotelFavoriteFilter={toggleHotelFavoriteFilter}
            favoriteFilter={favoriteFilter}
            view={view}
            isActivePinnedOffers={isActivePinnedOffers}
            size={size}
            pageSizeOptions={pageSizeOptions}
            handlePageSizeChange={changePageSize}
            pageNumber={pageNumber}
            pagesCount={pagesCount}
            handleChangePage={handleChangePage}
            mapFilter={mapFilter}
        />
    )
}

const mapStateToProps = (state: RootState) => ({
    searchResults: state.hotelSearchResults,
    pinnedOffers: state.pinnedOffers
});

const connector = connect(mapStateToProps, {
    ...searchResultsActions,
    changeSort,
    putSavedSearch,
    handleChangePage
});

export default withPinnedOffers(connector(SearchResultsContainer));
