import {useCallback, useLayoutEffect} from "react";
import usePrevious from "utils/hooks/usePrevious";

export enum KeyDetectCode {
    TAB = 9,
    ENTER = 13,
    SHIFT = 16,
    CAPS_LOCK = 20,
    ESCAPE = 27,
    SPACEBAR = 32,
    LEFT_ARROW = 37,
    UP_ARROW = 38,
    RIGHT_ARROW = 39,
    DOWN_ARROW = 40
}

type KeyDetectVirtualComponentProps = {
    keyCode: KeyDetectCode;
    callback: any;
    suppressEvents?: boolean;
};

export function KeyDetectVirtualComponent({
    keyCode,
    callback,
    suppressEvents
}: KeyDetectVirtualComponentProps): null {
    useKeyDetect(keyCode, callback, suppressEvents);
    return null;
}

function useKeyDetect(keyCode: number, callback: any, suppressEvents?: boolean): void {
    const handleKeyCodeMatch = useCallback(
        (e: { keyCode: number; stopPropagation: () => void; preventDefault: () => void; }) => {
            if (callback && keyCode === e.keyCode) {
                if (callback() || suppressEvents) {
                    e.stopPropagation();
                    e.preventDefault();
                }
            }
        },
        [callback, keyCode, suppressEvents]
    );

    const prevHandle = usePrevious(handleKeyCodeMatch);

    useLayoutEffect(() => {
        if (prevHandle && prevHandle !== handleKeyCodeMatch) {
            document.removeEventListener("keydown", prevHandle, false);
        }
        document.addEventListener("keydown", handleKeyCodeMatch, false);

        return () => {
            document.removeEventListener("keydown", handleKeyCodeMatch, false);
        };
    }, [keyCode, handleKeyCodeMatch, prevHandle]);
}

export default useKeyDetect;
