import React, {FunctionComponent, ReactElement, SVGProps} from "react";

import {ReactComponent as AccessibilityIcon} from "assets/icons/features/accessibility.svg";
import {ReactComponent as BabycareIcon} from "assets/icons/features/babycare.svg";
import {ReactComponent as BathroomIcon} from "assets/icons/features/bathroom.svg";
import {ReactComponent as BedroomIcon} from "assets/icons/features/bedroom.svg";
import {ReactComponent as BusinessIcon} from "assets/icons/features/business.svg";
import {ReactComponent as ChildcareIcon} from "assets/icons/features/childcare.svg";
import {ReactComponent as CleaningIcon} from "assets/icons/features/cleaning.svg";
import {ReactComponent as DisinfectingIcon} from "assets/icons/features/disinfecting.svg";
import {ReactComponent as ExtrasIcon} from "assets/icons/features/extras.svg";
import {ReactComponent as FamilyServicesIcon} from "assets/icons/features/family_services.svg";
import {ReactComponent as FoodIcon} from "assets/icons/features/food.svg";
import {ReactComponent as FoodSafetyIcon} from "assets/icons/features/food_safety.svg";
import {ReactComponent as GeneralIcon} from "assets/icons/features/general.svg";
import {ReactComponent as InternetIcon} from "assets/icons/features/internet.svg";
import {ReactComponent as KitchenIcon} from "assets/icons/features/kitchen.svg";
import {ReactComponent as MediaIcon} from "assets/icons/features/media.svg";
import {ReactComponent as OffSiteActivitiesIcon} from "assets/icons/features/off-site_activities.svg";
import {ReactComponent as OtherIcon} from "assets/icons/features/other.svg";
import {ReactComponent as OutdoorIcon} from "assets/icons/features/outdoor.svg";
import {ReactComponent as ParkingIcon} from "assets/icons/features/parking.svg";
import {ReactComponent as PetsIcon} from "assets/icons/features/pets.svg";
import {ReactComponent as ReceptionIcon} from "assets/icons/features/reception.svg";
import {ReactComponent as RoomAmenitiesIcon} from "assets/icons/features/room_amenities.svg";
import {ReactComponent as SafetyIcon} from "assets/icons/features/safety.svg";
import {ReactComponent as SecurityIcon} from "assets/icons/features/security.svg";
import {ReactComponent as SportsIcon} from "assets/icons/features/sports.svg";
import {ReactComponent as TransportIcon} from "assets/icons/features/transport.svg";
import {ReactComponent as WellnessIcon} from "assets/icons/features/wellness.svg";
import {ReactComponent as WellnessFacilitiesIcon} from "assets/icons/features/wellness_facilities.svg";
import {ReactComponent as PoolIcon} from "assets/icons/features/pool.svg";
import {HotelFeatureGroup} from "../../../proptypes/PropTypeObjects";

const iconMap = new Map<HotelFeatureGroup, FunctionComponent<SVGProps<SVGSVGElement>>>([
    [HotelFeatureGroup.ACCESIBILITY, AccessibilityIcon],
    [HotelFeatureGroup.BABYCARE_AMENITIES, BabycareIcon],
    [HotelFeatureGroup.BATHROOM_AND_TOILET, BathroomIcon],
    [HotelFeatureGroup.BEDROOM, BedroomIcon],
    [HotelFeatureGroup.BUSINESS_FACILITIES, BusinessIcon],
    [HotelFeatureGroup.CHILDCARE_AMENITIES, ChildcareIcon],
    [HotelFeatureGroup.CLEANING_SERVICES, CleaningIcon],
    [HotelFeatureGroup.CLEANLINESS_AND_DISINFECTING, DisinfectingIcon],
    [HotelFeatureGroup.SERVICES_AND_EXTRAS, ExtrasIcon],
    [HotelFeatureGroup.ENTERTAINMENT_AND_FAMILY_SERVICES, FamilyServicesIcon],
    [HotelFeatureGroup.FOOD_AND_DRINKS, FoodIcon],
    [HotelFeatureGroup.FOOD_AND_DRINKS_SAFETY, FoodSafetyIcon],
    [HotelFeatureGroup.GENERAL, GeneralIcon],
    [HotelFeatureGroup.GENERAL_NEW, GeneralIcon],
    [HotelFeatureGroup.INTERNET, InternetIcon],
    [HotelFeatureGroup.INTERNET_AND_WIFI, InternetIcon],
    [HotelFeatureGroup.KITCHEN, KitchenIcon],
    [HotelFeatureGroup.RESTAURANT, KitchenIcon],
    [HotelFeatureGroup.MEDIA_AND_TECHNOLOGY, MediaIcon],
    [HotelFeatureGroup.OFFSITE_ACTIVITIES, OffSiteActivitiesIcon],
    [HotelFeatureGroup.OTHER, OtherIcon],
    [HotelFeatureGroup.OTHER_NEW, OtherIcon],
    [HotelFeatureGroup.OUTDOOR_AND_VIEW, OutdoorIcon],
    [HotelFeatureGroup.ACTIVITIES, OutdoorIcon],
    [HotelFeatureGroup.PARKING, ParkingIcon],
    [HotelFeatureGroup.PARKING_NEW, ParkingIcon],
    [HotelFeatureGroup.PETS, PetsIcon],
    [HotelFeatureGroup.RECEPTION_24, ReceptionIcon],
    [HotelFeatureGroup.RECEPTION_SERVICES, ReceptionIcon],
    [HotelFeatureGroup.ROOM_AMENITIES, RoomAmenitiesIcon],
    [HotelFeatureGroup.AIR_CONDITIONING, RoomAmenitiesIcon],
    [HotelFeatureGroup.HAIR_DRYER, RoomAmenitiesIcon],
    [HotelFeatureGroup.INSIDE_ROOMS, RoomAmenitiesIcon],
    [HotelFeatureGroup.SAFETY_AND_MEASURES, SafetyIcon],
    [HotelFeatureGroup.SAFETY_FEATURES, SafetyIcon],
    [HotelFeatureGroup.PHYSICAL_AND_SOCIAL_DISTANCING, SafetyIcon],
    [HotelFeatureGroup.SAFE, SafetyIcon],
    [HotelFeatureGroup.SAFETY_AND_SECURITY, SecurityIcon],
    [HotelFeatureGroup.SPORTS_AND_OTHER_ACTIVITIES, SportsIcon],
    [HotelFeatureGroup.TRANSPORT, TransportIcon],
    [HotelFeatureGroup.BEAUTY_TREATMENTS, WellnessIcon],
    [HotelFeatureGroup.WELLNESS, WellnessIcon],
    [HotelFeatureGroup.WELLNESS_FACILITIES, WellnessFacilitiesIcon],
    [HotelFeatureGroup.AEROBIC, WellnessFacilitiesIcon],
    [HotelFeatureGroup.POOL, PoolIcon]
]);

export type FeatureIconProps = {
    featureGroupId: number,

    width?: string,
    height?: string,
    className?: string
};

export default function FeatureIcon(props: FeatureIconProps): ReactElement {
    const {
        featureGroupId,

        width,
        height,
        className
    } = props;

    const type: HotelFeatureGroup = featureGroupId;
    const El = iconMap.get(type);

    if (El) {
        return (<El className={className} width={width} height={height} />);
    }

    return (<OtherIcon className={className} width={width} height={height} />);
}