import React, {ReactElement, useCallback, useState} from "react";
import AgreeTermsAndConditionsInitial
    from "../../../common/AgreeTermsModal/AgreeTermsAndConditionInitial/AgreeTermsAndConditionsInitial";
import {useAppSelector} from "redux/hooks";
import styles from "../../../../views/MyCompany/MyCompanyInfo/MyCompanyInfo.module.scss";
import EditBillingDetails from "../../../../views/MyCompany/EditBillingDetails";
import {Credentials} from "proptypes/PropTypeObjects";
import {updateBillingDetails} from "redux/actions/company.actions";
import {useGetBillingDetailsAction} from "components/utils/useCompanyData";

const PostLoginModalsContainer = (): ReactElement => {
    const hasBillingDetails = useAppSelector((state) => state.auth.userData?.company?.hasBillingDetails);
    const [billingDetailsModalVisible, setBillingDetailsModalVisible] = useState<boolean>(false);
    const [billingDetails, setBillingDetails] = useState<Credentials | undefined>(undefined);
    const {getBillingDetails} = useGetBillingDetailsAction(setBillingDetails);

    const postAcceptTermsCallback = useCallback(() => {
        if (!hasBillingDetails) {
            getBillingDetails();
            setBillingDetailsModalVisible(true);
        }
    }, [getBillingDetails, hasBillingDetails]);

    const updateBillingDetailsCallback = useCallback((credentials: Credentials) => {
        updateBillingDetails(credentials);
    }, []);

    return (
        <>
            <AgreeTermsAndConditionsInitial postAccept={postAcceptTermsCallback}/>

            {billingDetailsModalVisible && billingDetails && (
                <EditBillingDetails
                    className={styles.EditBillingDetails}
                    credentials={billingDetails}
                    hasSignedMainAgreement={false}
                    updateBillingDetails={updateBillingDetailsCallback}
                    visible
                    afterLogin={true}
                />
            )}
        </>
    );
};

export default PostLoginModalsContainer;