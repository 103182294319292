import React, {ReactElement} from "react";
import "./Arrow.scss";
import cx from "classnames";

type Props = {
    className?: string;
};

function Arrow({
    className
}: Props): ReactElement {
    return (
        <div className={cx("Arrow", className)}>
            <div className="arrow-container">
                <div className="top-wing" />
                <div className="bottom-wing" />
            </div>
        </div>
    );
}

export default Arrow;
