import React, {CSSProperties, useState} from "react";
import onClickOutside from "react-onclickoutside";
import {ReactComponent as SearchIcon} from "assets/icons/search.svg";
import {useTranslation} from "react-i18next";
import cx from "classnames";
import styles from "./SearchElement.module.scss";
import Input from "../../base/Input/TextInput/Input";

function SearchElement({className, style = {}, inputProps}: {
    inputProps: React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>
    className?: string
    style?: CSSProperties
}) {
    const [isOpen, setIsOpen] = useState(false);
    (SearchElement as any).handleClickOutside = () => setIsOpen(false);
    const {t} = useTranslation();

    return (
        <div style={style} className={cx(styles.Root, className)}>
            <div onClick={() => setIsOpen(!isOpen)} className={styles.Label}>
                <span>{t("se_se_search")}</span>
                <SearchIcon className="ml-2" />
            </div>

            {isOpen && (
                <Input
                    variants={{labelPosition: "inside"}}
                    fullHeight={false}
                    inputProps={inputProps}
                />
            )}
        </div>
    );
}
const clickOutsideConfig = {
    handleClickOutside: () => (SearchElement as any).handleClickOutside
};
export default onClickOutside(SearchElement, clickOutsideConfig);
