import cx from "classnames";
import React, {ReactElement, useMemo} from "react";
import {useTranslation} from "react-i18next";
import _ from "lodash";
import styles from "./SearchBox.module.scss";
import CustomCheckBox from "../../Input/CustomCheckBox";
import {HotelSearchResultsFiltersPropTypes, ReviewRatingFilterPropTypes} from "proptypes/PropTypeObjects";
import Tippy from "../../../common/Tippy/Tippy";
import {useAppSelector} from "redux/hooks";
import Slider from "../../Input/Slider/Slider";

type Props = {
    filters: HotelSearchResultsFiltersPropTypes;
    handleReviewRating: (filter: ReviewRatingFilterPropTypes) => void;
};

function CustomerReviewRating({
    filters,
    handleReviewRating
}: Props): ReactElement {
    const decimalPlaces = 1;
    const step = 0.1;
    const min = 5;
    const max = 10;

    const {t} = useTranslation();

    const filterStatistics = useAppSelector(
        (state) => state.hotelSearchResults.statistics
    );
    const hotelOffers = useAppSelector(
        (state) => state.hotelSearchResults.allHotelOffers
    );
    const hotelOffersSize = hotelOffers?.length || 0;
    const hotelOffersWithReview = useMemo(
        () => hotelOffers?.filter((h) => h.hotel.reviewRating).length,
        [hotelOffers]
    );

    const reviewRatings = [
        {
            value: 9,
            label: t("hsb_sb_rr_9") + ` (${filterStatistics?.reviewRatingCounts[9] || 0})`
        },
        {
            value: 8,
            label: t("hsb_sb_rr_8") + ` (${filterStatistics?.reviewRatingCounts[8] || 0})`
        },
        {
            value: 7,
            label: t("hsb_sb_rr_7") + ` (${filterStatistics?.reviewRatingCounts[7] || 0})`
        },
        {
            value: 6,
            label: t("hsb_sb_rr_6") + ` (${filterStatistics?.reviewRatingCounts[6] || 0})`
        }
    ];

    return (
        <div className={styles.CriteriaItem}>
            <h4 className={cx(styles.Title)}>{t("hsb_sb_rr_title")}</h4>

            {!filters.reviewRatingFilter.slider && (
                <>
                    {reviewRatings.map((value) => (
                        <CustomCheckBox
                            key={value.value}
                            label={value.label}
                            inputProps={{
                                checked: filters.reviewRatingFilter.fromRating.includes(value.value),
                                onChange: (e) => handleReviewRating({...filters.reviewRatingFilter, fromRating: e.target.checked ? [...filters.reviewRatingFilter.fromRating, value.value] : filters.reviewRatingFilter.fromRating.filter((val) => val !== value.value)})
                            }}
                        />
                    ))}
                </>
            )}
            <div className={cx(styles.CriteriaItem)} title={t("hsb_sb_rr_custom_range_tooltip")} style={{textDecoration: "underline"}}>
                <CustomCheckBox
                    label={t("hsb_sb_rr_custom_range_checkbox")}
                    inputProps={{
                        checked: filters.reviewRatingFilter.slider,
                        onChange: (e) => handleReviewRating({...filters.reviewRatingFilter, slider: e.target.checked})
                    }}
                />
            </div>
            {filters.reviewRatingFilter.slider && (
                <>
                    <div className={cx(styles.CriteriaItem)}>
                        <div className={cx(styles.RangeBox)}>
                            <div className={cx(styles.ValueMin)}>
                                <div className={cx(styles.RangeValueLabel)}>{t("hsb_sb_min")}</div>
                                <div className={cx(styles.RangeValue)}>
                                    <input
                                        type="number"
                                        step={step.toString()}
                                        onChange={(e) => {
                                            handleReviewRating({
                                                ...filters.reviewRatingFilter,
                                                range: {
                                                    min: parseFloat(e.target.value),
                                                    max: filters.reviewRatingFilter.range.max
                                                }
                                            });
                                        }}
                                        value={decimalPlaces > 0 ? filters.reviewRatingFilter.range.min : Math.ceil((filters.reviewRatingFilter.range).min)}
                                    />
                                </div>
                            </div>

                            <div className={cx(styles.ValueMax)}>
                                <div className={cx(styles.RangeValueLabel)}>{t("hsb_sb_max")}</div>
                                <div className={cx(styles.RangeValue)}>
                                    <input
                                        type="number"
                                        step={step.toString()}
                                        onChange={(e) => {
                                            handleReviewRating({
                                                ...filters.reviewRatingFilter,
                                                range: {
                                                    min: filters.reviewRatingFilter.range.min,
                                                    max: parseFloat(e.target.value)
                                                }
                                            });
                                        }}
                                        value={decimalPlaces > 0 ? filters.reviewRatingFilter.range.max : Math.ceil((filters.reviewRatingFilter.range).max)}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={cx(styles.CriteriaItem)}>
                        <Slider
                            onChange={(e) => {
                                handleReviewRating({
                                    ...filters.reviewRatingFilter,
                                    range: {min: e.min && _.round((e).min, decimalPlaces) || min, max: e.max && _.round(e.max, decimalPlaces) || max}
                                });
                            }}
                            max={max}
                            min={min}
                            maxValue={max}
                            maxShown={max}
                            step={step / 10}
                            minValue={min}
                            pointsInside={9}
                            intervals={5}
                        />

                    </div>

                </>
            )}
            <div className={cx(styles.CriteriaItem)}>
                <Tippy
                    content={(
                        <div className={styles.CriteriaItemTooltip}>
                            <div>{t("hsb_sb_rr_rating_note_tooltip", {
                                percent: hotelOffersWithReview > 0 ? ((100 * hotelOffersWithReview) / hotelOffersSize).toFixed(1) : 0, total: hotelOffersSize, reviewed: hotelOffersWithReview, remaining: (hotelOffersSize - hotelOffersWithReview)
                            })}
                            </div>
                        </div>
                    )}
                >
                    <div>{t("hsb_sb_rr_rating_note", {percent: ((100 * hotelOffersWithReview) / hotelOffersSize).toFixed(1), total: hotelOffersSize, reviewed: hotelOffersWithReview})}</div>
                </Tippy>
            </div>
        </div>
    );
}

function areEqual(prevProps: Readonly<React.PropsWithChildren<Props>>, nextProps: Readonly<React.PropsWithChildren<Props>>): boolean {
    return prevProps.filters === nextProps.filters;
}

export default React.memo(CustomerReviewRating, areEqual);