import zipkin, {
    BatchRecorder, ExplicitContext, jsonEncoder, sampler, option, TraceId, Tracer, randomTraceId
} from "zipkin";
import {HttpLogger} from "zipkin-transport-http";
import {ReduxZipkinPropTypes} from "../../proptypes/PropTypeRedux";
import ZipkinTypes from "../constants/zipkin.constants";
import BASE_URL from "../../api/constants/baseURL";
import {MyAction} from "../store/store.types";
import fetchWithCookies from "../../utils/fetchCrossOriginWithCookies";

export function createNewTrace(tracer: zipkin.Tracer): void {
    const rootId = tracer.createRootId();

    tracer.setId(new TraceId({
        traceId: rootId.traceId,
        parentId: new option.Some(rootId.spanId),
        sampled: rootId.sampled,
        debug: rootId.isDebug(),
        shared: rootId.isShared()
    }));

    let localSpanId;
    tracer.local("PATH " + window.location.pathname, () => {
        localSpanId = tracer.id.spanId;
    });

    tracer.setId(new TraceId({
        traceId: rootId.traceId,
        spanId: localSpanId,
        sampled: rootId.sampled,
        debug: rootId.isDebug(),
        shared: rootId.isShared()
    }));
}

function getTracer() {
    const tracer = new Tracer({
        ctxImpl: new ExplicitContext(),
        recorder: new BatchRecorder({
            logger: new HttpLogger({
                endpoint: BASE_URL + "/app/spans/",
                jsonEncoder: jsonEncoder.JSON_V2,
                // @ts-ignore
                fetchImplementation: fetchWithCookies
            })
        }),
        sampler: new sampler.CountingSampler(1),
        traceId128Bit: false, //Binary serializer with 128 bit not working correctly
        localServiceName: "HOTELSTON_FRONTEND"
    });

    createNewTrace(tracer);
    return tracer;
}

const initialState = {
    tracer: getTracer()
} as ReduxZipkinPropTypes;

export type ZipkinActionProps = MyAction<ZipkinTypes.SET_TRACKING_ID, { trackingId: string }>;
export default (state = initialState, action: ZipkinActionProps): ReduxZipkinPropTypes => {
    switch (action.type) {
    case ZipkinTypes.SET_TRACKING_ID: {
        state.tracer.setId(state.tracer.createRootId());
        return state;
    }
    default:
        return state;
    }
};
