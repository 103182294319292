import {useCallback, useMemo, useReducer} from "react";
import {createCompanyUser, deleteCompanyUser, updateCompanyUser} from "api/companyAPI";
import {useTranslation} from "react-i18next";
import {CompanyUserPropTypes, CompanyUserWithPassword, ItemModificationResponse} from "../../proptypes/PropTypeObjects";
import {useAppSelector} from "../../redux/hooks";
import {globalAxiosController} from "api/axios/globalAxiosController";

const initialState = {
    sort: undefined,
    pageNr: 1,
    pageSize: 10
} as State;

type State = {
    sort?: SortColumnPropTypes;
    pageNr: number;
    pageSize: number;

    requestingUserAction?: boolean;
    errors?: string[];
};

type Action =
    { type: "CHANGE_SORT", sort: SortColumnPropTypes }
    | { type: "CHANGE_PAGE_NR", pageNr: number }
    | { type: "CHANGE_PAGE_SIZE", pageSize: number }
    ;

type OrderPropTypes =
    "ASC" |
    "DESC" |
    undefined;

type SortColumnPropTypes = {
    key: ColumnKeyPropTypes;
    order: OrderPropTypes;
};

export type ColumnKeyPropTypes =
    "fullName"
    | "email"
    | "phone"
    | "rights";

export const reducer = (state: State, action: Action): State => {
    switch (action.type) {
    case "CHANGE_SORT":
        return {
            ...state,
            sort: action.sort
        };
    case "CHANGE_PAGE_NR":
        return {
            ...state,
            pageNr: action.pageNr
        };
    case "CHANGE_PAGE_SIZE":
        return {
            ...state,
            pageSize: action.pageSize
        };
    default:
        return state;
    }
};

type UseCompanyUsers = State & {
    companyUsersSelected: CompanyUserPropTypes[];
    pagesCount: number;
    handleSort: (sort: SortColumnPropTypes) => void
    handlePageNr: (pageNr: number) => void;
    handlePageSize: (pageSize: number) => void;

};

export function useCompanyUsers(companyUsers: CompanyUserPropTypes[]): UseCompanyUsers {
    const [state, dispatch] = useReducer(reducer, initialState);

    const {
        requestingUserAction, errors, sort, pageNr, pageSize
    } = state;

    const handlePageNr = useCallback((pageNr: number) => dispatch({type: "CHANGE_PAGE_NR", pageNr}), []);
    const handlePageSize = useCallback((pageSize: number) => dispatch({type: "CHANGE_PAGE_SIZE", pageSize}), []);
    const handleSort = useCallback((sort: SortColumnPropTypes) => dispatch({type: "CHANGE_SORT", sort}), []);

    const pagesCount = useMemo(() => (companyUsers && companyUsers.length ? Math.ceil(companyUsers.length / pageSize) : 1), [companyUsers, pageSize]);

    const companyUsersSelected = useMemo(() => {
        if (!companyUsers) return [];
        if (sort) {
            return companyUsers
                .sort((aObj, bObj) => {
                    if (sort.key === "fullName") {
                        return sort.order === "DESC"
                            ? bObj.firstname.localeCompare(aObj.firstname)
                            : aObj.firstname.localeCompare(bObj.firstname);
                    }
                    return sort.order === "DESC"
                        ? bObj[sort.key].localeCompare(aObj[sort.key])
                        : aObj[sort.key].localeCompare(bObj[sort.key]);
                })
                .slice((pageNr - 1) * pageSize, pageNr * pageSize);
        }
        return companyUsers.slice((pageNr - 1) * pageSize, pageNr * pageSize);
    }, [companyUsers, pageNr, pageSize, sort]);
    return {
        companyUsersSelected,
        requestingUserAction,
        errors,
        sort,
        pageNr,
        pageSize,
        pagesCount,
        handlePageNr,
        handlePageSize,
        handleSort
    };
}

const actionState = {
    requesting: false,
    errors: undefined
} as ActionStateProps;

type ActionStateProps = {
    errors?: string[];
    requesting: boolean
};

type ActionProps =
    { type: "REQUEST" }
    | { type: "CLEAR" }
    | { type: "SUCCESS" }
    | { type: "FAILURE", errors: string[] }
    ;

export const actionReducer = (state: ActionStateProps, action: ActionProps): ActionStateProps => {
    switch (action.type) {
    case "CLEAR":
        return {
            ...actionState
        };
    case "REQUEST":
        return {
            ...state,
            errors: undefined,
            requesting: true
        };
    case "SUCCESS":
        return {
            ...state,
            requesting: false
        };
    case "FAILURE":
        return {
            ...state,
            requesting: false,
            errors: action.errors
        };
    default:
        return state;
    }
};

type UseCreateUserAction = ActionStateProps & {
    createUser: (user: CompanyUserWithPassword) => void;
    clearState: () => void;
};

export function useCreateUserAction(onSuccess = (user: CompanyUserPropTypes) => {
}): UseCreateUserAction {
    const [state, dispatch] = useReducer(actionReducer, actionState);

    const locale = useAppSelector((state) => state.locale.currentLocale);
    const {t} = useTranslation();

    const clearState = useCallback(() => {
        dispatch({type: "CLEAR"});
    }, []);

    const createUser = useCallback((user: CompanyUserWithPassword) => {
        dispatch({type: "REQUEST"});
        globalAxiosController.addRequest(createCompanyUser(user, locale))
            .then((data) => {
                if (data && data.success) {
                    const {password, ...userWithoutPassword} = user;
                    onSuccess({
                        ...userWithoutPassword,
                        id: data.id
                    });
                    dispatch({type: "SUCCESS"});
                } else {
                    dispatch({
                        type: "FAILURE",
                        errors: (data && data.errors) || [
                            t("util_ucu_something_went_wrong")
                        ]
                    });
                }
            })
            .catch((error: unknown) => {
                dispatch({
                    type: "FAILURE",
                    errors: error ? [error as string] : [t("util_ucu_something_went_wrong")]
                });
            });
    }, [locale, onSuccess, t]);
    return {
        ...state,
        createUser,
        clearState
    };
}

type UseUpdateUserAction = ActionStateProps & {
    updateUser: (user: CompanyUserWithPassword) => Promise<ItemModificationResponse | undefined>
    clearState: () => void;
};

export function useUpdateUserAction(): UseUpdateUserAction {
    const [state, dispatch] = useReducer(actionReducer, actionState);
    const locale = useAppSelector((state) => state.locale.currentLocale);
    const {t} = useTranslation();

    const clearState = useCallback(() => {
        dispatch({type: "CLEAR"});
    }, []);

    const updateUser = useCallback((user: CompanyUserWithPassword) => {
        dispatch({type: "REQUEST"});
        return globalAxiosController.addRequest(updateCompanyUser(user, locale))
            .then((data) => {
                if (data && data.success) {
                    const {password, emailPassword, ...userWithoutPassword} = user;
                    dispatch({type: "SUCCESS"});
                } else {
                    dispatch({
                        type: "FAILURE",
                        errors: (data && data.errors) || [
                            t("util_ucu_something_went_wrong")
                        ]
                    });
                }

                return data;
            })
            .catch((error: unknown) => {
                dispatch({
                    type: "FAILURE",
                    errors: error ? [error as string] : [t("util_ucu_something_went_wrong")]
                });

                return undefined;
            });
    }, [locale, t]);

    return {
        ...state,
        updateUser,
        clearState
    };
}

type UseDeleteUserAction = ActionStateProps & {
    deleteUser: (id: number) => void;
    clearState: () => void;
};

export function useDeleteUserAction(onSuccess = (id: number) => {
}): UseDeleteUserAction {
    const [state, dispatch] = useReducer(actionReducer, actionState);

    const clearState = useCallback(() => {
        dispatch({type: "CLEAR"});
    }, []);

    const locale = useAppSelector((state) => state.locale.currentLocale);
    const {t} = useTranslation();
    const deleteUser = useCallback((id: number) => {
        dispatch({type: "REQUEST"});
        globalAxiosController.addRequest(deleteCompanyUser(id, locale))
            .then((data) => {
                if (data && data.success) {
                    onSuccess(id);
                    dispatch({type: "SUCCESS"});
                } else {
                    dispatch({
                        type: "FAILURE",
                        errors: (data && data.errors) || [
                            t("util_ucu_something_went_wrong")
                        ]
                    });
                }
            })
            .catch((error: unknown) => {
                dispatch({
                    type: "FAILURE",
                    errors: error ? [error as string] : [t("util_ucu_something_went_wrong")]
                });
            });
    }, [locale, onSuccess, t]);
    return {
        ...state,
        deleteUser,
        clearState
    };
}
