import React, {
    useMemo, useState, useEffect, ReactElement, ReactNode, ReactChild, Dispatch, SetStateAction
} from "react";
import cx from "classnames";
import Tippy from "components/common/Tippy";
import {ReactComponent as CheckIcon} from "assets/icons/check.svg";
import {useTranslation} from "react-i18next";
import styles from "./PasswordValidity.module.scss";

function validatePassword(password: string) {
    const containsUpperCase = /[A-Z]/;
    const containsLowerCase = /[a-z]/;
    const containsTwoNumbers = /\d.*\d/;

    return {
        AT_LEAST_10_CHARS: password.length >= 10,
        CONTAINS_UPPERCASE_LETTER: containsUpperCase.test(password),
        CONTAINS_LOWERCASE_LETTER: containsLowerCase.test(password),
        CONTAINS_NUMBER: containsTwoNumbers.test(password)
    };
}

type Props = {
    password: string;
    className?: string;
    halfInputSize?: number;
    children: any;
    onValidityChange?: (passwordIsValid: boolean) => boolean | any
};

export default function PasswordValidity({
    password,
    className,
    halfInputSize = 25,
    children,
    onValidityChange = (passwordIsValid) => passwordIsValid
}: Props): ReactElement {
    const {t} = useTranslation();
    const [tippyVisible, setVisibleTippy] = useState(false);

    const passwordValidity = useMemo(() => validatePassword(password), [
        password
    ]);
    const passwordIsValid = useMemo(
        () => Object.keys(passwordValidity).every((key) => passwordValidity[key]),
        [passwordValidity]
    );

    useEffect(() => {
        if (password) {
            setVisibleTippy(true);
        } else {
            setVisibleTippy(false);
        }
    }, [password]);

    useEffect(() => {
        onValidityChange(passwordIsValid);
    }, [passwordIsValid, onValidityChange]);

    const tippyContent = (
        <div className={styles.passwordPopup}>
            <div
                className={cx(
                    styles.validityField,
                    passwordValidity.AT_LEAST_10_CHARS && styles.isValid
                )}
            >
                <div className={styles.icon}>
                    <CheckIcon />
                </div>

                {t("util_pv_at_least_8_char_long")}
            </div>

            <div
                className={cx(
                    styles.validityField,
                    passwordValidity.CONTAINS_UPPERCASE_LETTER &&
                    styles.isValid
                )}
            >
                <div className={styles.icon}>
                    <CheckIcon />
                </div>

                {t("util_pv_contains_uppercase_letters")}
            </div>

            <div
                className={cx(
                    styles.validityField,
                    passwordValidity.CONTAINS_LOWERCASE_LETTER &&
                    styles.isValid
                )}
            >
                <div className={styles.icon}>
                    <CheckIcon />
                </div>

                {t("util_pv_contains_lowercase_letters")}
            </div>

            <div
                className={cx(
                    styles.validityField,
                    passwordValidity.CONTAINS_NUMBER && styles.isValid
                )}
            >
                <div className={styles.icon}>
                    <CheckIcon />
                </div>

                {t("util_pv_contains_numbers")}
            </div>
        </div>
    );

    return (
        <div className={cx(className, styles.root)}>
            {children(setVisibleTippy, passwordIsValid)}

            <Tippy visible={tippyVisible} content={tippyContent} zIndex={1000000000000001}>
                <span
                    tabIndex={-1}
                    style={{
                        top: halfInputSize
                    }}
                    className={cx(
                        styles.passwordTip,
                        passwordIsValid ? styles.good : password && styles.bad
                    )}
                >
                    {passwordIsValid ? t("util_pv_good") : password ? t("util_pv_bad") : t("util_pv_min_8_chars")}
                </span>
            </Tippy>
        </div>
    );
}
