import React, {ReactElement} from "react";
import cx from "classnames";
import {HotelSearchResultsFiltersPropTypes} from "../../../../../proptypes/PropTypeObjects";
import {ReactComponent as StarIcon} from "../../../../../assets/icons/star.svg";
import CustomCheckBox from "../../../../../components/base/Input/CustomCheckBox";
import styles from "./HotelCategoryFilter.module.scss";
import {useTranslation} from "react-i18next"

type HotelCategoryFilterProps = {
    onChange: (value: string | number, isActive: boolean) => void;
    filters: HotelSearchResultsFiltersPropTypes;
};

const HotelCategoryFilter = (props: HotelCategoryFilterProps): ReactElement => {
    const {onChange, filters} = props;

    const {t} = useTranslation();

    const spanClasses = {
        allStar: cx({
            item: true,
            active: filters.hotelCategoryFilter?.length === 0
        }),
        fiveStar: cx({
            star: true,
            active: filters.hotelCategoryFilter?.includes(5)
        }),
        fourStar: cx({
            star: true,
            active: filters.hotelCategoryFilter?.includes(4)
        }),
        threeStar: cx({
            star: true,
            active: filters.hotelCategoryFilter?.includes(3)
        }),
        twoStar: cx({
            star: true,
            active: filters.hotelCategoryFilter?.includes(2)
        }),
        oneStar: cx({
            star: true,
            active: filters.hotelCategoryFilter?.includes(1)
        }),
        unrated: cx({
            item: true,
            active: filters.hotelCategoryFilter?.includes(0)
        })
    };

    const starRatings = [
        {
            value: "All",
            label: t("h_s_sb_select_all")
        },
        {
            value: 5,
            label: (
                <>
                    <StarIcon className={spanClasses.fiveStar} />
                    <StarIcon className={spanClasses.fiveStar} />
                    <StarIcon className={spanClasses.fiveStar} />
                    <StarIcon className={spanClasses.fiveStar} />
                    <StarIcon className={spanClasses.fiveStar} />
                </>
            )
        },
        {
            value: 4,
            label: (
                <>
                    <StarIcon className={spanClasses.fourStar} />
                    <StarIcon className={spanClasses.fourStar} />
                    <StarIcon className={spanClasses.fourStar} />
                    <StarIcon className={spanClasses.fourStar} />
                </>
            )
        },
        {
            value: 3,
            label: (
                <>
                    <StarIcon className={spanClasses.threeStar} />
                    <StarIcon className={spanClasses.threeStar} />
                    <StarIcon className={spanClasses.threeStar} />
                </>
            )
        },
        {
            value: 2,
            label: (
                <>
                    <StarIcon className={spanClasses.twoStar} />
                    <StarIcon className={spanClasses.twoStar} />
                </>
            )
        },
        {
            value: 1,
            label: <StarIcon className={spanClasses.oneStar} />
        },
        {
            value: 0,
            label: <span className={spanClasses.unrated}>{t("h_s_sb_unrated")}</span>
        }

    ];

    return (
        <div className={cx(styles.Root)}>
            <div className={cx(styles.Title)}>{t("h_s_sb_category_of_hotels")}</div>
            {starRatings.map((value) => (
                <CustomCheckBox
                    key={value.value}
                    label={value.label}
                    inputProps={{
                        checked: filters.hotelCategoryFilter.includes(value.value) || (filters.hotelCategoryFilter.length === 0 && value.value === "All"),
                        onChange: (e) => onChange(value.value, e.target.checked)
                    }}
                />
            ))}
        </div>
    );
};

export default HotelCategoryFilter;