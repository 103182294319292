import {ReactElement} from "react";
import styles from "./Dots.module.scss";

const Dots = (): ReactElement => (
    <div className={styles.Root}>
        <span/>
        <span/>
        <span/>
    </div>
)

export default Dots;