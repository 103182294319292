import React, {ReactElement, useState} from "react";
import Spinner from "../../base/Loaders/Spinner/Spinner";
import FailureModal from "../ConfirmationModal/FailureModal";

type Props = {
    id: number;
    onClick: (amendmentId: number, callback?: (success: boolean, errors?: string[]) => void) => void;
    text: string;
};

function ActionWithLoader({
    id,
    onClick,
    text
}: Props): ReactElement {
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState<string[] | undefined>(undefined);

    const handleAction = () => {
        setLoading(true);
        onClick(id, (success, errors) => {
            setErrors(errors);
            setLoading(false);
        });
    };

    return (
        <>
            {errors && (
                <FailureModal
                    failureMessage={errors.join(",")}
                    onClose={() => setErrors(undefined)}
                />
            )}
            {!loading && (
                <span
                    onClick={handleAction}
                >
                    {text}
                </span>
            )}
            {loading && (
                <Spinner spinnerStyle={{
                    top: 0,
                    right: "10px",
                    left: "unset"
                }}
                />
            )}
        </>
    );
}

export default ActionWithLoader;
