import {applyMiddleware, compose, createStore} from "redux";
import thunk, {ThunkDispatch, ThunkMiddleware} from "redux-thunk";
import IAppActions from "./store.actions";
import rootReducer from "./rootReducer";
import HotelSearchResultsTypes from "../constants/hotelSearchResults.constants";

export type CommonActionReturnType = (dispatch: AppDispatch, getState: () => RootState) => void; //or use IDispatch
export type AsyncCommonActionReturnType = (dispatch: AppDispatch, getState: () => RootState) => Promise<void>;

export type IAppState = ReturnType<typeof rootReducer>;
export type IAppDispatch = ThunkDispatch<IAppState, any, IAppActions>;// <--here is the magic

const actionSanitizer = (action: IAppActions): IAppActions => (action.type === HotelSearchResultsTypes.SEARCH_RESULTS_RECIEVED && action
    ? {
        ...action,
        afterAllFilters: "<<LONG_BLOB>>",
        hotelOffers: "<<LONG_BLOB>>",
        allHotelOffers: "<<LONG_BLOB>>",
        dataForFilters: "<<LONG_BLOB>>"
    } as unknown as IAppActions : action);

const composeEnhancers = process.env.NODE_ENV !== "production" && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
    //TODO fix dispatch
    // @ts-expect-error fix dispatch
        actionSanitizer,
        stateSanitizer: (state: IAppState) => (state.hotelSearchResults ? {
            ...state,
            hotelSearchResults: {
                ...state.hotelSearchResults,
                afterAllFilters: "<<LONG_BLOB>>",
                dataForFilters: "<<LONG_BLOB>>",
                allHotelOffers: "<<LONG_BLOB>>"
            }
        } : state),

        // DEV TOOLS STACK TRACES
        trace: true,
        traceLimit: 25
    }) as typeof compose //TODO fix
    : compose;

const store = createStore(rootReducer, composeEnhancers(applyMiddleware<IAppDispatch, any>(thunk as ThunkMiddleware<IAppState, IAppActions, any>)));

declare global {
    interface Window {
        __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose;
    }
}

if (process.env.NODE_ENV !== "production") {
    if ((module as any).hot) {
        (module as any).hot.accept("./rootReducer", () => {
            store.replaceReducer(rootReducer);
        });
    }
}

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;