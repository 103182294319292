import React, {ReactElement} from "react";
import {useTranslation} from "react-i18next";
import ConfirmationModal from "./ConfirmationModal";

type Props = {
    onClose: () => void;
    failureMessage: string;
};

export default function FailureModal({
    onClose,
    failureMessage
}: Props): ReactElement {
    const {t} = useTranslation();

    return (
        <ConfirmationModal
            onConfirm={onClose}
            config={{
                variants: {
                    type: "error",
                    withoutDeclineButton: true
                },
                title: t("cm_cf_failed"),
                content: <>{failureMessage}</>,
                confirmButtonText: t("cm_cf_close")
            }}
        />
    );
}
