import React, {ReactElement, useCallback} from "react"
import {useTranslation} from "react-i18next"
import {SharedPaymentTypeProps} from "components/base/Payment/PaymentType/SharedPaymentTypeProps";
import Pricing from "components/common/Pricing/Pricing"
import WalletBlock from "./WalletBlock"
import {useCompanyCurrency, useCurrency} from "components/utils/withLocalesAndCurrencies"
import types from "utils/paymentTransferTypes"
import UserButton from "components/base/UserButton"
import {UserButtonVariant} from "components/base/UserButton/UserButton"
import {useAppDispatch} from "redux/hooks"
import {partialyPayWithWallet} from "redux/actions/payment.actions"
import usePaymentOptions from "components/base/Payment/PaymentType/usePaymentOptions";

export type WalletProps = SharedPaymentTypeProps;

const Wallet = (props: WalletProps): ReactElement => {
    const {
        toggleSelectedPayment,
        paymentTransferTypeEnum
    } = props;

    const {t} = useTranslation();

    const currency = useCurrency();
    const companyCurrency = useCompanyCurrency();
    const dispatch = useAppDispatch();

    const {
        paymentOptions,
        appliedWalletAmount,
        discount,
        voucherSum
    } = usePaymentOptions();

    const wallet = paymentOptions?.paymentTransferTypes.includes(types[paymentOptions?.afterDeadline ? "after" : "before"].wallet);
    if (!paymentOptions || !wallet) {
        return (
            <></>
        );
    }

    const {
        canUseHotelstonWallet,
        hotelstonWalletActivated,
        hotelstonWalletAmountBefore,
        hotelstonWalletAmountAfter,
        afterDeadline,
        paymentPending,
        instantPaymentAfterCXLAvailable,
        allowToBook,
        sanctionedCompany,
        smsFee,
        displaySmsFee,
        salePrice,
        displaySalePrice
    } = paymentOptions;

    const partialPayWithWallet = useCallback((evt: React.MouseEvent) => {
        evt.stopPropagation();
        evt.preventDefault();

        dispatch(partialyPayWithWallet(+paymentOptions.hotelstonWalletAmountBefore, !!appliedWalletAmount));

        // toggleSelectedPayment(paymentTransferTypeEnum!, false);
    }, [appliedWalletAmount, dispatch, paymentOptions.hotelstonWalletAmountBefore]);

    return (
        <WalletBlock
            key="hotelston-wallet"
            canUseHotelstonWallet={canUseHotelstonWallet}
            hotelstonWalletActivated={hotelstonWalletActivated}
            hotelstonWalletAmountBefore={hotelstonWalletAmountBefore}
            hotelstonWalletAmountAfter={hotelstonWalletAmountAfter}
            paymentTransferTypeEnum={paymentTransferTypeEnum}
            afterDeadline={afterDeadline}
            paymentPending={paymentPending}
            instantPaymentAfterCXLAvailable={instantPaymentAfterCXLAvailable}
            toggleSelectedPayment={toggleSelectedPayment}
        >
            <Pricing
                customButton={Number(paymentOptions.hotelstonWalletAmountBefore) < paymentPending && Number(paymentOptions.hotelstonWalletAmountBefore) > 0 && (
                    <UserButton
                        buttonProps={{
                            style: {
                                width: "auto"
                            },
                            onClick: partialPayWithWallet
                        }}
                        text={appliedWalletAmount ? t("pc_pc_undo_partial_pay_wallet") : t("pc_pc_partial_pay_wallet")}
                        variant={UserButtonVariant.SECONDARY}
                    />
                )}
                withButton
                customButtonLabel={t("pc_pc_pay_now")}
                disabledButton={!canUseHotelstonWallet || !allowToBook || sanctionedCompany}
                greyLabels
                smsFee={smsFee}
                displaySmsFee={displaySmsFee}
                salePrice={salePrice}
                displaySalePrice={paymentPending}
                discount={discount}
                voucher={voucherSum.used}
                paymentPending={paymentPending}
            />
        </WalletBlock>
    );
}

export default Wallet;