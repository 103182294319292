import _ from "lodash";
import {CommonActionReturnType} from "redux/store/store.init";
import getHotelSearchResults from "../services/hotelSearch.services";
import * as userAPI from "../../api/userAPI";
import {getDestination} from "api/destinationsAPI";
import {replaceQueryParams, setQueryParams} from "utils/url/queryParams";
import {resetPinnedOffers} from "./pinnedOffers.actions";
import {
    DestinationDestinationPropTypes,
    HotelSearchCriteriaPropTypes,
    HotelStateFormData,
    NationalityPropTypes,
    RecentDestinationPropTypes,
    RecentSearchPropTypes,
    SavedSearchPropTypes
} from "proptypes/PropTypeObjects";
import HotelSearchTypes from "../constants/hotelSearch.constants";
import IAppActions from "../store/store.actions";
import {
    handleInputChange,
    resetMap,
    searchResultsRecieved,
    setFormDataHotelId,
    submitFilters
} from "./hotelSearch.base.actions"
import {toggleModal} from "./modals.actions";
import HotelSearchResultsTypes from "../constants/hotelSearchResults.constants";
import {resetWorkerHashes} from "utils/compute/queueWorker";
import {UrlQueryParams} from "utils/url/queryParamsTypes";
import router from "views/router/router"
import createLuxonDate from "../../utils/date/createLuxonDate"
import MapTypes from "../constants/map.constants"
import {handleFilters} from "./hotelSearchResults.base.actions"
import {globalAxiosController} from "api/axios/globalAxiosController";
import getNationality from "./nationality.actions";
import {on401Error} from "redux/actions/auth.base.actions"

export const initSearch = (): CommonActionReturnType => (dispatch) => {
    dispatch({type: HotelSearchTypes.INIT_SEARCH});
    dispatch({type: HotelSearchResultsTypes.CLEAR_STATE});
    dispatch({type: HotelSearchResultsTypes.REMOVE_MAP_FILTER});
    dispatch({type: MapTypes.RESET_MAP});
    //dispatch(searchResults.resetAllData())
};

export const submitSearch = (): IAppActions => ({type: HotelSearchTypes.SUBMIT_SEARCH});

export const clearCache = (): IAppActions => ({type: HotelSearchResultsTypes.CLEAR_HOTEL_DETAILS_CACHE});

export const setRecentSearches = (recentSearches: RecentSearchPropTypes[]): IAppActions => ({
    type: HotelSearchTypes.GET_RECENT_SEARCHES,
    recentSearches
});

export function getRecentSearches(): CommonActionReturnType {
    return (dispatch, getState) => {
        const locale = getState().locale.currentLocale;

        void globalAxiosController.addRequest(userAPI.getRecentSearch(locale))
            .then((recentSearches) => {
                dispatch(setRecentSearches(recentSearches));
            });
    };
}

export function deleteRecentSearch(searchId: number): CommonActionReturnType {
    return (dispatch) => {
        void globalAxiosController.addRequest(userAPI.deleteRecentSearch(searchId))
            .then(() => dispatch(getRecentSearches()));
    };
}

export function putRecentSearch(): CommonActionReturnType {
    return (dispatch, getState) => {
        const {formData} = getState().hotelSearch;
        const executed = createLuxonDate().toISO();

        void globalAxiosController.addRequest(userAPI.putRecentSearch(formData, executed))
            .then(() => dispatch(getRecentSearches()));
    };
}

export const setSavedSearches = (savedSearches: SavedSearchPropTypes[]): IAppActions => ({
    type: HotelSearchTypes.GET_SAVED_SEARCHES,
    savedSearches
});

export function getSavedSearches(): CommonActionReturnType {
    return (dispatch, getState) => {
        const locale = getState().locale.currentLocale;
        void globalAxiosController.addRequest(userAPI.getSavedSearch(locale))
            .then((savedSearches) => {
                dispatch(setSavedSearches(savedSearches));
            });
    };
}

export function deleteSavedSearch(searchId: number): CommonActionReturnType {
    return (dispatch) => {
        void globalAxiosController.addRequest(userAPI.deleteSavedSearch(searchId))
            .then(() => dispatch(getSavedSearches()));
    };
}

export function putSavedSearch(): CommonActionReturnType {
    return (dispatch, getState) => {
        const {formData} = getState().hotelSearch;
        const executed = createLuxonDate().toISO();

        void globalAxiosController.addRequest(userAPI.putSavedSearch(formData, executed)).then(() => dispatch(getSavedSearches()));
    };
}

export function startSearchFromData(formData: HotelSearchCriteriaPropTypes, destination: DestinationDestinationPropTypes | RecentDestinationPropTypes): CommonActionReturnType {
    return async (dispatch, getState) => {
        const {nationalities} = getState().nationality.nationalities;

        let nationalityInput = "";
        if (nationalities) {
            nationalityInput = nationalities.find((nat) => nat.iso === formData.clientNationality)?.name || "";
        }

        let destinationInput = "";
        if (destination.cityName && destination.countryName) {
            destinationInput = destination.cityName + ", " + destination.countryName;
        } else if (destination.cityName) {
            destinationInput = destination.cityName;
        } else if (destination.countryName) {
            destinationInput = destination.countryName;
        } else if (destination.hotelName) {
            destinationInput = destination.hotelName;
        }

        dispatch(handleInputChange("nationalityInput", nationalityInput));
        dispatch(handleInputChange("destinationInput", destinationInput));

        dispatch(initSearch());
        dispatch(handleFormData(formData));
        dispatch(changeCurrentDestination(destination));

        dispatch(startSearch(false, false));
    };
}

export function startSearchHotel(): CommonActionReturnType {
    return async (dispatch, getState) => {
        const {
            formData,
            stateFormData
        } = getState().hotelSearch;

        const {
            pageNumber,
            size,
            filters,
            sortBy
        } = getState().hotelSearchResults;

        const locale = getState().locale.currentLocale;
        const currency = getState().currency.currentCurrency;
        const {destinationInput} = getState().hotelSearch;

        const newFormData = {
            ...stateFormData,
            clientNationality: stateFormData.clientNationality
                ? stateFormData.clientNationality : formData.clientNationality
        } as HotelSearchCriteriaPropTypes;

        dispatch(initSearch());
        const urlParams =
            {
                in: newFormData.checkIn,
                out: newFormData.checkOut,

                sb: sortBy,
                pn: pageNumber,
                s: size,

                l: locale,
                cr: currency,

                a: newFormData.airportId,
                h: newFormData.hotelId,
                c: newFormData.cityId,

                n: newFormData.clientNationality,
                dest: destinationInput,
                f: filters,

                r: newFormData.rooms
            };

        await setQueryParams("/hotels/search", urlParams)
    };
}

export function startSearch(saveSearch = true, resetState = true): CommonActionReturnType {
    const start = (stateFormData: HotelStateFormData): IAppActions => ({
        type: HotelSearchTypes.START_SEARCH,
        stateFormData
    });
    const failure = (error: string): IAppActions => ({
        type: HotelSearchTypes.GET_DATA_FAILURE,
        error
    });

    resetWorkerHashes();

    return async (dispatch, getState) => {
        const {
            formData,
            formParams,
            stateFormData,
            destinationInput
        } = getState().hotelSearch;
        const {
            pageNumber,
            size,
            stateFilters,
            sortBy,
            view
        } = getState().hotelSearchResults;

        const beforeResetFilters = {
            ...stateFilters
        };
        if (resetState) {
            dispatch(initSearch());
        }

        const {logRequests, multiprovider, ignoreSelectBestOffers} = formParams;

        const locale = getState().locale.currentLocale;
        const currency = getState().currency.currentCurrency;

        dispatch(resetPinnedOffers());
        dispatch(toggleModal());
        await dispatch(submitSearch());
        dispatch(clearCache());
        dispatch(handleFilters(beforeResetFilters));
        await dispatch(submitFilters());
        dispatch(start(stateFormData));
        // dispatch(clearFilters());

        const newFormData = {
            ...stateFormData,
            clientNationality: stateFormData.clientNationality ? stateFormData.clientNationality : formData.clientNationality,
            logRequests,
            multiprovider,
            ignoreSelectBestOffers
        } as HotelSearchCriteriaPropTypes;

        const urlData = {
            in: newFormData.checkIn,
            out: newFormData.checkOut,

            sb: sortBy,
            pn: pageNumber,
            s: size,

            l: locale,
            cr: currency,

            a: newFormData.airportId,
            h: newFormData.hotelId,
            c: newFormData.cityId,

            n: newFormData.clientNationality,
            dest: destinationInput,
            f: stateFilters,
            v: view,

            r: newFormData.rooms,

            m: multiprovider,
            ign: ignoreSelectBestOffers,
            log: logRequests
        } as UrlQueryParams;

        await setQueryParams("/hotels/search", urlData);

        await getHotelSearchResults(newFormData, locale, currency, size)
            .then((data) => {
                if (!router.state.location.pathname.startsWith("/hotels/search") && !router.state.location.pathname.startsWith("/hotels/hotel")) {
                    return;
                }

                if ((newFormData.hotelId && !data.hotelOffers) || (!newFormData.hotelId && (!data.hotelOffers || !data.allHotelOffers))) {
                    // if (newFormData.hotelId) {
                    //     router.navigate({
                    //         pathname: `/hotels/hotel/${newFormData.hotelId}`,
                    //         search: router.state.location.search
                    //     }, {replace: true});
                    //     dispatch(failure("err_search_no_results"));
                    //
                    //     return;
                    // }

                    throw new Error("err_search_no_results");
                }

                resetWorkerHashes();

                dispatch(
                    searchResultsRecieved(
                        data.hotelOffers || [],
                        data.allHotelOffers ? data.allHotelOffers : data.hotelOffers || [],
                        data.allProvidersWithOffers ? data.allProvidersWithOffers : [],
                        data.allHotelOffers ? data.allHotelOffers.length : data.hotelOffers?.length || 0,
                        true
                    )
                );

                // if (formData.hotelId && data.hotelOffers) {
                //     router.navigate({
                //         pathname: `/hotels/hotel/${data.hotelOffers[0].hotel.id}`,
                //         search: router.state.location.search
                //     }, {replace: true});
                // }
            })
            .catch((error) => {
                // console.log(error, "----- error");
                const errorText = error.message ? error.message : String(error);
                if (error.response && error.response.status === 401) {
                    dispatch(on401Error(undefined, "/logout"));
                } else if (error.response && error.response.status && error.response.status !== 200) {
                    router.navigate(`/hotels/error/http${error.response.status}`);
                } else {
                    router.navigate(`/hotels/error/${errorText}`);
                }

                dispatch(failure(errorText));
            });
    };
}

export function searchUpdate(
    doRedirect = true,
    resetHotelId = false,
    updateQueryParams = true,
    performSearch = false
): CommonActionReturnType {
    const update = (stateFormData: HotelStateFormData): IAppActions => ({
        type: HotelSearchTypes.UPDATE_SEARCH,
        stateFormData
    });

    const failure = (error: string): IAppActions => ({
        type: HotelSearchTypes.GET_DATA_FAILURE,
        error
    });

    return async (dispatch, getState) => {
        const {
            formData,
            formParams,
            stateFormData,
            nationalityInput,
            destinationInput,
            currentDestination
        } = getState().hotelSearch;
        const {
            pageNumber,
            size,
            filters,
            sortBy,
            view
        } = getState().hotelSearchResults;

        const {logRequests, multiprovider, ignoreSelectBestOffers} = formParams;

        const locale = getState().locale.currentLocale;
        const currency = getState().currency.currentCurrency;

        dispatch(toggleModal());
        dispatch(resetPinnedOffers());

        if (performSearch) {
            await dispatch(update(stateFormData));
            dispatch(resetMap(false));
        }

        await dispatch(submitSearch());
        dispatch(clearCache());
        await dispatch(submitFilters());

        const newFormData = {
            ...stateFormData,
            clientNationality: stateFormData.clientNationality ? stateFormData.clientNationality : formData.clientNationality,
            logRequests,
            multiprovider,
            ignoreSelectBestOffers
        } as HotelSearchCriteriaPropTypes;

        let newDestinationInput = destinationInput;
        if (!formData.cityId && newFormData.cityId) {
            newDestinationInput = currentDestination?.cityName || destinationInput;
        } else if (!formData.hotelId && newFormData.hotelId) {
            newDestinationInput = currentDestination?.hotelName || destinationInput;
        } else if (!formData.airportId && newFormData.airportId) {
            newDestinationInput = currentDestination?.airportName || destinationInput;
        } else if (!formData.radius && newFormData.radius) {
            newDestinationInput = currentDestination?.radiusName || destinationInput;
        }

        if (updateQueryParams || performSearch) {
            const redirectionUrl = "/hotels/search";
            if (newFormData.hotelId) {
                // redirectionUrl = `/hotels/hotel/${newFormData.hotelId}`;
            }

            await replaceQueryParams(
                doRedirect ? redirectionUrl : router.state.location.pathname,
                {
                    in: newFormData.checkIn,
                    out: newFormData.checkOut,

                    sb: sortBy,
                    pn: pageNumber,
                    s: size,

                    l: locale,
                    cr: currency,

                    a: newFormData.airportId,
                    h: newFormData.hotelId,
                    c: newFormData.cityId,

                    n: newFormData.clientNationality,
                    dest: newDestinationInput,
                    f: filters,
                    v: view,

                    r: newFormData.rooms,

                    m: multiprovider,
                    ign: ignoreSelectBestOffers,
                    log: logRequests
                }
            );
        }

        if (performSearch) {
            getHotelSearchResults(newFormData, locale, currency, size)
                .then((data) => {
                    if (!data.hotelOffers && !data.allHotelOffers) {
                        // dispatch(failure(""));

                        // if (newFormData.hotelId) {
                        //     router.navigate({
                        //         pathname: `/hotels/hotel/${newFormData.hotelId}`,
                        //         search: router.state.location.search
                        //     });
                        //     return;
                        // } else {
                        //     router.navigate({
                        //         pathname: "/hotels",
                        //         search: ""
                        //     });
                        // }
                        router.navigate({
                            pathname: "/hotels",
                            search: ""
                        });

                        dispatch(failure("err_search_no_results"));

                        return;
                        // throw new Error("err_search_no_results");
                    }

                    const firstTimeMounted = getState().hotelSearchResults.firstTimeMounted;

                    dispatch(
                        searchResultsRecieved(
                            data.hotelOffers || [],
                            data.allHotelOffers
                                ? data.allHotelOffers
                                : data.hotelOffers || [],
                            data.allProvidersWithOffers
                                ? data.allProvidersWithOffers
                                : data.allProvidersWithOffers || [],
                            data.allHotelOffers
                                ? data.allHotelOffers.length
                                : data.hotelOffers?.length || 0,
                            updateQueryParams,
                            firstTimeMounted
                        )
                    );

                    // if (doRedirect && newFormData.hotelId && data.hotelOffers) {
                    //     router.navigate({
                    //         pathname: `/hotels/hotel/${data.hotelOffers[0].hotel.id}`,
                    //         search: router.state.location.search
                    //     }, {replace: true});
                    // }

                    if (resetHotelId) {
                        dispatch(setFormDataHotelId(undefined));
                    }
                }).catch((error) => {
                // console.log(error, "----- error");
                    const errorText = error.message ? error.message : String(error);
                    if (error.response && error.response.status === 401) {
                        dispatch(on401Error(undefined, "/logout"));
                    } else if (doRedirect) {
                        if (error.response && error.response.status && error.response.status !== 200) {
                            router.navigate(`/hotels/error/http${error.response.status}`);
                        } else {
                            router.navigate(`/hotels/error/${errorText}`);
                        }
                    }

                    // dispatch(searchResultsRecieved([], [], 0, true, true));
                    dispatch(failure(errorText));

                    if (resetHotelId) {
                        dispatch(setFormDataHotelId(undefined));
                    }
                });
        }
    };
}

export const handleFormData = ({
    checkIn,
    checkOut,
    clientNationality,
    hotelId,
    cityId,
    airportId,
    radius,
    rooms
}: HotelSearchCriteriaPropTypes): CommonActionReturnType => (dispatch, getState) => {
    const dispatchFormData = (formData: HotelSearchCriteriaPropTypes): IAppActions => ({
        type: HotelSearchTypes.HANDLE_FORM_DATA,
        formData
    });
    const dispatchStateFormData = (stateFormData: HotelStateFormData): IAppActions => ({
        type: HotelSearchTypes.UPDATE_FORM_DATA,
        stateFormData
    });

    let formClientNationality = clientNationality;
    if (!getState().nationality.initialized) {
        dispatch(getNationality(true, true));

        formClientNationality = getState().hotelSearch.formData.clientNationality || clientNationality;
    }

    const newFormData = {
        checkIn:
            _.isDate(createLuxonDate(checkIn).toJSDate()) && !_.eq(checkIn, checkOut)
                ? createLuxonDate(checkIn).toFormat("yyyy-MM-dd")
                : createLuxonDate().plus({day: 7}).toFormat("yyyy-MM-dd"),
        checkOut:
            _.isDate(createLuxonDate(checkOut).toJSDate()) && !_.eq(checkIn, checkOut)
                ? createLuxonDate(checkOut).toFormat("yyyy-MM-dd")
                : createLuxonDate().plus({day: 10}).toFormat("yyyy-MM-dd"),
        clientNationality:
            _.isString(formClientNationality)
                ? formClientNationality
                : undefined,
        hotelId: hotelId,
        cityId: cityId,
        airportId: airportId,
        radius: radius,
        rooms:
            _.isArray(rooms) && _.isObject(rooms[0]) && !_.isEmpty(rooms[0])
                ? rooms
                : [
                    {
                        adults: 2,
                        children: []
                    }
                ]
    } as HotelSearchCriteriaPropTypes;

    const stateFormData = {
        checkIn:
            _.isDate(createLuxonDate(checkIn).toJSDate()) && !_.eq(checkIn, checkOut)
                ? createLuxonDate(checkIn).toFormat("yyyy-MM-dd")
                : createLuxonDate().plus({day: 7}).toFormat("yyyy-MM-dd"),
        checkOut:
            _.isDate(createLuxonDate(checkOut).toJSDate()) && !_.eq(checkIn, checkOut)
                ? createLuxonDate(checkOut).toFormat("yyyy-MM-dd")
                : createLuxonDate().plus({day: 10}).toFormat("yyyy-MM-dd"),
        clientNationality:
            _.isString(formClientNationality)
                ? formClientNationality
                : undefined,
        hotelId: hotelId,
        cityId: cityId,
        airportId: airportId,
        radius: radius,
        rooms:
            _.isArray(rooms) && _.isObject(rooms[0]) && !_.isEmpty(rooms[0])
                ? rooms
                : [
                    {
                        adults: 2,
                        children: []
                    }
                ]
    } as HotelStateFormData;

    dispatch(dispatchFormData(newFormData));
    dispatch(dispatchStateFormData(stateFormData));
};

export const handleAddRoom = (): IAppActions => ({type: HotelSearchTypes.ADD_ROOM});

export const handleRemoveRoom = (index: number): IAppActions => ({
    type: HotelSearchTypes.REMOVE_ROOM,
    index
});

export const handleChildrenChange = (i: number, children: number[]): CommonActionReturnType => {
    const dispatchChildrenChange = (stateFormData: HotelStateFormData): IAppActions => ({
        type: HotelSearchTypes.UPDATE_STATE_FORM,
        stateFormData
    });
    return (dispatch, getState) => {
        const {stateFormData} = getState().hotelSearch;
        const {rooms} = stateFormData;
        const arr = rooms.slice();
        arr[i] = {
            ...arr[i],
            children
        };
        const fd = {
            ...stateFormData,
            rooms: [...arr]
        };
        dispatch(dispatchChildrenChange(fd));
    };
};

export const handleAdultsChange = (i: number, adults: number): CommonActionReturnType => {
    const dispatchChildrenChange = (stateFormData: HotelStateFormData): IAppActions => ({
        type: HotelSearchTypes.UPDATE_STATE_FORM,
        stateFormData
    });
    return (dispatch, getState) => {
        const {stateFormData} = getState().hotelSearch;
        const {rooms} = stateFormData;
        const arr = rooms.slice();
        arr[i] = {
            ...arr[i],
            adults
        };
        const fd = {
            ...stateFormData,
            rooms: [...arr]
        };
        dispatch(dispatchChildrenChange(fd));
    };
};

export const changeCurrentDestination = (currentDestination?: DestinationDestinationPropTypes | RecentDestinationPropTypes): IAppActions => ({
    type: HotelSearchTypes.CHANGE_CURRENT_DESTINATION,
    currentDestination
});

export const handleDestinations = (destination: HotelSearchCriteriaPropTypes): CommonActionReturnType => (dispatch, getState) => {
    const {stateFormData} = getState().hotelSearch;
    const dispatchStateFormData = (stateFormData: HotelStateFormData): IAppActions => ({
        type: HotelSearchTypes.UPDATE_FORM_DATA,
        stateFormData
    });
    const sfd = {
        ...stateFormData,
        ...destination
    };
    //dispatch(changeCurrentDestination(destination))
    dispatch(dispatchStateFormData(sfd));
};

export const handleUpdateDestination = (): CommonActionReturnType => (dispatch, getState) => {
    const {cityId} = getState().hotelSearch.formData;
    const locale = getState().locale.currentLocale;
    if (cityId) {
        void globalAxiosController.addRequest(getDestination(cityId, locale))
            .then((dest) => {
                if (dest) {
                    //dispatch(handleDestinations(dest));
                    dispatch(changeCurrentDestination(dest));
                    dispatch(
                        handleInputChange(
                            "destinationInput",
                            `${dest.name}, ${dest.countryName}`
                        )
                    );
                }
            });
    }
};

export const handleNatChange = (clientNationality?: string): CommonActionReturnType => (dispatch, getState) => {
    const {stateFormData} = getState().hotelSearch;
    const dispatchStateFormData = (stateFormData: HotelStateFormData): IAppActions => ({
        type: HotelSearchTypes.UPDATE_FORM_DATA,
        stateFormData
    });
    const sfd = {
        ...stateFormData,
        clientNationality
    } as HotelStateFormData;
    dispatch(dispatchStateFormData(sfd));
};

export const handleDateChange = (checkIn: number | string, checkOut: number | string): CommonActionReturnType => (dispatch, getState) => {
    const {stateFormData} = getState().hotelSearch;
    const {rooms} = stateFormData;
    const dispatchStateFormData = (stateFormData: HotelStateFormData): IAppActions => ({
        type: HotelSearchTypes.UPDATE_FORM_DATA,
        stateFormData
    });
    const sfd = {
        ...stateFormData,
        checkIn: _.isDate(createLuxonDate(checkIn).toJSDate())
            ? createLuxonDate(checkIn).toFormat("yyyy-MM-dd")
            : createLuxonDate().plus({day: 7}).toFormat("yyyy-MM-dd"),
        checkOut: _.isDate(createLuxonDate(checkOut).toJSDate())
            ? createLuxonDate(checkOut).toFormat("yyyy-MM-dd")
            : createLuxonDate().plus({day: 10}).toFormat("yyyy-MM-dd"),
        rooms: rooms
    } as HotelStateFormData;
    dispatch(dispatchStateFormData(sfd));
};

export const handleIgnoreSelectBestOffersChange = (checked?: boolean): IAppActions => ({
    type: HotelSearchTypes.IGNORE_SELECT_BEST_OFFERS_CHANGE,
    checked
});

export const handleMultiproviderChange = (checked?: boolean): IAppActions => ({
    type: HotelSearchTypes.MULTIPROVIDER_CHANGE,
    checked
});

export const handleLogRequestsChange = (checked?: boolean): IAppActions => ({
    type: HotelSearchTypes.LOG_REQUESTS_CHANGE,
    checked
});

export const toggleHotelID = (showHotelId: boolean): IAppActions => ({
    type: HotelSearchTypes.TOGGLE_HOTEL_ID,
    showHotelId
});