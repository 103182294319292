import React, {ReactElement, useMemo} from "react";
import cx from "classnames";
import MainContainer from "components/base/MainContainer";
import BookingStatus from "components/common/BookingStatus";
import TransferRangeBox from "components/common/TransferRangeBox";
import TransferInfo from "components/common/BookingStatus/TransferInfo";
import TransferFlightInfoBox from "components/common/TransferFlightInfoBox";
import BookingInfo from "components/common/BookingStatus/BookingInfo/BookingInfo";
import UserButton from "components/base/UserButton";
import {useTranslation} from "react-i18next";
import BillingDetailsCheck from "components/utils/BillingDetailsCheck";
import _ from "lodash";
import CancelBooking from "../CancelBooking";
import PaymentInfo from "./PaymentInfo/PaymentInfo";
import Overpayment from "./Overpayment";
import PaymentPending from "./PaymentPending";
import Voucher from "./Voucher";
import AmendmentsSideBox from "./AmendmentsSideBox";
import AmendmentsList from "./AmendmentsList";
import styles from "./BookingStatus.module.scss";
import {ClientBookingStatusPropTypes, WebTransferBookingDetails} from "proptypes/PropTypeObjects";
import canCancelBooking from "../../../utils/canCancelBooking";
import {UserButtonVariant} from "../../base/UserButton/UserButton";
import transferStatusStyles from "./TransferStatus.module.scss";
import {useNavigate} from "react-router-dom"
import createLuxonDate from "../../../utils/date/createLuxonDate"
import useDefaultFeatureEnabled from "utils/hooks/useDefaultFeatureEnabled";

type DestinationLabels = {
    bigTextTo?: string;
    smallTextTo?: string;

    bigTextFrom?: string;
    smallTextFrom?: string;
};

type Props = {
    variants?: {
        afterBooking?: boolean;
        afterPayment?: boolean;
        cancellable?: boolean;
        withSidebar?: boolean;
        withInvoices?: boolean;
    };
    data: WebTransferBookingDetails & {
        mainStatus: ClientBookingStatusPropTypes,
        showBusinessCredentials?: boolean
    }
    handlePaymentPending?: (activeOrder: any) => void
    cancelBooking?: (id: number) => void
    setBookingMemo?: (memo: string) => void
    setShowBusinessCredentialsToFalse?: () => void;
    mainContainerClassName?: string;
};

function TransferStatus({
    data,
    variants,
    handlePaymentPending,
    cancelBooking,
    setBookingMemo,
    setShowBusinessCredentialsToFalse,

    mainContainerClassName
}: Props): ReactElement {
    const {t} = useTranslation();
    const {
        id,
        children,
        adults,
        bookingType,
        cxlDate,
        createDate,
        contactPerson,
        arrivalDetails,
        departureDetails,
        currency,
        serviceStartDate,
        serviceEndDate,
        leadPassenger,
        serviceName,
        smsReminderOrdered,
        smsReminderLocale,
        smsReminderNumber,
        mainStatus,
        status,
        reference,
        specificLocationAddress,
        specificLocationName,
        memo,
        transferTypeName,
        origin,
        destination,
        paymentDeadline,
        paymentTransferType,
        paymentPending,
        canUseOverpayment,
        overpayment,
        creditCardFee,
        autoCancel,
        paidAmount,
        price = 0,
        invoices,
        proformaInvoices,
        creditNotes,
        penaltyNotes,
        compensationNotes,
        paymentDeadlineDate,
        paymentDeadlineTime,
        autoCancellationDate,
        autoCancellationTime,
        voucherEnabled,
        voucherEmail,
        amendments,
        showBusinessCredentials,
        invoiceRecipient,
        customInvoice
    } = data;

    const {
        featureEnabled: newTransferSearch,
        featuresLoaded
    } = useDefaultFeatureEnabled("web2NewTransferSearch");

    const navigate = useNavigate();

    const {
        smallTextFrom,
        smallTextTo,
        bigTextFrom,
        bigTextTo
    } = useMemo<DestinationLabels>(() => {
        if (!featuresLoaded) {
            return {};
        }

        let ret: DestinationLabels;
        if (newTransferSearch) {
            if (arrivalDetails && !departureDetails) {
                ret = {
                    smallTextFrom: destination?.countryName,
                    smallTextTo: origin?.countryName,
                    bigTextFrom: destination?.name,
                    bigTextTo: origin?.name
                };
            } else if (!arrivalDetails && departureDetails) {
                ret = {
                    smallTextFrom: destination?.countryName,
                    smallTextTo: origin?.countryName,
                    bigTextFrom: destination?.name,
                    bigTextTo: origin.name
                };
            } else {
                ret = {
                    smallTextFrom: destination?.countryName,
                    smallTextTo: origin?.countryName,
                    bigTextFrom: destination?.name,
                    bigTextTo: origin?.name
                };
            }
        } else if (arrivalDetails) {
            ret = {
                smallTextFrom: origin?.name,
                smallTextTo: destination?.name,
                bigTextFrom: destination?.countryName,
                bigTextTo: origin.countryName
            };
        } else {
            ret = {
                smallTextFrom: destination?.name,
                smallTextTo: origin?.name,
                bigTextFrom: destination?.countryName,
                bigTextTo: origin?.countryName
            };
        }

        if (_.isEmpty(ret.bigTextFrom)) {
            ret.bigTextFrom = ret.smallTextFrom;
            ret.smallTextFrom = undefined;

            ret.bigTextTo = ret.smallTextTo;
            ret.smallTextTo = undefined;
        } else if (_.isEmpty(ret.bigTextTo)) {
            ret.bigTextTo = ret.smallTextTo;
            ret.smallTextTo = undefined;

            ret.bigTextFrom = ret.smallTextFrom;
            ret.smallTextFrom = undefined;
        }

        return ret;
    }, [arrivalDetails, departureDetails, destination?.countryName, destination?.name, featuresLoaded, newTransferSearch, origin?.countryName, origin?.name]);

    const cxlDateFormed = createLuxonDate(cxlDate).toFormat("yyyy-MM-dd");
    const createdTimeAndDate = createLuxonDate(createDate).toFormat("yyyy-MM-dd HH:mm");
    const arrivalFlightDate = arrivalDetails ? createLuxonDate(arrivalDetails.time) : undefined;
    const departureFlightDate = departureDetails ? createLuxonDate(departureDetails.time) : undefined;
    const paymentPendingDaysLeft = Math.floor(createLuxonDate(paymentDeadline).diff(createLuxonDate(), "days").days);

    const sidebar = ((canUseOverpayment && overpayment > 0) || (paymentPending && paymentPending > 0) || voucherEnabled) && (
        <>
            {canUseOverpayment && overpayment > 0 && (
                <Overpayment
                    currency={currency}
                    overpayment={overpayment}
                    id={id}
                />
            )}

            {paymentPending && paymentPending > 0 && handlePaymentPending ? (
                <PaymentPending
                    paymentDeadlineDate={paymentDeadlineDate}
                    paymentDeadlineTime={paymentDeadlineTime}
                    daysLeft={paymentPendingDaysLeft}
                    createDate={createDate}
                    paymentDeadline={paymentDeadline}
                    autoCancellationDate={autoCancellationDate}
                    autoCancellationTime={autoCancellationTime}
                    onPayNow={() => handlePaymentPending({
                        bookingType,
                        contactPerson,
                        currency,
                        cxlDate,
                        id,
                        paymentPending,
                        price,
                        reference,
                        serviceEndDate,
                        serviceStartDate,
                        serviceName,
                        leadPassenger
                    })}
                />
            ) : undefined}

            <Voucher
                email={voucherEmail}
                id={id}
                voucherEnabled={voucherEnabled}
            />

            <AmendmentsSideBox
                bookingType={bookingType}
                id={id}
                amendments={amendments}
                serviceStartDate={serviceStartDate}
                status={status}
            />
        </>
    );

    return (
        <MainContainer
            sidebar={variants?.withSidebar && sidebar}
            containerClassName={cx(mainContainerClassName, transferStatusStyles.Root)}
        >
            {showBusinessCredentials && setShowBusinessCredentialsToFalse && (
                <BillingDetailsCheck
                    onConfirm={setShowBusinessCredentialsToFalse}
                    onDecline={setShowBusinessCredentialsToFalse}
                />
            )}

            <BookingStatus
                box={(
                    <TransferRangeBox
                        dashed
                        smallTextFrom={smallTextFrom}
                        smallTextTo={smallTextTo}
                        bigTextFrom={bigTextFrom}
                        bigTextTo={bigTextTo}
                    />
                )}
                cxlDate={cxlDateFormed}
                createdTimeAndDate={createdTimeAndDate}
                bookingStatus={mainStatus}
                bookingReference={reference}
            >
                <TransferInfo
                    transferName={transferTypeName}
                    pax={adults && children ? adults + children : adults}
                    //leadPassenger={leadPassenger}
                    leadPassenger={leadPassenger}
                    adultsCount={adults}
                    childrenCount={children}
                    specificLocation={{
                        specificLocationAddress,
                        specificLocationName
                    }}
                >
                    <TransferFlightInfoBox
                        className={styles.TransferDetails}
                        simplified={false}
                        withBorder
                        arrivalTransfer={arrivalDetails && arrivalDetails.ordered}
                        arrivalFlightDate={arrivalFlightDate}
                        departureTransfer={departureDetails && departureDetails.ordered}
                        departureFlightDate={departureFlightDate}
                        arrivalDetails={arrivalDetails}
                        departureDetails={departureDetails}
                    />
                </TransferInfo>

                <div
                    className={cx(styles.middleWrapper, !variants?.withSidebar && variants?.withInvoices && styles.noSidebar)}
                >
                    <BookingInfo
                        memo={memo}
                        contactInfo={{
                            title: contactPerson.title,
                            firstname: contactPerson.firstname,
                            lastname: contactPerson.lastname,
                            phone: contactPerson.phone,
                            email: contactPerson.email
                        }}
                        sms={{
                            smsOrdered: smsReminderOrdered,
                            smsReminderNumber: smsReminderNumber,
                            smsLanguage: smsReminderLocale
                        }}
                        customInvoice={customInvoice}
                        invoiceRecipient={invoiceRecipient}
                        bookingStatus={mainStatus}
                        id={id}
                        checkOut={departureFlightDate ? createLuxonDate(departureFlightDate) : undefined}
                        departureTransfer={(departureDetails && departureDetails.ordered) || false}
                    />

                    {variants?.withInvoices && (
                        <PaymentInfo
                            id={id}
                            paymentDeadline={paymentDeadline}
                            paymentTransferType={paymentTransferType}
                            paymentPending={paymentPending}
                            autoCancel={autoCancel}
                            paidAmount={paidAmount}
                            price={price}
                            currency={currency}
                            invoices={invoices}
                            proformaInvoices={proformaInvoices}
                            creditNotes={creditNotes}
                            penaltyNotes={penaltyNotes}
                            compensationNotes={compensationNotes}
                            creditCardFee={creditCardFee}
                        />
                    )}

                    {!!amendments && amendments.length > 0 && (
                        <AmendmentsList
                            amendments={amendments}
                            bookingType={bookingType}
                            id={id}
                            status={status}
                            serviceStartDate={serviceStartDate}
                        />
                    )}
                </div>

                {(variants?.afterBooking || variants?.afterPayment) && (
                    <UserButton
                        text={t("bs_ts_go_to_booking")}
                        variant={UserButtonVariant.PRIMARY}
                        buttonProps={{
                            type: "button",
                            onClick: () => navigate(`/my-bookings/booking/${id}`)
                        }}
                    />
                )}
            </BookingStatus>

            {variants?.cancellable && cancelBooking && canCancelBooking(status, serviceStartDate) && (
                <CancelBooking
                    className={cx(!variants?.withSidebar && transferStatusStyles.NoSidebarCancel)}
                    cxlDate={cxlDate}
                    serviceStartDate={serviceStartDate}
                    onCancel={() => cancelBooking(id)}
                    status={status}
                />
            )}
        </MainContainer>
    );
}

export default TransferStatus;
