import React, {ReactElement} from "react";
import {ReactComponent as LtIcon} from "../assets/icons/flag/lithuania.svg";
import {ReactComponent as BgIcon} from "../assets/icons/flag/bulgaria.svg";
import {ReactComponent as GeIcon} from "../assets/icons/flag/georgia.svg";
import {ReactComponent as ItIcon} from "../assets/icons/flag/italy.svg";
import {ReactComponent as PlIcon} from "../assets/icons/flag/poland.svg";
import {ReactComponent as RuIcon} from "../assets/icons/flag/russia.svg";
import {ReactComponent as EsIcon} from "../assets/icons/flag/spain.svg";
import {ReactComponent as UaIcon} from "../assets/icons/flag/ukraine.svg";
import {ReactComponent as CzIcon} from "../assets/icons/flag/czech.svg";
import {ReactComponent as RoIcon} from "../assets/icons/flag/romania.svg";
import {ReactComponent as PtIcon} from "../assets/icons/flag/portugal.svg";

export default function getCountryFlag(lang: string): ReactElement {
    switch (lang) {
    case "lt":
        return <LtIcon />;
    case "bg":
        return <BgIcon />;
    case "ge":
        return <GeIcon />;
    case "it":
        return <ItIcon />;
    case "pl":
        return <PlIcon />;
    case "ru":
        return <RuIcon />;
    case "es":
        return <EsIcon />;
    case "pt":
        return <PtIcon />
    case "ua":
        return <UaIcon />;
    case "ro":
        return <RoIcon />;
    case "cz":
        return <CzIcon />;
    default: return <></>;
    }
}