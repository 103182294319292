import React, {ReactElement} from "react";
import {useTranslation} from "react-i18next";
import Breadcrumb from "../Breadcrumb/Breadcrumb";
import {BreadcrumbSharedProps} from "../Breadcrumb/Breadcrumb.base";
import {matchPath} from "react-router-dom"

const MyCompanyBreadcrumbs = ({
    location,
    lastLocation
}: BreadcrumbSharedProps): ReactElement => {
    const {t} = useTranslation();

    if (!matchPath("/my-company/*", location.pathname)) {
        return (
            <></>
        );
    }

    return (
        <Breadcrumb
            location={location}
            lastLocation={lastLocation}
            previousLocationLabel={t("b_c_my_company")}
            crumbs={[
                {pathName: "/my-company/user", child: <>{t("b_c_user_settings")}</>},
                {pathName: "/my-company/user/:id", child: <>{t("b_c_user_settings")}</>},
                {pathName: "/my-company/agreement/*", child: <>{t("b_c_agreement")}</>}
            ]}
        />
    );
};

export default MyCompanyBreadcrumbs;