import React, {Component, ComponentType, ReactElement, useEffect} from "react"
import {Outlet, useLocation} from "react-router-dom"
import {ConnectedComponent} from "react-redux"
import {useAppDispatch, useAppSelector} from "redux/hooks"
import {checkLogin} from "redux/actions/auth.actions"
import {on401Error} from "redux/actions/auth.base.actions"

type Props = {
    component: () => ReactElement | Component<any> | ConnectedComponent<ComponentType<any>, any>;
};

const ProtectedRoute = (props: Props): ReactElement | null => {
    const {
        component: Component
    } = props;

    const appDispatch = useAppDispatch();
    const location = useLocation();

    const checkedLogin = useAppSelector((state) => !!state.auth.checkedLogin);
    const loggingIn = useAppSelector((state) => state.auth.loggingIn);
    const loggedIn = useAppSelector((state) => !!state.auth.userData);

    useEffect(() => {
        if (!loggingIn && checkedLogin && !loggedIn && location.pathname !== "/login") {
            appDispatch(on401Error(undefined, "/login"));
        } else if (!checkedLogin) {
            appDispatch(checkLogin());
        }
    }, [appDispatch, checkedLogin, loggedIn, loggingIn]);

    return (
        // @ts-ignore
        checkedLogin && loggedIn ? (<><Component {...props} /><Outlet /></>) : null
    );
}

export default ProtectedRoute;
