import {
    useCallback, useEffect,
    useMemo,
    useRef,
    useState
} from "react";
import {useTranslation, WithTranslation, withTranslation} from "react-i18next";
import {connect, ConnectedProps} from "react-redux";
import _ from "lodash";
import cx from "classnames";
import styles from "./MapWrapper.module.scss";
import GoogleMapContainer from "../../../../../components/common/GoogleMap/GoogleMapContainer";
import {
    changeCenter,
    changePrecalculatedCenter,
    changePrecalculatedZoom,
    changeZoom,
    setFigure
} from "redux/actions/map.actions";
import {RootState} from "redux/store/store.init";
import OneActiveSubject from "../../../../../utils/generic/oneActiveSubject";
import {applyMapFilter, clearFilters} from "redux/actions/hotelSearch.base.actions";
import MarkererClusterer from "./MarkerClusterer/MarkererClusterer";
import DrawingTools from "./DrawingTools/DrawingTools";
import fitMarkersToBounds from "../../../../../utils/maps/fitMarkersToBounds";
import POIContainer from "./POIContainer/POIContainer";
import MapControls from "./MapControls/MapControls";
import MapMarkerProps from "views/Hotels/SearchResults/DetailedView/MapContainer/MapMarkerProps"

//TODO fix props
type Props = WithTranslation & ConnectedProps<typeof connector> & {
    handleFullWidthMap: () => void;
    singleMarkerSubject: OneActiveSubject<void, number>;
};

function MapWrapper(props: Props) {
    const {
        fullWidthMap,
        polygon,
        circle,
        places,

        handleFullWidthMap,

        dataForFilters,

        mapTypeId,

        singleMarkerSubject,

        applyMapFilter,
        changeZoom,
        changeCenter,
        setFigure,

        changePrecalculatedZoom,
        changePrecalculatedCenter,
        clearFilters,
        isUpdating
    } = props;

    const {t} = useTranslation();

    const [scriptLoaded, setScriptLoaded] = useState(false);
    const [map, setMap] = useState<google.maps.Map | null>();
    const [mapLoading, setMapLoading] = useState<boolean>(false);

    const mapHtmlElementRef = useRef<HTMLDivElement | null>(null);
    const fittingRequested = useRef<boolean>(false);
    const userHasMapControl = useRef<boolean>(false);

    const dataForMarkers = useMemo(() => {
        const markersMap = new Map<number, MapMarkerProps>();

        dataForFilters?.filter((item) => item.hotel.coordinates)?.forEach((item) => {
            const cheapest = [...item.roomOffers].sort((val1, val2) => val1[0].price - val2[0].price)[0][0];

            markersMap.set(
                item.hotel.id, {
                    boardType: cheapest.boardType,
                    roomType: cheapest.roomType,
                    reviewRating: item.hotel.reviewRating,

                    coordinates: item.hotel.coordinates,
                    id: item.hotel.id
                }
            );
        });

        return markersMap;
    }, [dataForFilters]);

    const fitMarkers = useCallback((immediate?: boolean, overrideRef?: google.maps.Map) => {
        let usedMap = map;
        if (!usedMap) {
            usedMap = overrideRef;
        }

        if (!usedMap || fittingRequested.current) {
            return;
        }

        fittingRequested.current = true;
        const handler = () => {
            const calculated = fitMarkersToBounds(
                usedMap,
                // null,
                mapHtmlElementRef.current,
                circle,
                polygon,
                places,
                dataForMarkers
            );

            if (calculated) {
                changeZoom(calculated.calculatedZoom);
                changePrecalculatedZoom(calculated.calculatedZoom);

                changeCenter(calculated.calculatedCenter);
                changePrecalculatedCenter(calculated.calculatedCenter);
            }

            void singleMarkerSubject.next(null, 0);
            fittingRequested.current = false;
            setMapLoading(false)
        };

        handler();
    }, [changeCenter, changePrecalculatedCenter, changePrecalculatedZoom, changeZoom, circle, dataForMarkers, map, places, polygon, singleMarkerSubject]);

    const fitMarkersDebounced = useMemo(
        () => _.debounce(fitMarkers, 2000),
        [fitMarkers]
    );

    const fitMarkersToMapCenter = useCallback((immediate?: boolean, overrideRef?: google.maps.Map) => {
        setMapLoading(true);

        fitMarkersDebounced(immediate, overrideRef);
    }, [fitMarkersDebounced]);

    const handleCompleteFigure = useCallback((
        figureName: google.maps.drawing.OverlayType.POLYGON | google.maps.drawing.OverlayType.CIRCLE,
        figure: google.maps.Circle | google.maps.Polygon
    ) => {
        setFigure(figureName, figure);
        applyMapFilter();
    }, [applyMapFilter, setFigure]);

    const onZoomChanged = useCallback((newZoom?: number) => {
        if (map) {
            const currentZoom = map.getZoom();
            if (newZoom && currentZoom !== newZoom) {
                changeZoom(newZoom);
            }
        }
    }, [changeZoom, map]);

    const onGoogleMapsScriptLoaded = useCallback((mapRef: google.maps.Map) => {
        setMap(mapRef);
        setScriptLoaded(true);
    }, []);

    const onMouseOut = useCallback(() => {
        userHasMapControl.current = false;
    }, [])
    const onMouseOver = useCallback(() => {
        userHasMapControl.current = true;
    }, [])

    const throttledGrabUserZoom = useMemo(() => _.debounce(() => {
        if (!map) {
            return;
        }

        if (userHasMapControl.current) {
            changePrecalculatedZoom(map.getZoom());
        }
    }, 250), [changePrecalculatedZoom, map]);

    const throttledGrabUserCenter = useMemo(() => _.debounce(() => {
        if (!map) {
            return;
        }

        if (userHasMapControl.current) {
            changePrecalculatedCenter(map.getCenter());
        }
    }, 250), [changePrecalculatedCenter, map]);

    const onZoomChangedHandler = useCallback(() => {
        onZoomChanged();
        throttledGrabUserZoom();
    }, [onZoomChanged, throttledGrabUserZoom]);

    const clearFiltersCallback = useCallback(() => clearFilters(), [clearFilters]);
    const goBackToSearchCallback = useCallback(() => {
        window.location.href = "/hotels";
    }, []);

    const onClickCallback = useCallback(() => {
        singleMarkerSubject.next(null, 0);
    }, [singleMarkerSubject]);

    const noResultsCustomOverlay = useMemo(() => (
        <div className={styles.NoResultsCustomOverlay}>
            <a
                role="button"
                onClick={clearFiltersCallback}
            >
                {t("g_m_clear_filters")}
            </a>

            <a
                role="button"
                onClick={goBackToSearchCallback}
            >
                {t("g_m_go_back_to_search")}
            </a>
        </div>
    ), [clearFiltersCallback, goBackToSearchCallback, t]);

    useEffect(() => {
        fitMarkersToMapCenter(true);
    }, [dataForMarkers, fitMarkersToMapCenter]);

    useEffect(() => {
        fitMarkersToMapCenter(true);
    }, [circle, fitMarkersToMapCenter, polygon]);

    return (
        <GoogleMapContainer
            onLoad={onGoogleMapsScriptLoaded}
            spinner={mapLoading}
            mapContainerClassName={styles.MapWrapper}
            onMouseOver={onMouseOver}
            onMouseOut={onMouseOut}
            onZoomChanged={onZoomChangedHandler}
            onCenterChanged={throttledGrabUserCenter}
            noResultsOverlay={_.isEmpty(dataForMarkers) && !isUpdating && _.isEmpty(polygon) && _.isEmpty(circle)}
            noResultsCustomOverlay={noResultsCustomOverlay}
            options={{
                disableDefaultUI: true,
                minZoom: 3,
                maxZoom: 18,
                mapTypeId: mapTypeId
                // styles: [
                //     {
                //         featureType: "poi.business",
                //         elementType: "labels",
                //         stylers: [{ visibility: "off" }]
                //     }
                // ]
            }}
            onClick={onClickCallback}
        >
            {scriptLoaded && !isUpdating && (
                <div ref={mapHtmlElementRef} className={cx(fullWidthMap && styles.MapWrapperFullWidth)}>
                    <MapControls handleFullWidthMap={handleFullWidthMap} fitMarkers={fitMarkersToMapCenter} />

                    {/*{!isUpdating && <MarkererClusterer dataForMarkers={dataForMarkers} singleMarkerSubject={singleMarkerSubject} />}*/}
                    {!mapLoading && (
                        <MarkererClusterer
                            dataForMarkers={dataForMarkers}
                            singleMarkerSubject={singleMarkerSubject}
                        />
                    )}

                    <DrawingTools handleCompleteFigure={handleCompleteFigure} />

                    <POIContainer handleFullWidthMap={handleFullWidthMap} fitMarkers={fitMarkersToMapCenter} />
                </div>
            )}
        </GoogleMapContainer>
    );
}

const mapStateToProps = (state: RootState) => ({
    dataForFilters: state.hotelSearchResults.dataForFilters,
    mapTypeId: state.map.mapTypeId,

    circle: state.map.circle,
    polygon: state.map.polygon,
    fullWidthMap: state.map.fullWidthMap,
    places: state.map.places,
    zoom: state.map.zoom,
    center: state.map.center,
    isUpdating: state.hotelSearchResults.isUpdating
});

const connector = connect(mapStateToProps, {
    applyMapFilter,
    changeZoom,
    changeCenter,
    setFigure,

    changePrecalculatedZoom,
    changePrecalculatedCenter,
    clearFilters
});

export default withTranslation()(connector(MapWrapper));
