import React, {ReactElement, useCallback, useEffect, useRef, useState} from "react"
import {PriceRange} from "proptypes/PropTypeObjects";
import SliderScale from "components/base/Input/Slider/SliderScale";
import _ from "lodash";
import styles from "components/base/Input/Slider/Slider.module.scss";

export type SliderProps = {
    min: number;
    max: number;
    maxShown: number;
    intervals?: number;
    pointsInside?: number
    onChange: (value: PriceRange) => void;
    maxValue?: number;
    minValue?: number;
    step: number;
    debounceDelay?: number;
};

function Slider({
    min = 0,
    max = 1000,
    maxShown = 1000,
    intervals = 4,
    pointsInside = 4,
    maxValue,
    minValue,
    step,
    onChange,
    debounceDelay = 1000
}: SliderProps): ReactElement {
    const [innerMinValue, setInnerMinValue] = useState<number>(minValue || min);
    const [innerMaxValue, setInnerMaxValue] = useState<number>((maxValue === maxShown ? max : maxValue) || 0);
    const [waitingTime, setWaitingTime] = useState(debounceDelay);

    const startInputRef = useRef<HTMLInputElement>(null);
    const endInputRef = useRef<HTMLInputElement>(null);

    const onChangeStart = useCallback((evt: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = +evt.target.value;
        if (newValue < innerMaxValue) {
            onChange({
                min: newValue,
                max: innerMaxValue
            });
        }
    }, [innerMaxValue, onChange]);

    const debouncedInnerMinValue = useCallback(_.debounce((newValue) => {
        setInnerMinValue(newValue);
    }, waitingTime), [waitingTime]);

    const onChangeEnd = useCallback((evt: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = +evt.target.value;
        if (newValue > innerMinValue) {
            onChange({
                min: innerMinValue,
                max: (newValue === maxShown || newValue + step === maxShown) ? undefined : newValue
            });
        }
    }, [innerMinValue, maxShown, step, onChange]);

    const debouncedInnerMaxValue = useCallback(
        _.debounce((newValue) => {
            setInnerMaxValue(newValue);
        }, waitingTime),
        [waitingTime]
    );

    const getPercentage = useCallback((val: number) => {
        const distance = Math.abs(maxShown - min);
        const displacement = Math.abs(val - min);
        return Math.min((displacement / distance) * 100, 100);
    }, [maxShown, min]);

    useEffect(() => {debouncedInnerMinValue(minValue === 0 ? 0 : minValue || innerMinValue)}, [minValue, innerMinValue, debouncedInnerMinValue]);

    useEffect(() => {debouncedInnerMaxValue(maxValue? maxValue : innerMaxValue)}, [maxValue, innerMaxValue, debouncedInnerMaxValue]);

    return (
        <>
            <div className={styles.Root}>
                <div className={styles.Track}>
                    <input
                        type="range"
                        className={styles.DragStart}
                        value={innerMinValue}
                        min={min}
                        max={maxShown}
                        onInput={onChangeStart}
                        onMouseDown={() => setWaitingTime(0)}
                        onMouseUp={() => setWaitingTime(debounceDelay)}
                        step={step}
                        ref={startInputRef}
                        style={{
                            zIndex: innerMinValue === maxShown ? 2 : undefined
                        }}
                    />

                    <div
                        style={{
                            width: `${getPercentage(innerMaxValue) - getPercentage(innerMinValue)}%`,
                            marginLeft: `${getPercentage(innerMinValue)}%`
                        }}
                        className={styles.DragTrack}
                    />

                    <input
                        type="range"
                        className={styles.DragEnd}
                        value={innerMaxValue}
                        min={min}
                        max={maxShown}
                        onInput={onChangeEnd}
                        onMouseDown={() => setWaitingTime(0)}
                        onMouseUp={() => setWaitingTime(debounceDelay)}
                        step={step}
                        ref={endInputRef}
                    />
                </div>
            </div>

            {/*<input*/}
            {/*    type="range"*/}
            {/*    min={minValue}*/}
            {/*    max={maxValue}*/}
            {/*    step={step}*/}
            {/*    value={[((minValue && min < minValue) ? minValue : min).toString(), ((maxValue && max > maxValue) ? maxValue : max).toString()]}*/}
            {/*    onChange={(e) => {*/}
            {/*        console.log(e);*/}
            {/*        onChange({*/}
            {/*            min: +e.target.min,*/}
            {/*            max: +e.target.max*/}
            {/*        });*/}
            {/*    }}*/}
            {/*/>*/}

            <SliderScale
                intervals={intervals}
                max={max}
                maxShown={maxShown}
                min={min}
                pointsInside={pointsInside}
            />
        </>
    );
}

export default Slider;
