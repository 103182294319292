import React, {ReactElement} from "react";
import useSubagentsData from "components/utils/useSubagentsData";
import {useTranslation} from "react-i18next";
import SubagentsList from "./SubagentsList";

function SubagentsContainer(): ReactElement {
    const {
        subagentsSelected,
        requestingSubagents,
        sort,
        getSubagents,
        addSubagent,
        updateSubagent,
        handlePageNr,
        handlePageSize,
        handleSort,
        handleSearch,
        search,
        error
    } = useSubagentsData();

    const {t} = useTranslation();

    return (
        <SubagentsList
            loading={requestingSubagents}
            handlePageNr={handlePageNr}
            handlePageSize={handlePageSize}
            handleSort={handleSort}
            handleSearch={handleSearch}
            search={search}
            sort={sort}
            subagents={subagentsSelected}
            addSubagent={addSubagent}
            updateSubagent={updateSubagent}
        />
    );
}

export default SubagentsContainer;
