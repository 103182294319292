import React, {ReactElement} from "react";
import cx from "classnames";
import styles from "./ModalWithOverlay.module.scss";

function ModalWithOverlay({
    children,
    absolute = false
}: any): ReactElement {
    // const [disableScroll, setDisableScroll] = useState(false)

    // useLayoutEffect(()=>{
    //     setDisableScroll(document.body.clientHeight > window.innerHeight)
    // }, [])

    return (
        <>
            {/* <GlobalStyles
               css={disableScroll
               ? `
                html, body {margin: 0; height: 100%; overflow: hidden}
               `
               : ``
               }
            /> */}
            <div className={styles.overlay} />
            <div
                id="modal-container"
                className={cx(styles.container, absolute && styles.absolute)}
            >
                <div>{children}</div>
            </div>
        </>
    );
}

export default ModalWithOverlay;
