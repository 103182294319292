import React, {ReactElement, ReactNode} from "react";
import {ReactComponent as ErrorIcon} from "assets/icons/cross.svg";
import {ReactComponent as CheckIcon} from "assets/icons/check.svg";
import {ReactComponent as PendingPaymentIcon} from "assets/icons/payment pending.svg";
import {useTranslation} from "react-i18next"
import cx from "classnames";
import styles from "./BookingStatus.module.scss";
import {ClientBookingStatusPropTypes} from "proptypes/PropTypeObjects";
import useLocaleDateTimeFormat, {LocaleDateFormatTypeEnum} from "../../utils/Internationalization/useLocaleDateTimeFormat";
import getLabelFromEnum from "../../../utils/getLabelFromEnum";
import {convertToServerTime} from "utils/dateUtils";
import createLuxonDate from "../../../utils/date/createLuxonDate";
import {TFunction} from "i18next";

function getBookingStatusIcon(bookingStatus: ClientBookingStatusPropTypes | "SUCCESS" | "FAILURE") {
    switch (bookingStatus.toUpperCase()) {
    case "SUCCESS":
        return <CheckIcon />;
    case "FAILURE":
        return <ErrorIcon />;
    case "REJECTED":
        return <ErrorIcon />;
    case "CONFIRMED":
        return <CheckIcon />;
    case "CONFIRMATION_PENDING":
        return <PendingPaymentIcon />;
    case "PAYMENT_PENDING":
        return <PendingPaymentIcon />;
    case "CANCELLED":
        return <ErrorIcon />;
    default:
        return false;
    }
}
function getBookingStatusTitle(bookingStatus: ClientBookingStatusPropTypes | "SUCCESS" | "FAILURE" | "success" | "failure", t: TFunction): ReactElement | string {
    switch (bookingStatus) {
    case "SUCCESS":
        return t("bs_bs_successful_booking");
    case "success":
        return t("bs_bs_successful_booking");
    case "FAILURE":
        return <span className="error-color">{t("bs_bs_booking_failed")}</span>;
    case "failure":
        return <span className="error-color">{t("bs_bs_booking_failed")}</span>;
    case "CONFIRMED":
        return t("bs_bs_booking_confirmed");
    case "PAYMENT_PENDING":
        return t("bs_bs_booking_payment_pending");
        // case "NEW":
        //     return i18n.t("bs_bs_booking_new");
    case "REJECTED":
        return <span className="error-color">{t("bs_bs_booking_rejected")}</span>;
    case "CONFIRMATION_PENDING":
        return t("bs_bs_booking_confirmation_pending");
    case "CANCELLATION_PENDING":
        return t("bs_bs_booking_cancellation_pending");
    case "CANCELLED":
        return t("bs_bs_booking_canceled");
    default:
        return getLabelFromEnum(bookingStatus);
    }
}

type Props = {
    box: ReactNode;
    children: ReactNode;
    bookingStatus: ClientBookingStatusPropTypes;
    bookingReference: string;
    cxlDate: string;
    createdTimeAndDate: string;
};

function BookingStatus({
    box, children, bookingStatus, bookingReference = "", cxlDate, createdTimeAndDate
}: Props): ReactElement {
    const {t} = useTranslation();

    const statusMessage = getBookingStatusTitle(bookingStatus, t);
    const statusIcon = getBookingStatusIcon(bookingStatus);
    const dateFormat = useLocaleDateTimeFormat(LocaleDateFormatTypeEnum.DATE);
    const dateTimeFormat = useLocaleDateTimeFormat(LocaleDateFormatTypeEnum.DATE_TIME);

    return (
        <div className={styles.Root}>
            <div className={styles.Top}>
                <div className={styles.Icon}>{statusIcon}</div>
                <div className={cx(styles.Status,
                    (bookingStatus === "CONFIRMATION_PENDING" ||
                        bookingStatus === "PAYMENT_PENDING" ||
                        bookingStatus === "CANCELLATION_PENDING") && styles.StatusPending,
                    (bookingStatus === "REJECTED" || bookingStatus === "CANCELLED") && styles.StatusRejected)}
                >{statusMessage}
                </div>
                <h3 className={styles.Code}>{t("bs_bs_booking")} {bookingReference}</h3>

                <div className={styles.Info}>
                    <span className={styles.Created}>{t("bs_bs_created")}: {createLuxonDate(createdTimeAndDate).toFormat(dateTimeFormat)}</span>
                    <span className={styles.CXL}>{t("bs_bs_cxl")}: {createLuxonDate(convertToServerTime(cxlDate)).toFormat(dateFormat)}</span>
                </div>

                {box}
            </div>

            {children}
        </div>
    );
}
export default BookingStatus;
