import {DateTime, IANAZone, Settings} from "luxon"
import _ from "lodash";
import {FALLBACK_DATE_FORMAT, FALLBACK_DATE_TIME_FORMAT} from "components/utils/Internationalization/formats";

Settings.defaultZone = new IANAZone("Europe/Vilnius");
Settings.defaultLocale = "lt-LT";

function isIsoDate(str: string) {
    if (!/\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}Z/.test(str)) return false;
    const d = new Date(str);
    return d instanceof Date && !isNaN(d.getTime()) && d.toISOString() === str; // valid date
}

function isShortISODate(str: string) {
    return !/\d{4}-\d{2}-\d{2}/.test(str);
}

export default function createLuxonDate(val?: DateTime | string[] | string | number | null | undefined): DateTime {
    if (val === undefined || val === null) {
        return DateTime.now();
    }

    if (val instanceof DateTime) {
        // see comment on immutability: https://github.com/moment/luxon/blob/master/docs/moment.md

        // if (val.zone !== Settings.defaultZone) {
        //     val.setZone(Settings.defaultZone);
        // }

        return val;
    }

    if (_.isArray(val)) {
        return createLuxonDate(val[0]);
    }

    // problematic datetimes
    let fromPrimitive: DateTime | null = null;
    if (_.isNumber(val)) {
        return DateTime.fromMillis(val);
    }

    if (_.isString(val)) {
        if (isShortISODate(val)) {
            return DateTime.fromJSDate(new Date(`${val} 00:00`));
        }

        let triedStringConversion = false;
        if (DateTime.fromFormat(val, FALLBACK_DATE_FORMAT).isValid) {
            fromPrimitive = DateTime.fromFormat(val, FALLBACK_DATE_FORMAT);
            triedStringConversion = true;
        }

        if (DateTime.fromFormat(val, FALLBACK_DATE_TIME_FORMAT).isValid) {
            fromPrimitive = DateTime.fromFormat(val, FALLBACK_DATE_TIME_FORMAT);
            triedStringConversion = true;
        }

        if (!triedStringConversion || (triedStringConversion && !fromPrimitive?.isValid)) {
            fromPrimitive = DateTime.fromISO(val);
        }
    }

    return fromPrimitive || createLuxonDate();
}