import React, {ReactElement, useState, useCallback, useEffect} from "react";
import cx from "classnames";
import CustomCheckBox from "components/base/Input/CustomCheckBox";
import {useTranslation} from "react-i18next";
import styles from "./Righter.module.scss";
import {
    withCurrencies, WithCurrenciesProps, withLocales, WithLocalesProps
} from "../../utils/withLocalesAndCurrencies";
import RighterDropdown from "./RighterDropdown";
import NotificationsDropdown from "./NotificationsDropdown/NotificationsDropdown";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import {toggleHotelID} from "redux/actions/hotelSearch.actions";

type LocalesDropdownProps = WithLocalesProps & {
    variants: {
        normal?: boolean;
        transparent?: boolean;
        authPages?: boolean;
        dark?: boolean;
    };
};

const LocalesDropdown = withLocales(({
    changeCurrentLocale,
    currentLocale,
    localesFormed,
    variants
}: LocalesDropdownProps) => (
    <RighterDropdown
        onDropdownChange={changeCurrentLocale}
        dropDownValue={currentLocale}
        className={cx("locale", !!variants.transparent && "transparent", !!variants.dark && "dark", variants.authPages && "localeAuthPage")}
        options={localesFormed}
    />
), {labelAsValue: true});

type WrappedWithCurrenciesDropdownProps = WithCurrenciesProps & {
    variants: {
        normal?: boolean;
        transparent?: boolean;
        authPages?: boolean;
        dark?: boolean;
    };
};

const CurrenciesDropdown = withCurrencies(({
    changeCurrentCurrency,
    currentCurrency,
    currenciesFormed,
    variants
}: WrappedWithCurrenciesDropdownProps) => (
    <RighterDropdown
        onDropdownChange={changeCurrentCurrency}
        dropDownValue={currentCurrency}
        className={cx("currency", !!variants.transparent && "transparent")}
        options={currenciesFormed}
    />
), {labelAsValue: true});

type Props = {
    variants?: {
        normal?: boolean;
        transparent?: boolean;
        authPages?: boolean;
        dark?: boolean;
    }
};

function Righter({
    variants = {
        normal: true
    }
}: Props): ReactElement {
    const {i18n} = useTranslation();
    const [i18nKeysMode, setI18KeysMode] = useState(false);
    const [notificationsOpen, setNotificationsOpen] = useState(false);
    const currentLocale = useAppSelector((state) => state.locale.currentLocale);
    const ownUser = useAppSelector((state) => state.auth.userData?.companyUser.ownUser);
    const showHotelId = useAppSelector((state) => state.hotelSearch?.showHotelId);

    const dispatch = useAppDispatch();

    useEffect(() => {
        if (i18nKeysMode) {
            void i18n.changeLanguage("cimode");
        } else {
            void i18n.changeLanguage(currentLocale);
        }
    }, [i18nKeysMode, i18n, currentLocale]);

    const handleChangeHotelIdsView = useCallback((showHotelId: boolean) => {
        dispatch(toggleHotelID(showHotelId));
    }, [dispatch]);

    return (
        <div
            className={cx(!!variants.normal && styles.root, !!variants.transparent && styles.transparent, !!variants.authPages && styles.authPages, !!variants.dark && styles.dark)}
        >
            <div className={styles.wrapper}>
                {!variants.authPages && (
                    <NotificationsDropdown
                        open={notificationsOpen}
                        setOpen={setNotificationsOpen}
                    />
                )}
                <LocalesDropdown variants={{
                    ...variants,
                    transparent: false
                }}
                />
                {!variants.authPages && (
                    <CurrenciesDropdown variants={variants} />
                )}
                {ownUser && (
                    <>
                        <div className={styles.i18nControl}>
                            <div className={`d-flex ${styles.position}`}>
                                <CustomCheckBox
                                    label="i18n"
                                    inputProps={{
                                        checked: i18nKeysMode,
                                        onChange: (e) => setI18KeysMode(e.target.checked)
                                    }}
                                />
                            </div>
                        </div>
                        <div className={styles.i18nControl}>
                            <div className={`d-flex ${styles.position} ${styles.position2ndCheckbox}`}>
                                <CustomCheckBox
                                    label="h_id"
                                    inputProps={{
                                        checked: showHotelId,
                                        onChange: (e) => handleChangeHotelIdsView(e.target.checked)
                                    }}
                                />
                            </div>
                        </div>
                    </>

                )}

            </div>
        </div>
    );
}

export default Righter;
