import React, {ReactElement, useEffect} from "react";
import {useAppDispatch, useAppSelector} from "redux/hooks";
import MainContainer from "components/base/MainContainer";
import {requestSplitPriceSessionValidation} from "redux/actions/payment.actions";
import {useParams, useSearchParams} from "react-router-dom";
import SplitPaymentContent from "components/common/Payment/PaymentContent/SplitPaymentContent/SplitPaymentContent";
import SplitPaymentSummary from "./SplitPaymentSummary/SplitPaymentSummary";

const SplitPayment = (): ReactElement => {
    // usePaymentType(ReduxPaymentTypePropTypes.SPLIT_PRICE_PART_PAYMENT);
    const [searchParams] = useSearchParams();
    const {splitPaymentId, splitPaymentPart} = useParams();
    const dispatch = useAppDispatch();

    const splitPriceParts = useAppSelector((state) => state.payment.splitPriceParts);
    const splitPriceBookings = useAppSelector((state) => state.payment.splitPriceBookings);

    useEffect(() => {
        if (splitPaymentId && !splitPriceParts) {
            const failure = searchParams.get("failure");
            dispatch(requestSplitPriceSessionValidation(Number(splitPaymentId), splitPaymentPart ? Number(splitPaymentPart) : undefined, Boolean(failure)));
        }
    }, [dispatch, searchParams, splitPaymentId, splitPaymentPart, splitPriceParts]);

    return (
        <MainContainer
            sidebar={splitPriceBookings && (
                <SplitPaymentSummary/>
            )}
        >
            <SplitPaymentContent/>
        </MainContainer>
    );
}

export default SplitPayment;