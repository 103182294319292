import React, {ReactElement} from "react";
import {useTranslation} from "react-i18next";
import AgreeTermsModal, {AgreeTermsModalSharedProps} from "../AgreeTermsModal";
import {ExportedDocumentTypeEnum} from "../../../../api/companyDocumentsAPI";

export default function AgreeWalletTerms({
    onConfirm,
    onClose
}: AgreeTermsModalSharedProps): ReactElement {
    const {t} = useTranslation();

    return (
        <AgreeTermsModal
            onConfirm={onConfirm}
            onClose={onClose}
            title={t("atm_atm_terms_n_conditions")}
            content={t("atm_atm_terms")}
            agreeButtonText={t("atm_atm_activate_wallet")}
            agreeWithTermsText={t("atm_atm_agree_to_terms_and_conditions")}
            exportedFileType={ExportedDocumentTypeEnum.WALLET_TERMS_AND_CONDITIONS}
            withFooter
        />
    );
}