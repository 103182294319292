import toRad from "./toRad";

export default function getDistancesBetweenCoordinates(
    lat1: number,
    lon1: number,
    lat2: number,
    lon2: number
): number {
    const R = 6371; //Earth radius in km

    const dLat = toRad((lat2 - lat1));
    const dLon = toRad((lat2 - lat1));

    const lat1Radians = toRad(lat1);
    const lat2Radians = toRad(lat2);

    const a = Math.sin(dLat / 2) * Math.sin(dLat / 2) + Math.sin(dLon / 2) * Math.sin(dLon / 2) * Math.cos(lat1Radians) * Math.cos(lat2Radians);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    return R * c;
}