import {useEffect, useState} from "react";
import {useAppSelector} from "redux/hooks";

export type UseDefaultFeatureReturn = {
    featureEnabled: boolean;
    featuresLoaded: boolean;
};

export default function useDefaultFeatureEnabled(feature: string): UseDefaultFeatureReturn {
    const defaultFeatures = useAppSelector((state) => state.auth.userData?.defaultFeatures);
    const [featureEnabled, setFeatureEnabled] = useState<boolean>(false);
    const [featuresLoaded, setFeaturesLoaded] = useState<boolean>(false);

    useEffect(() => {
        if (defaultFeatures === undefined) {
            setFeatureEnabled(false);
            return;
        }

        if (!featuresLoaded) {
            setFeaturesLoaded(true);
        }

        setFeatureEnabled(!!defaultFeatures.find((featureItem) => featureItem === feature));
    }, [defaultFeatures, feature, featuresLoaded]);

    return {
        featureEnabled,
        featuresLoaded
    };
}