import {CountryResult, PhoneCodesResult} from "proptypes/PropTypeObjects";
import {ConstrainedAxiosRequestConfig} from "./axios/constrainedAxiosRequestConfig";

export default function getCountries(locale: string): ConstrainedAxiosRequestConfig<CountryResult> {
    return {
        method: "get",
        url: "/app/country/",
        headers: {
            Accept: "application/json"
        },
        params: {
            locale
        },
        maxNumRequests: 1
    };
}

export function getPhoneCodes(locale: string): ConstrainedAxiosRequestConfig<PhoneCodesResult> {
    return {
        method: "get",
        url: "/app/country/phoneCodes",
        headers: {
            Accept: "application/json"
        },
        params: {
            locale
        },
        maxNumRequests: 1
    };
}