import React from "react";
import {connect} from "react-redux";
import cx from "classnames";
import HotelBox from "./HotelBox";
import PageControl from "../../../../components/common/PageControl";
import styles from "./SimpleView.module.scss";
import {HotelOfferPropTypes} from "../../../../proptypes/PropTypeObjects";
import {resetMap} from "../../../../redux/actions/hotelSearch.base.actions";

type Props = {
    hotelOffers: HotelOfferPropTypes[];
    isActivePinnedOffers: boolean;

    size?: number;
    pageSizeOptions: { value: number, label: number }[];
    handlePageSizeChange: (size: number, hotelDetails?: boolean, updateQueryParam?: boolean) => void;
    pageNumber: number;
    pagesCount: number;
    handleChangePage: (page: number, hotelDetails?: boolean, updateQueryParam?: boolean) => void;

    className: string;
    resetMap: (hotelDetails?: boolean, updateQueryParams?: boolean) => void
};

class SimpleView extends React.Component<Props> {
    componentDidMount() {
        const {resetMap} = this.props;

        resetMap();
    }

    render() {
        const {
            className,
            hotelOffers,
            handleChangePage,
            pagesCount,
            pageNumber,
            size,
            pageSizeOptions,
            handlePageSizeChange,
            isActivePinnedOffers
        } = this.props;

        return (
            <div className={cx(styles.SimpleView, className)}>
                {
                    hotelOffers.map((hotel: HotelOfferPropTypes) => (
                        <HotelBox
                            key={hotel.hotel.id}
                            hotel={hotel.hotel}
                            roomOffers={hotel.roomOffers}
                            isActivePinnedOffers={isActivePinnedOffers}
                        />
                    ))
                }

                <PageControl
                    handleChangePage={handleChangePage}
                    pagesCount={pagesCount}
                    pageNumber={pageNumber}
                    size={size}
                    pageSizeOptions={pageSizeOptions}
                    handlePageSizeChange={handlePageSizeChange}
                    reverse
                />
            </div>
        );
    }
}

export default connect(null, {resetMap})(SimpleView);