import {ItemModificationResponse, SpecialRequestsPropTypes} from "../proptypes/PropTypeObjects";
import formatToLTDate from "../utils/internationalization/formatToLTDate";
import {ConstrainedAxiosRequestConfig} from "./axios/constrainedAxiosRequestConfig";

export default function specialRequestsAmendmentRequest(formData: SpecialRequestsPropTypes, id: number, locale: string): ConstrainedAxiosRequestConfig<ItemModificationResponse, SpecialRequestsPropTypes> {
    return {
        method: "put",
        url: `/app/amendment/hotel/${id}/specialRequests`,
        data: {
            ...formData,
            lateArrivalTime: formatToLTDate(formData.lateArrivalTime)
        },
        headers: {
            "Content-Type": "application/json",
            Accept: "application/json"
        },
        params: {
            locale
        },
        maxNumRequests: 1
    };
}
