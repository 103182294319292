import {CurrencyTypes} from "../constants/currency.constants";
import {ReduxCurrencyPropTypes} from "proptypes/PropTypeRedux";
import CurrencyActionTypes from "./actions/currency.reducer.actions";
import Currency from "@hotelston_web_frontend_parent/constants/currency";

const currencies = {
    currencies: [
        "EUR"
    ]
};

export const INITIAL_STATE = {
    currencies,
    currentCurrency: Currency.EUR
} as ReduxCurrencyPropTypes;

export default (state = INITIAL_STATE, action: CurrencyActionTypes): ReduxCurrencyPropTypes => {
    switch (action.type) {
    case CurrencyTypes.REQ_CURRENCIES:
        return {...state};
    case CurrencyTypes.GET_CURRENCIES_SUCCESS:
        return {...state, currencies: action.currencies};
    case CurrencyTypes.GET_CURRENCIES_FAILURE:
        return {...state, error: action.error};
    case CurrencyTypes.CHANGE_CURRENCY:
        return {...state, currentCurrency: action.currentCurrency};
    default:
        return state;
    }
};
