import React, {ReactElement} from "react";
import cx from "classnames";
import {HotelSearchResultsFiltersPropTypes} from "../../../../../proptypes/PropTypeObjects";
import CustomCheckBox from "../../../../../components/base/Input/CustomCheckBox";
import styles from "./SpecialOffersAndCancellationPolicyFilter.module.scss";
import {useTranslation} from "react-i18next"

type SpecialOffersAndCancellationPolicyFilterProps = {
    filters: HotelSearchResultsFiltersPropTypes;
    onToggleGoodCXL: (checked: boolean) => void;
    onToggleOnlyRefundable: (checked: boolean) => void;
    onToggleOnlyNonRefundable: (checked: boolean) => void;
    onToggleSpecialOffers: (checked: boolean) => void;
    onToggleMemberOnly: (checked: boolean) => void;
};

const SpecialOffersAndCancellationPolicyFilter = (props: SpecialOffersAndCancellationPolicyFilterProps): ReactElement => {
    const {
        filters,
        onToggleOnlyRefundable: handleOnlyRefundableFilter,
        onToggleGoodCXL: handleOnlyGoodCXLFilter,
        onToggleOnlyNonRefundable: handleOnlyNonRefundableFilter,
        onToggleSpecialOffers: handleSpecialOffersFilter,
        onToggleMemberOnly: handleMemberOnlyFilter
    } = props;

    const {t} = useTranslation();

    return (
        <div className={cx(styles.Root)}>
            <div className={cx(styles.Title)}>{t("h_s_sb_special_offer")}</div>
            <CustomCheckBox
                label={t("h_s_sb_show_specialOffers_only")}
                inputProps={{
                    checked: filters.specialOffersFilter,
                    onChange: (e) => handleSpecialOffersFilter(e.target.checked),
                    name: "specialOffers"
                }}
                className="so-group"
            />

            <CustomCheckBox
                inputProps={{
                    name: "memberOnly",
                    checked: filters.memberOnlyFilter,
                    onChange: (e) => handleMemberOnlyFilter(e.target.checked)
                }}
                className="so-group"
                label={t("h_s_sb_show_memberOnly_only")}
            />

            <div className={cx(styles.Title)}>{t("h_s_sb_refundable")}</div>
            <CustomCheckBox
                inputProps={{
                    name: "onlyGoodCXL",
                    checked: filters.onlyGoodCXLFilter,
                    onChange: (e) => handleOnlyGoodCXLFilter(e.target.checked)
                }}
                className="cp-group"
                label={t("h_s_sb_show_goodCXL_only")}
            />
            <CustomCheckBox
                inputProps={{
                    name: "onlyRefundable",
                    checked: filters.onlyRefundableFilter,
                    onChange: (e) => handleOnlyRefundableFilter(e.target.checked)
                }}
                className="cp-group"
                label={t("h_s_sb_show_refundable_only")}
            />
            <CustomCheckBox
                inputProps={{
                    name: "onlyNonRefundable",
                    checked: filters.onlyNonRefundableFilter,
                    onChange: (e) => handleOnlyNonRefundableFilter(e.target.checked)

                }}
                className="cp-group"
                label={t("h_s_sb_show_nonRefundable_only")}
            />
        </div>
    );
};

export default SpecialOffersAndCancellationPolicyFilter;