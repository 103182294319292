import {useAppSelector} from "redux/hooks";
import {useMemo} from "react"
import {ReduxPaymentTypePropTypes} from "proptypes/PropTypeRedux"

export type UseSelectedMarkupReturn = {
    hotelAmount: number;
    transferAmount: number;
    isNETPrice: boolean;
};

export default function useSelectedMarkup(): UseSelectedMarkupReturn {
    const companyMarkups = useAppSelector((state) => state.auth.userData?.companyMarkups || []);
    const currentSelectedMarkupIndex = useAppSelector((state) => state.auth.currentCompanyMarkupIndex || 0);
    const isNETPrice = companyMarkups[currentSelectedMarkupIndex]?.id === -1;
    const isAnonymousMultiBooking = useAppSelector((state) => state.payment.type === ReduxPaymentTypePropTypes.ANONYMOUS_MULTIBOOKING);

    return useMemo(() => {
        if (isAnonymousMultiBooking || companyMarkups.length === 0) {
            return {
                hotelAmount: 1,
                transferAmount: 1,
                isNETPrice: false
            };
        }

        return {
            hotelAmount: companyMarkups[currentSelectedMarkupIndex].hotelAmount || 1,
            transferAmount: companyMarkups[currentSelectedMarkupIndex].transferAmount || 1,
            isNETPrice
        };
    }, [companyMarkups, currentSelectedMarkupIndex, isAnonymousMultiBooking, isNETPrice]);
}