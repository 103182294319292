import React, {ReactElement, useEffect, useState} from "react"
import {connect, ConnectedProps} from "react-redux";
import HotelStatus from "components/common/BookingStatus/HotelStatus";
import TransferStatus from "components/common/BookingStatus/TransferStatus";
import {cancelBooking, getBooking, setBookingMemo, updateBooking} from "redux/actions/bookingDetails.actions";
import setLastLocation from "redux/actions/router.actions";
import {handlePaymentPending} from "redux/actions/multiPayment.actions";
import Spinner from "components/base/Loaders/Spinner";
import isEqual from "lodash/isEqual";
import {WebHotelBookingDetails, WebTransferBookingDetails} from "proptypes/PropTypeObjects";
import {RootState} from "redux/store/store.init";
import {LastLocationKey, LocationBaseKey} from "redux/reducers/actions/router.reducer.actions";
import {useLocation, useParams} from "react-router-dom"

type Props = ConnectedProps<typeof connector>;

const BookingContainer = (props: Props): ReactElement => {
    const {
        requestingBookingDetails,
        data,
        handlePaymentPending,
        cancelBooking,
        setBookingMemo,
        requestingCancelBooking,
        getBooking,
        setLastLocation,
        currency,
        locale,
        updateBooking,
        bookingDetailsParams
    } = props;

    const {id} = useParams<{ id: string }>();
    const location = useLocation();
    const [show, setShow] = useState<boolean>(false);

    useEffect(() => {
        setShow(true);

        getBooking(parseInt(id || "", 10));
        setLastLocation(location, LocationBaseKey.MY_BOOKINGS, LastLocationKey.LAST_BOOKING_LOCATION);
    }, []);

    useEffect(() => {
        if (!requestingBookingDetails && bookingDetailsParams && !isEqual({
            locale,
            currency
        }, bookingDetailsParams)) {
            updateBooking();
        }
    }, [bookingDetailsParams, currency, locale, props, requestingBookingDetails, updateBooking]);

    if (!requestingBookingDetails && !data) {
        return (<></>);
    }

    return (requestingBookingDetails || requestingCancelBooking
        ? (
            <Spinner
                size="50px"
                style={{
                    width: "100%",
                    height: "calc( 100vh - 120px )"
                }}
            />
        )
        : show
            ? (
                <>
                    {!!data && data.bookingType === "TRANSFER" && (
                        <TransferStatus
                            data={{
                                ...(data as WebTransferBookingDetails),
                                mainStatus: data.status
                            }}
                            setBookingMemo={setBookingMemo}
                            cancelBooking={cancelBooking}
                            handlePaymentPending={handlePaymentPending}
                            variants={{
                                cancellable: data.status !== "CANCELLED",
                                withInvoices: true,
                                withSidebar: !!data
                            }}
                        />
                    )}

                    {!!data && data.bookingType === "HOTEL" && (
                        <HotelStatus
                            data={{
                                ...(data as WebHotelBookingDetails),
                                mainStatus: data.status
                            }}
                            cancelBooking={cancelBooking}
                            handlePaymentPending={handlePaymentPending}
                            variants={{
                                cancellable: data.status !== "CANCELLED",
                                withInvoices: true,
                                withSidebar: !!data,
                                canEditMemo: true
                            }}
                        />
                    )}
                </>
            ) : <></>);
}

const mapStateToProps = ({
    bookingDetails, currency, auth, locale
}: RootState) => ({
    data: bookingDetails.data,
    bookingDetailsParams: bookingDetails.bookingDetailsParams,
    locale: locale.currentLocale,
    currency: currency.currentCurrency,
    hotelAmount: auth.userData?.companyMarkups[auth.currentCompanyMarkupIndex || 0].hotelAmount,
    transferAmount: auth.userData?.companyMarkups[auth.currentCompanyMarkupIndex || 0].transferAmount,
    //hotelAmount: auth.userData?.companyMarkups[auth.currentCompanyMarkupIndex].hotelAmount,
    //transferAmount: auth.userData?.companyMarkups[auth.currentCompanyMarkupIndex].transferAmount,
    requestingBookingDetails: bookingDetails.requestingBookingDetails,
    requestingCancelBooking: bookingDetails.requestingCancelBooking
});

const connector = connect(mapStateToProps, {
    updateBooking,
    getBooking,
    setLastLocation,
    handlePaymentPending,
    cancelBooking,
    setBookingMemo
});

export default connector(BookingContainer);
