import React, {
    ReactElement, useCallback, useState
} from "react";
import BasicDropdown from "components/base/Input/BasicDropdown/BasicDropdown";
import {useTranslation} from "react-i18next";
import cx from "classnames";
import styles from "./Details.module.scss";
import {WalletDetailsProps} from "../../../proptypes/PropTypeObjects";
import NumberInput from "../../../components/base/NumberInput/NumberInput";
import UserButton from "../../../components/base/UserButton";
import {UserButtonVariant} from "../../../components/base/UserButton/UserButton";
import {RootState} from "../../../redux/store/store.init";
import {useAppSelector} from "../../../redux/hooks";
import {CustomTagType} from "../../../components/utils/CustomTag";
import baseURL from "../../../api/constants/baseURL";
import SkeletonLoader from "../../../components/utils/SkeletonLoader/SkeletonLoader";
import CustomCheckBox from "../../../components/base/Input/CustomCheckBox";

export type DetailsProps = {
    walletDetails?: WalletDetailsProps;
};

export default function Details({
    walletDetails
}: DetailsProps): ReactElement {
    const {t} = useTranslation();

    const walletPaymentOrder = useAppSelector((state: RootState) => state.auth.userData?.walletPaymentOrder) || true;
    const [officialDocumentAmount, setOfficialDocumentAmount] = useState<string>("100.00");
    const [officialDocumentToggle, setOfficialDocumentToggle] = useState<boolean>(false);

    const [activeTransferBankIndex, setActiveTransferBankIndex] = useState(0);
    const [showMore, setShowMore] = useState<boolean>(false);
    const options = walletDetails?.bankTransferBanks.map(({
        bankName
    }, i) => ({
        value: i,
        label: bankName
    })) || [];

    const showMoreLessCallback = useCallback(() => {
        setShowMore(!showMore);
    }, [showMore]);

    if (!walletDetails) {
        return (
            <SkeletonLoader className={styles.SkeletonLoader}>
                <div className={styles.SkeletonTitle} data-skeleton-animated="true" />
                <div className={styles.SkeletonAddFundsContainer} data-skeleton-animated="true" />

                <div className={styles.SkeletonTitle} data-skeleton-animated="true" />
                <div className={styles.SkeletonRefundWallet} data-skeleton-animated="true" />
            </SkeletonLoader>
        );
    }

    return (
        <div className={styles.Root} id="WalletDetailsAddFunds">
            <h2 className={styles.Heading}>{t("w_wd_d_add_funds")}</h2>

            <div className={styles.AddFundsContainer}>
                <p>{t("w_wd_d_about_add_funds")}</p>

                {walletDetails?.bankTransferBanks.length !== 1 && (
                    <BasicDropdown
                        required={true}
                        hideErrors={true}
                        label={t("w_wd_d_select_bank") + ":"}
                        dropDownOptions={options}
                        onDropdownChange={(val) => setActiveTransferBankIndex(val)}
                        dropDownValue={activeTransferBankIndex}
                        className={styles.Dropdown}
                    />
                )}

                <div className={cx(styles.BankDetailsContainer, showMore && styles.BankDetailsContainerOpen)}>
                    {walletDetails?.bankTransferBanks[activeTransferBankIndex] && (({
                        recipientName,
                        iban,
                        currencies,
                        bankAddress,
                        swiftCode,
                        recipientAddress
                    }) => (
                        <dl className={styles.BankDetails}>
                            <dt>{t("w_wd_d_recipient_name")}:</dt>
                            <dd>{recipientName}</dd>

                            <dt>{t("w_wd_d_recipient_iban")}:</dt>
                            <dd>{iban}</dd>

                            <dt>{t("w_wd_d_payment_purpose")}:</dt>
                            <dd className={styles.PaymentPurpose}>{walletDetails?.paymentPurpose}</dd>

                            <dt>{t("w_wd_d_currencies_accepted")}:</dt>
                            <dd>{currencies}</dd>

                            <dt>{t("w_wd_d_swift_code")}:</dt>
                            <dd>{swiftCode}</dd>

                            <dt>{t("w_wd_d_bank_address")}:</dt>
                            <dd>{bankAddress}</dd>

                            <dt>{t("w_wd_d_recipient_address")}:</dt>
                            <dd>{recipientAddress}</dd>
                        </dl>
                    ))(walletDetails?.bankTransferBanks[activeTransferBankIndex])}

                    {walletDetails?.bankTransferBanks[activeTransferBankIndex] && (
                        <a onClick={showMoreLessCallback}>{showMore ? t("w_wd_d_see_less") : t("w_wd_d_see_more")}</a>
                    )}
                </div>

                <CustomCheckBox
                    className={styles.OfficialDocumentToggle}
                    // label={t("w_wd_d_official_payment_document_toggle")}
                    label={t("w_wd_d_official_payment_document_explanation")}
                    inputProps={{
                        checked: officialDocumentToggle,
                        onChange: () => setOfficialDocumentToggle(!officialDocumentToggle)
                    }}
                />

                {/*<p>{t("w_wd_d_official_payment_document_explanation")}</p>*/}

                {walletPaymentOrder && (
                    <div
                        className={cx(styles.OfficialDocumentDownloadContainer, officialDocumentToggle && styles.OfficialDocumentDownloadContainerOpen)}
                    >
                        <NumberInput
                            className={styles.ValueInput}
                            inputProps={{
                                placeholder: t("w_wd_d_wallet_document_input_label")
                            }}
                            value={officialDocumentAmount}
                            onChange={(val) => setOfficialDocumentAmount(val)}
                        />

                        <UserButton
                            customTag={CustomTagType.A}
                            text={t("w_wd_d_wallet_document_download_btn")}
                            variant={UserButtonVariant.PRIMARY}
                            buttonProps={{
                                href: `${baseURL}/wallet/paymentOrder/export?amount=${officialDocumentAmount}&bankAccountId=${walletDetails?.bankTransferBanks[activeTransferBankIndex].id}`,
                                download: "paymentVoucher.pdf"
                            }}
                        />
                    </div>
                )}
            </div>
        </div>
    );
}
