import React, {ReactElement} from "react";
import cx from "classnames";
import styles from "./ItemsWrapper.module.scss";

export type ItemsWrapperProps = {
    wrap?: boolean;
    hasTitle?: boolean;
    inline?: boolean;
    children: ReactElement | ReactElement[];
    title: string;
    containerClassName?: string;
};

export default function ItemsWrapper({
    wrap = false,
    hasTitle = false,
    inline = false,
    children,
    title,
    containerClassName
}: ItemsWrapperProps): ReactElement {
    return wrap ? (
        <div className={cx(styles.Root, inline && styles.Inline)}>
            {hasTitle && (
                <p className={styles.Title}>
                    {title}
                </p>
            )}

            <div className={cx(styles.Container, containerClassName)}>
                {children}
            </div>
        </div>
    )
        : (
            <>
                {children}
            </>
        );
}