/* eslint-disable */

// @ts-ignore
import AllFiltersWorker from "workerize-loader?name=[name].[contenthash:8]!utils/compute/filterWorker";
// @ts-ignore
import MapFilterWorker from "workerize-loader?name=[name].[contenthash:8]!utils/compute/mapFilterWorker";
// @ts-ignore
import StatisticsWorker from "workerize-loader?name=[name].[contenthash:8]!utils/compute/statisticsWorker";

export enum WorkerType {
    ALL_FILTERS = "ALL_FILTERS",
    MAP_FILTER = "MAP_FILTER",
    STATISTICS = "STATISTICS"
}

export type AbstractWorkerProps<T, N> = {
    type: WorkerType;
    beforeStart: () => void;
    doPrepareData: () => T;
    onComplete: (result: N) => void | Promise<void>;
    hash: string;

    force?: boolean;
};

type BaseWebWorkerReturn = {
    method?: string;
    type?: string;
}

type AbstractWorkerReturn<T> = BaseWebWorkerReturn & {
    result: T
};

const ACTIVE_WORKERS = new Map<WorkerType, Worker>();
const HASHES = new Map<WorkerType, string>();
const HASHES_RESULTS = new Map<string, unknown>();

export function resetWorkerHashes(): void {
    HASHES.clear();
    HASHES_RESULTS.clear();
}

export default async function queueWorker<T, N>(props: AbstractWorkerProps<T, N>): Promise<void> {
    const {
        type,
        beforeStart,
        doPrepareData,
        hash,
        onComplete,
        force = false
    } = props;
    let worker: Worker;

    if (!force && HASHES.get(type) === hash && !!HASHES_RESULTS.get(hash)) {
        onComplete(HASHES_RESULTS.get(hash) as N);
        return Promise.resolve();
    }

    if (ACTIVE_WORKERS.get(type) && HASHES.get(type) === hash) {
        return Promise.resolve();
    }

    ACTIVE_WORKERS.get(type)?.terminate();
    HASHES.set(type, hash);

    if (type === WorkerType.ALL_FILTERS) {
        worker = new AllFiltersWorker();
        HASHES.delete(WorkerType.MAP_FILTER);
    } else if (type === WorkerType.MAP_FILTER) {
        worker = new MapFilterWorker();
        HASHES.delete(WorkerType.ALL_FILTERS);
    } else if (type === WorkerType.STATISTICS) {
        worker = new StatisticsWorker();
        HASHES.delete(WorkerType.STATISTICS);
    } else {
        onComplete(HASHES_RESULTS.get(hash) as N);
        return Promise.resolve();
    }

    if (worker) {
        worker.addEventListener("message", (evt: MessageEvent<AbstractWorkerReturn<N>>) => {
            if (evt.data && evt.data.method === "ready") {
                // worker ready event, ignore
                return;
            }

            if (evt.data.result) {
                onComplete(evt.data.result);
                HASHES_RESULTS.set(hash, evt.data.result);
                ACTIVE_WORKERS.delete(type);
                worker.terminate();
            }
        });

        beforeStart();
        ACTIVE_WORKERS.set(type, worker);
        worker.postMessage(doPrepareData());
    }
}

/* eslint-enable */