enum TransferSearchTypes {
    // transferSearch processing'

    INIT_SEARCH = "transferSearch/INIT_SEARCH",
    SUBMIT_SEARCH = "transferSearch/SUBMIT_SEARCH",
    START_SEARCH = "transferSearch/START_SEARCH",
    UPDATE_SEARCH = "transferSearch/UPDATE_SEARCH",
    GET_DATA_SUCCESS = "transferSearch/GET_DATA_SUCCESS",
    GET_DATA_FAILURE = "transferSearch/GET_DATA_FAILURE",
    HANDLE_FORM_DATA = "transferSearch/HANDLE_FORM_DATA",
    HANDLE_STATE_FORM_DATA = "transferSearch/HANDLE_STATE_FORM_DATA",
    HANDLE_INPUT_CHANGE = "transferSearch/HANDLE_INPUT_CHANGE",
    HANDLE_SAVE_SEARCH = "transferSearch/HANDLE_SAVE_SEARCH",
    TOGGLE_ROUND_TRIP = "transferSearch/TOGGLE_ROUND_TRIP",
    CHANGE_CURRENT_DESTINATION = "transferSearch/CHANGE_CURRENT_DESTINATION",

    //recent searches and saved searches
    GET_RECENT_SEARCHES = "transferSearch/GET_RECENT_SEARCHES",
    GET_SAVED_SEARCHES = "transferSearch/GET_SAVED_SEARCHES"
}

export default TransferSearchTypes;
