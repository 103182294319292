import React, {
    ReactElement, useEffect, useMemo, useReducer, useState
} from "react"
import {useTranslation} from "react-i18next";
import {Reducer} from "redux";
import i18n from "i18n";
import debounce from "lodash/debounce";
import {useAppSelector} from "redux/hooks";
import {DateTime} from "luxon"
import cx from "classnames"
import {WalletDetailsProps, WalletEntry} from "proptypes/PropTypeObjects";
import {getPriceWithCurrencySymbol} from "@hotelston_web_frontend_utils/currency/getPriceWithCurrency";
import {convertToServerTime} from "utils/dateUtils";
import createLuxonDate from "utils/date/createLuxonDate"
import {getWalletTransactions} from "api/walletAPI";
import TablePageTemplate, {
    TablePageTableRow,
    TablePageTableRowCell
} from "components/base/TablePageTemplate/TablePageTemplate";
import {TableHeading} from "components/base/ResponsiveTable/ResponsiveTable.base";
import DoubleDatePicker from "components/base/Input/DatePicker/DoubleDatePicker/DoubleDatePicker"
import Balance from "../Balance/Balance";
import Details from "../Details";
import RefundWallet from "../RefundWallet";
import styles from "./Transactions.module.scss";
import Currency from "@hotelston_web_frontend_parent/constants/currency";
import globalAxiosController from "api/axios/globalAxiosController"

type StateProps = {
    isFetching: boolean;
    transactions: WalletEntry[];
    error?: string;
};

const initialState: StateProps = {
    isFetching: false,
    transactions: [],
    error: undefined
};

type ActionProps = { type: "GET_TRANSACTIONS" }
| { type: "GET_TRANSACTIONS_SUCCESS", payload: WalletEntry[] }
| { type: "GET_TRANSACTIONS_FAILED", error: string };

const reducer: Reducer<StateProps, ActionProps> = (state: StateProps = initialState, action: ActionProps) => {
    switch (action.type) {
    case "GET_TRANSACTIONS":
        return {
            ...state,
            isFetching: true
        };
    case "GET_TRANSACTIONS_SUCCESS":
        return {
            ...state,
            isFetching: false,
            transactions: action.payload
        };
    case "GET_TRANSACTIONS_FAILED":
        return {
            ...state,
            isFetching: false,
            error: action.error
        };
    default:
        return state;
    }
};

function getValue(key: string, value: string | number, bookingRef: string) {
    switch (key) {
    case "date":
        return DateTime.fromISO(convertToServerTime(value) || "").toFormat("yyyy-MM-dd");
    case "textKey":
        return i18n.t(value as string, {bookingRef});
    case "amount":
        return (
            <span className={cx(styles.NumberCell, Number(value) > 0 ? styles.PositiveAmount : styles.NegativeAmount)}>{getPriceWithCurrencySymbol(value as number, Currency.EUR)}</span>
        );
    case "balance":
        return (
            <span className={styles.NumberCell}>{getPriceWithCurrencySymbol(value as number, Currency.EUR)}</span>
        );
    default:
        return value;
    }
}

export type TransactionProps = {
    balance?: number;
    walletDetails?: WalletDetailsProps;
};

export default function Transactions({
    balance,
    walletDetails
}: TransactionProps): ReactElement {
    const {t} = useTranslation();
    const [state, dispatch] = useReducer(reducer, initialState);
    const [{from, to}, setFromTo] = useState<{ from?: string, to?: string }>({
        from: undefined,
        to: undefined
    });

    const locale = useAppSelector((rootState) => rootState.locale.currentLocale);
    const {transactions = [], isFetching} = state;

    const columnsSorted = useMemo(() => [
        {
            key: "date",
            field: "date",
            enabled: true,
            order: 0
        }, {
            key: "textKey",
            field: "textKey",
            enabled: true,
            order: 1
        }, {
            key: "amount",
            field: "amount",
            enabled: true,
            order: 2
        }, {
            key: "balance",
            field: "balance",
            enabled: true,
            order: 3
        }
    ], []);

    useEffect(() => {
        dispatch({type: "GET_TRANSACTIONS"});
        debounce(() => {
            globalAxiosController.addRequest(getWalletTransactions(locale, from, to))
                .then((data) => {
                    if (!data || !data.entries) {
                        dispatch({
                            type: "GET_TRANSACTIONS_FAILED",
                            error: t("w_t_something_went_wrong")
                        });
                    } else {
                        dispatch({
                            type: "GET_TRANSACTIONS_SUCCESS",
                            payload: data.entries || []
                        });
                    }
                })
                .catch((error: string) => {
                    dispatch({
                        type: "GET_TRANSACTIONS_FAILED",
                        error
                    });
                });
        }, 300)();
    }, [locale, from, to, t]);

    const tHeadings = useMemo(() => columnsSorted.map(({key}, i) => ({
        element: t("w_t_" + key),
        style: i !== 0 && key !== "textKey" ? {display: "block", textAlign: "right"} : undefined,
        sortable: true,
        label: t("w_t_" + key)
    }) as TableHeading), [columnsSorted, t]);

    const tRows = useMemo(() => transactions.map((data) => ({
        variant: "Normal",
        actionable: false,
        tDatas: columnsSorted.map(({key}, i) => ({
            element: (
                <span style={i !== 0 && key !== "textKey" ? {display: "block", textAlign: "right"} : undefined}>
                    {getValue(key, data[key], data.bookingReference)}
                </span>
            ),
            actionable: false,
            rawValue: data[key]
        } as TablePageTableRowCell) )
    } as TablePageTableRow)), [transactions, columnsSorted]);

    return (
        <TablePageTemplate
            title={(<Balance balance={balance} />)}
            content={<Details walletDetails={walletDetails} />}
            filters={(
                <div className={styles.TableControlsWrapper}>
                    <span className={styles.ControlsLabel}>{t("w_t_show_transactions")}:</span>

                    <DoubleDatePicker
                        from={from ? createLuxonDate(from) : null}
                        to={to ? createLuxonDate(to) : null}
                        fromPlaceholder={t("w_t_enter_date") + "..."}
                        toPlaceholder={t("w_t_enter_date") + "..."}
                        onDateFromChange={(date) => setFromTo({
                            from: date ? createLuxonDate(date).toFormat("yyyy-MM-dd") : undefined,
                            to
                        })}
                        onDateToChange={(date) => setFromTo({
                            from,
                            to: date ? createLuxonDate(date).toFormat("yyyy-MM-dd") : undefined
                        })}
                    />

                    {/*<DateRangeInput*/}
                    {/*    label={t("w_t_from")}*/}
                    {/*    label2={t("w_t_to")}*/}
                    {/*    double*/}
                    {/*    className={styles.DateControls}*/}
                    {/*    startDate={from ? moment(from) : null}*/}
                    {/*    endDate={to ? moment(to) : null}*/}
                    {/*    index={0}*/}
                    {/*    readOnly={false}*/}
                    {/*    onStartDateChange={}*/}
                    {/*    onEndDateChange={}*/}
                    {/*    startDatePlaceholderText={t("w_t_enter_date") + "..."}*/}
                    {/*    endDatePlaceholderText={t("w_t_enter_date") + "..."}*/}
                    {/*/>*/}
                </div>
            )}
            bottomContent={
                walletDetails?.balance && walletDetails?.balance > 0 ? (
                    <RefundWallet balance={walletDetails?.balance} />
                ) : undefined
            }
            tableTitle={t("w_t_payment_history")}
            tHeadings={tHeadings}
            tRows={tRows || []}
            tableSettings={{
                autoSize: true,
                className: styles.Table,
                firstColumnSticky: true,
                transparent: true
            }}
            loading={isFetching}
        />
    );
    // return (
    //     <div className={styles.Root}>
    //         <h2>{t("w_t_payment_history")}</h2>
    //         <div>
    //     </div>
    // );
}
