enum CompanyTypes {
    REQ_COMPANY_DETAILS = "company/REQ_COMPANY_DETAILS",
    REQ_COMPANY_DETAILS_SUCCESS = "company/REQ_COMPANY_DETAILS_SUCCESS",
    REQ_COMPANY_DETAILS_FAILURE = "company/REQ_COMPANY_DETAILS_FAILURE",
    UPDATE_COMPANY_DETAILS = "company/UPDATE_COMPANY_DETAILS",
    UPDATE_CURRENT_USER = "company/UPDATE_CURRENT_USER",
    UPDATE_BILLING_DETAILS = "company/UPDATE_BILLING_DETAILS",
    ADD_COMPANY_MARKUP = "company/ADD_COMPANY_MARKUP",
    UPDATE_COMPANY_MARKUP = "company/UPDATE_COMPANY_MARKUP",
    REMOVE_COMPANY_MARKUP = "company/REMOVE_COMPANY_MARKUP",
    ADD_USER = "company/ADD_USER",
    REMOVE_USER = "company/REMOVE_USER",
    UPDATE_USER = "company/UPDATE_USER",
    UPDATE_LOGO = "company/UPDATE_LOGO"
}

export default CompanyTypes;