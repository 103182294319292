import React, {ReactElement, useEffect, useMemo} from "react"
import {connect, ConnectedProps} from "react-redux";
import HotelStatus from "components/common/BookingStatus/HotelStatus";
import TransferStatus from "components/common/BookingStatus/TransferStatus";
import Spinner from "components/base/Loaders/Spinner";
import {
    getBookingDetails,
    resetDetails,
    setShowBusinessCredentialsToFalse,
    updateBookingDetails
} from "redux/actions/bookingDetails.actions";
import isEqual from "lodash/isEqual";
import {toByteArray} from "base64-js";
import {
    ClientBookingStatusPropTypes,
    WebHotelBookingDetails,
    WebTransferBookingDetails
} from "proptypes/PropTypeObjects";
import {getQueryParamsObject} from "utils/url/queryParamsUtils";
import {RootState} from "redux/store/store.init";
import {Navigate, useLocation, useParams} from "react-router-dom"

type Props = ConnectedProps<typeof connector>;

const PaymentStatusContainer = (props: Props): ReactElement => {
    const {
        requestingBookingDetails,
        data,
        multiBooking,
        setShowBusinessCredentialsToFalse,
        getBookingDetails,

        currency,
        locale,
        updateBookingDetails,
        bookingDetailsParams
    } = props;

    const location = useLocation();
    const urlParams = useParams();
    // const mainStatus = urlParams.status as ClientBookingStatusPropTypes;
    const mainStatus = data?.status || urlParams.status as ClientBookingStatusPropTypes;
    const params = useMemo(() => getQueryParamsObject(location.search), [location]);

    useEffect(() => {
        if (params && params.po) {
            getBookingDetails(parseInt(params.po, 10));
        }
    }, [getBookingDetails, params]);

    useEffect(() => {
        if (
            !requestingBookingDetails &&
            bookingDetailsParams &&
            !isEqual({
                locale,
                currency
            }, bookingDetailsParams)
        ) {
            updateBookingDetails();
        }
    }, [bookingDetailsParams, currency, locale, requestingBookingDetails, updateBookingDetails]);

    if (params.data) {
        const providerPaymentCallbackData = getQueryParamsObject(decodeURIComponent(new TextDecoder().decode(toByteArray(params.data.substring(0, params.data.length - (params.data.length % 4))))));

        if (providerPaymentCallbackData.paidafterconfirm) {
            return <Navigate to={`/my-bookings/payment/${mainStatus}`}/>;
        }
    }

    if (multiBooking) {
        return <Navigate to={`/my-bookings/payment/${mainStatus}`}/>;
    }

    return requestingBookingDetails ? (
        <Spinner
            size="50px"
            style={{
                width: "100%",
                height: "calc(100vh - 120px)"
            }}
        />
    ) : (
        <>
            {!!data && data.bookingType === "TRANSFER" && (
                <TransferStatus
                    data={{
                        ...data as WebTransferBookingDetails,
                        mainStatus
                    }}
                    setShowBusinessCredentialsToFalse={setShowBusinessCredentialsToFalse}
                    variants={{
                        afterPayment: true
                    }}
                />
            )}

            {!!data && data.bookingType === "HOTEL" && (
                <HotelStatus
                    data={{
                        ...data as WebHotelBookingDetails,
                        mainStatus
                    }}
                    setShowBusinessCredentialsToFalse={setShowBusinessCredentialsToFalse}
                    variants={{
                        afterPayment: true
                    }}
                />
            )}
        </>
    );
}

const mapStateToProps = (
    {
        bookingDetails,
        currency,
        locale
    }: RootState
) => ({
    data: bookingDetails.data,
    multiBooking: bookingDetails.multiBooking,
    locale: locale.currentLocale,
    currency: currency.currentCurrency,
    requestingBookingDetails: bookingDetails.requestingBookingDetails,
    bookingDetailsParams: bookingDetails.bookingDetailsParams
});

const connector = connect(mapStateToProps, {
    getBookingDetails,
    updateBookingDetails,
    setShowBusinessCredentialsToFalse,
    resetDetails
});

export default connector(PaymentStatusContainer);
