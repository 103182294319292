import React, {CSSProperties, ReactElement} from "react";
import cx from "classnames";
import toTitleCase from "utils/toTitleCase";
import {useTranslation} from "react-i18next";
import Arrow from "../Arrow/Arrow";
import styles from "./DatesRangeBox.module.scss";
import {convertToServerTime} from "../../../utils/dateUtils";
import {DateTime} from "luxon"
import createLuxonDate from "../../../utils/date/createLuxonDate"

type Props = {
    checkIn?: DateTime | string | number;
    checkOut?: DateTime | string | number;
    nights?: number;
    dashed?: boolean;
    style?: CSSProperties;
};

function DatesRangeBox({
    checkIn, checkOut, nights, style = {}, dashed = false
}: Props): ReactElement {
    const {t} = useTranslation();

    const delocalizedCheckIn = convertToServerTime(createLuxonDate(checkIn).toISO() as string);
    const delocalizedCheckOut = convertToServerTime(createLuxonDate(checkOut).toISO() as string);

    return (
        <div style={style} className={cx(styles.Root, dashed && styles.Dashed)}>
            <div className={styles.Top}>
                <div className={styles.DateStart}>
                    <h4>{createLuxonDate(delocalizedCheckIn).toFormat("dd")}</h4>
                </div>

                <div className={styles.Arrow}>
                    <Arrow />
                </div>

                <div className={styles.DateEnd}>
                    <h4>{createLuxonDate(delocalizedCheckOut).toFormat("dd")}</h4>
                </div>
            </div>

            <div className={styles.Bottom}>
                <div className={styles.Month}>{toTitleCase(createLuxonDate(delocalizedCheckIn).toFormat("MMM, yyyy"))}</div>

                <div className={styles.Duration}>{nights} {t("drb_brb_night", {count: nights})}</div>

                <div className={styles.Month}>{toTitleCase(createLuxonDate(delocalizedCheckOut).toFormat("MMM, yyyy"))}</div>
            </div>
        </div>
    );
}
export default DatesRangeBox;
