import React, {ReactElement, useRef} from "react"
import {Link, Navigate} from "react-router-dom"
import {Trans, useTranslation} from "react-i18next";
import cx from "classnames";
import styles from "./Login.module.scss";
import Input from "../../components/base/Input";
import UserButton from "../../components/base/UserButton";
import EnterPinModal from "./EnterPinModal";
import {UserButtonVariant} from "../../components/base/UserButton/UserButton";
import {InsufficientAuthenticationType, LoginErrorType} from "../../proptypes/PropTypeObjects";
import landingBaseUrl from "../../api/constants/landingBaseUrl";
import PasswordInput from "../../components/common/PasswordInput/PasswordInput";
import OutlineInput from "../../components/base/Input/TextInput/OutlinedInput";
import Spinner from "../../components/base/Loaders/Spinner"
import {useAppSelector} from "../../redux/hooks"

function getLoginErrorTranslationKey(loginError: LoginErrorType): string {
    switch (loginError) {
    case LoginErrorType.BAD_CREDENTIALS:
        return "login_email_password_wrong";
    case LoginErrorType.BAD_PIN:
        return "login_err_bad_pin";
    case LoginErrorType.PIN_EXPIRED:
        return "login_err_pin_expired";
    case LoginErrorType.PASSWORD_EXPIRED:
        return "login_err_password_expired";
    case LoginErrorType.PIN_MAX_ATTEMPTS:
        return "login_err_pin_max_attempts";
        //TODO implement
    case LoginErrorType.ACCOUNT_LOCKED:
        return "login_email_password_wrong";
    case LoginErrorType.ACCOUNT_NOT_APPROVED:
        return "login_email_password_wrong";
    default:
        return "login_email_password_wrong";
    }
}

const Login = ({
    handleInput,
    handleSubmit,
    handlePinSubmit,
    error,
    email,
    password,
    pinLoginType,
    loggingIn,
    clearCurrentUserData,
    loginError

}: Props): ReactElement => {
    const {t} = useTranslation();
    const inputRef = useRef<OutlineInput>(null);
    const loggedIn = useAppSelector((state) => !!state.auth.userData);

    if (loggedIn) {
        return (
            <Navigate to="/"/>
        );
    }

    return (
        <div className={styles.Login}>
            <div className={styles.LoginContainer}>
                <div className={styles.LeftColumn}>
                    <form
                        // noValidate
                        id="hotelston-login"
                        name="hotelston-login"
                        onSubmit={handleSubmit}
                    >
                        <h1 className={styles.Title}>
                            {t("login_login_hotelston_account")}
                        </h1>

                        <div className={styles.FormBox}>
                            {!pinLoginType && error && (
                                <h4 className={styles.AlertWrongPassword}>
                                    {t("login_email_password_wrong")}
                                </h4>
                            )}

                            {!pinLoginType && loginError && (
                                <h4 className={styles.AlertWrongPassword}>
                                    {t(getLoginErrorTranslationKey(loginError))}
                                </h4>
                            )}

                            <div className={styles.Email}>
                                <Input
                                    inputComponentRef={inputRef}
                                    className="input"
                                    variants={{labelPosition: "outlined"}}
                                    inputProps={{
                                        required: true,
                                        value: email,
                                        placeholder: t("login_email"),
                                        onChange: (e) => {
                                            handleInput(e);
                                        },
                                        type: "email",
                                        name: "email"
                                    }}

                                />
                            </div>
                            <div className={styles.Password}>
                                <PasswordInput
                                    className="input"
                                    variants={{labelPosition: "outlined"}}
                                    inputProps={{
                                        required: true,
                                        value: password,
                                        placeholder: t("login_password"),
                                        onChange: (e) => {
                                            handleInput(e);
                                        },
                                        type: "password",
                                        name: "password"
                                    }}
                                />
                            </div>

                            <div className={styles.LoginActions}>
                                <Link className={styles.ForgotPassword} to="/forgot-password">
                                    {t("login_forgot_password_question")}
                                </Link>
                                <UserButton
                                    text={t("login_login")}
                                    variant={UserButtonVariant.PRIMARY}
                                    customIcon={loggingIn ? (
                                        <Spinner/>
                                    ) : undefined}
                                    className={cx(loggingIn && styles.SignInButtonLoading)}
                                    buttonProps={{
                                        type: "submit"
                                    }}
                                />
                            </div>
                        </div>

                        <span className={styles.NewAgent}>
                            <Trans i18nKey="login_register_new_agent_question">
                                Want to register
                                <a className="green-color" href={`${landingBaseUrl}/register`}>
                                    a new agent?
                                </a>
                            </Trans>
                        </span>
                    </form>

                    {pinLoginType && (
                        <EnterPinModal
                            email={email}
                            onConfirm={(pin: string) => handlePinSubmit(pin)}
                            onDecline={clearCurrentUserData}
                            spinner={loggingIn}
                            errors={loginError ? [t(getLoginErrorTranslationKey(loginError))] : error}
                            pinLoginType={pinLoginType}
                        />
                    )}
                </div>

                <div className={styles.RightColumn}>
                    {/*<Righter*/}
                    {/*    variants={{*/}
                    {/*        authPages: true,*/}
                    {/*        transparent: true*/}
                    {/*    }}*/}
                    {/*/>*/}
                </div>
            </div>
        </div>
    );
};

type Props = {
    handleInput: (e: React.ChangeEvent<HTMLInputElement>) => void;
    handleSubmit: (e: React.FormEvent<HTMLFormElement>) => void;
    handlePinSubmit: (pin: string) => void;
    error?: string[];
    email: string;
    password: string;
    pinLoginType?: InsufficientAuthenticationType;
    loggingIn: boolean;
    clearCurrentUserData: () => void;
    loginError?: LoginErrorType;
};

export default Login;