import {useMemo} from "react"
import {useAppSelector} from "redux/hooks"
import useSelectedMarkup from "utils/hooks/useSelectedMarkup"
import {
    BookingOptionsPropTypes,
    CustomVoucherPropTypes,
    PaymentTransferTypePropTypes,
    PaymentVoucherPropTypes,
    WebBankLinkOptionPropTypes,
    WebPaymentPromotion
} from "proptypes/PropTypeObjects"
import {ReduxDiscountCode, ReduxPaymentTypePropTypes} from "proptypes/PropTypeRedux"

export type UsePaymentOptionsReturn = {
    paymentOptions?: BookingOptionsPropTypes;
    discount: number;
    appliedWalletAmount?: number;
    voucherSum: { all: number, used: number };
    transactionFee: number,
    displayTransactionFee: number;
    markup: number;
    paymentTransferType?: PaymentTransferTypePropTypes;
    selectedPaymentLink?: WebBankLinkOptionPropTypes;
    customVoucher?: CustomVoucherPropTypes;
    paymentVouchers: PaymentVoucherPropTypes[];
    paymentPromotion?: WebPaymentPromotion;
    discountCode?: ReduxDiscountCode;

    type?: ReduxPaymentTypePropTypes;

    errors: string[];
};

const usePaymentOptions = (): UsePaymentOptionsReturn => {
    const {
        hotelAmount,
        transferAmount
    } = useSelectedMarkup();

    // const options = useAppSelector((state) => state.payment.bookingOptions);
    const paymentType = useAppSelector((state) => state.payment.type);
    const paymentOptions = useAppSelector((state) => state.payment.bookingOptions);
    const appliedDiscountAmount = useAppSelector((state) => state.payment.appliedDiscountAmount);
    const appliedWalletAmount = useAppSelector((state) => state.payment.appliedWalletAmount);
    const appliedVoucherSum = useAppSelector((state) => state.payment.voucherSum);
    const transactionFee = useAppSelector((state) => state.payment.transactionFee);
    const displayTransactionFee = useAppSelector((state) => state.payment.displayTransactionFee);
    const paymentTransferType = useAppSelector((state) => state.payment.paymentTransferTypeEnum);
    const selectedPaymentLink = useAppSelector((state) => state.payment.selectedPaymentLink);
    const customVoucher = useAppSelector((state) => state.payment.customVoucher);
    const paymentVouchers = useAppSelector((state) => state.payment.paymentVouchers);
    const paymentPromotion = useAppSelector((state) => state.payment.paymentPromotion);
    const discountCode = useAppSelector((state) => state.payment.appliedDiscountCode);
    const errors = useAppSelector((state) => state.payment.errors);

    return useMemo(() => {
        if (paymentOptions) {
            const paymentOptionsCopy = {
                ...paymentOptions
            };

            let markup = 1;
            if (paymentType === ReduxPaymentTypePropTypes.HOTEL) {
                markup = hotelAmount;
            } else if (paymentType === ReduxPaymentTypePropTypes.TRANSFER) {
                markup = transferAmount;
            }

            if (paymentType === ReduxPaymentTypePropTypes.MULTIBOOKING || paymentType === ReduxPaymentTypePropTypes.ANONYMOUS_MULTIBOOKING) {
                paymentOptionsCopy.salePrice = paymentOptions.paymentPending;
                paymentOptionsCopy.displaySalePrice = paymentOptions.paymentPending;
            }

            if (paymentType === ReduxPaymentTypePropTypes.MULTIBOOKING || paymentType === ReduxPaymentTypePropTypes.ANONYMOUS_MULTIBOOKING) {
                paymentOptionsCopy.salePrice = paymentOptions.paymentPending;
                paymentOptionsCopy.displaySalePrice = paymentOptions.paymentPending;
            }

            let ret = {
                paymentOptions: paymentOptionsCopy,
                discount: appliedDiscountAmount || 0,
                appliedWalletAmount: appliedWalletAmount,
                voucherSum: appliedVoucherSum,
                transactionFee: transactionFee || 0,
                displayTransactionFee: displayTransactionFee || 0,
                markup,
                paymentTransferType: paymentTransferType,
                selectedPaymentLink: selectedPaymentLink,
                customVoucher,
                type: paymentType,
                paymentVouchers: paymentVouchers || [],
                paymentPromotion,
                discountCode,
                errors: errors || []
            };

            // if (paymentType === ReduxPaymentTypePropTypes.SPLIT_PRICE_PART_PAYMENT) {
            //     ret = {
            //         ...ret,
            //         paymentOptions: {
            //             ...paymentOptions,
            //             paymentTransferTypes: [splitPricePaymentType as PaymentTransferTypePropTypes],
            //             paymentPending: selectedSplitPricePart?.amount || paymentOptions.paymentPending,
            //             salePrice: selectedSplitPricePart?.amount || paymentOptions.displaySalePrice,
            //             displaySalePrice: selectedSplitPricePart?.amount || paymentOptions.displaySalePrice
            //         }
            //     }
            // }

            return ret;
        }

        return {
            paymentOptions: undefined,
            discount: 0,
            voucherSum: {
                all: 0,
                used: 0
            },
            transactionFee: 0,
            displayTransactionFee: 0,
            markup: 1,
            paymentTransferType: undefined,
            selectedPaymentLink: undefined,
            type: undefined,
            paymentVouchers: [],
            paymentPromotion: undefined,
            discountCode: undefined,
            errors: []
        };
    }, [
        appliedDiscountAmount,
        appliedVoucherSum,
        appliedWalletAmount,
        displayTransactionFee,
        hotelAmount,
        paymentOptions,
        paymentTransferType,
        paymentType,
        selectedPaymentLink,
        transactionFee,
        transferAmount,
        customVoucher,
        paymentVouchers,
        paymentPromotion,
        discountCode,
        errors
    ]);
}

export default usePaymentOptions;