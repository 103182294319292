import cx from "classnames";
import React, {ReactElement} from "react";
import {useTranslation} from "react-i18next";
import {animateScroll as scroll} from "react-scroll";
import animatedLogo from "../../../assets/images/logoAnimated.gif";
import styles from "./RequestingBookingWrapper.module.scss";

export type BookingLoadingWrapperProps = {
    // address: string,
    //
    // bookingName: string,
    // totalPassengers: number,
    // totalRooms?: number,
    //
    // checkIn?: string | number,
    // checkOut?: string | number
};

export default function RequestingBookingWrapper(props: BookingLoadingWrapperProps): ReactElement {
    // const {
    //     address,
    //
    //     bookingName,
    //     totalPassengers,
    //     totalRooms,
    //
    //     checkIn,
    //     checkOut
    // } = props;

    const {t} = useTranslation();

    // let totalPassengersLabel: string;
    // if (totalPassengers === 1) {
    //     totalPassengersLabel = totalPassengers.toString() + " " + t("pc_pc_reservation_in_progress_passengers_single");
    // } else {
    //     totalPassengersLabel = totalPassengers.toString() + " " + t("pc_pc_reservation_in_progress_passengers_multiple");
    // }
    //
    // let totalRoomsLabel: string | null = null;
    // if (totalRooms === 1) {
    //     totalRoomsLabel = totalRooms.toString() + " " + t("pc_pc_reservation_in_progress_rooms_single");
    // } else if (totalRooms) {
    //     totalRoomsLabel = totalRooms.toString() + " " + t("pc_pc_reservation_in_progress_rooms_multiple");
    // }

    return (
        <div className={styles.RequestingBookingContainer} onLoad={() => scroll.scrollToTop()}>
            <img
                className={styles.RequestingBookingSpinner}
                src={animatedLogo}
                alt="animated logo"
            />
            <div className={styles.SpinnerExplanationText}><b>{t("pc_pc_reservation_in_progress_wait")}</b></div>
            <div className={styles.SpinnerExplanationText}><b>{t("pc_pc_reservation_in_progress_no_refresh")}</b></div>

            {/*<div className={cx(styles.RequestingBookingHotelNameContainer)}>{address}</div>*/}
            {/*<div className={cx(styles.RequestingBookingInfoContainer)}>*/}
            {/*    <div className={cx(styles.RequestingBookingHotelInfoContainer)}>*/}
            {/*        <span>{bookingName}</span>*/}
            {/*        <div className={cx(styles.SeparatorBubble)} />*/}
            {/*        <span>{totalPassengersLabel}</span>*/}
            {/*        {totalRoomsLabel && (*/}
            {/*            <>*/}
            {/*                <div className={cx(styles.SeparatorBubble)} />*/}
            {/*                <span>{totalRoomsLabel}</span>*/}
            {/*            </>*/}
            {/*        )}*/}
            {/*    </div>*/}
            {/*    <div className={cx(styles.RequestingBookingHotelDateInfo)}>*/}
            {/*        {checkIn && <span>{moment(checkIn).format("D MMMM, YYYY")}</span>}*/}
            {/*        {checkIn && checkOut && (<span className={cx(styles.ArrowStart, styles.ArrowEnd)} />)}*/}
            {/*        {checkOut && <span>{moment(checkOut).format("D MMMM, YYYY")}</span>}*/}
            {/*    </div>*/}
            {/*</div>*/}
        </div>
    );
}