import {useEffect, useState} from "react";

export type UseBrowserTitleProps = {
    title: string | null;
};

export default function useBrowserTitle(props: UseBrowserTitleProps): void {
    const {
        title
    } = props;

    useEffect(() => {
        let originalBrowserTitle = "Hotelston";
        const titleElement = document.querySelector("head > title");

        if (titleElement) {
            originalBrowserTitle = titleElement.textContent || "Hotelston";
            titleElement.textContent = title;
        }

        return () => {
            if (titleElement) {
                titleElement.textContent = originalBrowserTitle;
            }
        };
    }, [title]);
}