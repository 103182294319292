enum OverpaymentTypes {
    REQ_OPTIONS = "overpayment/REQ_OPTIONS",
    REQ_OPTIONS_SUCCESS = "overpayment/REQ_OPTIONS_SUCCESS",
    REQ_OPTIONS_FAILURE = "overpayment/REQ_OPTIONS_FAILURE",

    REQ_USE = "overpayment/REQ_OVERPAYMENT",
    REQ_USE_SUCCESS = "overpayment/REQ_OVERPAYMENT_SUCCESS",
    REQ_USE_FAILURE = "overpayment/REQ_USE_FAILURE"
}

export default OverpaymentTypes;