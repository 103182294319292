import React, {useCallback, useEffect, useRef, useState} from "react"
import {useTranslation} from "react-i18next"
import {ReactComponent as LocationIcon} from "assets/icons/location.svg";
import {ReactComponent as TrashIcon} from "assets/icons/delete.svg";
import Tippy from "components/common/Tippy"
import onClickOutside from "react-onclickoutside"
import cx from "classnames"
import {useGoogleMap} from "@react-google-maps/api"
import _ from "lodash"
import styles from "./POI.module.scss"
import {GooglePlace} from "../../../../../../../proptypes/PropTypeObjects"
import ActionIcon, {
    ActionIconType
} from "../../../../../../../components/common/ActionIcon/ActionIcon"
import useClickOutside from "../../../../../../../utils/hooks/useClickOutside"
import {useResizeDetector} from "react-resize-detector";

export type POIProps = {
    places: GooglePlace[];
    setPlaces: (places: GooglePlace[]) => void;
    bounds?: any; //google.maps.LatLngBounds | google.maps.LatLng ,
    handleFullWidthMap: (fullWidthMap: boolean) => void;
    fullWidthMap: boolean;

    colorPallete: string[];
    indices: string[];
};

function POI({
    places,
    setPlaces,
    bounds,
    handleFullWidthMap,
    fullWidthMap,
    colorPallete,
    indices
}: POIProps) {
    const {t} = useTranslation()
    const [query, setQuery] = useState("")
    const [isOpen, setIsOpen] = useState(false)
    const [isFocused, setIsFocused] = useState(false)
    const [tippyVisible, setTippyIsVisible] = useState(false)
    const [visibleItems, setVisibleItems] = useState(3)
    const [tempPlace, setTempPlace] = useState<GooglePlace>()
    const autoCompleteElRef = useRef<google.maps.places.Autocomplete>()
    const map = useGoogleMap()

    const getNewBounds = useCallback((radiusInMeters: number) => {
        const distanceFromCenterToCorner = radiusInMeters * Math.sqrt(2)
        const {computeOffset} = window.google.maps.geometry.spherical
        let center: google.maps.LatLng | undefined
        if (bounds && bounds.getCenter) {
            center = bounds.getCenter()
        } else {
            center = map?.getCenter()
        }
        if (center === null || center === undefined) {
            //fallback
            return new window.google.maps.LatLngBounds({
                lat: 0,
                lng: 0
            })
        }
        const southwestCorner = computeOffset(
            center,
            distanceFromCenterToCorner,
            225
        )
        const northeastCorner = computeOffset(
            center,
            distanceFromCenterToCorner,
            45
        )
        return new window.google.maps.LatLngBounds(
            southwestCorner,
            northeastCorner
        )
    }, [bounds, map])

    const removePlace = useCallback((placeId: string) => {
        const newPlaces = places.filter((p) => p.placeId !== placeId)
        setPlaces(newPlaces)
    }, [places, setPlaces])

    const addPlace = useCallback((place: GooglePlace) => {
        setTempPlace(place)
    }, [])

    const removeAllPlaces = useCallback(() => {
        setPlaces([]);
    }, [setPlaces]);

    // const resetPlaces = (place) =>setPlaces([])
    const startAutoComplete = useCallback(() => {
        const autoCompleteOptions = {
            strictBounds: true,
            bounds: getNewBounds(50000)
        }
        if (!autoCompleteElRef.current) {
            const inputEl = document.getElementById(
                "poi-search-input"
            ) as HTMLInputElement
            if (inputEl) {
                const autocomplete = new window.google.maps.places.Autocomplete(
                    inputEl
                )
                autocomplete.setFields([
                    "address_components",
                    "formatted_address",
                    "geometry",
                    "name",
                    "place_id"
                ])
                autocomplete.setOptions(autoCompleteOptions)
                autocomplete.addListener("place_changed", () => {
                    const place = autocomplete.getPlace()
                    if (!place.geometry) {
                        return
                    }
                    setQuery("")
                    addPlace({
                        placeId: place.place_id,
                        description: place.name,
                        location: place.geometry.location
                    } as GooglePlace)
                })
                autoCompleteElRef.current = autocomplete
            }
        } else {
            autoCompleteElRef.current.setOptions(autoCompleteOptions)
        }
    }, [addPlace, getNewBounds])

    const onInputChange = useCallback((event: any) => {
        setQuery(event.target.value)
    }, [])

    const focusRef = useRef<HTMLDivElement>(null)
    const visiblePlaces = places.slice(0, isOpen ? 2 : 3)
    const notVisiblePlaces = places.slice(isOpen ? 2 : 3)

    const poiIcon = (
        <span
            ref={focusRef}
            className={cx(
                styles.poiSwitch,
                places.length,
                {[styles.active]: isFocused},
                isFocused && styles.label
            )}
        >
            <LocationIcon width="15px" height="22px" />
        </span>
    );
    (POI as any).handleClickOutside = () => setIsOpen(false)

    const {ref} = useResizeDetector({
        handleWidth: true,
        refreshRate: 100,
        refreshOptions: {
            leading: true
        },
        onResize: _.debounce((width, height) => {
            setVisibleItems(Math.floor((width - 350) / 200))
        }, 50)
    })

    useEffect(() => {
        startAutoComplete()
    }, [query, startAutoComplete])

    useEffect(() => {
        if (tempPlace && !places.find((place) => place.placeId === tempPlace.placeId)) {
            const newPlaces = [...places, tempPlace]
            setPlaces(newPlaces)
            setTempPlace(undefined)
        }
    }, [places, setPlaces, tempPlace])

    useEffect(() => {
        if (isOpen) {
            const inputEl = document.getElementById("poi-search-input")

            if (!inputEl) {
                return
            }

            inputEl.focus()
        }
    }, [isOpen])

    const toggleFocus = () => {
        if (!isFocused) {
            setIsFocused(!isFocused)
        }
    }

    useClickOutside(focusRef, () => {
        setIsFocused(false)
    })

    return (
        <>
            <div
                className={cx(styles.root, fullWidthMap && styles.poiSearchWidth)}
                ref={ref}
            >
                <>
                    <div
                        onClick={toggleFocus}
                        ref={focusRef}
                        className={cx(styles.poiSearch, {
                            [styles.poiSearchNotFocus]: !isFocused
                        })}
                    >
                        {poiIcon}{" "}
                        <input
                            type="text"
                            value={query}
                            onChange={onInputChange}
                            id="poi-search-input"
                        />
                    </div>

                    {!fullWidthMap && places.length !== 0 && (
                        <div className={styles.DeleteAllIcon} onClick={removeAllPlaces}>
                            <TrashIcon />
                        </div>
                    )}

                    {fullWidthMap &&
                        visiblePlaces.map((p, i) => (
                            <div
                                className={cx(styles.box, "mr-10", i === 0 && "ml-10")}
                                key={`${p.placeId}-${i}`}
                            >
                                <div
                                    style={{backgroundColor: colorPallete[i > 10 ? i % 10 : i]}}
                                    className={styles.number}
                                >
                                    {indices[i]}
                                </div>
                                <p>{p.description}</p>
                                <div
                                    className={styles.closeBtn}
                                    onClick={() => removePlace(p.placeId)}
                                >
                                    <ActionIcon type={ActionIconType.CLOSE} />
                                </div>
                            </div>
                        ))}
                    {fullWidthMap && !!notVisiblePlaces.length && (
                        <Tippy
                            key="poi_tippy_overlay"
                            visible={tippyVisible}
                            interactive
                            content={(
                                <div className={styles.tippyContent}>
                                    {notVisiblePlaces.map((p, i) => (
                                        <div
                                            key={p.placeId}
                                            style={{
                                                marginBottom: i + 1 < notVisiblePlaces.length ? 5 : 0
                                            }}
                                            className={cx(styles.box)}
                                        >
                                            <div
                                                style={{
                                                    backgroundColor: colorPallete[i + visiblePlaces.length]
                                                }}
                                                className={styles.number}
                                            >
                                                {indices[visiblePlaces.length + i]}
                                            </div>
                                            <p>{p.description}</p>
                                            <div
                                                className={styles.closeBtn}
                                                onClick={() => removePlace(p.placeId)}
                                            >
                                                <ActionIcon type={ActionIconType.CLOSE} />
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            )}
                        >
                            <div
                                className={styles.moreBox}
                                onClick={() => setTippyIsVisible(!tippyVisible)}
                            >
                                {notVisiblePlaces.length} {t("h_sr_dw_more")}
                                <span className={styles.plus} />
                            </div>
                        </Tippy>
                    )}
                </>
            </div>
        </>
    )
}

const clickOutsideConfig = {
    handleClickOutside: () => (POI as any).handleClickOutside
}

export default onClickOutside(POI, clickOutsideConfig)
