import React, {ReactElement} from "react";
import animatedLogo from "assets/images/logoAnimated.gif";

import {useTranslation} from "react-i18next";
import styles from "./LoadingScreen.module.scss";
import {useAppSelector} from "../../../../redux/hooks";

function LoadingScreen(): ReactElement {
    const {t} = useTranslation();

    const currentDestination = useAppSelector((state) => state.transferSearch.currentDestination);

    return (
        <div className={styles.loadingScreen}>
            <div className={styles.screenBox}>
                <div className={styles.centeredBox}>
                    <img src={animatedLogo} alt="animated logo" />

                    {currentDestination && (
                        <p className={styles.notice}>
                            {t("t_s_ls_searching_page_notice", {
                                destinationName: currentDestination.name || currentDestination.countryName
                            })}
                        </p>
                    )}
                </div>
            </div>
        </div>
    );
}

export default LoadingScreen;
