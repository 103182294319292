import {useTranslation} from "react-i18next";
import React, {ReactElement} from "react";
import {DocumentFile} from "../../../../../proptypes/PropTypeObjects";
import useLocaleDateTimeFormat, {LocaleDateFormatTypeEnum} from "../../../../../components/utils/Internationalization/useLocaleDateTimeFormat";
import styles from "./UploadedFiles.module.scss";
import baseUrl from "../../../../../api/constants/baseURL";
import {ReactComponent as DownloadIcon} from "../../../../../assets/icons/download.svg";
import {ReactComponent as DelIcon} from "../../../../../assets/icons/delete.svg";
import createLuxonDate from "../../../../../utils/date/createLuxonDate"

export default function UploadedFiles({files, deleteFile}: { files: DocumentFile[], deleteFile: (id: number) => void }): ReactElement {
    const {t} = useTranslation();
    const dateTimeFormat = useLocaleDateTimeFormat(LocaleDateFormatTypeEnum.DATE_TIME);

    return (
        <div className={styles.Root}>
            <h4 className={styles.Heading}>{t("mc_a_a_uploaded_files")}</h4>
            <div className={styles.Box}>
                <table className={styles.Table}>
                    <thead>
                        <tr>
                            <th>{t("mc_a_a_uploaded")}</th>
                            <th>{t("mc_a_a_name")}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {files.map(({id, uploaded, fileName}) => (
                            <tr key={id}>
                                <td>{createLuxonDate(uploaded).toFormat(dateTimeFormat)}</td>
                                <td>{fileName}</td>
                                <td className={styles.TableActions}>
                                    <div className={styles.Wrapper}>
                                        <a rel="noopener noreferrer" target="_blank" href={`${baseUrl}/company/document/file/${id}`} download={fileName}>
                                            <DownloadIcon width={11} height={14} />
                                        </a>
                                        <DelIcon onClick={() => deleteFile(id)} width={9} height={10} />
                                    </div>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
}