import React, {ReactElement} from "react";
import {useTranslation} from "react-i18next";
import styles from "./BookingDetails.module.scss";
import BookingRoomSidebar from "../../../components/common/BookingRoomSidebar";
import {ContactInfoPropTypes, HotelBOokingRoomInfoPropTypes} from "../../../proptypes/PropTypeObjects";

type Props = {
    hotelBookingRoomInfo: HotelBOokingRoomInfoPropTypes[],
    contactInfo: ContactInfoPropTypes,
    memo: string | null,
    hideBookingDetailsNote: boolean
};

function BookingDetails({
    hotelBookingRoomInfo = [],
    contactInfo,
    memo,
    hideBookingDetailsNote
}: Props): ReactElement {
    const {t} = useTranslation();

    return (
        <div className={styles.Root}>
            <h3 className={styles.Heading}>{t("h_bc_bd_booking_details")}</h3>
            {!hideBookingDetailsNote && (
                <div className={styles.Comment}>{t("h_bc_bd_can_save_booking_details")}</div>
            )}
            {hotelBookingRoomInfo.map(({adults, children}, i) => (
                <BookingRoomSidebar
                    key={i}
                    roomNo={i}
                    simplified
                    displayChildren
                    childrenData={children}
                    adultsData={adults}
                />
            ))}

            {contactInfo && (
                <div className={styles.Box}>
                    <h4 className={styles.BoxHeading}>{t("h_bc_bd_contact_person")}</h4>

                    <p className="capitalize">{contactInfo.firstname} {contactInfo.lastname}</p>
                    <p>{t("h_bc_bd_email")}: {contactInfo.email}</p>
                    <p>{t("h_bc_bd_phone")}: {contactInfo.phone}</p>
                </div>
            )}

            {memo && (
                <div className={styles.Box}>
                    <h4 className={styles.BoxHeading}>{t("h_bc_bd_memo")}</h4>
                    <p>{memo}</p>
                </div>
            )}
        </div>
    );
}

export default BookingDetails;
