import _ from "lodash";
import {
    AuthenticationSuccessRoute,
    HotelSearchCriteriaPropTypes,
    ItemModificationResponse,
    PasswordModificationResponse,
    RecentSearchPropTypes,
    SavedSearchPropTypes,
    TransferSearchCriteriaPropTypes,
    UserDetailsPropTypes
} from "../proptypes/PropTypeObjects";
import {ConstrainedAxiosRequestConfig} from "./axios/constrainedAxiosRequestConfig";

// export function getSpecicUserDetails(id: any) {
//     return instance.get(`/app/user/${id}/details`);
// }

export function getLoginActiveLinkToken(token: string): ConstrainedAxiosRequestConfig<void> {
    return {
        method: "GET",
        url: "/",
        params: {
            token
        },
        maxNumRequests: 1
    };
}

export function getCurrentUserDetails(): ConstrainedAxiosRequestConfig<UserDetailsPropTypes> {
    return {
        method: "GET",
        url: "/app/user/",
        maxNumRequests: 1
    };
}

export function updateCurrentCompanyUserPassword(password: string, locale: string): ConstrainedAxiosRequestConfig<PasswordModificationResponse, string> {
    return {
        method: "post",
        url: "/app/user/password",
        data: btoa(encodeURI(password)),
        headers: {
            "Content-Type": "application/json",
            Accept: "application/json"
        },
        params: {
            locale
        },
        maxNumRequests: 1
    };
}

export function sendPasswordReminder(email: string): ConstrainedAxiosRequestConfig<ItemModificationResponse, {
    email: string
}> {
    const requestBody = {
        email
    };

    return {
        method: "post",
        url: "/app/user/remind",
        data: requestBody,
        headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            Accept: "application/json"
        },
        maxNumRequests: 1
    };
}

export function resetPasswordUsingToken(password: string, token: string): ConstrainedAxiosRequestConfig<ItemModificationResponse, {
    password: string,
    token: string
}> {
    const requestBody = {
        password: btoa(encodeURI(password)),
        token
    };

    return {
        method: "post",
        url: "/app/user/reset",
        data: requestBody,
        headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            Accept: "application/json"
        },
        maxNumRequests: 1
    };
}

export function login(email: string, password: string, pin?: string): ConstrainedAxiosRequestConfig<AuthenticationSuccessRoute | undefined> {
    let requestBody;

    if (_.isEmpty(pin)) {
        requestBody = {
            email,
            password: btoa(encodeURI(password))
        };
    } else {
        requestBody = {
            email,
            pin
        }
    }

    return {
        method: "post",
        url: "/app/user/login",
        data: requestBody,
        headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            Accept: "application/json"
        }
    };
}

export function logout(): ConstrainedAxiosRequestConfig<void> {
    return {
        method: "GET",
        url: "/app/user/logout",
        maxNumRequests: 1
    };
}

export function putRecentSearch(formData: HotelSearchCriteriaPropTypes | TransferSearchCriteriaPropTypes, executed: string): ConstrainedAxiosRequestConfig<number, HotelSearchCriteriaPropTypes | TransferSearchCriteriaPropTypes> { //iso-string //TODO implement transfer criteria
    return {
        method: "put",
        url: "/app/user/searches/recent",
        data: formData,
        headers: {
            "Content-Type": "application/json",
            Accept: "application/json"
        },
        params: {
            executed
        },
        maxNumRequests: 1
    };
}

export function getRecentSearch(locale: string): ConstrainedAxiosRequestConfig<RecentSearchPropTypes[]> {
    return {
        method: "get",
        url: "/app/user/searches/recent",
        headers: {
            "Content-Type": "application/json",
            Accept: "application/json"
        },
        params: {
            locale
        },
        maxNumRequests: 1
    };
}

export function deleteRecentSearch(searchId: number): ConstrainedAxiosRequestConfig<number> {
    return {
        method: "delete",
        url: "/app/user/searches/recent",
        headers: {
            "Content-Type": "application/json"
        },
        params: {
            searchId
        },
        maxNumRequests: 1
    };
}

export function putSavedSearch(formData: HotelSearchCriteriaPropTypes, executed: string): ConstrainedAxiosRequestConfig<number, HotelSearchCriteriaPropTypes> {
    return {
        method: "put",
        url: "/app/user/searches/saved",
        data: formData,
        headers: {
            "Content-Type": "application/json",
            Accept: "application/json"
        },
        params: {
            executed
        },
        maxNumRequests: 1
    };
}

export function getSavedSearch(locale: string): ConstrainedAxiosRequestConfig<SavedSearchPropTypes[]> {
    return {
        method: "get",
        url: "/app/user/searches/saved",
        headers: {
            "Content-Type": "application/json",
            Accept: "application/json"
        },
        params: {
            locale
        },
        maxNumRequests: 1
    };
}

export function deleteSavedSearch(searchId: number): ConstrainedAxiosRequestConfig<number> {
    return {
        method: "delete",
        url: "/app/user/searches/saved",
        headers: {
            "Content-Type": "application/json"
        },
        params: {
            searchId
        },
        maxNumRequests: 1
    };
}

export function changeUserLocale(locale: string): ConstrainedAxiosRequestConfig<boolean> {
    return {
        method: "put",
        url: "/app/user/locale",
        params: {
            locale
        },
        headers: {
            Accept: "application/json"
        },
        maxNumRequests: 1
    };
}

export function changeUserMarkup(markupId: number): ConstrainedAxiosRequestConfig<boolean> {
    return {
        method: "put",
        url: "/app/user/markup",
        params: {
            markupId
        },
        headers: {
            Accept: "application/json"
        },
        maxNumRequests: 1
    };
}

export function getUserGeoFromIp(): ConstrainedAxiosRequestConfig<string> {
    return {
        method: "get",
        url: "/app/geoip/",
        headers: {
            Accept: "application/json"
        },
        maxNumRequests: 1
    };
}