import React, {ReactElement} from "react";
import ReactPaginate from "react-paginate";
import "./Pagination.scss";

export type PaginationProps = {
    pagesCount?: number;
    handleChangePage: ((page: number) => void) | undefined;
    initialPage?: number;
    pageNumber?: number;
};

function Pagination({
    pagesCount = 0,
    handleChangePage,
    initialPage = 0,
    pageNumber = 1
}: PaginationProps): ReactElement {
    return (
        <ReactPaginate
            previousLabel={<div className="prev-label" />}
            nextLabel={<div className="next-label" />}
            breakLabel="..."
            breakClassName="break-me"
            pageCount={pagesCount}
            forcePage={pageNumber - 1}
            initialPage={initialPage}
            disableInitialCallback
            marginPagesDisplayed={0}
            pageRangeDisplayed={3}
            onPageChange={(data) => {
                if (handleChangePage) {
                    handleChangePage(data.selected + 1);
                }
            }}
            containerClassName="Pagination"
            activeClassName="active"
        />
    );
}

export default Pagination;
