import React, {MouseEvent, ReactElement, useState, useCallback, useMemo} from "react"
import RoomsContainer from "components/common/Rooms";
import Stars from "components/common/Stars";
import FavoriteHotel from "components/common/FavoriteHotel";
import {withTranslation} from "react-i18next";
import {LazyImageSlider, LazyImageSliderImage} from "components/common/LazyImageSlider/LazyImageSlider";
import cx from "classnames";
import {connect, ConnectedProps} from "react-redux";
import styles from "./HotelBox.module.scss";
import {
    HotelFeatureGroupPropTypes,
    HotelPropTypes,
    RoomOfferPropTypes
} from "proptypes/PropTypeObjects";
import HotelCustomerReviews from "components/common/HotelCustomerReviews/HotelCustomerReviews";
import getHotelAddress from "utils/getHotelAddress";
import {changeCenter, changeZoom} from "redux/actions/map.actions";
import {RootState} from "redux/store/store.init";
import createResizedImageUrl, {HOTEL_RESULTS_RESOLUTION} from "utils/thumbnail";
import OneActiveSubject from "utils/generic/oneActiveSubject";
import {Link, useLocation} from "react-router-dom"

type Props = ConnectedProps<typeof connector> & {
    fullWidthMap: boolean;
    key: number;
    hotel: HotelPropTypes;
    roomOffers: RoomOfferPropTypes[][]
    isActivePinnedOffers: boolean,

    zoom: number;
    precalculatedZoom?: number;
    center?: google.maps.LatLng;
    precalculatedCenter?: google.maps.LatLng,
    markersSubject: OneActiveSubject<void, number>;
};

const HotelBox = (props: Props): ReactElement => {
    const {
        hotel: {
            id,
            name,
            starRating,
            descriptionPreview,
            images,
            featureGroups,
            customerRatings,
            reviewRating,
            address,
            distances,
            tripAdvisorRating,
            coordinates
        },
        roomOffers,
        fullWidthMap,
        isActivePinnedOffers,

        changeZoom,
        changeCenter,
        zoom,
        precalculatedZoom,
        precalculatedCenter,

        markersSubject
    } = props;

    const [roomsCanBeInsideHotel, setRoomsCanBeInsideHotel] = useState<boolean>(false);

    const location = useLocation();

    const handleRoomsContainerInsideHotel = useCallback((isAllRoomsShown: boolean) => {
        setRoomsCanBeInsideHotel(fullWidthMap && !isAllRoomsShown);
    }, [fullWidthMap]);

    const features = featureGroups ? (featureGroups).map((e: HotelFeatureGroupPropTypes) => e.hotelFeatures).flat() : [];

    const imageArr = useMemo(() => {
        let ret: LazyImageSliderImage[] = [];
        if (images && images.length !== 0) {
            ret = images.map((image: string) => ({
                original: createResizedImageUrl(image, HOTEL_RESULTS_RESOLUTION),
                thumbnail: createResizedImageUrl(image, HOTEL_RESULTS_RESOLUTION)
            })) as LazyImageSliderImage[];
        }

        return ret;
    }, [images]);

    const hotelFeatureBulletins = useMemo<string[]>(() => {
        const ret = [];

        if (distances && distances.length !== 0) {
            for (let i = 0; i < distances.length && ret.length !== 4; i++) {
                const distance = distances[i];

                if (!distance) {
                    continue;
                }

                ret.push(`${distance.value} ${distance.name}`);
            }
        }

        if (ret.length !== 4 && features && features.length !== 0) {
            for (let i = 0; i < features.length && ret.length !== 4; i ++) {
                const feature = features[i];

                if (!feature) {
                    continue;
                }

                ret.push(feature.name);
            }
        }

        return ret;
    }, [distances, features]);

    const handleAddressClick = useCallback((e: MouseEvent<HTMLParagraphElement>) => {
        e.stopPropagation();
        e.preventDefault();

        if (!precalculatedZoom) {
            return;
        }

        changeZoom(zoom + 2);
        changeCenter(new google.maps.LatLng(coordinates.latitude, coordinates.longitude));
        void markersSubject.next(null, id);
    }, [changeCenter, changeZoom, coordinates.latitude, coordinates.longitude, id, markersSubject, precalculatedZoom, zoom]);

    const onMouseMoveCallback = useCallback(() => {
        if (!precalculatedZoom || !precalculatedCenter) {
            return;
        }

        void markersSubject.next(null, id);
    }, [id, markersSubject, precalculatedCenter, precalculatedZoom]);

    const onMouseEnterCallback = useCallback(() => {
        if (!precalculatedZoom || !precalculatedCenter) {
            return;
        }

        void markersSubject.next(null, id);
    }, [id, markersSubject, precalculatedCenter, precalculatedZoom]);

    const onMouseLeaveCallback = useCallback(() => {
        if (precalculatedZoom && precalculatedCenter) {
            changeCenter(precalculatedCenter);
            changeZoom(precalculatedZoom);
        }
    }, [changeCenter, changeZoom, precalculatedCenter, precalculatedZoom]);

    return (
        <div
            className={styles.HotelBox}
            onMouseMove={onMouseMoveCallback}
            onMouseEnter={onMouseEnterCallback}
            onMouseLeave={onMouseLeaveCallback}
        >
            <div className={cx(styles.ImageGalleryDescriptionContainer)}>
                <div
                    style={{
                        height: "100%",
                        minWidth: "180px",
                        marginBottom: roomsCanBeInsideHotel ? 20 : 0
                    }}
                    className={cx(styles.ImageGalleryContainer)}
                >
                    <LazyImageSlider
                        link={{
                            pathname: `/hotels/hotel/${id}`,
                            search: location.search
                        }}
                        showThumbnails={fullWidthMap}
                        images={imageArr}
                        showFullscreenButton={false}
                        showImageMessage={false}
                    />
                </div>

                <div className={cx(styles.ResultDescription)}>
                    <div className={cx(styles.LinkHeartContainer)}>
                        <Link
                            className={cx(styles.Title)}
                            target="_blank"
                            to={{
                                pathname: `/hotels/hotel/${id}`,
                                search: location.search
                            }}
                        >
                            <h2>{name}</h2>
                        </Link>

                        <FavoriteHotel id={id} className={cx(styles.HeartIcon)}/>
                    </div>

                    <div className={cx(styles.InfoLine)}>
                        <Stars className={cx(styles.Stars)} starRating={starRating}/>
                        {/*<div className={cx(styles.Trust)}>*/}
                        <HotelCustomerReviews
                            className={styles.CustomerReviews}
                            reviewRating={reviewRating}
                            customerRatings={customerRatings}
                            tripAdvisorRating={tripAdvisorRating}
                            hotelName={name}
                        />
                        {/*    <span className="mr-3">5/5 Trust you</span>*/}
                        {/*    <span>4/5 TripAdvisor</span>*/}
                        {/*</div>*/}
                    </div>

                    <div className={cx(styles.Content)}>
                        <p
                            className={cx(styles.Address)}
                            onClick={handleAddressClick}
                            role="link"
                        >
                            {getHotelAddress(address)}
                        </p>

                        <div className={cx(styles.Opinion)}>
                            {descriptionPreview}
                        </div>

                        <div className={cx(styles.FeaturedBullets)}>
                            {hotelFeatureBulletins && hotelFeatureBulletins.length > 0 ? hotelFeatureBulletins.map((bulletin) => (
                                <li key={bulletin}>{bulletin}</li>
                            )) : undefined}
                        </div>
                    </div>
                </div>
            </div>

            <RoomsContainer
                getIsAllRoomsShown={handleRoomsContainerInsideHotel}
                hotelId={id}
                fullWidthMap={fullWidthMap}
                roomOffers={roomOffers}
                isActivePinnedOffers={isActivePinnedOffers}
            />
        </div>
    );
}

const mapStateToProps = (state: RootState) => ({
    center: state.map.center,

    zoom: state.map.zoom,
    precalculatedZoom: state.map.precalculatedZoom,
    precalculatedCenter: state.map.precalculatedCenter
});

const connector = connect(mapStateToProps, {changeCenter, changeZoom});

export default withTranslation()(connector(HotelBox));
