import {
    AirportDestinationPropTypes,
    DestinationDestinationPropTypes,
    HotelDestinationPropTypes
} from "proptypes/PropTypeObjects";
import _ from "lodash";

export default function destinationOptionSorter<
    T extends DestinationDestinationPropTypes | AirportDestinationPropTypes | HotelDestinationPropTypes
>(destinations: T[]): T[] {
    const destinationMap = new Map<DestinationDestinationPropTypes, number>();
    let index: number = 0;
    let prevName: string;
    destinations.forEach(destination => {
        if (!_.eq(prevName, destination.name)) {
            index += 1;
        }

        destinationMap.set(destination, index);
        prevName = destination.name;
    });

    return destinations.sort(
        (a, b) => {
            if (destinationMap.get(a)! === destinationMap.get(b)!) {
                return a.id - b.id;
            } else {
                return destinationMap.get(a)! - destinationMap.get(b)!;
            }
        }
    );
}