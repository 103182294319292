import _ from "lodash";
import {DestinationsTypes} from "../constants/destinations.constants";
import * as destinationsAPI from "../../api/destinationsAPI";
import {DestinationsResultPropTypes, TransferDestinationsResultPropTypes} from "proptypes/PropTypeObjects";
import {AsyncCommonActionReturnType, CommonActionReturnType} from "../store/store.init"
import IAppActions from "../store/store.actions";
import {globalAxiosController} from "api/axios/globalAxiosController";

function getDestinationsRequest(): IAppActions {
    return ({
        type: DestinationsTypes.REQ_DEST
    });
}

function getDestinationsSuccess(destinations: DestinationsResultPropTypes): IAppActions {
    return ({
        type: DestinationsTypes.GET_DEST_SUCCESS,
        destinations
    });
}

function getDestinationsFailure(error: any): IAppActions {
    return ({
        type: DestinationsTypes.GET_DEST_FAILURE,
        error
    });
}

export function getDestinations(name = ""): AsyncCommonActionReturnType {
    return async (dispatch, getState) => {
        if (name.length >= 3) {
            const locale = getState().locale.currentLocale;
            dispatch(getDestinationsRequest());
            await globalAxiosController.addRequest(destinationsAPI.getDestinations(locale, name))
                .then((data) => {
                    dispatch(getDestinationsSuccess(data));
                })
                .catch((error: any) => {
                    // console.log(error);
                    dispatch(getDestinationsFailure(error));
                });
        }
    };
}

function getTransferDestinations(): IAppActions {
    return ({
        type: DestinationsTypes.REQ_TRANSFER_DEST
    });
}

function getTransferDestinationsSuccess(transferDestinations: TransferDestinationsResultPropTypes): IAppActions {
    return ({
        type: DestinationsTypes.GET_TRANSFER_DEST_SUCCESS,
        transferDestinations
    });
}

function getTransferDestinationsFailure(error: any): IAppActions {
    return ({
        type: DestinationsTypes.GET_TRANSFER_DEST_FAILURE,
        error
    });
}

export function getSearchTransferDestination(name = "", originId: string | number): CommonActionReturnType {
    if (_.size(name) >= 2) {
        return (dispatch, getState) => {
            const locale = getState().locale.currentLocale;
            dispatch(getTransferDestinations());
            globalAxiosController.addRequest(destinationsAPI.getSearchTransferDestination(locale, name, originId)).then(
                (data) => {
                    dispatch(getTransferDestinationsSuccess(data));
                }
            ).catch((error: any) => {
                // console.log(error);
                dispatch(getTransferDestinationsFailure(error));
            });
        };
    }

    return (dispatch) => {
        dispatch(getTransferDestinationsSuccess({destinations: [], destinationsCount: 0}));
    };
}

function requestTransferOrigin(): IAppActions {
    return ({
        type: DestinationsTypes.REQ_TRANSFER_ORIGIN
    });
}

function getSearchTransferOriginSuccess(transferOrigins: TransferDestinationsResultPropTypes): IAppActions {
    return ({
        type: DestinationsTypes.GET_TRANSFER_ORIGIN_SUCCESS,
        transferOrigins
    });
}

function getSearchTransferOriginFailure(error: string[]): IAppActions {
    return ({
        type: DestinationsTypes.GET_TRANSFER_ORIGIN_FAILURE,
        error
    });
}

export function getSearchTransferOrigin(name = ""): CommonActionReturnType {
    if (_.size(name) >= 2) {
        return (dispatch, getState) => {
            const locale = getState().locale.currentLocale;
            dispatch(requestTransferOrigin());
            globalAxiosController.addRequest(destinationsAPI.getSearchTransferOrigin(locale, name)).then(
                (data) => {
                    dispatch(getSearchTransferOriginSuccess(data));
                }
            ).catch((error: any) => {
                // console.log(error);
                dispatch(getSearchTransferOriginFailure([error as string]));
            });
        };
    }

    return (dispatch) => {
        dispatch(getSearchTransferOriginSuccess({destinations: [], destinationsCount: 0}));
    };
}
