import React, {ReactElement} from "react";
import {Trans, useTranslation} from "react-i18next";
import styles from "./Reconcile.module.scss";
import getCurrencySymbol from "@hotelston_web_frontend_utils/currency/getCurrencySymbol";
import Currency from "@hotelston_web_frontend_parent/constants/currency";

type Props = {
    overpayment: number;
    currency: Currency;
    bookingId: number;
    cancelReconcile: () => void;
};

function Reconcile({
    overpayment,
    currency,
    bookingId,
    cancelReconcile
}: Props): ReactElement {
    const {t} = useTranslation();

    return (
        <div className={styles.Root}>
            <h1 className={styles.Title}>{t("mb_rec_reconcile_overpayment")}</h1>

            <p>
                {t("mb_rec_reconcile_decision_line", {
                    overpayment: overpayment.toFixed(2),
                    currency: getCurrencySymbol(currency),
                    bookingId
                })}
            </p>

            <p>{t("mb_rec_reconcile_please_filter_line")}</p>

            <p>
                <Trans i18nKey="mb_rec_reconcile_change_mind_line">
                    If you have changed your mind and no longer want to use your
                    overpayment to pay for other bookings,
                    <a onClick={cancelReconcile}>click here</a>
                    to change your decision.
                </Trans>
            </p>
        </div>
    );
}

export default Reconcile;
