import _ from "lodash";
import {POSSIBLE_ARRAY_PARAM_RESOLVER, setUrlParamPrimitive} from "../queryParamsUtils";
import {QueryParamSerializerDeserializerResolver, QueryParamsObject} from "../queryParamsTypes";

const SortBySerializer: QueryParamSerializerDeserializerResolver = {
    serializer: (query: QueryParamsObject, param: string, object: { label?: string | number, value?: string | number }) => {
        setUrlParamPrimitive(query, param + "l", _.toString(object.label));
        setUrlParamPrimitive(query, param + "v", _.toString(object.value));
    },

    resolver: POSSIBLE_ARRAY_PARAM_RESOLVER(["sbl", "sbv"]),
    deserializer: (query: QueryParamsObject) => ({
        label: query.sbl,
        value: query.sbv
    } as { label?: string | number, value?: string | number }),
    array: false
};

export default SortBySerializer;