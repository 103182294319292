import React, {ReactElement} from "react"
import {useTranslation} from "react-i18next"
import InfoBox from "components/common/InfoBox"
import {PaymentTransferTypePropTypes} from "proptypes/PropTypeObjects"
import {ReactComponent as PayLaterIcon} from "assets/icons/pay later.svg";
import styles from "components/common/Payment/PaymentContent/PaymentContent.module.scss"
import {useAppDispatch} from "redux/hooks";
import usePaymentOptions from "components/base/Payment/PaymentType/usePaymentOptions";
import {calculateTransactionFee} from "redux/actions/pricing.actions";

export type SuperAgentPaymentBlockProps = {
    paymentTransferType?: PaymentTransferTypePropTypes;
    toggleSelectedPayment: (paymentType: PaymentTransferTypePropTypes, keepSelected?: boolean) => void;
    paymentDeadlineDate?: string;
    paymentDeadlineTime?: string;
    children: ReactElement;
}

const SuperAgentPaymentBlock = (props: SuperAgentPaymentBlockProps): ReactElement => {
    const {
        paymentTransferType,
        toggleSelectedPayment,
        paymentDeadlineTime,
        paymentDeadlineDate,
        children
    } = props;

    const {
        paymentOptions
    } = usePaymentOptions();

    const {t} = useTranslation();
    const dispatch = useAppDispatch();

    return (
        <InfoBox
            style={{marginBottom: 15}}
            active={paymentTransferType === PaymentTransferTypePropTypes.SUPERAGENT_PAYMENT}
            title={t("pc_pc_superagent_payment_title", {superAgentName: paymentOptions?.superAgentName})}
            message={t("pc_pc_superagent_payment_subtitle", {superAgentName: paymentOptions?.superAgentName})}
            customIcon={<PayLaterIcon/>}
            miniIcon
            onClick={() => {
                toggleSelectedPayment(PaymentTransferTypePropTypes.SUPERAGENT_PAYMENT);
                dispatch(calculateTransactionFee());

                return false;
            }}
            type="custom"
        >
            <div className={styles.Option}>
                <div className={styles.OptionText}>
                    <p>
                        {paymentOptions?.afterDeadline ? t("pc_pc_superagent_payment_message_1", {superAgentName: paymentOptions?.superAgentName}) : t("pc_pc_superagent_payment_refundable_message_1", {superAgentName: paymentOptions?.superAgentName})}
                    </p>

                    <p>
                        {paymentOptions?.afterDeadline ? t("pc_pc_superagent_payment_message_2", {
                            superAgentName: paymentOptions?.superAgentName,
                            paymentDeadlineDate,
                            paymentDeadlineTime
                        }) : t("pc_pc_superagent_payment_refundable_message_2", {
                            superAgentName: paymentOptions?.superAgentName,
                            paymentDeadlineDate,
                            paymentDeadlineTime
                        })}
                    </p>
                </div>

                <div
                    style={{
                        marginTop: 30,
                        marginBottom: 30
                    }}
                    className="h-divider"
                />

                {children}
            </div>
        </InfoBox>
    );
}

export default SuperAgentPaymentBlock;