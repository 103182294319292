export default function downloadFile(file: File): void {
    const filename = file.name;
    const blob = new Blob([file]);
    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.download = filename;
    a.href = url;
    a.target = "_blank";
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
}