enum MultiPaymentTypes {
    TOGGLE_MULTIPLE_ORDERS = "multiPayment/TOGGLE_MULTIPLE_ORDERS",

    ADD_ACTIVE_ORDER = "multiPayment/ADD_ACTIVE_ORDER",
    REMOVE_ACTIVE_ORDER = "multiPayment/REMOVE_ACTIVE_ORDER",

    CLEAR_MULTI_PAYMENT_DATA = "multiPayment/CLEAR_MULTI_PAYMENT_DATA",

    TOGGLE_AGREE_TERMS = "multiPayment/TOGGLE_AGREE_TERMS"
}

export default MultiPaymentTypes;