import React, {ReactElement, useCallback, useEffect} from "react";
import _ from "lodash";
import {SharedPredicateProps} from "../SearchPredicates.base";
import BasePredicate from "../BasePredicate";
import {
    ClientBookingStatusKeyValuePropTypes,
    ClientBookingStatusPropTypes
} from "../../../../../proptypes/PropTypeObjects";

export default function BookingStatusBubble({
    collectPipeline,
    label,
    value,
    onRemove
}: SharedPredicateProps<ClientBookingStatusKeyValuePropTypes>): ReactElement {
    const formBuilderCallback = useCallback((data: any) => ({
        ...data,
        statuses: [...((_.get(data, "statuses") as ClientBookingStatusPropTypes[]) || []), value.value]
    }), [value]);

    useEffect(() => {
        collectPipeline.subscribe(formBuilderCallback);

        return () => {
            collectPipeline.unsubscribe(formBuilderCallback);
        };
    }, [collectPipeline, formBuilderCallback]);

    return (
        <BasePredicate reference={value.value} onRemove={onRemove}>
            <span style={{whiteSpace: "nowrap"}}>
                {label}
            </span>
        </BasePredicate>
    );
}