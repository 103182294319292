import React, {
    ReactElement, useEffect, useRef, useState
} from "react"
import cx from "classnames"
import _ from "lodash"
import {useTranslation} from "react-i18next"
import styles from "./ResponsiveTable.module.scss"
import ResponsiveTableHorizontalScrollbar
    from "./ResponsiveTableHorizontalScrollbar/ResponsiveTableHorizontalScrollbar"
import TableRowElement from "./TableRow/TableRow"
import {MobileTableVariant, TableHeading, TableRow} from "./ResponsiveTable.base"
import useMobileSwitch from "../../../utils/hooks/useMobileSwitch"
import SkeletonLoader from "../../utils/SkeletonLoader/SkeletonLoader"

export type ResponsiveTableProps = {
    tHeadings?: TableHeading[];
    tRows?: TableRow[];
    firstColumnSticky: boolean;
    withoutBorder?: boolean;
    className?: string;
    loading?: boolean;
    mobileVariant?: MobileTableVariant;
    autoSize?: boolean;
    transparent?: boolean;
    scrollbar?: boolean;
};

function ResponsiveTable({
    firstColumnSticky,
    withoutBorder = false,
    tHeadings = [],
    tRows = [],
    className,
    loading,
    mobileVariant = "Card",
    autoSize = true,
    transparent,
    scrollbar = true
}: ResponsiveTableProps): ReactElement {
    const {t} = useTranslation()
    const tableContainerRef = useRef<HTMLDivElement>(null)
    const tableRef = useRef<HTMLTableElement>(null)
    const {isMobile} = useMobileSwitch()

    const [maxColumnWidth, setMaxColumnWidth] = useState(0)
    useEffect(() => {
        if (!tableRef.current || isMobile) {
            return
        }

        setMaxColumnWidth(autoSize ? 0 : tableRef.current.offsetWidth / tHeadings.length)
    }, [autoSize, isMobile, tHeadings?.length])

    return (
        <div
            className={cx(
                styles.Root,
                firstColumnSticky && styles.FirstColumnSticky,
                withoutBorder && styles.WithoutBorder,
                mobileVariant === "Accordion" && styles.AccordionVariant,
                transparent && styles.Transparent,
                loading && styles.ResponsiveTableLoading,
                className
            )}
        >
            <div
                className={styles.TableContainer}
                ref={tableContainerRef}
            >
                <table
                    className={styles.ResponsiveTable}
                    ref={tableRef}
                >
                    <thead className={styles.TableHeader}>
                        <tr>
                            {tHeadings.map(({
                                onClick, element, sortable, sortVariant, style
                            }, i) => (
                                <th
                                    key={i}
                                    className={cx(styles.HeaderCell, sortable && styles.Sortable)}
                                >
                                    <span
                                        style={style}
                                        {...(sortable && {onClick})}
                                    >
                                        <span className={styles.TableHeaderSortControlsContainer}>
                                            <span>
                                                {element}
                                            </span>

                                            <span
                                                className={styles.SortControlsContainer}
                                                style={{marginLeft: "10px"}}
                                            >
                                                {sortable && (
                                                    <span
                                                        className={cx(styles.TableHeaderSortControls, sortVariant && styles[sortVariant])}
                                                    />
                                                )}
                                            </span>
                                        </span>
                                    </span>
                                </th>
                            ))}
                        </tr>
                    </thead>

                    {!loading && (
                        <tbody className={styles.TableBody}>
                            {tRows.map(({
                                tDatas, actionTd, actionable, variant, onClick
                            }) => (
                                <TableRowElement
                                    key={_.uniqueId("TableRowElement_")}
                                    onClick={onClick}
                                    actionTd={actionTd}
                                    actionable={actionable}
                                    tDatas={tDatas}
                                    mobileVariant={mobileVariant}
                                    maxColumnWidth={maxColumnWidth}
                                    variant={variant}
                                    headings={tHeadings}
                                    isMobile={isMobile}
                                />
                            ))}

                            {tRows?.length === 0 && (
                                <tr className={styles.NoItemsPlaceholder}>
                                    <td colSpan={tHeadings?.length}>{t("r_t_no_items_found")}</td>
                                </tr>
                            )}
                        </tbody>
                    )}
                </table>

                {loading && (
                    <SkeletonLoader className={styles.SkeletonLoader}>
                        <div data-skeleton-animated="true" />
                        <div data-skeleton-animated="true" />
                        <div data-skeleton-animated="true" />
                        <div data-skeleton-animated="true" />
                        <div data-skeleton-animated="true" />
                        <div data-skeleton-animated="true" />
                        <div data-skeleton-animated="true" />
                        <div data-skeleton-animated="true" />
                    </SkeletonLoader>
                )}
            </div>

            {scrollbar ? (
                <ResponsiveTableHorizontalScrollbar
                    tableContainerRef={tableContainerRef}
                    tableRef={tableRef}
                    columns={tHeadings.length}
                />
            ) : undefined}
        </div>
    )
}

export default React.memo(ResponsiveTable)
