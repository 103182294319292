import {TFunction} from "i18next";

export default function initQuickFilters(t: TFunction): { boardTypes: { value: string | number; label: string; }[]; features: { value: number; label: string; }[]; } {
    return {
        boardTypes: [
            {
                value: "All",
                label: t("h_h_sbc_all")
            },
            {
                value: 30309844,
                label: t("h_h_sbc_bt_ro")

            },
            {
                value: 30309841,
                label: t("h_h_sbc_bt_bb")

            },
            {
                value: 30309843,
                label: t("h_h_sbc_bt_hb")

            },
            {
                value: 30309842,
                label: t("h_h_sbc_bt_fb")

            },
            {
                value: 30309840,
                label: t("h_h_sbc_bt_ai")

            }
        ],
        features: [
            {
                value: 39969645,
                label: t("h_h_sbc_ft_internet")

            },
            {
                value: 32993053,
                label: t("h_h_sbc_ft_ac")

            },
            {
                value: 39970381,
                label: t("h_h_sbc_ft_parking")

            },
            {
                value: 39970704,
                label: t("h_h_sbc_ft_pool")

            },
            {
                value: 39973667,
                label: t("h_h_sbc_ft_minibar")

            },
            {
                value: 39971934,
                label: t("h_h_sbc_ft_hair_dryer")

            },
            {
                value: 39972266,
                label: t("h_h_sbc_ft_safe")

            },
            {
                value: 39972727,
                label: t("h_h_sbc_ft_restaurant")
            }

        ]
    };
}