const globalFetch = (typeof window !== "undefined" && window.fetch) ||
    (typeof global !== "undefined" && global.fetch);

export default function fetchWithCookies(input: RequestInfo, init?: (RequestInit | undefined)): Promise<Response> {
    if (globalFetch) {
        const fetchOptions = {
            ...init,
            credentials: "include"
        } as RequestInit;
        return globalFetch(input, fetchOptions);
    }
    return Promise.reject(new Error("no fetch implementation"));
}
