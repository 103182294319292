import React, {ReactElement, useCallback, useState} from "react";
import {Trans, useTranslation} from "react-i18next";
import cx from "classnames";
import InfoBox from "../../../../components/common/InfoBox";
import Spinner from "../../../../components/base/Loaders/Spinner";
import styles from "./CompanyUserView.module.scss";
import UserButton from "../../../../components/base/UserButton";
import {UserButtonVariant} from "components/base/UserButton/UserButton";
import UserDetailsModal from "../../../../components/common/UserDetailsModal";
import SuccessModal from "../../../../components/common/ConfirmationModal/SuccessModal";
import {useAppDispatch, useAppSelector} from "redux/hooks";
import {UserDetailsModalType} from "components/common/UserDetailsModal/UserDetailsModal";
import {
    CompanyUserPropTypes,
    CompanyUserWithPassword,
    ItemModificationResponse,
    UserRole
} from "proptypes/PropTypeObjects";
import WeakPasswordModal from "../../../../components/common/WeakPasswordModal";
import usePasswordUpdateAction from "../../../../components/utils/usePasswordUpdate";
import IAppActions from "../../../../redux/store/store.actions";
import AuthTypes from "../../../../redux/constants/auth.constants";
import useDefaultFeatureEnabled from "../../../../utils/hooks/useDefaultFeatureEnabled";
import SkeletonLoader from "../../../../components/utils/SkeletonLoader/SkeletonLoader";
import {useLocation, useNavigate} from "react-router-dom"

type Props = {
    companyUser?: CompanyUserPropTypes;
    requestingUserUpdate: boolean;
    userUpdateErrors?: string[];
    updateUserAction: (user: CompanyUserWithPassword) => Promise<ItemModificationResponse | undefined>;

    //only for other users
    updateCompanyUser?: (companyUser: CompanyUserPropTypes) => void;
    isAdmin?: boolean;
    temporaryPassword?: boolean;
    companyDataError?: string;
    requestingCompanyDetails?: boolean;
    clearState: () => void;
};

export default function CompanyUserView({
    companyUser,
    requestingCompanyDetails,
    companyDataError,
    isAdmin,
    temporaryPassword,
    requestingUserUpdate,
    userUpdateErrors,
    updateUserAction,
    updateCompanyUser,
    clearState

}: Props): ReactElement {
    const {t} = useTranslation();

    const {
        errors: errorsPassword,
        updateCurrentCompanyUserPassword,
        updating: updatingPassword
    } = usePasswordUpdateAction();

    const location = useLocation();
    const navigate = useNavigate();
    const hasWeakPasswordParam = new URLSearchParams(location.search).get("weakPassword") === "true";

    const dispatch = useAppDispatch();
    const {
        featureEnabled: amadeusSettingsEnabled,
        featuresLoaded
    } = useDefaultFeatureEnabled("amadeusXML");

    const updateRoles = (roles?: UserRole[]) => {
        const success = (roles?: UserRole[]): IAppActions => ({
            type: AuthTypes.ROLES_UPDATE,
            roles
        });
        dispatch(success(roles));
    };

    const hasWeakPasswordRole = useAppSelector((state) => state.auth.userData?.roles?.includes(UserRole.ROLE_WEAK_PASSWORD));

    const [successModal, setSuccessModal] = useState(false);
    const [userEditModalOpen, setUserEditModalOpen] = useState(false);
    const [passwordEditModalOpen, setPasswordEditModalOpen] = useState(false);
    const [weakPasswordBlockedEditModalOpen, setWeakPasswordBlockedEditModalOpen] = useState(hasWeakPasswordRole || hasWeakPasswordParam || false);

    const [amadeusSettingsModalOpen, setAmadeusSettingsModalOpen] = useState(false);

    const onSuccessWeakPassword = useCallback((user: CompanyUserWithPassword) => {
        updateCurrentCompanyUserPassword(user.password).then((roles) => {
            if (!userUpdateErrors) {
                setWeakPasswordBlockedEditModalOpen(false);
                if (updateRoles) {
                    updateRoles(roles);
                }
                setSuccessModal(true);
                removeWeakPasswordURLParam();
            }
        }).catch((reason) => {
            // console.log(reason);
        });
    }, [removeWeakPasswordURLParam, updateCurrentCompanyUserPassword, updateRoles, userUpdateErrors]);

    const onSuccess = useCallback((user: CompanyUserWithPassword) => {
        updateUserAction(user).then((value) => {
            if (value && value.success) {
                setUserEditModalOpen(false);
                if (updateCompanyUser) {
                    updateCompanyUser(user);
                }
                setSuccessModal(true);
            }
        }).catch((reason) => {
            // console.log(reason);
        });
    }, [updateCompanyUser, updateUserAction]);

    const onSuccessPassword = useCallback((user: CompanyUserWithPassword) => {
        updateUserAction(user).then((value) => {
            if (value && value.success) {
                setPasswordEditModalOpen(false);
                setSuccessModal(true);
            }
        }).catch((reason) => {
            // console.log(reason);
        });
    }, [updateUserAction]);

    const onSuccessAmadeus = useCallback((user: CompanyUserWithPassword) => {
        updateUserAction(user).then((value) => {
            if (value && value.success) {
                setAmadeusSettingsModalOpen(false);
                if (updateCompanyUser) {
                    updateCompanyUser(user);
                }
                setSuccessModal(true);
            }
        }).catch((reason) => {
            // console.log(reason);
        });
    }, [updateCompanyUser, updateUserAction]);

    function removeWeakPasswordURLParam() {
        const queryParams = new URLSearchParams(location.search);
        if (queryParams.has("weakPassword")) {
            queryParams.delete("weakPassword");
            navigate({
                search: queryParams.toString()
            }, {replace: true});
        }
    }

    return (
        <>
            {!!companyDataError && !requestingCompanyDetails && (
                <InfoBox
                    className="mb-60"
                    title={t("mc_mcc_something_went_wrong")}
                    message={companyDataError}
                    type="warn"
                />
            )}

            {successModal && (
                <SuccessModal
                    onClose={() => setSuccessModal(false)}
                    successMessage={t("mc_edu_eud_success_message_user_details_update")}
                />
            )}

            {requestingCompanyDetails && (
                <Spinner
                    size="50px"
                    style={{
                        width: "100%",
                        height: "calc(100vh - 200px)"
                    }}
                />
            )}

            {userEditModalOpen && companyUser && (
                <UserDetailsModal
                    onConfirm={onSuccess}
                    companyUser={companyUser}
                    onDecline={() => setUserEditModalOpen(false)}
                    title={t("mc_edu_eud_general_settings")}
                    errors={userUpdateErrors}
                    spinner={requestingUserUpdate}
                    variant={UserDetailsModalType.DETAILS}
                    canMakeAdmin={isAdmin}
                />
            )}

            {passwordEditModalOpen && companyUser && (
                <UserDetailsModal
                    onConfirm={onSuccessPassword}
                    companyUser={companyUser}
                    onDecline={() => setPasswordEditModalOpen(false)}
                    title={temporaryPassword ? t("mc_edu_cuv_temporary_password") : t("mc_edu_cuv_change_password")}
                    errors={userUpdateErrors}
                    spinner={requestingUserUpdate}
                    variant={UserDetailsModalType.PASSWORD}
                    canMakeAdmin={isAdmin}
                    temporaryPassword={temporaryPassword}
                    temporaryPasswordNotice={temporaryPassword}
                />
            )}

            {(weakPasswordBlockedEditModalOpen) && companyUser && (
                <WeakPasswordModal
                    onConfirm={onSuccessWeakPassword}
                    companyUser={companyUser}
                    onDecline={() => {
                        setWeakPasswordBlockedEditModalOpen(false);
                        removeWeakPasswordURLParam();
                    }}
                    errors={errorsPassword}
                    spinner={updatingPassword}
                    blocked={hasWeakPasswordRole || false}
                />
            )}

            {amadeusSettingsModalOpen && companyUser && (
                <UserDetailsModal
                    onConfirm={onSuccessAmadeus}
                    companyUser={companyUser}
                    onDecline={() => {
                        setAmadeusSettingsModalOpen(false);
                    }}
                    title={t("mc_edu_eud_amadeus_settings")}
                    errors={userUpdateErrors}
                    spinner={requestingUserUpdate}
                    variant={UserDetailsModalType.AMADEUS}
                    canMakeAdmin={isAdmin}
                />
            )}

            {!requestingCompanyDetails && (
                <div className={cx(styles.RootContainer, !amadeusSettingsEnabled && styles.SingleView)}>
                    <div className={styles.HeaderContainer}>
                        {/*<div className={styles.IconContainer}>*/}
                        {/*    <SettingsIcon />*/}
                        {/*</div>*/}

                        <h1>
                            <Trans
                                i18nKey="mc_edu_eud_general_settings_header_intro"
                                values={{name: companyUser?.firstname + " " + companyUser?.lastname}}
                            >
                                Settings for {companyUser?.firstname} {companyUser?.lastname}
                            </Trans>
                        </h1>
                    </div>

                    {!featuresLoaded && (
                        <SkeletonLoader>
                            <div className={styles.SkeletonContainer}>
                                <div className={styles.SkeletonDescriptionContainer}>
                                    <div className={styles.SkeletonTitle} data-skeleton-animated="true"/>
                                    <div className={styles.SkeletonDescription} data-skeleton-animated="true"/>
                                </div>

                                <div className={styles.SkeletonDescriptionContainer}>
                                    <div className={styles.SkeletonTitle} data-skeleton-animated="true"/>
                                    <div className={styles.SkeletonDescription} data-skeleton-animated="true"/>
                                </div>
                            </div>
                        </SkeletonLoader>
                    )}

                    {featuresLoaded && (
                        <div className={styles.GeneralInformationWrapper}>
                            <div className={styles.GeneralInformationWrapperItem}>
                                <h2>{t("mc_edu_eud_general_settings")}</h2>

                                <div className={styles.GeneralInformationContainer}>
                                    <dl className={styles.GeneralDataEntriesContainer}>
                                        <dt>{t("mc_edu_eud_general_settings_name")}</dt>
                                        <dd>{companyUser?.firstname} {companyUser?.lastname}</dd>

                                        <dt>{t("mc_edu_eud_general_settings_email")}</dt>
                                        <dd>{companyUser?.email}</dd>

                                        <dt>{t("mc_edu_eud_general_settings_phone_number")}</dt>
                                        <dd>{companyUser?.phone}</dd>

                                        <dt>{t("mc_edu_eud_general_settings_administrator")}</dt>
                                        <dd>{companyUser?.admin?.toString()}</dd>
                                    </dl>

                                    <div className={styles.ButtonsContainer}>
                                        <UserButton
                                            text={t("mc_edu_eud_general_settings_edit")}
                                            buttonProps={{
                                                onClick: () => {
                                                    clearState();
                                                    setUserEditModalOpen(true);
                                                }
                                            }}
                                            variant={UserButtonVariant.PRIMARY}
                                        />

                                        <UserButton
                                            text={t("mc_edu_eud_general_settings_change_password")}
                                            buttonProps={{
                                                onClick: () => {
                                                    clearState();
                                                    setPasswordEditModalOpen(true);
                                                }
                                            }}
                                            variant={UserButtonVariant.PRIMARY}
                                        />
                                    </div>
                                </div>
                            </div>

                            {amadeusSettingsEnabled && (
                                <div className={styles.GeneralInformationWrapperItem}>
                                    <h2>{t("mc_edu_eud_amadeus_settings")}</h2>

                                    <div className={styles.GeneralInformationContainer}>
                                        <dl className={styles.GeneralDataEntriesContainer}>
                                            <dt>{t("mc_edu_eud_amadeus_settings_user_sign")}</dt>
                                            <dd>{companyUser?.amadeusAgentUserSign || "-"}</dd>

                                            <dt>{t("mc_edu_eud_amadeus_settings_pcc")}</dt>
                                            <dd>{companyUser?.amadeusPcc || "-"}</dd>
                                        </dl>

                                        <div className={styles.ButtonsContainer}>
                                            <UserButton
                                                text={t("mc_edu_eud_amadeus_settings_edit")}
                                                buttonProps={{
                                                    onClick: () => {
                                                        clearState();
                                                        setAmadeusSettingsModalOpen(true);
                                                    }
                                                }}
                                                variant={UserButtonVariant.PRIMARY}
                                            />
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    )}
                </div>
            )}
        </>
    );
}