import React, {ReactElement} from "react"
import {useTranslation} from "react-i18next"
import usePaymentOptions from "components/base/Payment/PaymentType/usePaymentOptions"
import {useAppSelector} from "redux/hooks"
import styles from "../../../MyBookings/Payment/PaymentSummary.module.scss"
import cx from "classnames"
import createLuxonDate from "utils/date/createLuxonDate"
import Pricing from "components/common/Pricing/Pricing"
import useLocaleDateTimeFormat, {
    LocaleDateFormatTypeEnum
} from "components/utils/Internationalization/useLocaleDateTimeFormat"

const PaymentSummary = (): ReactElement => {
    const {t} = useTranslation();
    const dateFormat = useLocaleDateTimeFormat(LocaleDateFormatTypeEnum.DATE);

    const {
        paymentOptions,
        transactionFee
    } = usePaymentOptions();
    const activeOrders = useAppSelector((state) => state.payment.anonymousActiveOrders || []);

    return (
        <div className={styles.Root}>
            <h3 className={styles.Title}>
                {t("pc_pc_pl_payment_summary")}
                {/*<Link className={styles.BackLink} to="/my-bookings">*/}
                {/*    {t("mb_ps_back_to_booking")}*/}
                {/*</Link>*/}
            </h3>

            {activeOrders.map(({
                id, serviceStartDate, displayServiceName, leadPassengerName, reference
            }) => (
                <div key={id} className={cx(styles.Box, styles.Single)}>
                    <h4>{t("pc_pc_pl_booking_id", {id: id})}</h4>

                    <dl>
                        <dt>{t("pc_pc_pl_summary_service_name")}:</dt>
                        <dd>{displayServiceName}</dd>

                        {/*<dt>{t("mb_ps_single_lead_passenger")}</dt>*/}
                        {/*<dd>{leadPassenger?.title} {leadPassenger?.firstname} {leadPassenger?.lastname}</dd>*/}

                        <dt>{t("pc_pc_pl_summary_single_service_start")}</dt>
                        <dd>{createLuxonDate(serviceStartDate).toFormat(dateFormat)}</dd>

                        {/*<dt>{t("mb_ps_single_service_end")}</dt>*/}
                        {/*<dd>{createLuxonDate(serviceEndDate).toFormat(dateFormat)}</dd>*/}
                    </dl>
                </div>
            ))}

            <div
                style={{
                    marginTop: 30,
                    marginBottom: 30
                }}
                className="h-divider"
            />

            <Pricing
                displaySalePrice={paymentOptions?.displaySalePrice || 0}
                greyLabels
                salePrice={paymentOptions?.salePrice || 0}
                paymentPending={paymentOptions?.paymentPending || 0}
            />
        </div>
    );
}

export default PaymentSummary;