import React, {useEffect} from "react";
import PaymentProviderRedirect from "components/common/PaymentProviderRedirect/PaymentProviderRedirect";
import InfoBox from "components/common/InfoBox";
import RedirectingModal from "components/common/Modal/RedirectingModal";
import isEqual from "lodash/isEqual";
import Spinner from "components/base/Loaders/Spinner";
import {connect, ConnectedProps} from "react-redux";
import {useTranslation} from "react-i18next";
import PaymentSummary from "../Payment/PaymentSummary";
import MainContainer from "../../../components/base/MainContainer";
import Content from "./Content";
import styles from "./BookingChanged.module.scss";
import CancelAndSearchAgain from "../../../components/common/CancelAndSearchAgain";
import DuplicateBooking from "./DuplicateBooking";
import {
    cancelAndSearchAgain,
    confirmDuplicateBooking,
    updateBookingOptions
} from "redux/actions/transferBooking.actions";
import router from "views/router/router"
import {useAppSelector} from "redux/hooks"
import {RootState} from "redux/store/store.init";

type BookingChangedContainerProps = ConnectedProps<typeof connector>;

// routes:
// "OK"
// "NO_AVAILABILITY"
// "CHANGES"
// "DUPLICATE_BOOKINGS"

const BookingChangedContainer = ({
    transferBooking,
    updateBookingOptions,
    lastSearchResultsLocation,
    confirmDuplicateBooking,
    cancelAndSearchAgain,
    currency,
    companyCurrency,
    transferAmount,
    currentOrigin,
    currentDestination,
    locale,
    providerPayment
}: BookingChangedContainerProps) => {
    const {
        firstTimeChanges,
        data,
        requestingBooking,
        transfer,
        bookingOptionsParams
    } = transferBooking;

    const loading = useAppSelector((state) => state.payment.loading);

    if (!data) {
        return (
            <></>
        );
    }

    const {
        route,
        transferBookingInfo,
        transferAvailabilityCriteria,
        bookingInfo,
        duplicateBookings,
        errors
    } = data;

    const {t} = useTranslation();

    useEffect(() => {
        if (
            !loading &&
            !requestingBooking &&
            bookingOptionsParams &&
            !isEqual({
                locale,
                currency
            }, bookingOptionsParams)
        ) {
            updateBookingOptions();
        }
    }, [
        locale,
        currency,
        updateBookingOptions,
        loading,
        requestingBooking,
        bookingOptionsParams
    ]);

    return requestingBooking ? (
        <Spinner
            size="50px"
            style={{
                width: "100%",
                height: "calc( 100vh - 120px )"
            }}
        />
    ) : (
        <MainContainer
            sidebar={
                !firstTimeChanges && (
                    <PaymentSummary/>
                )
            }
        >
            <Content>
                {(() => {
                    switch (route) {
                    case "VALIDATION_ERRORS":
                        return (
                            <>
                                <div className={styles.header}>
                                    <InfoBox
                                        className="mb-60"
                                        title={t("t_b_bc_bcc_reservation_failed")}
                                        message={errors?.map((specificError: string) => t(specificError))}
                                        type="error"
                                    />
                                </div>
                            </>
                        );
                    case "NO_AVAILABILITY":
                        return (
                            <>
                                <div className={styles.header}>
                                    <h1>{t("t_b_bc_bcc_reservation_failed")}</h1>

                                    <InfoBox
                                        className="mb-60"
                                        title={t("t_b_bc_bcc_not_available_transfer")}
                                        message={t(
                                            "t_b_bc_bcc_not_available_transfer_message"
                                        )}
                                        type="error"
                                    />
                                </div>

                                <div className={styles.heading}>
                                    {t("t_b_bc_bcc_choose_an_alternative")}:
                                </div>
                            </>
                        );
                    case "DUPLICATE_BOOKINGS":
                        return (
                            <>
                                <div className={styles.header}>
                                    <h1>{t("t_b_bc_bcc_possible_double_booking")}</h1>
                                    <InfoBox
                                        className="mb-60"
                                        title={t("t_b_bc_bcc_attention")}
                                        message={t(
                                            "t_b_bc_bcc_notice_about_possible_double_booking"
                                        )}
                                        type="warn"
                                    />
                                </div>

                                <DuplicateBooking
                                    duplicateBookings={duplicateBookings}
                                    goBack={() => router.navigate("/transfers/booking/")}
                                    confirmDuplicateBooking={
                                        confirmDuplicateBooking
                                    }
                                />
                            </>
                        );

                    default:
                        return false;
                    }
                })()}
            </Content>

            {!!providerPayment && (
                <PaymentProviderRedirect {...providerPayment}>
                    <RedirectingModal/>
                </PaymentProviderRedirect>
            )}

            {route !== "DUPLICATE_BOOKINGS" && route !== "OK" && (
                <CancelAndSearchAgain
                    canSavePassengerDetails={!firstTimeChanges}
                    routeBack={lastSearchResultsLocation}
                    cancelAndSearchAgain={cancelAndSearchAgain}
                />
            )}
        </MainContainer>
    );
};
const mapStateToProps = ({
    transferBooking,
    payment,
    transferSearch,
    router,
    currency,
    auth,
    locale
}: RootState) => ({
    transferBooking,
    currency: currency.currentCurrency,
    locale: locale.currentLocale,
    companyCurrency: auth.userData?.currency,
    transferAmount: auth.userData?.companyMarkups[auth.currentCompanyMarkupIndex || 0].transferAmount,
    lastSearchResultsLocation: router.lastLocations.hotels.lastSearchResultsLocation,
    currentOrigin: transferSearch.currentOrigin,
    currentDestination: transferSearch.currentDestination,
    providerPayment: payment.providerPayment
});

const connector = connect(mapStateToProps, {
    confirmDuplicateBooking,
    cancelAndSearchAgain,
    updateBookingOptions
});

export default connector(BookingChangedContainer);
