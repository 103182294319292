import React, {
    ReactElement, useMemo, useReducer, useState
} from "react";
import ConfirmationModal from "components/common/ConfirmationModal";
import CustomCheckBox from "components/base/Input/CustomCheckBox";
import {handleColumns} from "redux/actions/myBookings.actions";
import {useTranslation} from "react-i18next";
import styles from "./EditColumns.module.scss";
import {ColumnPropTypes} from "../../../proptypes/PropTypeObjects";
import {useAppDispatch, useAppSelector} from "../../../redux/hooks";

const columnsText = {
    REFERENCE: "booking_ref",
    SERVICE_NAME: "name_of_booking",
    CREATE_DATE: "create_date",
    CXL: "cxl",
    SERVICE_START_DATE: "service_start",
    SERVICE_END_DATE: "service_end",
    LEAD_PASSENGER: "lead_passenger",
    PERSON: "contact_person",
    PRICE: "price",
    PENDING: "pending",
    STATUS: "booking_status"
};

function reducer(state: any, action: any) {
    const {type, name, value} = action;
    switch (type) {
    case "HANDLE_INPUT":
        return {
            ...state,
            [name]: value
        };
    default:
        return state;
    }
}

function EditColumnsModal({
    onConfirm,
    onDecline,
    columns
}: any) {
    const {t} = useTranslation();

    const superAgent = useAppSelector((state) => state.auth.userData?.superAgent);

    const initialData = useMemo(
        () => columns.reduce(
            (obj: any, {
                key,
                enabled
            }: any) => Object.assign(obj, {[key]: enabled}),
            {}
        ),
        [columns]
    );
    const [localFormData, dispatch] = useReducer(reducer, initialData);
    const handleCheckBox = ({
        target: {name, checked}
    }: any) => dispatch({
        type: "HANDLE_INPUT",
        name,
        value: checked
    });

    const content = (
        <div className={styles.content}>
            <div className={styles.columns}>
                {columns.filter(({key}: any) => key === "PAYMENT_PENDING" ? superAgent : true).map(({
                    key
                }: any, i: any) => (
                    <div className={styles.checkboxWrapper} key={i}>
                        <CustomCheckBox
                            label={t("mb_ec_" + columnsText[key])}
                            inputProps={{
                                name: key,
                                checked: localFormData[key],
                                onChange: handleCheckBox
                            }}
                        />
                    </div>
                ))}
            </div>
        </div>
    );

    return (
        <ConfirmationModal
            onConfirm={() => onConfirm(localFormData)}
            onDecline={onDecline}
            config={{
                variants: {
                    type: "normal",
                    innerCloseButton: true
                },
                title: t("mb_ec_edit_columns"),
                //customHeader,
                content,
                isForm: true,
                confirmButtonText: t("mb_ec_save_changes"),
                declineButtonText: t("mb_ec_cancel")
            }}
        />
    );
}

function EditColumns({
    columns
}: any): ReactElement {
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const dispatch = useAppDispatch();
    const {t} = useTranslation();

    return (
        <>
            {modalIsOpen && (
                <EditColumnsModal
                    onConfirm={(columnsObj: ColumnPropTypes[]) => {
                        setModalIsOpen(false);
                        dispatch(handleColumns(columnsObj));
                    }}
                    onDecline={() => {
                        setModalIsOpen(false);
                    }}
                    columns={columns}
                />
            )}
            <span className={styles.root} onClick={() => setModalIsOpen(true)}>
                {t("mb_ec_edit_columns")}
            </span>
        </>
    );
}

export default EditColumns;
