import _ from "lodash";
import {QueryParamsObject} from "./queryParamsTypes";

const arrayResolverRegex = RegExp("^([a-zA-Z0-9]+)\\d+$");
export const ANY_ARRAY_PARAM_RESOLVER = (paramName: string): boolean => RegExp(arrayResolverRegex).test(paramName);
export const ANY_ARRAY_PARAM_EXTRACTOR = (paramName: string): string | undefined => {
    const match = arrayResolverRegex.exec(paramName);

    if (match && match[1]) {
        return match[1];
    }

    return undefined;
};

export const SIMPLE_ARRAY_PARAM_RESOLVER = (paramStart: string) => (paramName: string): boolean => RegExp(("^" + _.toString(paramStart) + "\\d+$")).test(paramName);
export const POSSIBLE_ARRAY_PARAM_RESOLVER = (possibleParams: string[], prefix = ""): (paramName: string) => boolean => {
    const allCombinations = possibleParams.map((val) => prefix + val);
    return (paramName: string) => !!_.find(allCombinations, (combination) => _.startsWith(paramName, combination));
};

export function addParamToSearchQuery(query: string, paramName: string, param: string | number) {
    const newParams = getQueryParamsObject(query);
    _.set(newParams, paramName, param);

    return convertQueryParamsObjectToSearch(newParams);
}

export function removeParamsFromSearchQuery(query: string, params: string[]): string {
    const newParams = getQueryParamsObject(query);
    _.set(newParams, "__toDelete", params);

    return convertQueryParamsObjectToSearch(newParams);
}

export function getQueryParamsObject(query: string): QueryParamsObject {
    const newParamsObj = new QueryParamsObject();
    if (_.isEmpty(query)) {
        return newParamsObj;
    }

    query.substring(query[0] === "?" ? 1 : 0, query.length).split("&").forEach((pair) => {
        const [paramName, value] = pair.split("=");

        if (!_.isEmpty(paramName) && !_.isEmpty(value)) {
            _.set(newParamsObj, paramName, decodeURI(value));
        }
    });

    return newParamsObj;
}

export function convertQueryParamsObjectToSearch(query: QueryParamsObject): string {
    let search = "?";

    // Prefilter keys that need to be replaced
    _.forEach(_.pickBy(query, (value, key) => key && key.startsWith("__replace_")), (value, key) => {
        const existingKey = key.split("__replace_")[1];
        _.set(query, existingKey, value);
        _.unset(query, key);
    });

    Object.entries(query).forEach(([key, value]) => {
        if (!value || key === "__toDelete" || (_.get(query, "__toDelete") && _.get(query, "__toDelete").includes(key))) {
            return;
        }

        search += key + "=" + value + "&";
    });

    return encodeURI(search.substring(0, search.length - 1));
}

export function getUrlParamArray(query: QueryParamsObject, paramNameWithoutIndex: string): (number | string | boolean)[] {
    const specificArrayParamResolver = SIMPLE_ARRAY_PARAM_RESOLVER(paramNameWithoutIndex);

    const resolved: (number | string | boolean)[] = [];
    Object.keys(query).forEach((key) => {
        if (specificArrayParamResolver(key)) {
            resolved.push(getUrlParamPrimitive(query[key]));
        }
    });

    return resolved;
}

export function getUrlParamPrimitive(val: string): string | number | boolean {
    if (val === "true" || val === "false") {
        return val === "true";
    }

    if (!Number.isNaN(+val)) {
        return +val;
    }

    return _.toString(val) || "";
}

export function setUrlParamArray(query: QueryParamsObject, paramName: string, param: unknown[]): void {
    const arrayParamResolver = SIMPLE_ARRAY_PARAM_RESOLVER(paramName);

    if (!param || _.isEmpty(param)) {
        Object.keys(query).forEach((key) => {
            if (arrayParamResolver(key)) {
                _.set(query, "__toDelete", _.union(_.get(query, "__toDelete"), [key]));
            }
        });

        return;
    }

    for (let i = 0; i < param.length; i++) {
        setUrlParamPrimitive(query, paramName + _.toString(i), _.toString(param[i]));
    }

    let currParamCount = 0;
    Object.keys(query).forEach((key) => {
        currParamCount += arrayParamResolver(key) ? 1 : 0;
    });

    if (currParamCount > param.length) {
        // Delete any leftover keys
        Object.keys(query).filter((key) => arrayParamResolver(key))
            .sort()
            .splice(param.length)
            .forEach((arrayKey) => {
                _.set(query, "__toDelete", _.union(_.get(query, "__toDelete"), [arrayKey]));
            });
    }
}

export function setUrlParamPrimitive(query: QueryParamsObject, paramName: string, param: string): void {
    if (param === "undefined" || _.isEmpty(param) || param === "false" || param === "0" || param === "NaN") {
        _.set(query, "__toDelete", _.union(_.get(query, "__toDelete"), [paramName]));
        return;
    }

    if (query[paramName] && param && !_.eq(query[paramName], param)) {
        _.set(query, "__replace_" + paramName, param);
    }

    _.set(query, paramName, param);
}