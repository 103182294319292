// Source: https://www.w3resource.com/javascript-exercises/javascript-math-exercise-22.php
function charToNumber(symbol: string): number {
    switch (symbol) {
    case "I": return 1;
    case "V": return 5;
    case "X": return 10;
    case "L": return 50;
    case "C": return 100;
    case "D": return 500;
    case "M": return 1000;
    default: return 0;
    }
}

export default function convertRomanNumeral(romanNumeral: string): number {
    if (!romanNumeral) {
        return -1;
    }

    let num = charToNumber(romanNumeral.charAt(0));
    let pre: number;
    let curr: number;

    for (let i = 1; i < romanNumeral.length; i++) {
        curr = charToNumber(romanNumeral.charAt(i));
        pre = charToNumber(romanNumeral.charAt(i - 1));

        if (curr <= pre) {
            num += curr;
        } else {
            num = num - pre * 2 + curr;
        }
    }

    return num;
}