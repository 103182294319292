import React, {useEffect} from "react";
import {useTranslation} from "react-i18next";
import {
    SharedPredicateFactoryProps,
    SharedPredicateFactoryReturn
} from "../SearchPredicates.base";
import useRegexBubbleFactory from "../Shared/useRegexBubbleFactory";
import {getQueryParamsState} from "../../../../../utils/url/queryParams";
import {MyBookingsQueryParams} from "../../../../../utils/url/queryParamsTypes";

const PARTIAL_BOOKING_REFERENCE_REGEX = /\bTF[0-9]{1}[0-9]{0,6}\b/gi;
const BOOKING_REFERENCE_REGEX = /\b(?:TF|TO|HO)[0-9]{7}\b/gi;

export default function useBookingReferenceBubbleFactory({
    collectPipeline,

    addSubject,
    removeSubject,

    searchString
}: SharedPredicateFactoryProps): SharedPredicateFactoryReturn {
    const {t} = useTranslation();

    const {options, manualAddCallback} = useRegexBubbleFactory({
        collectPipeline,
        addSubject,
        removeSubject,
        searchString,

        partialMatch: PARTIAL_BOOKING_REFERENCE_REGEX,
        fullMatch: BOOKING_REFERENCE_REGEX,
        labelPrefix: t("s_p_c_booking_reference_search")
    });

    useEffect(() => {
        const queryState = getQueryParamsState() as MyBookingsQueryParams;

        if (manualAddCallback && queryState && queryState.bookingReferences) {
            manualAddCallback(queryState.bookingReferences.join(" "));
        }
    }, []);

    return {
        options
    };
}