import React, {ReactElement} from "react"
import {useTranslation} from "react-i18next"
import Pricing from "components/common/Pricing/Pricing"
import ButtonConfirmNonrefModal from "components/base/Payment/ButtonConfirmNonRefModal/ButtonConfirmNonrefModal"
import PayLaterBlock from "./PayLaterBlock"
import usePaymentOptions from "../usePaymentOptions"
import {SharedPaymentTypeProps} from "../SharedPaymentTypeProps"
import types from "utils/paymentTransferTypes"
import {useAppSelector} from "redux/hooks";

export type PayLaterProps = SharedPaymentTypeProps & {};

const PayLater = (props: PayLaterProps): ReactElement => {
    const {
        toggleSelectedPayment,
        paymentTransferTypeEnum
    } = props;

    const {t} = useTranslation();

    const {
        paymentOptions,
        discount,
        voucherSum
    } = usePaymentOptions();
    const isSubagent = useAppSelector((state) => state.auth.userData?.subAgent)

    const payLater = paymentOptions?.paymentTransferTypes.includes(types[paymentOptions?.afterDeadline ? "after" : "before"].payLater);

    if (!paymentOptions || !payLater) {
        return (
            <></>
        );
    }

    const {
        cancellationTime,
        cancellationDate,
        afterDeadline,
        paymentDeadlineTime,
        paymentDeadlineDate,
        allowToBook,
        sanctionedCompany,
        salePrice,
        displaySalePrice,
        smsFee,
        displaySmsFee,
        paymentPending,
        superAgentUseBalance
    } = paymentOptions;

    // SubAgents may have their own version of Pay Later if their SuperAgents Credit Line is disabled.
    // For more information, see SuperAgentPayment.tsx
    if (isSubagent && !superAgentUseBalance) {
        return (
            <></>
        );
    }

    return (
        <PayLaterBlock
            key="pay-later"
            cancellationTime={cancellationTime}
            cancellationDate={cancellationDate}
            paymentTransferTypeEnum={paymentTransferTypeEnum}
            afterDeadline={afterDeadline}
            paymentDeadlineTime={paymentDeadlineTime}
            paymentDeadlineDate={paymentDeadlineDate}
            toggleSelectedPayment={toggleSelectedPayment}
        >
            <Pricing
                customButton={afterDeadline ? <ButtonConfirmNonrefModal/> : undefined}
                withButton
                customButtonLabel={t("pc_pc_book_now")}
                disabledButton={!allowToBook || sanctionedCompany}
                greyLabels
                salePrice={salePrice}
                displaySalePrice={displaySalePrice}
                smsFee={smsFee}
                displaySmsFee={displaySmsFee}
                discount={discount}
                voucher={voucherSum.used}
                paymentPending={paymentPending}
            />
        </PayLaterBlock>
    );
}

export default PayLater;