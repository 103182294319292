import React, {PureComponent, ReactElement} from "react";
import {ReactComponent as NoPhoto} from "../../../assets/images/no-photo-placeholder.svg";
import isAppleWebkit from "utils/detection/isAppleWebkit";

export enum ImageLoadingTypeEnum {
    LAZY = "lazy",
    EAGER = "eager"
}

export type ImageThatDisappearsOnErrorProps = {
    src?: string;
    alt: string;
    loading?: ImageLoadingTypeEnum;
    onError?: (...args: any[]) => void;
    className?: string;
};

type State = {
    error: boolean;
};

export default class ImageThatDisappearsOnError extends PureComponent<ImageThatDisappearsOnErrorProps, State> {
    constructor(props: ImageThatDisappearsOnErrorProps) {
        super(props);
        this.state = {
            error: false
        };
    }

    handleError = (error: boolean): void => {
        if (error) {
            this.setState({error});
        }
        if (this.props.onError) {
            this.props.onError(error);
        }
    };

    render(): ReactElement {
        const {
            src = "", alt = "", loading, className
        } = this.props;

        // no lazy loading on AppleWebkit
        // see: https://github.com/Automattic/jetpack/issues/23583
        const appleWebkit = isAppleWebkit();

        return (
            <div className={className}>
                {!this.state.error && (
                    <div className="img-wrapper">
                        <img
                            loading={!appleWebkit ? (loading || ImageLoadingTypeEnum.LAZY) : ImageLoadingTypeEnum.EAGER}
                            alt={alt}
                            src={src}
                            onLoad={() => this.handleError(false)}
                            onError={() => this.handleError(true)}
                        />
                    </div>
                )}
                {
                    this.state.error && (<NoPhoto/>)
                }
            </div>
        );
    }
}
