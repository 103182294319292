export default function getNullishInput<T>(value: T, inputType: "text" | "textarea" | "number" | "checkbox" | "radio", defaultValue?: T): T {
    if (value !== null && value !== undefined) {
        return value;
    }

    switch (inputType) {
    case "text":
    case "textarea":
    case "number":
        return defaultValue || "" as unknown as T;
    case "checkbox":
    case "radio":
        return (Boolean(defaultValue) || false) as unknown as T;
    default:
        return defaultValue || value;
    }
}