import React, {ReactElement} from "react";
import {useTranslation} from "react-i18next";
import styles from "./SpecificLocationBox.module.scss";

type Props = {
    specificLocationName?: string;
    specificLocationAddress?: string;
};

function SpecificLocationBox({specificLocationName, specificLocationAddress}: Props): ReactElement {
    const {t} = useTranslation();

    return (
        <div className={styles.Root}>
            <div className={styles.Heading}>
                {t("slb_slb_specific_location")}
            </div>

            <dl className={styles.Text}>
                <dt>{t("slb_slb_name")}:</dt>
                <dd>{specificLocationName}</dd>

                <dt>{t("slb_slb_address")}:</dt>
                <dd>{specificLocationAddress}</dd>
            </dl>
        </div>
    );
}

export default SpecificLocationBox;
