import React, {CSSProperties, ReactElement} from "react";
import styles from "./ContactPhones.module.scss";
import getCountryFlag from "../../../utils/getCountryFlag";

type Props = {
    phones: {
        numbers: string[],
        lang: string;
        label: string;
    }[];
    title: string;
    tip?: string;
};
export default function ContactPhones({
    phones = [], title = "", tip = ""
}: Props): ReactElement {
    return (
        <div className={styles.Root}>
            <h4 className={styles.Title}>{title}</h4>

            {phones.map(
                (phone) => (
                    <div key={phone.lang} className={styles.Item}>
                        <span className={styles.Label}>
                            {getCountryFlag(phone.lang)}
                            {phone.label}
                        </span>

                        {phone.numbers ? phone.numbers.join(", ") : ""}
                    </div>
                )
            )}

            {tip && <p className={styles.Tip}>{tip}</p>}
        </div>
    );
}
