import {ReactElement} from "react";

const traverseRowChildrenText = (element: ReactElement | string | undefined | null): string => {
    if (!element) {
        return "";
    }

    if (typeof element === "string") {
        return element;
    }

    const children = element.props && element.props.children;
    if (children instanceof Array) {
        return children.map(traverseRowChildrenText).join("");
    }

    return traverseRowChildrenText(children);
};

export default traverseRowChildrenText;