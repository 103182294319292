import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import _ from "lodash";
import {
    SharedPredicateFactoryOption,
    SharedPredicateFactoryProps,
    SharedPredicateFactoryReturn
} from "../SearchPredicates.base";
import DummyBubble from "../Shared/DummyBubble";

export default function useFreeTextSearchBubbleFactory({
    collectPipeline,
    addSubject,
    searchString,
    removeSubject
}: SharedPredicateFactoryProps): SharedPredicateFactoryReturn {
    const {t} = useTranslation();
    const [options, setOptions] = useState<SharedPredicateFactoryOption[]>([]);

    useEffect(() => {
        if (searchString && searchString.length !== 0) {
            const uniqueId = _.uniqueId("freeTextBubble_");

            setOptions([
                {
                    label: <>{t("s_p_c_text_search")} {searchString}</>,
                    onClick: () => void addSubject.next({
                        child: <DummyBubble
                            key={uniqueId}
                            collectPipeline={collectPipeline}
                            label={searchString}
                            value={searchString}
                            onRemove={() => void removeSubject.next(uniqueId)}
                        />,
                        reference: uniqueId,
                        fromPos: 0,
                        strLen: searchString.length
                    })
                }
            ]);
        } else {
            setOptions([]);
        }
    }, [addSubject, collectPipeline, options.length, removeSubject, searchString, t]);

    return {
        options
    };
}