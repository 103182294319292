import {ReduxPricingPropTypes} from "../../proptypes/PropTypeRedux"
import PricingActionTypes from "./actions/pricing.reducer.actions"
import PricingTypes from "../constants/pricing.constants"

export const INITIAL_STATE = {
    loading: false
} as ReduxPricingPropTypes;

export default (state = INITIAL_STATE, action: PricingActionTypes): ReduxPricingPropTypes => {
    switch (action.type) {
    case PricingTypes.REQ_TRANSACTION_FEE:
        return {
            ...state,
            loading: true,
            transactionFee: undefined,
            displayTransactionFee: undefined
        };
    case PricingTypes.GET_TRANSACTION_FEE:
        return {
            ...state,
            loading: false,
            transactionFee: action.fee,
            displayTransactionFee: action.displayFee,
            lastSalePrice: undefined
        };
    case PricingTypes.ERR_TRANSACTION_FEE:
        return {
            ...state,
            loading: false,
            transactionFee: undefined,
            displayTransactionFee: undefined
        };
    case PricingTypes.SET_LAST_REQUESTED_PRICE:
        return {
            ...state,
            lastSalePrice: action.price
        };
    case PricingTypes.REMEMBER_TRANSACTION_FEE:
        return {
            ...state,
            rememberedTransactionFee: action.transactionFee
        };
    default:
        return state;
    }
}