import React, {ReactElement, useCallback, useEffect} from "react";
import {useTranslation} from "react-i18next";
import styles from "./HamburgerMenu.module.scss";
import {ReactComponent as BarsIcon} from "assets/icons/bars.svg";
import {ReactComponent as CrossIcon} from "assets/icons/cross.svg";
import MainLinks from "../MainLinks/MainLinks";
import HamburgerMenuLocaleDropdown from "./HamburgerMenuLocaleDropdown";
import HamburgerMenuCurrencyDropdown from "./HamburgerMenuCurrencyDropdown";
import {toggleModal, toggleScrollLock} from "redux/actions/modals.actions";
import store from "redux/store/store.init";
import {useAppDispatch, useAppSelector} from "redux/hooks";
import Modal from "../../../common/Modal";
import {ReduxModalType} from "proptypes/PropTypeObjects";

export type HamburgerMenuProps = {
    className?: string;
};

const HamburgerMenu = ({className}: HamburgerMenuProps): ReactElement => {
    const {t} = useTranslation();
    const dispatch = useAppDispatch();

    const trackingId = store.getState().zipkin.tracer.id.traceId;
    const ownUser = useAppSelector((state) => state.auth.userData?.companyUser.ownUser);
    const activeModal = useAppSelector((state) => state.modals.currentActiveModal);

    useEffect(() => () => {
        toggleScrollLock(false);
    }, []);

    const toggleMenuCallback = useCallback(() => {
        dispatch(toggleModal(ReduxModalType.HAMBURGER_MENU));
    }, [dispatch]);

    const afterLinkClick = useCallback(() => {
        dispatch(toggleModal(undefined));
    }, [dispatch]);

    return (
        <>
            {activeModal !== ReduxModalType.HAMBURGER_MENU && (
                <BarsIcon
                    className={styles.MenuIcon}
                    onClick={toggleMenuCallback}
                />
            )}

            {activeModal === ReduxModalType.HAMBURGER_MENU && (
                <CrossIcon
                    className={styles.MenuIcon}
                    onClick={toggleMenuCallback}
                />
            )}

            {activeModal === ReduxModalType.HAMBURGER_MENU && (
                <Modal>
                    <div
                        className={styles.Root}
                    >
                        <div
                            className={styles.HamburgerMenuOverlay}
                        >
                            <div className={styles.ItemsContainer}>
                                <div className={styles.ActionsContainer}>
                                    <div className={styles.ActionContainer}>
                                        <span>{t("h_m_locale_dropdown_label")}</span>
                                        <HamburgerMenuLocaleDropdown />
                                    </div>

                                    <div className={styles.ActionContainer}>
                                        <span>{t("h_m_currency_dropdown_label")}</span>
                                        <HamburgerMenuCurrencyDropdown />
                                    </div>

                                    {ownUser && (
                                        <div className={styles.TrackingIdContainer}>
                                            Tracking id <span className={styles.TrackingId}>{trackingId}</span>
                                        </div>
                                    )}
                                </div>

                                <MainLinks mobile afterLinkClick={afterLinkClick} linkClassName={styles.Link} />
                            </div>
                        </div>
                    </div>
                </Modal>
            )}
        </>
    );
};

export default HamburgerMenu;