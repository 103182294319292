import React, {ReactElement} from "react";
import styles from "./SpecialOffers.module.scss";
import cx from "classnames";
import {ReactComponent as StarIcon} from "assets/icons/specialOffers/Special offer.svg";
import {ReactComponent as LastMinIcon} from "assets/icons/specialOffers/Last minute.svg";
import {ReactComponent as FreeNightsIcon} from "assets/icons/specialOffers/Free nights.svg";
import {ReactComponent as KidsStayFreeIcon} from "assets/icons/specialOffers/Kids stay free.svg";
import {ReactComponent as FreeBoardIcon} from "assets/icons/specialOffers/Free board.svg";
import {ReactComponent as GoodDealIcon} from "assets/icons/specialOffers/Good deal.svg";
import {ReactComponent as PackageRateIcon} from "assets/icons/specialOffers/Package rate.svg";
import {ReactComponent as HoneyMoonIcon} from "assets/icons/specialOffers/Honeymoon.svg";
import {ReactComponent as MemberOnlyIcon} from "assets/icons/specialOffers/Member onloy.svg";
import {ReactComponent as GiftBoxIcon} from "assets/icons/gift-box.svg";
import {ReactComponent as MobileIcon} from "assets/icons/specialOffers/mobile.svg";
import _ from "lodash";
import {useTranslation} from "react-i18next";
import Tippy from "../Tippy";
import {SpecialOfferEnumPropTypes, SpecialOfferPropTypes} from "proptypes/PropTypeObjects";

type SpecialOfferProps = {
    type: SpecialOfferEnumPropTypes;
    description?: string | JSX.Element | JSX.Element[];
};

function SpecialOffer({
    type,
    description
}: SpecialOfferProps) {
    const {t} = useTranslation();

    switch (type) {
    case "SPECIAL_OFFER":
        return (
            <Tippy
                disabled={!description}
                content={<span className="semi-bold-text">{description}</span>}
            >
                <span className={styles.SpecialOffer}><StarIcon
                    className={styles.StarIcon}
                />{t("s_o_special_offer")}
                </span>
            </Tippy>
        );
    case "LAST_MINUTE":
        return (
            <Tippy
                disabled={!description}
                content={<span className="semi-bold-text">{description}</span>}
            >
                <span className={styles.SpecialOffer}>
                    <LastMinIcon
                        className={styles.MobileIcon}
                    />
                    {t("s_o_last_minute")}
                </span>
            </Tippy>
        );
    case "FREE_NIGHTS":
        return (
            <Tippy
                disabled={!description}
                content={<span className="semi-bold-text">{description}</span>}
            >
                <span className={styles.SpecialOffer}>
                    <FreeNightsIcon
                        className={styles.StarIcon}
                    />
                    {t("s_o_free_nights")}
                </span>
            </Tippy>
        );
    case "KIDS_STAY_FREE":
        return (
            <Tippy
                disabled={!description}
                content={<span className="semi-bold-text">{description}</span>}
            >
                <span className={styles.SpecialOffer}>
                    <KidsStayFreeIcon
                        className={styles.StarIcon}
                    />
                    {t("s_o_kids_stay_free")}
                </span>
            </Tippy>
        );
    case "FREE_BOARD":
        return (
            <Tippy
                disabled={!description}
                content={<span className="semi-bold-text">{description}</span>}
            >
                <span className={styles.SpecialOffer}>
                    <FreeBoardIcon
                        className={styles.StarIcon}
                    />
                    {t("s_o_free_board")}
                </span>
            </Tippy>
        );
    case "EARLY_BOOKING":
        return (
            <Tippy
                disabled={!description}
                content={<span className="semi-bold-text">{description}</span>}
            >
                <span className={styles.SpecialOffer}>
                    <LastMinIcon
                        className={styles.StarIcon}
                    />
                    {t("s_o_early_booking")}
                </span>
            </Tippy>
        );
    case "GOOD_DEAL":
        return (
            <Tippy
                disabled={!description}
                content={<span className="semi-bold-text">{description}</span>}
            >
                <span className={styles.SpecialOffer}>
                    <GoodDealIcon
                        className={styles.StarIcon}
                    />
                    {t("s_o_good_deal")}
                </span>
            </Tippy>
        );
    case "PACKAGE_RATE":
        return (
            <Tippy
                disabled={!description}
                content={<span className="semi-bold-text">{description}</span>}
            >
                <span className={styles.SpecialOffer}>
                    <PackageRateIcon
                        className={styles.StarIcon}
                    />
                    {t("s_o_package_rate")}
                </span>
            </Tippy>
        );
    case "HONEYMOON":
        return (
            <Tippy
                disabled={!description}
                content={<span className="semi-bold-text">{description}</span>}
            >
                <span className={styles.SpecialOffer}>
                    <HoneyMoonIcon
                        className={styles.StarIcon}
                    />
                    {t("s_o_honeymoon")}
                </span>
            </Tippy>
        );
    case "MEMBER_ONLY":
        return (
            <Tippy
                disabled={!description}
                content={<span className="semi-bold-text">{description}</span>}
            >
                <span className={styles.SpecialOffer}><MemberOnlyIcon
                    className={styles.StarIcon}
                />
                    {t("s_o_member_only")}
                </span>
            </Tippy>
        );
    case "BLACK_FRIDAY":
        return (
            <Tippy
                disabled={!description}
                content={<span className="semi-bold-text">{description}</span>}
            >
                <span className={styles.BlackFriday}>
                    <GiftBoxIcon
                        className={styles.HotelstonIcon}
                    />
                    {t("s_o_black_friday")}
                </span>
            </Tippy>
        );
    case "FREE_TRANSFER":
        return (
            <Tippy
                disabled={!description}
                content={<span className="semi-bold-text">{description}</span>}
            >
                <span>
                    <PackageRateIcon className={styles.StarIcon}/>
                    {t("s_o_free_transfer")}
                </span>
            </Tippy>
        );
    case "MOBILE_RATE":
        return (
            <Tippy
                disabled={!description}
                content={<span className="semi-bold-text">{description}</span>}
            >
                <span>
                    <MobileIcon className={styles.MobileIcon}/>
                    {t("s_o_mobile_rate")}
                </span>
            </Tippy>
        );
    default:
        return (
            <Tippy
                disabled={!description}
                content={<span className="semi-bold-text">{description}</span>}
            >
                <span className={styles.SpecialOffer}>
                    <StarIcon
                        className={styles.StarIcon}
                    />
                    {t("s_o_special_offer")}
                </span>
            </Tippy>
        );
    }
}

type Props = {
    inline: boolean;
    specialOffers: SpecialOfferPropTypes[];
    compactView?: boolean;
};

function SpecialOffers({
    specialOffers,
    inline = false,
    compactView = false
}: Props): ReactElement {
    const genericSO = specialOffers.filter((so) => so.type === "SPECIAL_OFFER");
    const otherSO = specialOffers.filter((so) => so.type !== "SPECIAL_OFFER");
    const genericSODescriptions = genericSO.map((sod) => sod.description)
        .filter((d) => !_.isEmpty(d))
        .map((desc) => <p key={_.uniqueId("genericSODescription_")}>{desc}</p>);

    return (
        <div className={cx(styles.Root, !inline && styles.NewLine, compactView && styles.Compact)}>
            {
                otherSO.map((specialOffer) => <SpecialOffer key={specialOffer.type} {...specialOffer} />)
            }
            {genericSO.length > 0 && (
                <SpecialOffer key="SPECIAL_OFFER" type="SPECIAL_OFFER" description={genericSODescriptions}/>
            )}
        </div>
    );
}

export default SpecialOffers;
