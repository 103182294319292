import React, {Component, ErrorInfo, ReactNode} from "react";
import {ReactComponent as ErrorIcon} from "assets/icons/cross.svg";
import {WithTranslation, withTranslation} from "react-i18next";
import styles from "./ErrorBoundary.module.scss";
import Modal from "../../common/Modal";
import ModalWithOverlay from "../../common/Modal/ModalWithOverlay";
import UserButton from "../../base/UserButton";
import {UserButtonVariant} from "../../base/UserButton/UserButton";
import ActionIcon, {ActionIconType} from "../../common/ActionIcon/ActionIcon";
import Fade from "../Transitions/Fade";
import sendLogs from "../../../api/loggerAPI";
import store from "../../../redux/store/store.init";
import {FALLBACK_DATE_TIME_SECONDS_FORMAT} from "../Internationalization/formats";
import router from "views/router/router";
import createLuxonDate from "../../../utils/date/createLuxonDate"
import {globalAxiosController} from "api/axios/globalAxiosController";

type Props = WithTranslation & {
    children: React.ReactNode;
};

type State = {
    hasError: boolean;
    fade: boolean;
    error?: Error;

    date?: string;
    errorDescriptionOpen: boolean;
};

class ErrorBoundary extends Component<Props, State> {
    private fadeDuration = 300;

    constructor(props: Props) {
        super(props);
        this.state = {
            hasError: false,
            fade: true,
            errorDescriptionOpen: false
        };
    }

    static getDerivedStateFromError(error: Error): State { // Update state so the next render will show the fallback UI.
        return {
            hasError: true,
            fade: true,
            error: error,
            errorDescriptionOpen: false,
            date: createLuxonDate().toFormat(FALLBACK_DATE_TIME_SECONDS_FORMAT)
        };
    }

    componentDidCatch(error: Error, errorInfo: ErrorInfo): void { // You can also log the error to an error reporting service
        globalAxiosController.addRequest(sendLogs(JSON.stringify({
            message: JSON.stringify(error.message),
            errorInfo: errorInfo,
            stack: JSON.stringify(error.stack)
        })))
            .then((data) => {
            })
            .catch((error: any) => {
                // console.log(error);
            }); //logErrorToMyService(error, errorInfo);
    }

    onClose = (): void => {
        router.navigate("/", {replace: true});
    };

    onCloseWithFade = (): void => {
        this.setState({
            fade: false,
            error: undefined
        });

        setTimeout(() => this.onClose(), this.fadeDuration);
    };

    render(): ReactNode {
        const {errorDescriptionOpen, date} = this.state;
        const {t} = this.props;
        const trackingId = store.getState().zipkin.tracer.id.traceId;

        return (
            this.state.hasError ? (
                <>
                    <Modal>
                        <Fade
                            inProp={this.state.fade}
                            duration={this.fadeDuration}
                        >
                            <ModalWithOverlay>
                                <div className={styles.Root}>
                                    <div
                                        onClick={this.onCloseWithFade}
                                        className={styles.CloseBtn}
                                    >
                                        <ActionIcon type={ActionIconType.CLOSE}/>
                                    </div>

                                    <div className={styles.Header}>
                                        <ErrorIcon/>

                                        <h2 className={styles.Title}>{t("e_b_something_went_wrong")}</h2>

                                        <div className={styles.Date}>{date}</div>

                                        <dl className={styles.Details}>
                                            <dt>trackingId:</dt>
                                            <dd>{trackingId}</dd>

                                            <dt>{this.state.error?.name}:</dt>
                                            <dd>{this.state.error?.message}</dd>
                                        </dl>

                                        <div className={styles.ErrorDescriptionContainer}>
                                            {!errorDescriptionOpen && (
                                                <a
                                                    onClick={() => {
                                                        this.setState({
                                                            errorDescriptionOpen: true
                                                        });
                                                    }}
                                                >
                                                    {t("e_b_show_more")}
                                                </a>
                                            )}

                                            {errorDescriptionOpen && (
                                                <>
                                                    <a
                                                        onClick={() => {
                                                            this.setState({
                                                                errorDescriptionOpen: false
                                                            });
                                                        }}
                                                    >
                                                        {t("e_b_show_less")}
                                                    </a>

                                                    <textarea
                                                        className={styles.TextArea}
                                                        disabled={true}
                                                        value={this.state.error?.stack}
                                                    />
                                                </>
                                            )}
                                        </div>
                                    </div>

                                    <UserButton
                                        text={t("e_b_go_back")}
                                        variant={UserButtonVariant.SECONDARY}
                                        buttonProps={{
                                            onClick: this.onCloseWithFade,
                                            type: "button",
                                            style: {
                                                margin: "auto",
                                                height: "40px",
                                                width: "160px"
                                            }
                                        }}
                                    />
                                </div>
                            </ModalWithOverlay>
                        </Fade>
                    </Modal>
                </>
            )
                : ( // You can render any custom fallback UI
                    this.props.children
                )
        );
    }
}

export default withTranslation()(ErrorBoundary);