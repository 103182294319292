import {useCallback, useEffect, useReducer, useState} from "react";
import {
    addCompanyMarkup as addCompanyMarkupAPI,
    deleteCompanyMarkup as deleteCompanyMarkupAPI,
    deleteLogo as deleteLogoAPI,
    getBillingDetails as getBillingDetailsAPI,
    updateBillingDetails as updateBillingDetailsAPI,
    updateCompanyDetails as updateCompanyDetailsAPI,
    updateCompanyMarkup as updateCompanyMarkupAPI,
    updateCurrentCompanyUser as updateCurrentCompanyUserAPI,
    uploadLogo as uploadLogoAPI
} from "api/companyAPI";
import {useTranslation} from "react-i18next";
import useIsMounted from "@hotelston_web_frontend_components/hooks/detection/useIsMounted";
import {getCompanyDetails} from "redux/actions/company.actions";
import {
    CompanyDetails,
    CompanyDetailsResponse,
    CompanyMarkupsPropTypes,
    CompanyMarkupsRequestPropTypes,
    CompanyUserWithPassword,
    Credentials,
    ItemModificationResponse
} from "proptypes/PropTypeObjects";
import {useAppDispatch, useAppSelector} from "redux/hooks";
import {useLocale} from "./withLocalesAndCurrencies";
import {globalAxiosController} from "api/axios/globalAxiosController";
import {useNavigate} from "react-router-dom"

type CompanyStateProps = {
    error?: string;
    requestingCompanyDetails: boolean;
    data: CompanyDetailsResponse;
};

export default function useCompanyData(): CompanyStateProps {
    const {t} = useTranslation();
    const dispatch = useAppDispatch();

    const {
        data,
        requestingCompanyDetails,
        error
    } = useAppSelector((state) => state.company);

    useEffect(() => {
        dispatch(getCompanyDetails(t("util_ucd_something_went_wrong")));
    }, []);

    return {
        data,
        error,
        requestingCompanyDetails
    };
}
const actionState = {
    requesting: false,
    errors: undefined
} as ActionStateProps;

type ActionStateProps = {
    requesting: boolean;
    errors?: string[];
};

type ActionProps =
    | { type: "CLEAR" }
    | { type: "REQUEST" }
    | { type: "SUCCESS" }
    | { type: "FAILURE", errors: string[]; }
    ;

export const actionReducer = (state: ActionStateProps, action: ActionProps): ActionStateProps => {
    switch (action.type) {
    case "CLEAR":
        return {
            ...actionState
        };
    case "REQUEST":
        return {
            ...state,
            errors: undefined,
            requesting: true
        };
    case "SUCCESS":
        return {
            ...state,
            requesting: false
        };
    case "FAILURE":
        return {
            ...state,
            requesting: false,
            errors: action.errors
        };
    default:
        return state;
    }
};

type UseUpdateCompanyDetailsAction = ActionStateProps & {
    updateCompanyDetails: (companyDetails: CompanyDetails) => void,
    clearState: () => void;
};

export function useUpdateCompanyDetailsAction(onSuccess = (companyDetails: CompanyDetails) => {
}): UseUpdateCompanyDetailsAction {
    const [state, dispatch] = useReducer(actionReducer, actionState);

    const locale = useAppSelector((state) => state.locale.currentLocale);
    const {t} = useTranslation();
    const isMounted = useIsMounted();

    const clearState = useCallback(() => {
        dispatch({type: "CLEAR"});
    }, []);

    const updateCompanyDetails = useCallback((companyDetails: CompanyDetails) => {
        dispatch({type: "REQUEST"});
        globalAxiosController.addRequest(updateCompanyDetailsAPI(companyDetails, locale))
            .then((data) => {
                if (isMounted) {
                    if (data && data.success) {
                        onSuccess(companyDetails);
                        dispatch({type: "SUCCESS"});
                    } else {
                        dispatch({
                            type: "FAILURE",
                            errors: (data && data.errors) || [
                                t("util_ucd_something_went_wrong")
                            ]
                        });
                    }
                }
            })
            .catch((error: unknown) => {
                if (isMounted) {
                    dispatch({
                        type: "FAILURE",
                        errors: error ? [error as string] : [t("util_ucd_something_went_wrong")]
                    });
                }
            });
    }, [locale, onSuccess, t, isMounted]);
    return {
        ...state,
        clearState,
        updateCompanyDetails
    };
}

type UseUploadLogoAction = ActionStateProps & {
    uploadLogo: (file: FormData) => void;
    deleteLogo: () => void;
    updating: boolean;
};

export function useUploadLogoAction(onSuccess = (file?: string) => {
}): UseUploadLogoAction {
    const [state, dispatch] = useReducer(actionReducer, actionState);
    const [updating, setUpdating] = useState(false);
    const locale = useAppSelector((state) => state.locale.currentLocale);
    const {t} = useTranslation();
    const isMounted = useIsMounted();

    const deleteLogo = useCallback(() => {
        setUpdating(true);
        dispatch({type: "REQUEST"});
        globalAxiosController.addRequest(deleteLogoAPI(locale))
            .then((data) => {
                if (isMounted) {
                    if (data && data.success) {
                        onSuccess(undefined);
                        dispatch({type: "SUCCESS"});
                    } else {
                        dispatch({
                            type: "FAILURE",
                            errors: [
                                t("util_ucd_something_went_wrong")
                            ]
                        });
                    }
                    setUpdating(false);
                }
            })
            .catch((error: unknown) => {
                if (isMounted) {
                    dispatch({
                        type: "FAILURE",
                        errors: error ? [error as string] : [t("util_ucd_something_went_wrong")]
                    });
                }
                setUpdating(false);
            });
    }, [locale, onSuccess, t, isMounted]);

    const uploadLogo = useCallback((file: FormData) => {
        setUpdating(true);
        dispatch({type: "REQUEST"});
        globalAxiosController.addRequest(uploadLogoAPI(file, locale))
            .then((data) => {
                if (isMounted) {
                    if (data && data.success) {
                        onSuccess(data.path);
                        dispatch({type: "SUCCESS"});
                    } else {
                        dispatch({
                            type: "FAILURE",
                            errors: [
                                t("util_ucd_something_went_wrong")
                            ]
                        });
                    }
                    setUpdating(false);
                }
            })
            .catch((error: unknown) => {
                if (isMounted) {
                    dispatch({
                        type: "FAILURE",
                        errors: error ? [error as string] : [t("util_ucd_something_went_wrong")]
                    });
                }
                setUpdating(false);
            });
    }, [locale, onSuccess, t, isMounted]);
    return {
        ...state,
        deleteLogo,
        uploadLogo,
        updating
    };
}

type UseUpdateCurrentCompanyUserAction = ActionStateProps & {
    updateCurrentUser: (currentUser: CompanyUserWithPassword) => Promise<ItemModificationResponse | undefined>;
    clearState: () => void;
};

export function useUpdateCurrentCompanyUserAction(onSuccess = (currentUser: CompanyUserWithPassword) => {
}): UseUpdateCurrentCompanyUserAction {
    const [state, dispatch] = useReducer(actionReducer, actionState);

    const locale = useAppSelector((state) => state.locale.currentLocale);
    const {t} = useTranslation();
    const isMounted = useIsMounted();

    const clearState = useCallback(() => {
        dispatch({type: "CLEAR"});
    }, []);

    const updateCurrentUser = useCallback((currentUser: CompanyUserWithPassword) => {
        dispatch({type: "REQUEST"});

        return globalAxiosController.addRequest(updateCurrentCompanyUserAPI(currentUser, locale))
            .then((data) => {
                if (isMounted) {
                    if (data && data.success) {
                        onSuccess(currentUser);
                        dispatch({type: "SUCCESS"});
                    } else {
                        dispatch({
                            type: "FAILURE",
                            errors: (data && data.errors) || [
                                t("util_ucd_something_went_wrong")
                            ]
                        });
                    }
                }

                return data;
            })
            .catch((error: unknown) => {
                if (isMounted) {
                    dispatch({
                        type: "FAILURE",
                        errors: error ? [error as string] : [t("util_ucd_something_went_wrong")]
                    });
                }

                return undefined;
            });
    }, [isMounted, locale, onSuccess, t]);

    return {
        ...state,
        updateCurrentUser,
        clearState
    };
}

type UseUpdateBillingDetailsAction = ActionStateProps & {
    updateBillingDetails: (credentials: Credentials) => void;
};

export function useUpdateBillingDetailsAction(onSuccess = (credentials: Credentials) => {
}): UseUpdateBillingDetailsAction {
    const [state, dispatch] = useReducer(actionReducer, actionState);

    const locale = useAppSelector((state) => state.locale.currentLocale);

    const {t} = useTranslation();
    const isMounted = useIsMounted();
    const navigate = useNavigate()

    const updateBillingDetails = useCallback((credentials: Credentials) => {
        dispatch({type: "REQUEST"});
        globalAxiosController.addRequest(updateBillingDetailsAPI(credentials, locale))
            .then((data) => {
                if (isMounted) {
                    if (data && data.success) {
                        onSuccess(credentials);
                        dispatch({type: "SUCCESS"});

                        if (data.id) {
                            // newly created agreement, redirect
                            navigate(`/my-company/agreement/${data.id}`);
                        }
                    } else {
                        dispatch({
                            type: "FAILURE",
                            errors: (data && data.errors) || [
                                t("util_ucd_something_went_wrong")
                            ]
                        });
                    }
                }
            })
            .catch((error: unknown) => {
                if (isMounted) {
                    dispatch({
                        type: "FAILURE",
                        errors: error ? [error as string] : [t("util_ucd_something_went_wrong")]
                    });
                }
            });
    }, [locale, onSuccess, t, isMounted]);
    return {
        ...state,
        updateBillingDetails
    };
}

type UseGetBillingDetailsAction = ActionStateProps & {
    getBillingDetails: () => void
};

export function useGetBillingDetailsAction(onSuccess = (credentials: Credentials) => {
}): UseGetBillingDetailsAction {
    const [state, dispatch] = useReducer(actionReducer, actionState);

    const locale = useLocale();
    const {t} = useTranslation();
    const isMounted = useIsMounted();

    const userData = useAppSelector((state) => state.auth.userData);

    const getBillingDetails = useCallback(() => {
        dispatch({type: "REQUEST"});
        globalAxiosController.addRequest(getBillingDetailsAPI(locale))
            .then((data) => {
                if (isMounted) {
                    if (data) {
                        if (!data.id) {
                            // non existent credentials, prefill data
                            if (!data.companyName) {
                                data.companyName = userData?.company.name || "";
                            }

                            if (!data.email) {
                                data.email = userData?.companyUser.email || "";
                            }

                            if (!data.phone) {
                                data.phone = userData?.companyUser.phone || "";
                            }
                        }

                        onSuccess(data);
                        dispatch({type: "SUCCESS"});
                    } else {
                        dispatch({
                            type: "FAILURE",
                            errors: [t("util_ucd_something_went_wrong")]
                        });
                    }
                }
            })
            .catch((error: unknown) => {
                if (isMounted) {
                    dispatch({
                        type: "FAILURE",
                        errors: error ? [error as string] : [t("util_ucd_something_went_wrong")]
                    });
                }
            });
    }, [locale, onSuccess, t, isMounted]);
    return {
        ...state,
        getBillingDetails
    };
}

type UseAddMarkupAction = ActionStateProps & {
    addMarkup: (companyMarkup: CompanyMarkupsRequestPropTypes) => void
    clearState: () => void;
};

export function useAddMarkupAction(onSuccess = (companyMarkup: CompanyMarkupsPropTypes) => {
}): UseAddMarkupAction {
    const [state, dispatch] = useReducer(actionReducer, actionState);

    const locale = useAppSelector((state) => state.locale.currentLocale);
    const {t} = useTranslation();
    const isMounted = useIsMounted();

    const clearState = useCallback(() => {
        dispatch({type: "CLEAR"});
    }, []);

    const addMarkup = useCallback((companyMarkup: CompanyMarkupsRequestPropTypes) => {
        dispatch({type: "REQUEST"});
        globalAxiosController.addRequest(addCompanyMarkupAPI(companyMarkup, locale))
            .then((data) => {
                if (isMounted) {
                    if (data && data.success) {
                        onSuccess({
                            ...companyMarkup,
                            id: data.id
                        } as CompanyMarkupsPropTypes);
                        dispatch({type: "SUCCESS"});
                    } else {
                        dispatch({
                            type: "FAILURE",
                            errors: (data && data.errors) || [
                                t("util_ucd_something_went_wrong")
                            ]
                        });
                    }
                }
            })
            .catch((error: unknown) => {
                if (isMounted) {
                    dispatch({
                        type: "FAILURE",
                        errors: error ? [error as string] : [t("util_ucd_something_went_wrong")]
                    });
                }
            });
    }, [locale, onSuccess, t, isMounted]);
    return {
        ...state,
        addMarkup,
        clearState
    };
}

type UseUpdateMarkupAction = ActionStateProps & {
    updateMarkup: (companyMarkup: CompanyMarkupsPropTypes) => void;
    clearState: () => void;
};

export function useUpdateMarkupAction(onSuccess = (companyMarkup: CompanyMarkupsPropTypes) => {
}): UseUpdateMarkupAction {
    const [state, dispatch] = useReducer(actionReducer, actionState);

    const locale = useAppSelector((state) => state.locale.currentLocale);
    const {t} = useTranslation();
    const isMounted = useIsMounted();

    const clearState = useCallback(() => {
        dispatch({type: "CLEAR"});
    }, []);

    const updateMarkup = useCallback((companyMarkup: CompanyMarkupsPropTypes) => {
        dispatch({type: "REQUEST"});
        globalAxiosController.addRequest(updateCompanyMarkupAPI(companyMarkup, locale))
            .then((data) => {
                if (isMounted) {
                    if (data && data.success) {
                        onSuccess(companyMarkup);
                        dispatch({type: "SUCCESS"});
                    } else {
                        dispatch({
                            type: "FAILURE",
                            errors: (data && data.errors) || [
                                t("util_ucd_something_went_wrong")
                            ]
                        });
                    }
                }
            })
            .catch((error: unknown) => {
                if (isMounted) {
                    dispatch({
                        type: "FAILURE",
                        errors: error ? [error as string] : [t("util_ucd_something_went_wrong")]
                    });
                }
            });
    }, [locale, onSuccess, t, isMounted]);
    return {
        ...state,
        updateMarkup,
        clearState
    };
}

type UseDeleteMarkupAction = ActionStateProps & {
    deleteMarkup: (id: number) => void;
    clearState: () => void;
};

export function useDeleteMarkupAction(onSuccess = (id: number) => {
}): UseDeleteMarkupAction {
    const [state, dispatch] = useReducer(actionReducer, actionState);

    const locale = useAppSelector((state) => state.locale.currentLocale);
    const {t} = useTranslation();

    const clearState = useCallback(() => {
        dispatch({type: "CLEAR"});
    }, []);

    const deleteMarkup = useCallback((id: number) => {
        dispatch({type: "REQUEST"});
        globalAxiosController.addRequest(deleteCompanyMarkupAPI(id, locale))
            .then((data) => {
                if (data && data.success) {
                    onSuccess(id);
                    dispatch({type: "SUCCESS"});
                } else {
                    dispatch({
                        type: "FAILURE",
                        errors: (data && data.errors) || [
                            t("util_ucd_something_went_wrong")
                        ]
                    });
                }
            })
            .catch((error: unknown) => {
                dispatch({
                    type: "FAILURE",
                    errors: error ? [error as string] : [t("util_ucd_something_went_wrong")]
                });
            });
    }, [locale, onSuccess, t]);

    return {
        ...state,
        clearState,
        deleteMarkup
    };
}
