import React, {ReactElement} from "react";
import {useTranslation} from "react-i18next";
import {ReactComponent as DocIcon} from "assets/icons/document.svg";
import {ReactComponent as PendingIcon} from "assets/icons/pending.svg";
import {ReactComponent as CheckIcon} from "assets/icons/check.svg";
import {ReactComponent as SignIcon} from "assets/icons/sign.svg";
import {CompanyDocumentListItem, DocumentStatus} from "proptypes/PropTypeObjects";
import styles from "./Agreements.module.scss";
import useLocaleDateTimeFormat, {LocaleDateFormatTypeEnum} from "../../../components/utils/Internationalization/useLocaleDateTimeFormat";
import {useNavigate} from "react-router-dom"
import createLuxonDate from "../../../utils/date/createLuxonDate"

export type AgreementsProps = {
    agreements: CompanyDocumentListItem[];
};

function getStatusIcon(status: DocumentStatus): ReactElement | undefined {
    switch (status) {
    case "SIGNATURE_CONFIRMED":
        return <CheckIcon className={styles.StatusSuccess} />;
    case "NOT_SIGNED":
    case "SIGNATURE_REJECTED":
        return <SignIcon className={styles.StatusNotSigned} />;
    case "SIGNATURE_NOT_VERIFIED":
        return <PendingIcon className={styles.StatusPending} />;
    default:
        return undefined;
    }
}

export default function Agreements({
    agreements
}: AgreementsProps): ReactElement {
    const {t} = useTranslation();
    const navigate = useNavigate();
    const dateFormat = useLocaleDateTimeFormat(LocaleDateFormatTypeEnum.DATE);

    return (
        <div className={styles.Root}>
            <h2 className={styles.Heading}>{t("mc_a_al_agreements")}</h2>
            <table className={styles.Table}>
                <thead>
                    <tr>
                        <th>{t("mc_a_al_document")}</th>
                        <th>{t("mc_a_al_created")}</th>
                        <th>{t("mc_a_al_signed")}</th>
                        <th>{t("mc_a_al_status")}</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        agreements.map(({
                            id,
                            name,
                            created,
                            signed,
                            status,
                            statusText
                        }) => (
                            <tr onClick={() => navigate(`/my-company/agreement/${id}`)} key={id}>
                                <td className={styles.Document}>
                                    <DocIcon />
                                    {name}
                                </td>
                                {/* @ts-ignore */}
                                <td tdlabelmobile={t("mc_a_al_created")}>
                                    {created ? createLuxonDate(created).toFormat(dateFormat) : "-"}
                                </td>
                                {/* @ts-ignore */}
                                <td tdlabelmobile={t("mc_a_al_signed")}>
                                    {signed ? createLuxonDate(signed).toFormat(dateFormat) : "-"}
                                </td>
                                <td className={styles.Status}>
                                    <div className={styles.StatusIcon}>{getStatusIcon(status)}</div>
                                    {statusText}
                                </td>
                            </tr>
                        ))
                    }
                </tbody>
            </table>
        </div>
    );
}
