import React, {
    ReactElement,
    ReactNode,
    RefObject
} from "react";
import cx from "classnames";
import {useTranslation} from "react-i18next";
import {ButtonProps, InputProps} from "reactstrap";
import UserButton from "../UserButton";
import Input from "../Input";
import styles from "./SearchInputWithSubmit.module.scss";
import {UserButtonVariant} from "../UserButton/UserButton";
import OutlineInput from "../Input/TextInput/OutlinedInput";

export type SearchInputWithSubmitProps = {
    inputProps: InputProps,
    buttonProps?: ButtonProps,
    inputRef?: RefObject<OutlineInput>,
    regularSize: boolean,

    children?: ReactNode[] | ReactNode | undefined,
    className?: string
};

function SearchInputWithSubmit(
    props: SearchInputWithSubmitProps
): ReactElement {
    const {
        regularSize,
        children,
        inputProps,
        buttonProps,
        inputRef,

        className
    } = props;
    const {t} = useTranslation();

    return (
        <div className={cx(styles.root, regularSize && styles.regularSize, className)}>
            <Input
                wrapsLabel
                inputComponentRef={inputRef}
                variants={{labelPosition: "inside"}}
                className={styles.customInput}
                fullHeight={!regularSize}
                inputProps={{
                    ...inputProps,
                    autoComplete: "off"
                }}
            >
                {children}
            </Input>

            <UserButton
                text={t("siws_siws_search")}
                variant={UserButtonVariant.PRIMARY}
                searchButton
                buttonProps={{
                    type: "submit",
                    ...buttonProps
                }}
            />
        </div>
    );
}

export default SearchInputWithSubmit;
