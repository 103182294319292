import _ from "lodash";
import {GooglePlace, HotelCoordinatesPropTypes} from "proptypes/PropTypeObjects";
import getDistanceBetweenCoordinates from "./getDistanceBetweenCoordinates";
import getAverageDistance from "./getAverageDistance";
import getPolygonBounds from "./getPolygonBounds";
import getZoom from "./getZoom";
import getLatRad from "./getLatRad";
import getStandardDeviation from "./getStandardDeviation";

const MAX_DIST_FROM_AVER = 500;
const MAX_ZOOM_VALUE = 14;
const GOOGLE_WORLD_SIZE = 256;

export type FitMarkersToBoundsReturn = {
    calculatedZoom: number;
    calculatedCenter: google.maps.LatLng;
};

export default function fitMarkersToBounds(
    map: google.maps.Map | null | undefined,
    mapHTMLContainer: HTMLDivElement | null | undefined,
    circle: google.maps.Circle | null | undefined,
    polygon: google.maps.Polygon | null | undefined,
    places: GooglePlace[],
    dataForMarkers: Map<number, { coordinates: HotelCoordinatesPropTypes, id: number }>
): FitMarkersToBoundsReturn | undefined {
    let centerLat = 0;
    let centerLon = 0;
    let centerCount = 0;
    const markersVisible = [
        ...dataForMarkers.values()
        // ...places.map((place) => ({coordinates: {latitude: place.location.lat(), longitude: place.location.lng()}}))
    ];

    if (_.isEmpty(markersVisible) && circle && circle.getCenter() && circle.getCenter()?.lat() && circle.getCenter()?.lng()) {
        markersVisible.push({coordinates: {latitude: circle.getCenter()?.lat() || 0, longitude: circle.getCenter()?.lng() || 0}, id: 0});
    }

    if (_.isEmpty(markersVisible) && polygon && !_.isEmpty(polygon.getPaths())) {
        let polygonCenterLat = 0;
        let polygonCenterLon = 0;

        polygon.getPaths().getAt(0).forEach((path: google.maps.LatLng) => {
            polygonCenterLat += path.lat();
            polygonCenterLon += path.lng();
        });

        const total = polygon.getPaths().getAt(0).getLength();
        markersVisible.push({coordinates: {latitude: polygonCenterLat / total, longitude: polygonCenterLon / total}, id: 0});
    }

    for (let i = 0, len = markersVisible.length; i < len; ++i) {
        if (centerLat === 0 || centerLon === 0) {
            centerLat = markersVisible[i].coordinates.latitude;
            centerLon = markersVisible[i].coordinates.longitude;
        } else {
            if (getDistanceBetweenCoordinates(centerLat, centerLon, markersVisible[i].coordinates.latitude, markersVisible[i].coordinates.longitude) > MAX_DIST_FROM_AVER) {
                continue;
            }

            centerCount += 1;
            centerLat = (centerCount * centerLat + markersVisible[i].coordinates.latitude) / (centerCount + 1);
            centerLon = (centerCount * centerLon + markersVisible[i].coordinates.longitude) / (centerCount + 1);
        }
    }

    const averageDist = getAverageDistance(markersVisible, MAX_DIST_FROM_AVER, centerLat, centerLon);
    const standardDevDistance = getStandardDeviation(markersVisible, MAX_DIST_FROM_AVER, averageDist, centerLat, centerLon);

    /*second iteration
     * to exclude outliers (further then 2stdDev) in center coordinates,
     * distanceAverage and deviation calculations (for better precision)
     */

    centerCount = 2; // including marker count
    const FIRST_MULT = 2; //~95percent
    const SECOND_MULT = 1.4;//~80 percent

    for (let n = 0, len = markersVisible.length; n < len; ++n) {
        if (getDistanceBetweenCoordinates(centerLat, centerLon, markersVisible[n].coordinates.latitude, markersVisible[n].coordinates.longitude) > averageDist + FIRST_MULT * standardDevDistance) {
            continue;
        }

        centerCount += 1;
        centerLat = (centerCount * centerLat + markersVisible[n].coordinates.latitude) / (centerCount + 1);
        centerLon = (centerCount * centerLon + markersVisible[n].coordinates.longitude) / (centerCount + 1);
    }

    const averageDist2 = getAverageDistance(markersVisible, averageDist + FIRST_MULT * standardDevDistance, centerLat, centerLon);
    const standardDevDistance2 = getStandardDeviation(markersVisible, averageDist + FIRST_MULT * standardDevDistance, averageDist2, centerLat, centerLon);

    let center2Lon = 0;
    let center2Lat = 0;
    let maxLon = 0;
    let minLon = 0;
    let minLat = 0;
    let maxLat = 0;

    for (let v = 0, len = markersVisible.length; v < len; ++v) {
        const dist = getDistanceBetweenCoordinates(centerLat, centerLon, markersVisible[v].coordinates.latitude, markersVisible[v].coordinates.longitude);
        if (dist > SECOND_MULT * standardDevDistance2 + averageDist2) {
            continue;
        }

        if (maxLon === 0 && minLon === 0) {
            maxLon = markersVisible[v].coordinates.longitude;
            minLon = markersVisible[v].coordinates.longitude;
            maxLat = markersVisible[v].coordinates.latitude;
            minLat = markersVisible[v].coordinates.latitude;
        }

        maxLon = Math.max(maxLon, markersVisible[v].coordinates.longitude);
        minLon = Math.min(minLon, markersVisible[v].coordinates.longitude);

        maxLat = Math.max(maxLat, markersVisible[v].coordinates.latitude);
        minLat = Math.min(minLat, markersVisible[v].coordinates.latitude);
    }

    let zoomValue: number;

    if ((maxLat === 0 && minLat === 0) || (maxLon === 0 && minLon === 0)) {
        center2Lat = centerLat;
        center2Lon = centerLon;
        zoomValue = MAX_ZOOM_VALUE;
    } else {
        center2Lat = (maxLat + minLat) / 2;
        center2Lon = (maxLon + minLon) / 2;

        const latFraction = (getLatRad(maxLat) - getLatRad(minLat)) / Math.PI;

        const lngDiff = maxLon - minLon;
        const lngFraction = ((lngDiff < 0) ? (lngDiff + 360) : lngDiff) / 360;

        const rect1 = mapHTMLContainer?.getBoundingClientRect() || {
            right: 800, left: 400, bottom: 800, top: 400
        };
        let width = rect1.right - rect1.left;
        let height = rect1.bottom - rect1.top;
        if (width === 0 || height === 0) {
            width = 400;
            height = 300;
        }

        const zoomLat = getZoom(height, GOOGLE_WORLD_SIZE, latFraction);
        const zoomLon = getZoom(width, GOOGLE_WORLD_SIZE, lngFraction);

        zoomValue = Math.min(zoomLat, zoomLon, MAX_ZOOM_VALUE);
    }

    if (map) {
        if (circle || polygon || !_.isEmpty(places)) {
            let newBounds: google.maps.LatLngBounds | null | undefined;
            if (polygon) {
                newBounds = getPolygonBounds(polygon);
            } else if (circle) {
                newBounds = circle.getBounds();
            } else if (map && map.getBounds()) {
                newBounds = new google.maps.LatLngBounds().extend(new google.maps.LatLng(center2Lat, center2Lon));
            }

            // additional check if all POIContainer markers are visible
            places.forEach((place) => {
                if (newBounds) {
                    newBounds = newBounds.extend(place.location);
                }
            });

            if (map && newBounds) {
                map.fitBounds(newBounds, 0);
            }

            const boundCenter = map.getCenter();
            const boundZoom = map.getZoom();
            if (boundCenter && boundZoom) {
                return {
                    calculatedCenter: boundCenter,
                    calculatedZoom: boundZoom
                };
            }

            return undefined;
        }

        if (!center2Lat || !center2Lon) {
            return undefined;
        }

        const precalculatedZoomValue = zoomValue;
        const precalculatedCenterValue = new google.maps.LatLng(center2Lat, center2Lon, true);

        return {
            calculatedZoom: precalculatedZoomValue,
            calculatedCenter: precalculatedCenterValue
        };
    }

    return undefined;
}