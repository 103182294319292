import {ReduxNationalityPropTypes} from "../../proptypes/PropTypeRedux";
import NationalityTypes from "../constants/nationality.constants";
import NationalityActionProps from "./actions/nationality.reducer.actions";

const nationalities = {
    nationalities: [
        {
            iso: "LT",
            name: "Lithuanian"
        },
        {
            iso: "CW",
            name: "Curacao"
        },
        {
            iso: "OM",
            name: "Oman"
        },
        {
            iso: "BN",
            name: "Brunei"
        },
        {
            iso: "PY",
            name: "Paraguay"
        }
    ],
    currentNationality: {
        iso: "LT",
        name: "Lithuanian"
    }
};

export const INITIAL_STATE = {
    nationalities,
    currentNationalitiesLocale: "en",
    initialized: false,
    requesting: false
} as ReduxNationalityPropTypes;

export default (state = INITIAL_STATE, action: NationalityActionProps): ReduxNationalityPropTypes => {
    switch (action.type) {
    case NationalityTypes.REQ_NATIONALITY:
        return {
            ...state,
            requesting: true
        };
    case NationalityTypes.GET_NATIONALITY_SUCCESS:
        return {
            ...state,
            nationalities: action.nationalities,
            currentNationalitiesLocale: action.locale,
            initialized: true,
            requesting: false
        };
    case NationalityTypes.GET_NATIONALITY_FAILURE:
        return {
            ...state,
            error: action.error,
            requesting: false
        };
    default:
        return state;
    }
};
