import React, {ReactElement} from "react";
import {Trans, useTranslation} from "react-i18next";
import companyDetailsImage from "assets/images/company-details.png";
import {Link} from "react-router-dom";
import styles from "./Contacts.module.scss";
import ContactPhones from "./ContactPhones";
import {ReactComponent as CSIcon} from "../../assets/icons/custom support.svg";
import {ReactComponent as BagIcon} from "../../assets/icons/bag.svg";
import {ReactComponent as AccountingIcon} from "../../assets/icons/accounting.svg";
import {ReactComponent as ITIcon} from "../../assets/icons/it.svg";

const ContactsContainer = (): ReactElement => {
    const {t} = useTranslation();

    return (
        <div className={styles.PageContainer}>
            <div className={styles.Heading}>
                <div className={styles.RestAreaWrapper}>
                    <h1 className={styles.PageTitle}>
                        <Trans i18nKey="c_cc_main_text">
                            Get in touch with <span>Hotelston</span>
                        </Trans>
                    </h1>
                </div>

                <div className={styles.EmailsBox}>
                    <div className={styles.EmailItem}>
                        <div>
                            <CSIcon/>
                            <p><a
                                href={"mailto:" + t("c_cc_customer_service_email")}
                            >{t("c_cc_customer_service_email")}
                            </a>
                            </p>
                        </div>

                        <p>{t("c_cc_customer_service_email_desc")}</p>
                    </div>
                    <div className={styles.EmailItem}>
                        <div>
                            <BagIcon/>
                            <p><a href={"mailto:" + t("c_cc_sales_email")}>{t("c_cc_sales_email")}</a></p>
                        </div>

                        <p>{t("c_cc_sales_email_desc")}</p>
                    </div>
                    <div className={styles.EmailItem}>
                        <div>
                            <AccountingIcon/>
                            <p><a href={"mailto:" + t("c_cc_accounting")}>{t("c_cc_accounting")}</a></p>
                        </div>

                        <p>{t("c_cc_accounting_email_desc")}</p>
                    </div>
                    <div className={styles.EmailItem}>
                        <div>
                            <ITIcon/>
                            <p><a href={"mailto:" + t("c_cc_it_support")}>{t("c_cc_it_support")}</a></p>
                        </div>

                        <p>{t("c_cc_it_support_desc")}</p>
                    </div>
                </div>
            </div>

            <div className={styles.ContactsContainer}>
                <ContactPhones
                    tip={t("c_cc_phone_numbers_tip")}
                    title={t("c_cc_phone_numbers") + " " + t("c_cc_phone_numbers_hours")}
                    phones={[
                        {
                            lang: "es",
                            label: t("c_cc_spain"),
                            numbers: ["+34 822 680 057"]
                        },
                        {
                            lang: "pt",
                            label: t("c_cc_portugal"),
                            numbers: ["+351 211 452 311"]
                        },
                        {
                            lang: "bg",
                            label: t("c_cc_bulgaria"),
                            numbers: ["+359 2 491 7788"]
                        },
                        {
                            lang: "cz",
                            label: t("c_cc_czech"),
                            numbers: ["+420 234 280 760"]
                        },
                        {
                            lang: "ge",
                            label: t("c_cc_georgia"),
                            numbers: ["+995 706 777 813"]
                        },
                        {
                            lang: "it",
                            label: t("c_cc_italy"),
                            numbers: ["+39 02 87360 640"]
                        },
                        {
                            lang: "lt",
                            label: t("c_cc_lithuania"),
                            numbers: ["+370 5 249 6276"]
                        },
                        {
                            lang: "pl",
                            label: t("c_cc_poland"),
                            numbers: ["+48 22 2062186"]
                        },
                        {
                            lang: "ro",
                            label: t("c_cc_romania"),
                            numbers: ["+40 31 229 59 34"]
                        },
                        // {
                        //     lang: "ru",
                        //     label: t("c_cc_russia"),
                        //     numbers: ["+7 495 249 90 62"]
                        // },
                        {
                            lang: "ua",
                            label: t("c_cc_ukraine"),
                            numbers: ["+380 44 392 80 55"]
                        }
                    ]}
                />

                <ContactPhones
                    title={t("c_cc_emergency_phone_numbers")}
                    tip={t("c_cc_emergency_phone_numbers_tip")}
                    phones={[
                        {
                            lang: "es",
                            label: t("c_cc_spain"),
                            numbers: ["+34 919 016571"]
                        },
                        {
                            lang: "pt",
                            label: t("c_cc_portugal"),
                            numbers: ["+351 211 452 312"]
                        },
                        {
                            lang: "it",
                            label: t("c_cc_italy"),
                            numbers: ["+39 02 87360 641"]
                        },
                        {
                            lang: "lt",
                            label: t("c_cc_lithuania"),
                            numbers: ["+370 5 240 5911"]
                        }
                    ]}
                />
            </div>

            <div className={styles.CompanyDetails}>
                <div className={styles.CompanyDetailsImage}>
                    <img src={companyDetailsImage} width={370} height={370} alt="company"/>
                </div>

                <div className={styles.CompanyDetailsContent}>
                    <h2>{t("c_cc_hotelston_group")}</h2>

                    <dl>
                        <dt>{t("c_cc_company_name_sl")}</dt>

                        <dt>{t("c_cc_company_id_sl_label")}</dt>
                        <dd>{t("c_cc_company_id_sl")}</dd>

                        <dt>{t("c_cc_address_sl_label")}</dt>
                        <dd>{t("c_cc_address_sl")}</dd>
                    </dl>

                    <p>
                        {/*<br />*/}
                        {/*<br />*/}
                        {/*{t("c_cc_company_name")}*/}
                        {/*<br />*/}
                        {/*{t("c_cc_company_id")}*/}
                        {/*<br />*/}
                        {/*{t("c_cc_address")}*/}
                        {/*<br />*/}
                        {/*{t("c_cc_vat_id")}*/}
                        <Trans i18nKey="c_cc_link_to_bank_accounts">
                            You will find the bank account numbers at our
                            <Link to="/contacts/payment">
                                payment information page
                            </Link>
                        </Trans>
                    </p>
                </div>
            </div>
        </div>
    );
};

export default ContactsContainer;