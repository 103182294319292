import React, {useMemo, useCallback, ReactElement} from "react";
import {ReactComponent as PinIcon} from "assets/icons/pin.svg";
import {usePinnedOffers} from "components/utils/withPinnedOffers";
import cx from "classnames";
import styles from "./Pin.module.scss";

type Props = {
    hotelId: number;
    roomId: number;
    roomNo: number;
};

export default function Pin({
    hotelId,
    roomId,
    roomNo
}: Props): ReactElement {
    const {
        addPinnedOffer,
        removePinnedOffer,
        pinnedOffers
    } = usePinnedOffers();

    const activePinnedOffer = useMemo(() => pinnedOffers
        .find((po) => po.hotelId === hotelId && po.roomId === roomId && po.roomNo === roomNo),
    [pinnedOffers, roomId, hotelId, roomNo]);

    const handlePin = useCallback(() => {
        if (activePinnedOffer) {
            removePinnedOffer(activePinnedOffer);
        } else {
            addPinnedOffer({hotelId, roomId, roomNo});
        }
    }, [activePinnedOffer, roomId, hotelId, removePinnedOffer, addPinnedOffer, roomNo]);

    return (
        <PinIcon
            onClick={handlePin}
            className={cx(styles.pin, activePinnedOffer && styles.pinActive)}
        />
    );
}