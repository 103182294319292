import baseURL from "./constants/baseURL";
import {ConstrainedAxiosRequestConfig} from "./axios/constrainedAxiosRequestConfig";

export const webSocketUrl = `${baseURL}/websocket`;

export function getCSRFToken(): ConstrainedAxiosRequestConfig<string> {
    return {
        method: "get",
        url: "/app/csrf",
        headers: {
            "Content-Type": "application/json",
            Accept: "application/json"
        },
        maxNumRequests: 1
    };
}
