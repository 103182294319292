import {ClientBookingStatusPropTypes} from "proptypes/PropTypeObjects";
import {resetTime, todayWithoutTime} from "./dateUtils";
import createLuxonDate from "./date/createLuxonDate"

export default function canCancelBooking(status: ClientBookingStatusPropTypes, serviceStartDate: number): boolean {
    if (status === "PAYMENT_PENDING") {
        return true;
    } if (status !== "CONFIRMED") {
        return false;
    }
    return createLuxonDate(serviceStartDate).valueOf() > resetTime(todayWithoutTime()).valueOf();
}
