import React, {useEffect, useState} from "react";
import PaymentProviderRedirect from "components/common/PaymentProviderRedirect/PaymentProviderRedirect";
import RedirectingModal from "components/common/Modal/RedirectingModal";
import InfoBox from "components/common/InfoBox";
import isEqual from "lodash/isEqual";
import Spinner from "components/base/Loaders/Spinner";
import {connect, ConnectedProps} from "react-redux";
import {useTranslation} from "react-i18next";
import BookingDetails from "./BookingDetails";
import MainContainer from "components/base/MainContainer";
import Content from "./Content";
import styles from "./BookingChanged.module.scss";
import CancelAndSearchAgain from "components/common/CancelAndSearchAgain";
import AcceptBookingChanges from "./AcceptBookingChanges";
import BookingChangedRoom from "./BookingChangedRoom";
import DuplicateBooking from "./DuplicateBooking";
import {
    acceptBookingChanges,
    cancelAndSearchAgain,
    confirmDuplicateBooking,
    updateBookingOptions
} from "redux/actions/hotelBooking.actions";
import Pricing from "components/common/Pricing";
import {
    PriceChangeWrapperPropTypes,
    ReservationChangesPropTypes,
    WebHotelBookingRoomPropTypes
} from "proptypes/PropTypeObjects";
import {BookingChangedTotals} from "models/BookingChangedTotals";
import BookingChangesTotals from "./BookingChangesTotals";
import RequestingBookingWrapper from "components/common/RequestingBookingWrapper/RequestingBookingWrapper";
import {hotelSelector} from "redux/selectors/hotelSearchResults.selector";
import {RootState} from "redux/store/store.init";
import router from "views/router/router"
import usePaymentOptions from "components/base/Payment/PaymentType/usePaymentOptions"
import {useAppSelector} from "redux/hooks"

// routes:
// "OK"
// "NO_AVAILABILITY"
// "CHANGES"
// "DUPLICATE_BOOKINGS"

function getPriceOrTermsChangedKey(changes: ReservationChangesPropTypes | undefined): string {
    if (changes && changes.priceChanges && !changes.bookingTermChanges) {
        return "price_has_changed";
    }
    if (changes && changes.bookingTermChanges) {
        return "terms_have_changed";
    }
    return "";
}

function getPriceOrTermsChangeKeyTitle(changes: ReservationChangesPropTypes | undefined): string {
    if (changes && changes.priceChanges && !changes.bookingTermChanges) {
        return "price_changed";
    }
    if (changes && changes.bookingTermChanges) {
        return "reservation_terms_changed";
    }
    return "";
}

function getPriceOrTermsChangeKeyMessage(changes: ReservationChangesPropTypes | undefined): string {
    if (changes && changes.priceChanges && !changes.bookingTermChanges) {
        return "review_reservation_price_changes_message";
    }
    if (changes && changes.bookingTermChanges) {
        return "review_reservation_changes_message";
    }
    return "";
}

type BookingChangedContainerProps = ConnectedProps<typeof connector>;

const BookingChangedContainer = (props: BookingChangedContainerProps) => {
    const {t} = useTranslation();

    const submittedWithDisplayTransactionFee = useAppSelector((state) => state.pricing.rememberedTransactionFee);

    const {
        hotelBooking: {
            firstTimeChanges,
            data: {
                changes,
                route,
                hotelBookingInfo,
                hotelBookingRooms,
                bookingInfo: {
                    contactInfo = null,
                    memo = null
                } = {},
                duplicateBookings

            } = {},
            requestingBooking,
            requestingOptions,
            bookingOptionsParams
        },
        auth: {
            userData: {
                currency: companyCurrency
            } = {}
        },
        hotel,
        updateBookingOptions,
        lastSearchResultsLocation,
        confirmDuplicateBooking,
        acceptBookingChanges,
        cancelAndSearchAgain,
        currency,
        hotelAmount,
        locale,
        providerPayment
    } = props;

    const {
        paymentOptions,
        displayTransactionFee,
        paymentTransferType,
        transactionFee,
        voucherSum,
        discount
    } = usePaymentOptions();

    const [
        oldAndNewDisplaySalePriceTotals,
        setOldAndNewDisplaySalePriceTotals
    ] = useState<BookingChangedTotals | undefined>(undefined);

    useEffect(() => {
        if (
            !requestingOptions &&
            !requestingBooking &&
            bookingOptionsParams &&
            !isEqual({
                locale,
                currency
            }, bookingOptionsParams)
        ) {
            updateBookingOptions();
        }
    }, [
        locale,
        currency,
        updateBookingOptions,
        requestingOptions,
        requestingBooking,
        bookingOptionsParams
    ]);

    useEffect(() => {
        if (changes && changes.priceChanges) {
            type Temp = {
                newTotal: number,
                oldTotal: number,
                priceChanged: boolean
            };

            const oldNewDisplaySalePriceTotals: Temp = changes.priceChanges.reduce(
                (prev: Temp, curr: PriceChangeWrapperPropTypes) => ({
                    oldTotal:
                        Number(prev.oldTotal) +
                        Number(curr.oldDisplaySalePrice) * hotelAmount,
                    newTotal:
                        Number(prev.newTotal) +
                        Number(curr.newDisplaySalePrice) * hotelAmount,
                    priceChanged: prev.priceChanged || !!curr.priceChanged
                }),
                {
                    newTotal: 0,
                    oldTotal: 0,
                    priceChanged: false

                }
            );

            const totals: BookingChangedTotals = {
                priceChanged: oldNewDisplaySalePriceTotals.priceChanged,
                oldTransactionFee: submittedWithDisplayTransactionFee || 0,
                newTransactionFee: displayTransactionFee,
                oldBookingPrice: oldNewDisplaySalePriceTotals.oldTotal,
                newBookingPrice: oldNewDisplaySalePriceTotals.newTotal
            };
            setOldAndNewDisplaySalePriceTotals(totals);
        }
    }, [changes, hotelAmount, displayTransactionFee, submittedWithDisplayTransactionFee]);

    return (
        <MainContainer
            className={styles.Root}
            variants={{
                center: requestingBooking || requestingOptions
            }}
            sidebar={
                !requestingBooking && !requestingOptions && !firstTimeChanges && hotelBookingInfo && contactInfo && (
                    <BookingDetails
                        hotelBookingRoomInfo={hotelBookingInfo.hotelBookingRoomInfo}
                        contactInfo={contactInfo}
                        memo={memo}
                        hideBookingDetailsNote={route === "DUPLICATE_BOOKINGS"}
                    />
                )
            }
        >
            {
                requestingBooking || requestingOptions ? requestingBooking ? (

                    hotel && (
                        <RequestingBookingWrapper/>
                    )
                ) : (
                    <Spinner
                        size="50px"
                        style={{
                            width: "100%",
                            height: "calc(100vh - 120px)"
                        }}
                    />
                ) : (
                    <Content>
                        {(() => {
                            switch (route) {
                            case "NO_AVAILABILITY":
                                return (
                                    <>
                                        <div className={styles.Heading}>
                                            <h1>{t("h_bc_bcc_reservation_failed")}</h1>

                                            <InfoBox
                                                title={t("h_bc_bcc_not_available_room")}
                                                message={t("h_bc_bcc_room_sold_out_notice")}
                                                type="error"
                                            />
                                        </div>

                                        {hotelBookingRooms && hotelBookingRooms.map(
                                            (hotelBookingRoom: WebHotelBookingRoomPropTypes, i: number) => (
                                                <BookingChangedRoom
                                                    key={i}
                                                    data={{
                                                        ...hotelBookingRoom,
                                                        currency,
                                                        hotelAmount
                                                    }}
                                                />
                                            )
                                        )}
                                    </>
                                );
                            case "CHANGES":
                                return (
                                    <>
                                        <div className={styles.Heading}>
                                            <h1>{t("h_bc_bcc_" + getPriceOrTermsChangedKey(changes))}</h1>

                                            <InfoBox
                                                title={t("h_bc_bcc_" + getPriceOrTermsChangeKeyTitle(changes))}
                                                message={t("h_bc_bcc_" + getPriceOrTermsChangeKeyMessage(changes))}
                                                type="warn"
                                            />
                                        </div>

                                        {hotelBookingRooms && changes && hotelBookingInfo && hotelBookingRooms.map((room: WebHotelBookingRoomPropTypes, i: number) => (
                                            <BookingChangedRoom
                                                key={i}
                                                data={{
                                                    paymentTransferTypeEnum: paymentTransferType,
                                                    ...room,
                                                    priceChanges: changes.priceChanges && changes.priceChanges[i],
                                                    bookingTermChanges: changes.bookingTermChanges && changes.bookingTermChanges[i],
                                                    adults: !firstTimeChanges ? hotelBookingInfo.hotelBookingRoomInfo[i].adults : undefined,
                                                    currency,
                                                    hotelAmount
                                                }}
                                            />
                                        ))}

                                        {oldAndNewDisplaySalePriceTotals && (
                                            <BookingChangesTotals
                                                oldAndNewTotals={oldAndNewDisplaySalePriceTotals}
                                                currencyName={currency}
                                            />
                                        )}

                                        <AcceptBookingChanges
                                            acceptAndBook={() => (firstTimeChanges ? acceptBookingChanges(false) : acceptBookingChanges(true))}
                                        >
                                            {paymentOptions?.displaySalePrice && paymentOptions.salePrice && (
                                                <Pricing
                                                    displaySalePrice={paymentOptions?.displaySalePrice}
                                                    salePrice={paymentOptions.salePrice}
                                                    voucher={voucherSum.used}
                                                    discount={discount}
                                                    paymentPending={paymentOptions?.paymentPending}
                                                />
                                            )}
                                        </AcceptBookingChanges>
                                    </>
                                );
                            case "DUPLICATE_BOOKINGS":
                                return (
                                    <>
                                        <div className={styles.Heading}>
                                            <h1>{t("h_bc_bcc_possible_double_booking")}</h1>

                                            <InfoBox
                                                title={t("h_bc_bcc_attention")}
                                                message={t("h_bc_bcc_notice_about_possible_double_booking")}
                                                type="warn"
                                            />
                                        </div>

                                        {duplicateBookings && (
                                            <DuplicateBooking
                                                duplicateBookings={duplicateBookings}
                                                goBack={() => router.navigate("/hotels/booking/")}
                                                confirmDuplicateBooking={confirmDuplicateBooking}
                                            />
                                        )}
                                    </>
                                );

                            default:
                                return false;
                            }
                        })()}
                    </Content>
                )
            }
            {!!providerPayment && (
                <PaymentProviderRedirect {...providerPayment}>
                    <RedirectingModal/>
                </PaymentProviderRedirect>
            )}
            {route !== "DUPLICATE_BOOKINGS" && route !== "OK" && (
                <CancelAndSearchAgain
                    canSavePassengerDetails={!firstTimeChanges}
                    routeBack={lastSearchResultsLocation}
                    cancelAndSearchAgain={cancelAndSearchAgain}
                />
            )}
        </MainContainer>
    );
};

const mapStateToProps = ({
    hotelBooking,
    hotelSearchResults,
    router,
    currency,
    auth,
    locale,
    payment
}: RootState) => ({
    hotelBooking,
    auth,
    hotel: hotelBooking.data?.hotelAvailabilityCriteria &&
        hotelSelector()(hotelSearchResults, hotelBooking.data.hotelAvailabilityCriteria.hotelId),
    locale: locale.currentLocale,
    currency: currency.currentCurrency,
    hotelAmount: auth.userData?.companyMarkups[auth.currentCompanyMarkupIndex || 0].hotelAmount || 1,
    //hotelAmount: auth.userData?.companyMarkups[auth.currentCompanyMarkupIndex].hotelAmount,
    // hotel:(hotelBooking.data.hotelAvailabilityCriteria&&
    // hotelSelector()(hotelSearchResults, hotelBooking.data.hotelAvailabilityCriteria.hotelId)) || {},
    lastSearchResultsLocation: router.lastLocations.hotels.lastSearchResultsLocation,
    providerPayment: payment.providerPayment
});

const connector = connect(mapStateToProps, {
    confirmDuplicateBooking,
    acceptBookingChanges,
    cancelAndSearchAgain,
    updateBookingOptions
});

export default connector(BookingChangedContainer);
