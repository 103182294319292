import {ChangeEvent, useCallback, useState} from "react";
import {useTranslation} from "react-i18next";
import {CountryPropTypes, InvoiceRecipientPropTypes} from "proptypes/PropTypeObjects";
import Input from "components/base/Input";
import CustomRadioButton from "components/base/Input/CustomRadioButton";
import CountriesDropdown from "components/base/Input/CustomReactSelect/CountriesDropdown";
import styles from "./BookingInvoiceRecipient.module.scss";

type Props = {
    handleInvoiceRecipient: ({target: {value, name}}: any) => void,
    invoiceRecipient: InvoiceRecipientPropTypes | undefined
}

export default function BookingInvoiceRecipient({handleInvoiceRecipient, invoiceRecipient}: Props) {
    const {t} = useTranslation();
    const [customInvoiceCountry, setCustomInvoiceCountry] = useState<string>("");

    const handleChangeInvoiceReceiver = useCallback((e?: ChangeEvent<HTMLInputElement>) => {
        const syntheticEvent = {
            target: {
                name: "companyInvoice",
                value: e?.target.id === "Company"
            }
        };
        handleInvoiceRecipient(syntheticEvent);
    }, [handleInvoiceRecipient]);

    const handleCustomInvoiceCountry = useCallback(
        (country?: CountryPropTypes) => {
            if (country && customInvoiceCountry !== country.name) {
                setCustomInvoiceCountry(country.name);
                const syntheticEvent = {
                    target: {
                        name: "country",
                        value: country.id
                    }
                };
                handleInvoiceRecipient(syntheticEvent);
            }
        },
        [handleInvoiceRecipient]
    );


    return (
        <div className={styles.CustomInvoiceContainer}>
            <div className={styles.CustomInvoiceReceiver}>
                <CustomRadioButton
                    id="Company"
                    name="Company"
                    label={t("h_b_bf_option_company")}
                    onChange={(e) => handleChangeInvoiceReceiver(e)}
                    checked={invoiceRecipient?.companyInvoice}
                />

                <CustomRadioButton
                    id="Person"
                    name="Person"
                    label={t("h_b_bf_option_person")}
                    onChange={(e) => handleChangeInvoiceReceiver(e)}
                    checked={!invoiceRecipient?.companyInvoice}
                />
            </div>

            <div className={styles.InvoiceCompany}>
                <Input
                    fullHeight={false}
                    variants={{labelPosition: "outlined"}}
                    inputProps={{
                        onChange: (e) => {
                            handleInvoiceRecipient(e);
                        },
                        type: "text",
                        autoComplete: "organization",
                        name: "companyName",
                        required: true,
                        value: invoiceRecipient?.companyName || "",
                        placeholder: t(invoiceRecipient?.companyInvoice ? "h_b_bf_company_name" : "h_b_bf_person_name"),
                        className: "input-field"
                    }}
                />
            </div>

            <div className={styles.InvoiceCompanyAddress}>
                <Input
                    fullHeight={false}
                    variants={{labelPosition: "outlined"}}
                    inputProps={{
                        onChange: (e) => {
                            handleInvoiceRecipient(e);
                        },
                        type: "text",
                        autoComplete: "on",
                        name: "address",
                        required: true,
                        value: invoiceRecipient?.address  || "",
                        placeholder: t("h_b_bf_address"),
                        // TODO change to translations?
                        // placeholder: invoiceRecipient?.companyInvoice ? "company address" : "personal address",
                        className: "input-field"
                    }}
                />
            </div>

            <div className={styles.InvoiceCompanyCode}>
                <Input
                    fullHeight={false}
                    variants={{labelPosition: "outlined"}}
                    inputProps={{
                        onChange: (e) => {
                            handleInvoiceRecipient(e);
                        },
                        type: "text",
                        autoComplete: "on",
                        required: false,
                        name: "companyCode",
                        value: invoiceRecipient?.companyCode|| "",
                        placeholder: t(invoiceRecipient?.companyInvoice ? "h_b_bf_company_code" : "h_b_bf_person_code"),
                        className: "input-field"
                    }}
                />
            </div>

            <div className={styles.InvoiceVATCode}>
                <Input
                    fullHeight={false}
                    variants={{labelPosition: "outlined"}}
                    inputProps={{
                        onChange: (e) => {
                            handleInvoiceRecipient(e);
                        },
                        type: "text",
                        autoComplete: "on",
                        name: "vatCode",
                        required: false,
                        value: invoiceRecipient?.vatCode || "",
                        placeholder: t("h_b_bf_vat_code"),
                        className: "input-field"
                    }}
                />
            </div>
            <div className={styles.InvoiceCountry}>
                <CountriesDropdown
                    setDefaultCountry={false}
                    required={true}
                    className="mb-20"
                    currentCountryName={
                        customInvoiceCountry || ""
                    }
                    onCountryChange={handleCustomInvoiceCountry}
                    onCountryInputChange={(e) => {
                        setCustomInvoiceCountry(e.target.value);
                    }}
                    countryInputName="customInvoiceRecipientCountry"
                />
            </div>
        </div>
    )
}