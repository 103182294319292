import React, {ReactElement} from "react";
import {ReactComponent as DownloadIcon} from "assets/icons/autoconfirmation.svg";
import {useTranslation} from "react-i18next";
import styles from "components/base/Payment/AppliedBlock/Applied.module.scss";
import {PaymentTransferTypePropTypes} from "proptypes/PropTypeObjects";
import {useAppDispatch, useAppSelector} from "redux/hooks"
import {setPaymentTransferType} from "redux/actions/payment.actions"

type Props = {
    propData: {
        payDue: number;
        handleSubmit: (e: React.MouseEvent<HTMLButtonElement>) => void;
    };
    loading: boolean;
    children: React.ReactNode;
};

const AppliedBlock = (props: Props): ReactElement => {
    const {t} = useTranslation();
    const dispatch = useAppDispatch();
    const voucherSum = useAppSelector((state) => state.payment.voucherSum);
    const appliedDiscountAmount = useAppSelector((state) => state.payment.appliedDiscountAmount);

    const {
        propData: {
            handleSubmit,
            payDue
        },
        loading,
        children
    } = props;

    const handleBook = (e: React.MouseEvent<HTMLButtonElement>) => {
        dispatch(setPaymentTransferType(PaymentTransferTypePropTypes.TRANSFER));
        handleSubmit(e);
    };

    if (!loading && payDue <= 0 && (voucherSum.used > 0 || !!appliedDiscountAmount)) {
        return (
            <div className={styles.root}>
                <div className="d-flex mb-4">
                    <DownloadIcon
                        className={styles.dropdown}
                        width={22}
                        height={22}
                    />

                    <div className={styles.title}>{t("pc_ab_your_booking_is_fully_covered_by_refund_voucher")}</div>
                </div>

                <div className="row">
                    <div className="col-6">
                        {children}
                    </div>

                    <div className="col-6 d-flex">
                        <button
                            type="button"
                            onClick={handleBook}
                            className={styles.button}
                        >
                            {t("pc_ab_book_now")}
                        </button>
                    </div>
                </div>
            </div>
        );
    }

    return (
        <div/>
    );
};

export default AppliedBlock;
