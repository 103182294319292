import React, {Component} from "react";
import {connect, ConnectedProps} from "react-redux";
import {login, clearCurrentUserData} from "../../redux/actions/auth.actions";
import {getLocale} from "../../redux/actions/locale.actions";
import Login from "./Login";
import {RootState} from "../../redux/store/store.init";
import CookieConsentContainer from "../../components/utils/CookieConsentContainer/CookieConsentContainer";
import {getCookieValue, setCookieValue} from "../../utils/cookie/cookie";
import scrollToFormError from "../../utils/scrollToFormError";
import {Navigate} from "react-router-dom";
import LoginNavBar from "./LoginNavBar/LoginNavBar"

 type Props = ConnectedProps<typeof connector>;

type LoginContainerState = {
    email: string;
    password: string;
};

class LoginContainer extends Component<Props, LoginContainerState> {
    constructor(props: Props) {
        super(props);
        this.state = {
            email: "",
            password: ""
        };
    }

    handleValidationAndSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        if (!e) {
            return;
        }

        const form = e.target as HTMLFormElement;
        e.preventDefault();
        const valid = form.checkValidity();
        if (!valid) {
            scrollToFormError(form);
        }

        if (valid) {
            this.handleSubmit();
        }
    };

    handleInput = (e: React.ChangeEvent<HTMLInputElement>) => {
        const {name, value} = e.target;
        //@ts-expect-error  FIXME:
        this.setState({
            [name]: value
        });
    };

    handleSubmit = (pin?: string) => {
        const {email, password} = this.state;
        const {login} = this.props;

        login(email, password, pin);
    };

    render() {
        const {
            email,
            password
        } = this.state;

        const {
            userData,
            loggingIn,
            loggingOut,
            error,
            pinLoginType,
            clearCurrentUserData,
            loginError
        } = this.props;

        return (
            <>
                {/*{!userData || loggingOut ? (*/}
                {/*    <Login*/}
                {/*        email={email}*/}
                {/*        password={password}*/}
                {/*        pinLoginType={pinLoginType}*/}
                {/*        error={error}*/}
                {/*        handleInput={this.handleInput}*/}
                {/*        handleSubmit={this.handleValidationAndSubmit}*/}
                {/*        handlePinSubmit={this.handleSubmit}*/}
                {/*        loggingIn={loggingIn}*/}
                {/*        clearCurrentUserData={clearCurrentUserData}*/}
                {/*        loginError={loginError}*/}
                {/*    />*/}
                {/*) : (*/}
                {/*    <Navigate to="/" />*/}
                {/*)}*/}

                <LoginNavBar />

                <Login
                    email={email}
                    password={password}
                    pinLoginType={pinLoginType}
                    error={error}
                    handleInput={this.handleInput}
                    handleSubmit={this.handleValidationAndSubmit}
                    handlePinSubmit={this.handleSubmit}
                    loggingIn={loggingIn}
                    clearCurrentUserData={clearCurrentUserData}
                    loginError={loginError}
                />

                <CookieConsentContainer />
            </>
        );
    }
}

const mapStateToProps = (state: RootState) => ({
    pinLoginType: state.auth.pinLoginType,
    error: state.auth.error,
    userData: state.auth.userData,
    loggingOut: state.auth.loggingOut,
    loggingIn: state.auth.loggingIn,
    loginError: state.auth.loginError
});

const connector = connect(mapStateToProps, {
    login,
    clearCurrentUserData,
    getLocale
});

export default connector(LoginContainer);
