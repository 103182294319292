import React, {ReactElement, useEffect, useState} from "react";
import {Link} from "react-router-dom";
import styles from "./ImagesHeader.module.scss";
import {ReactComponent as NoPhoto} from "../../../../../assets/images/no-photo-placeholder.svg";

type ImagesHeaderProps = {
    images?: string[];
    link: string;
};

const ImagesHeader = ({
    images,
    link
}: ImagesHeaderProps): ReactElement => {
    const [finalImages, setFinalImages] = useState<string[] | undefined>(undefined);

    useEffect(() => {
        let isMounted = true;
        if (isMounted) {
            if (images && images.length > 0) {
                setFinalImages(images.slice(0, images.length > 4 ? 4 : images.length));
            } else {
                //onError(true);
            }
        }

        return () => { isMounted = false; };
    }, [images]);

    return (
        <div className={styles.Root}>
            <Link to={link} target="_blank">
                <div className={styles.ImageWrapper}>
                    {!finalImages && <NoPhoto />}

                    {finalImages && finalImages.map((src, i) => (
                        <div
                            key={i}
                            className={styles.ImageContainer}
                            style={{
                                backgroundImage: `url("${src}")`
                            }}
                        />
                    ))}
                </div>
            </Link>
        </div>
    );
};

export default ImagesHeader;