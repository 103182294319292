import React, {ReactElement} from "react";
import BasicDropdown from "components/base/Input/BasicDropdown/BasicDropdown";
import ChildrenDropdown from "components/common/Roomate/ChildrenDropdown";
import classNames from "classnames";
import {useTranslation} from "react-i18next";
import styles from "./Pax.module.scss";
import {Option} from "../../../proptypes/PropTypeObjects";

const adultNumbers = (): Option<number, string>[] => {
    const ret = [] as Option<number, string>[];
    for (let i = 1; i < 100; i++) {
        const item = {value: i, label: i.toString(10)} as Option<number, string>;
        ret.push(item);
    }

    return ret;
};

const childrenNumbers = (): Option<number, string>[] => {
    const ret = adultNumbers();
    const items = {value: 0, label: "0"} as Option<number, string>;
    ret.unshift(items);
    return ret;
};

type Props = {
    data: {
        children: number[];
        adults: number;
        pax: number;
        sidebar?: boolean
    }
    handleAdultsChange(): void;
    handleChildrenChange(): void;
    className?: string;
};

function Pax({
    data,
    handleAdultsChange,
    handleChildrenChange,
    className
}: Props): ReactElement {
    const {t} = useTranslation();

    const paxClass = classNames(
        styles.root,
        data.sidebar && styles.sidebar,
        data.children && data.children.length > 0 && styles.withChildren,
        className
    );

    // @ts-expect-error ts-migrate(2554) FIXME: Expected 1-3 arguments, but got 0.
    const childrenAges = Array(15).fill().map((_, i) => ({
        value: i,
        label: t("pax_p_years_old", {count: i, age: i})
    }));

    return (
        <div className={paxClass} style={data.sidebar ? {display: "block"} : {}}>
            <div className={styles.title}>{t("pax_p_pax")}: {data.pax}</div>
            <div className={styles.pax} style={data.sidebar ? {display: "block"} : {}}>
                <div className={styles.adults}>
                    <div className={styles.label}>{t("pax_p_num_of_adults") + ":"}</div>
                    <BasicDropdown
                        hideErrors={true}
                        required={true}
                        onDropdownChange={handleAdultsChange}
                        className="adults-dropdown"
                        dropDownValue={data.adults}
                        dropDownOptions={adultNumbers()}
                    />
                </div>
                <ChildrenDropdown
                    label={t("pax_p_num_of_children") + ": "}
                    className={styles.children}
                    handleChildren={handleChildrenChange}
                    dropDownValues={data.children}
                    numbers={childrenNumbers()}
                    childrenAges={childrenAges}
                />
            </div>
        </div>
    );
}

export default Pax;
