import React, {ReactElement, useCallback, useMemo, useState} from "react";
import {useTranslation} from "react-i18next";
import {CompanyMarkupsPropTypes} from "../../../proptypes/PropTypeObjects";
import {TableHeading, TableRowVariant} from "../../../components/base/ResponsiveTable/ResponsiveTable.base";
import TablePageTemplate, {
    TablePageTableRow,
    TablePageTableRowCell
} from "../../../components/base/TablePageTemplate/TablePageTemplate";
import SearchInput from "components/common/SearchInput/SearchInput";
import EditDeleteMarkup from "../EditDeleteMarkup";
import AddMarkup from "../AddMarkup";
import styles from "./SalesMarkupList.module.scss";

function getValue(value: any, key: string) {
    switch (key) {
    case "hotelAmount":
        return value.toFixed(2);
    case "transferAmount":
        return value.toFixed(2);
    default:
        return value;
    }
}

type Props = {
    markups: CompanyMarkupsPropTypes[];
    addCompanyMarkup: (companyMarkup: CompanyMarkupsPropTypes) => void;
    updateCompanyMarkup: (companyMarkup: CompanyMarkupsPropTypes) => void;
    deleteCompanyMarkup: (id: number) => void;
};

function SalesMarkupList({
    markups,
    addCompanyMarkup,
    updateCompanyMarkup,
    deleteCompanyMarkup
}: Props): ReactElement {
    const columns = [
        {
            key: "name"
        },
        {
            key: "hotelAmount"
        },
        {
            key: "transferAmount"
        }
    ];

    const {t} = useTranslation();
    const [searchTerm, setSearchTerm] = useState("");

    const searchedMarkups = useMemo(() => (
        markups.filter(markup =>
            markup.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
            markup.hotelAmount.toString().toLowerCase().includes(searchTerm.toLowerCase()) ||
            markup.transferAmount.toString().toLowerCase().includes(searchTerm.toLowerCase()))
    ), [markups, searchTerm]);

    const handleSearchInput = useCallback((e: string) => {setSearchTerm(e)}, []);

    const searchTooltip = useMemo(() =>
        columns.map(({key}) => (t("mc_sml_" + key))).join(", "), [t]);


    const tHeadings = columns.map(({key}) => ({
        element: t("mc_sml_" + key),
        sortable: false,
        label: t("mc_sml_" + key),
        style: key === "hotelAmount" || key === "transferAmount" ? {display: "block", textAlign: "right"} : undefined
    }) as TableHeading);

    const tRows = searchedMarkups.map((data) => ({
        // normal disabled add remove link editable
        variant: "Editable" as TableRowVariant,

        actionable: false,
        onClick: () => false,

        actionTd: {
            element: (
                <EditDeleteMarkup
                    updateCompanyMarkup={updateCompanyMarkup}
                    deleteCompanyMarkup={deleteCompanyMarkup}
                    companyMarkup={data}
                    editTooltipText={t("mc_sml_edit")}
                    deleteTooltipText={t("mc_sml_delete")}
                />
            ),
            actionable: true
        },

        tDatas: columns.map(({key}) => ({
            // @ts-ignore
            element: <span style={key === "hotelAmount" || key === "transferAmount" ? {display: "block", textAlign: "right"} : undefined}>{getValue(data[key], key)}</span>,
            actionable: false,
            // @ts-ignore
            rawValue: getValue(data[key], key)
        } as TablePageTableRowCell))
    } as TablePageTableRow));

    return (
        <TablePageTemplate
            title={(<h2 className={styles.heading}>{t("mc_sml_sales_markups")}</h2>)}
            actions={(
                <div className={styles.actionsContainer}>
                    <SearchInput
                        handleSearch={handleSearchInput}
                        minInputLength={1}
                        tooltipText={searchTooltip}
                    />
                    <AddMarkup addMarkup={addCompanyMarkup} />
                </div>)}
            tHeadings={tHeadings}
            tRows={tRows}
            tableSettings={{
                firstColumnSticky: false,
                withoutBorder: true,
                scrollbar: false,
                transparent: true
            }}
            standalonePage={false}
            className={styles.Root}
        />
    );
}

export default SalesMarkupList;
