import React, {ReactElement} from "react";
import {useTranslation} from "react-i18next";
import styles from "./ResultsNav.module.scss";
import BasicDropdown from "../../../components/base/Input/BasicDropdown/BasicDropdown";

export type ResultsNavProps = {
    sortByOptions: { value: string }[];
    sortBy: { value: string; label: string; };

    handleSortChange: (value: string, label: string, i?: number) => void;

    allResultsCount: number;
};

const ResultsNav = ({
    sortByOptions,
    sortBy,
    handleSortChange,
    allResultsCount
}: ResultsNavProps): ReactElement => {
    const {t} = useTranslation();

    const sortByOptionsFormed = sortByOptions.map(({
        value
    }) => ({
        value,
        label: t("t_sr_sr_" + value)
    }));

    return (
        <div className={styles.NavRoot}>
            <div className={styles.NavLeft}>
                <div className={styles.Sort}>
                    <span>{t("t_sr_sr_sort_by")}</span>
                    <BasicDropdown<string, string>
                        required={true}
                        hideErrors={true}
                        dropDownOptions={sortByOptionsFormed}
                        onDropdownChange={handleSortChange}
                        dropDownValue={t(sortBy.value)}
                        className={styles.SortDropdown}
                        noBorders
                    />
                </div>

                <div className={styles.Results}>
                    <span className={styles.ResultsLabel}>{t("t_sr_sr_results")}:&nbsp;</span>

                    <div className={styles.ResultsCountContainer}>
                        <span className={styles.ResultsNumber}>
                            {allResultsCount}{" "}
                            {t("t_sr_sr_transfer", {count: allResultsCount})}
                        </span>

                        {/*<span className={styles.ResultsDivider} />*/}

                        {/*<span className={styles.ResultsSaveButton}>{t("t_sr_sr_save_search_param")}</span>*/}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ResultsNav;