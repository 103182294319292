import React, {ChangeEvent, Component} from "react";
import {WithTranslation, withTranslation} from "react-i18next";
import debounce from "lodash/debounce";
import {DebouncedFunc} from "lodash";
import {connect, ConnectedProps} from "react-redux";
import * as searchActions from "redux/actions/hotelSearch.actions";
import * as resultsActions from "redux/actions/hotelSearchResults.actions";
import getNationality from "redux/actions/nationality.actions";
import {getDestinations} from "redux/actions/destinations.actions";
import {
    setFormDataHotelId,
    updateFiltersData,
    updateFilters, handleInputChange
} from "redux/actions/hotelSearch.base.actions"
import {RootState} from "redux/store/store.init";
import initQuickFilters from "utils/quickFilters";
import {resetWorkerHashes} from "utils/compute/queueWorker";
import {NationalityResultPropTypes} from "proptypes/PropTypeObjects";
import SearchBox from "./SearchBox";
import {DropdownWithSearchOption} from "components/base/Input/DropdownWithSearch/StandaloneDropdownWithSearch";

type SearchBoxContainerProps = ConnectedProps<typeof connector> & WithTranslation & {
    currentHotelId: number;
    editSearchCriteria: boolean;
    discardChanges: () => void;
    searchCriteriaChanged: boolean
    handleSearchCriteriaChanged: (...args: any[]) => any;
    toggleEditSearchCriteria: () => void;
};

type SearchBoxContainerState = {
    boardTypes: any[];
    features: any[];
};

class SearchBoxContainer extends Component<SearchBoxContainerProps, SearchBoxContainerState> {
    debouncedGetDestinations: DebouncedFunc<(value: any) => any>;

    constructor(props: SearchBoxContainerProps) {
        super(props);
        const {t, getDestinations} = props;
        this.state = initQuickFilters(t);
        this.debouncedGetDestinations = debounce((value: string | undefined) => getDestinations(value), 300, {leading: false, trailing: true});
    }

    componentDidMount() {
        const {
            search: {
                nationalityInput
            },
            getNationality,
            getDestinations
        } = this.props;
        getNationality(!nationalityInput, !!nationalityInput);

        getDestinations();
    }

    componentDidUpdate(prevProps: SearchBoxContainerProps) {
        const {
            searchResults: {
                allResultsCount
            },
            searchUpdate,
            setFormDataHotelId,
            currentHotelId,

            locale,
            currency
        } = this.props;

        if (allResultsCount > 0 && prevProps.locale !== locale) {
            searchUpdate();
        }

        if (allResultsCount > 0 && prevProps.currency !== currency) {
            searchUpdate(false, false);
            setFormDataHotelId(currentHotelId);
        }

        // if (!_.isEqual(prevProps.search.stateFormData, stateFormData) || !_.isEqual(prevProps.searchResults.activeFilters, activeFilters)) {
        //     handleSearchCriteriaChanged();
        // }
    }

    handleSubmitSearch = () => {
        //e.preventDefault()
        const {
            search: {
                formData: {
                    cityId,
                    hotelId
                }
            },
            setFormDataHotelId,
            searchUpdate,
            currentHotelId,
            toggleEditSearchCriteria
        } = this.props;

        if (!cityId && (Number(hotelId) === 0 || !hotelId)) {
            setFormDataHotelId(currentHotelId);
        }

        resetWorkerHashes();
        searchUpdate(false, false, true, true);
        toggleEditSearchCriteria();
    };

    handleNatChange = (option: DropdownWithSearchOption<string>) => {
        const {
            handleInputChange,
            handleNatChange
        } = this.props;

        handleInputChange("nationalityInput", option.label);
        handleNatChange(option.value);
    };

    resetNationalities = () => {
        const {handleNatChange} = this.props;

        handleNatChange(undefined);
    };

    handleInputChange = ({
        target: {name, value}
    }: never) => {
        const {
            handleInputChange
        } = this.props;

        if (name === "destinationInput") {
            //this.debouncedGetDestinations(value, this.props.setImg);
            this.debouncedGetDestinations(value);
        }
        handleInputChange(name, value);
    };

    handleBoardType = (value: never, isActive: never) => {
        const {handleBoardTypeFilter, updateFilters} = this.props;
        handleBoardTypeFilter(value, isActive);
        updateFilters(false);
    };

    handleFilterInputChange = (e: ChangeEvent<HTMLInputElement>): void => {
        const {handleFilterInputChange, updateFilters} = this.props;
        handleFilterInputChange(e);
        updateFilters(false);
    };

    render() {
        const {
            boardTypes,
            features
        } = this.state;

        const {
            search: {
                formData: {
                    checkIn,
                    checkOut,
                    clientNationality
                },
                stateFormData: {
                    checkIn: stateCheckIn,
                    checkOut: stateCheckOut,
                    rooms: stateRooms
                },
                nationalityInput,
                destinationInput
            },
            searchResults: {
                filters,
                activeFilters,
                size
            },
            destinations: {
                destinations = {}
            },
            nationality: {
                nationalities = {} as NationalityResultPropTypes
            },
            searchCriteriaChanged,
            editSearchCriteria,
            discardChanges,
            handleDateChange,
            handleAdultsChange,
            handleChildrenChange,
            handleAddRoom,
            handleRemoveRoom
        } = this.props;

        return (
            <SearchBox
                data={{
                    searchCriteriaChanged,
                    editSearchCriteria,
                    destinations,
                    nationalities,
                    clientNationality,
                    nationalityInput,
                    destinationInput,
                    boardTypes,
                    features,
                    checkIn,
                    checkOut,
                    stateCheckIn,
                    stateCheckOut,
                    rooms: stateRooms,
                    filters,
                    activeFilters,
                    size
                }}
                resetNationalities={this.resetNationalities}
                handleBoardType={this.handleBoardType}
                handleInputChange={this.handleInputChange}
                handleNatChange={this.handleNatChange}
                handleSubmitSearch={this.handleSubmitSearch}
                handleDateChange={handleDateChange}
                handleFilterInputChange={this.handleFilterInputChange}
                handleAdultsChange={handleAdultsChange}
                handleChildrenChange={handleChildrenChange}
                handleAddRoom={handleAddRoom}
                handleRemoveRoom={handleRemoveRoom}
                discardChanges={discardChanges}
            />
        );
    }
}

const mapStateToProps = (state: RootState) => ({
    search: state.hotelSearch,
    searchResults: state.hotelSearchResults,
    destinations: state.destinations,
    nationality: state.nationality,
    locale: state.locale.currentLocale,
    currency: state.currency.currentCurrency
});

const connector = connect(mapStateToProps, {
    handleInputChange,
    ...searchActions,
    getDestinations,
    getNationality,
    ...resultsActions,
    setFormDataHotelId,
    updateFiltersData,
    updateFilters
});

export default withTranslation()(connector(SearchBoxContainer));
