import React, {ReactElement, ReactNode} from "react";
import styles from "./Content.module.scss";

type Props = {
    children?: ReactNode
};

function Content({
    children
}: Props): ReactElement {
    return (
        <div className={styles.root}>
            {children}
        </div>
    );
}

export default Content;
