import React, {ReactElement, useCallback, useMemo, useRef, useState} from "react"
import styles from "./DateRangePicker.module.scss";
import useLocaleDateTimeFormat, {
    LocaleDateFormatTypeEnum
} from "../../../../utils/Internationalization/useLocaleDateTimeFormat";
import cx from "classnames";
import DateRangePickerMonth from "../Shared/DateRangePickerMonth";
import {ReactComponent as TriangleIcon} from "assets/icons/arrow.svg";
import useMobileSwitch from "../../../../../utils/hooks/useMobileSwitch";
import Modal from "../../../../common/Modal";
import useClickOutside from "../../../../../utils/hooks/useClickOutside";
import {DateTime} from "luxon";
import createLuxonDate from "../../../../../utils/date/createLuxonDate";
import {toggleScrollLock} from "redux/actions/modals.actions";
import {connect, ConnectedProps} from "react-redux"
import {ReactComponent as CloseModalIcon} from "assets/icons/arrow 2.svg"

export type DateRangePicker = ConnectedProps<typeof connector> & {
    // dateFormat: string;
    from: DateTime;
    fromLabel?: string;

    to: DateTime;
    toLabel?: string;

    validFrom?: DateTime;

    nightsLabel?: string;

    handleDateChange?: (dateFrom: DateTime, dateTo: DateTime) => void;

    orientation?: "horizontal" | "vertical";
    hover?: boolean;
    vertical?: boolean;

    className?: string;
    pickerClassName?: string;
};

const DateRangePicker = (props: DateRangePicker): ReactElement => {
    const {
        from,
        fromLabel,
        to,
        toLabel,
        nightsLabel,
        handleDateChange,
        className,
        pickerClassName,
        validFrom,
        vertical,
        toggleScrollLock
    } = props;

    const dateFormat = useLocaleDateTimeFormat(LocaleDateFormatTypeEnum.DATE);
    const {isMobile} = useMobileSwitch();

    const containerRef = useRef<HTMLDivElement>(null);

    const [startDateSelected, setStartDateSelected] = useState(false);
    const [endDateSelected, setEndDateSelected] = useState(false);

    const [active, setActive] = useState<boolean>(false);
    const [innerValueFrom, setInnerValueFrom] = useState<DateTime>();
    const [innerValueTo, setInnerValueTo] = useState<DateTime>();

    const [hoverActive, setHoverActive] = useState<boolean>(false);

    const [
        renderCalendarFromDate,
        setRenderCalendarFromDate
    ] = useState<DateTime>(from);
    const [hoverUntil, setHoverUntil] = useState<DateTime>();

    const nights = useMemo(() => Math.floor(to.diff(from, "days").days), [from, to]);

    useClickOutside(containerRef, () => {
        setActive(false);
        setHoverActive(false);
        setHoverUntil(undefined);
        setStartDateSelected(false);
        setEndDateSelected(false);
    });

    const toggleActive = useCallback(() => {
        setActive(!active);

        if (!active) {
            setInnerValueFrom(from);
            toggleScrollLock(true);
        } else {
            toggleScrollLock(false);
        }
    }, [active, from, toggleScrollLock]);

    const onDayTickerDecrement = useCallback(() => {
        if (handleDateChange && createLuxonDate(to).minus({day: 1}).valueOf() > from.valueOf()) {
            handleDateChange(from, createLuxonDate(to).minus({day: 1}));
        }
    }, [from, handleDateChange, to]);

    const onDayTickerIncrement = useCallback(() => {
        if (handleDateChange) {
            handleDateChange(from, createLuxonDate(to).plus({day: 1}));
        }
    }, [from, handleDateChange, to]);

    const previousMonthCallback = useCallback(() => {
        setRenderCalendarFromDate(createLuxonDate(renderCalendarFromDate || from).minus({month: 1}));
    }, [from, renderCalendarFromDate]);

    const nextMonthCallback = useCallback(() => {
        setRenderCalendarFromDate(createLuxonDate(renderCalendarFromDate || from).plus({month: 1}));
    }, [from, renderCalendarFromDate]);

    const closeCallback = useCallback(() => {
        setActive(false);
        toggleScrollLock(false);
    }, [toggleScrollLock]);

    const onMouseOverDay = useCallback((value: DateTime) => {
        if (innerValueFrom && !hoverUntil?.equals(value)) {
            setHoverUntil(value);
        } else if (!innerValueFrom) {
            setHoverUntil(undefined);
        }
    }, [hoverUntil, innerValueFrom]);

    const onMouseLeaveDay = useCallback(() => {
        if (innerValueFrom) {
            // persist hover until day is selected
            return;
        }

        setHoverUntil(undefined);
    }, [innerValueFrom]);

    const onDateChanged = useCallback(() => {
        toggleScrollLock(false);
    }, [toggleScrollLock]);

    const onSelectDate = useCallback((value: DateTime) => {
        if (!innerValueFrom || !innerValueTo) {
            return;
        }

        if (startDateSelected) {
            if (value.valueOf() < innerValueFrom.valueOf()) {
                setInnerValueFrom(value);
            } else if (value.valueOf() > innerValueFrom.valueOf() && value.valueOf() > innerValueTo.valueOf()) {
                setInnerValueFrom(value);
                setInnerValueTo(value.plus({day: 1}));
            } else if (value.valueOf() > innerValueFrom.valueOf() && value.valueOf() < innerValueTo.valueOf()) {
                setInnerValueFrom(value);
            }

            if (handleDateChange) {
                if (innerValueTo.valueOf() > value.valueOf()) {
                    handleDateChange(value, innerValueTo);
                } else {
                    handleDateChange(value, value.plus({day: 1}));
                }
            }

            setStartDateSelected(false);
            setEndDateSelected(true);

            setHoverActive(true);
        }

        if (endDateSelected) {
            if (value.valueOf() < innerValueFrom.valueOf()) {
                setInnerValueFrom(value);

                if (handleDateChange) {
                    handleDateChange(value, innerValueTo);
                }

                setHoverActive(true);
            } else if (value.valueOf() > innerValueFrom.valueOf()) {
                if (handleDateChange) {
                    handleDateChange(innerValueFrom, value);
                }

                setInnerValueFrom(undefined);
                setInnerValueTo(undefined);

                setStartDateSelected(false);
                setEndDateSelected(false);

                setHoverActive(false);

                onDateChanged();
                toggleActive();
            }
        }
    }, [endDateSelected, handleDateChange, innerValueFrom, innerValueTo, onDateChanged, startDateSelected, toggleActive]);

    const startDateLabelClickCallback = useCallback(() => {
        setInnerValueFrom(from);
        setInnerValueTo(to);
        setStartDateSelected(true);

        toggleActive();
    }, [from, to, toggleActive]);

    const endDateLabelClickCallback = useCallback(() => {
        setInnerValueFrom(from);
        setInnerValueTo(to);
        setEndDateSelected(true);

        toggleActive();
    }, [from, to, toggleActive]);

    const suppressContainerEventsCallback = useCallback((e: React.MouseEvent<HTMLDivElement>) => {
        e.stopPropagation();
        e.preventDefault();
    }, []);

    const element = (
        <div
            ref={containerRef}
            onClick={suppressContainerEventsCallback}
            className={cx(styles.Root, vertical && styles.Vertical, active && styles.Active, className)}
        >
            <div className={styles.DateFrom}>
                {fromLabel && (<label>{fromLabel}</label>)}

                <a className={styles.Date} onClick={startDateLabelClickCallback}>{from.toFormat(dateFormat)}</a>
            </div>

            <div className={styles.DayTicker}>
                {nightsLabel && (<label>{nightsLabel}</label>)}

                <div className={styles.DayTickerContent}>
                    <a onClick={onDayTickerDecrement}>-</a>

                    {nights}

                    <a onClick={onDayTickerIncrement}>+</a>
                </div>
            </div>

            <div className={styles.DateTo}>
                {toLabel && (<label>{toLabel}</label>)}

                <a className={styles.Date} onClick={endDateLabelClickCallback}>{to.toFormat(dateFormat)}</a>
            </div>

            {active && (
                <div className={cx(styles.DateRangePicker, pickerClassName)}>
                    <div className={styles.DateRangePickerControls}>
                        <a onClick={previousMonthCallback}>
                            <TriangleIcon/>
                        </a>

                        <a onClick={nextMonthCallback}>
                            <TriangleIcon/>
                        </a>

                        <a onClick={closeCallback} className={styles.CloseIcon}>
                            <CloseModalIcon/>
                        </a>
                    </div>

                    <DateRangePickerMonth
                        value={renderCalendarFromDate}
                        from={innerValueFrom || from}
                        until={innerValueTo || to}
                        hover={hoverActive}
                        hoverUntil={hoverUntil}
                        onSelectDay={onSelectDate}
                        onDayMouseEnter={onMouseOverDay}
                        onDayMouseLeave={onMouseLeaveDay}
                        validFrom={validFrom}
                    />

                    <DateRangePickerMonth
                        value={renderCalendarFromDate}
                        from={innerValueFrom || from}
                        until={innerValueTo || to}
                        hover={hoverActive}
                        hoverUntil={hoverUntil}
                        onSelectDay={onSelectDate}
                        onDayMouseEnter={onMouseOverDay}
                        onDayMouseLeave={onMouseLeaveDay}
                        validFrom={validFrom}
                        nextMonth
                    />
                </div>
            )}
        </div>
    );

    if (active && isMobile) {
        return (
            <Modal children={element}/>
        )
    }

    return element;
}

const connector = connect(undefined, {
    toggleScrollLock
});

export default connector(DateRangePicker);