import {ChangeEvent} from "react";
import i18n from "../i18n";

const BOOKING_REF_REGEXP = /\S{2}\d{7}/;
export const BOOKING_REF_PATTERN = (new RegExp(BOOKING_REF_REGEXP).source);

const EMAIL_REGEXP = /^[a-zA-Z0-9._%+\-]+@(?!.*xn--)[a-zA-Z0-9._%+\-]+\.[a-zA-Z]+$/;
export const EMAIL_PATTERN = (new RegExp(EMAIL_REGEXP).source);

function isName(value?: string) {
    if (!value) {
        return true;
    }
    if (!value.trim()) {
        return false;
    }
    const regex = /^[ a-zA-Z.'-]+$/;
    const regex2 = /^[^`-]/;
    const normalizedString = value
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "");
    if (
        !value ||
        value.length > 254 ||
        !regex.test(normalizedString) ||
        !regex2.test(normalizedString)
    ) {
        return false;
    }
    return true;
}
const PHONE_REGEXP = /^([+][0-9]{1,3}([ .-])?)?([(]{1}[0-9]{3}[)])?([0-9A-Z .-]{1,32})((x|ext|extension)?[0-9]{1,4}?)$/;
export const PHONE_PATTERN = (new RegExp(PHONE_REGEXP).source);

export function validateName(e: ChangeEvent<HTMLInputElement> | undefined): void {
    if (!e) {
        return;
    }
    const correct = isName(e.target.value);
    e.target.setCustomValidity(correct ? "" : i18n.t("util_vf_error_bad_name"));
}

export function validatePassword(e: ChangeEvent<HTMLInputElement> | undefined): void {
    if (!e) {
        return;
    }
    const password = e.target.value;

    const containsUpperCase = /[A-Z]/;
    const containsLowerCase = /[a-z]/;
    const containsTwoNumbers = /\d.*\d/;

    const correct = password.length >= 10 &&
        containsUpperCase.test(password) &&
        containsLowerCase.test(password) &&
        containsTwoNumbers.test(password);

    //e.target.setCustomValidity(correct ? "" : i18n.t("util_vf_bad_password"));
    e.target.setCustomValidity(correct ? "" : " ");
}

// const MARKUP_REGEX = /^(100(\.0{1,2})?|0?(\.\d{1,2})?|\d{1,2}(\.\d{1,2})?)$/; //allows 0
const MARKUP_REGEX = /^(100(\.0{1,2})?|0?\.\d{1,2}|[1-9]\d?(\.\d{1,2})?)$/;

export function validateMarkup(e: ChangeEvent<HTMLInputElement> | undefined): void {
    if (!e) {
        return
    }
    const markup = e.target.value;
    const correct = MARKUP_REGEX.test(markup);
    e.target.setCustomValidity(correct ? "" : i18n.t("util_vf_bad_markup"));
}