import React, {ReactElement, useCallback} from "react";
import {useUpdateCurrentCompanyUserAction} from "components/utils/useCompanyData";
import {useAppSelector} from "redux/hooks";
import CompanyUserView from "./CompanyUserView";

export default function CurrentCompanyUserView(): ReactElement {
    const companyUser = useAppSelector((state) => state.auth.userData?.companyUser);

    const onSuccess = useCallback(
        (currentUser: any) => {
            //setModalIsOpen(false);
            //setSuccessModal(true);
            //updateCurrentUser(currentUser)
        },
        []
    );

    const {
        requesting,
        errors,
        updateCurrentUser: updateCurrentUserAction,
        clearState
    } = useUpdateCurrentCompanyUserAction(onSuccess);

    return (
        <CompanyUserView
            companyUser={companyUser}
            requestingUserUpdate={requesting}
            userUpdateErrors={errors}
            updateUserAction={updateCurrentUserAction}
            clearState={clearState}
        />
    );
}