import React, {ReactElement, useState} from "react";
import introImg from "assets/images/searchIntroImage.jpg";
import PageIntro from "../../../components/common/PageIntro";
import SearchBoxContainer from "./SearchBox/SearchBoxContainer";
import SavedBoxContainer from "./SavedBox";

const Search = (props: any): ReactElement => {
    const [img, setImg] = useState("");
    const [swtichItem, setSwitch] = useState<number>(1);
    const setIntroImg = (item: any) => {
        setImg(item);
        handleSwitch();
    };
    const handleSwitch = () => {
        if (swtichItem === 1) {
            setSwitch(2);
            return (2);
        }
        setSwitch(1);
        return (1);
    };

    return (
        <>
            <PageIntro swtichItem={swtichItem} introImg={img && img.includes("http") ? img : introImg} />
            <SearchBoxContainer setImg={setIntroImg} />
            <SavedBoxContainer />
        </>
    );
};

export default Search;
