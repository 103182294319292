import {Location} from "history";
import RouterTypes from "../constants/router.constants";
import IAppActions from "../store/store.actions";
import {LastLocationKey, LocationBaseKey} from "../reducers/actions/router.reducer.actions";

export default function setLastLocation(location: Location, baseKey: LocationBaseKey, lastLocationKey: LastLocationKey): IAppActions {
    return ({
        type: RouterTypes.SET_LAST_LOCATION, location, baseKey, lastLocationKey
    });
}

export function toggleDisabledNav(disabledNav: boolean): IAppActions {
    return ({
        type: RouterTypes.TOGGLE_DISABLED_NAV,
        disabledNav
    });
}