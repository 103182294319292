import React, {ReactElement, useCallback, useMemo, useState} from "react";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom"
import i18n from "../../../i18n";
import {CompanyUserPropTypes} from "../../../proptypes/PropTypeObjects";
import TablePageTemplate, {
    TablePageTableRow,
    TablePageTableRowCell
} from "../../../components/base/TablePageTemplate/TablePageTemplate";
import SearchInput from "components/common/SearchInput/SearchInput";
import EditDeleteUser from "../EditDeleteUser";
import AddUser from "../AddUser";
import styles from "./CompanyUsersList.module.scss";

function getValue(key: string, data: CompanyUserPropTypes) {
    switch (key) {
    case "fullName":
        return `${data.firstname} ${data.lastname}`;
    case "rights":
        return data.admin ? i18n.t("mc_cul_administrator") : "";
    default:
        return data[key];
    }
}

type Props = {
    companyUsers: CompanyUserPropTypes[];
    addCompanyUser: (companyUser: CompanyUserPropTypes) => void;
    deleteCompanyUser: (id: number) => void;
};

function CompanyUsersList({
    addCompanyUser,
    deleteCompanyUser,
    companyUsers
}: Props): ReactElement {
    const {t} = useTranslation();
    const navigate = useNavigate();

    const [searchTerm, setSearchTerm] = useState("");
    const searchedUsers = useMemo(() =>
        (companyUsers.filter(user =>
            user.firstname.toLowerCase().includes(searchTerm.toLowerCase()) ||
            user.lastname.toLowerCase().includes(searchTerm.toLowerCase()) ||
            user.email.toLowerCase().includes(searchTerm.toLowerCase()) ||
            user.phone.toLowerCase().includes(searchTerm.toLowerCase())
        ))
    , [companyUsers, searchTerm])

    const handleSearchInput = useCallback((e: string) => {setSearchTerm(e)}, [])

    const columns = useMemo(() => [
        {
            key: "fullName"
        },
        {
            key: "email"
        },
        {
            key: "phone"
        },
        {
            key: "rights"
        }
    ], []);

    const tHeadings = useMemo(() => columns.map(({key}) => ({
        element: t("mc_cul_" + key),
        sortable: key !== "rights",
        label: t("mc_cul_" + key)
    })), [columns, t]);

    const searchTooltip = useMemo(() =>
        columns.slice(0, 3).map(({key}) => (t("mc_cul_" + key))).join(", "), [t]);

    const tRows = useMemo(() => searchedUsers.map((user) => ({
        // normal disabled add remove link editable
        variant: "Link",

        actionable: true,
        onClick: () => navigate(`/my-company/user/${user.id}`),

        actionTd: {
            // @ts-ignore
            onClick: () => {},
            element: (
                <EditDeleteUser
                    deleteUser={deleteCompanyUser}
                    companyUser={user}
                    deleteTooltipText={t("mc_cul_delete")}
                    editTooltipText={t("mc_cul_edit")}
                    changePasswordText={t("mc_cul_change_password")}
                    viewAdvancedSettingsText={t("mc_cul_view_advanced_settings")}
                />
            ),
            actionable: true
        },

        tDatas: columns.map(({key}) => ({
            element: <span>{getValue(key, user)}</span>,
            actionable: false,
            rawValue: getValue(key, user)
        } as TablePageTableRowCell))
    })) as TablePageTableRow[], [columns, companyUsers, deleteCompanyUser, history, t, searchTerm, searchedUsers]);

    return (
        <TablePageTemplate
            title={<h2 className={styles.heading}>{t("mc_cul_company_users")}</h2>}
            actions={(
                <div className={styles.actionsContainer}>
                    <SearchInput
                        handleSearch={handleSearchInput}
                        inputProps={{
                            name: "company-user-search"
                        }}
                        tooltipText={searchTooltip}
                    />
                    <AddUser addUser={addCompanyUser} />
                </div>
            )}
            tHeadings={tHeadings}
            tRows={tRows}
            tableSettings={{
                firstColumnSticky: false,
                withoutBorder: true,
                transparent: true,
                scrollbar: false
            }}
            standalonePage={false}
            className={styles.Root}
        />
    );
}

export default CompanyUsersList;
