import React, {ReactElement} from "react";
import useDynamicTimeUpdate from "utils/hooks/useDynamicTimeUpdate";
import Tippy from "../Tippy";
import useLocaleDateTimeFormat, {LocaleDateFormatTypeEnum} from "../../utils/Internationalization/useLocaleDateTimeFormat";

type Props = {
    time: number;
    outputFormatType: LocaleDateFormatTypeEnum,
    displayDailyOnly?: boolean;
    maxDaysAgo?: number;
    realTimeUpdate?: boolean;
    className?: string;
};

export default function DynamicTime({
    time,
    outputFormatType,
    displayDailyOnly,
    maxDaysAgo,
    realTimeUpdate = true,
    className
}: Props): ReactElement {
    const dateFormat = useLocaleDateTimeFormat(outputFormatType);
    const {timeFormatted, fromNow, fromNowInDays} = useDynamicTimeUpdate({
        time,
        outputFormat: dateFormat,
        displayDailyOnly,
        realTimeUpdate
    });

    return (
        <div className={className}>
            {!maxDaysAgo || fromNowInDays <= maxDaysAgo ? (
                <Tippy content={<span>{timeFormatted}</span>}>
                    <span>{fromNow}</span>
                </Tippy>
            ) : (
                timeFormatted
            )}
        </div>
    );
}
