import React, {ReactElement} from "react";
import {useAppSelector} from "../../redux/hooks";

type Props = {
    authorizedComponent(): ReactElement;
    unauthorizedComponent(): ReactElement;
};

export default function AuthComponentSwitch(
    {
        authorizedComponent: AuthorizedComponent = () => <></>,
        unauthorizedComponent: UnauthorizedComponent = () => <></>
    }: Props
): ReactElement {
    const isLoggedIn = useAppSelector((state) => !!state.auth.userData);
    return isLoggedIn ? <AuthorizedComponent /> : <UnauthorizedComponent />;
}
