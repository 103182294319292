import React, {
    CSSProperties, ReactElement, useMemo
} from "react";
import TimeCustomSelect from "components/base/Input/CustomReactSelect/TimeCustomSelect";
import styles from "./DateWithTime.module.scss";
import {dateWithSetTime} from "../../../utils/dateUtils";
import Item from "../../../components/common/Amendments/Item";
import SingleDatePicker from "../../../components/base/Input/DatePicker/SingleDatePicker/SingleDatePicker"
import {DateTime} from "luxon"
import createLuxonDate from "../../../utils/date/createLuxonDate"

function handleInsertTimeToDate({
    momentDate,
    hoursMinutes = ""
}: {momentDate: DateTime, hoursMinutes: string}, defaultValue: string): string {
    const hours = hoursMinutes.toString().split(":")[0];
    const minutes = hoursMinutes.toString().split(":")[1];

    const HH = +hours;
    const mm = +minutes;

    const validTime = /^(0[0-9]|1[0-9]|2[0-3]|[0-9]):[0-5]?[0-9]$/.test(`${HH}:${mm}`);

    if (!validTime) {
        return defaultValue;
    }

    // console.log(dateWithSetTime(momentDate, HH, mm));
    const retMoment = dateWithSetTime(momentDate, HH, mm);
    return retMoment.toISO()!;
}

type Props = {
    handleDateAndTime: (isoDate: string) => void,
    isoDateForInput: string | number | undefined;
    isoDateForValue: string | number | undefined;
    edit: boolean;
    label: string;
    labelStyle?: CSSProperties;
    transferInput?: boolean;
    required?: boolean;
    className?: string;
};

function DateWithTime({
    handleDateAndTime,
    isoDateForInput,
    isoDateForValue,
    edit = false,
    label,
    labelStyle = {},
    transferInput = false,
    required,
    className
}: Props): ReactElement {
    const formDateString = (value: string | number | undefined): DateTime => {
        let date: DateTime;
        if (value) {
            if (!Number.isNaN(+value)) {
                date = createLuxonDate(+value);
            } else {
                date = createLuxonDate(value);
            }
        } else {
            date = createLuxonDate();
        }

        return date;
    };

    const dateTimeFormed = useMemo(() => formDateString(isoDateForValue).toFormat("yyyy-MM-dd HH:mm"), [isoDateForValue]);
    const dateFormed = useMemo(() => formDateString(isoDateForInput), [isoDateForInput]);
    const hoursMinutesFormed = useMemo(() => formDateString(isoDateForInput).toFormat("HH:mm"), [isoDateForInput]);

    return (
        <Item
            edit={edit}
            label={label}
            labelStyle={labelStyle}
            value={dateTimeFormed}
            inputContainerClassName={className}
            inputEl={(
                <div className={styles.Root}>
                    <div className={styles.DateInput}>
                        <SingleDatePicker
                            className={styles.DatePicker}
                            date={dateFormed}
                            handleDateChange={
                                (momentDate) => {
                                    handleDateAndTime(
                                        handleInsertTimeToDate({
                                            momentDate,
                                            hoursMinutes: hoursMinutesFormed
                                        }, dateFormed.toFormat("yyyy-MM-dd HH:mm"))
                                    );
                                }
                            }
                        />
                    </div>

                    <TimeCustomSelect
                        required={required}
                        className={styles.TimeInput}
                        transferInput={transferInput}
                        //placeholderLabel={t("mb_ta_dwt_time")}
                        hoursMinutes={hoursMinutesFormed}
                        onChange={
                            (hoursMinutes: string) => {
                                handleDateAndTime(
                                    handleInsertTimeToDate({momentDate: dateFormed, hoursMinutes}, dateFormed.toFormat("yyyy-MM-dd HH:mm"))
                                );
                            }
                        }
                    />
                </div>
            )}
        />
    );
}

export default DateWithTime;
