import React from "react";
import cx from "classnames";
import {withLocales, WithLocalesProps} from "../../../utils/withLocalesAndCurrencies";
import BasicDropdown from "../../Input/BasicDropdown/BasicDropdown";
import styles from "./HamburgerMenuLocaleDropdown.module.scss";

const HamburgerMenuLocaleDropdown = withLocales(({
    changeCurrentLocale,
    currentLocale,
    localesFormed,
    className
}: WithLocalesProps & {className?: string}) => (
    <BasicDropdown
        required={true}
        hideErrors={true}
        className={cx(styles.Root, className)}
        onDropdownChange={(value) => changeCurrentLocale(value)}
        dropDownValue={currentLocale}
        dropDownOptions={localesFormed}
    />
));

export default HamburgerMenuLocaleDropdown;