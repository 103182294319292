import React, {ReactElement} from "react";
import {useTranslation} from "react-i18next";
import styles from "./TransferInfo.module.scss";
import SpecificLocationBox from "../SpecificLocationBox";
import TransferPassengerBox from "../TransferPassengerBox";
import {PassengerPropTypes} from "../../../proptypes/PropTypeObjects";

type Props = {
    transferName: string;
    leadPassenger: PassengerPropTypes;
    adultsCount: number;
    childrenCount: number;
    pax: number;
    specificLocation: {
        specificLocationAddress?: string;
        specificLocationName?: string;
    };
    children: JSX.Element;
};

function TransferInfo({
    transferName,
    leadPassenger,
    adultsCount,
    childrenCount,
    pax,
    specificLocation,
    children
}: Props): ReactElement {
    return (
        <div className={styles.Root}>
            <div className={styles.box}>
                <TransferPassengerBox
                    childrenCount={childrenCount || 0}
                    pax={pax}
                    adultsCount={adultsCount || 0}
                    leadPassenger={leadPassenger}
                    withBox={false}
                    dotStyle={true}
                >
                    <div>{transferName}</div>
                </TransferPassengerBox>
            </div>

            {(specificLocation.specificLocationAddress || specificLocation.specificLocationName) && (
                <SpecificLocationBox
                    specificLocationName={specificLocation.specificLocationName}
                    specificLocationAddress={specificLocation.specificLocationAddress}
                />
            )}

            {children}
        </div>
    );
}

export default TransferInfo;
