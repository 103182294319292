import React, {ReactElement} from "react";
import {useTranslation} from "react-i18next";
import NotFoundImage from "assets/images/NotFound.png";
import HeaderContainer from "../../components/base/Header/HeaderContainer";
import Footer from "../../components/base/Footer";
import Lefter from "../../components/base/Lefter/LefterContainer";
import Righter from "../../components/base/Righter";
import PageTemplate from "../../components/base/PageTemplate/PageTemplate";
import ErrorBoundary from "../../components/utils/ErrorBoundary/ErrorBoundary";
import UserButton from "../../components/base/UserButton";
import {UserButtonVariant} from "../../components/base/UserButton/UserButton";
import styles from "./NotFound.module.scss";
import {useAppSelector} from "../../redux/hooks"
import LoginNavBar from "../Login/LoginNavBar/LoginNavBar"
import CookieConsentContainer from "../../components/utils/CookieConsentContainer/CookieConsentContainer"

const NotFound = (): ReactElement => {
    const {t} = useTranslation();
    const authenticated = useAppSelector((state) => !!state.auth.userData);

    const mainContent = (
        <ErrorBoundary>
            <div className={styles.NotFoundContainer}>
                <img src={NotFoundImage} alt="Not found" />

                <div className={styles.NotFoundDescription}>
                    <div className={styles.NotFoundDescriptionTitle}>{t("n_f_text_title")}</div>

                    <div className={styles.NotFoundDescriptionText}>{t("n_f_text")}</div>

                    <UserButton
                        buttonProps={{
                            onClick: () => {
                                window.location.href = window.location.origin;
                            }
                        }}
                        text={t("n_f_button_text")}
                        variant={UserButtonVariant.PRIMARY}
                        className={styles.NotFoundDescriptionButton}
                    />
                </div>
            </div>
        </ErrorBoundary>
    );

    if (!authenticated) {
        return (
            <PageTemplate>
                <LoginNavBar />

                {mainContent}

                <CookieConsentContainer />
            </PageTemplate>
        )
    }

    return (
        <PageTemplate
            header={<HeaderContainer />}
            footer={<Footer />}
            lefter={<Lefter />}
            righter={<Righter />}
        >
            {mainContent}
        </PageTemplate>
    );
};

export default NotFound;