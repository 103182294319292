import React, {ReactElement} from "react";
import "./CustomRadioButton.scss";
import cx from "classnames";

type Props = React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> & {
    label?: string;
};

function CustomRadioButton(props: Props): ReactElement {
    const {className, id, label} = props;

    return (
        <div className={cx("CustomRadioButtonContainer")}>
        <div className={cx("CustomRadioButton", className)}>
            <input {...props} className="radio" type="radio" />
            <span className="custom-radio" />
        </div>
            {label && <label htmlFor={id}>{label}</label>}
        </div>
    );
}

export default CustomRadioButton;
