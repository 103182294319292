import React, {
    ReactElement, useCallback, useEffect, useImperativeHandle, useState
} from "react";
import {useTranslation} from "react-i18next";
import Modal from "../../common/Modal";
import ModalWithOverlay from "../../common/Modal/ModalWithOverlay";
import Fade from "../../utils/Transitions/Fade";
import styles from "./UserModal.module.scss";
import {ReactComponent as CrossIcon} from "../../../assets/icons/cross.svg";

export type UserModalInterface = ReactElement & {
    show: () => void;
    hide: () => void;
    fade: (visible?: boolean) => void;
};

export type UserModalProps = {
    fadeDuration?: number;

    closeable?: boolean;
    onClose: () => void;

    header?: ReactElement;
    children: ReactElement | ReactElement[];
};

const UserModal = (props: UserModalProps, ref: React.Ref<ReactElement>): ReactElement => {
    const {
        fadeDuration = 300,
        closeable = true,
        onClose,
        header,
        children
    } = props;

    const [fade, setFade] = useState<boolean>();

    const openCallback = useCallback(() => {
        setFade(true);
    }, [setFade]);

    const closeCallback = useCallback(() => {
        setFade(false);

        setTimeout(() => onClose && onClose(), fadeDuration);
    }, [fadeDuration, onClose]);

    useEffect(() => {
        setFade(true);
    }, []);

    useImperativeHandle(ref, () => ({
        show: () => {
            openCallback();
        },
        hide: () => {
            closeCallback();
        },
        fade: (visible?: boolean) => {
            setFade(visible || false);
        },
        props,
        type: typeof UserModal,
        key: ""
    }));

    return (
        <Modal>
            <Fade inProp={fade} duration={fadeDuration}>
                <ModalWithOverlay>
                    <div className={styles.Root}>
                        {closeable && <CrossIcon className={styles.CloseIcon} onClick={closeCallback} />}

                        <div className={styles.Header}>
                            {header}
                        </div>

                        {children}

                        <div className={styles.Footer} />
                    </div>
                </ModalWithOverlay>
            </Fade>
        </Modal>
    );
};

export default React.forwardRef<UserModalInterface, UserModalProps>(UserModal);