import React, {useCallback, useEffect, useState} from "react";
import {withTranslation} from "react-i18next";
import {connect} from "react-redux";
import cx from "classnames";
import Tippy from "../../../../../../components/common/Tippy/Tippy";
import styles from "./SearchedItem.module.scss";
import personIcon from "../../../../../../assets/icons/person.svg";
import keyIcon from "../../../../../../assets/icons/key.svg";
import {deleteRecentSearch} from "../../../../../../redux/actions/hotelSearch.actions";
import {ReactComponent as DeleteIcon} from "../../../../../../assets/icons/green-cross.svg";
import useLocaleDateTimeFormat, {
    LocaleDateFormatTypeEnum
} from "../../../../../../components/utils/Internationalization/useLocaleDateTimeFormat";
import createLuxonDate from "../../../../../../utils/date/createLuxonDate"

type Props = {
    id: number;
    checkIn: string | number;
    checkOut: string | number;
    location: string;
    destinationName: string;
    rooms: number;
    paxs: number;
    onClick: () => void;
    t: (...args: unknown[]) => string;

    deleteRecentSearch: (searchId: number) => void;
};

function SearchedItem(props: Props) {
    const {
        id,
        checkIn,
        checkOut,
        location,
        destinationName,
        rooms,
        paxs,
        onClick,
        t,

        deleteRecentSearch
    } = props;

    const [deleting, setDeleting] = useState(false);
    const dateFormat = useLocaleDateTimeFormat(LocaleDateFormatTypeEnum.DATE);

    const deleteCallback = useCallback(() => {
        setDeleting(true);
        deleteRecentSearch(id);
    }, [deleteRecentSearch, id]);

    useEffect(() => setDeleting(false), [id]);

    return (
        <div className={cx(styles.SearchedItemContainer, deleting && styles.SearchedItemDeleting)}>
            <Tippy content={(<span>{t("h_s_sei_tooltip")}</span>)}>
                <div onClick={() => onClick()} className={styles.SearchedItems}>
                    {destinationName && (<div className={styles.Location}>{destinationName}</div>)}
                    {location && (<div className={styles.Location}>{location}</div>)}
                    {/*{!location && <div className={cx(styles.FakeData)} />}*/}
                    {/*{!destinationName && <div className={cx(styles.FakeData)} />}*/}

                    <div className={styles.DateDetails}>
                        <span>{createLuxonDate(checkIn).toFormat(dateFormat)}</span>

                        <span>{createLuxonDate(checkOut).toFormat(dateFormat)}</span>
                    </div>

                    <div className={styles.Details}>
                        <div className={styles.Rooms}>
                            <img
                                src={keyIcon}
                                width="10px"
                                height="15px"
                                style={{marginRight: 5}}
                                alt=""
                            />

                            <p>{rooms} {t("h_s_sei_room", {count: rooms})}</p>
                        </div>

                        <div className={styles.Pax}>
                            <img
                                src={personIcon}
                                style={{marginRight: 5}}
                                alt=""
                            />

                            <p>{paxs} {t("h_s_sei_pax")}</p>
                        </div>
                    </div>
                </div>
            </Tippy>

            <div className={styles.DeleteIcon} onClick={deleteCallback}>
                <DeleteIcon />
            </div>
        </div>
    );
}

export default connect(undefined, {deleteRecentSearch})(withTranslation()(SearchedItem));
