import React, {ReactElement, useCallback, useMemo, useState} from "react"
import {useAppSelector} from "redux/hooks";
import {useTranslation} from "react-i18next";
import {CompanyUserPropTypes, CompanyUserWithPassword} from "proptypes/PropTypeObjects";
import LineWithAction from "components/common/LineWithAction/LineWithAction";
import SuccessModal from "components/common/ConfirmationModal/SuccessModal";
import {useCreateUserAction} from "components/utils/useCompanyUsers";
import UserDetailsModal from "components/common/UserDetailsModal";

const initialCompanyUserData = {
    admin: true,
    email: "",
    firstname: "",
    lastname: "",
    password: "",
    phone: "",
    title: "MR"
} as CompanyUserWithPassword;

type Props = {
    addUser: (companyUser: CompanyUserPropTypes) => void;
    className?: string;
};

function AddUser({className, addUser}: Props): ReactElement {
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [successModal, setSuccessModal] = useState(false);
    const isAdmin = useAppSelector((state) => state.auth.userData?.companyUser.admin);
    const {t} = useTranslation();
    const onSuccess = useCallback((user: CompanyUserPropTypes) => {
        setModalIsOpen(false);
        setSuccessModal(true);
        addUser(user);
    }, [addUser]);
    const {
        requesting,
        errors,
        createUser: createUserAction,
        clearState
    } = useCreateUserAction(onSuccess);

    const title = useMemo(() => requesting ? t("mc_au_adding_new_group_member") : t("mc_au_add_new_group_member"), [t, requesting]);

    return (
        <>
            {modalIsOpen && (
                <UserDetailsModal
                    onConfirm={(companyUser) => {
                        createUserAction(companyUser);
                    }}
                    onDecline={() => {
                        setModalIsOpen(false);
                    }}
                    title={title}
                    errors={errors}
                    spinner={requesting}
                    companyUser={initialCompanyUserData}
                    isNewUser={true}
                    temporaryPassword={true}
                    canMakeAdmin={isAdmin}
                />
            )}
            {successModal && (
                <SuccessModal
                    onClose={() => setSuccessModal(false)}
                    successMessage={t("mc_au_success_message_create_user")}
                />
            )}
            <LineWithAction
                label={t("mc_au_add_new_group_member")}
                onClick={() => {
                    clearState();
                    setModalIsOpen(true);
                }}
            />
        </>
    );
}

export default AddUser;
