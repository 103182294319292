import {useTranslation} from "react-i18next";
import React, {ReactElement} from "react";
import {Document} from "../../../../../proptypes/PropTypeObjects";
import useLocaleDateTimeFormat, {LocaleDateFormatTypeEnum} from "../../../../../components/utils/Internationalization/useLocaleDateTimeFormat";
import styles from "./AgreementGeneralInfo.module.scss";
import createLuxonDate from "../../../../../utils/date/createLuxonDate"

export default function AgreementGeneralInfo({statusText, nameText, documentDate}: Document & { statusText: string }): ReactElement {
    const {t} = useTranslation();
    const dateFormat = useLocaleDateTimeFormat(LocaleDateFormatTypeEnum.DATE);

    return (
        <div className={styles.Root}>
            <h4 className={styles.Heading}>{t("mc_a_a_general_info")}</h4>
            <dl className={styles.Box}>
                <dt>{t("mc_a_a_name")}:</dt>
                <dd>{nameText}</dd>

                <dt>{t("mc_a_a_status")}:</dt>
                <dd>{statusText}</dd>

                <dt>{t("mc_a_a_agreement_date")}:</dt>
                <dd>{createLuxonDate(documentDate).toFormat(dateFormat)}</dd>
            </dl>
        </div>
    );
}