import React, {
    ReactElement, ReactNode
} from "react";
import cx from "classnames";
import styles from "./HotelOffers.module.scss";
import Stars from "../../Stars";
import {HotelFeatureGroupPropTypes, HotelPropTypes} from "../../../../proptypes/PropTypeObjects";
import getHotelAddress from "../../../../utils/getHotelAddress";
import ImagesHeader from "./ImagesHeader/ImagesHeader";
import {LazyImageSlider, LazyImageSliderImage} from "../../LazyImageSlider/LazyImageSlider";

function createMarkup(content: any) {
    return {__html: content};
}

type Props = {
    children: ReactNode;
    hotel: HotelPropTypes;
    onlyOne: boolean;
};

function HotelOffers({
    children,
    hotel,
    onlyOne = false
}: Props): ReactElement {
    const {
        id,
        name,
        starRating,
        description,
        images,
        featureGroups,
        address,
        distances
    } = hotel;

    const features = featureGroups ? (featureGroups).map((e: HotelFeatureGroupPropTypes) => e.hotelFeatures).flat() : [];
    return (
        <div
            className={cx(
                styles.Root,
                onlyOne && styles.OnlyOne
            )}
        >
            <div className={styles.ImageDescriptionContainer}>
                {onlyOne ? (
                    <ImagesHeader
                        images={images}
                        link={`/hotels/hotel/${id}`}
                    />
                ) : (
                    <LazyImageSlider
                        className={styles.ImageSlider}
                        showFullscreenButton={false}
                        showImageMessage={false}
                        showThumbnails={false}
                        link={{
                            pathname: `/hotels/hotel/${id}`,
                            search: ""
                        }}
                        images={(images && images.map((image) => ({
                            original: image
                        } as LazyImageSliderImage))) || []}
                    />
                )}

                <div className={styles.Description}>
                    <h2 className={styles.Title}>{name}</h2>

                    <div className={styles.Reviews}>
                        <Stars starRating={starRating} />
                    </div>

                    <div className={styles.Address}>
                        {getHotelAddress(address)}
                    </div>

                    <ul>
                        {distances && distances[0] && (<li>{distances[0].value} {distances[0].name}</li>)}
                        {features && features[0] && <li>{features[0].name}</li>}
                        {distances && distances[1] && (<li>{distances[1].value} {distances[1].name}</li>)}
                        {features && features[1] && <li>{features[1].name}</li>}
                    </ul>
                </div>
            </div>

            {onlyOne && (
                <div
                    className={styles.Content}
                    dangerouslySetInnerHTML={
                        createMarkup(
                            `<p>
                                    ${description}
                                </p>
                                `
                        )
                    }
                />
            )}

            {children}
        </div>
    );
}

export default HotelOffers;
