import {ReduxMultiPaymentPropTypes} from "../../proptypes/PropTypeRedux";
import MultiPaymentTypes from "../constants/multiPayment.constants";
import MultiPaymentActionProps from "./actions/multiPayment.reducer.actions";

const INITIAL_STATE = {
    agreeTerms: false,
    activeOrders: [],
    bookingIds: [],
    requestingOptions: true,
    requestingPay: false,
    allowedInPaymentPages: false,
    multipleOrders: false,
    error: undefined,
    route: undefined
} as ReduxMultiPaymentPropTypes;

export default (state = INITIAL_STATE, action: MultiPaymentActionProps): ReduxMultiPaymentPropTypes => {
    switch (action.type) {
    case MultiPaymentTypes.TOGGLE_MULTIPLE_ORDERS:
        return {
            ...state,
            multipleOrders: !state.multipleOrders,
            activeOrders: []
        };
    case MultiPaymentTypes.ADD_ACTIVE_ORDER:
        return {
            ...state,
            activeOrders: [...state.activeOrders, action.activeOrder]
        };
    case MultiPaymentTypes.REMOVE_ACTIVE_ORDER:
        return {
            ...state,
            activeOrders: state.activeOrders
                .filter((value) => value.id !== action.activeOrder.id)
        };
    case MultiPaymentTypes.CLEAR_MULTI_PAYMENT_DATA:
        return INITIAL_STATE;
    case MultiPaymentTypes.TOGGLE_AGREE_TERMS:
        return {
            ...state,
            agreeTerms: !state.agreeTerms
        };
    default:
        return state;
    }
};