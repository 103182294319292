import React, {ChangeEvent, ReactElement} from "react";
import BasicDropdown from "components/base/Input/BasicDropdown/BasicDropdown";
import Input from "components/base/Input";
import {useTranslation} from "react-i18next";
import ChildrenDropdown from "components/common/Roomate/ChildrenDropdown";
import titles from "assets/json/titles.json";
import styles from "./PassengerInputs.module.scss";
import {validateName} from "../../../utils/validateForm";
import {Option} from "../../../proptypes/PropTypeObjects";

const adultNumbers = [
    {
        value: 1,
        label: "1"
    },
    {
        value: 2,
        label: "2"
    },
    {
        value: 3,
        label: "3"
    },
    {
        value: 4,
        label: "4"
    },
    {
        value: 5,
        label: "5"
    },
    {
        value: 6,
        label: "6"
    },
    {
        value: 7,
        label: "7"
    },
    {
        value: 8,
        label: "8"
    },
    {
        value: 9,
        label: "9"
    },
    {
        value: 10,
        label: "10"
    }
] as Option<number, string>[];

const childrenNumbers = [{
    value: 0,
    label: "0"
}, ...adultNumbers];

type LeadPassengerInputsProps = {
    handleTitle: (value: string) => void;
    handleInput: (value: ChangeEvent<HTMLInputElement>) => void;
    title: string;
    name: string;
    surname: string;
};

export function LeadPassengerInputs({
    handleTitle,
    handleInput,
    title,
    name,
    surname
}: LeadPassengerInputsProps): ReactElement {
    const {t} = useTranslation();

    return (
        <div className={styles.Root}>
            <div className={styles.InputContainer}>
                <BasicDropdown<string, string>
                    required={true}
                    style={{
                        marginRight: 10
                    }}
                    dropDownOptions={titles.titles.map(({
                        label,
                        value
                    }) => ({
                        value,
                        label: t(label)
                    }))}
                    className={styles.Dropdown}
                    onDropdownChange={(value) => handleTitle(value)}
                    dropDownValue={title}
                />

                <Input
                    variants={{labelPosition: "outlined"}}
                    fullHeight={false}
                    validator={validateName}
                    className={styles.Input}
                    inputProps={{
                        required: true,
                        name: "name",
                        value: name || "",
                        onChange: handleInput,
                        placeholder: t("mb_ta_pi_first_name")
                    }}
                />
            </div>

            <div className={styles.InputContainer}>
                <Input
                    variants={{labelPosition: "outlined"}}
                    fullHeight={false}
                    validator={validateName}
                    className={styles.Input}
                    inputProps={{
                        required: true,
                        name: "surname",
                        value: surname || "",
                        onChange: handleInput,
                        placeholder: t("mb_ta_pi_last_name")
                    }}
                />
            </div>
        </div>
    );
}
type ChildrenInputProps = {
    handleChildren: (ages: number[]) => void;
    childrenVal?: number[];
};

export function ChildrenInput({
    handleChildren,
    childrenVal
}: ChildrenInputProps): ReactElement {
    const {t} = useTranslation();

    const childrenAges = Array(15)
        // @ts-expect-error ts-migrate(2554) FIXME: Expected 1-3 arguments, but got 0.
        .fill()
        .map((_, i) => ({
            value: i,
            label: t("mb_ta_pi_years_old", {
                count: i,
                age: i
            })
        })) as Option<number, string>[];

    return (
        <ChildrenDropdown
            handleChildren={handleChildren}
            dropDownValues={childrenVal || []}
            numbers={childrenNumbers}
            childrenAges={childrenAges}
        />
    );
}

type Props = {
    handleAdults: (value: number, label: string) => void;
    adults?: number;
};

export function AdultsInput({
    handleAdults,
    adults
}: Props): ReactElement {
    return (
        <BasicDropdown<number, string>
            required={true}
            onDropdownChange={handleAdults}
            className={styles.adults}
            dropDownValue={adults}
            dropDownOptions={adultNumbers}
        />
    );
}
