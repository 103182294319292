import React, {ReactElement, useCallback} from "react";
import {useTranslation} from "react-i18next";
import {connect} from "react-redux";
import styles from "../DetailedView.module.scss";
import {HotelOfferPropTypes} from "../../../../../proptypes/PropTypeObjects";
import HotelBox from "../HotelBox/HotelBox";
import PageControl from "../../../../../components/common/PageControl";
import OneActiveSubject from "../../../../../utils/generic/oneActiveSubject";
import {RootState} from "../../../../../redux/store/store.init";
import {clearFilters} from "../../../../../redux/actions/hotelSearch.base.actions";

export type HotelOffersContainerProps = {
    hotelOffers: HotelOfferPropTypes[];
    fullWidthMap: boolean;
    clearFilters: () => void;
    isActivePinnedOffers: boolean;
    markersSubject: OneActiveSubject<void, number>,

    handlePageSizeChange: (size: number) => void;
    pageSizeOptions: { value: number, label: number }[];
    handleChangePage: (page: number) => void;
    pageNumber: number;
    pageCount: number;
    size: number;
};

const HotelOffersContainer = ({
    hotelOffers,
    fullWidthMap,
    isActivePinnedOffers,
    markersSubject,

    handlePageSizeChange,
    pageSizeOptions,
    handleChangePage,
    pageNumber,
    pageCount,
    size,
    clearFilters
}: HotelOffersContainerProps): ReactElement => {
    const {t} = useTranslation();

    const clearFiltersCallback = useCallback(() => clearFilters(), [clearFilters]);
    const goBackToSearchCallback = useCallback(() => {
        window.location.href = "/hotels";
    }, []);

    return (
        <div className={styles.HotelOffersContainer}>
            {
                hotelOffers.map((hotel: HotelOfferPropTypes) => (
                    <HotelBox
                        fullWidthMap={fullWidthMap}
                        key={hotel.hotel.id}
                        hotel={hotel.hotel}
                        roomOffers={hotel.roomOffers}
                        isActivePinnedOffers={isActivePinnedOffers}
                        markersSubject={markersSubject}
                    />
                ))
            }

            {
                (!hotelOffers || hotelOffers.length === 0) && (
                    <div className={styles.NoOffersDescriptionContainer}>
                        <span>{t("h_l_no_results_description")}</span>

                        <a onClick={clearFiltersCallback}>
                            {t("h_l_reset_filters")}
                        </a>

                        <a onClick={goBackToSearchCallback}>
                            {t("h_l_go_back_to_search")}
                        </a>
                    </div>
                )
            }

            <PageControl
                className={styles.PagingControl}
                handleChangePage={handleChangePage}
                pagesCount={pageCount}
                pageNumber={pageNumber}
                size={size}
                pageSizeOptions={pageSizeOptions}
                handlePageSizeChange={handlePageSizeChange}
                reverse
            />
        </div>
    );
};

const mapStateToProps = (state: RootState) => ({
    fullWidthMap: state.map.fullWidthMap,
    modal: state.modals.currentActiveModal
});

const connector = connect(mapStateToProps, {
    clearFilters
});

export default React.memo(connector(HotelOffersContainer));