import {SimpleHotelOfferPropTypes} from "proptypes/PropTypeObjects";

export function compareNumbers(a: number, b: number): number {
    if (a === b) {
        return 0;
    }

    if (a > b) {
        return 1;
    }

    return -1;
}

export function localeCompare(a: string, b: string): number {
    return a.localeCompare(b);
}

export function sortHotelPrice(
    hotelA: SimpleHotelOfferPropTypes,
    hotelB: SimpleHotelOfferPropTypes
): number {
    const minPrice = (hotel: SimpleHotelOfferPropTypes) => {
        const pricesArr: number[] = [];
        const roomsOffers = hotel.roomOffers;
        for (let i = 0; i < roomsOffers.length; i++) {
            const roomPrices = roomsOffers[i]
                .filter((room) => room.matchesFilters === undefined || room.matchesFilters)
                .map((room) => room.price);

            pricesArr.push(...roomPrices);
        }

        return Math.min(...pricesArr);
    };

    return compareNumbers(minPrice(hotelA), minPrice(hotelB));
}

// export function sortHotelRoomOffersPrices(
//     hotels: SimpleHotelOfferPropTypes[]
// ): SimpleHotelOfferPropTypes[] {
//     return hotels.map(hotel => ({
//         ...hotel,
//         roomOffers: hotel.roomOffers.map(roomOffer => roomOffer.sort((a, b) => a.price - b.price))
//     })).sort((a, b) => a.roomOffers[0][0].price - b.roomOffers[0][0].price);
// }

export function sortHotelStars(hotelA: SimpleHotelOfferPropTypes, hotelB: SimpleHotelOfferPropTypes): number {
    return compareNumbers(hotelA.hotel.starRating, hotelB.hotel.starRating);
}

export function sortHotelName(hotelA: SimpleHotelOfferPropTypes, hotelB: SimpleHotelOfferPropTypes): number {
    return localeCompare(hotelA.hotel.name, hotelB.hotel.name);
}
