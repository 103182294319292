import * as rdd from "react-device-detect";

export enum DetectedBrowserType {
    CHROME = "CHROME",
    FIREFOX = "FIREFOX",
    SAFARI = "SAFARI",
    EDGE = "EDGE",
    OPERA = "OPERA",
    GENERIC = "GENERIC"
}

export default function detectBrowserType(): DetectedBrowserType {
    if (rdd.isFirefox) {
        return DetectedBrowserType.FIREFOX;
    }

    if (rdd.isChrome || rdd.isChromium || rdd.isEdgeChromium) {
        return DetectedBrowserType.CHROME;
    }

    if (rdd.isSafari || rdd.isMobileSafari) {
        return DetectedBrowserType.SAFARI;
    }

    if (rdd.isEdge) {
        return DetectedBrowserType.EDGE;
    }

    if (rdd.isOpera) {
        return DetectedBrowserType.OPERA;
    }

    return DetectedBrowserType.GENERIC;
}