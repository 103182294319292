import React, {ReactElement} from "react";
import styles from "./AppUnavailable.module.scss";


const AppNoInternet = (): ReactElement => (
    <div className={styles.Root}>
        <div className={styles.LogoDescriptionContainer}>
            <div className={styles.Logo}>
                !
            </div>
            <div className={styles.Description}>
                No internet connection. Please check your network.
            </div>
        </div>

    </div>
);

export default AppNoInternet;