import React, {ReactElement} from "react"
import {useTranslation} from "react-i18next"
import {useAppSelector} from "../../../redux/hooks"
import styles from "./PaymentFailure.module.scss"
import MainContainer from "../../../components/base/MainContainer"
import useLocaleDateTimeFormat, {
    LocaleDateFormatTypeEnum
} from "../../../components/utils/Internationalization/useLocaleDateTimeFormat"
import PageStatus from "../../../components/common/PageStatus"
import Spinner from "../../../components/base/Loaders/Spinner"
import UserButton from "../../../components/base/UserButton"
import {UserButtonVariant} from "../../../components/base/UserButton/UserButton"

const PaymentFailure = (): ReactElement => {
    const {t} = useTranslation();

    return (
        <div
            className={styles.Root}
        >
            <MainContainer className={styles.MainContainer}>
                <PageStatus
                    title={t("pc_pc_pl_payment_failed_header")}
                    content={t("pc_pc_pl_payment_failed")}
                    withActionButton={false}
                    status="error"
                    className={styles.FailureMessage}
                />
            </MainContainer>
        </div>
    );
}

export default PaymentFailure;