export type OneActiveSubjectObserver<T> = {
    onPayload: (payload: T | null) => void;
    onClose: () => void;
};

export default class OneActiveSubject<T, K> {
    private currentlyActive: K | undefined = undefined;

    private observers: Map<K, OneActiveSubjectObserver<T>> = new Map<K, OneActiveSubjectObserver<T>>();

    private globalObservers: OneActiveSubjectObserver<K>[] = [];

    hasActive(): boolean {
        return !!this.currentlyActive;
    }

    getActive(): K | undefined {
        return this.currentlyActive;
    }

    next(payload: T | null, newActive: K): void {
        if (this.currentlyActive === newActive) {
            return;
        }

        if (this.currentlyActive) {
            this.observers.get(this.currentlyActive)?.onClose();
        }

        this.currentlyActive = newActive;
        this.observers.get(this.currentlyActive)?.onPayload(payload);

        this.globalObservers.forEach((observer) => observer.onPayload(newActive));
    }

    subscribe(key: K, observer: OneActiveSubjectObserver<T>): void {
        this.observers.set(key, observer);
    }

    subscribeGlobal(observer: OneActiveSubjectObserver<K>): void {
        this.globalObservers.push(observer);
    }

    unsubscribe(key: K): void {
        this.observers.delete(key);
    }

    unsubscribeGlobal(observer: OneActiveSubjectObserver<K>): void {
        if (this.globalObservers.find((existingConsumer) => existingConsumer === observer)) {
            this.globalObservers.splice(this.globalObservers.indexOf(observer), 1);
        }
    }
}