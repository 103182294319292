import React, {ReactElement} from "react";
import {useTranslation} from "react-i18next";
import AgreeTermsModal, {AgreeTermsModalSharedProps} from "../AgreeTermsModal";
import {ExportedDocumentTypeEnum} from "../../../../api/companyDocumentsAPI";

export default function AgreeTermsAndConditionsModal({
    onConfirm = () => {},
    onClose
}: AgreeTermsModalSharedProps): ReactElement {
    const {t} = useTranslation();

    return (
        <AgreeTermsModal
            onConfirm={onConfirm}
            onClose={onClose}
            title={t("tc_tc_terms_and_conditions_title")}
            content={t("tc_tc_terms_and_conditions_content")}
            exportedFileType={ExportedDocumentTypeEnum.TERMS_AND_CONDITIONS}
        />
    );
}
