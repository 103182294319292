import React, {ReactElement, useEffect} from "react"
import {useParams} from "react-router-dom"
import LoginNavBar from "../../Login/LoginNavBar/LoginNavBar"
import CookieConsentContainer from "../../../components/utils/CookieConsentContainer/CookieConsentContainer"
import Payment from "./Payment"
import {useAppDispatch, useAppSelector} from "redux/hooks"
import {getAnonymousPaymentOptions, requestPaymentOptions} from "redux/actions/payment.actions"
import PaymentProviderRedirect from "../../../components/common/PaymentProviderRedirect/PaymentProviderRedirect"
import RedirectingModal from "../../../components/common/Modal/RedirectingModal"
import usePaymentType from "components/utils/usePaymentType";
import {ReduxPaymentTypePropTypes} from "proptypes/PropTypeRedux";

const PaymentContainer = (): ReactElement => {
    const {token} = useParams();

    const dispatch = useAppDispatch();
    let providerPayment = useAppSelector((state) => state.payment.providerPayment);

    useEffect(() => {
        if (!token) {
            return;
        }

        dispatch(requestPaymentOptions());
        dispatch(getAnonymousPaymentOptions(token));
    }, [dispatch, token]);

    usePaymentType(ReduxPaymentTypePropTypes.ANONYMOUS_MULTIBOOKING);

    return (
        <>
            <LoginNavBar/>

            <>
                {!!providerPayment && (
                    <PaymentProviderRedirect {...providerPayment}>
                        <RedirectingModal/>
                    </PaymentProviderRedirect>
                )}
            </>

            <Payment
                token={token}
            />

            <CookieConsentContainer/>
        </>
    );
}

export default PaymentContainer;