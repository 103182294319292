import IAppActions from "../store/store.actions"
import PaymentTypes from "../constants/payment.constants"
import {CommonActionReturnType} from "../store/store.init"
import {
    BookingOptionsPropTypes,
    CustomVoucherPropTypes,
    HotelBookingContent,
    InvoiceRecipientPropTypes,
    MultiPaymentBookingOptionsPropTypes,
    PaymentTransferTypePropTypes,
    PaymentVoucherPropTypes,
    SimpleBookingPropTypes,
    TransferBookingContent,
    WebBankLinkOptionPropTypes,
    WebMultiPaymentOptions,
    WebMultiPaymentRequest,
    WebPaymentOptions,
    WebPaymentPromotion,
    WebPaymentProvider,
    WebProviderPaymentPropTypes,
    WebsocketSplitPricePaymentPartNotification,
    WebSplitPriceOptionsResponse,
    WebSplitPricePart,
    WebSplitPricePayPartRequest,
    WebSplitPricePayPartResponse,
    WebSplitPriceValidateSessionRequest,
    WebSplitPriceValidateSessionResponse
} from "proptypes/PropTypeObjects"
import {customPaymentTypeFilter} from "../services/payment.services"
import {ReduxDiscountCode, ReduxPaymentTypePropTypes} from "proptypes/PropTypeRedux"
import {
    getPaymentLinkPaymentOptions,
    getSplitPriceOptions,
    payMultiBookingNonAuth,
    paySplitPricePart,
    validateSplitPriceSession
} from "api/multiPaymentAPI"
import {setDefaults} from "./auth.actions"
import {calculateTransactionFee, setLastRequestedPrice, setTransactionFee} from "./pricing.actions"
import {globalAxiosController} from "api/axios/globalAxiosController"
import {checkDiscountCodeValidity} from "api/pricingAPI"
import router from "views/router/router"
import {makeHotelBookingRequest} from "redux/actions/hotelBooking.actions";
import {makeTransferBookingRequest} from "redux/actions/transferBooking.actions";
import {paymentPendingSelector} from "../selectors/payment.selector";

export const requestPaymentOptions = (): IAppActions => ({
    type: PaymentTypes.REQ_OPTIONS
})

export const requestPaymentOptionsSuccess = (): IAppActions => ({
    type: PaymentTypes.REQ_OPTIONS_SUCCESS
})

export const requestPaymentOptionsFailure = (anonymousOptionsFailure?: boolean): IAppActions => ({
    type: PaymentTypes.REQ_OPTIONS_FAILURE,
    anonymousOptionsFailure
})

export const requestPay = (): IAppActions => ({
    type: PaymentTypes.REQ_PAY
})

export const requestPaySuccess = (): IAppActions => ({
    type: PaymentTypes.REQ_PAY_SUCCESS
})

export const requestPayFailure = (): IAppActions => ({
    type: PaymentTypes.REQ_PAY_FAILURE
})

export const setPaymentType = (paymentType?: ReduxPaymentTypePropTypes): IAppActions => ({
    type: PaymentTypes.SET_PAYMENT_TYPE,
    paymentType
})

export const getAnonymousPaymentOptions = (token: string): CommonActionReturnType => (dispatch, getState) => {
    globalAxiosController.addRequest(getPaymentLinkPaymentOptions(token))
        .then((data) => {
            const activeOrders = data.relatedBookings

            dispatch(setDefaults({
                currentCurrency: data.currency,
                currentLocale: data.locale
            }))
            dispatch(setAnonymousActiveOrders(activeOrders))
            dispatch(
                setPaymentOptions({
                    bookingType: ReduxPaymentTypePropTypes.ANONYMOUS_MULTIBOOKING,
                    bookingOptions: {
                        ...data,
                        salePrice: data.paymentPending,
                        displaySalePrice: data.paymentPending
                    },
                    paymentPromotion: data.paymentPromotion,
                    showBillingDetails: false
                })
            )
            dispatch(setTransactionFee(data.creditCardFee, data.creditCardFee))
            dispatch(setLastRequestedPrice(data.paymentPending))
            dispatch(requestPaymentOptionsSuccess())
        }).catch(() => {
            dispatch(requestPaymentOptionsFailure(true))
        })
}

export const payNonAuth = (token: string): CommonActionReturnType => (dispatch, getState) => {
    const {
        anonymousActiveOrders,
        paymentTransferTypeEnum,
        selectedPaymentLink
    } = getState().payment

    const bookingIds = anonymousActiveOrders.map((order) => order.id)
    globalAxiosController.addRequest(
        payMultiBookingNonAuth(
            token,
            {
                bookingIds,
                paymentTransferTypeEnum,
                selectedPaymentLink,
                paymentVouchers: [],
                appliedDiscount: undefined,
                appliedWalletAmount: undefined
            } as WebMultiPaymentRequest
        )
    ).then((data) => {
        if (data.route === "validationFailure") {
            // dispatch(failure(data.errors))
            dispatch(requestPayFailure())
            return
        }

        if (data.providerPayment) {
            dispatch(setProviderPayment(data.providerPayment))
            return
        }

        // dispatch(success(data));
        dispatch(requestPaySuccess())
    }).catch((err) => {
        dispatch(requestPayFailure())
    })
}

export const parseHotelPaymentOptions = (
    // booking: HotelBookingContent | TransferBookingContent | WebPaymentOptions
    booking: HotelBookingContent
): CommonActionReturnType => (dispatch, getState) => {
    const bookingOptions = booking.bookingOptions
    const bookingInfo = booking.bookingInfo

    if (bookingOptions && bookingInfo) {
        dispatch(dispatch(updateVoucherData(bookingOptions.paymentVouchers)))
        dispatch(
            setPaymentOptions({
                bookingType: ReduxPaymentTypePropTypes.HOTEL,
                bookingOptions,
                showBillingDetails: bookingOptions.showBusinessCredentials,
                paymentPromotion: bookingOptions.paymentPromotion
            })
        )
    }
}

export const parseTransferPaymentOptions = (
    booking: TransferBookingContent
): CommonActionReturnType => (dispatch, getState) => {
    const bookingOptions = booking.bookingOptions
    const bookingInfo = booking.bookingInfo

    if (bookingOptions && bookingInfo) {
        dispatch(dispatch(updateVoucherData(bookingOptions.paymentVouchers)))
        dispatch(
            setPaymentOptions({
                bookingType: ReduxPaymentTypePropTypes.TRANSFER,
                bookingOptions,
                showBillingDetails: bookingOptions.showBusinessCredentials,
                paymentPromotion: bookingOptions.paymentPromotion
            })
        )
    }
}

export const parseMultipaymentPaymentOptions = (
    options: WebMultiPaymentOptions
): CommonActionReturnType => (dispatch, getState) => {
    const activeOrders = getState().multiPayment.activeOrders

    const salePrice = activeOrders.reduce((accumulator, obj) => obj.price + accumulator, 0)
    const paymentPending = activeOrders.reduce((accumulator, obj) => obj.paymentPending + accumulator, 0)

    dispatch(dispatch(updateVoucherData(options.paymentVouchers)))
    dispatch(
        setPaymentOptions({
            bookingType: ReduxPaymentTypePropTypes.MULTIBOOKING,
            bookingOptions: {
                ...options,
                salePrice,
                displaySalePrice: salePrice,
                paymentPending
            },
            paymentPromotion: options.paymentPromotion,
            showBillingDetails: false
        })
    )
}

export const setAnonymousActiveOrders = (orders: SimpleBookingPropTypes[]): CommonActionReturnType => (dispatch, getState) => {
    dispatch({
        type: PaymentTypes.SET_ANONYMOUS_ACTIVE_ORDERS,
        orders
    })
}

export const setPaymentOptions = (
    data: {
        bookingType: ReduxPaymentTypePropTypes,
        bookingOptions: BookingOptionsPropTypes | WebPaymentOptions | WebMultiPaymentOptions,
        showBillingDetails?: boolean,
        paymentPromotion?: WebPaymentPromotion
    }
): IAppActions => ({
    type: PaymentTypes.SET_PAYMENT_OPTIONS,
    bookingType: data.bookingType,
    bookingOptions: data.bookingOptions,
    showBillingDetails: data.showBillingDetails,
    paymentPromotion: data.paymentPromotion
})

export const setProviderPayment = (
    provider: WebProviderPaymentPropTypes
): CommonActionReturnType => (dispatch, getState) => {
    dispatch({
        type: PaymentTypes.SET_PROVIDER_PAYMENT,
        providerPayment: provider
    })
}

export const setPaymentTransferType = (transferType?: PaymentTransferTypePropTypes, paymentProvider?: WebPaymentProvider): IAppActions => ({
    type: PaymentTypes.HANDLE_PAYMENT_TRANSFER_TYPE,
    transferType,
    paymentProvider
})

export const setSelectedPaymentLink = (link?: WebBankLinkOptionPropTypes): IAppActions => ({
    type: PaymentTypes.HANDLE_SELECTED_PAYMENT_LINK,
    link
})

export const updateDiscountData = (
    discount: number
): IAppActions => ({
    type: PaymentTypes.UPDATE_DISCOUNT_DATA,
    discount
})

export const updateVoucherData = (
    vouchers: PaymentVoucherPropTypes[]
): IAppActions => ({
    type: PaymentTypes.UPDATE_VOUCHER_DATA,
    vouchers
})

export const updateVoucherSum = (
    voucherSum: { all: number, used: number }
): IAppActions => ({
    type: PaymentTypes.UPDATE_VOUCHER_SUM,
    voucherSum
})

export const updateCustomVoucher = (
    customVoucher: CustomVoucherPropTypes
): IAppActions => ({
    type: PaymentTypes.UPDATE_CUSTOM_VOUCHER,
    customVoucher
})

export function partialyPayWithWallet(currentWalletAmount: number, undo?: boolean): CommonActionReturnType {
    return (dispatch, getState) => {
        if (undo) {
            dispatch({
                type: PaymentTypes.WALLET_PARTIAL_PAY,
                amount: undefined
            })

            return
        }

        if (!currentWalletAmount) {
            return
        }

        dispatch({
            type: PaymentTypes.WALLET_PARTIAL_PAY,
            amount: currentWalletAmount
        })
    }
}

export const toggleInvoiceRecipient = (val?: boolean): IAppActions => ({
    type: PaymentTypes.TOGGLE_INVOICE_RECIPIENT,
    val
})

export const handleInvoiceRecipient = (recipient: InvoiceRecipientPropTypes): IAppActions => ({
    type: PaymentTypes.HANDLE_INVOICE_RECIPIENT,
    recipient
})

export const toggleBillingDetails = (
    val?: boolean
): IAppActions => ({
    type: PaymentTypes.TOGGLE_BILLING_DETAILS,
    val
})

export const toggleAgreeTerms = (
    val?: boolean
): IAppActions => ({
    type: PaymentTypes.TOGGLE_AGREE_TERMS,
    val
})

export const setPaymentLinkToken = (token?: string): IAppActions => ({
    type: PaymentTypes.SET_PAYMENT_LINK_TOKEN,
    token
})

export function processTransactionFeeUpdate(bookingOptions?: BookingOptionsPropTypes): CommonActionReturnType {
    return (dispatch, getState) => {
        if (!bookingOptions) {
            return
        }

        const paymentTransferTypeEnum = getState().payment.paymentTransferTypeEnum
        const selectedPaymentLink = getState().payment.selectedPaymentLink

        if (paymentTransferTypeEnum === PaymentTransferTypePropTypes.CREDIT_CARD || paymentTransferTypeEnum === PaymentTransferTypePropTypes.CREDIT_CARD_AFTER_CXL) {
            dispatch(setTransactionFee(bookingOptions.creditCardFee, bookingOptions.displayCreditCardFee))
        }

        if (paymentTransferTypeEnum === PaymentTransferTypePropTypes.BANK_LINK || paymentTransferTypeEnum === PaymentTransferTypePropTypes.BANK_LINK_AFTER_CXL) {
            const newBanklink = customPaymentTypeFilter(bookingOptions.bankLinks, selectedPaymentLink)
            dispatch(setTransactionFee(newBanklink?.fee || 0, newBanklink?.displayFee || 0))
        }

        if (paymentTransferTypeEnum === PaymentTransferTypePropTypes.MIP || paymentTransferTypeEnum === PaymentTransferTypePropTypes.MIP_AFTER_CXL) {
            const newMip = customPaymentTypeFilter(bookingOptions.mips, selectedPaymentLink)
            dispatch(setTransactionFee(newMip?.fee || 0, newMip?.displayFee || 0))
        }
    }
}

export const setDiscountCode = (discount?: ReduxDiscountCode): IAppActions => ({
    type: PaymentTypes.SET_DISCOUNT_CODE,
    discount
})

export function applyDiscountCode(discountCode: string, callback: (code?: ReduxDiscountCode) => void): CommonActionReturnType {
    return (dispatch, getState) => {
        if (!getState().payment.bookingOptions) {
            return
        }

        const code = discountCode
        const currency = getState().currency.currentCurrency
        const salePrice = getState().payment.bookingOptions?.salePrice

        if (!salePrice) {
            return
        }

        globalAxiosController.addRequest(checkDiscountCodeValidity(code, salePrice, currency))
            .then((response) => {
                const newDiscountCode = {
                    code,
                    valid: response.valid,
                    discountSum: response.discountSum
                }

                dispatch(setDiscountCode(newDiscountCode))
                callback(newDiscountCode)
            })
            .catch((err) => {
                dispatch(setDiscountCode({
                    code,
                    valid: false,
                    discountSum: undefined
                }))

                callback()
            })
    }
}

export const requestSplitPriceOptionsReq = (): IAppActions => ({
    type: PaymentTypes.SPLIT_PRICE_GET_OPTIONS_REQ
})

export const requestSplitPriceOptionsSuccess = (data: WebSplitPriceOptionsResponse): IAppActions => ({
    type: PaymentTypes.SPLIT_PRICE_GET_OPTIONS_SUCCESS,
    data
})

export const requestSplitPriceOptionsFailure = (): IAppActions => ({
    type: PaymentTypes.SPLIT_PRICE_GET_OPTIONS_FAILURE
})

export function requestSplitPriceOptions(): CommonActionReturnType {
    return (dispatch, getState) => {
        const activeOrders = getState().multiPayment.activeOrders;
        const locale = getState().locale.currentLocale;
        const maxPrice = getState().payment.splitPricePartSize;
        const paymentPending = paymentPendingSelector(getState().payment);
        const paymentTransferType = getState().payment.paymentTransferTypeEnum;
        const bankLinkOption = getState().payment.selectedPaymentLink;
        const paymentType = getState().payment.type;

        if (!maxPrice) {
            return
        }

        dispatch(requestSplitPriceOptionsReq())
        globalAxiosController.addRequest(getSplitPriceOptions(
            paymentTransferType,
            bankLinkOption,
            paymentType === ReduxPaymentTypePropTypes.MULTIBOOKING ? activeOrders.map((order) => order.id) : undefined,
            // activeOrders.map((order) => order.id),
            paymentPending,
            maxPrice,
            locale
        )).then((data) => {
            dispatch(requestSplitPriceOptionsSuccess(data))
        }).catch(() => {
            dispatch(requestSplitPriceOptionsFailure())

            // return new ExtendedAxiosError(err, true);
        })
    }
}

export function redirectPayInParts(paymentTransferType: PaymentTransferTypePropTypes): CommonActionReturnType {
    return (dispatch, getState) => {
        const paymentType = getState().payment.type;
        const activeOrders = getState().multiPayment.activeOrders;

        dispatch(setPaymentTransferType(paymentTransferType));
        switch (paymentType) {
        case ReduxPaymentTypePropTypes.HOTEL:
            router.navigate("/hotels/booking/split-payment")
            break
        case ReduxPaymentTypePropTypes.TRANSFER:
            router.navigate("/transfers/booking/split-payment")
            break
        case ReduxPaymentTypePropTypes.MULTIBOOKING:
            const existingPayment = (getState().payment.bookingOptions as MultiPaymentBookingOptionsPropTypes).existingSplitPricePayment;
            if (existingPayment) {
                router.navigate(`/payment/booking/split-payment/${existingPayment}`);
            } else if (activeOrders.length === 1) {
                router.navigate(`/my-bookings/booking/${activeOrders[0].id}/split-payment`)
            } else {
                router.navigate("/my-bookings/booking/split-payment")
            }

            break
        }
    }
}

export function handleSplitPricePartSizeInput(partSize: number): IAppActions {
    return ({
        type: PaymentTypes.HANDLE_SPLIT_PRICE_PART_SIZE_INPUT,
        partSize
    })
}

export function requestSplitPriceSessionValidationReq(): IAppActions {
    return ({
        type: PaymentTypes.SPLIT_PRICE_VALIDATE_SESSION_REQ
    })
}

export function requestSplitPriceSessionValidationSuccess(resp: WebSplitPriceValidateSessionResponse, sessionId: number): IAppActions {
    return ({
        type: PaymentTypes.SPLIT_PRICE_VALIDATE_SESSION_SUCCESS,
        data: resp,
        sessionId
    })
}

export function requestSplitPriceSessionValidationFailure(data?: WebSplitPriceValidateSessionResponse): IAppActions {
    return ({
        type: PaymentTypes.SPLIT_PRICE_VALIDATE_SESSION_FAILURE,
        data
    })
}

export function requestSplitPriceSessionValidation(
    sessionId?: number,
    partNo?: number,
    failure?: boolean
): CommonActionReturnType {
    return (dispatch, getState) => {
        const locale = getState().locale.currentLocale
        const currency = getState().currency.currentCurrency

        if (!sessionId) {
            return
        }

        dispatch(requestSplitPriceSessionValidationReq())
        globalAxiosController.addRequest(validateSplitPriceSession(
            {
                sessionId,
                partNo,
                failure
            } as WebSplitPriceValidateSessionRequest,
            locale,
            currency
        )).then((data) => {
            if (!data.valid) {
                dispatch(requestSplitPriceSessionValidationFailure(data))
                dispatch(requestPaymentOptionsFailure())

                return
            }

            dispatch(requestSplitPriceSessionValidationSuccess(data, sessionId))
            dispatch(requestPaymentOptionsSuccess())

            dispatch(
                setPaymentOptions({
                    bookingType: ReduxPaymentTypePropTypes.SPLIT_PRICE_PART_PAYMENT,
                    bookingOptions: data,
                    paymentPromotion: undefined,
                    showBillingDetails: false
                })
            )
        }).catch(() => {
            dispatch(requestSplitPriceSessionValidationFailure())
            dispatch(requestPaymentOptionsFailure())
        })
    }
}

export function requestSplitPricePayPartReq(): IAppActions {
    return ({
        type: PaymentTypes.SPLIT_PRICE_PAY_PART_REQ
    })
}

export function requestSplitPricePayPartSuccess(data: WebSplitPricePayPartResponse): IAppActions {
    return ({
        type: PaymentTypes.SPLIT_PRICE_PAY_PART_SUCCESS,
        data
    })
}

export function requestSplitPricePayPartFailure(): IAppActions {
    return ({
        type: PaymentTypes.SPLIT_PRICE_PAY_PART_FAILURE
    })
}

export function selectSplitPricePart(part?: WebSplitPricePart): CommonActionReturnType {
    return (dispatch) => {
        dispatch({
            type: PaymentTypes.SPLIT_PRICE_SELECT_PART_PAYMENT,
            data: part
        })

        // dispatch(setPaymentType(ReduxPaymentTypePropTypes.SPLIT_PRICE_PART_PAYMENT))
        dispatch(calculateTransactionFee());
        dispatch(requestSplitPricePayPart());
    }
}

export function requestSplitPricePayPart(): CommonActionReturnType {
    return (dispatch, getState) => {
        const type = getState().payment.type;
        const locale = getState().locale.currentLocale
        const currency = getState().currency.currentCurrency
        const selectedPaymentLink = getState().payment.selectedPaymentLink
        const paymentTransferType = getState().payment.paymentTransferTypeEnum
        const part = getState().payment.selectedSplitPricePart
        const sessionId = getState().payment.splitPriceSessionId
        const partSize = getState().payment.splitPricePartSize

        //        const paymentType = getState().payment.type
        let bookings = getState().payment.splitPriceBookings?.map((booking) => booking.id)
        if (!bookings) {
            bookings = getState().multiPayment.activeOrders?.map((order) => order.id)
        }

        if (!part) {
            return;
        }

        dispatch(requestSplitPricePayPartReq())
        if (type === ReduxPaymentTypePropTypes.HOTEL) {
            dispatch(makeHotelBookingRequest());
            return;
        } else if (type === ReduxPaymentTypePropTypes.TRANSFER) {
            dispatch(makeTransferBookingRequest());
            return;
        }

        dispatch(requestPay())
        globalAxiosController.addRequest(paySplitPricePart({
            bookings,
            sessionId,
            partSize,
            partNo: part.order,
            selectedPaymentLink,
            paymentTransferType
        } as WebSplitPricePayPartRequest, locale, currency)).then((data) => {
            dispatch(requestSplitPricePayPartSuccess(data))
            dispatch(requestPaySuccess())
        }).catch(() => {
            dispatch(requestSplitPricePayPartFailure())
            dispatch(requestPayFailure())
        })
    }
}

export function markSplitPricePartPaid(request: WebsocketSplitPricePaymentPartNotification): CommonActionReturnType {
    return (dispatch, getState) => {
        const parts = getState().payment.splitPriceParts;

        if (!parts || parts.length === 0) {
            return;
        }

        const foundPart = parts.filter(part => part.order === request.partNo && part.paymentOrderId === request.paymentOrder);
        if (foundPart && foundPart.length === 1) {
            dispatch({
                type: PaymentTypes.SPLIT_PRICE_PAY_PART_SUCCESS_NOTIFICATION,
                part: foundPart[0]
            });
        }
    }
}