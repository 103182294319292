import * as rdd from "react-device-detect";

export enum DetectedDeviceType {
    GENERIC_DESKTOP = "GENERIC_DESKTOP",
    GENERIC_TABLET = "GENERIC_TABLET",
    GENERIC_MOBILE = "GENERIC_MOBILE",
    IPHONE = "IPHONE",
    IPAD = "IPAD",
    MAC = "MAC",
    ANDROID_MOBILE = "ANDROID_MOBILE",
    ANDROID_TABLET = "ANDROID_TABLET"
}

export function detectDeviceType(): DetectedDeviceType {
    if (rdd.isMobile || rdd.isTablet) {
        if (rdd.osName === rdd.OsTypes.IOS) {
            return rdd.isTablet ? DetectedDeviceType.IPAD : DetectedDeviceType.IPHONE;
        } else if (rdd.osName === rdd.OsTypes.Android) {
            return rdd.isTablet ? DetectedDeviceType.ANDROID_TABLET : DetectedDeviceType.ANDROID_MOBILE;
        } else {
            return rdd.isTablet ? DetectedDeviceType.GENERIC_TABLET : DetectedDeviceType.GENERIC_MOBILE;
        }
    }

    if (rdd.osName === rdd.OsTypes.MAC_OS) {
        return DetectedDeviceType.MAC;
    }

    return DetectedDeviceType.GENERIC_DESKTOP;
}

export default detectDeviceType;