import React, {ReactElement} from "react";
import TimeCustomSelect from "components/base/Input/CustomReactSelect/TimeCustomSelect";
import cx from "classnames";
import {useTranslation} from "react-i18next";
import styles from "./SpecialRequestsForm.module.scss";
import {SpecialRequestsPropTypes} from "../../../proptypes/PropTypeObjects";
import CustomCheckBox from "../../base/Input/CustomCheckBox";

type Props = SpecialRequestsPropTypes & {
    handleSpecialRequests: (target: { target: {
        value: boolean | string,
        name: string
    } }) => void;
    handleLateArrival: (hoursMinutes: string) => void;
    toggleLateArrival: (...args: any[]) => any;
    children?: React.ReactNode;
};

function SpecialRequestsForm({
    handleSpecialRequests,
    handleLateArrival,
    toggleLateArrival,
    honeymoon,
    lateArrival,
    lateArrivalTime = "",
    nonSmoking,
    smoking,
    separateBeds,
    doubleBed,
    text,
    babyCot,
    children
}: Props): ReactElement {
    const {t} = useTranslation();

    return (
        <div>
            <div className={styles.special}>
                <div className={styles["late-arrival-wrapper"]}>
                    <div className={cx("mb-4", "mt-3")}>
                        <CustomCheckBox
                            label={lateArrival
                                ? t("srf_srf_arriving_late_at")
                                : t("srf_srf_arriving_late")}
                            inputProps={{
                                checked: !!lateArrival,
                                onChange: () => {
                                    toggleLateArrival(!lateArrival);
                                }
                            }}

                        />
                    </div>

                    {!!lateArrival && (
                        <div className="bc-text ml-4">
                            <TimeCustomSelect
                                required={true}
                                hoursMinutes={lateArrivalTime}
                                onChange={(hoursMinutes: string) => handleLateArrival(hoursMinutes)}
                            />
                        </div>
                    )}
                </div>
            </div>

            <div className={styles.special2}>
                <div className={styles.comment}>
                    {t("srf_srf_if_possible_provide")}:
                </div>

                <div className="mt-4 mb-1">
                    <CustomCheckBox
                        label={t("srf_srf_double_beds")}
                        inputProps={{
                            checked: !!doubleBed,
                            onChange: () => {
                                handleSpecialRequests({
                                    target: {
                                        value: !doubleBed,
                                        name: "doubleBed"
                                    }
                                });
                                if (!doubleBed) {
                                    handleSpecialRequests({
                                        target: {
                                            value: false,
                                            name: "separateBeds"
                                        }
                                    });
                                }
                            }
                        }}
                    />

                    <CustomCheckBox
                        label={t("srf_srf_separate_beds")}
                        inputProps={{
                            checked: !!separateBeds,
                            onChange: () => {
                                handleSpecialRequests({
                                    target: {
                                        value: !separateBeds,
                                        name: "separateBeds"
                                    }
                                });
                                if (!separateBeds) {
                                    handleSpecialRequests({
                                        target: {
                                            value: false,
                                            name: "doubleBed"
                                        }
                                    });
                                }
                            }
                        }}
                    />

                    <CustomCheckBox
                        label={t("srf_srf_baby_cot")}
                        inputProps={{
                            checked: !!babyCot,
                            onChange: () => {
                                handleSpecialRequests({
                                    target: {
                                        value: !babyCot,
                                        name: "babyCot"
                                    }
                                });
                            }
                        }}
                    />

                    <CustomCheckBox
                        label={t("srf_srf_smoking_rooms")}
                        inputProps={{
                            checked: !!smoking,
                            onChange: () => {
                                handleSpecialRequests({
                                    target: {
                                        value: !smoking,
                                        name: "smoking"
                                    }
                                });
                                if (!smoking) {
                                    handleSpecialRequests({
                                        target: {
                                            value: false,
                                            name: "nonSmoking"
                                        }
                                    });
                                }
                            }
                        }}
                    />

                    <CustomCheckBox
                        label={t("srf_srf_not_smoking_rooms")}
                        inputProps={{
                            checked: !!nonSmoking,
                            onChange: () => {
                                handleSpecialRequests({
                                    target: {
                                        value: !nonSmoking,
                                        name: "nonSmoking"
                                    }
                                });
                                if (!nonSmoking) {
                                    handleSpecialRequests({
                                        target: {
                                            value: false,
                                            name: "smoking"
                                        }
                                    });
                                }
                            }
                        }}
                    />
                </div>

                <div className="mb-4">
                    <CustomCheckBox
                        label={t("srf_srf_guests_honeymooners")}
                        inputProps={{
                            checked: !!honeymoon,

                            onChange: () => {
                                handleSpecialRequests({
                                    target: {
                                        value: !honeymoon,
                                        name: "honeymoon"
                                    }
                                });
                            }
                        }}
                    />
                </div>

                <textarea
                    className={styles["input-field"] + " mb-10"}
                    autoComplete="on"
                    value={text || ""}
                    name="text"
                    onChange={handleSpecialRequests}
                    placeholder={t("srf_srf_type_additional_request_here")}
                />

                <p className={styles.textareaDesc}>{t("srf_srf_sr_desc")}</p>
            </div>

            {children}
        </div>
    );
}

export default SpecialRequestsForm;