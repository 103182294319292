import BasicDropdown from "components/base/Input/BasicDropdown/BasicDropdown";
import React, {
    ReactElement
} from "react";
import {useTranslation} from "react-i18next";
import cx from "classnames";
import styles from "./BookingRoom.module.scss";
import {
    PassengerChildPropTypes, PassengerPropTypes, SpecialOfferPropTypes
} from "proptypes/PropTypeObjects";
import {validateName} from "utils/validateForm";
import Input from "../../../components/base/Input/TextInput/Input";
import {getPriceWithCurrencySymbol} from "@hotelston_web_frontend_utils/currency/getPriceWithCurrency";
import Currency from "@hotelston_web_frontend_parent/constants/currency";

function createMarkup(content: any) {
    return {__html: content};
}

type Props = {
    data: {
        titles: {
            value: string,
            label: string
        }[],
        adults: PassengerPropTypes[],
        children: PassengerChildPropTypes[],
        cxlDate: number,
        boardTypeName: string,
        hotelAmount: number,
        currency: Currency,
        displayBookingTermsCXL: string,
        notAmendable: boolean,
        displayBookingTermsRemarks: string,
        displaySalePrice: number,
        exactRoomTypeName: string,
        hotelstonRoomTypeName: string,
        roomNo: number,
        salePrice: number,
        specialOffers: SpecialOfferPropTypes,
        visaSupport: boolean
    },

    handleAdults: ({target: {value, name}}: any, roomNo: any, personIndex: any) => any,
    handleChildren: ({target: {value, name}}: any, roomNo: any, personIndex: any) => void,
};

function BookingRoom({
    data: {
        titles,
        adults,
        children,
        cxlDate,
        boardTypeName,
        hotelAmount,
        currency,
        displayBookingTermsCXL,
        notAmendable,
        displayBookingTermsRemarks,
        displaySalePrice,
        exactRoomTypeName,
        hotelstonRoomTypeName,
        roomNo
    },

    handleAdults,
    handleChildren
}: Props): ReactElement {
    const {t} = useTranslation();

    return (
        <div className={styles.root}>
            <div className={styles.Heading}>
                <span className={styles.hLeft}>
                    {`${t("h_b_br_room")} ${roomNo + 1}: ${exactRoomTypeName}`}
                </span>
                <span className={styles.hright}>
                    {getPriceWithCurrencySymbol(displaySalePrice * hotelAmount, currency)}
                </span>
            </div>

            <div className={styles.Selector}>
                {adults.map(({
                    firstname,
                    lastname,
                    title
                }: PassengerPropTypes, personIndex: number) => (
                    <div key={personIndex} className={styles.SelectorRow}>
                        <div className={styles.Title}>{t("h_b_br_adult")}</div>
                        <div className={styles.TitleDropdown}>
                            <BasicDropdown
                                required={true}
                                onDropdownChange={(value: any) => handleAdults(
                                    {
                                        target: {
                                            value,
                                            name: "title"
                                        }
                                    },
                                    roomNo,
                                    personIndex
                                )}
                                className="adults-dropdown"
                                dropDownValue={title}
                                dropDownOptions={titles.map(({
                                    label,
                                    value
                                }: { value: string, label: string }) => (
                                    {
                                        value,
                                        label: t(label)
                                    } as { value: string, label: string }
                                ))}
                            />
                        </div>
                        <div className={styles.Name}>
                            <Input
                                fullHeight={false}
                                variants={{labelPosition: "outlined"}}
                                validator={(e) => validateName(e)}
                                inputProps={{
                                    onChange: (e) => {
                                        handleAdults(e, roomNo, personIndex);
                                    },
                                    type: "text",
                                    autoComplete: "given-name",
                                    name: "firstname",
                                    required: true,
                                    value: firstname || "",
                                    placeholder: t("h_b_br_first_name"),
                                    className: "input-field"
                                }}
                            />
                        </div>
                        <div className={styles.Surname}>
                            <Input
                                fullHeight={false}
                                variants={{labelPosition: "outlined"}}
                                validator={(e) => validateName(e)}
                                inputProps={{
                                    onChange: (e) => {
                                        handleAdults(e, roomNo, personIndex);
                                    },
                                    type: "text",
                                    autoComplete: "family-name",
                                    name: "lastname",
                                    required: true,
                                    value: lastname || "",
                                    placeholder: t("h_b_br_last_name"),
                                    className: "input-field"
                                }}
                            />
                        </div>
                    </div>
                ))}

                {children.map(
                    ({
                        firstname,
                        lastname,
                        age = 0
                    }: PassengerChildPropTypes, personIndex: number) => (
                        <div key={personIndex} className={styles.SelectorRow}>
                            <div className={styles.Title}>
                                {t("h_b_br_child")}
                            </div>
                            <div className={styles.Age}>
                                <div className={styles.DisabledText}>
                                    {t("h_b_br_years_old", {age})}
                                </div>
                            </div>
                            <div className={styles.Name}>
                                <Input
                                    fullHeight={false}
                                    variants={{labelPosition: "outlined"}}
                                    validator={(e) => validateName(e)}
                                    inputProps={{
                                        onChange: (e) => {
                                            handleChildren(e, roomNo, personIndex);
                                        },
                                        type: "text",
                                        autoComplete: "given-name",
                                        name: "firstname",
                                        required: true,
                                        value: firstname || "",
                                        placeholder: t("h_b_br_first_name"),
                                        className: "input-field"
                                    }}
                                />
                            </div>
                            <div className={styles.Surname}>
                                <Input
                                    fullHeight={false}
                                    variants={{labelPosition: "outlined"}}
                                    validator={(e) => validateName(e)}
                                    inputProps={{
                                        onChange: (e) => {
                                            handleChildren(e, roomNo, personIndex);
                                        },
                                        type: "text",
                                        autoComplete: "family-name",
                                        name: "lastname",
                                        required: true,
                                        value: lastname || "",
                                        placeholder: t("h_b_br_last_name"),
                                        className: "input-field"
                                    }}
                                />
                            </div>
                        </div>
                    )
                )}
            </div>
            <div className={styles.Info}>
                <dl className={styles.InfoField}>
                    <dt>{t("h_b_br_room_type")}:</dt>
                    <dd>{hotelstonRoomTypeName}</dd>

                    <dt>{t("h_b_br_exact_room_type")}:</dt>
                    <dd>{exactRoomTypeName}</dd>

                    <dt>{t("h_b_br_board_type")}:</dt>
                    <dd>{boardTypeName}</dd>
                </dl>

                <div
                    className={styles.InfoField}
                >
                    <span className={styles.CancellationRulesHeader}>{t("h_b_br_cancellation_rules")}</span>

                    <div
                        dangerouslySetInnerHTML={
                            createMarkup(
                                `<p>
                                    ${displayBookingTermsCXL}
                                </p>
                                `
                            )
                        }
                    />
                </div>

                {notAmendable && (
                    <div
                        className={styles.InfoField}
                    >
                        <span className={styles.CancellationRulesHeader}>{t("h_b_br_amendable_rules")}</span>
                        <div
                            dangerouslySetInnerHTML={
                                createMarkup(
                                    `<p>
                                    ${t("h_b_br_non_amendable")}
                                </p>
                                `
                                )
                            }
                        />
                    </div>
                )}

                {displayBookingTermsRemarks && (
                    <div className={cx(styles.InfoField, styles.Remarks)}>
                        {t("h_b_br_hotel_remarks")}
                        <div
                            dangerouslySetInnerHTML={
                                createMarkup(
                                    `<p>
                                        ${displayBookingTermsRemarks}
                                    </p>
                                    `
                                )
                            }
                        />
                    </div>
                )}

                {/*<Collapse*/}
                {/*    className={cx(styles.InfoField, styles.Remarks)}*/}
                {/*    moreName={t("h_b_br_view_remarks")}*/}
                {/*    lessName={t("h_b_br_less_remarks")}*/}
                {/*>*/}
                {/*    <div*/}
                {/*        dangerouslySetInnerHTML={*/}
                {/*            createMarkup(*/}
                {/*                `<p>*/}
                {/*                        ${displayBookingTermsRemarks}*/}
                {/*                    </p>*/}
                {/*                    `*/}
                {/*            )*/}
                {/*        }*/}
                {/*    />*/}
                {/*</Collapse>*/}
            </div>
        </div>
    );
}

export default BookingRoom;
