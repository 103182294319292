import React, {
    ReactElement, useEffect, useRef, useState
} from "react";
import {TFunction} from "i18next";
import _ from "lodash";
import PasswordValidity from "../../utils/PasswordValidity/PasswordValidity";
import Input from "../../base/Input";
import {validatePassword} from "../../../utils/validateForm";
import OutlineInput from "../../base/Input/TextInput/OutlinedInput";
import PasswordInput from "../PasswordInput/PasswordInput";

type Props = {
    passwordNeeded: boolean;
    password: string;
    onChange: (e: React.ChangeEvent<HTMLInputElement> | undefined) => void;
    t: TFunction;
    className: string;
};

function PasswordWithRepeat({
    passwordNeeded,
    password,
    onChange,
    t,
    className
}: Props): ReactElement {
    const repeatedPasswordInputRef = useRef<OutlineInput>(null);
    const [repeatedPassword, setRepeatedPassword] = useState("");

    useEffect(() => {
        if (repeatedPasswordInputRef.current && !_.isEmpty(repeatedPassword)) {
            repeatedPasswordInputRef.current.updateValidity();
        }
    }, [password, repeatedPassword]);

    return (
        <div>
            <div className={className}>
                <PasswordValidity
                    password={password}
                >
                    {(setVisibleTippy: (visible: boolean) => void) => (
                        <PasswordInput
                            variants={{labelPosition: "outlined"}}
                            fullHeight
                            validator={(e) => ((e.target.value !== undefined && e.target.value !== "") ? validatePassword(e) : e.target.setCustomValidity(""))}
                            inputProps={{
                                required: passwordNeeded,
                                autoComplete: "new-password",
                                onFocus: () => {
                                    setVisibleTippy(true);
                                },
                                onBlur: () => {
                                    setVisibleTippy(false);
                                },
                                name: "password",
                                onChange: (e) => {
                                    onChange(e);
                                },
                                value: password,
                                type: "password",
                                placeholder: t("udm_udm_password")
                            }}
                        />
                    )}
                </PasswordValidity>
            </div>
            <PasswordInput
                inputComponentRef={repeatedPasswordInputRef}
                customErrorMessage={t("util_vf_error_psw_not_match")}
                variants={{labelPosition: "outlined"}}
                fullHeight
                inputProps={{
                    required: passwordNeeded || (password !== undefined && password !== ""),
                    pattern: _.escapeRegExp(password),
                    autoComplete: "new-password",
                    name: "repeatedPassword",
                    placeholder: t("udm_udm_repeat_password"),
                    onChange: (e) => {
                        setRepeatedPassword(e.target.value);
                        onChange(undefined);
                    },
                    value: repeatedPassword,
                    type: "password"
                }}
            />
        </div>
    );
}

export default PasswordWithRepeat;