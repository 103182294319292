import React, {ReactElement} from "react";
import {useTranslation} from "react-i18next";
import cx from "classnames";
import styles from "./SmsLanguage.module.scss";
import {LocalePropTypes} from "../../../proptypes/PropTypeObjects";
import StandaloneDropdownWithSearch, {
    DropdownWithSearchOption
} from "components/base/Input/DropdownWithSearch/StandaloneDropdownWithSearch";

type Props = {
    textInputValue?: string;
    setTextInputValue: (textInputValue?: string | number | readonly string[]) => void;
    handleSmsLocaleChange: (value: string) => void;

    options: LocalePropTypes[];
    fullHeight: boolean;
    className?: string;
};

function SmsLanguage({
    textInputValue,
    setTextInputValue,
    handleSmsLocaleChange,
    options = [{
        name: "English",
        lang: "en",
        googleLang: "en"
    }],
    fullHeight = true,

    className
}: Props): ReactElement {
    const optionsFormed = options.map(({name, lang}) => new DropdownWithSearchOption(
        name, lang
    ));

    const {t} = useTranslation();

    return (
        <>
            <StandaloneDropdownWithSearch
                inputProps={{
                    name: "smsReminderLocale",
                    value: textInputValue,
                    placeholder: t("t_b_sms_message_language"),
                    onChange: (e) => {
                        setTextInputValue(e.target.value);
                    },
                    required: true
                }}
                onValueChange={(value) => {
                    handleSmsLocaleChange(value.value);
                    setTextInputValue(value.label);
                }}
                options={optionsFormed}
                className={cx(styles.root, className)}
            />
        </>
    );
}

export default SmsLanguage;
