import React, {ReactElement} from "react";
import {useTranslation} from "react-i18next";
import styles from "./DuplicateBooking.module.scss";
import UserButton from "../../../components/base/UserButton";
import {UserButtonVariant} from "../../../components/base/UserButton/UserButton";
import createLuxonDate from "../../../utils/date/createLuxonDate"
//UNUSED !!!
function DuplicateBooking({
    duplicateBookings,
    confirmDuplicateBooking,
    goBack
}: any): ReactElement {
    const {t} = useTranslation();
    return (
        <div className={styles.root}>
            <div style={{overflowX: "auto"}}>
                <table className={styles.table}>
                    <thead className={styles.thead}>
                        <tr>
                            <td>{t("b_b_bc_db_booking_ref")}.</td>
                            <td>{t("b_b_bc_db_name_of_booking")}</td>
                            <td>{t("b_b_bc_db_check-in")}</td>
                            <td>{t("b_b_bc_db_check-in")}</td>
                            <td>{t("b_b_bc_db_check-in")}</td>
                        </tr>
                    </thead>
                    <tbody className={styles.tbody}>
                        {duplicateBookings.map(
                            ({
                                displayServiceName,
                                id,
                                leadPassengerName,
                                reference,
                                serviceStartDate,
                                status,
                                statusName
                            }: any) => (
                                <tr key={id}>
                                    <td className="green-color">
                                        {reference}
                                    </td>
                                    <td>{displayServiceName}</td>
                                    <td>
                                        {createLuxonDate(serviceStartDate).toUTC().toFormat("yyyy-MM-dd")}
                                    </td>
                                    <td className="capitalize">
                                        {leadPassengerName}
                                    </td>
                                    <td>{statusName}</td>
                                </tr>
                            )
                        )}
                    </tbody>
                </table>
            </div>
            <div className={styles.actionBox}>
                <div className={styles.actionTitle}>
                    {t("b_b_bc_db_question_about_proceed_booking")}
                </div>
                <div className={styles.buttonsWrapper}>
                    <UserButton
                        text={t("b_b_bc_db_back_button")}
                        variant={UserButtonVariant.SECONDARY}
                        buttonProps={{
                            type: "button",
                            onClick: goBack,
                            style: {
                                width: 180
                            }
                        }}
                    />
                    <UserButton
                        text={t("b_b_bc_db_continue")}
                        variant={UserButtonVariant.PRIMARY}
                        buttonProps={{
                            type: "button",
                            onClick: confirmDuplicateBooking,
                            style: {
                                width: 180,
                                marginLeft: "auto"
                            }
                        }}
                    />
                </div>
            </div>
        </div>
    );
}

export default DuplicateBooking;
