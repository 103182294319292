import _ from "lodash";
import {FALLBACK_DATE_FORMAT} from "../components/utils/Internationalization/formats";
import {DateTime} from "luxon"
import createLuxonDate from "./date/createLuxonDate"

export function convertToServerTime(date: string | number | undefined): string | undefined {
    if (!date) {
        return undefined;
    }

    let luxonDate = createLuxonDate(date);
    let convertedDate: Date;
    if (!Number.isNaN(date)) {
        convertedDate = new Date(Date.parse(date as string));

        if (_.isNumber(date)) {
            convertedDate = createLuxonDate(date).toJSDate();
        }

        if (Number.isNaN(convertedDate.getTime()) || !_.isDate(convertedDate)) {
            convertedDate = createLuxonDate(date).toJSDate();
        }
    } else {
        convertedDate = new Date(date);
    }

    const isoDate = DateTime.fromJSDate(convertedDate);
    const isoDateString = isoDate.toISO();
    if (isoDate.isValid && isoDateString) {
        return isoDateString;
    }

    return luxonDate.setLocale("Europe/Vilnius").toISO() || undefined;
}

export function removeTimezoneInformationFromDate(date: string | number | DateTime | undefined): string | undefined {
    if (!date) {
        return undefined;
    }

    return createLuxonDate(date).toFormat(FALLBACK_DATE_FORMAT);
}

export function resetTime(date: DateTime): DateTime {
    return DateTime.fromObject({
        year: date.year,
        month: date.month,
        day: date.day
    });
}

export function dateWithSetTime(date: DateTime, hour: number, minute: number): DateTime {
    return DateTime.fromObject({
        year: date.year,
        month: date.month,
        day: date.day,
        hour: hour,
        minute: minute
    });
}

export function setMaxTime(date: DateTime): DateTime {
    return DateTime.fromObject({
        year: date.year,
        month: date.month,
        day: date.day,
        hour: 23,
        minute: 59,
        second: 59,
        millisecond: 999
    });
}

export function todayWithoutTime(): DateTime {
    return createLuxonDate().set({
        hour: 0,
        minute: 0,
        second: 0,
        millisecond: 0
    });
}

export function formatTimeString(hour?: string | number, minute?: string | number): string | undefined {
    if (hour === undefined || minute === undefined) {
        return undefined;
    }

    return ((+hour < 10) ? `0${hour.toString()[0]}` : `${hour}`) + ":" + ((+minute < 10) ? `0${minute.toString()[0]}` : `${minute}`);
}