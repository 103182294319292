import {pinnedHotelsSelector, pinnedHotelsRemovePinnedOffer} from "redux/selectors/pinnedHotelsSelector";
import {PinnedOffer} from "../../proptypes/PropTypeObjects";
import PinnedOffersTypes from "../constants/pinnedOffers.constants";
import {CommonActionReturnType} from "../store/store.init";
import IAppActions from "../store/store.actions";

export function addPinnedOffer(pinnedOffer: PinnedOffer): CommonActionReturnType {
    return (dispatch, getState) => {
        const pinnedHotels = pinnedHotelsSelector(pinnedOffer)(getState());
        dispatch({type: PinnedOffersTypes.ADD_PINNED_OFFER, pinnedOffer, pinnedHotels});
    };
}

export function removePinnedOffer(pinnedOffer: PinnedOffer): CommonActionReturnType {
    return (dispatch, getState) => {
        const pinnedHotels = pinnedHotelsRemovePinnedOffer(pinnedOffer)(getState());
        dispatch({type: PinnedOffersTypes.REMOVE_PINNED_OFFER, pinnedOffer, pinnedHotels});
    };
}

export function setSlide(slide: boolean): IAppActions {
    return ({type: PinnedOffersTypes.SET_SLIDE, slide});
}

export function resetPinnedOffers(): IAppActions {
    return ({type: PinnedOffersTypes.RESET_PINNED_OFFERS});
}
