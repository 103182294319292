enum HotelSearchTypes {
    // hotelSearch processing'

    INIT_SEARCH = "hotelSearch/INIT_SEARCH",
    SUBMIT_SEARCH = "hotelSearch/SUBMIT_SEARCH",
    START_SEARCH = "hotelSearch/START_SEARCH",
    UPDATE_SEARCH = "hotelSearch/UPDATE_SEARCH",
    GET_DATA_SUCCESS = "hotelSearch/GET_DATA_SUCCESS",
    GET_DATA_FAILURE = "hotelSearch/GET_DATA_FAILURE",
    HANDLE_FORM_DATA = "hotelSearch/HANDLE_FORM_DATA",
    UPDATE_FORM_DATA = "hotelSearch/UPDATE_FORM_DATA",
    ADD_ROOM = "hotelSearch/ADD_ROOM",
    REMOVE_ROOM = "hotelSearch/REMOVE_ROOM",
    UPDATE_STATE_FORM = "hotelSearch/UPDATE_STATE_FORM",
    HANDLE_INPUT_CHANGE = "hotelSearch/HANDLE_INPUT_CHANGE",

    SET_HOTEL_ID = "hotelSearch/SET_HOTEL_ID",

    //recent searches and saved searches

    CHANGE_CURRENT_DESTINATION = "hotelSearch/CHANGE_CURRENT_DESTINATION",

    GET_RECENT_SEARCHES = "hotelSearch/GET_RECENT_SEARCHES",

    GET_SAVED_SEARCHES = "hotelSearch/GET_SAVED_SEARCHES",

    IGNORE_SELECT_BEST_OFFERS_CHANGE = "hotelSearch/IGNORE_SELECT_BEST_OFFERS_CHANGE",
    MULTIPROVIDER_CHANGE = "hotelSearch/MULTIPROVIDER_CHANGE",
    LOG_REQUESTS_CHANGE = "hotelSearch/LOG_REQUESTS_CHANGE",

    TOGGLE_HOTEL_ID = "hotelSearch/TOGGLE_HOTEL_ID",
}

export default HotelSearchTypes;