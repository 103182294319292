import React, {ReactElement, useCallback, useEffect} from "react"
import {connect, ConnectedProps} from "react-redux";
import * as myBookingsActions from "redux/actions/myBookings.actions";
import {handleUseOverpayment} from "redux/actions/overpayment.actions";
import MainContainer from "components/base/MainContainer";
import Spinner from "components/base/Loaders/Spinner";
import withConfirmation, {WithConfirmation} from "components/utils/withConfirmation";
import SearchMyBookings from "../SearchMyBookings";
import MyBookingsList from "../MyBookingsList";
import Reconcile from "./Reconcile";
import {PaymentStatusPropTypes} from "proptypes/PropTypeObjects";
import {RootState} from "redux/store/store.init";
import {Navigate, useNavigate, useParams} from "react-router-dom"
import {useTranslation} from "react-i18next"
import Currency from "@hotelston_web_frontend_parent/constants/currency";

type ReconcileContainerProps = ConnectedProps<typeof connector> & WithConfirmation;
const PRELOAD_PAYMENT_STATUSES = ["PARTIALLY_PAID", "NOT_PAID"] as PaymentStatusPropTypes[];

const ReconcileContainer = (props: ReconcileContainerProps): ReactElement => {
    const {
        myBookings: {
            bookingOptions: {
                columns = [],
                bookingStatuses = [],
                countries = [],
                users = [],
                subAgents = [],
                paymentStatuses = [],
                serviceTypes = []
            } = {},
            data,
            formData,
            pageNr,
            pageSize,
            isSearching,
            searchSubmitted,
            optionsLoading,
            advancedFilters,
            activeQuickFilters,
            quickFilters
        },
        overpayment: {
            overpaymentOptions: {
                amount = 0,
                currency = Currency.EUR,
                overPaymentTypes = []
            } = {},
            requestingUse
        },
        lastBookingLocation,
        toggleAdvancedFilters,
        handleQuickFilters,
        handleSubmit,
        resetFormData,
        companyCurrency,
        handleSort,
        handlePageNr,
        handlePageSize,
        searchBookings,
        getSearchOptions,
        resetData,
        locale,

        setModal,
        handleUseOverpayment: handleUseOverpayment1
    } = props;

    const {t} = useTranslation();
    const params = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        getSearchOptions();
        searchBookings();

        return () => {
            resetData();
        }
    }, []);

    useEffect(() => {
        getSearchOptions();
        searchBookings();
    }, [currency, locale]);

    const handleReconcilePay = useCallback((bookingToPayId: number) => {
        setModal({
            onConfirm: () => handleUseOverpayment1(parseInt(params?.id || "", 10), {
                bookingToPayId,
                overPaymentType: "RECONCILE"
            }),
            onDecline: () => false,
            config: {
                variants: {
                    type: "warn"
                },
                title: t("mb_recc_reconcile_overpayment"),
                content: <span>{t("mb_recc_you_decided_to_pay_for", {id: bookingToPayId})}</span>
            }
        });
    }, [handleUseOverpayment1, params?.id, setModal, t]);

    if (!overPaymentTypes.includes("RECONCILE")) {
        return <Navigate to={lastBookingLocation || "/my-bookings"}/>;
    }

    return (
        <MainContainer
            variants={{
                fluid: true
            }}
        >
            {requestingUse ? (
                <Spinner
                    size="50px"
                    style={{
                        width: "100%",
                        height: "calc( 100vh - 120px )"
                    }}
                />
            ) : (
                <>
                    <Reconcile
                        overpayment={amount}
                        currency={currency}
                        bookingId={parseInt(params?.id || "", 10)}
                        cancelReconcile={() => navigate(
                            `/my-bookings/booking/${params.id}/overpayment`
                        )}
                    />

                    <SearchMyBookings
                        bookingStatuses={bookingStatuses || []}
                        countries={countries || []}
                        users={users || []}
                        subAgents={subAgents || []}
                        paymentStatuses={paymentStatuses || []}
                        serviceTypes={serviceTypes || []}
                        formData={formData}
                        advancedFilters={advancedFilters && !optionsLoading}
                        //preloadQuickFilters={this.preloadQuickFilters}
                        preloadPaymentStatuses={PRELOAD_PAYMENT_STATUSES}
                        quickFilters={quickFilters}
                        activeQuickFilters={activeQuickFilters}
                        searchSubmitted={searchSubmitted}
                        toggleAdvancedFilters={toggleAdvancedFilters}
                        handleQuickFilters={handleQuickFilters}
                        handleSubmit={handleSubmit}
                        resetFormData={resetFormData}
                        searchBookings={searchBookings}
                    />

                    {isSearching ? (
                        <Spinner
                            size="50px"
                            style={{
                                width: "100%",
                                height: "calc( 100vh - 120px )"
                            }}
                        />
                    ) : (companyCurrency && formData && data && (
                        <MyBookingsList
                            variants={{
                                withHeader: false,
                                withPayAction: true
                            }}
                            companyCurrency={companyCurrency}
                            pageNr={pageNr}
                            pageSize={pageSize}
                            sort={formData.sort}
                            columns={columns || []}
                            bookings={data.bookings || []}
                            bookingsCount={data.bookingsCount || 0}
                            multipleOrders={false}
                            handleSort={handleSort}
                            handlePageNr={handlePageNr}
                            handlePageSize={handlePageSize}
                            handleReconcilePay={handleReconcilePay}
                        />
                    )
                    )}
                </>
            )}
        </MainContainer>
    );
}

const mapStateToProps = ({
    myBookings,
    locale,
    currency,
    auth,
    overpayment,
    router
}: RootState) => ({
    myBookings,
    lastBookingLocation: router.lastLocations.myBookings.lastBookingLocation,
    locale: locale.currentLocale,
    currency: currency.currentCurrency,
    companyCurrency: auth.userData?.currency,
    overpayment
});

const connector = connect(mapStateToProps, {...myBookingsActions, handleUseOverpayment});
export default withConfirmation(connector(ReconcileContainer));
