import React, {useEffect, useMemo, useState} from "react";
import {DateTime} from "luxon"
import groupBy from "lodash/groupBy";
import DynamicTime from "components/common/DynamicTime";
import SearchElement from "components/common/SearchElement";
import UserButton from "components/base/UserButton";
import {useTranslation} from "react-i18next";
import {WithNotifications, withNotifications} from "components/utils/withNotifications";
import {Dictionary} from "lodash";
import {NotificationsDateBoxItem} from "./NotificationsDateBox/NotificationsDateBox";
import NotificationsDateBox from "./NotificationsDateBox";
import {Notification} from "proptypes/PropTypeObjects";
import {UserButtonVariant} from "components/base/UserButton/UserButton";
import {LocaleDateFormatTypeEnum} from "components/utils/Internationalization/useLocaleDateTimeFormat";
import MainContainer from "components/base/MainContainer";
import createLuxonDate from "utils/date/createLuxonDate"
import styles from "./Notifications.module.scss";

function getGroups(array: Notification[]): Dictionary<Notification[]> {
    return groupBy(array, (result) => createLuxonDate(result.timestamp).toFormat("yyyy-MM-dd"));
}

type Props = WithNotifications;

function NotificationsContainer({
    setAsRead,
    setAsReadAll,
    loadNotifications,
    notificationsData
}: Props) {
    const {t} = useTranslation();

    const [inputFilter, setInputFilter] = useState<string>("");

    const notificationsFiltered = useMemo(
        () => (notificationsData
            ? notificationsData.notifications.filter(({
                body,
                title
            }) => {
                if (inputFilter) {
                    return (
                        title.toLowerCase().includes(inputFilter.toLowerCase()) ||
            body.toLowerCase().includes(inputFilter.toLowerCase())
                    );
                }
                return true;
            })
            : []),

        [notificationsData, inputFilter]
    );

    const groups = useMemo(() => getGroups(notificationsFiltered), [
        notificationsFiltered
    ]);

    useEffect(() => {
        const from = 0;

        const to = notificationsData ? notificationsData.total > 20 ? 20 : notificationsData.total : 20;

        if (from < 20) {
            loadNotifications({from, to});
        }
    }, []);

    const notificationListSorted = useMemo(
        () => Object.keys(groups)
            .sort((a, b) => (a > b ? -1 : b < a ? 1 : 0))
            .map((key, keyIndex) => (
                <NotificationsDateBox
                    date={(
                        <DynamicTime
                            time={+DateTime.fromFormat(key, "yyyy-MM-dd").toMillis()}
                            displayDailyOnly
                            maxDaysAgo={0}
                            outputFormatType={LocaleDateFormatTypeEnum.DATE}
                        />
                    )}
                    key={`${key}-${keyIndex}`}
                >
                    {groups[key]
                        .sort((a, b) => b.timestamp - a.timestamp)
                        .map((item, i) => (
                            <NotificationsDateBoxItem
                                key={`${item.id}-${i}`}
                                body={item.body}
                                heading={item.title}
                                unread={item.status === "UNREAD"}
                                link={item.link}
                                setAsRead={() => setAsRead(item.id)}
                                time={(
                                    <DynamicTime
                                        time={item.timestamp}
                                        realTimeUpdate={keyIndex === 0}
                                        maxDaysAgo={0}
                                        outputFormatType={LocaleDateFormatTypeEnum.TIME}
                                    />
                                )}
                            />
                        ))}
                </NotificationsDateBox>
            )),
        [groups, setAsRead]
    );

    //<MainContainer
    //             sidebar={(
    //                 <NotificationsSettings />
    //             )}
    //         >

    return (
        <MainContainer>
            <div className={styles.Root}>
                <div className={styles.Heading}>
                    <h1>{t("notif_nc_notifications")}</h1>
                    <SearchElement
                        className={styles.NotificationsSearch}
                        inputProps={{
                            value: inputFilter,
                            className: styles.NotificationsSearchInput,
                            onChange: (e: any) => setInputFilter(e.target.value)
                        }}
                    />
                </div>

                {notificationListSorted}

                {notificationsData && notificationsData.notifications.length < notificationsData.total && (
                    <UserButton
                        text={t("notif_nc_load_more_notifications")}
                        variant={UserButtonVariant.SECONDARY}
                        buttonProps={{
                            type: "button",
                            onClick: () => loadNotifications({
                                from: notificationsData.notifications.length,
                                to: notificationsData.notifications.length + 20
                            }),
                            style: {
                                marginTop: 20
                            }
                        }}
                    />
                )}
            </div>
        </MainContainer>
    );
}

export default withNotifications(NotificationsContainer);
