import React, {ReactElement} from "react";
import {useTranslation} from "react-i18next";
import cx from "classnames";
import styles from "./SavedBox.module.scss";

const SavedBox = ({
    children
}: any): ReactElement => {
    const {t} = useTranslation();

    return (
        <div className={cx(styles.HotelsSavedBox)}>
            <div>
                <h2 className={cx(styles.SearchTitleText)}>{t("h_s_save_saved_searches_title")}</h2>
                <div>
                    {children}
                </div>
            </div>
        </div>
    );
};

export default SavedBox;
