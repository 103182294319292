import React, {ReactElement} from "react";

export enum ActionIconType {
    CLOSE,
    ERROR
}

export type ActionIconProps = {
    type: ActionIconType;
};

const ICON_MAP = new Map<ActionIconType, string>([
    [ActionIconType.CLOSE, "✖"],
    [ActionIconType.ERROR, "✖"]
]);

const ActionIcon = ({type}: ActionIconProps): ReactElement => {
    const symbol = ICON_MAP.get(type);

    return (<>{symbol}</>);
};

export default ActionIcon;