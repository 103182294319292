import React, {ReactElement} from "react";
import cx from "classnames";
import styles from "./MoreLessButton.module.scss";

function MoreLessButton({
    moreLabel = "",
    lessLabel = "",
    canBeMore = false,
    onClick = () => {},
    className
}: {
    moreLabel: string;
    lessLabel: string;
    canBeMore: boolean;
    onClick: (...args: any[]) => void;
    className?: string;
}): ReactElement {
    return (
        <div
            className={cx(styles.root, !canBeMore && styles.Less, className)}
            onClick={onClick}
        >
            {canBeMore ? moreLabel : lessLabel}
        </div>
    );
}

export default MoreLessButton;
