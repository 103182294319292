import React, {ReactElement} from "react";
import styles from "components/base/Input/Slider/SliderScale.module.scss";

type Props = {
    min: number;
    intervals: number;
    pointsInside: number;
    maxShown: number
    max: number;
};

function SliderScale({
    min,
    intervals,
    pointsInside,
    maxShown,
    max
}: Props): ReactElement {
    const scales = [];
    let key = 0;

    for (let index = 0; index < intervals; index++) {
        key += 1;

        const maxValue = maxShown > max ? max : maxShown;
        const intervalIncrement = (maxValue - min) / intervals;

        scales.push(
            <div key={key} className={styles.PointWithValue}>
                <span className={styles.PointLong} />
                <span className={styles.Value}>{ Math.ceil(min + intervalIncrement * index)}</span>
            </div>
        );

        for (let j = 0; j < pointsInside; j++) {
            key += 1;
            scales.push(
                <div key={key} className={styles.Point}>
                    <span className={styles.PointShort} />
                </div>
            );
        }
    }

    key += 1;
    scales.push(
        <div key={key} className={styles.PointWithValue}>
            <span className={styles.PointLong} />
            <span className={styles.Value}>{maxShown < max ? `${maxShown}+` : max}</span>
        </div>
    );

    return (
        <div className={styles.ScalesContainer}>
            {scales}
        </div>
    );
}

function areEqual(prevProps: Readonly<React.PropsWithChildren<Props>>, nextProps: Readonly<React.PropsWithChildren<Props>>): boolean {
    return prevProps.max === nextProps.max && prevProps.min === nextProps.min;
}

export default React.memo(SliderScale, areEqual);
