import {Marker} from "@react-google-maps/api";
import React, {ReactElement} from "react";
import {HotelCoordinatesPropTypes} from "../../../../proptypes/PropTypeObjects";

export default function CustomMarker({coordinates}: any): ReactElement {
    const {latitude: lat, longitude: lng} = coordinates;
    return (
        <Marker
            position={{
                lat,
                lng
            }}
            noClustererRedraw
        />
    );
}