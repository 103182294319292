import React, {ReactElement, useCallback} from "react";
import {useTranslation} from "react-i18next";
import {connect} from "react-redux";
import {Link, matchPath} from "react-router-dom"
import {BreadcrumbSharedProps} from "../Breadcrumb/Breadcrumb.base";
import Breadcrumb from "../Breadcrumb/Breadcrumb";
import {RootState} from "../../../../../redux/store/store.init";

export type MyBookingBreadcrumbsProps = {
    requesting?: boolean;
    bookingId?: number;
    bookingReference?: string;
} & BreadcrumbSharedProps;

const MyBookingsBreadcrumbs = ({
    location,
    lastLocation,

    requesting,
    bookingId,
    bookingReference
}: MyBookingBreadcrumbsProps): ReactElement => {
    const {t} = useTranslation();

    const extractReferenceFromURL = useCallback(() => {
        const split = window.location.pathname.split("/");
        const id = +split[split.length - 1];

        if (bookingReference && +bookingReference.substr(2, bookingReference.length) === id) {
            return bookingReference;
        }

        return "TF" + id;
    }, [bookingReference]);

    if (!matchPath("/my-bookings/*", location.pathname)) {
        return (
            <></>
        );
    }

    return (
        <Breadcrumb
            location={location}
            lastLocation={{pathname: "/my-bookings", search: "", hash: ""}}
            previousLocationLabel={t("b_c_my_bookings")}
            crumbs={[
                {pathName: "/my-bookings/booking/:id", child: t("b_c_my_bookings_booking", {bookingReference: extractReferenceFromURL()})},

                {pathName: "/my-bookings/payment/*", child: <Link to={`/my-bookings/booking/${bookingId}`}>{t("b_c_my_bookings_booking", {bookingReference: bookingReference})}</Link>},
                {pathName: "/my-bookings/payment/*", child: t("b_c_my_bookings_payment")},

                {pathName: "/my-bookings/booking/:id/payment/*", child: <Link to={`/my-bookings/booking/${bookingId}`}>{t("b_c_my_bookings_booking", {bookingReference: bookingReference})}</Link>},
                {pathName: "/my-bookings/booking/:id/payment/*", child: t("b_c_my_bookings_payment")},

                {pathName: "/my-bookings/booking/:id/amendments/transfer/*", child: <Link to={`/my-bookings/booking/${bookingId}`}>{t("b_c_my_bookings_booking", {bookingReference: bookingReference})}</Link>},
                {pathName: "/my-bookings/booking/:id/amendments/transfer/*", child: t("b_c_my_bookings_amendments")},

                {pathName: "/my-bookings/booking/:id/overpayment/*", child: <Link to={`/my-bookings/booking/${bookingId}`}>{t("b_c_my_bookings_booking", {bookingReference: bookingReference})}</Link>},
                {pathName: "/my-bookings/booking/:id/overpayment/*", child: t("b_c_my_bookings_overpayment")},

                // {pathName: "/my-bookings/booking/:id/overpayment/reconcile/*", child: <Link to={`/my-bookings/booking/${bookingId}`}>{t("b_c_my_bookings_booking", {bookingReference: bookingReference})}</Link>},
                // {pathName: "/my-bookings/booking/:id/overpayment/reconcile/*", child: <Link to={`/my-bookings/booking/${bookingId}/overpayment`}>{t("b_c_my_bookings_overpayment")}</Link>},
                {pathName: "/my-bookings/booking/:id/overpayment/reconcile/*", child: t("b_c_my_bookings_overpayment_reconcile")}
            ]}
        />
    );
};

const mapStateToProps = (root: RootState) => ({
    requesting: root.bookingDetails.requestingBookingDetails,
    bookingId: root.bookingDetails.data?.id,
    bookingReference: root.bookingDetails.data?.reference
});

export default connect(mapStateToProps, undefined)(MyBookingsBreadcrumbs);