import React from "react";
import styles from "./DefaultScreen.module.scss"
import animatedLogo from "assets/images/logoAnimated.gif";

export type DefaultScreenProps = {
  phrase: string;
}

const DefaultScreen = (props: DefaultScreenProps) => {
    const {
        phrase
    } = props

    return (
        <div className={styles.Root}>
            <div className={styles.Centered}>
                <img src={animatedLogo} alt="loading_anim" />

                <div className={styles.Notice}>
                    {phrase}
                </div>
            </div>
        </div>
    );
}

export default DefaultScreen;