import LocaleTypes from "../constants/locale.constants";
import {ReduxLocalePropTypes} from "../../proptypes/PropTypeRedux";
import LocaleActions from "./actions/locale.reducer.actions";

const initialLocales = {
    locales: [
        {
            name: "English",
            lang: "en",
            googleLang: "en"
        },
        {
            name: "Italiano",
            lang: "it",
            googleLang: "it"
        },
        {
            name: "Pусский",
            lang: "ru",
            googleLang: "ru"
        },
        {
            name: "Polski",
            lang: "pl",
            googleLang: "pl"
        },
        {
            name: "Lietuvių",
            lang: "lt",
            googleLang: "lt"
        },
        {
            name: "Español",
            lang: "es",
            googleLang: "es"
        },
        {
            name: "Português",
            lang: "pt",
            googleLang: "pt"
        }
    ]
};

export const INITIAL_STATE = {
    locales: initialLocales,
    currentLocale: localStorage.getItem("currentLocale") || "en"
} as ReduxLocalePropTypes;

export default (state = INITIAL_STATE, action: LocaleActions): ReduxLocalePropTypes => {
    switch (action.type) {
    case LocaleTypes.REQ_LOCALES:
        return {...state};
    case LocaleTypes.GET_LOCALES_SUCCESS:
        return {...state, locales: action.locales};
    case LocaleTypes.GET_LOCALES_FAILURE:
        return {...state, error: action.error};
    case LocaleTypes.CHANGE_LOCALE:
        return {
            ...state,
            currentLocale: action.currentLocale
        };
    default:
        return state;
    }
};
