import React, {useCallback, useState} from "react"
import {useTranslation} from "react-i18next"
import styles from "./LoginNavBar.module.scss";
import {ReactComponent as HotelstonLogo} from "assets/images/logoHotel.svg";
import {ReactComponent as HotelstonLogoSmall} from "assets/images/logo.svg";
import {ReactComponent as BarsIcon} from "assets/icons/menu.svg";
import {ReactComponent as CrossIcon} from "assets/icons/crossSlim.svg";
import cx from "classnames";
import landingURL from "api/constants/landingBaseUrl";
import HamburgerMenuLocaleDropdown from "../../../components/base/Header/HamburgerMenu/HamburgerMenuLocaleDropdown"
import LinkGroup from "./LinkGroup/LinkGroup"
import {useLocale} from "../../../components/utils/withLocalesAndCurrencies"

export type LoginNavBarProps = {
    transparent?: boolean;
};

const LoginNavBar = (props: LoginNavBarProps) => {
    const {
        transparent = false
    } = props;

    const {t} = useTranslation();
    const locale = useLocale();

    const [hamburgerActive, setHamburgerActive] = useState(false);

    const toggleActive = useCallback(() => {
        setHamburgerActive(!hamburgerActive);
    }, [hamburgerActive]);

    return (
        <nav className={cx(
            styles.Root,
            transparent && styles.Transparent,
            hamburgerActive && styles.HamburgerActive
        )}
        >
            <div className={styles.Container}>
                {!hamburgerActive && (
                    <>
                        <a className={styles.LogoContainer} href={`${landingURL}/${locale}`}>
                            <HotelstonLogo className={styles.Logo} />
                            <HotelstonLogoSmall className={styles.LogoSmall} />
                        </a>

                        <BarsIcon
                            className={styles.MenuIcon}
                            onClick={toggleActive}
                        />
                    </>
                )}

                {hamburgerActive && (
                    <CrossIcon
                        className={styles.CloseIcon}
                        onClick={toggleActive}
                    />
                )}

                <div className={styles.LinkContainer}>
                    <a href={`${landingURL}/${locale}/about`}>{t("l_n_b_about_link")}</a>

                    <LinkGroup initial={true} groupLabel={t("l_n_b_services_group")}>
                        <a href={`${landingURL}/${locale}/hotels`}>{t("l_n_b_hotels_link")}</a>
                        <a href={`${landingURL}/${locale}/transfers`}>{t("l_n_b_transfers_link")}</a>
                        <a href={`${landingURL}/${locale}/api`}>{t("l_n_b_api_link")}</a>
                    </LinkGroup>

                    <a href={`${landingURL}/${locale}/contacts`}>{t("l_n_b_contacts_link")}</a>
                    <a href={`${landingURL}/${locale}/news`}>{t("l_n_b_news_link")}</a>
                </div>

                <HamburgerMenuLocaleDropdown className={styles.LocaleDropdown} />
            </div>
        </nav>
    )
};

export default LoginNavBar;