import React, {ReactElement, useState} from "react";
import search_icon from "assets/icons/search 2.svg";
import delete_icon from "assets/icons/delete.svg";
import {useTranslation} from "react-i18next";
import {
    DestinationDestinationPropTypes,
    HotelSearchCriteriaPropTypes,
    NationalityPropTypes, RecentDestinationPropTypes,
    SavedSearchPropTypes
} from "../../../../../proptypes/PropTypeObjects";
import styles from "./SavedItem.module.scss";
import ConfirmationModal from "../../../../../components/common/ConfirmationModal";
import createLuxonDate from "../../../../../utils/date/createLuxonDate"

type Props = {
    data: { nationalities: NationalityPropTypes[] } & SavedSearchPropTypes;
    startSearchFromData: (formData: HotelSearchCriteriaPropTypes, destination: DestinationDestinationPropTypes | RecentDestinationPropTypes) => void
    deleteSavedSearch: (searchId: number) => void
};

const SavedItem = ({
    data: {
        criteria,
        destination,
        id,
        nationalities
    },

    deleteSavedSearch,
    startSearchFromData
}: Props): ReactElement => {
    const {t} = useTranslation();
    const [confirmationVisible, setConfirmationVisible] = useState<boolean>(false);

    const location = destination.cityName || destination.countryName
        ? `${destination.cityName ? destination.cityName : ""}${destination.countryName ? ", " + destination.countryName : ""}`
        : destination.hotelName ? destination.hotelName : "";

    const clients = criteria.rooms
        ? criteria.rooms.reduce((prev, curr) => ({
            adults: prev.adults + curr.adults,
            children: prev.children + curr.children.length
        }), {
            adults: 0,
            children: 0
        })
        : undefined;

    const clientString = clients ? `${clients.adults} ${t("h_s_si_adult", {count: clients.adults})} ${clients.children} ${t("h_s_si_child", {count: clients.children})}` : "";

    const fromDate = createLuxonDate(criteria.checkIn).toFormat("yyyy-MM-dd");
    const endDate = createLuxonDate(criteria.checkOut).toFormat("yyyy-MM-dd");
    // const days = moment(endDate).diff(moment(fromDate), "days");

    // const nightString = `${days} ${t("h_s_si_night", { count: days })}`;
    const clientNationality = nationalities && nationalities.find((nat) => nat.iso === criteria.clientNationality)?.name;

    return (
        <div className={styles.HotelsSavedItems}>
            {confirmationVisible ? (
                <ConfirmationModal
                    onConfirm={() => {
                        deleteSavedSearch(id);
                        setConfirmationVisible(false);
                    }}
                    onDecline={() => setConfirmationVisible(false)}
                    config={{
                        variants: {
                            type: "normal"
                        },
                        content: (<>{t("h_s_si_delete_confirmation_content")}</>),
                        title: t("h_s_si_delete_confirmation_title")
                    }}
                />
            ) : undefined}

            <div className={styles.DestinationTitle}>
                <div className={styles.MagnifyingGlassIcon}>
                    <img src={search_icon} alt="search" className={styles.SearchIcon} />
                </div>

                <h4>{location}</h4>
            </div>

            <dl className={styles.DateInformation}>
                <dt>{t("h_s_si_from")}:</dt>
                <dd>{fromDate}</dd>

                {/*<dt><img src={moon_icon} alt="moon" className={styles.MoonIcon} /></dt>*/}
                {/*<dd>{nightString}</dd>*/}

                <dt>{t("h_s_si_to")}:</dt>
                <dd>{endDate}</dd>
            </dl>

            <dl className={styles.ClientsInformation}>
                <dt>{t("h_s_si_clients")}:</dt>
                <dd>{clientString}</dd>

                <dt>{t("h_s_si_client_nationality")}:</dt>
                <dd>{clientNationality}</dd>

                {/*<dt>{t("h_s_si_room_type")}:</dt>*/}
                {/*<dd>-</dd>*/}
            </dl>

            {/*<dl className={styles.HotelSearchInformation}>*/}
            {/*    <dt>{t("h_s_si_board_type")}:</dt>*/}
            {/*    <dd>-</dd>*/}

            {/*    <dt>{t("h_s_si_category")}:</dt>*/}
            {/*    <dd>-</dd>*/}

            {/*    <dt>{t("h_s_si_features")}:</dt>*/}
            {/*    <dd>-</dd>*/}
            {/*</dl>*/}

            <div className={styles.RowControlPanel}>
                <a onClick={() => startSearchFromData(criteria, destination)} className={styles.SeeResults}>{t("h_s_si_see_results")}</a>

                <a onClick={() => setConfirmationVisible(true)}>
                    <img src={delete_icon} alt="moon" className={styles.DeleteIcon} />
                    {t("h_s_si_delete")}
                </a>
            </div>
        </div>
    );
};

export default SavedItem;
