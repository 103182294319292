import React, {ReactElement, useState} from "react";
import {animateScroll as scroll} from "react-scroll";
import {useTranslation} from "react-i18next";
import CropImage from "./CropImage";
import ImageInput from "./ImageInput";
import ConfirmationModal from "../ConfirmationModal";
import styles from "./ImageInput.module.scss";

type Props = {
    className: string;
    aspect: number;
    currentImageSrc?: string;
    onConfirm: (file: FormData) => void;
    onDelete: () => void;
    spinner: boolean;
};

function ImageInputWithCrop({
    className,
    aspect,
    currentImageSrc,
    onConfirm,
    onDelete,
    spinner
}: Props): ReactElement {
    const {t} = useTranslation();

    const [placeholderImgBlob, setPlaceholderImgBlob] = useState<Blob | undefined>(undefined);
    const [placeholderImg, setPlaceholderImg] = useState(currentImageSrc);
    const [src, setSrc] = useState<string | undefined>(undefined);
    const [fileName, setFileName] = useState<string | undefined>(undefined);
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [deleteModalOpen, setDeleteModalOpen] = useState(false);

    const imageCropModal = {
        onConfirm: () => {
            setModalIsOpen(false);

            if (placeholderImgBlob) {
                const file = new FormData();
                file.append("file", placeholderImgBlob, fileName);

                onConfirm(file);
                setPlaceholderImgBlob(undefined);
            }

            scroll.scrollToTop({
                smooth: true
            });
        },
        onDecline: () => {
            setModalIsOpen(false);
            setPlaceholderImg(currentImageSrc);
            setPlaceholderImgBlob(undefined);
            scroll.scrollToTop({
                smooth: true
            });
        },
        config: {
            variants: {
                type: "normal"
            },
            title: t("iiwc_iiwc_adjust_photo"),
            content: (
                <CropImage
                    src={src || ""}
                    fileName={fileName}
                    setCroppedImageUrl={setPlaceholderImg}
                    setCroppedImgBlob={setPlaceholderImgBlob}
                    aspect={aspect}
                />
            ),
            confirmButtonText: t("iiwc_iiwc_save_changes"),
            declineButtonText: t("iiwc_iiwc_cancel"),
            spinner: spinner
        }
    };

    const onSelectFile = (selectedFiles: File[]) => {
        if (selectedFiles && selectedFiles.length > 0) {
            const file = selectedFiles[0];
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onloadend = () => {
                if (reader.result !== null) {
                    setSrc(reader.result as string);
                    setFileName(file.name);
                    setModalIsOpen(true);
                }
            };
        }
    };

    return (
        <>
            {(modalIsOpen || spinner) && <ConfirmationModal {...imageCropModal} />}

            {deleteModalOpen && (
                <ConfirmationModal
                    onConfirm={() => {
                        onDelete();
                        setPlaceholderImg(undefined);
                        setDeleteModalOpen(false);
                    }}
                    onDecline={() => {
                        setDeleteModalOpen(false);
                    }}
                    config={{
                        variants: {
                            type: "normal"
                        },
                        title: t("iiwc_iiwc_confirm_cancel_all_title"),
                        content: (
                            <>{t("iiwc_iiwc_confirm_cancel_all_text")}</>
                        ),
                        confirmButtonText: t("iiwc_iiwc_save_changes"),
                        declineButtonText: t("iiwc_iiwc_cancel"),
                        spinner: spinner
                    }}
                />
            )}

            <ImageInput
                className={className}
                onSelectFile={onSelectFile}
                placeholderImg={placeholderImg}
            />

            {placeholderImg && (
                <div
                    onClick={() => {
                        setDeleteModalOpen(true);
                    }}
                    className={styles.remove}
                >
                    {t("iiwc_iiwc_remove")}
                </div>
            )}
        </>
    );
}

export default ImageInputWithCrop;
