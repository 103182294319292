import React, {ReactElement, useMemo} from "react"
import getSpecialRequestText from "utils/getSpecialRequestText"
import {useTranslation} from "react-i18next"
import DatesRangeBox from "components/common/DatesRangeBox"
import BookingRoomSidebar from "components/common/BookingRoomSidebar"
import Edit from "components/common/Edit"
import OpenableBox from "components/common/OpenableBox"
import Pricing from "components/common/Pricing"
import styles from "./PaymentSummary.module.scss"
import ContactPersonBox from "components/common/ContactPersonBox"
import BillingDetailsBox from "components/common/BillingDetailsBox"
import MemoBox from "components/common/MemoBox"
import getHotelAddress from "utils/getHotelAddress"
import router from "views/router/router"
import createLuxonDate from "utils/date/createLuxonDate"
import {useAppSelector} from "redux/hooks"
import {hotelSelector} from "redux/selectors/hotelSearchResults.selector"
import usePaymentOptions from "components/base/Payment/PaymentType/usePaymentOptions"
import {useCurrency} from "components/utils/withLocalesAndCurrencies"

export default function PaymentSummary(): ReactElement {
    const {t} = useTranslation();

    const currency = useCurrency();

    const bookingData = useAppSelector((state) => state.hotelBooking.data);
    const hotelAvailabilityCriteria = useAppSelector((state) => state.hotelBooking.data?.hotelAvailabilityCriteria);
    const hotelBookingRooms = useAppSelector((state) => state.hotelBooking.data?.hotelBookingRooms);
    const hotel = useAppSelector(
        (state) => state.hotelBooking.data?.hotelAvailabilityCriteria &&
            hotelSelector()(state.hotelSearchResults, state.hotelBooking.data.hotelAvailabilityCriteria.hotelId)
    );

    const customInvoice = useAppSelector((state) => state.payment.customInvoice);
    const invoiceRecipient = useAppSelector((state) => state.payment.invoiceRecipient);

    const {
        paymentOptions,
        markup,
        discount,
        voucherSum
    } = usePaymentOptions();

    const {
        checkIn,
        checkOut
    } = hotelAvailabilityCriteria || {
        checkIn: createLuxonDate()
            .toFormat("yyyy-MM-dd"),
        checkOut: createLuxonDate()
            .toFormat("yyyy-MM-dd")
    }

    const nightsCalculated = useMemo(
        () => Math.floor(createLuxonDate(checkOut).diff(createLuxonDate(checkIn), "days").days)
        , [checkIn, checkOut]);

    if (!bookingData || !hotel || !paymentOptions) {
        return (
            <></>
        );
    }

    const {
        specialRequests,
        hotelBookingRoomInfo
    } = bookingData.hotelBookingInfo || {};

    const {
        memo,
        contactInfo
    } = bookingData.bookingInfo || {};

    const {
        displaySalePrice = 0,
        salePrice,
        paymentPending
    } = paymentOptions || {}

    const {
        name,
        address
    } = hotel

    return (
        <div className={styles.Root}>
            <h3 className={styles.Title}>
                {t("h_p_ps_booking_summary")}

                <Edit
                    onClick={() => router.navigate("/hotels/booking")}
                    what={t("h_p_ps_edit_booking_button")}
                    style={{
                        fontSize: "14px",
                        fontWeight: 600,
                        lineHeight: "22px"
                    }}
                />
            </h3>

            <div className={styles.Name}>{name}</div>

            <div className={styles.Address}>
                {getHotelAddress(address)}
            </div>

            <DatesRangeBox
                checkIn={checkIn}
                checkOut={checkOut}
                nights={nightsCalculated}
            />

            {hotelBookingRooms?.map(
                (
                    {
                        roomNo = 0,
                        displaySalePrice = 0,
                        hotelstonRoomTypeName = "",
                        exactRoomTypeName = "",
                        boardTypeName = ""
                    },
                    i: number
                ) => (
                    <BookingRoomSidebar
                        key={i}
                        roomNo={roomNo}
                        price={displaySalePrice * markup}
                        currencyName={currency}
                        exactRoomType={exactRoomTypeName}
                        roomType={hotelstonRoomTypeName}
                        boardType={boardTypeName}
                        childrenData={
                            hotelBookingRoomInfo &&
                            hotelBookingRoomInfo[roomNo].children
                        }
                        adultsData={
                            hotelBookingRoomInfo &&
                            hotelBookingRoomInfo[roomNo].adults
                        }
                        displayChildren={false}
                    />
                )
            )}

            {contactInfo && (
                <ContactPersonBox
                    contactInfo={contactInfo}
                />
            )}

            {customInvoice && invoiceRecipient && (
                <BillingDetailsBox
                    invoiceRecipient={invoiceRecipient}
                    customInvoice={customInvoice}
                />
            )}

            <OpenableBox
                title={t("h_p_ps_special_request")}
                className={styles.SpecialRequests}
                withBorder
            >
                {getSpecialRequestText(specialRequests)
                    ?.map(
                        (specialReqText, i) => (
                            <p key={i}>{specialReqText}</p>
                        )
                    )}
            </OpenableBox>

            <MemoBox memo={memo}/>

            <div
                style={{
                    marginTop: 30,
                    marginBottom: 30
                }}
                className="h-divider"
            />

            <Pricing
                greyLabels
                displaySalePrice={displaySalePrice}
                salePrice={salePrice}
                discount={discount}
                voucher={voucherSum?.used}
                paymentPending={paymentPending}
            />
        </div>
    )
}
