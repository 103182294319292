import React, {ReactElement, useCallback, useMemo} from "react"
import {useAppDispatch, useAppSelector} from "redux/hooks"
import {useCurrency} from "components/utils/withLocalesAndCurrencies"
import {
    handleSplitPricePartSizeInput,
    requestSplitPriceOptions,
    selectSplitPricePart
} from "redux/actions/payment.actions"
import {WebSplitPricePart, WebSplitPricePartStatus} from "proptypes/PropTypeObjects"
import styles from "./SplitPaymentContent.module.scss"
import Input from "components/base/Input"
import UserButton from "components/base/UserButton"
import {UserButtonVariant} from "components/base/UserButton/UserButton"
import Spinner from "components/base/Loaders/Spinner"
import ResponsiveTable from "components/base/ResponsiveTable/ResponsiveTable"
import {getPriceWithCurrencySymbol} from "@hotelston_web_frontend_utils/currency/getPriceWithCurrency"
import {TableHeading, TableRow} from "components/base/ResponsiveTable/ResponsiveTable.base"
import {useNavigate} from "react-router-dom"
import InfoBox from "components/common/InfoBox"
import Dots from "components/base/Loaders/Dots/Dots"
import PaymentProviderRedirect from "components/common/PaymentProviderRedirect/PaymentProviderRedirect";
import RedirectingModal from "components/common/Modal/RedirectingModal";
import usePaymentOptions from "../../../../base/Payment/PaymentType/usePaymentOptions";
import SplitPricePricing from "./SplitPricePricing/SplitPricePricing";
import Currency from "@hotelston_web_frontend_parent/constants/currency";
import {useTranslation} from "react-i18next";

const SplitPaymentContent = (): ReactElement => {
    const {t} = useTranslation();

    const dispatch = useAppDispatch();
    const currency = useCurrency();
    const navigate = useNavigate();

    const splitPricePartSize = useAppSelector((state) => state.payment.splitPricePartSize);
    const errors = useAppSelector((state) => state.payment.errors);

    const splitPriceParts = useAppSelector((state) => state.payment.splitPriceParts);
    const resumedSession = useAppSelector((state) => state.payment.resumedSplitPriceSession);

    const loading = useAppSelector((state) => state.payment.loading);
    const splitPriceSessionInvalid = useAppSelector((state) => state.payment.splitPriceSessionInvalid);
    const splitPriceAllBookingsPaid = useAppSelector((state) => state.payment.splitPriceAllBookingsPaid || false);
    const splitPriceBookings = useAppSelector((state) => state.payment.splitPriceBookings);
    const providerPayment = useAppSelector((state) => state.payment.providerPayment);

    const {
        paymentOptions
    } = usePaymentOptions();

    const allPartsPaid = useMemo<boolean>(() => splitPriceParts?.every((part) => part.status === WebSplitPricePartStatus.SUCCESS || part.status === WebSplitPricePartStatus.WAITING_FOR_MONEY), [splitPriceParts]);

    const bookingReferences = useMemo<string>(() => {
        if (!splitPriceBookings) {
            return "";
        }

        if (splitPriceBookings.length === 1) {
            return splitPriceBookings[0].reference;
        }

        return splitPriceBookings.map((booking) => booking.reference).join(", ");
    }, [splitPriceBookings]);

    const totalTransactionFees = useMemo<number>(() => {
        if (!splitPriceParts) {
            return 0;
        }

        return splitPriceParts.map((part) => part.transactionFee)
            .reduce((a, b) => a + b);
    }, [splitPriceParts])

    const alreadyPaidAmount = useMemo<number>(() => {
        if (!splitPriceParts) {
            return 0;
        }

        return splitPriceParts.filter((part) => part.status === WebSplitPricePartStatus.SUCCESS || part.status === WebSplitPricePartStatus.WAITING_FOR_MONEY)
            .map((part) => part.amount + part.transactionFee)
            .reduce((prev, curr) => prev + curr, 0);
    }, [splitPriceParts]);

    const remainingAmount = useMemo<number>(() => {
        if (!paymentOptions) {
            return 0;
        }

        return (paymentOptions.originalAmount + totalTransactionFees) - alreadyPaidAmount;
    }, [paymentOptions, totalTransactionFees, alreadyPaidAmount]);

    const requestOptionsCallback = useCallback((evt: React.FormEvent<HTMLFormElement>) => {
        // evt.stopPropagation();
        evt.preventDefault()

        dispatch(requestSplitPriceOptions())
    }, [dispatch])

    const selectPartCallback = useCallback((part: WebSplitPricePart) => {
        if (!splitPriceParts) {
            return
        }

        dispatch(selectSplitPricePart(part))
    }, [dispatch, splitPriceParts])

    const goToBookingsCallback = useCallback(() => {
        if (!splitPriceBookings) {
            navigate("/my-bookings")
            return
        }

        if (splitPriceBookings.length === 1) {
            navigate(`/my-bookings/booking/${splitPriceBookings[0].id}`)
        } else {
            navigate(`/my-bookings?${splitPriceBookings.map((booking, index) => `b${index}=${booking.id}`).join("&")}`)
        }
    }, [navigate, splitPriceBookings])

    const inputValidatorCallback = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        if (splitPricePartSize) {
            if (splitPricePartSize < paymentOptions.splitPricePartMinAmount) {
                e.target.setCustomValidity(t("err_split_payment_amount_low", {amount: getPriceWithCurrencySymbol(paymentOptions.splitPricePartMinAmount, Currency.EUR)}))
            }

            if (splitPricePartSize > paymentOptions.splitPricePartMaxAmount) {
                e.target.setCustomValidity(t("err_split_payment_amount_high", {amount: getPriceWithCurrencySymbol(paymentOptions.splitPricePartMaxAmount, Currency.EUR)}))
            }
        }
    }, [paymentOptions?.splitPricePartMaxAmount, paymentOptions?.splitPricePartMinAmount, splitPricePartSize, t])

    const buildPartPayLink = useCallback((part: WebSplitPricePart) => {
        if (part.status === WebSplitPricePartStatus.IN_PROGRESS) {
            return (
                <div className={styles.PartLoadingContainer}>
                    <a>{t("pc_pc_split_payment_part_status_in_progress")}</a>

                    <Dots/>
                </div>
            )
        }

        if (part.status === WebSplitPricePartStatus.WAITING_FOR_MONEY) {
            return (
                <div className={styles.PartLoadingContainer}>
                    <a>{t("pc_pc_split_payment_part_status_waiting_for_money")}</a>

                    <Dots/>
                </div>
            )
        }

        if (part.status === WebSplitPricePartStatus.SUCCESS) {
            return (
                <a className={styles.PayDisabled}>
                    {t("pc_pc_split_payment_part_status_money_received")}
                </a>
            )
        }

        return (
            <a
                className={styles.PayPartialPayment}
                onClick={() => selectPartCallback(part)}
            >
                {t("pc_pc_split_payment_part_status_new")}
            </a>
        )
    }, [selectPartCallback, t])

    const splitPriceHeaders = useMemo(() => [
        {
            element: (<>{t("pc_pc_split_payment_partial_header_name")}</>),
            label: t("pc_pc_split_payment_partial_header_name")
        },
        {
            label: t("pc_pc_split_payment_partial_header_sum"),
            element: (<>{t("pc_pc_split_payment_partial_header_sum")}</>)
        },
        {
            label: t("pc_pc_split_payment_partial_header_status"),
            element: (<>{t("pc_pc_split_payment_partial_header_status")}</>)
        }
    ] as TableHeading[], [t])

    const splitPricePartRows = useMemo(() => {
        if (!splitPriceParts) {
            return undefined
        }

        return splitPriceParts.sort((a, b) => a.order - b.order)
            .map((part) => ({
                // variant: part.paid ? part.awaitingPayment ? "Loading" : "Blocked" : "Normal",
                // variant: part.status === WebSplitPricePartStatus.IN_PROGRESS ? "Loading" : part.status === WebSplitPricePartStatus.SUCCESS ? "Success" : part.status === WebSplitPricePartStatus.FAILURE ? "Blocked" : "Normal",
                variant: part.status === WebSplitPricePartStatus.SUCCESS ? "Success" : part.status === WebSplitPricePartStatus.FAILURE ? "Blocked" : "Normal",
                actionable: false,
                tDatas: [
                    {
                        element: (<>{t("pc_pc_split_payment_partial_row_name", {number: part.order + 1})}</>)
                    },
                    {
                        element: (<>{getPriceWithCurrencySymbol(part.amount + part.transactionFee, currency)}</>)
                    },
                    {
                        element: buildPartPayLink(part)
                    }
                ]
            }) as TableRow)
    }, [buildPartPayLink, currency, splitPriceParts, t])

    return (
        <div className={styles.Root}>
            <h1>{t("pc_pc_split_payment_header")}</h1>

            {providerPayment && (
                <PaymentProviderRedirect {...providerPayment}>
                    <RedirectingModal/>
                </PaymentProviderRedirect>
            )}

            {errors && (
                <InfoBox
                    className={styles.ErrorMessageContainer}
                    type="error"
                    title=""
                    message={(
                        <>{errors?.join(", ")}</>
                    )}
                />
            )}

            {!splitPriceSessionInvalid && !resumedSession && !loading && (
                <form
                    id="split-payment-form"
                    name="split-payment-form"
                    onSubmit={requestOptionsCallback}
                    className={styles.MainContainer}
                >
                    <p>
                        {t("pc_pc_split_payment_content_introduction")}
                    </p>

                    <h4>{t("pc_pc_split_payment_content_pricing_header")}</h4>

                    <SplitPricePricing/>

                    {/*<Pricing*/}
                    {/*    greyLabels*/}
                    {/*    smsFee={paymentOptions.paymentOptions?.smsFee}*/}
                    {/*    displaySmsFee={paymentOptions.paymentOptions?.displaySmsFee}*/}
                    {/*    salePrice={paymentOptions.paymentOptions?.salePrice || 0}*/}
                    {/*    displaySalePrice={paymentOptions.paymentOptions?.displaySalePrice || 0}*/}
                    {/*    discount={0}*/}
                    {/*    voucher={0}*/}
                    {/*    paymentPending={paymentOptions.paymentOptions?.paymentPending}*/}
                    {/*/>*/}

                    <p>
                        {t("pc_pc_split_payment_content_input_message")}
                    </p>

                    <div className={styles.MaxPriceInputContainer}>
                        <Input
                            className={styles.MaxPriceInput}
                            validator={inputValidatorCallback}
                            inputProps={{
                                type: "number",
                                placeholder: t("pc_pc_split_payment_content_input_placeholder"),
                                name: "maxPriceInput",
                                value: splitPricePartSize,
                                min: paymentOptions.splitPricePartMinAmount,
                                max: paymentOptions.splitPricePartMaxAmount,
                                onChange: (evt) => {
                                    dispatch(handleSplitPricePartSizeInput(+evt.target.value))
                                },
                                required: true
                            }}
                            variants={{
                                labelPosition: "outlined"
                            }}
                            helperText={t("pc_pc_split_payment_content_input_helper", {
                                minAmount: getPriceWithCurrencySymbol(paymentOptions.splitPricePartMinAmount, Currency.EUR),
                                maxAmount: getPriceWithCurrencySymbol(paymentOptions.splitPricePartMaxAmount, Currency.EUR)
                            })}
                        />

                        <UserButton
                            className={styles.GetOptionsButton}
                            text={t("pc_pc_split_payment_content_button_get_options")}
                            variant={UserButtonVariant.PRIMARY}
                            buttonProps={{
                                type: "submit"
                            }}
                        />
                    </div>
                </form>
            )}

            {splitPriceSessionInvalid && !splitPriceAllBookingsPaid && (
                <p>{t("pc_pc_split_payment_invalid")}</p>
            )}

            {splitPriceSessionInvalid && splitPriceAllBookingsPaid && (
                <>
                    <p>
                        {
                            splitPriceBookings.length === 1
                                ? t("pc_pc_split_payment_all_booking_paid_single", {booking: bookingReferences})
                                : t("pc_pc_split_payment_all_booking_paid_multi", {bookings: bookingReferences})
                        }
                    </p>

                    <div className={styles.BottomActions}>
                        <UserButton
                            text={splitPriceBookings.length === 1 ? t("pc_pc_split_payment_complete_go_to_booking") : t("pc_pc_split_payment_complete_go_to_bookings")}
                            variant={UserButtonVariant.PRIMARY}
                            buttonProps={{
                                onClick: goToBookingsCallback
                            }}
                        />
                    </div>
                </>
            )}

            {loading && (!splitPriceParts || !allPartsPaid) && (
                <Spinner size={50}/>
            )}

            {(splitPriceSessionInvalid === undefined || !splitPriceSessionInvalid) && splitPriceParts && (
                <>
                    {allPartsPaid && (
                        <p>
                            {
                                splitPriceBookings.length > 1
                                    ? t("pc_pc_split_payment_success_message_multi", {
                                        amount: getPriceWithCurrencySymbol(alreadyPaidAmount, Currency.EUR),
                                        bookings: bookingReferences
                                    })
                                    : t("pc_pc_split_payment_success_message_single", {
                                        amount: getPriceWithCurrencySymbol(alreadyPaidAmount, Currency.EUR),
                                        booking: bookingReferences
                                    })
                            }
                        </p>
                    )}

                    {!allPartsPaid && !loading && (
                        <>
                            {resumedSession ? (
                                <form
                                    id="split-payment-form"
                                    name="split-payment-form"
                                    onSubmit={requestOptionsCallback}
                                    className={styles.MainContainer}
                                >
                                    <p style={{marginBottom: "20px"}}>
                                        {splitPriceBookings.length > 1 ? t("pc_pc_split_payment_content_ongoing_text_multi", {
                                            bookings: bookingReferences,
                                            amount: getPriceWithCurrencySymbol(paymentOptions.originalAmount, Currency.EUR),
                                            partSize: getPriceWithCurrencySymbol(paymentOptions.originalSplitPartSize, Currency.EUR),
                                            paidAmount: getPriceWithCurrencySymbol(alreadyPaidAmount, Currency.EUR),
                                            remainingAmount: getPriceWithCurrencySymbol(remainingAmount, Currency.EUR)
                                        }) : t("pc_pc_split_payment_content_ongoing_text_single", {
                                            booking: bookingReferences,
                                            amount: getPriceWithCurrencySymbol(paymentOptions.originalAmount, Currency.EUR),
                                            partSize: getPriceWithCurrencySymbol(paymentOptions.originalSplitPartSize, Currency.EUR),
                                            paidAmount: getPriceWithCurrencySymbol(alreadyPaidAmount, Currency.EUR),
                                            remainingAmount: getPriceWithCurrencySymbol(remainingAmount, Currency.EUR)
                                        })}
                                    </p>

                                    <h4>{t("pc_pc_split_payment_content_pricing_header")}</h4>

                                    <SplitPricePricing/>

                                    <p>
                                        {t("pc_pc_split_payment_content_ongoing_remaining")}
                                    </p>
                                </form>
                            ) : (
                                <p>
                                    {t("pc_pc_split_payment_content_ongoing_remaining")}
                                </p>
                            )}
                        </>
                    )}

                    {!loading && (
                        <ResponsiveTable
                            className={styles.SplitPriceOptions}
                            loading={loading}
                            firstColumnSticky={false}
                            autoSize={true}
                            tHeadings={splitPriceHeaders}
                            tRows={splitPricePartRows}
                        />
                    )}

                    {allPartsPaid && (
                        <div className={styles.BottomActions}>
                            <UserButton
                                text={splitPriceBookings.length === 1 ? t("pc_pc_split_payment_complete_go_to_booking") : t("pc_pc_split_payment_complete_go_to_bookings")}
                                variant={UserButtonVariant.PRIMARY}
                                buttonProps={{
                                    onClick: goToBookingsCallback
                                }}
                            />
                        </div>
                    )}
                </>
            )}
        </div>
    )
}

export default SplitPaymentContent