import {useTranslation} from "react-i18next";
import React, {ReactElement} from "react";
import cx from "classnames";
import styles from "./AgreementStatusHeader.module.scss";
import {DocumentStatus} from "../../../../../proptypes/PropTypeObjects";
import {ReactComponent as CheckIcon} from "../../../../../assets/icons/check.svg";
import {ReactComponent as SignIcon} from "../../../../../assets/icons/sign.svg";
import {ReactComponent as TimerIcon} from "../../../../../assets/icons/timer.svg";

export type AgreementStatusHeaderProps = {
    status: DocumentStatus;
    statusText: string;
};

const AgreementStatusHeader = ({
    status,
    statusText
}: AgreementStatusHeaderProps): ReactElement => {
    const {t} = useTranslation();

    let agreementStatusContent: ReactElement;
    switch (status) {
    case "SIGNATURE_CONFIRMED":
        agreementStatusContent = (
            <div className={styles.Wrapper}>
                <div className={cx(styles.Icon, styles.Success)}>
                    <CheckIcon />
                </div>

                <p className={styles.LabelGreen}>{statusText}</p>
            </div>
        );
        break;
    case "NOT_SIGNED":
    case "SIGNATURE_REJECTED":
        agreementStatusContent = (
            <div className={styles.Wrapper}>
                <div className={styles.Icon}>
                    <SignIcon />
                </div>

                <p className={styles.LabelRed}>{statusText}</p>
            </div>
        );
        break;
    case "SIGNATURE_NOT_VERIFIED":
        agreementStatusContent = (
            <div className={styles.Wrapper}>
                <div className={styles.Icon}>
                    <TimerIcon />
                </div>

                <p className={styles.LabelRed}>{statusText}</p>
            </div>
        );
        break;
    default:
        agreementStatusContent = <></>;
        break;
    }

    return (
        <div className={styles.Root}>
            {agreementStatusContent}
        </div>
    );
};

export default AgreementStatusHeader;