import React, {ReactElement, ReactNode} from "react";
import {useTranslation} from "react-i18next";
import styles from "./AcceptBookingChanges.module.scss";
import UserButton from "../../../components/base/UserButton";
import {UserButtonVariant} from "../../../components/base/UserButton/UserButton";

type Props = {
    children: ReactNode;
    acceptAndBook: () => void
};

function AcceptBookingChanges({
    acceptAndBook,
    children
}: Props): ReactElement {
    const {t} = useTranslation();

    return (
        <div className={styles.Root}>
            {children && (
                <div className={styles.TotalBox}>
                    {children}
                </div>
            )}

            <UserButton
                text={t("h_bc_abc_accept_and_book")}
                variant={UserButtonVariant.PRIMARY}
                buttonProps={{
                    onClick: acceptAndBook,
                    type: "button",
                    style: {
                        height: "50px",
                        width: "160px",
                        marginLeft: "auto",
                        alignSelf: "flex-end"
                    }
                }}
            />
        </div>
    );
}

export default AcceptBookingChanges;
