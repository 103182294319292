/* polyfills.js */
import "react-app-polyfill/ie11";
import "react-app-polyfill/ie9";
import "react-app-polyfill/jsdom";
import "core-js/full";
import "unorm";
import "url-search-params-polyfill";
import "utils/canvas-toBlob";
import React from "react";
import "./i18n";
import "normalize.css";
import "bootstrap/dist/css/bootstrap-grid.min.css";
import "./styles/main.scss";
import "./assets/fonts/font.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "styles/_react-toggle-component.scss";
import "os-browserify";
import "react-image-crop/src/ReactCrop.scss";
import * as serviceWorker from "serviceWorker";
import {Provider} from "react-redux";
import store from "./redux/store/store.init";
import {createRoot} from "react-dom/client";
import App from "views/App";
import Cookies from "js-cookie";
import {DEFAULT_WEB_VERSION_COOKIE_NAME} from "./utils/cookie/cookie"
import createLuxonDate from "./utils/date/createLuxonDate"

// getUTC methods corejs polyfill breaks the functionality on firefox, restore using iframe
const iframe = document.createElement("iframe");
document.documentElement.appendChild(iframe);
const _window = iframe.contentWindow;
if (_window) {
    Date.prototype.getUTCFullYear = (_window as typeof window).Date.prototype.getUTCFullYear;
    Date.prototype.getUTCMonth = (_window as typeof window).Date.prototype.getUTCMonth;
    Date.prototype.getUTCDate = (_window as typeof window).Date.prototype.getUTCDate;
    Date.prototype.getUTCDay = (_window as typeof window).Date.prototype.getUTCDay;
    Date.prototype.getUTCHours = (_window as typeof window).Date.prototype.getUTCHours;
    Date.prototype.getUTCMinutes = (_window as typeof window).Date.prototype.getUTCMinutes;
    Date.prototype.getUTCSeconds = (_window as typeof window).Date.prototype.getUTCSeconds;
    Date.prototype.getUTCMilliseconds = (_window as typeof window).Date.prototype.getUTCMilliseconds;
}
document.documentElement.removeChild(iframe);

Cookies.set(DEFAULT_WEB_VERSION_COOKIE_NAME, "2", {
    expires: createLuxonDate().plus({day: 30}).toJSDate(),
    domain: "hotelston.com"
});

// @ts-ignore
window.initMap = function() {};

const rootEl = document.getElementById("root");
const root = createRoot(rootEl!);

root.render(
    <Provider store={store}>
        <App />
    </Provider>
);

if ((module as any).hot) {
    (module as any).hot.accept("./views/App", () => {
        const NextApp = require("./views/App").default;

        const root = createRoot(rootEl!);
        root.render(
            <Provider store={store}>
                <NextApp />
            </Provider>
        );
        /* eslint-enable */
    });
}
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();