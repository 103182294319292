export type Observer<T> = (payload: T | null) => void;

export default class Subject<T> {
    private observers: Observer<T>[] = [];

    private queued = false;

    private queuedPayload: T | null = null;

    constructor(observer?: Observer<T>) {
        if (observer) {
            this.observers.push(observer);
        }
    }

    public async next(payload: T | null, queueIfEmpty = false): Promise<boolean> {
        if (queueIfEmpty && this.observers.length === 0) {
            this.queued = true;
            this.queuedPayload = payload;

            return new Promise(() => {});
        }

        return new Promise(() => this.observers.forEach((observer) => observer && observer(payload)));
    }

    public subscribe(observer: Observer<T>): void {
        if (!observer) {
            return;
        }

        this.observers.push(observer);

        if (this.queued) {
            this.queued = false;
            void this.next(this.queuedPayload);
            this.queuedPayload = null;
        }
    }

    public unsubscribe(observer: Observer<T>): void {
        if (!observer) {
            return;
        }

        this.observers = this.observers.splice(this.observers.indexOf(observer, 0), 1);
    }
}