import React, {
    memo,
    useCallback, useContext,
    useEffect,
    useState
} from "react";
import useKeyDetect from "components/utils/useKeyDetect";
import SlideIn, {SlideInSideEnum} from "components/utils/Transitions/SlideIn";
import Tippy from "components/common/Tippy";
import cx from "classnames";
import {Trans, useTranslation} from "react-i18next";
import {PinnedOffersContext, useOffersRequest, usePinnedOffersGetPdfAction} from "components/utils/withPinnedOffers";
import createBlobAndDownload from "utils/createBlobAndDownload";
import {Route, Routes} from "react-router-dom";
import {scroller} from "react-scroll";
import EmailSent from "./SendEmailModal/EmailSent";
import SendEmailModal from "./SendEmailModal";
import PinnedOffersPreviewModal from "./PinnedOffersPreviewModal";
import styles from "./PinnedOffers.module.scss";
import {PinnedOffer, ReduxModalType} from "../../../proptypes/PropTypeObjects"
import ActionIcon, {ActionIconType} from "../ActionIcon/ActionIcon";
import Spinner from "../../base/Loaders/Spinner";
import ConfirmationModal from "../ConfirmationModal";
import useSelectedMarkup from "../../../utils/hooks/useSelectedMarkup";

type Props = {
    innerModal?: boolean;
    pinnedOffers: PinnedOffer[];
    toggleSlideIn: () => void;
    slide?: boolean;
    slideDuration?: number;
    resetPinnedOffers?: () => void;
};

function PinnedOffers({
    pinnedOffers,
    resetPinnedOffers,
    toggleSlideIn,
    slide,
    slideDuration,
    innerModal = false
}: Props) {
    const {t} = useTranslation();
    const {isNETPrice} = useSelectedMarkup();

    const [modals, setModals] = useState<string[]>([]);
    const [exporting, setExporting] = useState<boolean>(false);
    const [exportPDFModalVisible, setExportPDFModalVisible] = useState<boolean>(false);

    const closeModal = useCallback((name: string) => setModals(modals.filter((mN) => mN !== name)), [modals]);
    const openModal = useCallback((name: string) => setModals([...modals, name]), [modals]);

    const {formElementToObserve} = useContext(PinnedOffersContext);

    useKeyDetect(27, modals.length ? null : resetPinnedOffers);

    const {
        requestBody, senderEmail, subject, destination
    } = useOffersRequest();

    const getPdf = usePinnedOffersGetPdfAction((pdfFile) => createBlobAndDownload(
        pdfFile,
        "application/pdf",
        subject + ".pdf",
        true
    ));

    useEffect(() => {
        toggleSlideIn();
    }, []);

    const jumpToRoomOffersCallback = useCallback(() => {
        if (formElementToObserve) {
            scroller.scrollTo(formElementToObserve.id, {smooth: true, offset: -300, delay: 25});
        }
    }, [formElementToObserve]);

    const closePreviewOnClick = useCallback(() => closeModal("preview"), [closeModal]);
    const sendEmailOnBack = useCallback(() => closeModal("sendEmail"), [closeModal]);
    const sendEmailOnSent = useCallback(() => openModal("emailSent"), [openModal]);
    const onEmailSent = useCallback(() => setModals([]), []);
    const previewOnClick = useCallback(() => openModal("preview"), [openModal]);
    const sendEmailOnClick = useCallback(() => openModal("sendEmail"), [openModal]);

    const exportPdfOnClick = useCallback((withPrice: boolean) => {
        setExportPDFModalVisible(false);
        setExporting(true);

        getPdf(requestBody, withPrice).then(() => {
            setExporting(false);
        }).catch(() => {
            setExporting(false);
        });
    }, [getPdf, requestBody]);

    const onExportPDFStart = useCallback(() => {
        if (!isNETPrice) {
            setExportPDFModalVisible(true);
        } else {
            exportPdfOnClick(false);
        }
    }, [exportPdfOnClick, isNETPrice]);

    return (
        <div className={cx(styles.Root, innerModal && styles.InnerModal)}>
            {exportPDFModalVisible && (
                <ConfirmationModal
                    onConfirm={() => exportPdfOnClick(true)}
                    onDecline={() => exportPdfOnClick(false)}
                    config={{
                        variants: {
                            type: "normal",
                            innerCloseButton: true
                        },
                        title: t("pin_po_export_include_price_header"),
                        content: (
                            <>{t("pin_po_export_include_price_explanation")}</>
                        ),
                        confirmButtonText: t("pin_po_export_pdf_with_price_yes"),
                        declineButtonText: t("pin_po_export_pdf_with_price_no")
                    }}
                />
            )}

            {modals.includes("preview") && (
                <PinnedOffersPreviewModal onClose={closePreviewOnClick}/>
            )}

            {modals.includes("sendEmail") && (
                <SendEmailModal
                    requestBody={requestBody}
                    senderEmail={senderEmail}
                    subject={subject}
                    destination={destination}
                    onBack={sendEmailOnBack}
                    onSent={sendEmailOnSent}
                />
            )}

            {modals.includes("emailSent") && (
                <EmailSent
                    onClose={onEmailSent}
                />
            )}

            <SlideIn side={SlideInSideEnum.BOTTOM} inProp={slide} duration={slideDuration}>
                <div className={styles.Content}>
                    {innerModal && (
                        <Tippy
                            content={(
                                <span className={styles.ClearButtonTip}>
                                    {t("pin_po_clear_all_pinned_offers")}
                                </span>
                            )}
                        >
                            <button
                                type="button"
                                onClick={resetPinnedOffers}
                                className={styles.ClearButton}
                            >
                                <ActionIcon type={ActionIconType.CLOSE}/>
                            </button>
                        </Tippy>
                    )}

                    <div className={styles.Title}>
                        <span className={styles.Number}>{pinnedOffers.length}</span>
                        {t("pin_po_pinned_offers_title", {count: pinnedOffers.length})}
                    </div>

                    {innerModal && (
                        <button
                            type="button"
                            onClick={previewOnClick}
                            className={styles.Button}
                        >
                            {t("pin_po_preview")}
                        </button>
                    )}

                    <button
                        type="button"
                        onClick={sendEmailOnClick}
                        className={styles.Button}
                    >
                        {t("pin_po_send_pins_by_email")}
                    </button>

                    <button
                        type="button"
                        onClick={onExportPDFStart}
                        className={cx(styles.Button, exporting && styles.ButtonExporting)}
                    >
                        {t("pin_po_export_pdf")}

                        <Spinner className={styles.ExportPDFSpinner}/>
                    </button>

                    <Routes>
                        <Route
                            path="/hotels/hotel"
                            element={<a
                                className={styles.JumpToRoomOffers}
                                onClick={jumpToRoomOffersCallback}
                            >{t("h_h_h_jump_to_room_offers")}
                            </a>}
                        />
                    </Routes>

                    {innerModal && (
                        <div className={styles.Tip}>
                            <Trans i18nKey="pin_po_esc_deselect_all_notice">
                                or press &quot;
                                <span className={styles.Escape}>esc</span>&quot; to
                                deselect all
                            </Trans>
                        </div>
                    )}
                </div>
            </SlideIn>
        </div>
    );
}

export default memo(PinnedOffers);
