import React from "react";
import cx from "classnames";
import {withCurrencies, WithCurrenciesProps} from "../../../utils/withLocalesAndCurrencies";
import BasicDropdown from "../../Input/BasicDropdown/BasicDropdown";
import styles from "./HamburgerMenuCurrencyDropdown.module.scss";
import Currency from "@hotelston_web_frontend_parent/constants/currency";

const HamburgerMenuCurrencyDropdown = withCurrencies(({
    changeCurrentCurrency,
    currentCurrency,
    currenciesFormed
}: WithCurrenciesProps) => (
    <BasicDropdown<Currency, Currency>
        required={true}
        hideErrors={true}
        onDropdownChange={(value) => changeCurrentCurrency(value)}
        dropDownValue={currentCurrency}
        dropDownOptions={currenciesFormed}
        className={cx(styles.Root)}
    />
), {labelAsValue: true});

export default HamburgerMenuCurrencyDropdown;