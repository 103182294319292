import {HotelBookingTypes} from "../constants/hotelBooking.constants";
import {ReduxHotelBookingPropTypes} from "../../proptypes/PropTypeRedux";
import {HotelAvailabilityCriteriaPropTypes, WebHotelBookingRoomPropTypes} from "../../proptypes/PropTypeObjects";
import HotelBookingActions from "./actions/hotelBooking.reducer.actions";
import {FALLBACK_DATE_FORMAT} from "../../components/utils/Internationalization/formats";
import createLuxonDate from "../../utils/date/createLuxonDate"

export enum HotelBookingRoute {
    OK = "OK",
    CHANGES = "CHANGES",
    NO_AVAILABILITY = "NO_AVAILABILITY",
    DUPLICATE_BOOKINGS = "DUPLICATE_BOOKINGS",
    VALIDATION_ERRORS = "VALIDATION_ERRORS"
}

export const INITIAL_STATE: ReduxHotelBookingPropTypes = {
    data: {
        bookingInfo: undefined,
        hotelAvailabilityCriteria: undefined,
        duplicateBookings: undefined,
        changes: undefined,
        hotelBookingInfo: undefined,
        hotelBookingRooms: [],
        route: HotelBookingRoute.OK
    },
    bookingOptionsParams: undefined,
    savedPassengerDetails: undefined,
    firstTimeChanges: true,
    allowedInBookingPages: false,
    requestingOptions: false,
    requestingBooking: false,
    allowBookSubmit: true,
    requestingValidation: false,
    loadedData: false
};

export default (state = INITIAL_STATE, action: HotelBookingActions): ReduxHotelBookingPropTypes => {
    switch (action.type) {
    case HotelBookingTypes.GET_OPTIONS:
        return {
            ...state,
            bookingOptionsParams: action.bookingOptionsParams,
            data: {
                ...state.data,
                hotelAvailabilityCriteria: action.hotelAvailabilityCriteria
            },
            allowBookSubmit: true,
            allowedInBookingPages: true,
            requestingOptions: true
        };
    case HotelBookingTypes.GET_OPTIONS_SUCCESS: {
        const {hotelBookingRooms} = action.data;
        const hotelAvailabilityCriteriaNew = {
            ...state.data?.hotelAvailabilityCriteria,
            rooms: hotelBookingRooms.map(({
                roomNo,
                cxlDate,
                displayBookingTermsCXL,
                displayBookingTermsRemarks
            }) => ({
                ...state.data?.hotelAvailabilityCriteria?.rooms[roomNo],
                cxlDate,
                displayBookingTermsCXL,
                displayBookingTermsRemarks
            }))
        } as HotelAvailabilityCriteriaPropTypes;
        return {
            ...state,
            data: {
                ...state.data,
                ...action.data,
                hotelBookingInfo: {
                    ...action.data.hotelBookingInfo,
                    clientRequiredCheckInInformation: action.data?.bookingOptions?.canEnterClientRequiredCheckInInformation ? {} : undefined
                },
                bookingInfo: {
                    ...action.data.bookingInfo
                },
                hotelAvailabilityCriteria: hotelAvailabilityCriteriaNew
            },
            loadedData: true,
            requestingOptions: false
        };
    }
    case HotelBookingTypes.GET_OPTIONS_FAILURE:
        return {
            ...state,
            requestingOptions: false,
            loadedData: false,
            errors: action.error
        };
    case HotelBookingTypes.UPDATE_OPTIONS:
        return {
            ...state,
            bookingOptionsParams: action.bookingOptionsParams,
            allowedInBookingPages: true,
            requestingOptions: true
        };
    case HotelBookingTypes.UPDATE_OPTIONS_SUCCESS: {
        const {
            bookingInfo, hotelBookingInfo, hotelBookingRooms, ...restData
        } = action.data;
        const hotelAvailabilityCriteriaNew = {
            ...state.data?.hotelAvailabilityCriteria,
            rooms: hotelBookingRooms.map(({
                roomNo,
                cxlDate,
                displayBookingTermsCXL,
                displayBookingTermsRemarks,
                salePrice,
                displaySalePrice
            }) => ({
                ...state.data?.hotelAvailabilityCriteria?.rooms[roomNo],
                cxlDate,
                displayBookingTermsCXL,
                displayBookingTermsRemarks,
                price: salePrice,
                displayPrice: displaySalePrice
            }))
        } as HotelAvailabilityCriteriaPropTypes;
        return {
            ...state,
            data: {
                ...state.data,
                ...restData,
                bookingInfo: {
                    ...state.data?.bookingInfo
                },
                hotelAvailabilityCriteria: hotelAvailabilityCriteriaNew,
                hotelBookingRooms
            },
            loadedData: true,
            requestingOptions: false
        };
    }
    case HotelBookingTypes.REQUEST_HOTEL_BOOKING:
        return {
            ...state,
            allowBookSubmit: false,
            requestingBooking: true
        };
    case HotelBookingTypes.HOTEL_BOOKING_SUCCESS:
        return {
            ...INITIAL_STATE,
            data: {
                ...INITIAL_STATE.data
            },
            allowedInBookingPages: !!action.data.providerPayment
        };

    case HotelBookingTypes.HOTEL_BOOKING_FAILURE:
        return {
            ...state,
            requestingBooking: false,
            loadedData: false,
            firstTimeChanges: false,
            allowBookSubmit: false
        };
    case HotelBookingTypes.HOTEL_BOOKING_CHANGES:
        return {
            ...state,
            data: {
                ...state.data,
                duplicateBookings: action.data.duplicateBookings,
                changes: action.data.changes,
                route: action.data.route,
                hotelBookingRooms: action.data.hotelBookingRooms ? action.data.hotelBookingRooms : state.data?.hotelBookingRooms
            },
            requestingBooking: false,
            firstTimeChanges: false,
            allowBookSubmit: true
        };
    case HotelBookingTypes.REQUEST_VALIDATE_BOOKING:
        return {
            ...state,
            requestingValidation: true,
            data: {
                ...state.data,
                errors: undefined,
                route: "OK"
            }
        };
    case HotelBookingTypes.VALIDATE_BOOKING_SUCCESS:
        return {
            ...state,
            data: {
                ...state.data,
                ...action.data
            },
            requestingValidation: false
        };

    case HotelBookingTypes.VALIDATE_BOOKING_FAILURE:
        return {
            ...state,
            requestingValidation: false
        };
    case HotelBookingTypes.SAVE_PASSENGER_DETAILS:
        return {
            ...state,
            savedPassengerDetails: state.data?.hotelBookingInfo?.hotelBookingRoomInfo
        };
    case HotelBookingTypes.REFILL_PASSENGER_DETAILS: {
        const {hotelBookingRoomInfo} = state.data.hotelBookingInfo;
        const {savedPassengerDetails} = state;

        return {
            ...state,
            data: {
                ...state.data,
                hotelBookingInfo: {
                    ...state.data?.hotelBookingInfo,
                    hotelBookingRoomInfo: savedPassengerDetails.map(({adults, children}, roomNo) => ({
                        adults,
                        children: children
                            .map(({firstname, lastname}, childIndex) => ({
                                firstname,
                                lastname,
                                age: hotelBookingRoomInfo[roomNo].children[childIndex].age
                            }))
                    }))
                }
            },
            savedPassengerDetails: undefined
        };
    }
    case HotelBookingTypes.CLEAR_BOOKING_DATA: {
        return {
            ...INITIAL_STATE,
            savedPassengerDetails: state.savedPassengerDetails
        };
    }
    case HotelBookingTypes.CLEAR_VALIDATION_DATA:
        return {
            ...state,
            data: {
                ...state.data,
                errors: undefined,
                route: "OK"
            }
        };
    case HotelBookingTypes.ACCEPT_BOOKING_CHANGES: {
        // @ts-expect-error ts-migrate(2698) FIXME: npe
        const {hotelBookingRooms}: { hotelBookingRooms: WebHotelBookingRoomPropTypes[] } = state.data;
        const hotelAvailabilityCriteriaNew = {
            ...state.data?.hotelAvailabilityCriteria,
            rooms: hotelBookingRooms.map(({
                roomNo,
                cxlDate,
                displayBookingTermsCXL,
                displayBookingTermsRemarks,
                displaySalePrice,
                salePrice
            }) => ({
                ...state.data?.hotelAvailabilityCriteria?.rooms[roomNo],
                cxlDate,
                displayBookingTermsCXL,
                displayBookingTermsRemarks,
                displayPrice: displaySalePrice,
                price: salePrice
            }))
        } as HotelAvailabilityCriteriaPropTypes;

        return {
            ...state,
            data: {
                ...state.data,
                hotelAvailabilityCriteria: hotelAvailabilityCriteriaNew,
                changes: undefined,
                route: "OK"
            }
        };
    }
    case HotelBookingTypes.CONFIRM_DUPLICATE_BOOKING:
        return {
            ...state,
            firstTimeChanges: false,
            data: {
                ...state.data,
                bookingInfo: {
                    ...state.data?.bookingInfo,
                    confirmedDuplicateBooking: true
                },
                route: "OK",
                duplicateBookings: undefined
            }
        };
    case HotelBookingTypes.HANDLE_CONTACT_INFO:
        return {
            ...state,
            data: {
                ...state.data,
                bookingInfo: {
                    ...state.data?.bookingInfo,
                    contactInfo: {
                        ...state.data?.bookingInfo?.contactInfo,
                        [action.name]: action.value
                    }
                }
            }
        };
    case HotelBookingTypes.HANDLE_LATE_ARRIVAL:
        return {
            ...state,
            data: {
                ...state.data,
                hotelBookingInfo: {
                    ...state.data?.hotelBookingInfo,
                    specialRequests: {
                        ...state.data?.hotelBookingInfo?.specialRequests,
                        lateArrivalTime: action.lateArrivalTime,
                        lateArrival: !!action.lateArrivalTime
                    }
                }
            }
        };
    case HotelBookingTypes.HANDLE_MEMO:
        return {
            ...state,
            data: {
                ...state.data,
                bookingInfo: {
                    ...state.data?.bookingInfo,
                    memo: action.value
                }
            }
        };
    case HotelBookingTypes.HANDLE_SPECIAL_REQUESTS:
        return {
            ...state,
            data: {
                ...state.data,
                hotelBookingInfo: {
                    ...state.data?.hotelBookingInfo,
                    specialRequests: {
                        ...state.data?.hotelBookingInfo?.specialRequests,
                        //lateArrivalTime: name === "lateArrival" && value === false ? lateArrivalTime: undefined: state.lateArrivalTime,
                        [action.name]: action.value
                    }
                }
            }
        };
    case HotelBookingTypes.HANDLE_ADULTS:
        return {
            ...state,
            data: {
                ...state.data,
                hotelBookingInfo: {
                    ...state.data?.hotelBookingInfo,
                    hotelBookingRoomInfo:
                            Object.assign(
                                [],
                                state.data?.hotelBookingInfo?.hotelBookingRoomInfo,
                                {
                                    [action.roomNo]: {
                                        ...state.data?.hotelBookingInfo?.hotelBookingRoomInfo[action.roomNo],
                                        adults: Object.assign(
                                            [],
                                            state.data?.hotelBookingInfo?.hotelBookingRoomInfo[action.roomNo].adults,
                                            {
                                                [action.personIndex]: {
                                                    ...state.data?.hotelBookingInfo?.hotelBookingRoomInfo[action.roomNo].adults[action.personIndex],
                                                    [action.name]: action.value
                                                }
                                            }
                                        )
                                    }
                                }
                            )
                }
            }
        };
    case HotelBookingTypes.HANDLE_CHILDREN:
        return {
            ...state,
            data: {
                ...state.data,
                hotelBookingInfo: {
                    ...state.data?.hotelBookingInfo,
                    hotelBookingRoomInfo:
                            Object.assign(
                                [],
                                state.data?.hotelBookingInfo?.hotelBookingRoomInfo,
                                {
                                    [action.roomNo]: {
                                        ...state.data?.hotelBookingInfo?.hotelBookingRoomInfo[action.roomNo],
                                        children: Object.assign(
                                            [],
                                            state.data?.hotelBookingInfo?.hotelBookingRoomInfo[action.roomNo].children,
                                            {
                                                [action.personIndex]: {
                                                    ...state.data?.hotelBookingInfo?.hotelBookingRoomInfo[action.roomNo].children[action.personIndex],
                                                    [action.name]: action.value
                                                }
                                            }
                                        )
                                    }
                                }
                            )
                }
            }
        };
    case HotelBookingTypes.UPDATE_OPTIONS_FAILURE:
        return {
            ...state
        };
    case HotelBookingTypes.HANDLE_CHECK_IN_LEAD_PASSENGER_NAME:
        return {
            ...state,
            data: {
                ...state.data,
                hotelBookingInfo: {
                    ...state.data?.hotelBookingInfo,
                    clientRequiredCheckInInformation: {
                        ...state.data?.hotelBookingInfo?.clientRequiredCheckInInformation,
                        leadPassengerName: action.name
                    }
                }
            }
        };
    case HotelBookingTypes.HANDLE_CHECK_IN_LEAD_PASSENGER_PHONE_NUMBER:
        return {
            ...state,
            data: {
                ...state.data,
                hotelBookingInfo: {
                    ...state.data?.hotelBookingInfo,
                    clientRequiredCheckInInformation: {
                        ...state.data?.hotelBookingInfo?.clientRequiredCheckInInformation,
                        leadPassengerPhoneNumber: action.phone
                    }
                }
            }
        };
    case HotelBookingTypes.HANDLE_CHECK_IN_PHONE_COUNTRY_CODE:
        return {
            ...state,
            data: {
                ...state.data,
                hotelBookingInfo: {
                    ...state.data?.hotelBookingInfo,
                    clientRequiredCheckInInformation: {
                        ...state.data?.hotelBookingInfo?.clientRequiredCheckInInformation,
                        phoneCountryCode: action.countryCode
                    }
                }
            }
        };
    case HotelBookingTypes.TOGGLE_CHECK_IN_ENTER_LATER:
        return {
            ...state,
            data: {
                ...state.data,
                hotelBookingInfo: {
                    ...state.data?.hotelBookingInfo,
                    clientRequiredCheckInInformation: state.data?.hotelBookingInfo?.clientRequiredCheckInInformation ? undefined : {}
                }
            }
        };
    case HotelBookingTypes.HANDLE_CHECK_IN_ARRIVAL_TIME:
        return {
            ...state,
            data: {
                ...state.data,
                hotelBookingInfo: {
                    ...state.data?.hotelBookingInfo,
                    clientRequiredCheckInInformation: {
                        ...state.data?.hotelBookingInfo?.clientRequiredCheckInInformation,
                        checkInTime: action.checkInArrivalTime
                    }
                }
            }
        };
    case HotelBookingTypes.TOGGLE_FLIGHT_DETAILS:
        return {
            ...state,
            data: {
                ...state.data,
                hotelBookingInfo: {
                    ...state.data?.hotelBookingInfo,
                    flightDetails: action.enabled ? {
                        arrival: {
                            arrivalDate: createLuxonDate().plus({day: 7}).toFormat(FALLBACK_DATE_FORMAT)
                        },
                        departure: {
                            arrivalDate: createLuxonDate().plus({day: 14}).toFormat(FALLBACK_DATE_FORMAT)
                        }
                    } : undefined
                }
            }
        };
    case HotelBookingTypes.HANDLE_FLIGHT_DETAILS_CODE_FROM:
        return {
            ...state,
            data: {
                ...state.data,
                hotelBookingInfo: {
                    ...state.data?.hotelBookingInfo,
                    flightDetails: {
                        arrival: action.departure ? state.data?.hotelBookingInfo?.flightDetails?.arrival : {
                            ...state.data?.hotelBookingInfo?.flightDetails?.arrival,
                            fromAirportCode: action.code
                        },
                        departure: action.departure ? {
                            ...state.data?.hotelBookingInfo?.flightDetails?.departure,
                            fromAirportCode: action.code
                        } : state.data?.hotelBookingInfo?.flightDetails?.departure
                    }
                }
            }
        };
    case HotelBookingTypes.HANDLE_FLIGHT_DETAILS_CODE_TO:
        return {
            ...state,
            data: {
                ...state.data,
                hotelBookingInfo: {
                    ...state.data?.hotelBookingInfo,
                    flightDetails: {
                        arrival: action.departure ? state.data?.hotelBookingInfo?.flightDetails?.arrival : {
                            ...state.data?.hotelBookingInfo?.flightDetails?.arrival,
                            toAirportCode: action.code
                        },
                        departure: action.departure ? {
                            ...state.data?.hotelBookingInfo?.flightDetails?.departure,
                            toAirportCode: action.code
                        } : state.data?.hotelBookingInfo?.flightDetails?.departure
                    }
                }
            }
        };
    case HotelBookingTypes.HANDLE_FLIGHT_DETAILS_DATE:
        return {
            ...state,
            data: {
                ...state.data,
                hotelBookingInfo: {
                    ...state.data?.hotelBookingInfo,
                    flightDetails: {
                        arrival: action.departure ? state.data?.hotelBookingInfo?.flightDetails?.arrival : {
                            ...state.data?.hotelBookingInfo?.flightDetails?.arrival,
                            arrivalDate: createLuxonDate(action.date).toFormat(FALLBACK_DATE_FORMAT)
                        },
                        departure: action.departure ? {
                            ...state.data?.hotelBookingInfo?.flightDetails?.departure,
                            arrivalDate: createLuxonDate(action.date).toFormat(FALLBACK_DATE_FORMAT)
                        } : state.data?.hotelBookingInfo?.flightDetails?.departure
                    }
                }
            }
        };
    case HotelBookingTypes.HANDLE_FLIGHT_DETAILS_TIME:
        return {
            ...state,
            data: {
                ...state.data,
                hotelBookingInfo: {
                    ...state.data?.hotelBookingInfo,
                    flightDetails: {
                        arrival: action.departure ? state.data?.hotelBookingInfo?.flightDetails?.arrival : {
                            ...state.data?.hotelBookingInfo?.flightDetails?.arrival,
                            arrivalTimeHour: action.time.split(":")[0],
                            arrivalTimeMinute: action.time.split(":")[1]
                        },
                        departure: action.departure ? {
                            ...state.data?.hotelBookingInfo?.flightDetails?.departure,
                            arrivalTimeHour: action.time.split(":")[0],
                            arrivalTimeMinute: action.time.split(":")[1]
                        } : state.data?.hotelBookingInfo?.flightDetails?.departure
                    }
                }
            }
        };
    case HotelBookingTypes.HANDLE_FLIGHT_DETAILS_NUMBER:
        return {
            ...state,
            data: {
                ...state.data,
                hotelBookingInfo: {
                    ...state.data?.hotelBookingInfo,
                    flightDetails: {
                        arrival: action.departure ? state.data?.hotelBookingInfo?.flightDetails?.arrival : {
                            ...state.data?.hotelBookingInfo?.flightDetails?.arrival,
                            flightNumber: action.number
                        },
                        departure: action.departure ? {
                            ...state.data?.hotelBookingInfo?.flightDetails?.departure,
                            flightNumber: action.number
                        } : state.data?.hotelBookingInfo?.flightDetails?.departure
                    }
                }
            }
        };
    default:
        return state;
    }
};
