import React from "react";
import {connect, ConnectedProps} from "react-redux";
import {animateScroll as scroll} from "react-scroll";
import cx from "classnames";
import {WithTranslation, withTranslation} from "react-i18next";
import * as mapActions from "../../../../redux/actions/map.actions";
import styles from "./DetailedView.module.scss";
import MapWrapper from "./MapContainer/MapWrapper";
import {HotelOfferPropTypes, ReduxModalType} from "../../../../proptypes/PropTypeObjects";
import {RootState} from "../../../../redux/store/store.init";
import {
    toggleMapFilterBox,
    toggleMapWidth
} from "../../../../redux/actions/map.actions";
import OneActiveSubject from "../../../../utils/generic/oneActiveSubject";
import HotelOffersContainer from "./HotelOffers/HotelOffersContainer";

type Props = ConnectedProps<typeof connector> & WithTranslation & {
    requestingDetails: boolean;
    sortBy: { label: string, value: string };
    sortByOptions: {
        value: string;
    }[];
    hotelOffers: HotelOfferPropTypes[];
    allResultsCount: number;
    handleChangeView: (view: string, updateQueryParam?: boolean) => void;
    putSavedSearch: () => void;
    toggleHotelFavoriteFilter: (favoriteFilter: boolean) => void;
    favoriteFilter: boolean;
    view: string;
    isActivePinnedOffers: boolean;

    size?: number;
    pageSizeOptions: { value: number, label: number }[];
    handlePageSizeChange: (size: number, hotelDetails?: boolean, updateQueryParam?: boolean) => void;
    pageNumber: number;
    pagesCount: number;
    handleChangePage: (page: number, hotelDetails?: boolean, updateQueryParam?: boolean) => void;

    className: string;
    modal?: ReduxModalType;
};

type DetailedViewState = {
    markersSubject: OneActiveSubject<void, number>;
};

class DetailedView extends React.Component<Props, DetailedViewState> {
    constructor(props: Props) {
        super(props);
        this.state = {
            markersSubject: new OneActiveSubject<void, number>()
        };
    }

    handleFullWidthMap = () => {
        const {
            toggleMapWidth
        } = this.props;
        scroll.scrollToTop();
        toggleMapWidth();
    };

    render() {
        const {
            className,
            hotelOffers,
            handleChangePage,
            pagesCount,
            pageNumber,
            size,
            pageSizeOptions,
            handlePageSizeChange,
            isActivePinnedOffers,
            modal,
            fullWidthMap
        } = this.props;
        const {markersSubject} = this.state;

        return (
            <div className={cx(styles.DetailedView, className)}>
                <div className={cx(styles.DetailedViewContainer, fullWidthMap && styles.MapContainerFullWidth)}>
                    <HotelOffersContainer
                        hotelOffers={hotelOffers}
                        size={size || 0}
                        pageSizeOptions={pageSizeOptions}
                        handlePageSizeChange={handlePageSizeChange}
                        isActivePinnedOffers={isActivePinnedOffers}
                        markersSubject={markersSubject}
                        handleChangePage={handleChangePage}
                        pageNumber={pageNumber}
                        pageCount={pagesCount}
                    />

                    <div
                        className={cx(
                            styles.MapContainer,
                            fullWidthMap && styles.MapContainerFullWidth,
                            modal === ReduxModalType.MAP && styles.MapContainerMobileActive
                        )}
                    >
                        <MapWrapper handleFullWidthMap={this.handleFullWidthMap} singleMarkerSubject={markersSubject} />
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state: RootState) => ({
    fullWidthMap: state.map.fullWidthMap,
    modal: state.modals.currentActiveModal
});

const connector = connect(mapStateToProps, {
    mapActions,
    toggleMapWidth,
    toggleMapFilterBox
});

export default withTranslation()(connector(DetailedView));