import React, {useCallback, useMemo} from "react";
import {useTranslation} from "react-i18next";
import debounce from "lodash/debounce";
import {useAppDispatch, useAppSelector} from "redux/hooks"
import {handleInputChange, handleDestination} from "redux/actions/transferSearch.actions"
import {getSearchTransferDestination} from "redux/actions/destinations.actions";
import useDefaultFeatureEnabled from "utils/hooks/useDefaultFeatureEnabled";
import StandaloneDropdownWithSearch, {DropdownWithSearchOption} from "components/base/Input/DropdownWithSearch/StandaloneDropdownWithSearch";
import {TransferDestinationPropTypes} from "proptypes/PropTypeObjects";
import styles from "./TransferDestinationInput.module.scss";

type Props = {
    handleCanBeTransferDestinationSet: (e: React.FocusEvent<HTMLInputElement>) => void;
}

const TransferDestinationInput: React.FC<Props> = ({handleCanBeTransferDestinationSet}) => {
    const dispatch = useAppDispatch();
    const {t} = useTranslation();
    const {
        featureEnabled: newTransferSearch,
        featuresLoaded
    } = useDefaultFeatureEnabled("web2NewTransferSearch");

    const hasNewTransferSearchFeature = useMemo(() => featuresLoaded && newTransferSearch, [featuresLoaded, newTransferSearch]);

    const venueInput = useAppSelector((state) => state.transferSearch?.venueInput);
    const currentDestination = useAppSelector((state) => state.transferSearch?.currentDestination);
    const bookRoundtrip = useAppSelector((state) => state.transferSearch?.bookRoundtrip);
    const departureTransfer = useAppSelector((state) => state.transferSearch?.formData?.departureTransfer);
    const transferOriginId = useAppSelector((state) => state.transferSearch?.formData?.transferOriginId);
    const originId = useAppSelector((state) => state.transferSearch?.formData?.originId);
    const transferDestinations = useAppSelector((state) => state.destinations?.transferDestinations);

    const transferDestinationsFormed = transferDestinations && transferDestinations.destinations && transferDestinations.destinations.map((value) => new DropdownWithSearchOption(
        `${hasNewTransferSearchFeature ? value.name : `${value.name}, ${value.countryName}`}`,
        value,
        `${value.address && hasNewTransferSearchFeature ? value.address : `${value.cityName ? `${value.cityName}, ` : ""}${value.countryName}`}`,
        "transferDestinations"
    )
    ).filter((opt) => !!opt);

    const handleInputChangeCallback = useCallback(({target: {value, name}}: React.ChangeEvent<HTMLInputElement>) => dispatch(handleInputChange(name, value)), [handleInputChange]);
    const handleTransferDestinations = useCallback(
        debounce((name: string, value: string) => {
            dispatch(getSearchTransferDestination(name, value));
        }, 200, {leading: false, trailing: true}),
        []
    );

    return (
        <StandaloneDropdownWithSearch
            smallFont
            filterEmptyMatch={false}
            customErrorMessage={t("util_vf_error_not_valid_transfer_destination")}
            className={styles.TransferDestinationInput}
            inputProps={{
                required: true,
                name: "venueInput",
                value: venueInput,
                placeholder: (!bookRoundtrip && departureTransfer)
                    ? (hasNewTransferSearchFeature ? t("t_s_sb_from_hotel") : t("t_s_sb_from_venue")) + ":"
                    : (hasNewTransferSearchFeature ? t("t_s_sb_to_hotel") : t("t_s_sb_to_venue")) + ":",
                onFocus: (e: React.FocusEvent<HTMLInputElement>) => {
                    handleCanBeTransferDestinationSet(e);
                },
                onChange: (e: React.FocusEvent<HTMLInputElement>) => {
                    handleInputChangeCallback(e)
                    if (transferOriginId || originId) {
                        handleTransferDestinations(e.target.value, (transferOriginId || originId).toString());
                    }
                }
            }}
            groups={[
                {
                    order: 1,
                    name: "transferDestinations",
                    label: (!bookRoundtrip && departureTransfer)
                        ? (hasNewTransferSearchFeature ? t("t_s_sb_from_hotel") : t("t_s_sb_from_venue")) + ":"
                        : (hasNewTransferSearchFeature ? t("t_s_sb_to_hotel") : t("t_s_sb_to_venue")) + ":"
                }
            ]}
            onValueChange={(value: DropdownWithSearchOption<TransferDestinationPropTypes>) => {
                dispatch(handleDestination(value.value));
                dispatch(handleInputChange("venueInput", value.label));
            }}
            options={transferDestinationsFormed || []}
            currentValue={currentDestination}
        />
    );
}

export default TransferDestinationInput;
