import React, {
    ReactElement, useCallback, useEffect, useMemo, useRef, useState
} from "react";
import _ from "lodash";
import cx from "classnames";
import {ReactComponent as ArrowIcon} from "assets/icons/arrow.svg";
import Pipeline from "../../../../utils/generic/pipeline";
import {BookingSearchCriteriaPropTypes} from "proptypes/PropTypeObjects";
import styles from "./SearchPredicatesContainer.module.scss";
import useBookingReferenceBubbleFactory from "./BookingReferenceBubble/useBookingReferenceBubbleFactory";
import Subject from "../../../../utils/generic/subject";
import {ChildBubblePair} from "./SearchPredicates.base";
import useBookingStatusBubbleFactory from "./BookingStatusBubble/useBookingStatusBubbleFactory";
import useKeyDetect from "../../../../components/utils/useKeyDetect";
import useBookingInvoiceNumberBubbleFactory from "./BookingInvoiceNumber/useBookingInvoiceNumberBubbleFactory";
import OutlineInput from "../../../../components/base/Input/TextInput/OutlinedInput";
import useFreeTextSearchBubbleFactory from "./FreeTextSearch/useFreeTextSearchBubbleFactory";

const WORD_BOUNDARY_REGEX = /\b[a-z]+\b/gi;

export type SearchPredicatesContainerProps = {
    collectPipeline: Pipeline<BookingSearchCriteriaPropTypes>;
    buildPredicatesSubject: Subject<void>;
    onAddPredicate: (newString: string, count: number) => void;
    predicatesChangedSearch: (count: number) => void;
    clearPredicatesCallback: Subject<void>;
    triggerShowOptions: () => void;
    showOptions: boolean;
    searchString: string;
    inputRef: React.RefObject<OutlineInput>;
};

function SearchPredicatesContainer({
    collectPipeline,
    buildPredicatesSubject,
    onAddPredicate,
    predicatesChangedSearch,
    clearPredicatesCallback,
    showOptions,
    searchString,
    inputRef
}: SearchPredicatesContainerProps): ReactElement {
    const [childBubbles, setChildBubbles] = useState<ChildBubblePair[]>([]);
    const [selectedIndex, setSelectedIndex] = useState<number>(0);
    const [prebuildingPredicates, setPrebuildingPredicates] = useState<boolean>(false);

    const prevChildLength = useRef<number>(0);

    const currentChildAddBuffer = useRef<ChildBubblePair[]>([]);
    const currentChildAddTimeout = useRef<number>(0);

    const addChildCallback = useCallback((newChild: ChildBubblePair | null) => {
        if (!newChild) {
            return;
        }

        if (![...currentChildAddBuffer.current, ...childBubbles].find((existingChild) => existingChild.reference === newChild.reference)) {
            // setChildBubbles([
            //     ...childBubbles,
            //     newChild
            // ]);

            currentChildAddBuffer.current.push(newChild);

            clearTimeout(currentChildAddTimeout.current);
            currentChildAddTimeout.current = window.setTimeout(() => {
                let newText = searchString;
                currentChildAddBuffer.current.forEach((inBuffer) => {
                    if (newText.length > searchString.length) {
                        return;
                    }

                    const wholeWord = WORD_BOUNDARY_REGEX.exec(newText.substring(inBuffer.fromPos));
                    if (wholeWord && wholeWord[0] && wholeWord[0].length >= inBuffer.strLen) {
                        newText = (newText.substring(0, wholeWord.index) + newText.substring(wholeWord.index + wholeWord[0].length)).trim();
                    } else {
                        newText = (newText.substring(inBuffer.fromPos + inBuffer.strLen)).trim();
                    }
                });
                onAddPredicate(newText, childBubbles.length);

                setChildBubbles([
                    ...childBubbles,
                    ...currentChildAddBuffer.current
                ]);
                currentChildAddBuffer.current = [];
            });
        }
    }, [childBubbles, onAddPredicate, searchString]);

    const removeChildCallback = useCallback((existingReference: string | null) => {
        if (!existingReference) {
            return;
        }

        const found = childBubbles.find((bubble) => bubble.reference === existingReference);

        if (!found) {
            return;
        }

        setChildBubbles(childBubbles.filter(({reference}) => reference !== existingReference));
    }, [childBubbles]);

    const clearPredicates = useCallback(() => {
        setChildBubbles([]);
    }, []);

    const addChildSubject = useMemo(() => new Subject<ChildBubblePair>(addChildCallback), [addChildCallback]);
    const removeChildSubject = useMemo(() => new Subject<string>(), []);

    const {options: bookingReferenceOptions} = useBookingReferenceBubbleFactory({
        collectPipeline,
        addSubject: addChildSubject,
        removeSubject: removeChildSubject,
        searchString
    });

    const {options: bookingStatusOptions} = useBookingStatusBubbleFactory({
        collectPipeline,
        addSubject: addChildSubject,
        removeSubject: removeChildSubject,
        searchString
    });

    const {options: invoiceOptions} = useBookingInvoiceNumberBubbleFactory({
        collectPipeline,
        addSubject: addChildSubject,
        removeSubject: removeChildSubject,
        searchString
    });

    const {options: freeTextOptions} = useFreeTextSearchBubbleFactory({
        collectPipeline,
        addSubject: addChildSubject,
        removeSubject: removeChildSubject,
        searchString
    });

    const allOptions = useMemo(() => {
        const options = [
            ...bookingReferenceOptions,
            ...bookingStatusOptions,
            ...invoiceOptions,
            ...freeTextOptions
        ];

        options.forEach((option) => {
            // eslint-disable-next-line no-param-reassign
            option.ref = React.createRef();
        });

        return options;
    }, [bookingReferenceOptions, bookingStatusOptions, freeTextOptions, invoiceOptions]);

    const buildPredicatesCallback = useCallback(() => {
        setPrebuildingPredicates(true);
    }, []);

    useEffect(() => {
        if (prebuildingPredicates && allOptions.length !== 0 && allOptions[0].onClick) {
            allOptions[0].onClick();
        } else {
            setPrebuildingPredicates(false);
        }
    }, [allOptions, prebuildingPredicates]);

    useEffect(() => {
        buildPredicatesSubject.subscribe(buildPredicatesCallback);
        removeChildSubject.subscribe(removeChildCallback);
        clearPredicatesCallback.subscribe(clearPredicates);

        return () => {
            addChildSubject.unsubscribe(addChildCallback);
            removeChildSubject.unsubscribe(removeChildCallback);
            clearPredicatesCallback.unsubscribe(clearPredicates);
            buildPredicatesSubject.unsubscribe(buildPredicatesCallback);
        };
    }, [addChildCallback, addChildSubject, buildPredicatesCallback, buildPredicatesSubject, removeChildCallback, removeChildSubject]);

    useEffect(() => {
        if (childBubbles.length !== prevChildLength.current) {
            predicatesChangedSearch(childBubbles.length);
        }

        prevChildLength.current = childBubbles.length;

        return () => {
            setSelectedIndex(0);
        };
    }, [childBubbles, predicatesChangedSearch]);

    useKeyDetect(38, () => {
        if (!showOptions || allOptions.length === 0) {
            return;
        }

        setSelectedIndex((selectedIndex - 1) >= 0 ? selectedIndex - 1 : allOptions.length - 1);
    }, true);

    useKeyDetect(40, () => {
        if (!showOptions || allOptions.length === 0) {
            return;
        }

        setSelectedIndex((selectedIndex + 1) >= allOptions.length ? 0 : selectedIndex + 1);
    }, true);

    useKeyDetect(13, () => {
        if (showOptions && allOptions[selectedIndex]) {
            allOptions[selectedIndex].ref?.current?.click();
            return true;
        }

        return false;
    });

    useKeyDetect(8, () => {
        if (
            showOptions &&
            childBubbles &&
            childBubbles.length > 0 &&
            inputRef.current?.getCursorPosition() === 0 &&
            inputRef.current?.getCursorPosition() === inputRef.current?.getSelectionEnd()
        ) {
            setChildBubbles([
                ...childBubbles.slice(0, childBubbles.length - 1)
            ]);
            return true;
        }

        return false;
    });

    return (
        <>
            {childBubbles.map((child) => child.child)}

            {showOptions && (
                <ul className={styles.AutocompleteDropdown}>
                    {allOptions.slice(0, 5).map((option, index) => (
                        <li
                            className={cx(index === selectedIndex && styles.Selected)}
                            key={_.uniqueId("predicate_option_")}
                            onClick={option.onClick}
                            ref={option.ref}
                        >
                            <ArrowIcon />
                            {option.label}
                        </li>
                    ))}
                </ul>
            )}
        </>
    );
}

export default SearchPredicatesContainer;