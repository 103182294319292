import {ConstrainedAxiosRequestConfig} from "./axios/constrainedAxiosRequestConfig";

export default function agreeToTermsAndConditions(): ConstrainedAxiosRequestConfig<void> {
    return {
        method: "post",
        url: "/app/termsAndConditions/",
        headers: {
            "Content-Type": "application/json",
            Accept: "application/json"
        },
        data: {
            userAgent: navigator.userAgent
        },
        maxNumRequests: 1
    };
}