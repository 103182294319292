import i18n from "i18next";
import Backend from "i18next-http-backend";
import {initReactI18next} from "react-i18next";
import _, {DebouncedFunc} from "lodash";
import BASE_URL from "./api/constants/baseURL";
import sendLogs from "./api/loggerAPI";
import {getCookieValue} from "./utils/cookie/cookie";
import Subject from "./utils/generic/subject";
import {Settings} from "luxon"
import {globalAxiosController} from "api/axios/globalAxiosController";

export const FailedToLoadTranslationFileSubject = new Subject<boolean>();

const missingKeys: string[] = [];
const currentPromise: DebouncedFunc<() => void> = _.debounce(() => {
    globalAxiosController.addRequest(
        sendLogs(JSON.stringify({message: JSON.stringify("Missing translation keys: " + missingKeys.join(", "))}))
    ).catch((error: any) => {
        // console.log(error);
    }).finally(() => {
        missingKeys.splice(0);
    });
}, 500);
const queueRequest = (missingKey: string) => {
    if (!missingKeys.includes(missingKey)) {
        missingKeys.push(missingKey);
    }

    currentPromise();
};

const currentCookie = getCookieValue();
let caches: string[] = [];
if (currentCookie.preferences) {
    caches = ["cookie"];
}

(i18n
    .on("languageChanged", (lng) => {
        Settings.defaultLocale = lng;
    }) as any).on("failedLoading", () => {
    void FailedToLoadTranslationFileSubject.next(true);
}).use(Backend)
    .use(initReactI18next)
    .init({
        lng: localStorage.getItem("currentLocale") || "en",
        backend: {
            loadPath: `${BASE_URL}/app/translations/{{ns}}_{{lng}}.json`,
            crossDomain: true,
            withCredentials: true
        },
        detection: {
            caches: caches,
            excludeCacheFor: ["cimode"]
        },
        fallbackLng: "en",
        debug: false,
        ns: ["messages"],
        defaultNS: "messages",
        keySeparator: false,
        saveMissing: true,
        missingKeyHandler: (lngs: unknown, ns: string, key: string) => queueRequest(key),
        interpolation: {
            escapeValue: false,
            formatSeparator: ","
        },
        react: {
            useSuspense: true
        }
    });

export default i18n;
