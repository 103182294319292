import React from "react";
import {createSelectorCreator, defaultMemoize} from "reselect";
import {connect, ConnectedProps} from "react-redux";
import * as localeActions from "redux/actions/locale.actions";
import * as currencyActions from "redux/actions/currency.actions";
import isEqual from "lodash/isEqual";
import getDisplayName from "./getDisplayName";
import withRouteInfo, {WithRouteInfo} from "./withRouteInfo";
import {useAppSelector} from "redux/hooks";
import {LocalePropTypes} from "proptypes/PropTypeObjects";
import {
    ReduxCurrencyPropTypes,
    ReduxLocalePropTypes
} from "proptypes/PropTypeRedux";
import {RootState} from "redux/store/store.init";
import i18n from "i18next"
import Currency from "@hotelston_web_frontend_parent/constants/currency";

const createDeepEqualSelector = createSelectorCreator(defaultMemoize, isEqual);

const localeSelector = (state: ReduxLocalePropTypes) => state.locales.locales;

function getFormedLocales(locales: LocalePropTypes[], labelAsValue: boolean) {
    return locales.map((loc) => ({
        value: loc.lang,
        label: labelAsValue ? loc.lang : loc.name
    }));
}

const localesFormedSelector = (labelAsValue = true) => createDeepEqualSelector(localeSelector, (locales) => getFormedLocales(locales, labelAsValue));

const currencySelector = (state: ReduxCurrencyPropTypes) => state.currencies.currencies;

function getFormedCurrencies(currencies: Currency[]) {
    return currencies.map((currCode) => ({
        value: currCode,
        label: currCode
    }));
}

const currenciesFormedSelector = () => createDeepEqualSelector(currencySelector, (currencies) => getFormedCurrencies(currencies));

//function withConfirmation<P>(WrappedComponent: React.ComponentType<P>, config = {}): React.FC< Omit<P, keyof WithConfirmation>>

type ConfigProps = {
    labelAsValue?: boolean;
};

export type WithLocalesProps = {
    changeCurrentLocale: (currentLocale: string, updateQueryParam?: boolean) => void
    currentLocale: string;
    localesFormed: { value: string, label: string }[];
};

export function withLocales<P>(WrappedComponent: React.ComponentType<P>, config: ConfigProps = {}): React.ComponentClass<Omit<P, keyof WithLocalesProps>> {
    type WithLocalesPropsInner = ConnectedProps<typeof connector>;
    // const {i18n} = useTranslation();

    const HOComponent = ({
        changeCurrentLocale, currentPathHasQueryParams, currentLocale, disabledNav, lastLocation, getLocale, localesFormed, ...rest
    }: WithLocalesPropsInner & WithRouteInfo) => (
        <WrappedComponent
            changeCurrentLocale={(value: string) => currentLocale !== value &&
                    i18n.changeLanguage(value, () => changeCurrentLocale(value, currentPathHasQueryParams))}
            currentLocale={currentLocale}
            localesFormed={localesFormed}
            {...rest as P}
        />
    );

    HOComponent.displayName = `withLocalesAndCurrencies(${getDisplayName(HOComponent)})`;

    const mapStateToProps = ({locale}: RootState) => ({
        localesFormed: localesFormedSelector(config.labelAsValue)(locale),
        currentLocale: locale.currentLocale
    });
    const connector = connect(mapStateToProps, localeActions);

    return withRouteInfo(connector(HOComponent)) as unknown as React.ComponentClass<Omit<P, keyof WithLocalesProps>>;
}

export type WithCurrenciesProps = ConnectedProps<typeof currenciesConnector>;

export function withCurrencies<P>(WrappedComponent: React.ComponentType<P>, config = {}): React.ComponentClass<Omit<P, keyof WithCurrenciesProps>> {
    const HOComponent = ({
        changeCurrentCurrency, currentPathHasQueryParams, currentCurrency, currenciesFormed, lastLocation, disabledNav, getCurrency, ...rest
    }: WithCurrenciesProps & WithRouteInfo) => (

        <WrappedComponent
            changeCurrentCurrency={(value: Currency) => currentCurrency !== value && changeCurrentCurrency(value, currentPathHasQueryParams)}
            currentCurrency={currentCurrency}
            currenciesFormed={currenciesFormed}
            {...rest as P}
        />
    );

    HOComponent.displayName = `withLocalesAndCurrencies(${getDisplayName(HOComponent)})`;

    return withRouteInfo(currenciesConnector(HOComponent)) as unknown as React.ComponentClass<Omit<P, keyof WithCurrenciesProps>>;
}

const mapStateToPropsCurrencies = ({currency}: RootState) => ({
    currenciesFormed: currenciesFormedSelector()(currency),
    currentCurrency: currency.currentCurrency
});

const currenciesConnector = connect(mapStateToPropsCurrencies, currencyActions);

export const useLocale = (): string => useAppSelector((state) => state.locale.currentLocale);
export const useCurrency = (): Currency => useAppSelector((state) => state.currency.currentCurrency);
export const useCompanyCurrency = (): Currency | undefined => useAppSelector((state) => state.auth.userData?.currency);