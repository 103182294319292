import React, {ReactElement} from "react";
import MainContainer from "components/base/MainContainer";
import cx from "classnames";
import Spinner from "../../base/Loaders/Spinner";
import styles from "./Amendments.module.scss";

export type AmendmentsProps = {
    children?: (ReactElement | undefined | boolean)[];
    footer: ReactElement;
    isSpinnerOn: boolean;
    className?: string;
};

function Amendments({
    children,
    footer,
    isSpinnerOn,
    className
}: AmendmentsProps): ReactElement {
    return (
        <MainContainer sidebar={false}>
            {
                isSpinnerOn
                    ? (
                        <Spinner
                            size="50px"
                            style={{
                                width: "100%",
                                height: "calc( 100vh - 60px )"
                            }}
                        />
                    )
                    : (
                        <div className={cx(styles.Root, className)}>
                            <div className={styles.Content}>
                                {children}
                            </div>

                            {footer}
                        </div>
                    )
            }
        </MainContainer>
    );
}

export default Amendments;
