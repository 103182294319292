import React, {ReactElement} from "react";
import PageTemplate from "../../components/base/PageTemplate/PageTemplate";
import HeaderContainer from "../../components/base/Header/HeaderContainer";
import Footer from "../../components/base/Footer";
import Lefter from "../../components/base/Lefter/LefterContainer";
import Righter from "../../components/base/Righter";
import TravelRestrictionsContainer from "./TravelRestrictionsContainer";
import ErrorBoundary from "../../components/utils/ErrorBoundary/ErrorBoundary";

const TravelRestrictions = (): ReactElement => (
    <PageTemplate
        header={<HeaderContainer />}
        footer={<Footer />}
        lefter={<Lefter />}
        righter={<Righter />}
        className="TravelRestrictions"
    >
        <ErrorBoundary>
            <TravelRestrictionsContainer />
        </ErrorBoundary>
    </PageTemplate>
);

export default TravelRestrictions;