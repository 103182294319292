import {Trans, useTranslation} from "react-i18next";
import React, {ReactElement, useState} from "react";
import {useAppDispatch, useAppSelector} from "redux/hooks"
import {
    CountryPropTypes,
    PaymentTransferTypePropTypes,
    WebBankLinkOptionPropTypes
} from "proptypes/PropTypeObjects";
import InfoBox from "components/common/InfoBox";
import types from "utils/paymentTransferTypes";
import {ReactComponent as InternetBankIcon} from "assets/icons/internet bank.svg";
import styles from "components/common/Payment/PaymentContent/PaymentContent.module.scss";
import BasicDropdown from "components/base/Input/BasicDropdown/BasicDropdown";
import SelectableCard from "./SelectableCard";
import {calculateTransactionFee, setTransactionFee} from "redux/actions/pricing.actions"
import {setSelectedPaymentLink} from "redux/actions/payment.actions"
import Currency from "@hotelston_web_frontend_parent/constants/currency";

type Props = {
    paymentTransferTypeEnum?: PaymentTransferTypePropTypes,
    afterDeadline?: boolean;
    toggleSelectedPayment: (paymentType: PaymentTransferTypePropTypes, keepSelected?: boolean) => void,
    mips: any[],
    bankLinkCountries: CountryPropTypes[],
    withOtherCurrencyAndMarkup: boolean,
    selectedPaymentLink?: WebBankLinkOptionPropTypes,
    markupAmount: number,
    currency: Currency,
    bankLinks: any[],
    children: ReactElement
};

function InternetBankBlock({
    paymentTransferTypeEnum,
    afterDeadline,
    toggleSelectedPayment,
    mips,
    bankLinkCountries,
    withOtherCurrencyAndMarkup,
    selectedPaymentLink,
    markupAmount,
    currency,
    bankLinks,
    children

}: Props): ReactElement {
    const {t} = useTranslation();
    const dispatch = useAppDispatch();

    const bankLinkCountriesSorted = bankLinkCountries.sort((o1, o2) => o1.name.localeCompare(o2.name));

    const bankLinkCountriesFormed = bankLinkCountriesSorted.map(({name, id}: CountryPropTypes) => ({
        label: name,
        value: id
    }));

    const userCountryId = useAppSelector((state) => state.auth.userData?.countryId);

    const bankLinkCountriesIndexInitial = bankLinkCountriesSorted
        .findIndex(({
            id
        }) => userCountryId === id);

    const [bankLinkCountriesIndex, setBankLinkCountriesIndex] = useState<number>(bankLinkCountriesIndexInitial !== -1 ? bankLinkCountriesIndexInitial : 0);

    return (
        <InfoBox
            style={{
                marginBottom: 15
            }}
            active={paymentTransferTypeEnum === types[afterDeadline ? "after" : "before"].bankLink || paymentTransferTypeEnum === types[afterDeadline ? "after" : "before"].mip}
            title={t("pc_pc_internet_bank")}
            message={t("pc_pc_internet_bank_promotional_message")}
            customIcon={<InternetBankIcon/>}
            // miniIcon
            onClick={() => {
                toggleSelectedPayment(types[afterDeadline ? "after" : "before"].bankLink);
                dispatch(setTransactionFee(0, 0));

                return false;
            }}
            type="custom"
        >
            <div className={styles.Option}>
                <div className={styles.OptionText}>
                    <Trans i18nKey="pc_pc_internet_bank_notice">
                        <p>
                            There are two ways to pay via internet
                            bank: directly via its Internet banking
                            system or indirectly using{" "}
                            <a href="#">
                                payment initiation service (PIS)
                            </a>
                            . If you choose to pay directly via
                            Internet bank, a transaction fee may be
                            applied by the bank and added to your
                            booking price. Payments done via payment
                            initiation service are free of charge.
                        </p>
                    </Trans>
                </div>
                <div className={styles.Select}>
                    <BasicDropdown<number, string>
                        required={true}
                        hideErrors={true}
                        dropDownOptions={bankLinkCountriesFormed}
                        label={t("pc_pc_select_bank_country") + ":"}

                        onDropdownChange={(val, label, i) => {
                            setBankLinkCountriesIndex(i || 0);

                            if (bankLinkCountriesIndex !== i) {
                                dispatch(setSelectedPaymentLink(undefined));
                            }
                        }}
                        dropDownValue={bankLinkCountriesFormed[bankLinkCountriesIndex].value}
                        className="simple"
                    />
                </div>

                <div className={styles.SelectBank}>
                    <div className={styles.BankChoices}>
                        {mips[bankLinkCountries[bankLinkCountriesIndex].id] &&
                            mips[bankLinkCountries[bankLinkCountriesIndex].id].length > 0 &&
                            mips[bankLinkCountries[bankLinkCountriesIndex].id]
                                .sort((a: any, b: any) => a.order - b.order)
                                .map((option: WebBankLinkOptionPropTypes, i: number) => {
                                    const {
                                        id, mip, fee, displayFee, payseraId, paymentProviderId
                                    } = option;

                                    return (
                                        <div
                                            key={`${i}-${id}`}
                                            className={styles.BankChoice}
                                        >
                                            <SelectableCard
                                                onClick={() => {
                                                    toggleSelectedPayment(types[afterDeadline ? "after" : "before"].mip, true);
                                                    dispatch(setSelectedPaymentLink(option));
                                                    dispatch(calculateTransactionFee());
                                                }}
                                                image={(
                                                    <img
                                                        alt={payseraId}
                                                        src={`//www.hotelston.com/resources/images/banks/${paymentProviderId}.png`}
                                                    />
                                                )}
                                                active={selectedPaymentLink && (selectedPaymentLink.id === id && selectedPaymentLink.mip === mip)}
                                                fee={withOtherCurrencyAndMarkup ? displayFee * markupAmount : fee}
                                                currency={currency}
                                            />
                                        </div>
                                    );
                                })}
                        {bankLinks[bankLinkCountries[bankLinkCountriesIndex].id] &&

                            bankLinks[bankLinkCountries[bankLinkCountriesIndex].id].length > 0 && bankLinks[bankLinkCountries[bankLinkCountriesIndex].id]
                            .sort((a: any, b: any) => a.order - b.order)
                            .map((option: WebBankLinkOptionPropTypes, i: number) => {
                                const {
                                    id, mip, fee, displayFee, payseraId, paymentProviderId
                                } = option;

                                return (
                                    <div
                                        key={`${i}-${id}`}
                                        className={styles.BankChoice}
                                    >
                                        <SelectableCard
                                            onClick={() => {
                                                toggleSelectedPayment(types[afterDeadline ? "after" : "before"].bankLink, true);

                                                dispatch(setSelectedPaymentLink(option));
                                                if (withOtherCurrencyAndMarkup) {
                                                    dispatch(setTransactionFee(fee, displayFee));
                                                } else {
                                                    dispatch(setTransactionFee(fee, fee));
                                                }
                                            }}
                                            image={(
                                                <img
                                                    alt={payseraId}
                                                    src={`//www.hotelston.com/resources/images/banks/${payseraId || paymentProviderId}.png`}
                                                />
                                            )}
                                            active={selectedPaymentLink && (selectedPaymentLink.id === id && selectedPaymentLink.mip === mip)}
                                            fee={withOtherCurrencyAndMarkup ? displayFee * markupAmount : fee}
                                            currency={currency}
                                        />
                                    </div>
                                );
                            })}
                    </div>
                </div>

                <div
                    style={{
                        marginTop: 30,
                        marginBottom: 30
                    }}
                    className="h-divider"
                />
                {children}
            </div>
        </InfoBox>
    );
}

export default InternetBankBlock;