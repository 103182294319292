import React, {ReactElement, useState} from "react";
import {useTranslation} from "react-i18next";
import styles from "./CancelAndSearchAgain.module.scss";
import UserButton from "../../base/UserButton";
import CustomCheckBox from "../../base/Input/CustomCheckBox";
import {UserButtonVariant} from "../../base/UserButton/UserButton";

function CancelAndSearchAgain({
    cancelAndSearchAgain,
    routeBack,
    canSavePassengerDetails
}: any): ReactElement {
    const [savePassengerDetails, toggleSavePassengerDetails] = useState(true);
    const {t} = useTranslation();

    return (
        <div className={styles.root}>
            {canSavePassengerDetails && (
                <div className={styles.box}>
                    <CustomCheckBox
                        label={(
                            <span className={styles.label}>
                                {t("casa_casa_save_passenger_details")}
                            </span>
                        )}
                        inputProps={{
                            checked: savePassengerDetails,
                            onChange: () => toggleSavePassengerDetails(!savePassengerDetails)
                        }}
                    />

                    <div className={styles.comment}>
                        {t("casa_casa_save_passenger_to_clipboard")}
                    </div>
                </div>
            )}
            <UserButton
                text={t("casa_casa_cancel_and_search_again")}
                variant={UserButtonVariant.SECONDARY}
                buttonProps={{
                    onClick: () => cancelAndSearchAgain(
                        routeBack,
                        canSavePassengerDetails
                            ? savePassengerDetails
                            : false
                    ),
                    type: "button",
                    style: {
                        height: "50px",
                        width: "260px",
                        marginLeft: "auto"
                    }
                }}
            />
        </div>
    );
}

export default CancelAndSearchAgain;
