export default function scrollToFormError(form: HTMLFormElement): void {
    const {elements} = form;
    for (let i = 0; i < form.elements.length; i++) {
        const element = elements[i];
        const elem1 = (element as HTMLInputElement);
        if (elem1.checkValidity) {
            const result = elem1.checkValidity();
            if (!result) {
                element.scrollIntoView({behavior: "smooth", block: "center", inline: "center"});
                //console.log(elem1.id + " " + result);
                break;
            } else {
                //console.log(elem1.id + " " + result + " failed");
            }
        } else {
            //console.log("no validation method");
        }
    }
}