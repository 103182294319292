import React, {
    ReactElement, useCallback, useMemo, useState
} from "react";
import cx from "classnames";
import _ from "lodash";
import styles from "../ResponsiveTable.module.scss";
import {
    MobileTableVariant,
    TableHeading,
    TableRowActionCell,
    TableRowCellData
} from "../ResponsiveTable.base";
import {ReactComponent as ArrowIcon} from "../../../../assets/icons/arrow.svg";
import traverseRowChildrenText from "../../../../utils/traverseChildrenText";

export type TableRowProps = {
    actionable?: boolean;
    onClick?: () => void;
    mobileVariant: MobileTableVariant;

    tDatas?: TableRowCellData[];
    actionTd?: TableRowActionCell;

    variant?: string;
    headings: TableHeading[];
    maxColumnWidth: number;

    isMobile: boolean;
};

const TableRowElement = ({
    actionable,
    onClick,
    actionTd,
    tDatas,

    headings,
    variant,
    mobileVariant,
    maxColumnWidth,
    isMobile
}: TableRowProps): ReactElement => {
    const key = _.uniqueId("TableRow_");
    const [accordionExpanded, setAccordionExpanded] = useState(false);

    const rowOnClickInner = useCallback(() => {
        if (!isMobile || mobileVariant !== "Accordion") {
            if (onClick) {
                onClick();
            }

            return;
        }

        if (!accordionExpanded) {
            setAccordionExpanded(true);
            return;
        }

        if (onClick) {
            onClick();
        }
    }, [accordionExpanded, isMobile, mobileVariant, onClick]);

    const rowSummary = useMemo(() => {
        if (!isMobile || mobileVariant !== "Accordion") {
            return undefined;
        }

        let rowSummary = "";
        tDatas?.forEach((item) => {
            rowSummary += traverseRowChildrenText(item.element) + ", ";
        });
        rowSummary = rowSummary.substr(0, rowSummary.length - 2);

        return rowSummary;
    }, [isMobile, mobileVariant, tDatas]);

    const tDatasInner: (TableRowCellData | TableRowActionCell)[] = useMemo(() => {
        if (actionTd && actionTd.actionable) {
            return [...(tDatas || []), actionTd];
        }

        if (tDatas) {
            return tDatas;
        }

        return [];
    }, [actionTd, tDatas]);

    return (
        <tr
            key={key}
            className={cx(
                styles.TableRow,
                accordionExpanded && styles.TableRowExpanded,
                variant && _.get(styles, variant)
            )}
            onClick={rowOnClickInner}
        >
            {tDatasInner.map(
                ({
                    element,
                    actionable,
                    onClick: tdOnClick
                }, tdIndex) => (
                    <td
                        style={{
                            width: (maxColumnWidth !== 0 && maxColumnWidth !== Infinity) || isMobile ? undefined : "auto",
                            maxWidth: maxColumnWidth !== 0 && maxColumnWidth !== Infinity && !isMobile ? maxColumnWidth : undefined
                        }}
                        key={tdIndex}
                        className={cx(
                            styles.TableCell,
                            actionable && styles.TableActionCell
                        )}
                        onClick={(e) => {
                            if (!e.currentTarget.contains(e.target as Element)) {
                                e.stopPropagation();
                                // e.preventDefault();
                            }

                            if (tdOnClick) {
                                tdOnClick();
                            }
                        }}
                        data-td-label-mobile={headings[tdIndex]?.label}
                        data-table-row-summary={rowSummary}
                    >
                        <div className={styles.TableCellContentContainer}>
                            {element}
                        </div>

                        {mobileVariant === "Accordion" && tdIndex === 0 && (
                            <div
                                className={styles.AccordionExpandCollapse}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    e.preventDefault();

                                    setAccordionExpanded(!accordionExpanded);
                                }}
                            >
                                <ArrowIcon />
                            </div>
                        )}
                    </td>
                )
            )}
        </tr>
    );
};

export default TableRowElement;