import ReactDOM from "react-dom";
import React, {
    ReactElement, useEffect, useRef
} from "react";
import _ from "lodash";
import styles from "../../common/Modal/ModalWithOverlay.module.scss";

const modalRoot = document.getElementById("modal-root");
const zIndexMultiplierBase = 100000000;
const modals: HTMLDivElement[] = [];

export type ModalProps = {
    children?: ReactElement | ReactElement[];
};

const Modal = ({children}: ModalProps): React.ReactPortal | ReactElement => {
    const el = useRef<HTMLDivElement>(document.createElement("div"));

    useEffect(() => {
        el.current.classList.add(styles.root);
        el.current.style.zIndex = ((modals.length + 1) * zIndexMultiplierBase).toString();

        modals.push(el.current);
        modalRoot?.append(el.current);

        return () => {
            if (!el) {
                return;
            }

            if (el.current && modalRoot?.contains(el.current)) {
                el.current.parentNode?.removeChild(el.current);
            }

            modals.unshift(el.current);
            modals.forEach((item, index) => {
                _.set(item.style, "zIndex", ((index + 1) * zIndexMultiplierBase).toString());
            });
        };
    }, [el]);

    return ReactDOM.createPortal(
        children,
        el.current
    );
};

export default Modal;