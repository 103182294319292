import React, {
    ComponentClass,
    FunctionComponent,
    ReactElement
} from "react";
import cx from "classnames";
import {ReactComponent as SearchIcon} from "assets/icons/search.svg";
import {ButtonProps} from "reactstrap";
import CustomTag, {CustomTagType} from "components/utils/CustomTag";
import styles from "./UserButton.module.scss";

export enum UserButtonVariant {
    PRIMARY = "primary",
    SECONDARY = "secondary",
    WHITE = "white"
}

type Props = {
    text: string;
    disabledButton?: boolean;
    buttonProps?: ButtonProps;
    searchButton?: boolean;
    customIcon?: ReactElement;
    variant: UserButtonVariant;
    customTag?: ComponentClass<unknown> | FunctionComponent<unknown> | CustomTagType | unknown;
    className?: string;

    children?: ReactElement | ReactElement[];
};

const UserButton = (props: Props): ReactElement => {
    const {
        text = "",
        disabledButton,
        buttonProps = {
            type: "button"
        },
        searchButton = false,
        customIcon = false,
        variant = UserButtonVariant.PRIMARY,
        customTag,
        className,

        children
    } = props;

    return (
        <CustomTag
            elementTag={customTag as any || CustomTagType.BUTTON}
            {...buttonProps}
            disabled={!!disabledButton}
            className={cx(
                styles.root,
                className,
                styles[variant],
                (searchButton || customIcon) && styles.withIcon,
                disabledButton && styles.disabled
            )}
        >
            <div>
                {text}{children}

                {searchButton && (
                    <SearchIcon className={styles.search} />
                )}
                {customIcon && customIcon}
            </div>
        </CustomTag>
    );
};

export default UserButton;
