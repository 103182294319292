import React, {
    FormEvent, Fragment, PureComponent
} from "react";
import _ from "lodash";
import cx from "classnames";
import {connect, ConnectedProps} from "react-redux";
import {WithTranslation, withTranslation} from "react-i18next";
import {scroller} from "react-scroll";
import {RootState} from "redux/store/store.init";
import {handleCXLDate} from "redux/actions/hotelSearchResults.actions";
import {getBookingOptions} from "redux/actions/hotelBooking.actions";
import UserButton from "../../base/UserButton";
import MoreLessButton from "@hotelston_web_frontend_components/components/common/MoreLessButton";
import Rooms from "./Rooms";
import {
    RoomOfferPropTypes,
    SelectedRadiosElementType,
    SelectedRadiosType
} from "proptypes/PropTypeObjects";
import {UserButtonVariant} from "../../base/UserButton/UserButton";
import {getPriceWithCurrencySymbol} from "@hotelston_web_frontend_utils/currency/getPriceWithCurrency";
import Tippy from "../Tippy/Tippy";
import scrollToFormError from "../../../utils/scrollToFormError";
import createLuxonDate from "../../../utils/date/createLuxonDate"
import {ReactComponent as DeleteIcon} from "../../../assets/icons/delete.svg";
import styles from "./RoomsContainer.module.scss";

type Props = ConnectedProps<typeof connector> & WithTranslation & {
    hotelId: number;
    minShowResults?: number;
    fullWidthMap?: boolean;
    roomOffers: RoomOfferPropTypes[][];
    isActivePinnedOffers: boolean

    initialShowAllRooms?: boolean;
    getIsAllRoomsShown?: (showAllRooms: boolean) => void
    fullWidthView?: boolean;
    compactView?: boolean;
    className?: string;

    formRef?: React.Ref<HTMLFormElement>;
};

type RoomsContainerState = {
    showAllRooms: boolean,
    canBeMoreRooms: boolean,
    selectedRadios: SelectedRadiosType,
    firstProviderChosen?: number;
    hasHotDeals: boolean;
};

class RoomsContainer extends PureComponent<Props, RoomsContainerState> {
    constructor(props: Props) {
        super(props);
        this.state = {
            showAllRooms: false,
            canBeMoreRooms: false,
            selectedRadios: {},
            firstProviderChosen: undefined,
            hasHotDeals: false
        };
    }

    componentDidMount() {
        const {
            roomOffers,
            minShowResults,
            initialShowAllRooms = false,
            submitedFilters,
            getIsAllRoomsShown
        } = this.props;

        this.handleShowMoreRooms(roomOffers, minShowResults);

        if (initialShowAllRooms) {
            this.handleShowAllRooms(true);
        }

        if (getIsAllRoomsShown) {
            getIsAllRoomsShown(this.state.showAllRooms);
        }
    }

    componentDidUpdate(prevProps: Props, prevState: RoomsContainerState) {
        const {
            getIsAllRoomsShown,
            fullWidthMap,
            roomOffers,
            minShowResults
        } = this.props;

        this.handleShowMoreRooms(roomOffers, minShowResults);

        const {showAllRooms} = this.state;
        if (prevProps.fullWidthMap !== fullWidthMap && getIsAllRoomsShown) {
            getIsAllRoomsShown(showAllRooms);
        }

        if (prevState.showAllRooms !== showAllRooms && getIsAllRoomsShown) {
            getIsAllRoomsShown(showAllRooms);
        }
    }

    getTotalPrice = (selectedRadios: SelectedRadiosType) => Object.keys(selectedRadios).reduce((previous, key) => previous + selectedRadios[key].finalPrice, 0);

    handleShowAllRooms = (initial?: boolean) => {
        const {hotelId} = this.props;

        if (!initial) {
            scroller.scrollTo(`${hotelId}allRooms`, {smooth: true, offset: -120});
        }

        /* eslint-disable */
        this.setState({ showAllRooms: !this.state.showAllRooms });
        /* eslint-enable */
    };

    handleRadioInput = ({target: {name}}: { target: { name: string } }, data: SelectedRadiosElementType | undefined) => {
        if (!data) {
            const {selectedRadios} = this.state;
            const omitted = _.omit(selectedRadios, [name]);
            this.setState(({
                selectedRadios: {
                    ...omitted
                }
            }));

            if (Object.keys(omitted).length === 0) {
                this.setState({
                    firstProviderChosen: undefined
                });
            }

            return;
        }

        let newState = {
            ...this.state.selectedRadios,
            [name]: {
                ...data
            }
        }

        const matchingRoomOffer = this.props.roomOffers.flatMap((offer) => offer).find((offer) => {
            if (newState[name].roomId === offer.roomId) {
                return offer;
            }
        });

        if (this.state.firstProviderChosen !== matchingRoomOffer.providerId) {
            newState = {
                [name]: {
                    ...data
                }
            };

            this.setState({
                firstProviderChosen: matchingRoomOffer.providerId
            });
        }

        this.setState({
            selectedRadios: newState
        });
    };

    handleShowMoreRooms = (roomOffers: RoomOfferPropTypes[][], minShowResults = 1) => {
        let showMore = false;
        let hasHotDeals = false;
        for (let index = 0; index < roomOffers.length; index++) {
            if (showMore && hasHotDeals) {
                break;
            }

            if (!hasHotDeals && roomOffers[index].find((rOffer) => rOffer.hotDeal)) {
                hasHotDeals = true;
            }

            if (roomOffers[index].length > minShowResults) {
                showMore = true;
            }
        }

        this.setState({
            canBeMoreRooms: showMore,
            hasHotDeals: hasHotDeals
        });

        return false;
    };

    handleSubmit = (e: FormEvent<HTMLFormElement>) => {
        const {selectedRadios} = this.state;
        const {getBookingOptions, hotelId} = this.props;

        if (!e) {
            return;
        }

        const form = e.target as HTMLFormElement;
        e.preventDefault();
        const valid = form.checkValidity();
        if (!valid) {
            scrollToFormError(form);
        }

        if (valid) {
            getBookingOptions(hotelId, Object.keys(selectedRadios).map((key) => selectedRadios[key]));
        }
    };

    render() {
        const {
            getBookingOptions,
            handleCXLDate,
            cxlUpdatingRoomIds,
            hotelId,
            roomOffers,
            minShowResults = 1,
            fullWidthMap = false,
            compactView = false,
            fullWidthView,
            className = "",
            hotelAmount = 1,
            checkIn,
            checkOut,
            currency,
            t,
            isActivePinnedOffers,
            filters,
            activeFilters,
            formRef,
            companyMarkup,
            allowToBook
        } = this.props;

        const {
            showAllRooms,
            selectedRadios,
            canBeMoreRooms
        } = this.state;

        const nightsCalculated = Math.floor(createLuxonDate(checkOut).diff(createLuxonDate(checkIn), "days").days);

        return (
            <form
                ref={formRef}
                id={hotelId + "allRooms"}
                onSubmit={this.handleSubmit}
                className={cx(styles.Root, className, showAllRooms && styles.AllRooms, compactView && styles.CompactView)}
            >
                <div className={cx(styles.MultiRooms, showAllRooms && styles.MultiRoomsActive, isActivePinnedOffers && styles.PinnedActive)}>
                    {roomOffers.map((roomOffer: RoomOfferPropTypes[], i: number) => (
                        <Fragment key={i}>
                            {(roomOffers && roomOffers.length > 1) && (
                                <div
                                    key={"roomNumber" + i}
                                    onClick={() => roomOffers.length > 1 && showAllRooms && scroller.scrollTo(`${hotelId}roomContainer${i}`, {smooth: true, offset: -i * 30})}
                                    className={styles.RoomNumber}
                                >
                                    <div className={styles.RoomNumberHeading}>
                                        {t("rooms_rc_room_number", {count: i + 1})}
                                    </div>

                                    <div className={styles.RoomNumberLefter}>
                                        {selectedRadios[`roomNum${i + 1}`] && (
                                            <>
                                                <div className={styles.RoomNumberLefterContent}>
                                                    {t("room_rc_room_selected") + ": " + selectedRadios[`roomNum${i + 1}`].roomType + " " + selectedRadios[`roomNum${i + 1}`].boardType + " " + getPriceWithCurrencySymbol(selectedRadios[`roomNum${i + 1}`].displayPrice, currency)}
                                                </div>
                                                <div className={styles.Checkbox} />
                                            </>
                                        )}

                                        {/*{!selectedRadios[`roomNum${i + 1}`] && (*/}
                                        {/*    <div className={styles.RoomNumberLefterContent}>*/}
                                        {/*        {t("rooms_rc_room", {count: roomOffer.length})}*/}
                                        {/*    </div>*/}
                                        {/*)}*/}

                                        {selectedRadios[`roomNum${i + 1}`] && (
                                            <div
                                                className={styles.RoomNumberLefterRemove}
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    e.preventDefault();

                                                    this.handleRadioInput({target: {name: `roomNum${i + 1}`}}, undefined);
                                                }}
                                            >
                                                <DeleteIcon />
                                            </div>
                                        )}
                                    </div>
                                </div>
                            )}

                            <div
                                key={"roomsContainer" + i}
                                id={hotelId + "roomContainer" + i}
                                className={styles.RoomsContainer}
                            >
                                <Rooms
                                    data={{
                                        hotelAmount,
                                        checkIn: checkIn,
                                        currency,
                                        cxlUpdatingRoomIds,
                                        selectedRadios,
                                        showAllRooms,
                                        minShowResults,
                                        hotelId,
                                        roomCount: roomOffers.length,
                                        roomOffers: roomOffer,
                                        roomNo: i,
                                        nights: nightsCalculated,
                                        activeFilters,
                                        filters,
                                        hasHotDeals: this.state.hasHotDeals,
                                        fullWidthView: fullWidthView || (fullWidthMap && (showAllRooms || className === "no-image"))
                                    }}
                                    handleCXLDate={handleCXLDate}
                                    getBookingOptions={getBookingOptions}
                                    handleRadioInput={this.handleRadioInput}
                                    className={styles.Room}
                                    compactView={compactView}
                                    companyMarkup={companyMarkup}
                                    firstProviderChosen={this.state.firstProviderChosen}
                                />
                            </div>
                        </Fragment>
                    ))}
                    <div className={styles.RoomActions}>
                        {canBeMoreRooms && (
                            <MoreLessButton
                                onClick={this.handleShowAllRooms as (...args: unknown[]) => void}
                                moreLabel={t("rooms_rc_show_more_room_choices")}
                                lessLabel={t("rooms_rc_show_less_room_choices")}
                                canBeMore={!showAllRooms}
                            />
                        )}
                        <div className={styles.Book}>
                            {this.props.roomOffers.length > 1 && (
                                <>
                                    {this.getTotalPrice(selectedRadios) > 0 && (
                                        <span className={styles.Total}>
                                            {t("rooms_rc_total")}:{" "}{getPriceWithCurrencySymbol(this.getTotalPrice(selectedRadios), currency)}
                                        </span>
                                    )}

                                    {allowToBook ? (
                                        <UserButton
                                            text={t("rooms_rc_book_button")}
                                            variant={Object.keys(selectedRadios).length === roomOffers.length
                                                ? UserButtonVariant.PRIMARY
                                                : UserButtonVariant.SECONDARY}
                                            buttonProps={{
                                                style: {
                                                    maxWidth: 140
                                                },
                                                type: "submit"
                                            }}
                                        />
                                    ) : (
                                        <Tippy content={(
                                            <span>{t("co_co_net_price_notice")}</span>
                                        )}
                                        >
                                            <span>
                                                <UserButton
                                                    text={t("rooms_rc_book_button")}
                                                    variant={Object.keys(selectedRadios).length === roomOffers.length
                                                        ? UserButtonVariant.PRIMARY
                                                        : UserButtonVariant.SECONDARY}
                                                    buttonProps={{
                                                        style: {
                                                            maxWidth: 140
                                                        },
                                                        type: "submit"
                                                    }}
                                                />
                                            </span>
                                        </Tippy>
                                    )}
                                </>
                            )}
                        </div>
                    </div>
                </div>

            </form>
        );
    }
}

const mapStateToProps = (state: RootState) => ({
    activeFilters: state.hotelSearchResults.activeFilters,
    filters: state.hotelSearchResults.filters,
    submitedFilters: state.hotelSearchResults.submitedFilters,
    cxlUpdatingRoomIds: state.hotelSearchResults.cxlUpdatingRoomIds,
    hotelAmount: state.auth.userData?.companyMarkups[state.auth.currentCompanyMarkupIndex || 0].hotelAmount,
    checkIn: state.hotelSearch.formData.checkIn,
    checkOut: state.hotelSearch.formData.checkOut,
    currency: state.currency.currentCurrency,
    companyMarkup: state.auth.userData?.companyMarkups[state.auth.currentCompanyMarkupIndex || 0].hotelAmount || 1,
    allowToBook: state.auth.userData?.company?.allowToBook,
    ownUser: state.auth.userData?.companyUser?.ownUser || false
});

const connector = connect(mapStateToProps, {handleCXLDate, getBookingOptions});
export default withTranslation()(connector(RoomsContainer));
