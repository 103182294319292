export default function getCircleInnerPaths(point: google.maps.LatLng | null, radius: number, dir: number): google.maps.LatLng[] {
    if (!point || !radius) {
        return [];
    }

    const {google} = window;
    const bounds = new google.maps.LatLngBounds();
    const d2r = Math.PI / 180; // degrees to radians
    const r2d = 180 / Math.PI; // radians to degrees
    const earthsradius = 6371; //km
    const points = 32;
    let start;
    let end;
    // find the raidus in lat/lon
    const rlat = (radius / earthsradius) * r2d;
    const rlng = rlat / Math.cos(point.lat() * d2r);
    const extp: google.maps.LatLng[] = [];

    if (dir === 1) {
        start = 0;
        end = points + 1;
    } else {
        // one extra here makes sure we connect the ends
        start = points + 1;
        end = 0;
    }

    for (let i = start; dir === 1 ? i < end : i > end; i += dir) {
        const theta = Math.PI * (i / (points / 2));
        const ey = point.lng() + rlng * Math.cos(theta); // center a + radius x * cos(theta)
        const ex = point.lat() + rlat * Math.sin(theta); // center b + radius y * sin(theta)
        extp.push(new google.maps.LatLng(ex, ey));
        bounds.extend(extp[extp.length - 1]);
    }

    return extp;
}