import React, {ReactElement} from "react";
import {ReactComponent as ArriveIcon} from "assets/icons/plane arrives.svg";
import {ReactComponent as DepartIcon} from "assets/icons/plane departs.svg";
import cx from "classnames";
import {useTranslation} from "react-i18next";
import {TransferDetailsPropTypes} from "../../../proptypes/PropTypeObjects";
import styles from "./TransferFlightInfoBox.module.scss";
import useLocaleDateTimeFormat, {LocaleDateFormatTypeEnum} from "../../utils/Internationalization/useLocaleDateTimeFormat";
import {convertToServerTime} from "../../../utils/dateUtils";
import createLuxonDate from "../../../utils/date/createLuxonDate"
import {DateTime} from "luxon"

type Props = {
    arrivalDetails?: TransferDetailsPropTypes;
    departureDetails?: TransferDetailsPropTypes;
    simplified: boolean;
    arrivalTransfer?: boolean;
    arrivalFlightDate?: DateTime;
    departureTransfer?: boolean;
    departureFlightDate?: DateTime;
    className?: string;

    withBorder?: boolean;
};

function TransferFlightInfoBox({
    className,
    simplified = false,
    arrivalTransfer,
    arrivalFlightDate,
    departureTransfer,
    departureFlightDate,
    arrivalDetails,
    departureDetails,

    withBorder = false
}: Props): ReactElement {
    const {t} = useTranslation();
    const dateTimeFormat = useLocaleDateTimeFormat(LocaleDateFormatTypeEnum.DATE_TIME);

    return (
        <div className={cx(styles.Root, !simplified && !withBorder && styles.Detailed, className)}>
            {arrivalTransfer && (
                <div className={styles.Box}>
                    <div className={styles.Heading}>
                        <div className={styles.Label}>
                            <ArriveIcon />
                            <span>{t("tfib_tfib_arrival_flight")}</span>
                        </div>
                    </div>

                    <dl className={styles.Text}>
                        {simplified && (
                            <>
                                <dt>{t("tfib_tfib_arrival_to_time")}:</dt>
                                <dd>{createLuxonDate(arrivalFlightDate).toFormat(dateTimeFormat)}</dd>
                            </>
                        )}

                        {!simplified && (
                            <>
                                <dt>{t("tfib_tfib_arrival_from")}:</dt>
                                <dd>{arrivalDetails?.airportFrom}</dd>

                                <dt>{t("tfib_tfib_arrival_to")}:</dt>
                                <dd>{arrivalDetails?.airportTo}</dd>

                                <dt>{t("tfib_tfib_arrival_to_time")}:</dt>
                                <dd>{createLuxonDate(arrivalFlightDate).toFormat(dateTimeFormat)}</dd>

                                <dt>{t("tfib_tfib_arrival_flight_number")}:</dt>
                                <dd>{arrivalDetails?.flightNumber}</dd>

                                <dt>{t("tfib_tfib_arrival_airline_name")}:</dt>
                                <dd>{arrivalDetails?.airlineName}</dd>
                            </>
                        )}
                    </dl>
                </div>
            )}

            {departureTransfer && (
                <div className={styles.Box}>
                    <div className={styles.Heading}>
                        <div className={styles.Label}>
                            <DepartIcon />
                            <span>{t("tfib_tfib_departure_flight")}</span>
                        </div>
                    </div>

                    <dl className={styles.Text}>
                        {simplified && (
                            <>
                                <dt>{t("tfib_tfib_departure_to_time")}:</dt>
                                <dd>{createLuxonDate(departureFlightDate).toFormat(dateTimeFormat)}</dd>
                            </>
                        )}

                        {!simplified && (
                            <>
                                <dt>{t("tfib_tfib_departure_from")}:</dt>
                                <dd>{departureDetails?.airportFrom}</dd>

                                <dt>{t("tfib_tfib_departure_to")}:</dt>
                                <dd>{departureDetails?.airportTo}</dd>

                                <dt>{t("tfib_tfib_departure_to_time")}:</dt>
                                <dd>{createLuxonDate(departureFlightDate).toFormat(dateTimeFormat)}</dd>

                                <dt>{t("tfib_tfib_departure_flight_number")}:</dt>
                                <dd>{departureDetails?.flightNumber}</dd>

                                <dt>{t("tfib_tfib_departure_airline_name")}:</dt>
                                <dd>{departureDetails?.airlineName}</dd>
                            </>
                        )}
                    </dl>
                </div>
            )}
        </div>
    );
}
export default TransferFlightInfoBox;
