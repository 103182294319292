import React from "react"
import PageTemplate from "components/base/PageTemplate/PageTemplate";
import HeaderContainer from "components/base/Header";
import Righter from "components/base/Righter";
import Lefter from "components/base/Lefter";
import Footer from "components/base/Footer";
import {connect, ConnectedProps} from "react-redux";
import Search from "./Search";
import Searching from "./Searching";
import SearchResults from "./SearchResults";
import Booking from "./Booking";
import BookingChanged from "./BookingChanged";
import Payment from "./Payment";
import ErrorBoundary from "../../components/utils/ErrorBoundary/ErrorBoundary";
import {RootState} from "redux/store/store.init";
import {Navigate, Route, Routes} from "react-router-dom";
import SplitPayment from "views/Transfers/SplitPayment/SplitPayment";

type Props = ConnectedProps<typeof connector>;

const TransferContainer = ({
    isSearching,
    allowedInBookingPages
}: Props) =>
// if (!allowedInBookingPages) {
//     return (
//         <Navigate to="/transfers/" />
//     );
// }

    (
        <PageTemplate
            header={<HeaderContainer/>}
            footer={<Footer/>}
            lefter={<Lefter/>}
            righter={<Righter/>}
        >
            <ErrorBoundary>
                <Routes>
                    <Route path="/" element={<Search/>}/>

                    <Route path="/error/:error" element={<Search/>}/>

                    <Route path="/search/" element={isSearching ? <Searching/> : <SearchResults/>}/>

                    <Route path="/booking" element={<Booking/>}/>

                    <Route path="/booking/changed" element={<BookingChanged/>}/>

                    <Route path="/booking/payment" element={<Payment/>}/>

                    <Route path="/booking/split-payment" element={<SplitPayment/>}/>

                    <Route path="*" element={<Navigate to="/not-found"/>}/>
                </Routes>
            </ErrorBoundary>
        </PageTemplate>
    )


const mapStateToProps = ({
    transferSearch,
    transferBooking
}: RootState) => ({
    isSearching: transferSearch.isSearching,
    allowedInBookingPages: transferBooking.allowedInBookingPages
});

const connector = connect(mapStateToProps);
export default connector(TransferContainer);