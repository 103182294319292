import {LastLocationKey, LocationBaseKey} from "redux/reducers/actions/router.reducer.actions"
import {batch} from "react-redux"
import {
    GoogleMapsCirclePrimitive,
    GoogleMapsPolygonPrimitive,
    HotelDetails,
    HotelOfferPropTypes,
    HotelSearchResultsFiltersPropTypes, NationalityPropTypes,
    ProviderWithOfferPropTypes,
    SimpleHotelOfferPropTypes
} from "proptypes/PropTypeObjects"
import {CommonActionReturnType} from "../store/store.init"
import HotelSearchResultsTypes from "../constants/hotelSearchResults.constants"
import IAppActions from "../store/store.actions"
import MapTypes from "../constants/map.constants"
import * as selectors from "../selectors/hotelSearchResults.selector"
import {replaceQueryParams} from "utils/url/queryParams"
import getHotelsDetailsAPI from "../services/hotelSearchResults.services"
import setLastLocation from "./router.actions"
import HotelSearchTypes from "../constants/hotelSearch.constants"
import queueWorker, {resetWorkerHashes, WorkerType} from "utils/compute/queueWorker"
import {AllFiltersWorkerProps} from "utils/compute/filterWorker"
import {MapFilterWorkerProps} from "utils/compute/mapFilterWorker"
import router from "views/router/router"
import {HotelFilterStatistics, HotelSearchAllFiltersReturn} from "proptypes/HotelSearchObjects";
import {StatisticsWorkerProps} from "utils/compute/statisticsWorker";
import {on401Error} from "redux/actions/auth.base.actions"
import {handleNatChange} from "redux/actions/hotelSearch.actions"

export const removeMapFilter = (hotelDetails = true, updateQueryParams: boolean): CommonActionReturnType => (dispatch, getState) => {
    const {
        map: {
            polygon,
            circle
        }
    } = getState()

    polygon?.setMap(null)
    circle?.setMap(null)

    dispatch({type: HotelSearchResultsTypes.REMOVE_MAP_FILTER});
    dispatch(updateFilters(true, hotelDetails, updateQueryParams));
};

export const resetMap = (hotelDetails = true, updateQueryParams = false): CommonActionReturnType => (dispatch) => {
    dispatch(removeMapFilter(hotelDetails, updateQueryParams));
    dispatch({type: MapTypes.RESET_MAP});
};

export const requestMapFilter = (): IAppActions => ({
    type: HotelSearchResultsTypes.REQ_MAP_FILTER
});

export const applyMapFilter = (): CommonActionReturnType => (dispatch, getState) => {
    const {
        polygon,
        circle
    } = getState().map;

    const primitivePolygon = polygon ? {
        path: polygon.getPath().getArray().map((item) => ({
            lat: item.lat(),
            lng: item.lng()
        } as google.maps.LatLngLiteral))
    } as GoogleMapsPolygonPrimitive : undefined;

    const primitiveCircle = circle ? {
        center: {lat: circle.getCenter()?.lat(), lng: circle.getCenter()?.lng()} as google.maps.LatLngLiteral,
        radius: circle.getRadius()
    } as GoogleMapsCirclePrimitive : undefined;

    const doPrepareData = () => ({
        afterAllFilters: getState().hotelSearchResults.afterAllFilters,
        polygon: primitivePolygon,
        circle: primitiveCircle
    });

    const hash = JSON.stringify({
        ...primitivePolygon,
        ...primitiveCircle
    });

    void queueWorker<MapFilterWorkerProps, SimpleHotelOfferPropTypes[]>({
        type: WorkerType.MAP_FILTER,
        doPrepareData,
        beforeStart: () => dispatch(requestMapFilter()),
        onComplete: (result: SimpleHotelOfferPropTypes[]) => dispatch(addMapFilter(result)),
        hash
    });
};

export const getPagesCount = (): CommonActionReturnType => (dispatch, getState) => {
    const pagesCountSelector = selectors.pagesCountSelector();
    dispatch({
        type: HotelSearchResultsTypes.GET_PAGES_COUNT,
        pagesCount: pagesCountSelector(getState().hotelSearchResults)
    });
};

export const changePageNumber = (pageNumber: number): IAppActions => ({
    type: HotelSearchResultsTypes.CHANGE_PAGE_NUMBER,
    pageNumber
});

export const handleChangePage = (page: number, hotelDetails = true, updateQueryParam = true): CommonActionReturnType => async (dispatch, getState) => {
    const pageSelector = selectors.pageSelector();
    dispatch(changePageNumber(page));

    if (hotelDetails) {
        dispatch(getHotelsDetails(pageSelector(getState().hotelSearchResults)));
    }

    if (updateQueryParam) {
        void replaceQueryParams(undefined, {pn: page});
    }
};

export const addMapFilter = (dataForFilters: SimpleHotelOfferPropTypes[]): CommonActionReturnType => (dispatch, getState) => {
    dispatch({
        type: HotelSearchResultsTypes.ADD_MAP_FILTER,
        dataForFilters
    });
    dispatch(changeSort(getState().hotelSearchResults.sortBy, false, false, true, () => {
        dispatch(handleChangePage(1, true));
        dispatch(getPagesCount());
    }));
};

export const getMaxPrice = (): CommonActionReturnType => (dispatch, getState) => {
    const {currentCompanyMarkupIndex} = getState().auth;
    const hotelAmount = getState().auth.userData?.companyMarkups[currentCompanyMarkupIndex || 0].hotelAmount || 1;
    const maxPriceSelector = selectors.maxPriceSelector(hotelAmount);
    const {
        maxAllowed,
        maxPrice
    } = maxPriceSelector(getState().hotelSearchResults);
    dispatch({
        type: HotelSearchResultsTypes.GET_MAX_PRICE,
        maxPrice,
        maxAllowed
    });
};

export const searchSuccess = (): IAppActions => ({
    type: HotelSearchTypes.GET_DATA_SUCCESS
});

export const searchResultsRecieved = (
    hotelOffers: HotelOfferPropTypes[],
    allHotelOffers: HotelOfferPropTypes[] | SimpleHotelOfferPropTypes[],
    allProvidersWithOffers: ProviderWithOfferPropTypes[],
    allResultsCount: number,
    updateQueryParams: boolean,
    existingSearch = false
): CommonActionReturnType => (dispatch, getState) => {
    dispatch({
        type: HotelSearchResultsTypes.SEARCH_RESULTS_RECIEVED,
        hotelOffers,
        allHotelOffers,
        allProvidersWithOffers,
        allResultsCount
    });

    if (!existingSearch) {
        // dispatch(resetMap(false));
    }

    dispatch(getMaxPrice());
    dispatch(updateFilters(true, true, updateQueryParams, true, existingSearch));

    dispatch(changeSort(getState().hotelSearchResults.sortBy));
    dispatch(searchSuccess());
};

export const submitFilters = (): IAppActions => ({type: HotelSearchResultsTypes.FILTERS_SUBMITED});

const addFilter = (dataForFilters: SimpleHotelOfferPropTypes[]): IAppActions => ({
    type: HotelSearchResultsTypes.ADD_FILTER,
    dataForFilters
});

const addStatistics = (statistics: HotelFilterStatistics): IAppActions => ({
    type: HotelSearchResultsTypes.ADD_STATISTICS,
    statistics
});

export function addHotelFavoriteFilter(updateFinal: boolean = false): CommonActionReturnType {
    return (dispatch, getState) => {
        dispatch({
            type: HotelSearchResultsTypes.REQ_TOGGLE_FAVORITE
        });

        setTimeout(() => {
            const favoriteHotelSelector = selectors.favoriteHotelSelector();
            dispatch({
                type: HotelSearchResultsTypes.ADD_FAVORITE_FILTER,
                dataForFilters: favoriteHotelSelector(getState().hotelSearchResults)
            });
            dispatch(handleChangePage(1, true, false));
            dispatch(getPagesCount());

            dispatch({
                type: HotelSearchResultsTypes.REQ_TOGGLE_FAVORITE_COMPLETE,
                updateFinal
            });
        });
    };
}

export const filtersUpdated = (): IAppActions => ({type: HotelSearchResultsTypes.FILTERS_UPDATED});

export const clearFiltersState = (): CommonActionReturnType => {
    resetWorkerHashes();

    const clear = (): IAppActions => ({type: HotelSearchResultsTypes.CLEAR_FILTERS});
    return (dispatch) => {
        dispatch(clear());
        dispatch(submitFilters());
    };
};

export const clearFilters = (): CommonActionReturnType => {
    resetWorkerHashes();

    const clear = (): IAppActions => ({type: HotelSearchResultsTypes.CLEAR_FILTERS});
    return (dispatch) => {
        dispatch(clear());
        dispatch(resetMap(true, true));
        dispatch(getPagesCount());
        dispatch(submitFilters());
    };
};

export function getHotelsDetails(hotelIds: number[], changeFilterStatus = false, changeSearchSatus = false): CommonActionReturnType {
    const start = (): IAppActions => ({
        type: HotelSearchResultsTypes.START_GETTING_DETAILS
    });
    const success = (requestedHotelDetails: HotelDetails[], newHotelDetails?: HotelDetails[]): IAppActions => ({
        type: HotelSearchResultsTypes.RECEIVED_DETAILS,
        requestedHotelDetails,
        newHotelDetails
    });
    const failure = (error: any): IAppActions => ({
        type: HotelSearchResultsTypes.DETAILS_NOT_RECIEVED,
        error
    });
    return (dispatch, getState) => {
        const locale = getState().locale.currentLocale;
        dispatch(submitFilters());

        dispatch(start());

        const cachedHotelDetails = getState().hotelSearchResults.hotelDetailsCache.filter((e) => hotelIds?.includes(e.hotel.id));

        const alreadyHasDetailsIds = cachedHotelDetails.map((e) => e.hotel.id);

        const hotelIdsWithoutDetails = hotelIds?.filter((e) => !alreadyHasDetailsIds.includes(e));

        if (!hotelIdsWithoutDetails || hotelIdsWithoutDetails?.length === 0) {
            dispatch(success(cachedHotelDetails));
            // dispatch(success(getState().hotelSearchResults.hotelOffers));

            if (changeFilterStatus) {
                dispatch(filtersUpdated());
            }

            if (changeSearchSatus) {
                dispatch(searchSuccess());
            }

            return false;
        }

        getHotelsDetailsAPI(hotelIdsWithoutDetails, locale)
            .then((data) => {
                const lastLocation = setLastLocation(router.state.location, LocationBaseKey.HOTELS, LastLocationKey.LAST_SEARCH_RESULTS_LOCATION);

                if (lastLocation) {
                    dispatch(lastLocation);
                }

                dispatch(success([...cachedHotelDetails, ...data.hotelDetails], data.hotelDetails));

                if (changeFilterStatus) {
                    dispatch(filtersUpdated());
                }

                if (changeSearchSatus) {
                    dispatch(searchSuccess());
                }
            })
            .catch((error) => {
                // console.log(error, "----- error");
                const errorText = error.message ? error.message : String(error);
                if (error.response && error.response.status === 401) {
                    dispatch(on401Error(undefined, "/logout"));
                } else {
                    if (error.response && error.response.status && error.response.status !== 200) {
                        void router.navigate(`/hotels/error/http${error.response.status}`);
                    } else {
                        void router.navigate(`/hotels/error/${errorText}`);
                    }
                    dispatch({
                        type: HotelSearchTypes.GET_DATA_FAILURE,
                        error
                    });
                }
                dispatch(failure(errorText));
            });
        return true;
    };
}

export const setFormDataHotelId = (hotelId?: number): IAppActions => ({
    type: HotelSearchTypes.SET_HOTEL_ID,
    hotelId
});

export function getHotelsDetailsOnly(hotelIds: number[], callback: (hotelDetails: HotelDetails[]) => void): CommonActionReturnType {
    const success = (newHotelDetails: HotelDetails[]): IAppActions => ({
        type: HotelSearchResultsTypes.RECEIVED_HOTEL_DETAILS_CACHE,
        newHotelDetails
    });

    const failure = (error: string): IAppActions => ({
        type: HotelSearchTypes.GET_DATA_FAILURE,
        error
    });

    return (dispatch, getState) => {
        const locale = getState().locale.currentLocale;
        dispatch(submitFilters());

        const cachedHotelDetails = getState().hotelSearchResults.hotelDetailsCache.filter((e) => hotelIds.includes(e.hotel.id));

        const alreadyHasDetailsIds = cachedHotelDetails.map((e) => e.hotel.id);

        const hotelIdsWithoutDetails = hotelIds.filter((e) => !alreadyHasDetailsIds.includes(e));

        if (hotelIdsWithoutDetails.length === 0) {
            callback(cachedHotelDetails);
            return false;
        }

        getHotelsDetailsAPI(hotelIdsWithoutDetails, locale).then((data) => {
            const lastLocation = setLastLocation(router.state.location, LocationBaseKey.HOTELS, LastLocationKey.LAST_SEARCH_RESULTS_LOCATION);

            if (lastLocation) {
                dispatch(lastLocation);
            }

            if (hotelIdsWithoutDetails.length === 1) {
                dispatch(setFormDataHotelId(hotelIdsWithoutDetails[0]));
            }

            dispatch(success(data.hotelDetails));
            callback([...cachedHotelDetails, ...data.hotelDetails]);
        }).catch((error) => {
            // console.log(error, "----- error");
            const errorText = error.message ? error.message : String(error);
            if (error.response && error.response.status === 401) {
                dispatch(on401Error(undefined, "/logout"));
            } else if (error.response && error.response.status && error.response.status !== 200) {
                void router.navigate(`/hotels/error/http${error.response.status}`);
            } else {
                void router.navigate(`/hotels/error/${errorText}`);
            }

            dispatch(failure(errorText));
        });

        return true;
    };
}

const changeDataBySort = (dataForFilters: SimpleHotelOfferPropTypes[]): IAppActions => ({
    type: HotelSearchResultsTypes.CHANGE_SORT,
    dataForFilters
});

export const changeSort = (
    sortBy: { label: string, value: string },
    hotelDetails = true,
    updateQueryParam = true,
    override?: boolean,
    callback?: () => void
): CommonActionReturnType => async (dispatch, getState) => {
    if (
        sortBy.value === getState().hotelSearchResults.sortBy.value &&
        sortBy.label === getState().hotelSearchResults.sortBy.label &&
        !override
    ) {
        return;
    }

    const changeSortValue = (sortBy: { label: string, value: string }): IAppActions => ({
        type: HotelSearchResultsTypes.CHANGE_SORT_VALUE,
        sortBy
    });

    dispatch(changeSortValue(sortBy));
    const pageSelector = selectors.pageSelector();

    const sortHotelsAsync = async (): Promise<SimpleHotelOfferPropTypes[]> => new Promise((resolve) => {
        resolve(selectors.sortedHotelsSelector()(getState().hotelSearchResults))
    })

    await sortHotelsAsync().then((res) => {
        dispatch(changeDataBySort([...res]));

        if (hotelDetails) {
            dispatch(getHotelsDetails(pageSelector(getState().hotelSearchResults)));
        }

        if (updateQueryParam) {
            void replaceQueryParams(undefined, {sb: sortBy});
        }

        if (callback) {
            callback()
        }
    });
};

export const updateFiltersData = (filters?: HotelSearchResultsFiltersPropTypes, activeFilters?: string[]): IAppActions => ({
    type: HotelSearchResultsTypes.UPDATE_FILTERS,
    filters,
    activeFilters
});

export function updateFilters(reset = true, getDetails = true, updateQueryParams = true, changeSearchStatus = true, force = false): CommonActionReturnType {
    const resetFilterData = (): IAppActions => ({type: HotelSearchResultsTypes.RESET_FILTER_DATA});

    return (dispatch, getState) => {
        const {currentCompanyMarkupIndex} = getState().auth;
        const hash = JSON.stringify(getState().hotelSearchResults.stateFilters || []);

        const beforeStart = () => {
            batch(() => {
                dispatch(submitFilters());

                if (reset) {
                    dispatch(resetFilterData());
                }

                dispatch(updateFiltersData());
            });
        };

        const doPrepareData = () => ({
            hotelAmount: getState().auth.userData?.companyMarkups[currentCompanyMarkupIndex || 0].hotelAmount || 1,
            checkIn: getState().hotelSearch.stateFormData.checkIn,
            data: getState().hotelSearchResults.allHotelOffers,
            activeFilters: getState().hotelSearchResults.activeFilters || [],
            filters: getState().hotelSearchResults.filters || []
        } as AllFiltersWorkerProps);

        const onComplete = async (data?: HotelSearchAllFiltersReturn) => {
            const {
                stateFilters,
                mapFilter,
                sortBy,
                pageNumber,
                size,
                favoriteFilter
            } = getState().hotelSearchResults;

            dispatch(addFilter(
                (data?.hotels || [])
                    .filter((obj) => obj.match)
                    .map((filteredData) => {
                        filteredData.hotel.roomOffers = filteredData.matchingRooms;
                        return filteredData.hotel;
                    })
            ));

            await queueWorker<StatisticsWorkerProps, HotelFilterStatistics>({
                type: WorkerType.STATISTICS,
                hash: JSON.stringify({
                    type: WorkerType.STATISTICS,
                    hash
                }),
                onComplete: (statistics) => {
                    dispatch(addStatistics(statistics))
                },
                beforeStart: () => {},
                doPrepareData: () => ({
                    data: data?.hotels?.filter(hotel => hotel.match)?.flatMap((hotel) => hotel.hotel) || [],
                    hasFilters: getState().hotelSearchResults.activeFilters.length !== 0
                })
            });

            let isUpdateFinished = true;
            if (mapFilter) {
                dispatch(applyMapFilter());
                isUpdateFinished = false;
            }

            if (favoriteFilter) {
                dispatch(addHotelFavoriteFilter(isUpdateFinished));
            }

            dispatch(getPagesCount());

            const locale = getState().locale.currentLocale;
            if (updateQueryParams) {
                const {
                    sortBy: newSortBy,
                    pageNumber: newPageNumber
                } = getState().hotelSearchResults;

                await replaceQueryParams(undefined, {
                    f: stateFilters,
                    // TODO: wtf, needs analysis later
                    // p: stateFilters?.priceFilter,
                    sb: newSortBy,
                    pn: newPageNumber,
                    s: size,
                    l: locale
                });
            }

            dispatch(changeSort(sortBy, false, false, true, () => {
                dispatch(handleChangePage(1, getDetails, false));

                if (isUpdateFinished) {
                    dispatch(filtersUpdated());
                }
            }));
        };

        void queueWorker<AllFiltersWorkerProps, HotelSearchAllFiltersReturn>({
            type: WorkerType.ALL_FILTERS,
            doPrepareData,
            beforeStart,
            onComplete,
            hash,
            force
        });
    };
}

export const handleInputChange = (inputName: string, inputValue?: string): CommonActionReturnType => (dispatch) => dispatch({
    type: HotelSearchTypes.HANDLE_INPUT_CHANGE,
    inputName,
    inputValue
});

export const handleNatUpdate = (): CommonActionReturnType => (dispatch, getState) => {
    const {hotelSearch, nationality} = getState();
    const {nationalities} = nationality.nationalities;
    const countryIso = hotelSearch.formData.clientNationality;
    const foundNationality = nationalities.find((nat) => nat.iso === countryIso);
    if (foundNationality) {
        const nationalityInput = foundNationality.name;
        if (countryIso) {
            dispatch(handleNatChange(countryIso));
        }
        dispatch(handleInputChange("nationalityInput", nationalityInput));
    }
};

export function setDefaultNationality(): CommonActionReturnType {
    return (dispatch, getState) => {
        const {
            auth,
            nationality,
            hotelSearch
        } = getState();
        const {nationalities} = nationality.nationalities;
        const defaultCountryIso = auth.userData?.countryIso;
        const currentCountryISO = hotelSearch.formData.clientNationality;

        let foundNationality: NationalityPropTypes | undefined = undefined;
        if (currentCountryISO) {
            foundNationality = nationalities.find((nat) => nat.iso === currentCountryISO);
        }

        if (!foundNationality && defaultCountryIso) {
            foundNationality = nationalities.find((nat) => nat.iso === defaultCountryIso);
        }

        if (foundNationality) {
            const nationalityInput = foundNationality.name;

            if (currentCountryISO || defaultCountryIso) {
                dispatch(handleNatChange(currentCountryISO || defaultCountryIso));
            }

            dispatch(handleInputChange("nationalityInput", nationalityInput));
        }
    };
}