import {useEffect, useLayoutEffect, useMemo} from "react"
import _, {DebouncedFunc} from "lodash"

export type UseWindowResizeProps = {
    listener: (width: number, height: number) => void;
    method?: "debounce" | "throttle";
    debounceTime?: number;
    callOnMount?: boolean;
};

const useWindowResize = (props: UseWindowResizeProps) => {
    const {
        listener,
        method = "debounce",
        debounceTime,
        callOnMount
    } = props;

    const resizeFunc = useMemo(() => (thisWindow?: Window, ev?: UIEvent) => {
        const {innerWidth: width, innerHeight: height} = window;
        if (!width) {
            return;
        }

        listener(width, height);
    }, [listener]);

    useEffect(() => {
        let resizeListener: DebouncedFunc<(thisWindow: Window, ev: UIEvent) => void>;
        if (method === "debounce") {
            resizeListener = _.debounce(resizeFunc, debounceTime || 100);
        } else {
            resizeListener = _.throttle(resizeFunc, debounceTime || 100);
        }

        window.addEventListener("resize", resizeListener as unknown as EventListener);

        return () => {
            window.removeEventListener("resize", resizeListener! as unknown as EventListener);
        };
    }, [debounceTime, listener, method, resizeFunc]);

    useLayoutEffect(() => {
        if (callOnMount) {
            resizeFunc();
        }
    }, [callOnMount, resizeFunc]);
}

export default useWindowResize;