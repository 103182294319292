import React, {MouseEvent, ReactElement, useCallback, useState} from "react"
import styles from "./DateRangePickerDay.module.scss";
import cx from "classnames";
import {DateTime} from "luxon"
import useMobileSwitch from "../../../../../utils/hooks/useMobileSwitch"

export type DateRangePickerDayProps = {
    label: string;
    value?: DateTime;

    onSelect?: (value: DateTime) => void;
    onMouseEnter?: (value: DateTime) => void;
    onMouseLeave?: (value: DateTime) => void;

    disabled?: boolean;
    active?: boolean;
    className?: string;
};

const DateRangePickerDay = ({label, disabled, active, className, onSelect, value, onMouseEnter, onMouseLeave}: DateRangePickerDayProps): ReactElement => {
    const {isMobile} = useMobileSwitch();

    const onClickCallback = useCallback(() => {
        if (!disabled && onSelect && value) {
            onSelect(value);
        }
    }, [disabled, onSelect, value]);

    const onMouseEnterCallback = useCallback((evt: MouseEvent<HTMLDivElement>) => {
        evt.stopPropagation();
        evt.preventDefault();

        if (isMobile) {
            onClickCallback();
            return;
        }

        if (onMouseEnter && value) {
            onMouseEnter(value);
        }
    }, [isMobile, onClickCallback, onMouseEnter, value]);

    const onMouseLeaveCallback = useCallback((evt: MouseEvent<HTMLDivElement>) => {
        evt.stopPropagation();
        evt.preventDefault();

        if (onMouseLeave && value) {
            onMouseLeave(value);
        }
    }, [onMouseLeave, value]);

    return (
        <div
            onClick={onClickCallback}
            className={cx(styles.Root, active && styles.Active, disabled && styles.Disabled, className)}
            onMouseEnter={onMouseEnterCallback}
            onMouseLeave={onMouseLeaveCallback}
        >
            {label}
        </div>
    );
};

export default DateRangePickerDay;