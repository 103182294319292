import {
    Instrumentation, TraceId, Tracer
} from "zipkin";
import {
    AxiosError, AxiosInstance, AxiosRequestConfig, AxiosResponse, InternalAxiosRequestConfig
} from "axios"
import _ from "lodash";

function wrapAxios(axios: AxiosInstance, options: {tracer: Tracer, remoteServiceName?: string}): AxiosInstance {
    const {tracer} = options;

    const instrumentation = new Instrumentation.HttpClient(options);

    const zipkinRecordRequest = (config: InternalAxiosRequestConfig) => tracer.scoped(() => {
        const newConfig = instrumentation.recordRequest(
            config,
            config.url || "",
            config.url ? (_.toString(config.method) + " " + config.url) : ""
        ) as InternalAxiosRequestConfig & {traceId: TraceId};
        newConfig.traceId = tracer.id;
        return newConfig;
    });

    const zipkinRecordResponse = (res: AxiosResponse) => tracer.scoped(() => {
        instrumentation.recordResponse((res.config as AxiosRequestConfig & {traceId: TraceId}).traceId, String(res.status));
        return res;
    });

    const zipkinRecordError = (error: AxiosError) => tracer.scoped(() => {
        if (error.config) {
            const {traceId} = error.config as AxiosRequestConfig & {traceId: TraceId};
            if (error.response) {
                instrumentation.recordResponse(traceId, String(error.response.status));
            } else {
                instrumentation.recordError(traceId, error);
            }
        } // otherwise the error preceded the request interceptor
        return Promise.reject(error);
    });

    const axiosInstance = axios;

    axiosInstance.interceptors.request.use(zipkinRecordRequest, zipkinRecordError);

    axiosInstance.interceptors.response.use(zipkinRecordResponse, zipkinRecordError);

    return axiosInstance;
}

export default wrapAxios;
