import React, {Component} from "react";
import * as searchActions from "redux/actions/transferSearch.actions";
import {getSearchTransferDestination, getSearchTransferOrigin} from "redux/actions/destinations.actions";
import {connect, ConnectedProps} from "react-redux";
import isEqual from "lodash/isEqual";
import debounce from "lodash/debounce";
import SearchBox from "./SearchBox";
import {TransferSearchCriteriaPropTypes} from "proptypes/PropTypeObjects";
import {RootState} from "redux/store/store.init";

type Props = ConnectedProps<typeof connector>;
type SearchBoxContainerState = {
    backup: {
        airportInput?: string,
        venueInput?: string,
        formData?: TransferSearchCriteriaPropTypes
    }
};

class SearchBoxContainer extends Component<Props, SearchBoxContainerState> {
    constructor(props: Props) {
        super(props);
        this.state = {
            backup: {
                airportInput: undefined,
                venueInput: undefined,
                formData: undefined
            }
        };
    }

    componentDidMount() {
        this.setStateBackup();
        const {
            search: {
                formData: {
                    destinationId,
                    originId,
                    transferOriginId,
                    transferDestinationId
                }
            }
            ,
            handleUpdateOrigin,
            handleUpdateDestination
        } = this.props;

        if (originId || transferOriginId) {
            handleUpdateOrigin();
        }

        if ((destinationId && originId) || (transferOriginId && transferDestinationId)) {
            handleUpdateDestination();
        }
    }

    componentDidUpdate(prevProps: Props) {
        const {
            search: {
                formData,
                submitedSearch
            },
            locale,
            currency,
            searchUpdate
        } = this.props;

        const prevFormData = prevProps.search.formData;

        if (prevProps.locale !== locale) {
            searchUpdate();
        }
        if (prevProps.currency !== currency) {
            searchUpdate();
        }
        if (submitedSearch &&
            !isEqual(formData, prevFormData)) {
            this.setStateBackup();
        }
    }

    componentWillUnmount() {
        const {
            search: {
                submitedSearch
            }
        } = this.props;

        if (!submitedSearch) {
            this.discardChanges();
        }
    }

    handleInputChange = ({target: {value, name}}: {
        target: { value: string, name: string }
    }) => this.props.handleInputChange(name, value);

    setStateBackup = () => {
        const {
            search: {
                formData,
                airportInput,
                venueInput
            }
        } = this.props;

        this.setState({
            backup: {
                formData,
                venueInput,
                airportInput
            }
        });
    };

    discardChanges = () => {
        const {
            backup: {
                formData,
                airportInput,
                venueInput
            }
        } = this.state;

        const {
            handleFormData,
            submitSearch,
            handleInputChange: handleInputChange1
        } = this.props;

        if (formData) {
            handleFormData(formData);
        }
        handleInputChange1("venueInput", venueInput);
        handleInputChange1("airportInput", airportInput);
        submitSearch();
    };

    render() {
        const {
            search: {
                formData,
                airportInput,
                venueInput,
                bookRoundtrip,
                stateFormData,
                currentOrigin,
                currentDestination
            },
            destinations: {
                transferDestinations,
                transferOrigins
            },
            handleAdultsChange,
            handleChildrenChange,
            startSearchFromData,
            handleArrivalDepartureTransfer,
            handleArrivalDepartureTime,
            handleDestination,
            handleOrigin,
            getSearchTransferDestination,
            getSearchTransferOrigin,
            handleRelatedBooking,
            searchUpdate,
            toggleRoundTrip
        } = this.props;

        return (
            <SearchBox
                data={{
                    formData,
                    adults: stateFormData.adults,
                    arrivalTime: stateFormData.arrivalTime,
                    departureTime: stateFormData.departureTime,
                    children: stateFormData.children,
                    airportInput,
                    venueInput,
                    transferDestinations,
                    transferOrigins,
                    bookRoundtrip,
                    currentDestination,
                    currentOrigin
                }}
                toggleRoundTrip={toggleRoundTrip}
                handleInputChange={this.handleInputChange}
                handleSubmitSearch={searchUpdate}
                handleDestination={handleDestination}
                handleOrigin={handleOrigin}
                handleArrivalDepartureTransfer={handleArrivalDepartureTransfer}
                handleArrivalDepartureTime={handleArrivalDepartureTime}
                getSearchTransferDestination={debounce(getSearchTransferDestination, 300, {
                    leading: false,
                    trailing: true
                })}
                getSearchTransferOrigin={debounce(getSearchTransferOrigin, 300, {leading: false, trailing: true})}
                handleAdultsChange={handleAdultsChange}
                handleChildrenChange={handleChildrenChange}
                handleRelatedBooking={handleRelatedBooking}
                startSearchFromData={startSearchFromData}
            />
        );
    }
}

const mapStateToProps = (state: RootState) => ({
    search: state.transferSearch,
    searchResults: state.transferSearchResults,
    destinations: state.destinations,
    locale: state.locale.currentLocale,
    currency: state.currency.currentCurrency
});

const connector = connect(mapStateToProps, {
    ...searchActions,
    getSearchTransferDestination,
    getSearchTransferOrigin
});

export default connector(SearchBoxContainer);
