import React, {ReactElement, useEffect, useState} from "react";
import _ from "lodash";
import {useTranslation} from "react-i18next";
import styles from "./HotelImageViewer.module.scss";
import {LazyImageSlider, LazyImageSliderImage} from "../../../../components/common/LazyImageSlider/LazyImageSlider";
import {HotelPropTypes} from "../../../../proptypes/PropTypeObjects";
import GoogleMapContainer from "../../../../components/common/GoogleMap/GoogleMapContainer";
import CustomMarker from "../MapContainer/CustomMarker";
import createResizedImageUrl, {
    HOTELS_SLIDER_RESOLUTION,
    IMAGE_SLIDER_THUMBNAIL_RESOLUTION
} from "../../../../utils/thumbnail";

export type HotelImageViewerProps = {
    hotel: HotelPropTypes;
    zoom: number;
};

const HotelImageViewer = ({hotel, zoom}: HotelImageViewerProps): ReactElement => {
    const {t} = useTranslation();
    const [images, setImages] = useState<LazyImageSliderImage[]>([]);

    const renderThumbItem = () => (
        <div className="image-gallery-thumbnail-inner">
            <span className={styles.MapItem}>{t("h_h_h_map")}</span>
        </div>
    );

    const renderItem = () => (
        <div className="image-gallery-image">
            <GoogleMapContainer
                options={{
                    disableDefaultUI: true,
                    styles: [
                        {
                            featureType: "poi.business",
                            elementType: "labels",
                            stylers: [{visibility: "off"}]
                        }
                    ]
                }}
                coordinates={hotel?.coordinates}
                zoom={zoom}
            >
                <CustomMarker coordinates={hotel?.coordinates} />
            </GoogleMapContainer>
        </div>
    );

    const getFinalImagesArr = async (hotel: HotelPropTypes) => {
        if (!hotel.images || hotel.images.length === 0) {
            return [];
        }

        return hotel.images;
    };

    useEffect(() => {
        if (!hotel) {
            return;
        }
        void getFinalImagesArr(hotel).then((finalImages: (string | undefined) []) => {
            if (finalImages.length > 0) {
                const imagesGallery = finalImages.filter((item: (string | undefined)) => item !== undefined).map((imgSrc: string | undefined) => ({
                    original: createResizedImageUrl(imgSrc as string, HOTELS_SLIDER_RESOLUTION),
                    thumbnail: createResizedImageUrl(imgSrc as string, IMAGE_SLIDER_THUMBNAIL_RESOLUTION)
                })) as LazyImageSliderImage[];

                setImages(imagesGallery);
            }
        });
    }, [hotel]);

    return (
        <div className={styles.Root}>
            <LazyImageSlider
                showFullscreenButton={true}
                showThumbnails={true}
                showImageMessage={true}
                images={images}
                disableKeyDown={false}
            />
        </div>
    );
};

export default HotelImageViewer;