enum TransferBookingTypes {
    //transferBooking options
    GET_OPTIONS = "transferBooking/GET_OPTIONS",
    GET_OPTIONS_SUCCESS = "transferBooking/GET_OPTIONS_SUCCESS",
    GET_OPTIONS_FAILURE = "transferBooking/GET_OPTIONS_FAILURE",

    UPDATE_OPTIONS = "transferBooking/UPDATE_OPTIONS",
    UPDATE_OPTIONS_SUCCESS = "transferBooking/UPDATE_OPTIONS_SUCCESS",
    UPDATE_OPTIONS_FAILURE = "transferBooking/UPDATE_OPTIONS_FAILURE",

    REQUEST_TRANSFER_BOOKING = "transferBooking/REQUEST_TRANSFER_BOOKING",
    TRANSFER_BOOKING_SUCCESS = "transferBooking/TRANSFER_BOOKING_SUCCESS",
    TRANSFER_BOOKING_FAILURE = "transferBooking/TRANSFER_BOOKING_FAILURE",

    // validate

    REQUEST_VALIDATE_BOOKING = "transferBooking/REQUEST_VALIDATE_BOOKING",
    VALIDATE_BOOKING_SUCCESS = "transferBooking/VALIDATE_BOOKING_SUCCESS",
    VALIDATE_BOOKING_FAILURE = "transferBooking/VALIDATE_BOOKING_FAILURE",

    // inputs transferBooking Info

    CONFIRM_DUPLICATE_BOOKING = "transferBooking/CONFIRM_DUPLICATE_BOOKING",
    HANDLE_CONTACT_INFO = "transferBooking/HANDLE_CONTACT_INFO",
    HANDLE_MEMO = "transferBooking/HANDLE_MEMO",
    HANDLE_LEAD_PASSENGER = "transferBooking/HANDLE_LEAD_PASSENGER",
    HANDLE_TRANSFER_BOOKING_INFO_INPUT = "transferBooking/HANDLE_TRANSFER_BOOKING_INFO_INPUT",
    TOGGLE_SMS_ORDERED = "transferBooking/TOGGLE_SMS_ORDERED",
    HANDLE_INPUT_CHANGE = "transferBooking/HANDLE_INPUT_CHANGE",

    // hotel transferBooking info

    CLEAR_BOOKING_DATA = "transferBooking/CLEAR_BOOKING_DATA",
    CLEAR_VALIDATION_DATA = "transferBooking/CLEAR_VALIDATION_DATA",
}

export default TransferBookingTypes;