export default function createBlobAndDownload(
    data: Blob | any,
    type: string,
    filename: string,
    alreadyBlob = false
): void {
    const blob: Blob = alreadyBlob
        ? new Blob([data], {type})
        : data;

    // @ts-ignore
    if (window.navigator && window.navigator.msSaveOrOpenBlob) { // for IE
        // @ts-ignore
        window.navigator.msSaveOrOpenBlob(blob, filename);
    } else {
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = filename;

        document.body.appendChild(link);

        link.click();

        document.body.removeChild(link);
    }
}