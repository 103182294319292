import React, {ReactElement, useState} from "react";
import UserButton from "components/base/UserButton";
import {ReactComponent as EuroIcon} from "assets/icons/euro sign 2.svg";
import {ReactComponent as HeartIcon} from "assets/icons/heart 2.svg";
import {ReactComponent as AutoIcon} from "assets/icons/autoconfirmation.svg";
// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module 'assets/images/temp-wallet.PNG'... Remove this comment to see the full error message
import walletImg from "assets/images/temp-wallet.PNG";
import Spinner from "components/base/Loaders/Spinner";
import InfoBox from "components/common/InfoBox";
import {useTranslation} from "react-i18next";
import styles from "./ActivateWallet.module.scss";
import {UserButtonVariant} from "../../../components/base/UserButton/UserButton";
import AgreeWalletTerms from "../../../components/common/AgreeTermsModal/AgreeWalletTerms/AgreeWalletTerms";

type ActivateWalletProps = {
    activatingWallet: boolean;
    activateWallet: () => void,
    errors?: string[],
    setErrors: React.Dispatch<React.SetStateAction<string[] | undefined>>
};

export default function ActivateWallet({
    activatingWallet,
    activateWallet,
    errors,
    setErrors
}: ActivateWalletProps): ReactElement {
    const {t} = useTranslation();
    const [termsModalOpen, setTermsModalOpen] = useState(false);

    const processActivation = () => {
        setTermsModalOpen(false);
        activateWallet();
    };

    if (activatingWallet) {
        return (
            <Spinner
                size="50px"
                style={{
                    width: "100%",
                    height: "calc( 100vh - 120px )"
                }}
            />
        );
    }

    return (
        <div className={styles.Root}>
            <div className={styles.Box}>
                <div className={styles.Left}>
                    <h1>{t("w_aw_activate_hotelston_wallet")}</h1>
                    {errors && (
                        <InfoBox
                            className="mb-60"
                            title={t("w_aw_something_went_wrong")}
                            message={errors.map((errKey) => t(errKey)).join(". ")}
                            type="warn"
                        />
                    )}

                    <p>{t("w_aw_hotelston_wallet_promotional_text")}</p>

                    {termsModalOpen && (
                        <AgreeWalletTerms
                            onClose={() => setTermsModalOpen(false)}
                            onConfirm={() => {
                                processActivation();
                            }}
                        />
                    )}

                    <UserButton
                        text={t("w_aw_activate_wallet_now")}
                        variant={UserButtonVariant.SECONDARY}
                        buttonProps={{
                            type: "button",
                            style: {
                                width: "200px",
                                height: "50px",
                                marginTop: "40px"
                            },
                            onClick: () => {
                                setTermsModalOpen(true);
                                setErrors(undefined);
                            }
                        }}
                    />
                </div>

                <div className={styles.Right}>
                    <img src={walletImg} alt="wallet" />
                </div>
            </div>

            <div className={styles.Footer}>
                <div className={styles.Features}>
                    <div className={styles.Feature}>
                        <h3 className={styles.FeatureHeading}>
                            <EuroIcon className={styles.Icon}/>{" "}
                            {t("w_aw_instant_payment")}
                        </h3>

                        <p>{t("w_aw_about_instant_payment")}</p>
                    </div>

                    <div className={styles.Feature}>
                        <h3 className={styles.FeatureHeading}>
                            <HeartIcon className={styles.Icon}/>{" "}
                            {t("w_aw_free_of_charge")}
                        </h3>

                        <p>{t("w_aw_about_free_of_charge")}</p>
                    </div>

                    <div className={styles.Feature}>
                        <h3 className={styles.FeatureHeading}>
                            <AutoIcon className={styles.Icon}/>{" "}
                            {t("w_aw_Booking_autoconfirmation")}
                        </h3>

                        <p>{t("w_aw_about_booking_confirmation")}</p>
                    </div>
                </div>

                <div
                    style={{
                        marginTop: 60,
                        marginBottom: 60
                    }}
                    className="h-divider"
                />

                {/*<h2 className={styles.heading}>{t("w_aw_faq")}</h2>*/}
                {/*<OpenableHeading*/}
                {/*    headingText="Praesent eleifend, massa nec imperdiet imperdietac aliquam justo lacus et metus?"*/}
                {/*>*/}
                {/*    <p>*/}
                {/*        Lorem ipsum dolor sit amet, consectetur adipiscing elit.*/}
                {/*        Pellentesque non odio ac purus viverra iaculis. Nunc*/}
                {/*        malesuada turpis auctor convallis hendrerit. Vestibulum*/}
                {/*        sit amet lectus lacinia, sollicitudin est ac, pretium*/}
                {/*        velit. Ut pretium facilisis tempus. Aenean quis interdum*/}
                {/*        sapien, quis aliquet nisl. Proin tristique orci id*/}
                {/*        lectus vestibulum, vitae congue metus*/}
                {/*    </p>*/}
                {/*    <a className={styles.action} href="#">*/}
                {/*        {t("w_aw_read_more")}*/}
                {/*    </a>*/}
                {/*    <a href="#">{t("w_aw_see_tutorial")}</a>*/}
                {/*</OpenableHeading>*/}
            </div>
        </div>
    );
}
