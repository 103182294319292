import React, {ReactElement, useCallback, useState} from "react";
import {TruncatedList} from "react-truncate-list";
import "react-truncate-list/dist/styles.css";

export type TruncateProps = {
    children: ReactElement[] | ReactElement;
    lines: number;
    lineHeight?: number | string;
    ellipsis?: ReactElement | string;
    showLess?: boolean;
};

function Truncate({
    children,
    lines,
    lineHeight,
    ellipsis,
    showLess = false
}: TruncateProps): ReactElement {
    const [truncated, setTruncated] = useState<boolean>(true);

    const toggleTruncated = useCallback(() => setTruncated(!truncated), [truncated]);

    return (
        truncated ? (
            <TruncatedList
                renderTruncator={({hiddenItemsCount}) => {
                    setTruncated(!!hiddenItemsCount);

                    return ellipsis && <span className="ellipsis" onClick={toggleTruncated}>{ellipsis}</span>;
                }}
                style={{
                    lineHeight,
                    maxHeight: "1rem"
                }}
            >
                {children}
            </TruncatedList>
        ) : (
            <div>
                {children}

                {showLess && (
                    <span
                        onClick={toggleTruncated}
                        className="show-less"
                    >
                        {showLess}
                    </span>
                )}
            </div>
        )
    );
}

export default Truncate;
