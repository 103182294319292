import {PaymentAccountsResult} from "proptypes/PropTypeObjects";
import ConstrainedAxiosRequestConfig from "api/axios/constrainedAxiosRequestConfig"

export default function getPaymentAccounts(): ConstrainedAxiosRequestConfig<PaymentAccountsResult> {
    return {
        method: "GET",
        url: "/app/payment-account/",
        headers: {
            Accept: "application/json"
        },
        maxNumRequests: 1
    }
}