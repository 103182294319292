import React, {ChangeEvent, ReactElement, useCallback} from "react";
import {useTranslation} from "react-i18next";
import cx from "classnames";
import OutlineInput from "../Input/TextInput/OutlinedInput";
import styles from "./NumberInput.module.scss";

export type StepperInputProps = {
    value?: string;
    postFix?: string;
    onChange?: (newValue: string) => void;
    inputProps?: React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>;
    className?: string;
};

const NumberInput = ({
    value,
    onChange,
    inputProps,
    className
}: StepperInputProps): ReactElement => {
    const onChangeCallback = useCallback(() => {
        if (onChange) {
            if (!value) {
                onChange("");
            } else {
                onChange((+value).toFixed(2));
            }
        }
    }, [onChange, value]);

    return (
        <OutlineInput
            inputProps={{
                ...inputProps,
                className,
                type: "number",
                value: value,
                min: 0,
                step: 0.01,
                onChange: (e) => onChange && onChange(e.target.value),
                onBlur: () => {
                    onChangeCallback();
                }
            }}
            labelPosition="outlined"
            helperAndErrorTextPosition="bottom"
        />
    );
};

export default NumberInput;