import React, {ReactElement, useCallback} from "react";
import Spinner from "components/base/Loaders/Spinner";
import useCompanyData from "components/utils/useCompanyData";
import InfoBox from "components/common/InfoBox";
import {useTranslation} from "react-i18next";
import MyCompanyInfo from "./MyCompanyInfo";
import styles from "./MyCompany.module.scss";
import CompanyUsersList from "./CompanyUsersList";
import SalesMarkupList from "./SalesMarkupList";
import Agreements from "./Agreements";
import {useAppDispatch} from "../../redux/hooks";
import {
    updateLogo,
    updateCompanyDetails,
    updateBillingDetails,
    addCompanyUser, removeCompanyUser, addCompanyMarkup, updateCompanyMarkup, removeCompanyMarkup
} from "../../redux/actions/company.actions";
import {
    CompanyDetails,
    CompanyMarkupsPropTypes,
    CompanyUserPropTypes,
    Credentials
} from "../../proptypes/PropTypeObjects";

function MyCompanyContainer(): ReactElement {
    const {
        data,
        error,
        requestingCompanyDetails
    } = useCompanyData();

    const {t} = useTranslation();
    const dispatch = useAppDispatch();

    const updateLogoCallback = useCallback((photo?: string) => {
        dispatch(updateLogo(photo));
    }, [dispatch]);

    const updateCompanyDetailsCallback = useCallback((details: CompanyDetails) => {
        dispatch(updateCompanyDetails(details));
    }, [dispatch]);

    const updateBillingDetailsCallback = useCallback((billingDetails: Credentials) => {
        dispatch(updateBillingDetails(billingDetails));
    }, [dispatch]);

    const addCompanyUserCallback = useCallback((companyUser: CompanyUserPropTypes) => {
        dispatch(addCompanyUser(companyUser));
    }, [dispatch]);

    const deleteCompanyUserCallback = useCallback((id: number) => {
        dispatch(removeCompanyUser(id));
    }, [dispatch]);

    const addCompanyMarkupCallback = useCallback((companyMarkup: CompanyMarkupsPropTypes) => {
        dispatch(addCompanyMarkup(companyMarkup));
    }, [dispatch]);

    const updateCompanyMarkupCallback = useCallback((companyMarkup: CompanyMarkupsPropTypes) => {
        dispatch(updateCompanyMarkup(companyMarkup));
    }, [dispatch]);

    const deleteCompanyMarkupCallback = useCallback((id: number) => {
        dispatch(removeCompanyMarkup(id));
    }, [dispatch]);

    return (
        <div className={styles.Root}>
            {!!error && (
                <InfoBox
                    className="mb-60"
                    title={t("mc_mcc_something_went_wrong")}
                    message={error}
                    type="warn"
                />
            )}

            {requestingCompanyDetails ? (
                <Spinner
                    size="50px"
                    style={{
                        width: "100%",
                        height: "calc(100vh - 200px)"
                    }}
                />
            ) : (
                data && (
                    <>
                        <MyCompanyInfo
                            companyDetails={data.companyDetails}
                            credentials={data.credentials}
                            hasSignedMainAgreement={data.hasSignedMainAgreement}
                            updateLogo={updateLogoCallback}
                            updateCompanyDetails={updateCompanyDetailsCallback}
                            updateBillingDetails={updateBillingDetailsCallback}
                        />

                        <CompanyUsersList
                            companyUsers={data.companyUsers}
                            addCompanyUser={addCompanyUserCallback}
                            deleteCompanyUser={deleteCompanyUserCallback}
                        />

                        <SalesMarkupList
                            markups={data.companyMarkups}
                            addCompanyMarkup={addCompanyMarkupCallback}
                            updateCompanyMarkup={updateCompanyMarkupCallback}
                            deleteCompanyMarkup={deleteCompanyMarkupCallback}
                        />

                        <Agreements agreements={data.companyDocuments}/>
                    </>
                )
            )}
        </div>
    );
}

export default MyCompanyContainer;
