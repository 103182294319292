import React, {
    ReactElement, useEffect, useRef
} from "react";
import animatedLogo from "assets/images/logoAnimated.gif";
import styles from "./AppSplashscreen.module.scss";

const AppSplashscreen = (): ReactElement => {
    // const timer;
    const splashScreenRef = useRef<HTMLDivElement>(null);

    useEffect(() => () => {
        if (splashScreenRef.current) {
            splashScreenRef.current.className += " " + styles.FadeOut;
        }
    }, []);

    return (
        <div
            className={styles.Root}
            ref={splashScreenRef}
        >
            <img src={animatedLogo} alt="animated logo" />
            <div className={styles.AdditionalExplanation}>Welcome to Hotelston.com. Our site is reloading, please wait a little while longer…</div>
        </div>
    );
};

export default AppSplashscreen;