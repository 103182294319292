import React, {
    ReactElement, useCallback, useEffect, useState
} from "react";
import UserButton from "components/base/UserButton";
import useKeyDetect from "components/utils/useKeyDetect";
import {useTranslation} from "react-i18next";
import {ReactComponent as FileIcon} from "assets/icons/file.svg";
import CustomCheckBox from "components/base/Input/CustomCheckBox";
import Fade from "components/utils/Transitions/Fade";
import cx from "classnames";
import styles from "./AgreeTermsModal.module.scss";
import ModalWithOverlay from "../Modal/ModalWithOverlay";
import Modal from "../Modal";
import {UserButtonVariant} from "../../base/UserButton/UserButton";
import ActionIcon, {ActionIconType} from "../ActionIcon/ActionIcon";
import {ExportedDocumentTypeEnum} from "../../../api/companyDocumentsAPI";
import baseUrl from "../../../api/constants/baseURL";
import {useLocale} from "../../utils/withLocalesAndCurrencies";

function createMarkup(content: any) {
    return {
        __html: content
    };
}

export type AgreeTermsModalSharedProps = {
    onConfirm?: () => void;
    onClose: () => void;
    withFooter?: boolean;
};

export type AgreeTermsModalProps = AgreeTermsModalSharedProps & {
    title?: string;
    content: ReactElement | string;
    agreeButtonText?: string;
    agreeWithTermsText?: string;
    exportedFileType: ExportedDocumentTypeEnum;
    closeable?: boolean;
};

function AgreeTermsModal({
    onConfirm,
    onClose,

    title,
    content,
    agreeButtonText,
    agreeWithTermsText,
    exportedFileType,

    withFooter = false,
    closeable = true
}: AgreeTermsModalProps): ReactElement {
    const {t} = useTranslation();
    const [fade, setFade] = useState(false);
    const [agreeTerms, setAgreeTerms] = useState(false);
    const locale = useLocale();
    const fadeDuration = 300;

    useEffect(() => {
        setFade(true);
    }, []);

    const onConfirmAndFade = useCallback(() => {
        setFade(false);
        setTimeout(() => {
            if (onConfirm) {
                onConfirm();
            }
        }, fadeDuration);
    }, [onConfirm]);

    const onCloseAndFade = useCallback((usingKey = false) => {
        if (!closeable && usingKey) {
            return;
        }

        setFade(false);
        setTimeout(() => onClose(), fadeDuration);
    }, [closeable, onClose]);

    useKeyDetect(27, () => onCloseAndFade(true));

    return (
        <Modal>
            <Fade inProp={fade} duration={fadeDuration}>
                <ModalWithOverlay>
                    <div className={cx(styles.Root, withFooter && styles.WithFooter)}>
                        {closeable && (
                            <div
                                onClick={() => onCloseAndFade()}
                                className={styles.CloseBtn}
                            >
                                <ActionIcon type={ActionIconType.CLOSE} />
                            </div>
                        )}

                        <div className={styles.Header}>
                            <h2>{title}</h2>

                            {exportedFileType && (
                                <div className={styles.Actions}>
                                    <a href={`${baseUrl}/company/document/export/${exportedFileType}?locale=${locale}`} className={styles.ActionWrapper}>
                                        <FileIcon height="11.5px" width="9.5px" />
                                        <span>{t("atm_atm_download")} {t("atm_atm_pdf")}</span>
                                    </a>
                                </div>
                            )}
                        </div>

                        <div className={styles.Body} dangerouslySetInnerHTML={createMarkup(content)} />

                        {withFooter && (
                            <div className={styles.Footer}>
                                <div className={styles.Agree}>
                                    <CustomCheckBox
                                        label={agreeWithTermsText || ""}
                                        inputProps={{
                                            checked: agreeTerms,
                                            onChange: () => setAgreeTerms(!agreeTerms),
                                            required: true
                                        }}
                                    />
                                </div>

                                <UserButton
                                    text={agreeButtonText || ""}
                                    variant={UserButtonVariant.PRIMARY}
                                    disabledButton={!agreeTerms}
                                    buttonProps={{
                                        type: "button",
                                        onClick: onConfirmAndFade,
                                        style: {
                                            height: "50px",
                                            width: "160px"
                                        }
                                    }}
                                />
                            </div>
                        )}
                    </div>
                </ModalWithOverlay>
            </Fade>
        </Modal>
    );
}

export default AgreeTermsModal;
