import React, {ReactElement} from "react";
import usePaymentType from "components/utils/usePaymentType";
import {ReduxPaymentTypePropTypes} from "proptypes/PropTypeRedux";
import MainContainer from "components/base/MainContainer";
import PaymentSummary from "views/MyBookings/Payment/PaymentSummary";
import {useAppSelector} from "redux/hooks";
import SplitPaymentContent from "components/common/Payment/PaymentContent/SplitPaymentContent/SplitPaymentContent";
import SplitPaymentSummary from "../../Payment/SplitPayment/SplitPaymentSummary/SplitPaymentSummary";

const SplitPaymentContainer = (): ReactElement => {
    usePaymentType(ReduxPaymentTypePropTypes.MULTIBOOKING);

    return (
        <MainContainer
            sidebar={
                <SplitPaymentSummary/>
            }
        >
            <SplitPaymentContent/>
        </MainContainer>
    );
}

export default SplitPaymentContainer;