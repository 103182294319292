import {useEffect, useState} from "react";
import getCountries from "api/countryAPI";
import {CountryPropTypes} from "../../proptypes/PropTypeObjects";
import {useAppSelector} from "../../redux/hooks";
import {globalAxiosController} from "api/axios/globalAxiosController";

type UseCountries = {
    countries: CountryPropTypes[];
    defaultCountry?: CountryPropTypes;
};

export default function useCountries(): UseCountries {
    const [countries, setCountries] = useState<CountryPropTypes[]>([]);
    const [defaultCountry, setDefaultCountry] = useState<CountryPropTypes | undefined>(undefined);
    const locale = useAppSelector((state) => state.locale.currentLocale);
    const countryId = useAppSelector((state) => state.auth.userData?.countryId);
    useEffect(() => {
        let isMounted = true;
        globalAxiosController.addRequest(getCountries(locale))
            .then((data) => {
                if (isMounted) {
                    data.countries.sort((o1, o2) => o1.name.localeCompare(o2.name));
                    setCountries(data.countries);
                }
            })
            .catch((err: any) => {
                // console.log(err);
            });
        return () => {
            isMounted = false;
        };
    }, [locale]);
    useEffect(() => {
        let isMounted = true;
        if (isMounted) {
            setDefaultCountry(countries.find((c) => c.id === countryId));
        }
        return () => {
            isMounted = false;
        };
    }, [countries, countryId]);
    return {countries, defaultCountry};
}
