import {
    CompanyDetails,
    CompanyListResponse,
    ItemModificationResponse,
    RegisterCompanyRequest
} from "../proptypes/PropTypeObjects";
import {ConstrainedAxiosRequestConfig} from "./axios/constrainedAxiosRequestConfig";

export function getSubagents(locale: string): ConstrainedAxiosRequestConfig<CompanyListResponse> {
    return {
        method: "get",
        url: "/app/company/subagents",
        headers: {
            "Content-Type": "application/json",
            Accept: "application/json"
        },
        params: {
            locale
        },
        maxNumRequests: 1
    };
}

export function createSubagent(subagentDetails: RegisterCompanyRequest, locale: string): ConstrainedAxiosRequestConfig<ItemModificationResponse, RegisterCompanyRequest> {
    return {
        method: "put",
        url: "/app/company/subagent",
        headers: {
            "Content-Type": "application/json",
            Accept: "application/json"
        },
        data: subagentDetails,
        params: {
            locale
        },
        maxNumRequests: 1
    };
}

export function updateSubagentDetails(subagentDetails: CompanyDetails, locale: string): ConstrainedAxiosRequestConfig<ItemModificationResponse, CompanyDetails> {
    return {
        method: "post",
        url: `/app/company/subagent/${subagentDetails.id}`,
        headers: {
            "Content-Type": "application/json",
            Accept: "application/json"
        },
        data: subagentDetails,
        params: {
            locale
        },
        maxNumRequests: 1
    };
}

export function blockUnblockSubagent(id: number, locale: string): ConstrainedAxiosRequestConfig<ItemModificationResponse, {}> {
    return {
        method: "post",
        url: `/app/company/subagent/${id}/block`,
        headers: {
            Accept: "application/json"
        },
        data: {},
        params: {
            locale
        },
        maxNumRequests: 1
    };
}