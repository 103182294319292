import {AxiosResponse} from "axios";
import * as userAPI from "../../api/userAPI";
import {AuthenticationSuccessRoute, UserDetailsPropTypes} from "proptypes/PropTypeObjects";
import InsufficientAuthenticationError from "../../models/InsufficientAuthenticationError";
import LoginError from "../../models/LoginError";
import globalAxiosController from "api/axios/globalAxiosController"
import axiosInstance from "api/axios/axiosInstance"

export function login(email: string, password: string, pin?: string): Promise<AuthenticationSuccessRoute | undefined> {
    return axiosInstance.request(userAPI.login(email, password, pin)).then(handelResponse).catch(handleError);

    function handelResponse(res: AxiosResponse<AuthenticationSuccessRoute | undefined>) {
        if (res.status === 200) {
            return res.data;
        }
        return Promise.reject(res);
    }

    function handleError(err: any): Promise<never> {
        if (err && err.response && err.response.status === 401) {
            if (err.response.data) {
                return Promise.reject(new LoginError(err.response.data));
            }
            return Promise.reject(new Error(err.response.message));
        }
        if (err && err.response && err.response.status === 403) {
            return Promise.reject(new InsufficientAuthenticationError(err.response.data));
        }
        if (!err || !err.response) {
            return Promise.reject(new Error("empty response error"));
        }
        if (err instanceof Error) {
            return Promise.reject(err.message);
        }
        return Promise.reject(JSON.stringify(err.response));
    }
}

export function loginActiveLinkToken(token: string): Promise<void> {
    return globalAxiosController.addRequest(userAPI.getLoginActiveLinkToken(token))
        .catch((error) => Promise.reject(error));
}

export function checkLogin(): Promise<UserDetailsPropTypes> {
    return globalAxiosController.addRequest(userAPI.getCurrentUserDetails())
        .catch((error) => Promise.reject(error));
}

export function logout(): Promise<string> {
    return globalAxiosController.addRequest(userAPI.logout())
        .then((res) => "Logout successful")
        .catch((error) => Promise.reject(error));
}