import React, {ReactElement} from "react"
import styles from "./DoubleDatePicker.module.scss";
import SingleDatePicker from "../SingleDatePicker/SingleDatePicker";
import {DateTime} from "luxon"
import {connect, ConnectedProps} from "react-redux"
import {toggleScrollLock} from "../../../../../redux/actions/modals.actions"

export type DoubleDatePickerProps = {
    label?: string;

    from?: DateTime | null;
    fromPlaceholder: string;

    to?: DateTime | null;
    toPlaceholder: string;

    onDateFromChange: (date: DateTime) => void;
    onDateToChange: (date: DateTime) => void;
};

const DoubleDatePicker = (props: DoubleDatePickerProps): ReactElement => {
    const {
        label,
        from,
        fromPlaceholder,
        to,
        toPlaceholder,
        onDateFromChange,
        onDateToChange
    } = props;

    return (
        <div className={styles.Root}>
            {label && (<label>{label}</label>)}

            <div className={styles.DateSelectors}>
                <SingleDatePicker className={styles.DateSelect} date={from} placeholder={fromPlaceholder} handleDateChange={onDateFromChange} />

                <SingleDatePicker className={styles.DateSelect} date={to} placeholder={toPlaceholder} handleDateChange={onDateToChange} />
            </div>
        </div>
    );
}

export default DoubleDatePicker;