import React, {ReactElement, ReactNode} from "react";
import PageControl from "components/common/PageControl";
import {useTranslation} from "react-i18next";
import {connect} from "react-redux";
import styles from "./SearchResults.module.scss";
import ResultsNav from "../ResultsNav/ResultsNav";
import {ReactComponent as FiltersIcon} from "../../../assets/icons/filters.svg";
import {toggleModal} from "../../../redux/actions/modals.actions";
import {ReduxModalType} from "../../../proptypes/PropTypeObjects";

type Props = {
    putSavedSearch: () => void;
    handleSortChange: (value: string, label: string, i?: number) => void;
    handleChangePage(page: number): void;
    handlePageSizeChange(value: number): void;
    children: ReactNode;

    data: {
        pagesCount: number;
        pageNumber: number;
        size: number;
        pageSizeOptions: { value: number, label: number }[]
        allResultsCount: number;
        sortByOptions: { value: string }[];
        sortBy: { value: string; label: string; };
    };

    toggleModal: (modal: ReduxModalType, scrollLock?: boolean) => void;
};

function SearchResults({
    putSavedSearch,
    handleSortChange,
    handleChangePage,
    handlePageSizeChange,
    children,

    data: {
        pagesCount,
        pageNumber,
        size,
        pageSizeOptions,
        allResultsCount,
        sortByOptions,
        sortBy
    },

    toggleModal
}: Props): ReactElement {
    const {t} = useTranslation();

    return (
        <div className={styles.TransferSearchResults}>
            <ResultsNav
                sortByOptions={sortByOptions}
                sortBy={sortBy}
                handleSortChange={handleSortChange}
                allResultsCount={allResultsCount}
            />

            <div className={styles.ParametersButtonContainer}>
                <div
                    onClick={() => toggleModal(ReduxModalType.FILTERS, true)}
                    className={styles.ParametersButton}
                >
                    <span>{t("t_sr_t_filters_button_label")}</span>
                    <FiltersIcon />
                </div>
            </div>

            {children}

            <PageControl
                handleChangePage={handleChangePage}
                pagesCount={pagesCount}
                pageNumber={pageNumber}
                size={size}
                pageSizeOptions={pageSizeOptions}
                handlePageSizeChange={handlePageSizeChange}
                reverse
                className={styles.PageControl}
            />
        </div>
    );
}

export default connect(undefined, {
    toggleModal
})(SearchResults);
