import React, {ReactElement, useCallback, useEffect} from "react"
import {connect, ConnectedProps} from "react-redux";
import {Navigate, useLocation, useParams} from "react-router-dom"
import * as searchResultsActions from "../../../redux/actions/transferSearchResults.actions";
import {getBookingOptions} from "../../../redux/actions/transferBooking.actions";
import {putSavedSearch} from "../../../redux/actions/transferSearch.actions";
import {getPagesCount, visibleTransferOffersSelector} from "../../../redux/selectors/transferSearchResults.selector";
import SearchResults from "./SearchResults";
import Transfer from "./Transfer";
import {TransferOfferPropTypes} from "../../../proptypes/PropTypeObjects";
import {RootState} from "../../../redux/store/store.init";

type SearchResultsContainerProps = ConnectedProps<typeof connector>;

const SearchResultsContainerTest = (props: SearchResultsContainerProps): ReactElement => {
    const {
        data: {
            visibleTransfers,
            allResultsCount,
            pagesCount,
            locale,
            pageNumber,
            sortByOptions,
            sortBy,
            size,
            pageSizeOptions,
            pax,
            transferAmount,
            currency
        },
        getBookingOptions,
        putSavedSearch,
        changePageSize,
        changeSort,
        handleChangePage,
        checkQueryParams
    } = props;

    const location = useLocation();
    const params = useParams();

    useEffect(() => {
        checkQueryParams();
    }, [checkQueryParams, params]);

    // componentDidUpdate(prev: SearchResultsContainerProps) {
    //     const {
    //         location,
    //         checkQueryParams
    //     } = this.props;
    //
    //     if (location.search && prev.location.search !== location.search) {
    //         checkQueryParams();
    //     }
    // }

    const handleChangePageCallback = useCallback((page: number) => {
        handleChangePage(page);
    }, [handleChangePage]);

    const handleSortChange = useCallback((value: string, label: string, i?: number) => {
        changeSort({
            value,
            label
        });
    }, [changeSort]);

    const handlePageSizeChange = useCallback((value: number) => {
        changePageSize(value);
    }, [changePageSize]);

    return location.search ? (
        <SearchResults
            handleChangePage={handleChangePageCallback}
            handleSortChange={handleSortChange}
            handlePageSizeChange={handlePageSizeChange}
            putSavedSearch={putSavedSearch}
            data={{
                size,
                sortBy,
                sortByOptions,
                pageSizeOptions,
                allResultsCount,
                pageNumber,
                pagesCount
            }}
        >

            {transferAmount && visibleTransfers && visibleTransfers.map((data: TransferOfferPropTypes, i: number) => (
                <Transfer
                    getBookingOptions={getBookingOptions}
                    data={{
                        ...data,
                        pax,
                        transferAmount,
                        currency
                    }}
                    key={`${i}-${data.transfer?.id}`}
                />
            ))}
        </SearchResults>
    ) : (<Navigate to="/transfers" />);
}

const mapStateToProps = ({
    transferSearchResults, locale, transferSearch, auth, currency
}: RootState) => {
    const visibleTransfers = visibleTransferOffersSelector()(transferSearchResults);
    return {
        data: {
            visibleTransfers,
            allResultsCount: transferSearchResults.allResultsCount,
            pagesCount: getPagesCount(transferSearchResults.allResultsCount, transferSearchResults.size),
            locale: locale.currentLocale,
            pageNumber: transferSearchResults.pageNumber,
            sortByOptions: transferSearchResults.sortByOptions,
            sortBy: transferSearchResults.sortBy,
            size: transferSearchResults.size,
            pageSizeOptions: transferSearchResults.pageSizeOptions,
            pax: (Number(transferSearch.formData ? transferSearch.formData?.adults : 0) + (transferSearch.formData ? transferSearch.formData?.children.length : 0)),
            transferAmount: auth.userData?.companyMarkups[auth.currentCompanyMarkupIndex || 0].transferAmount,
            currency: currency.currentCurrency
        }
    };
};

const connector = connect(mapStateToProps, {
    ...searchResultsActions,
    putSavedSearch,
    getBookingOptions
});

export default connector(SearchResultsContainerTest);
