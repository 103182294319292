import React, {ReactElement} from "react";
import styles from "./BookingInfo.module.scss";
import SpecialRequests from "../SpecialRequests";
import ContactPersonBox from "../../ContactPersonBox";
import BillingDetailsBox from "../../BillingDetailsBox";
import PickupTimeSMSBox from "../../PickupTimeSMSBox";
import MemoBox from "../../MemoBox";
import {
    ClientBookingStatusPropTypes,
    ContactPersonPropTypes,
    InvoiceRecipientPropTypes, ReconfirmationDetailsPropTypes,
    SpecialRequestsPropTypes
} from "../../../../proptypes/PropTypeObjects";
import ClientRequiredCheckInInformation from "./ClientRequiredCheckInInformation/ClientRequiredCheckInInformation";
import HotelTransferDetails from "./HotelTransferDetails/HotelTransferDetails";
import ReconfirmationDetails from "./ReconfirmationDetails/ReconfirmationDetails";
import {DateTime} from "luxon"

type Props = {
    specialRequests?: SpecialRequestsPropTypes;
    checkIn?: DateTime;
    checkOut?: DateTime;
    id: number;
    sms?: {
        smsOrdered?: boolean;
        smsReminderNumber?: string;
        smsLanguage?: string;
    };
    contactInfo?: ContactPersonPropTypes;
    customInvoice?: boolean;
    invoiceRecipient?: InvoiceRecipientPropTypes;
    memo?: string;
    showSpecialRequests?: boolean;
    bookingStatus?: ClientBookingStatusPropTypes;
    canEditMemo?: boolean;

    reconfirmationDetails?: ReconfirmationDetailsPropTypes;
    freeTransferIncluded?: boolean,
    canEnterRequiredCheckInInfo?: boolean
    canEditClientRequiredCheckInInformation?: boolean;
    departureTransfer?: boolean;
};

function BookingInfo({
    specialRequests,
    checkIn,
    checkOut,
    id,
    sms: {
        smsOrdered,
        smsReminderNumber,
        smsLanguage
    } = {},
    contactInfo,
    customInvoice,
    invoiceRecipient,
    memo,
    showSpecialRequests,
    canEditMemo,
    bookingStatus,

    freeTransferIncluded,

    reconfirmationDetails,
    canEnterRequiredCheckInInfo,
    canEditClientRequiredCheckInInformation,
    departureTransfer
}: Props): ReactElement {
    return (
        <div className={styles.Root}>
            {contactInfo && (
                <ContactPersonBox
                    contactInfo={contactInfo}
                />
            )}

            {freeTransferIncluded && (
                <HotelTransferDetails
                    id={id}
                />
            )}

            {reconfirmationDetails && (
                <ReconfirmationDetails
                    reconfirmation={reconfirmationDetails}
                />
            )}

            {canEnterRequiredCheckInInfo && !["CANCELLATION_PENDING", "CANCELLED", "REJECTED"].includes(bookingStatus || "") && (
                <ClientRequiredCheckInInformation
                    id={id}
                    editable={canEditClientRequiredCheckInInformation}
                />
            )}

            {customInvoice && invoiceRecipient && (
                <BillingDetailsBox
                    customInvoice={customInvoice}
                    invoiceRecipient={invoiceRecipient}
                />
            )}

            {(!!showSpecialRequests || specialRequests) && (
                <SpecialRequests
                    specialRequests={specialRequests}
                    bookingStatus={bookingStatus}
                    checkIn={checkIn}
                    id={id}
                />
            )}

            <PickupTimeSMSBox
                smsOrdered={smsOrdered}
                smsLocale={smsLanguage}
                smsReminderNumber={smsReminderNumber}
                bookingCancelled={["CANCELLED", "CANCELLATION_PENDING", "REJECTED"].includes(bookingStatus || "")}
                departureTransfer={departureTransfer || false}
                departureDate={checkOut}
            />

            <MemoBox
                memo={memo}
                editable={canEditMemo}
            />

        </div>
    );
}

export default BookingInfo;
