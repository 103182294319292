import React, {PureComponent, ReactElement} from "react";
import LoadingScreen from "./LoadingScreen";
import LoadingAd from "./LoadingAd";
import PageIntro from "../../../components/common/PageIntro";
import introImg from "../../../assets/photos/transfer-landing.png";

class Searching extends PureComponent {
    render(): ReactElement {
        return (
            <>
                <PageIntro fadeIn={false} introImg={introImg} style={{backgroundPositionY: "95%"}} />
                <LoadingScreen />
                {/*<LoadingAd />*/}
            </>
        );
    }
}

export default Searching;
