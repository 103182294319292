import React, {PureComponent, ReactElement} from "react";
import "./HotelModal.scss";
import Modal from "components/common/Modal";
import {KeyDetectVirtualComponent} from "components/utils/useKeyDetect";
import Fade from "components/utils/Transitions/Fade";
import ModalWithOverlay from "../Modal/ModalWithOverlay";
import {HotelPropTypes} from "../../../proptypes/PropTypeObjects";
import Hotel from "./Hotel/Hotel";

type Props = {
    data: {
        hotel: HotelPropTypes;
    }
    toggleHotel: (open: boolean) => void;
};

type HotelState = {
    fade: boolean
};

class HotelModal extends PureComponent<Props, HotelState> {
    constructor(props: Props) {
        super(props);
        this.state = {
            fade: false
        };
    }

    render(): ReactElement {
        const {
            data: {
                hotel
            },
            toggleHotel
        } = this.props;
        const {fade} = this.state;

        const fadeDuration = 300;

        const toggleFade = () => {
            this.setState({
                fade: !fade
            });
        };

        const toggleHotelWithFade = () => {
            this.setState({fade: false});
            setTimeout(() => toggleHotel(false), fadeDuration);
        };

        return (
            <Modal>
                <Fade duration={fadeDuration} inProp={fade}>
                    <ModalWithOverlay>
                        <KeyDetectVirtualComponent keyCode={27} callback={toggleHotelWithFade} />

                        <Hotel
                            hotel={hotel}
                            toggleHotel={toggleHotel}
                            toggleFade={toggleFade}
                        />
                    </ModalWithOverlay>
                </Fade>
            </Modal>
        );
    }
}

export default HotelModal;
