import * as bookingDetailsAPI from "api/bookingDetailsAPI";
import {
    BookingListResultPropTypes, BookingSearchCriteriaPropTypes,
    BookingSearchOptionsPropTypes,
    ColumnPropTypes, SortColumnPropTypes
} from "proptypes/PropTypeObjects";
import MyBookingsTypes from "redux/constants/myBookings.constants";
import {CommonActionReturnType} from "redux/store/store.init";
import IAppActions from "redux/store/store.actions";
import {globalAxiosController} from "api/axios/globalAxiosController";

export function searchBookings(criteria?: BookingSearchCriteriaPropTypes): CommonActionReturnType {
    const start = (): IAppActions => ({type: MyBookingsTypes.START_SEARCH});
    const success = (data: BookingListResultPropTypes): IAppActions => ({
        type: MyBookingsTypes.SEARCH_SUCCESS,
        data
    });
    const failure = (error: any): IAppActions => ({
        type: MyBookingsTypes.SEARCH_FAILURE,
        error
    });

    return (dispatch, getState) => {
        const {pageSize, pageNr, formData, activeQuickFilters} = getState().myBookings;
        const locale = getState().locale.currentLocale;
        const currency = getState().currency.currentCurrency;

        dispatch(start());
        globalAxiosController.addRequest(bookingDetailsAPI.searchBookings(criteria || {
            ...formData,
            quickFilters: activeQuickFilters
        }, pageSize, pageNr, locale, currency))
            .then((data) => {
                dispatch(success(data));
            })
            .catch((error: any) => {
                dispatch(failure(error));
            });
    };
}

export function handleSubmit(formData = {}): CommonActionReturnType {
    return (dispatch) => {
        dispatch(handleFormData(formData));
        dispatch(searchBookings());
    };
}

export function handleFormData(formData: BookingSearchCriteriaPropTypes): IAppActions {
    return ({type: MyBookingsTypes.HANDLE_FORM_DATA, formData});
}

export function toggleAdvancedFilters(): IAppActions {
    return ({type: MyBookingsTypes.TOGGLE_ADVANCED_FILTERS});
}

export function resetData(): IAppActions {
    return ({type: MyBookingsTypes.RESET_DATA});
}

export function resetFormData(): CommonActionReturnType {
    return (dispatch) => {
        dispatch({type: MyBookingsTypes.RESET_FORM_DATA});
        dispatch(searchBookings());
    };
}

export function getSearchOptions(): CommonActionReturnType {
    const start = (): IAppActions => ({type: MyBookingsTypes.REQ_BOOKING_OPTIONS});
    const success = (bookingOptions: BookingSearchOptionsPropTypes): IAppActions => ({
        type: MyBookingsTypes.GET_BOOKING_SEARCH_OPTIONS_SUCCESS,
        bookingOptions
    });
    const failure = (error: any): IAppActions => ({
        type: MyBookingsTypes.GET_BOOKING_OPTIONS_FAILURE,
        error
    });

    return (dispatch, getState) => {
        const locale = getState().locale.currentLocale;

        dispatch(start());
        globalAxiosController.addRequest(bookingDetailsAPI.getSearchOptions(locale))
            .then((data) => {
                dispatch(success(data));
            })
            .catch((error: any) => {
                dispatch(failure(error));
            });
    };
}

export function handleQuickFilters(quickFilter: string, isActive: boolean): IAppActions {
    return ({type: MyBookingsTypes.HANDLE_QUICK_FILTERS, quickFilter, isActive});
}

export function handleSort(sort: SortColumnPropTypes): CommonActionReturnType {
    return (dispatch) => {
        dispatch(({type: MyBookingsTypes.HANDLE_SORT, sort}));
        dispatch(searchBookings());
    };
}

export function handlePageNr(pageNr: number): CommonActionReturnType {
    return (dispatch) => {
        dispatch(({type: MyBookingsTypes.HANDLE_PAGE_NR, pageNr}));
        dispatch(searchBookings());
    };
}

export function handlePageSize(pageSize: number): CommonActionReturnType {
    return (dispatch) => {
        dispatch(({type: MyBookingsTypes.HANDLE_PAGE_SIZE, pageSize}));
        dispatch(searchBookings());
    };
}

export function handleColumns(columnsObj: ColumnPropTypes[]): CommonActionReturnType {
    const start = (): IAppActions => ({type: MyBookingsTypes.MODIFY_COL_REQUEST});
    const success = (columnsObj: ColumnPropTypes[]): IAppActions => ({
        type: MyBookingsTypes.MODIFY_COL_SUCCESS,
        columnsObj
    });
    const failure = (error: any): IAppActions => ({
        type: MyBookingsTypes.MODIFY_COL_FAILED,
        error
    });

    return (dispatch) => {
        dispatch(start());
        globalAxiosController.addRequest(bookingDetailsAPI.modifyColumns({
            columns: columnsObj
        }))
            .then(() => {
                dispatch(success(columnsObj));
            })
            .catch((error: any) => {
                dispatch(failure(error));
            });
    };
}
