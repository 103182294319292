export enum HotelBookingTypes {
    //hotelBooking options
    GET_OPTIONS = "hotelBooking/GET_OPTIONS",
    GET_OPTIONS_SUCCESS = "hotelBooking/GET_OPTIONS_SUCCESS",
    GET_OPTIONS_FAILURE = "hotelBooking/GET_OPTIONS_FAILURE",

    UPDATE_OPTIONS = "hotelBooking/UPDATE_OPTIONS",
    UPDATE_OPTIONS_SUCCESS = "hotelBooking/UPDATE_OPTIONS_SUCCESS",
    UPDATE_OPTIONS_FAILURE = "hotelBooking/UPDATE_OPTIONS_FAILURE",

    // make hotelBooking

    REQUEST_HOTEL_BOOKING = "hotelBooking/REQUEST_HOTEL_BOOKING",
    HOTEL_BOOKING_SUCCESS = "hotelBooking/HOTEL_BOOKING_SUCCESS",
    HOTEL_BOOKING_FAILURE = "hotelBooking/HOTEL_BOOKING_FAILURE",
    HOTEL_BOOKING_CHANGES = "hotelBooking/HOTEL_BOOKING_CHANGES",

    // validate

    REQUEST_VALIDATE_BOOKING = "hotelBooking/REQUEST_VALIDATE_BOOKING",
    VALIDATE_BOOKING_SUCCESS = "hotelBooking/VALIDATE_BOOKING_SUCCESS",
    VALIDATE_BOOKING_FAILURE = "hotelBooking/VALIDATE_BOOKING_FAILURE",

    // inputs hotelBooking Info

    CONFIRM_DUPLICATE_BOOKING = "hotelBooking/CONFIRM_DUPLICATE_BOOKING",
    HANDLE_CONTACT_INFO = "hotelBooking/HANDLE_CONTACT_INFO",
    HANDLE_LATE_ARRIVAL = "hotelBooking/HANDLE_LATE_ARRIVAL",
    HANDLE_MEMO = "hotelBooking/HANDLE_MEMO",

    // hotel hotelBooking info

    HANDLE_SPECIAL_REQUESTS = "hotelBooking/HANDLE_SPECIAL_REQUESTS",
    HANDLE_ADULTS = "hotelBooking/HANDLE_ADULTS",
    HANDLE_CHILDREN = "hotelBooking/HANDLE_CHILDREN",

    ACCEPT_BOOKING_CHANGES = "hotelBooking/ACCEPT_BOOKING_CHANGES",
    CLEAR_BOOKING_DATA = "hotelBooking/CLEAR_BOOKING_DATA",
    CLEAR_VALIDATION_DATA = "hotelBooking/CLEAR_VALIDATION_DATA",

    SAVE_PASSENGER_DETAILS = "hotelBooking/SAVE_PASSENGER_DETAILS",
    REFILL_PASSENGER_DETAILS = "hotelBooking/REFILL_PASSENGER_DETAILS",

    HANDLE_CHECK_IN_LEAD_PASSENGER_NAME = "hotelBooking/HANDLE_CHECK_IN_LEAD_PASSENGER_NAME",
    HANDLE_CHECK_IN_LEAD_PASSENGER_PHONE_NUMBER = "hotelBooking/HANDLE_CHECK_IN_LEAD_PASSENGER_PHONE_NUMBER",
    HANDLE_CHECK_IN_PHONE_COUNTRY_CODE = "hotelBooking/HANDLE_CHECK_IN_PHONE_COUNTRY_CODE",
    HANDLE_CHECK_IN_ARRIVAL_TIME = "hotelBooking/HANDLE_CHECK_IN_ARRIVAL_TIME",
    TOGGLE_CHECK_IN_ENTER_LATER = "hotelBooking/TOGGLE_CHECK_IN_ENTER_LATER",

    TOGGLE_FLIGHT_DETAILS = "hotelBooking/TOGGLE_FLIGHT_DETAILS",
    HANDLE_FLIGHT_DETAILS_CODE_FROM = "hotelBooking/HANDLE_FLIGHT_DETAILS_CODE_FROM",
    HANDLE_FLIGHT_DETAILS_CODE_TO = "hotelBooking/HANDLE_FLIGHT_DETAILS_CODE_TO",
    HANDLE_FLIGHT_DETAILS_DATE = "hotelBooking/HANDLE_FLIGHT_DETAILS_DATE",
    HANDLE_FLIGHT_DETAILS_TIME = "hotelBooking/HANDLE_FLIGHT_DETAILS_TIME",
    HANDLE_FLIGHT_DETAILS_NUMBER = "hotelBooking/HANDLE_FLIGHT_DETAILS_NUMBER"
}

export default HotelBookingTypes;