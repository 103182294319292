import React, {ReactElement, useCallback, useState} from "react";
import UserButton from "components/base/UserButton";
import {ReactComponent as MailIcon} from "assets/icons/mail.svg";
import {ReactComponent as DownloadIcon} from "assets/icons/download.svg";
import baseURL from "api/constants/baseURL";
import {useTranslation} from "react-i18next";
import {resendVoucher} from "api/bookingDetailsAPI";
import styles from "./Voucher.module.scss";
import {UserButtonVariant} from "../../base/UserButton/UserButton";
import {CustomTagType} from "../../utils/CustomTag";
import Spinner from "../../base/Loaders/Spinner";
import {globalAxiosController} from "api/axios/globalAxiosController";

type Props = {
    id: number;
    email: string;
    voucherEnabled: boolean;
};

function Voucher({
    id,
    email,
    voucherEnabled
}: Props): ReactElement {
    const {t} = useTranslation();

    const [spinner, setSpinner] = useState(false);

    const resend = useCallback(() => {
        setSpinner(true);
        globalAxiosController.addRequest(resendVoucher(id)).then(() => {
            setSpinner(false);
        }).catch(() => {
            setSpinner(false);
        });
    }, [id]);

    return (
        <div className={styles.Root}>
            <h3 className={styles.Title}>{t("bs_vo_voucher")}</h3>

            <div className={styles.Box}>
                {voucherEnabled
                    ? <p>{t("bs_vo_voucher_notice", {email})}</p>
                    : <p>{t("bs_vo_voucher_notice_ns", {email})}</p>}

                {voucherEnabled && (
                    <>
                        <div
                            style={{
                                display: "block",
                                margin: "20px 0"
                            }}
                            className={styles.DashedLine}
                        />

                        <div className={styles.Wrapper}>
                            {spinner && (
                                <Spinner
                                    size="50px"
                                    style={{
                                        width: "100%",
                                        height: "100%"
                                    }}
                                />
                            )}

                            {!spinner && (
                                <UserButton
                                    text={t("bs_vo_resend_button")}
                                    variant={UserButtonVariant.SECONDARY}
                                    customIcon={(
                                        <MailIcon
                                            width={16}
                                            style={{
                                                marginLeft: 20
                                            }}
                                        />
                                    )}
                                    buttonProps={{
                                        onClick: () => resend(),
                                        type: "button"
                                    }}
                                />
                            )}

                            <UserButton
                                text={t("bs_vo_download")}
                                variant={UserButtonVariant.PRIMARY}
                                customTag={CustomTagType.A}
                                customIcon={(
                                    <DownloadIcon
                                        style={{
                                            marginLeft: 20
                                        }}
                                        width={12}
                                    />
                                )}
                                buttonProps={{
                                    href: `${baseURL}/booking/${id}/voucher`,
                                    download: id
                                }}
                            />
                        </div>
                    </>
                )}
            </div>
        </div>
    );
}

export default Voucher;
