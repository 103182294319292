import React, {ReactElement, useEffect} from "react";
import {logout} from "redux/actions/auth.actions";
import {useAppDispatch, useAppSelector} from "redux/hooks";
import {matchPath, Navigate} from "react-router-dom";
import {lastLocation} from "views/router/router"

function Logout(): ReactElement {
    const dispatch = useAppDispatch();
    const noGoBackPaths = useAppSelector((state) => state.router.noGoBackPaths);
    const userEmail = useAppSelector((state) => state.auth.userData && state.auth.userData.companyUser.email);

    function checkLastLocation() {
        for (let index = 0; index < noGoBackPaths.length; index++) {
            if (lastLocation && matchPath(lastLocation.pathname, noGoBackPaths[index])) {
                return {
                    pathname: "/",
                    state: undefined
                };
            }
        }

        return {
            ...lastLocation,
            userEmail
        };
    }

    useEffect(() => dispatch(logout()), [dispatch]);

    return (
        <Navigate
            to={{pathname: "/login"}}
            state={lastLocation
                ? lastLocation.pathname !== "/login"
                    ? checkLastLocation()
                    : lastLocation.state
                : undefined}
        />
    );
}

export default Logout;
