import React, {ReactElement} from "react";
import {useTranslation} from "react-i18next";
import styles from "./BookingChangedTotals.module.scss";
import {BookingChangedTotals} from "models/BookingChangedTotals";
import getPriceWithCurrency from "@hotelston_web_frontend_utils/currency/getPriceWithCurrency";
import Currency from "@hotelston_web_frontend_parent/constants/currency";

type Props = {
    oldAndNewTotals?: BookingChangedTotals
    currencyName: Currency
};

function BookingChangesTotals({
    oldAndNewTotals,
    currencyName
}: Props): ReactElement {
    const {t} = useTranslation();

    return (
        <div className={styles.Root}>
            <div className={styles.Heading}>{t("h_bc_abc_totals_heading")}</div>

            <div className={styles.Wrapper}>
                {oldAndNewTotals && oldAndNewTotals.priceChanged && (
                    <table className={styles.Table}>
                        <tbody className={styles.Body}>
                            {(oldAndNewTotals.oldTransactionFee > 0 || oldAndNewTotals.newTransactionFee > 0) && (
                                <tr>
                                    <td className={styles.OldTotal}>{t("h_bc_abc_old_transaction_total")}:</td>
                                    <td className={styles.OldTotal}>{getPriceWithCurrency(oldAndNewTotals.oldTransactionFee, currencyName)}</td>

                                    <td className={styles.NewTotal}>{t("h_bc_abc_new_transaction_total")}:</td>
                                    <td className={styles.NewTotal}>{getPriceWithCurrency(oldAndNewTotals.newTransactionFee, currencyName)}</td>
                                </tr>
                            )}

                            <tr>
                                <td className={styles.OldTotal}>{t("h_bc_abc_old_total")}:</td>
                                <td className={styles.OldTotal}>{getPriceWithCurrency(oldAndNewTotals.oldBookingPrice, currencyName)}</td>

                                <td className={styles.NewTotal}>{t("h_bc_abc_new_total")}:</td>
                                <td className={styles.NewTotal}>{getPriceWithCurrency(oldAndNewTotals.newBookingPrice, currencyName)}</td>
                            </tr>
                        </tbody>
                    </table>
                )}
            </div>
        </div>
    );
}

export default BookingChangesTotals;
