import React, {ReactElement} from "react";
import PageTemplate from "components/base/PageTemplate/PageTemplate";
import HeaderContainer from "components/base/Header";
import Righter from "components/base/Righter";
import Lefter from "components/base/Lefter";
import Footer from "components/base/Footer";
import {Navigate, Route, Routes} from "react-router-dom"
import MyCompanyContainer from "./MyCompanyContainer";
import Agreement from "./Agreements/Agreement/Agreement";
import ErrorBoundary from "../../components/utils/ErrorBoundary/ErrorBoundary";
import OtherCompanyUserView from "./EditDeleteUser/CompanyUserView/OtherCompanyUserView";
import CurrentCompanyUserView from "./EditDeleteUser/CompanyUserView/CurrentCompanyUserView";

const MyCompany = (): ReactElement => (
    <PageTemplate
        header={<HeaderContainer />}
        footer={<Footer />}
        lefter={<Lefter />}
        righter={<Righter />}
        className="MyCompany"
    >
        <ErrorBoundary>
            <Routes>
                <Route path="/" element={<MyCompanyContainer />}/>

                {/*<Route path="/billing-details" element={<EntityCredentials />} />*/}

                <Route path="/user/:id" element={<OtherCompanyUserView />}/>

                <Route path="/user" element={<CurrentCompanyUserView />} />

                <Route path="/agreement/:id" element={<Agreement />}/>

                <Route path="*" element={<Navigate to="/not-found" />}/>
            </Routes>
        </ErrorBoundary>
    </PageTemplate>
);

export default MyCompany;