import React from "react";
import {useTranslation} from "react-i18next";
import withConfirmation, {WithConfirmation} from "components/utils/withConfirmation";
import cx from "classnames";
import UserButton from "../../base/UserButton";
import styles from "./CancelBooking.module.scss";
import isCancelBookingFree from "../../../utils/isCancelBookingFree";
import {ClientBookingStatusPropTypes} from "../../../proptypes/PropTypeObjects";
import {UserButtonVariant} from "../../base/UserButton/UserButton";
import {convertToServerTime} from "../../../utils/dateUtils";
import createLuxonDate from "../../../utils/date/createLuxonDate"

type Props = WithConfirmation & {
    cxlDate: number;
    serviceStartDate: number;
    status: ClientBookingStatusPropTypes;
    onCancel: () => void;
    className?: string;
};

function CancelBooking({
    cxlDate,
    serviceStartDate,
    onCancel,
    setModal,
    status,
    className
}: Props) {
    const {t} = useTranslation();
    const cxlBeforeCheckin = Math.floor(createLuxonDate(convertToServerTime(serviceStartDate)).diff(
        createLuxonDate(cxlDate),
        "days"
    ).days);
    const cxlDateFormed = createLuxonDate(convertToServerTime(cxlDate)).toFormat("yyyy-MM-dd");
    return (
        <div className={cx(styles.root, className)}>
            <div className={styles.box}>
                <span className={styles.label}>
                    {t("cb_cb_booking_cancellation")}
                </span>
                <div className={styles.comment}>
                    {t("cb_cb_booking_cancellation_notice", {cxlDays: cxlBeforeCheckin, cxlDate: cxlDateFormed})}
                </div>
            </div>
            <UserButton
                text={t("cb_cb_cancel_booking_button")}
                variant={UserButtonVariant.SECONDARY}
                buttonProps={{
                    onClick: () => setModal({
                        onConfirm: () => onCancel(),
                        onDecline: () => false,
                        config: {
                            variants: {
                                type: "warn"
                            },
                            title: t("cb_cb_booking_cancellation"),
                            content: (
                                <span>
                                    {isCancelBookingFree(cxlDate, status) ? t("cb_cb_question_to_confirm_cancel_booking") : t("cb_cb_question_to_confirm_cancel_booking_with_penalty")}
                                </span>
                            ),
                            confirmButtonText: t("cb_cb_confirm"),
                            declineButtonText: t("cb_cb_cancel")
                        }
                    }),
                    type: "button",
                    style: {
                        height: "50px",
                        width: "160px",
                        marginLeft: "auto"
                    }
                }}
            />
        </div>
    );
}

export default withConfirmation(CancelBooking);
