import React, {
    ReactElement, useEffect, useState
} from "react";
import {useTranslation} from "react-i18next";
import {Link} from "react-router-dom";
import styles from "./Hotel.module.scss";
import ActionIcon, {ActionIconType} from "../../ActionIcon/ActionIcon";
import Stars from "../../Stars";
import getHotelAddress from "../../../../utils/getHotelAddress";
import CopyToClipboard from "../../CopyToClipboard";
import {LazyImageSlider, LazyImageSliderImage} from "../../LazyImageSlider/LazyImageSlider";
import {HotelFeaturePropTypes, HotelPropTypes} from "../../../../proptypes/PropTypeObjects";
import Location from "./Location/Location";
import FeaturesSearch from "../../../../views/Hotels/Hotel/FeaturesSearch/FeaturesSearch";
import FeatureIcon from "../../Feature/FeatureIcon";
import createResizedImageUrl, {
    HOTELS_SLIDER_RESOLUTION,
    IMAGE_SLIDER_THUMBNAIL_RESOLUTION
} from "../../../../utils/thumbnail";

export type HotelProps = {
    hotel: HotelPropTypes;
    toggleHotel: (open: boolean) => void;

    toggleFade: () => void;
};

const Hotel = ({
    toggleHotel,
    hotel,
    hotel: {
        id,

        name,
        address,
        starRating,

        coordinates,
        phone,
        description,

        featureGroups,
        distances,
        safetyFeatureGroups
    },

    toggleFade
}: HotelProps): ReactElement => {
    const {t} = useTranslation();
    const [images, setImages] = useState<string[]>();
    const [featureInput, setFeatureInput] = useState<string>("");
    const [safetyFeaturesSearchInput, setSafetyFeaturesSearchInput] = useState<string>("");

    const getFinalImagesArr = async (hotel: HotelPropTypes) => {
        if (!hotel.images) {
            return [];
        }

        return hotel.images;
    };

    let imagesGallery;
    if (images && images.length > 0) {
        imagesGallery = images.map((imgSrc: string) => (
            {
                original: createResizedImageUrl(imgSrc, HOTELS_SLIDER_RESOLUTION),
                thumbnail: createResizedImageUrl(imgSrc, IMAGE_SLIDER_THUMBNAIL_RESOLUTION)
            } as LazyImageSliderImage
        ));
        // .concat([
        //   {
        //       original: "",
        //       renderItem: renderMapItem,
        //       renderThumbInner: renderMapThumbItem
        //   } as LazyImageSliderImage
        // ]);
    }

    useEffect(() => {
        void getFinalImagesArr(hotel).then((images: string[]) => {
            setImages(images);
            toggleFade();
        });
    }, []);

    return (
        <div className={styles.Root}>
            <div onClick={() => toggleHotel(false)} className={styles.CloseButton}>
                <ActionIcon type={ActionIconType.CLOSE} />
            </div>

            <div className={styles.Content}>
                <h1 className={styles.Title}>{name}</h1>
                <div className={styles.ContentHeader}>
                    <Stars className={styles.StarsContainer} starRating={starRating} />
                    <span className={styles.HotelAddress}>{getHotelAddress(address)}</span>

                    <CopyToClipboard className={styles.CopyLink} url={`${window.location.hostname}/hotels/hotel/${id}`} />
                    <div className={styles.Separator} />
                    <Link className={styles.OpenInNewTab} target="_blank" to={`/hotels/hotel/${id}`}>{t("hm_hm_open_in_new_tab")}</Link>
                </div>

                <div className={styles.ImageViewerContainer}>
                    <LazyImageSlider
                        showImageMessage
                        images={imagesGallery}
                        showThumbnails
                        showFullscreenButton
                        disableKeyDown={false}
                        //showGalleryFullscreenButton
                        //showGalleryPlayButton
                    />
                </div>

                {description && (
                    <div className={styles.Description}>
                        <div
                            dangerouslySetInnerHTML={{
                                __html: description.toString()
                            }}
                            className={styles.DescriptionText}
                        />
                    </div>
                )}
                <div className={styles.FeaturesContainer}>
                    {safetyFeatureGroups && safetyFeatureGroups.length > 0 && (
                        <div className={styles.FeaturesContainer}>
                            <div className={styles.FeaturesTopRow}>
                                <span className={styles.FeaturesTitle}>
                                    {t("h_h_h_health_and_safety")}
                                </span>

                                <FeaturesSearch
                                    // handleClickOutside={() => setSafetyFeaturesSearchIsOpen(false)}
                                    className={styles.FeaturesSearch}
                                    handleInput={(e) => setSafetyFeaturesSearchInput(e.target.value)}
                                    inputName="safetyFeaturesSearchInput"
                                />
                            </div>

                            {safetyFeatureGroups && safetyFeatureGroups.length > 0 && (
                                <div className={styles.FeatureColumnsContainer}>
                                    {safetyFeatureGroups.filter(({hotelFeatures, name}) => name.toLowerCase()
                                        .includes(safetyFeaturesSearchInput.toLowerCase()) || hotelFeatures.filter(({name}) => name.toLowerCase()
                                        .includes(safetyFeaturesSearchInput.toLowerCase())).length > 0)
                                        .map((featureGroup) => (
                                            <div key={featureGroup.name} className={styles.FeatureColumn}>
                                                <div className={styles.FeatureColumnHeading}>
                                                    <FeatureIcon featureGroupId={featureGroup.id} width="28px" height="14px" className="mr-2" />

                                                    {featureGroup.name}
                                                </div>
                                                <div>
                                                    {featureGroup.hotelFeatures.filter(({name}: HotelFeaturePropTypes) => featureGroup.name.toLowerCase()
                                                        .includes(safetyFeaturesSearchInput.toLowerCase()) || name.toLowerCase()
                                                        .includes(safetyFeaturesSearchInput.toLowerCase()))
                                                        .map(({name}: HotelFeaturePropTypes, i: number) => (
                                                            <div key={i}>
                                                                <p>{name}</p>
                                                            </div>
                                                        ))}
                                                </div>
                                            </div>
                                        ))}
                                </div>
                            )}
                            <div>{t("h_h_h_health_and_safety_footer")}</div>
                        </div>
                    )}
                </div>

                <div className={styles.FeaturesContainer}>
                    <div className={styles.FeaturesTopRow}>
                        <span className={styles.FeaturesTitle}>{t("hm_hm_features")}</span>

                        <FeaturesSearch
                            // handleClickOutside={() => setFeatureInputOpen(false)}
                            className={styles.FeaturesSearch}
                            handleInput={(e) => setFeatureInput(e.target.value)}
                            inputName="featuresSearchInput"
                        />
                    </div>

                    {featureGroups && featureGroups.length > 0 && (
                        <div className={styles.FeatureColumnsContainer}>
                            {featureGroups.filter(({hotelFeatures, name}) => name.toLowerCase().includes(featureInput.toLowerCase()) || hotelFeatures.filter(({name}) => name.toLowerCase().includes(featureInput.toLowerCase())).length > 0).map((featureGroup) => (
                                <div className={styles.FeatureColumn} key={featureGroup.id}>
                                    <div className={styles.FeatureColumnHeading}>
                                        <FeatureIcon featureGroupId={featureGroup.id} width="28px" height="14px" className="mr-2" />

                                        {featureGroup.name}
                                    </div>

                                    {featureGroup.hotelFeatures.filter(({name}: HotelFeaturePropTypes) => featureGroup.name.toLowerCase().includes(featureInput.toLowerCase()) || name.toLowerCase().includes(featureInput.toLowerCase())).map(({name}, i: number) => (
                                        <div key={i}>
                                            <p>{name}</p>
                                        </div>
                                    ))}
                                </div>
                            ))}
                        </div>
                    )}

                    <div className={styles.LocationsContainer}>
                        {distances && distances.length > 0 && (
                            <div className={styles.LocationColumnsBlock}>
                                <h4>{t("hm_hm_location")}</h4>
                                <Location distances={distances} />
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Hotel;