import React, {ReactElement, useEffect, useState} from "react";
import {Trans, useTranslation} from "react-i18next";
import UserButton from "components/base/UserButton";
import InfoBox from "components/common/InfoBox";
import {ReactComponent as DelIcon} from "assets/icons/delete.svg";
import {ReactComponent as RemoveIcon} from "assets/icons/green-cross.svg";
import {ReactComponent as DownloadIcon} from "assets/icons/download.svg";
import {ReactComponent as DocIcon} from "assets/icons/document.svg";
import Browse from "components/base/Browse/Browse";
import baseUrl from "api/constants/baseURL";
import Tippy from "../../../../../components/common/Tippy/Tippy";
import styles from "./AgreementSidebar.module.scss";
import {DocumentFile, DocumentStatus} from "../../../../../proptypes/PropTypeObjects";
import {UserButtonVariant} from "../../../../../components/base/UserButton/UserButton";
import downloadFile from "../../../../../utils/downloadFile";

type Props = {
    status: DocumentStatus;
    rejectionReason: string;
    name: string;
    uploadedFiles: DocumentFile[];
    agreementFile: DocumentFile;
    signee?: string;
    signeePosition: string;
    deleteFile: (id: number) => void;
    uploadFiles: (files: File[]) => void;
    cancelDocVerification: () => void;
};

export default function AgreementsSidebar({
    rejectionReason,
    name,
    uploadedFiles,
    agreementFile,
    status,
    signee,
    signeePosition,
    deleteFile,
    uploadFiles,
    cancelDocVerification
}: Props): ReactElement {
    const {t} = useTranslation();

    const [selectedFiles, setSelectedFiles] = useState<File[]>([]);

    useEffect(() => {
        if (status !== "NOT_SIGNED" && status !== "SIGNATURE_REJECTED") {
            setSelectedFiles([]);
        }
    }, [status]);

    const removeFile = (index: number) => setSelectedFiles(selectedFiles.filter((_, i) => i !== index));

    return (
        <div className={styles.Root}>
            <h3>{t("mc_a_as_sign_agreement")}</h3>

            {status === "SIGNATURE_REJECTED" && (
                <InfoBox
                    style={{
                        marginBottom: 20,
                        lineHeight: 22
                    }}
                    title={(
                        <p>{t("mc_a_as_reject_agreement_msg", {reason: rejectionReason, name})}</p>
                    )}
                    message=""
                    type="error"
                />
            )}

            {status === "SIGNATURE_CONFIRMED" && (
                <div className={styles.SuccessBox}>
                    <p>{t("mc_a_as_success_agreement_msg", {name})}</p>
                </div>
            )}

            {status === "SIGNATURE_NOT_VERIFIED" && (
                <div className={styles.SuccessBox}>
                    <p>{t("mc_a_as_pending_agreement_msg")}</p>
                </div>
            )}

            {(status === "NOT_SIGNED" || status === "SIGNATURE_REJECTED") && agreementFile && (
                <div className={styles.AgreeBox}>
                    <p>{t("mc_a_as_agreement_template")}</p>
                    <UserButton
                        text={t("mc_a_as_download")}
                        variant={UserButtonVariant.WHITE}
                        customTag={
                            // @ts-ignore
                            ({children, ...rest}) => (<a {...rest}>{children}</a>)
                        }
                        customIcon={<DownloadIcon className={styles.Icon} />}
                        buttonProps={{
                            rel: "noopener noreferrer",
                            target: "_blank",
                            href: `${baseUrl}/company/document/file/${agreementFile.id}`,
                            download: agreementFile.fileName,
                            style: {
                                width: 145,
                                marginLeft: "auto"
                            }
                        }}
                    />
                </div>
            )}

            {(status === "NOT_SIGNED" || status === "SIGNATURE_REJECTED") && agreementFile && (
                <div className={styles.UploadBox}>
                    <p>
                        <Trans
                            values={{
                                signee,
                                signeePosition
                            }}
                            i18nKey="mc_a_as_signing_agreement_requiirments"
                        >
                            To sign this agreement, you must:
                            <br />
                            1. Print the <a rel="noopener noreferrer" target="_blank" href={`${baseUrl}/company/document/file/${agreementFile.id}`} download={agreementFile.fileName}>agreement</a>
                            <br />
                            2. Get it signed by your company representative {signee}, {signeePosition} provided in
                            the agreement.
                            <br />
                            3. Scan the signed document (all pages)
                            <br />
                            4. Upload the scanned file(s) below
                            <br />
                            5. Click “Submit at the bottom of this dialog
                            <br /><br />

                            Please note that the uploaded files must contain a valid signature
                            by {signee}, {signeePosition}.
                        </Trans>
                    </p>

                    <div className={styles.Separator} />

                    <div className={styles.SelectedFiles}>
                        {!!selectedFiles.length && selectedFiles.length > 0 && selectedFiles.map((file, i) => (
                            <div className={styles.SelectedFile} key={i}>
                                <div className={styles.SelectedFileName}>
                                    <DocIcon
                                        width={10}
                                        height={13}
                                        style={{
                                            marginRight: 10
                                        }}
                                    />

                                    {file.name}
                                </div>

                                <div className={styles.SelectedFileActions}>
                                    <Tippy
                                        content={(
                                            <div>
                                                {t("mc_a_as_selected_download_icon_tooltip")}
                                            </div>
                                        )}
                                    >
                                        <DownloadIcon
                                            width={11}
                                            height={14}
                                            onClick={() => downloadFile(file)}
                                        />
                                    </Tippy>

                                    <Tippy
                                        content={(
                                            <div>
                                                {t("mc_a_as_selected_remove_icon_tooltip")}
                                            </div>
                                        )}
                                    >
                                        <RemoveIcon
                                            width={8}
                                            height={8}
                                            onClick={() => removeFile(i)}
                                        />
                                    </Tippy>
                                </div>
                            </div>
                        ))}
                        {!selectedFiles.length && (
                            <div className={styles.NoDocs}>
                                {t("mc_a_as_no_documents")}...
                            </div>
                        )}
                    </div>

                    <Browse
                        onFilesSelected={(newFiles: File[]) => {
                            setSelectedFiles(selectedFiles.concat(...newFiles));
                        }}
                        buttonVariant={selectedFiles.length > 0 ? UserButtonVariant.WHITE : UserButtonVariant.PRIMARY}
                    />
                </div>
            )}

            {uploadedFiles && uploadedFiles.length > 0 && (
                <>
                    <h4>{t("mc_a_as_uploaded_files")}</h4>
                    <div className={styles.UploadedBox}>
                        {uploadedFiles.map((file, i) => (
                            <div className={styles.UploadedBoxItem}>
                                <p key={file.id}>{file.fileName}</p>
                                <div className={styles.Wrapper}>
                                    <Tippy
                                        content={(
                                            <div>
                                                {t("mc_a_as_selected_download_icon_tooltip")}
                                            </div>
                                        )}
                                    >
                                        <a rel="noopener noreferrer" target="_blank" href={`${baseUrl}/company/document/file/${file.id}`} download={file.fileName}>
                                            <DownloadIcon />
                                        </a>
                                    </Tippy>

                                    <Tippy
                                        content={(
                                            <div>
                                                {t("mc_a_as_selected_remove_icon_tooltip")}
                                            </div>
                                        )}
                                    >
                                        <DelIcon
                                            width={9}
                                            height={10}
                                            onClick={() => deleteFile(file.id)}
                                        />
                                    </Tippy>
                                </div>
                            </div>
                        ))}
                    </div>
                </>
            )}

            {Boolean(selectedFiles.length) && (
                <UserButton
                    text={t("mc_a_as_submit")}
                    variant={UserButtonVariant.PRIMARY}
                    buttonProps={{
                        type: "button",
                        onClick: () => uploadFiles(selectedFiles),
                        style: {
                            display: "flex",
                            marginTop: 30,
                            marginLeft: "auto",
                            width: 158
                        }
                    }}
                />
            )}

            {status === "SIGNATURE_NOT_VERIFIED" && (
                <UserButton
                    text={t("mc_a_as_cancel")}
                    variant={UserButtonVariant.PRIMARY}
                    buttonProps={{
                        type: "button",
                        onClick: cancelDocVerification,
                        style: {
                            display: "flex",
                            marginTop: 30,
                            marginLeft: "auto",
                            width: 158
                        }
                    }}
                />
            )}
        </div>
    );
}
