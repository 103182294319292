import Currency from "@hotelston_web_frontend_parent/constants/currency"
import getCurrencySymbol from "currency/getCurrencySymbol"

export function getNumberWithDecimalPoint(price: number, decimalPlaces?: number, noZeroPadding?: boolean): string {
    if (price % 1 === 0 && !noZeroPadding) {
        return `${price}${decimalPlaces && decimalPlaces !== 0 ? "." + "0".repeat(decimalPlaces) : ""}`;
    }

    return price?.toFixed(decimalPlaces === undefined ? 2 : decimalPlaces).replace(/\.0+$/, "");
}

export default function getPriceWithCurrency(price: number, currency: Currency): string {
    return getNumberWithDecimalPoint(price, 2).replace(/\.0+$/, "") + " " + currency;
}

export function getPriceWithCurrencySymbol(price: number, currency: Currency, decimalPlaces?: number): string {
    if (!price) {
        return getCurrencySymbol(currency) + "0" + (decimalPlaces ? "." + "0".repeat(decimalPlaces) : "");
    }

    return getCurrencySymbol(currency) + getNumberWithDecimalPoint(price, decimalPlaces === undefined ? 2 : decimalPlaces);
}