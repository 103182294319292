import React, {ReactElement} from "react"
import LoginNavBar from "../../Login/LoginNavBar/LoginNavBar"
import CookieConsentContainer from "../../../components/utils/CookieConsentContainer/CookieConsentContainer"
import PaymentFailure from "./PaymentFailure"

const PaymentFailureContainer = (): ReactElement => (
    <>
        <LoginNavBar />

        <PaymentFailure />

        <CookieConsentContainer />
    </>
)

export default PaymentFailureContainer;