import React, {Component, ReactElement} from "react";
import {WithTranslation, withTranslation} from "react-i18next";
import _ from "lodash";
import {AxiosError, AxiosResponse} from "axios";
import ModalWithOverlay from "../../common/Modal/ModalWithOverlay";
import styles from "./ErrorBoundary.module.scss";
import ActionIcon, {ActionIconType} from "../../common/ActionIcon/ActionIcon";
import {ReactComponent as ErrorIcon} from "../../../assets/icons/cross.svg";
import UserButton from "../../base/UserButton";
import {UserButtonVariant} from "../../base/UserButton/UserButton";
import Modal from "components/common/Modal";
import sendLogs from "api/loggerAPI";
import store from "redux/store/store.init";
import {FALLBACK_DATE_TIME_SECONDS_FORMAT} from "../Internationalization/formats";
import router from "views/router/router";
import createLuxonDate from "utils/date/createLuxonDate"
import axiosInstance from "api/axios/axiosInstance"
import globalAxiosController from "api/axios/globalAxiosController"

type Props = WithTranslation & {
    children: ReactElement;
};

type State = {
    error?: Error[];
    date?: string;
};

class AxiosErrorModal extends Component<Props, State> {
    private debug = false;

    private debugError = false;

    private allowedGETPaths = ["/app/nationality/", "/app/locale/", "/app/currency/", "/app/csrf", "/app/user/searches/recent", "/app/user/searches/saved", "/app/country/",
        "/app/company/", "/app/company/subagents", "/app/company/document/6889", "/app/booking/searchOptions", "/app/booking/5993147", "/app/destinations/searchTransferOrigin", "/app/destinations/searchTransferDestination",
        "/app/user/logout", "/app/destinations/search", "/app/hotel/details/"
    ];

    private allowedPOSTPaths = ["/app/booking/search", "/app/transfer/search", "/app/transfer/book/bookingOptions", "/app/transfer/book/validate", "/app/user/login", "/app/hotel/search", "/app/hotel/availability/cxl", "/app/hotel/book/bookingOptions", "/app/hotel/book/validate", "/app/logger/"
        // "/app/hotel/book/makeBooking"
    ];

    constructor(props: Props) {
        super(props);
        this.state = {
            error: undefined
        };
    }

    componentDidMount(): void {
        axiosInstance.interceptors.request.use((req) => {
            if (this.debug && req.url && (this.allowedGETPaths.includes(req.url) || req.url.startsWith("/destinations/")) && req.method?.toUpperCase() === "GET") {
                return req;
            }
            if (this.debug && req.url && this.allowedPOSTPaths.includes(req.url) && req.method?.toUpperCase() === "POST") {
                return req;
            }
            if (this.debug) {
                req.url += "x";
            }

            return req;
        });

        const onRejected = (error: AxiosError) => {
            if (error.config?.url === "/app/csrf" || error.config?.url?.startsWith("/app/company/vouchers/") || error.config?.url?.startsWith("/app/logger/") || error.config?.url?.startsWith("/app/spans/")) { //getVouchersByReference (404)
                return error;
            }

            if (error.config?.method?.toUpperCase() === "GET" && error.config.url === "/app/user/" && (error.response?.status === 401 || error.response?.status === 500)) { //login check
                return error;
            }

            if (error.config?.method?.toUpperCase() === "POST" && error.config.url === "/app/user/reset" && error.response?.status === 500) { // password reset no error modal
                return error
            }

            if (error.config?.url?.startsWith("/app/payment/token") && error.response?.status === 404) { // expired or invalid payment link token response
                return error
            }

            if (error.response?.status === 401) { //global unauthorized error disable, do graceful error ?
                return error;
            }

            if (error.config?.url === "/app/user/login" && error.response?.status === 403) { //response for pin login
                return error;
            }

            if (error.code === "ERR_CANCELED") {
                return error;
            }

            this.setState((prevState) => ({
                error: [...prevState.error || [], error],
                date: createLuxonDate().toFormat(FALLBACK_DATE_TIME_SECONDS_FORMAT)
            }));//console.log("updating state " + prevState.error);

            globalAxiosController.addRequest(sendLogs(JSON.stringify(error)))
                .then((data) => {})
                .catch((error: any) => {
                    // console.log(error);
                });
            return error;
        };
        const boundFuncRejected = onRejected.bind(this);

        const onFulfilled = (res: AxiosResponse) => {
            if (res && res.data && this.debugError) {
                res.data.errors = ["shit", "and shit"];
                res.data.success = false;
            }
            this.setState({error: undefined});
            return res;
        };
        const boundFuncFulfilled = onFulfilled.bind(this);

        axiosInstance.interceptors.response.use(boundFuncFulfilled, boundFuncRejected);
    }

    onClose = (): void => {
        router.navigate("/", {replace: true});
    };

    onCloseWithFade = (): void => {
        this.setState({
            error: undefined
        });

        this.onClose();
    };

    render(): ReactElement {
        const {error, date} = this.state;
        const {
            children,
            t
        } = this.props;
        const trackingId = store.getState().zipkin.tracer.id.traceId;

        return (
            <>
                {!_.isEmpty(error) && (
                    <>
                        <Modal>
                            <ModalWithOverlay>
                                <div className={styles.Root}>
                                    <div
                                        onClick={this.onCloseWithFade}
                                        className={styles.CloseBtn}
                                    >
                                        <ActionIcon type={ActionIconType.CLOSE} />
                                    </div>

                                    <div className={styles.Header}>
                                        <ErrorIcon />
                                        <h2 className={styles.Title}>{t("e_b_something_went_wrong")}</h2>
                                        <div className={styles.Date}>{date}</div>
                                        <div className="mb-3">{"trackingId: " + trackingId }</div>
                                        {error?.map((e, i) => (
                                            <div key={i}>
                                                <div className="mb-3">{e.message}</div>
                                                <div className="mb-3">{(e as any).config?.method} {(e as any).config?.url}</div>
                                                {/*<div><textarea disabled={true} value={JSON.stringify(e)} /></div>*/}
                                            </div>
                                        ))}
                                    </div>

                                    <UserButton
                                        text={t("e_b_go_back")}
                                        variant={UserButtonVariant.SECONDARY}
                                        buttonProps={{
                                            onClick: this.onCloseWithFade,
                                            type: "button",
                                            style: {
                                                margin: "auto",
                                                height: "40px",
                                                width: "160px"
                                            }
                                        }}
                                    />
                                </div>
                            </ModalWithOverlay>
                        </Modal>
                    </>
                )}
                {children}
            </>
        );
    }
}

export default withTranslation()(AxiosErrorModal);