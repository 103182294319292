import React, {ReactElement, useState} from "react";
import cx from "classnames";
import styles from "./PasswordInput.module.scss";
import Input from "../../base/Input";
import {ReactComponent as EyeIcon} from "../../../assets/icons/eye.svg";
import {ReactComponent as EyeOffIcon} from "../../../assets/icons/eye_off.svg";
import {InputProps} from "../../base/Input/TextInput/Input";

export type PasswordInputProps = InputProps;

const PasswordInput = (props: PasswordInputProps): ReactElement => {
    const [passwordVisible, setPasswordVisible] = useState(false);

    return (
        <div className={styles.Root}>
            <Input
                {...props}
                inputProps={{
                    ...props.inputProps,
                    type: passwordVisible ? "text" : "password"
                }}
            />

            <div
                className={cx(styles.EyeContainer, passwordVisible && styles.EyeContainerActive)}
                onClick={() => setPasswordVisible(!passwordVisible)}
            >
                {!passwordVisible && <EyeOffIcon />}
                {passwordVisible && <EyeIcon />}
            </div>
        </div>
    );
};

export default React.memo(PasswordInput);