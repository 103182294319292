import React, {ReactElement} from "react";
import {connect} from "react-redux";
import cx from "classnames";
import {matchPath, NavLink} from "react-router-dom";
import {Location} from "history";
import _ from "lodash";
import {RootState} from "../../../../../redux/store/store.init";
import styles from "./Breadcrumb.module.scss";

export type BreadcrumbChild = {
    pathName: string;
    child: ReactElement | string;
};

export type BreadcrumbProps = {
    location: Location;
    lastLocation: {pathname: string, hash: string, search: string};
    previousLocationLabel: string;
    expandedView?: boolean;
    crumbs: BreadcrumbChild[];
};

const Breadcrumb = ({
    location,
    lastLocation,
    previousLocationLabel,
    crumbs,
    expandedView
}: BreadcrumbProps): ReactElement => {
    const nodes = crumbs.filter((child) => matchPath(child.pathName, location.pathname))
        .map((child) => child.child);

    if (nodes.length === 0) {
        return (
            <></>
        );
    }

    return (
        <div className={cx(styles.Root, expandedView && styles.LefterOpen)}>
            <NavLink to={lastLocation}>{previousLocationLabel}</NavLink>

            {nodes.map((child, i) => (
                <span key={_.uniqueId("breadcrumbNode_")} className={cx(i === nodes.length - 1 && styles.CrumbActive)}>
                    <span className={styles.Separator}>/</span>

                    {child}
                </span>
            ))}
        </div>
    );
};

const mapStateToProps = (state: RootState) => ({
    expandedView: (state.auth.modalView && state.auth.expandedModalView) || (!state.auth.modalView && state.auth.expandedView)
});

export default connect(mapStateToProps, undefined)(Breadcrumb);