import React, {ReactElement, useMemo} from "react"
import {InfoBox} from "@react-google-maps/api";
import styles from "./POIMarkers.module.scss";
import {GooglePlace} from "../../../../../../../proptypes/PropTypeObjects";

export type POIMarkersProps = {
    places: GooglePlace[],
    colorPallete: string[],
    indices: string[]
};

export default function POIMarkers({
    places,
    colorPallete,
    indices
}: POIMarkersProps): ReactElement {
    const memoizedPlaces = useMemo(() => places.map((p: any, i: any) => (
        <InfoBox
            key={`${p.placeId}-${i}`}
            position={p.location}
            options={{
                disableAutoPan: true,
                closeBoxURL: "",
                boxClass: styles.root,
                boxStyle: {backgroundColor: colorPallete[i]} as CSSStyleDeclaration
            }}
        >
            <div>{indices[i]}</div>
        </InfoBox>
    )), [colorPallete, indices, places]);

    return (
        <>
            {memoizedPlaces}
        </>
    );
}
