import React, {ReactElement} from "react";
import cx from "classnames";
import {useTranslation} from "react-i18next";
import styles from "./BookingRoomSidebar.module.scss";
import titles from "../../../assets/json/titles.json";
import {getPriceWithCurrencySymbol} from "@hotelston_web_frontend_utils/currency/getPriceWithCurrency";
import Currency from "@hotelston_web_frontend_parent/constants/currency";

function getChildrenInfo(children: any, t: any) {
    return children.map((child: any, i: any) => (
        <React.Fragment key={i}>
            {child.firstname} {child.lastname}
            <span className={styles.age} key={i}>
                {" "}
                {t("brs_brs_years_old", {count: i, age: child.age})}
            </span>
        </React.Fragment>
    ));
}

function getAdultsInfo(adults: any, t: any) {
    return adults.map((adult: any, i: any) => (

        <React.Fragment key={i}>
            <span className="capitalize">{t(titles.titles.find((e) => e.value === adult.title)?.label)}</span>{" "}
            {adult.firstname} {adult.lastname}
            {adults.length > i + 1 ? ", " : ""}
        </React.Fragment>
    ));
}

function BookingRoomSidebar({
    roomNo,
    price,
    currencyName = Currency.EUR,
    exactRoomType,
    roomType,
    boardType,
    childrenData = [],
    adultsData = [],
    displayChildren = false,
    simplified = false
}: any): ReactElement {
    const {t} = useTranslation();
    return (
        <div className={cx(styles.root, simplified && styles.simplified)}>
            {roomNo !== undefined && (
                <span className={styles.number}>
                    {t("brs_brs_room")} {roomNo + 1}
                </span>
            )}
            {price && (
                <span className={styles.price}>
                    {getPriceWithCurrencySymbol(price, currencyName)}
                </span>
            )}
            {exactRoomType && <p className={styles.type}>{exactRoomType}</p>}
            {/*{roomType && (*/}
            {/*    <p>*/}
            {/*        {t("brs_brs_room_type")}: {roomType}*/}
            {/*    </p>*/}
            {/*)}*/}
            {/*{exactRoomType && (*/}
            {/*    <p>*/}
            {/*        {t("brs_brs_exact_room_type")}: {exactRoomType}*/}
            {/*    </p>*/}
            {/*)}*/}
            {boardType && (
                <p>
                    {t("brs_brs_board_type")}: {boardType}
                </p>
            )}
            {adultsData && adultsData.length > 0 && (
                <div className={styles.peopleInfo}>
                    <div className={styles.label}>{t("brs_brs_adults")}: </div>
                    <div className={styles.values}>
                        {getAdultsInfo(adultsData, t)}
                    </div>
                </div>
            )}
            {displayChildren && (
                <>
                    {childrenData && childrenData.length > 0 && (
                        <div className={styles.peopleInfo}>
                            <div className={styles.label}>{t("brs_brs_children")}:</div>
                            <div className={styles.values}>
                                {getChildrenInfo(childrenData, t)}
                            </div>
                        </div>
                    )}
                </>
            )}
        </div>
    );
}

export default BookingRoomSidebar;
