import React, {
    PureComponent, ReactElement, ReactNode
} from "react";
import "./CustomCheckBox.scss";
import _ from "lodash";
import cx from "classnames";

type Props = {
    label: string | ReactElement;
    children?: ReactNode;
    inputProps?: React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>;
    errorMessage?: string;
    className?: string;
};

type State = {
    valid?: boolean;
};

class CustomCheckBox extends PureComponent <Props, State> {
    private inputField: React.RefObject<HTMLInputElement>;

    private inputId = _.uniqueId("CheckBox_");

    constructor(props: Props) {
        super(props);

        this.state = {
            valid: true
        };

        this.inputField = React.createRef();
        this.updateValue = this.updateValue.bind(this);
        this.formSubmitEventHandler = this.formSubmitEventHandler.bind(this);
        window.addEventListener("submit", this.formSubmitEventHandler);
    }

    onClick(): void {
        if (!this.inputField.current || !this.inputField.current.onchange) {
            return;
        }

        this.inputField.current.onchange(new Event("change", {bubbles: true}));
    }

    formSubmitEventHandler(): void {
        this.updateValidity();
    }

    updateValue(e: React.ChangeEvent<HTMLInputElement>): void {
        const {
            inputProps: {
                onChange
            } = {}
        } = this.props;

        if (onChange) {
            onChange(e);
        }
        this.updateValidity();
    }

    updateValidity(): void {
        if (!this.inputField.current) {
            return;
        }
        const correct = !this.inputField.current.required || (this.inputField.current.required && this.inputField.current.checked);
        if (this.props.errorMessage) {
            this.inputField.current.setCustomValidity(correct ? "" : this.props.errorMessage);
        }
        //console.log(this.inputField.current.validity);
        //this.inputField.current.reportValidity();
        this.setState({
            valid: correct

        });
    }

    render(): ReactElement {
        const {
            children,
            inputProps,
            label,
            className
        } = this.props;
        const {
            valid
        } = this.state;

        let childrenWithProps = null;
        if (children) {
            // @ts-expect-error  FIXME:
            childrenWithProps = React.Children.map(children, (child, index) => React.cloneElement(child, {
                id: this.inputId,
                ref: this.inputField,
                onChange: this.updateValue
            }));
        }

        return (
            <>
                <div className={cx("CustomCheckBox", className)} onClick={() => this.onClick()}>
                    {
                        childrenWithProps ||
                        (
                            <input
                                id={this.inputId}
                                ref={this.inputField}
                                {...inputProps}
                                className="checkbox"
                                type="checkbox"
                            />
                        )
                    }
                    <div className="custom-checkbox" onClick={() => this.onClick()} />
                    <label className="checkboxLabel" htmlFor={this.inputId}>
                        {label}
                    </label>
                </div>

                {!valid && (
                    <div className="input-error" style={{width: "100%"}}>
                        {this.inputField.current?.validationMessage}
                    </div>
                )}
            </>
        );
    }
}

export default CustomCheckBox;
