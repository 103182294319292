import React, {
    useCallback, useMemo, useReducer, useState
} from "react";
import {shallowEqual} from "react-redux";
import PinnedOffers from "components/common/PinnedOffers";
import {
    addPinnedOffer, removePinnedOffer, resetPinnedOffers, setSlide
} from "redux/actions/pinnedOffers.actions";
import {useTranslation} from "react-i18next";
import {getPinnedOffersEmail, getPinnedOffersPdf} from "api/hotelAPI";
import _ from "lodash";
import getDisplayName from "./getDisplayName";
import {
    EmailHotelPinnedOffers,
    HotelOfferPropTypes,
    HotelPinnedOffers,
    PinnedOffer
} from "../../proptypes/PropTypeObjects";
import {useAppDispatch, useAppSelector} from "../../redux/hooks";
import IAppActions from "../../redux/store/store.actions";
import {globalAxiosController} from "api/axios/globalAxiosController";

type UsePinnedOffers = {
    addPinnedOffer: (pinnedOffer: PinnedOffer) => void,
    removePinnedOffer: (pinnedOffer: PinnedOffer) => void,
    resetPinnedOffers: () => void,
    toggleSlideIn: () => IAppActions,
    pinnedOffers: PinnedOffer[],
    slide: boolean,
    slideDuration: number,
    pinnedHotels: HotelOfferPropTypes[];
};

export function usePinnedOffers(): UsePinnedOffers {
    const dispatch = useAppDispatch();

    const {
        pinnedOffers,
        pinnedHotels,
        slideDuration,
        slide
    } = useAppSelector((state) => state.pinnedOffers, shallowEqual);

    return {
        addPinnedOffer: (pinnedOffer: PinnedOffer) => dispatch(addPinnedOffer(pinnedOffer)),

        removePinnedOffer: (pinnedOffer: PinnedOffer) => {
            if (pinnedOffers.length === 1) {
                dispatch(setSlide(false));
            }
            setTimeout(() => dispatch(removePinnedOffer(pinnedOffer)), pinnedOffers.length > 1 ? 0 : slideDuration);
        },

        resetPinnedOffers: () => {
            dispatch(setSlide(false));
            setTimeout(() => dispatch(resetPinnedOffers()), slideDuration);
        },

        toggleSlideIn: () => dispatch(setSlide(true)),

        pinnedOffers,
        slide,
        slideDuration,
        pinnedHotels
    };
}

type UseOffersRequest = {
    requestBody: HotelPinnedOffers,
    senderEmail?: string,
    subject: string
    destination: string
};

export function useOffersRequest(): UseOffersRequest {
    const {t} = useTranslation();

    const {pinnedHotels, pinnedOffers} = useAppSelector((state) => state.pinnedOffers, shallowEqual);

    const hotelOffers = useMemo(() => pinnedHotels.map((pinnedHotel: HotelOfferPropTypes) => ({
        hotelId: pinnedHotel.hotel.id,
        roomOffers: pinnedOffers
            .filter(({hotelId}) => pinnedHotel.hotel.id === hotelId)
            .map((pinnedOffer) => pinnedHotel.roomOffers[pinnedOffer.roomNo].filter(({roomId}) => pinnedOffer.roomId === roomId))
    })), [pinnedHotels, pinnedOffers]);

    const criteria = useAppSelector((state) => state.hotelSearch.formData, shallowEqual);
    const currency = useAppSelector((state) => state.currency.currentCurrency, shallowEqual);
    const companyMarkup = useAppSelector((state) => state.auth.userData?.companyMarkups[state.auth.currentCompanyMarkupIndex || 0].hotelAmount, shallowEqual) || 1;
    const senderEmail = useAppSelector((state) => state.auth.userData?.companyUser.email, shallowEqual);
    const destinationInput = useAppSelector((state) => state.hotelSearch.destinationInput, shallowEqual);

    const subject = t("util_wpo_default_subject_hotel", {
        city: pinnedHotels[0].hotel.address
            ? pinnedHotels[0].hotel.address.city
            : undefined
    });

    const destination = pinnedHotels.length > 1
        ? destinationInput
        : (pinnedHotels[0].hotel.name + ((pinnedHotels[0].hotel.address && pinnedHotels[0].hotel.address.city) ? (", " + pinnedHotels[0].hotel.address?.city) : undefined));

    return {
        requestBody: {
            companyMarkup,
            criteria,
            currency,
            hotelOffers
        },
        senderEmail,
        subject,
        destination
    };
}

const actionState = {
    requesting: false,
    errors: undefined
} as ActionState;

type ActionState = {
    requesting: boolean;
    errors?: string[];
};

type ActionProps =
    | { type: "REQUEST" }
    | { type: "SUCCESS" }
    | { type: "FAILURE", errors: string[]; }
    ;

const actionReducer = (state: ActionState, action: ActionProps): ActionState => {
    switch (action.type) {
    case "REQUEST":
        return {
            ...state,
            errors: undefined,
            requesting: true
        };
    case "SUCCESS":
        return {
            ...state,
            requesting: false
        };
    case "FAILURE":
        return {
            ...state,
            requesting: false,
            errors: action.errors
        };
    default:
        return state;
    }
};

type UsePinnedOffersSendEmailAction = ActionState & {
    sendEmail: (offers: EmailHotelPinnedOffers, withPrice: boolean) => void
};

export function usePinnedOffersSendEmailAction(onSuccess: () => void): UsePinnedOffersSendEmailAction {
    const [state, dispatch] = useReducer(actionReducer, actionState);

    const locale = useAppSelector((state) => state.locale.currentLocale);
    const {t} = useTranslation();

    const sendEmail = useCallback((offers: EmailHotelPinnedOffers, withPrice: boolean) => {
        dispatch({type: "REQUEST"});
        globalAxiosController.addRequest(getPinnedOffersEmail(offers, locale, withPrice))
            .then((success) => {
                if (success) {
                    onSuccess();
                    dispatch({type: "SUCCESS"});
                } else {
                    dispatch({
                        type: "FAILURE",
                        errors: [t("util_wpo_something_went_wrong")]
                    });
                }
            })
            .catch((error: unknown) => {
                dispatch({
                    type: "FAILURE",
                    errors: [(error as string) || t("util_wpo_something_went_wrong")]
                });
            });
    }, [locale, onSuccess, t]);

    return {
        ...state,
        sendEmail
    };
}

export function usePinnedOffersGetPdfAction(onSuccess: (pdfFile: Blob) => void): (offers: HotelPinnedOffers, withPrice: boolean) => Promise<void> {
    const [state, dispatch] = useReducer(actionReducer, actionState);

    const locale = useAppSelector((state) => state.locale.currentLocale);

    const {t} = useTranslation();

    const getPdf = useCallback((offers: HotelPinnedOffers, withPrice: boolean) => {
        dispatch({type: "REQUEST"});
        return globalAxiosController.addRequest(getPinnedOffersPdf(offers, locale, withPrice))
            .then((pdfFile) => {
                onSuccess(pdfFile);
                dispatch({type: "SUCCESS"});
            })
            .catch((error: unknown) => {
                // console.log(error);
                dispatch({
                    type: "FAILURE",
                    errors: [t("util_wpo_something_went_wrong")]
                });
            });
    }, [locale, onSuccess, t]);

    return getPdf;
}

export type PinnedOffersContextType = {
    formElementToObserve?: HTMLFormElement | null;
    setFormElementToObserve?: (el: HTMLFormElement) => void;
};

export const PinnedOffersContext = React.createContext<PinnedOffersContextType>({});

function withPinnedOffers(WrappedComponent: React.ComponentType<any>, config = {}): React.FC<any> {
    const HOComponent = ({...rest}) => {
        const {
            pinnedOffers,
            resetPinnedOffers,
            toggleSlideIn,
            slide,
            slideDuration
        } = usePinnedOffers();

        const [formElementToObserve, setFormElementToObserve] = useState<HTMLFormElement | null>(null);

        if (pinnedOffers.length > 0) {
            _.set(rest, "style", {...rest.style, height: "calc(100% + 60px)"});
        }

        return (
            <PinnedOffersContext.Provider
                value={{
                    formElementToObserve,
                    setFormElementToObserve
                }}
            >
                {pinnedOffers.length > 0 && (
                    <PinnedOffers
                        innerModal
                        slide={slide}
                        slideDuration={slideDuration}
                        pinnedOffers={pinnedOffers}
                        resetPinnedOffers={resetPinnedOffers}
                        toggleSlideIn={toggleSlideIn}
                    />
                )}
                <WrappedComponent {...rest as any} />
            </PinnedOffersContext.Provider>
        );
    };
    HOComponent.displayName = `withPinnedOffers(${getDisplayName(HOComponent)})`;
    return HOComponent;
}

export default withPinnedOffers;
