import React, {
    FormEvent,
    ReactElement, useCallback, useEffect, useMemo, useState
} from "react"
import {useTranslation} from "react-i18next";
import cx from "classnames";
import {connect} from "react-redux";
import styles from "./ClientRequiredCheckInInformation.module.scss";
import BasicDropdown from "../../../../base/Input/BasicDropdown/BasicDropdown";
import {TelephoneInput} from "../../../../base/Input/TextInput/TelephoneInput/TelephoneInput";
import TimeCustomSelect from "../../../../base/Input/CustomReactSelect/TimeCustomSelect";
import {RootState} from "../../../../../redux/store/store.init";
import {
    ClientRequiredCheckInInformationPropTypes,
    WebHotelBookingDetails,
    WebRoomDetails
} from "../../../../../proptypes/PropTypeObjects";
import UserButton from "../../../../base/UserButton";
import {UserButtonVariant} from "../../../../base/UserButton/UserButton";
import {updateCheckInRemarks} from "../../../../../api/bookingDetailsAPI";
import {convertToServerTime, dateWithSetTime} from "../../../../../utils/dateUtils"
import {FALLBACK_DATE_TIME_FORMAT} from "../../../../utils/Internationalization/formats"
import createLuxonDate from "../../../../../utils/date/createLuxonDate"
import {globalAxiosController} from "api/axios/globalAxiosController";

export type ClientRequiredCheckInInformationProps = {
    id: number;
    rooms?: WebRoomDetails[];
    checkIn?: number;
    editable?: boolean;

    clientRequiredCheckInInformation?: ClientRequiredCheckInInformationPropTypes;
};

function ClientRequiredCheckInInformation({
    id,
    rooms,
    checkIn,

    clientRequiredCheckInInformation,
    editable = true
}: ClientRequiredCheckInInformationProps): ReactElement {
    const {t} = useTranslation();

    const [editing, setEditing] = useState(editable && !clientRequiredCheckInInformation);
    const [localFormData, setLocalFormData] = useState<ClientRequiredCheckInInformationPropTypes>();

    const handleInput = useCallback((name: string, value: unknown) => {
        setLocalFormData({
            ...localFormData,
            [name]: value
        });
    }, [localFormData, setLocalFormData]);

    const buildDateCallback = useCallback((newVal?: string) => {
        if (!localFormData && !newVal) {
            return undefined;
        }

        let tweakedVal = newVal;
        if (typeof newVal === "number") {
            tweakedVal = createLuxonDate(convertToServerTime(newVal)).toFormat("HH:mm");
        }

        if (!tweakedVal) {
            tweakedVal = `${createLuxonDate(localFormData?.checkInTime).hour}:${createLuxonDate(localFormData?.checkInTime).minute}`;
        }

        const hours = tweakedVal.toString().split(":")[0] || "";
        const minutes = tweakedVal.toString().split(":")[1] || "";

        const HH = hours.length > 1 ? hours : `0${hours}`;
        const mm = minutes.length > 1 ? minutes : `0${minutes}`;

        return dateWithSetTime(
            createLuxonDate(checkIn),
            +HH,
            +mm
        ).toFormat(FALLBACK_DATE_TIME_FORMAT);
    }, [checkIn, localFormData]);

    useEffect(() => {
        if (!clientRequiredCheckInInformation || localFormData) {
            return;
        }

        setLocalFormData({
            ...clientRequiredCheckInInformation,
            checkInTime: createLuxonDate(clientRequiredCheckInInformation.checkInTime).toISO()
        });
    }, [buildDateCallback, clientRequiredCheckInInformation, localFormData]);

    const toggleEdit = useCallback(() => {
        setEditing(!editing);
    }, [editing]);

    const submitCallback = useCallback((e: FormEvent) => {
        e.stopPropagation();
        e.preventDefault();

        if (!localFormData || !editing) {
            return;
        }

        globalAxiosController.addRequest(
            updateCheckInRemarks(
                id,
                {
                    ...localFormData,
                    checkInTime: buildDateCallback()
                }
            )
        ).then(() => {
            toggleEdit();
        }).catch(() => {
            if (clientRequiredCheckInInformation) {
                setLocalFormData({
                    ...clientRequiredCheckInInformation,
                    checkInTime: buildDateCallback(clientRequiredCheckInInformation.checkInTime)
                });
            }

            toggleEdit();
        });
    }, [buildDateCallback, clientRequiredCheckInInformation, editing, id, localFormData, toggleEdit]);

    const leadGuestOptions = useMemo(() => {
        if (!rooms) {
            return [];
        }

        return rooms.flatMap((room) => room)
            .flatMap((room) => room.adults)
            .filter((passenger) => passenger.title && passenger.firstname && passenger.lastname)
            .map((adult) => ({
                label: adult.title + " " + adult.firstname + " " + adult.lastname,
                value: adult.title + " " + adult.firstname + " " + adult.lastname
            }));
    }, [rooms]);

    return (
        <form className={styles.Root} onSubmit={submitCallback}>
            <h4>{t("crcii_title")}</h4>

            <p>{t("crcii_not_provided_info")}</p>

            {!editing && (
                <dl className={styles.RequiredCheckInInformationReadOnly}>
                    <dt>{t("crcii_select_lead_guest")}</dt>
                    <dd>{localFormData?.leadPassengerName || "-"}</dd>

                    <dt>{t("crcii_contact_phone")}</dt>
                    <dd>{((localFormData?.phoneCountryCode || "") + (localFormData?.leadPassengerPhoneNumber || "") || "-")}</dd>

                    <dt>{t("crcii_arrival_time")}</dt>
                    <dd>{localFormData?.checkInTime ? createLuxonDate(localFormData?.checkInTime).toFormat("HH:mm") : "-"}</dd>
                </dl>
            )}

            {editing && (
                <div
                    className={cx(styles.RequiredCheckInInformation, !clientRequiredCheckInInformation && styles.EnterLater)}
                >
                    <div className={styles.LeadGuestDropdownContainer}>
                        <label>{t("crcii_select_lead_guest")}</label>

                        <BasicDropdown
                            className={styles.LeadGuestDropdown}
                            required
                            dropDownOptions={leadGuestOptions}
                            onDropdownChange={(value: string) => handleInput("leadPassengerName", value)}
                            dropDownValue={localFormData?.leadPassengerName || ""}
                        />
                    </div>

                    <div className={styles.PlannedArrivalTime}>
                        <label>{t("crcii_arrival_time")}</label>

                        <TimeCustomSelect
                            className={styles.ArrivalTimeSelect}
                            onChange={(val: string) => {
                                handleInput(
                                    "checkInTime",
                                    buildDateCallback(val)
                                );
                            }}
                            hoursMinutes={localFormData?.checkInTime ? createLuxonDate(localFormData?.checkInTime).toFormat("HH:mm") : undefined}
                            required
                        />
                    </div>

                    <div className={styles.LeadGuestPhoneContainer}>
                        <label>{t("crcii_contact_phone")}</label>

                        <TelephoneInput
                            className={styles.LeadGuestPhone}
                            countryCode={localFormData?.phoneCountryCode}
                            phone={localFormData?.leadPassengerPhoneNumber || ""}
                            onCountryCodeChange={(newVal) => handleInput("phoneCountryCode", newVal)}
                            onPhoneChange={(newVal) => handleInput("leadPassengerPhoneNumber", newVal)}
                            required
                        />
                    </div>
                </div>
            )}

            <p dangerouslySetInnerHTML={{__html: t("crcii_enter_later_warning")}}/>

            <div className={styles.ActionsContainer}>
                {!editing && editable && (
                    <UserButton
                        text={t("crcii_edit")}
                        buttonProps={{
                            onClick: toggleEdit
                        }}
                        variant={UserButtonVariant.SECONDARY}
                    />
                )}

                {editing && (
                    <>
                        <UserButton
                            text={t("crcii_cancel")}
                            buttonProps={{
                                onClick: () => {
                                    if (clientRequiredCheckInInformation) {
                                        setLocalFormData({
                                            ...clientRequiredCheckInInformation,
                                            checkInTime: buildDateCallback(clientRequiredCheckInInformation.checkInTime)
                                        });
                                    } else {
                                        setLocalFormData(undefined);
                                    }

                                    toggleEdit();
                                }
                            }}
                            variant={UserButtonVariant.SECONDARY}
                        />

                        <UserButton
                            text={t("crcii_confirm")}
                            buttonProps={{
                                type: "submit"
                            }}
                            variant={UserButtonVariant.PRIMARY}
                        />
                    </>
                )}
            </div>
        </form>
    );
}

const mapStateToProps = ({
    bookingDetails
}: RootState) => ({
    rooms: (bookingDetails.data as WebHotelBookingDetails)?.roomDetails,
    checkIn: bookingDetails.data?.serviceStartDate,

    clientRequiredCheckInInformation: (bookingDetails.data as WebHotelBookingDetails)?.clientRequiredCheckInInformation
});

export default connect(mapStateToProps, undefined)(ClientRequiredCheckInInformation);