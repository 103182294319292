const isElementInViewport = (element: Element | null): boolean => {
    if (!element) {
        return false;
    }

    const rect = element.getBoundingClientRect();
    const windowHeight = (window.innerHeight || document.documentElement.clientHeight);

    return !(
        Math.floor(100 - (((rect.top >= 0 ? 0 : rect.top) / +-rect.height) * 100)) < 1 ||
        Math.floor(100 - ((rect.bottom - windowHeight) / rect.height) * 100) < 1
    );
};

export default isElementInViewport;