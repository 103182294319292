import React, {useCallback} from "react";
import {setSendMessage} from "redux/actions/webSocket.actions";
import getDisplayName from "./getDisplayName";
import {Notifications, NotificationsRequest} from "../../proptypes/PropTypeObjects";
import {useAppDispatch, useAppSelector} from "../../redux/hooks";

export function useNotifications(): WithNotifications {
    const dispatch = useAppDispatch();

    const notificationsData = useAppSelector((state) => state.webSocket.notificationsData);

    const setAsRead = useCallback((id: string) => dispatch(setSendMessage({
        topic: "/app/notification/read",
        msg: id
    })), [dispatch]);

    const loadNotifications = useCallback(({from, to}: any) => dispatch(setSendMessage({
        topic: "/app/notifications/load",
        msg: JSON.stringify({from, to})
    })), [dispatch]);

    const setAsReadAll = useCallback(() => dispatch(setSendMessage({topic: "/app/notification/read-all"})), [dispatch]);

    return {
        setAsRead,
        setAsReadAll,
        loadNotifications,
        notificationsData
    };
}

export function withNotifications<P>(WrappedComponent: React.ComponentType<P>): React.FC< Omit<P, keyof WithNotifications>> {
    const HOComponent = (props: any) => {
        const {
            setAsRead,
            notificationsData,
            setAsReadAll,
            loadNotifications
        } = useNotifications();
        return (
            <WrappedComponent
                {...props}
                setAsRead={setAsRead}
                setAsReadAll={setAsReadAll}
                loadNotifications={loadNotifications}
                notificationsData={notificationsData}
            />
        );
    };
    HOComponent.displayName = `withNotifications(${getDisplayName(HOComponent)})`;
    return HOComponent;
}

export type WithNotifications = {
    setAsRead(id: string): void;
    setAsReadAll(): void;
    loadNotifications(fromTo: NotificationsRequest): void;
    notificationsData?: Notifications;
};