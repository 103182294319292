import React, {
    ReactElement, useCallback, useReducer, useState
} from "react";
import Input from "components/base/Input";
import ConfirmationModal from "components/common/ConfirmationModal";
import {ReactComponent as EditIcon} from "assets/icons/edit.svg";
import cx from "classnames";
import {useUpdateSubagentAction} from "components/utils/useSubagentsData";
import SuccessModal from "components/common/ConfirmationModal/SuccessModal";
import {useTranslation} from "react-i18next";
import styles from "./EditSubagentDetails.module.scss";
import {EMAIL_PATTERN, PHONE_PATTERN, validateMarkup} from "../../../utils/validateForm";
import {CompanyListItem} from "../../../proptypes/PropTypeObjects";
import Tippy from "components/common/Tippy/Tippy";

type ActionProps =
    | {type: "HANDLE_INPUT", name: string, value: string}
    | {type: "HANDLE_COUNTRY", country: string, countryId: number}
    ;

function reducer(state: CompanyListItem, action: ActionProps): CompanyListItem {
    switch (action.type) {
    case "HANDLE_INPUT":
        return {
            ...state,
            [action.name]: action.value
        };
    case "HANDLE_COUNTRY":
        return {
            ...state,
            countryId: action.countryId
        };

    default:
        return state;
    }
}

type EditSubagentDetailsModalProps = {
    onConfirm: (subagent: CompanyListItem) => void;
    onDecline: () => void;
    subagent: CompanyListItem;
    errors?: string[];
    spinner: boolean;
};

function EditSubagentDetailsModal({
    onConfirm,
    onDecline,
    subagent,
    errors,
    spinner
}: EditSubagentDetailsModalProps) {
    const {t} = useTranslation();
    const [localFormData, dispatch] = useReducer(reducer, subagent);

    const handleInput = useCallback(
        (event: {target: {name: string, type: string, value: any}}) => {
            dispatch({
                type: "HANDLE_INPUT",
                name: event.target.name,
                value:
                    event.target.type === "number" ? (event.target.value ? Number(event.target.value) : event.target.value) : event.target.value
            })
        },
        []
    );

    const handleCountry = useCallback(
        (value: any, label: any) => dispatch({
            type: "HANDLE_COUNTRY",
            country: label,
            countryId: value
        }),
        []
    );

    const content = (
        <div className={styles.content}>
            <Input
                fullHeight
                variants={{labelPosition: "outlined"}}
                inputProps={{
                    type: "text",
                    name: "companyName",
                    required: true,
                    placeholder: t("sa_esd_company_name"),
                    onChange: handleInput,
                    value: localFormData.companyName
                }}
            />

            <Input
                fullHeight
                variants={{labelPosition: "outlined"}}
                validator={e => validateMarkup(e)}
                inputProps={{
                    name: "superAgentMarkup",
                    placeholder: t("sa_esd_markup"),
                    required: true,
                    onChange: handleInput,
                    value: localFormData.superAgentMarkup || "",
                }}
            />
            {/*<CountriesDropdown*/}
            {/*    required={true}*/}
            {/*    currentCountryName={localFormData.address.country}*/}
            {/*    onCountryChange={handleCountry}*/}
            {/*    onCountryInputChange={handleAddress}*/}
            {/*    countryInputName="country"*/}
            {/*/>*/}

            <Input
                fullHeight
                variants={{labelPosition: "outlined"}}
                className="mb-20"
                inputProps={{
                    type: "email",
                    name: "email",
                    placeholder: t("sa_esd_company_email"),
                    required: true,
                    pattern: EMAIL_PATTERN,
                    onChange: handleInput,
                    value: localFormData.email
                }}
            />
            <Input
                className="mb-60"
                fullHeight
                variants={{labelPosition: "outlined"}}
                inputProps={{
                    type: "tel",
                    name: "phone",
                    placeholder: t("sa_esd_company_phone_number"),
                    required: true,
                    pattern: PHONE_PATTERN,
                    onChange: handleInput,
                    value: localFormData.phone
                }}
            />

        </div>
    );

    return (
        <ConfirmationModal
            onConfirm={() => onConfirm(localFormData)}
            onDecline={onDecline}
            config={{
                variants: {
                    type: "normal"
                },
                errors,
                spinner,
                title: t("sa_esd_edit_subagent_details"),
                //customHeader,
                content,
                isForm: true,
                validateForm: true,
                confirmButtonText: t("sa_esd_save_changes"),
                declineButtonText: t("sa_esd_cancel")
            }}
        />
    );
}
type Props = {
    subagent: CompanyListItem;
    className?: string;
    updateSubagent: (subagent: CompanyListItem) => void;
};

function EditSubagentDetails({
    subagent,
    className,
    updateSubagent
}: Props): ReactElement {
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [successModal, setSuccessModal] = useState(false);

    const onSuccess = (subagent: CompanyListItem) => {
        setModalIsOpen(false);
        setSuccessModal(true);
        updateSubagent(subagent);
    };

    const {
        requesting,
        errors,
        updateSubagent: updateSubagentAction,
        clearState
    } = useUpdateSubagentAction(onSuccess);

    const {t} = useTranslation();

    return (
        <Tippy
            content={(
                <div className="d-flex">{t("sa_sl_edit")}</div>
            )}
        >
            <div className={styles.wrapper}>
                {modalIsOpen && (
                    <EditSubagentDetailsModal
                        subagent={subagent}
                        onConfirm={(subagentDetails: CompanyListItem) => {
                            updateSubagentAction(subagentDetails);
                        }}
                        onDecline={() => {
                            setModalIsOpen(false);
                        }}
                        errors={errors}
                        spinner={requesting}
                    />
                )}
                {successModal && (
                    <SuccessModal
                        onClose={() => setSuccessModal(false)}
                        successMessage={t("sa_esd_success_message_updated_subagent")}
                    />
                )}
                <div
                    className={cx(styles.root, className)}
                    onClick={() => {
                        clearState();
                        setModalIsOpen(true);
                    }}
                >
                    <EditIcon />
                </div>
            </div>
        </Tippy>
    );
}

export default EditSubagentDetails;
