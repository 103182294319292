import {InsufficientAuthenticationType} from "../proptypes/PropTypeObjects";

export default class InsufficientAuthenticationError extends Error {
    public type: InsufficientAuthenticationType;

    constructor(type: InsufficientAuthenticationType) {
        super(type);
        this.type = type;
        this.name = "InsufficientAuthenticationError";
    }
}