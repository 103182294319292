import {ReduxPinnedOffersPropTypes} from "../../proptypes/PropTypeRedux";
import PinnedOffersTypes from "../constants/pinnedOffers.constants";
import PinnedOffersActionProps from "./actions/pinnedOffers.reducer.actions";

export const INITIAL_STATE = {
    pinnedOffers: [],
    pinnedHotels: [],
    slideDuration: 300,
    slide: false,
    error: undefined
} as ReduxPinnedOffersPropTypes;

export default (state = INITIAL_STATE, action: PinnedOffersActionProps): ReduxPinnedOffersPropTypes => {
    switch (action.type) {
    case PinnedOffersTypes.ADD_PINNED_OFFER:
        return {
            ...state,
            pinnedOffers: [
                ...state.pinnedOffers,
                action.pinnedOffer
            ],
            pinnedHotels: action.pinnedHotels
        };
    case PinnedOffersTypes.REMOVE_PINNED_OFFER:

        return {
            ...state,
            pinnedOffers: state.pinnedOffers
                .filter((po) => po !== action.pinnedOffer),
            pinnedHotels: action.pinnedHotels
        };
    case PinnedOffersTypes.RESET_PINNED_OFFERS:
        return {
            ...state,
            pinnedOffers: [],
            pinnedHotels: [],
            slide: false
        };
    case PinnedOffersTypes.SET_SLIDE:
        return {
            ...state,
            slide: action.slide
        };
    default:
        return state;
    }
};
