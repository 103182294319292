import {DestinationsTypes} from "../constants/destinations.constants";
import {ReduxDestinationsPropTypes} from "proptypes/PropTypeRedux";
import DestinationsActions from "./actions/destinations.reducer.actions";

export const INITIAL_STATE = {
    searching: false,
    destinations: undefined,
    transferDestinations: undefined,
    transferOrigins: undefined,
    error: undefined
} as ReduxDestinationsPropTypes;

export default (state = INITIAL_STATE, action: DestinationsActions): ReduxDestinationsPropTypes => {
    switch (action.type) {
    case DestinationsTypes.REQ_DEST:
        return {...state, searching: true};
    case DestinationsTypes.GET_DEST_SUCCESS:
        return {...state, destinations: action.destinations, searching: false};
    case DestinationsTypes.GET_DEST_FAILURE:
        return {...state, error: action.error, searching: false};
    case DestinationsTypes.REQ_TRANSFER_DEST:
        return {...state, searching: true};
    case DestinationsTypes.GET_TRANSFER_DEST_SUCCESS:
        return {...state, transferDestinations: action.transferDestinations, searching: false};
    case DestinationsTypes.GET_TRANSFER_DEST_FAILURE:
        return {...state, error: action.error, searching: false};
    case DestinationsTypes.REQ_TRANSFER_ORIGIN:
        return {...state, searching: true};
    case DestinationsTypes.GET_TRANSFER_ORIGIN_SUCCESS:
        return {...state, transferOrigins: action.transferOrigins, searching: false};
    case DestinationsTypes.GET_TRANSFER_ORIGIN_FAILURE:
        return {...state, error: action.error, searching: false};
    default:
        return state;
    }
};
