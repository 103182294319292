import React, {ReactElement} from "react"
import LoginNavBar from "../../Login/LoginNavBar/LoginNavBar"
import CookieConsentContainer from "../../../components/utils/CookieConsentContainer/CookieConsentContainer"
import PaymentSuccess from "./PaymentSuccess"

const PaymentSuccessContainer = (): ReactElement => (
    <>
        <LoginNavBar />

        <PaymentSuccess />

        <CookieConsentContainer />
    </>
)

export default PaymentSuccessContainer;