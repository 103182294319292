import React, {ReactElement, useCallback, useEffect, useMemo} from "react"
import cx from "classnames"
import {useTranslation} from "react-i18next"
import * as priceUtils from "utils/priceUtils"
import _ from "lodash"
import UserButton from "components/base/UserButton"
import styles from "./Pricing.module.scss"
import withConfirmation, {WithConfirmation} from "components/utils/withConfirmation"
import {UserButtonVariant} from "components/base/UserButton/UserButton"
import {getPriceWithCurrencySymbol} from "@hotelston_web_frontend_utils/currency/getPriceWithCurrency"
import {useAppDispatch, useAppSelector} from "redux/hooks"
import usePaymentOptions from "components/base/Payment/PaymentType/usePaymentOptions"
import {useCurrency} from "components/utils/withLocalesAndCurrencies"
import {getTransactionFeeByPrice} from "redux/actions/pricing.actions"
import Spinner from "components/common/../base/Loaders/Spinner"
import {ReduxPaymentTypePropTypes} from "proptypes/PropTypeRedux"
import Currency from "@hotelston_web_frontend_parent/constants/currency";

type PriceConversion = {
    label: string;
    value: number;
    displayValue: number;
    transactionFee?: boolean;
}

type Props = WithConfirmation & {
    children?: React.ReactNode | React.ReactNode[];
    customButton?: React.ReactNode;
    withButton?: boolean;
    customElementAboveButton?: React.ReactNode;
    disabledButton?: boolean;
    customButtonLabel?: string;
    smsFee?: number;
    displaySmsFee?: number;
    salePrice?: number;
    displaySalePrice: number;
    paymentPending?: number;
    greyLabels?: boolean;
    discount?: number;
    voucher?: number;
    secondaryButton?: ReactElement;

    hidePricingInformation?: boolean;
    customDisplaySalePriceLabel?: string;
};

function Pricing({
    children,
    customButton,
    withButton = false,
    customElementAboveButton,
    disabledButton = false,
    customButtonLabel = "",
    smsFee,
    displaySmsFee,
    salePrice = 0,
    displaySalePrice,
    greyLabels,
    discount = 0,
    voucher = 0,
    secondaryButton,
    paymentPending = salePrice,
    hidePricingInformation,
    customDisplaySalePriceLabel
}: Props): ReactElement {
    const {t} = useTranslation();
    const dispatch = useAppDispatch();

    const smsOrdered = useAppSelector((state) => state.transferBooking.data?.transferBookingInfo?.smsOrdered)

    const dynamicTransactionFee = useAppSelector((state) => state.pricing.transactionFee);
    const displayDynamicTransactionFee = useAppSelector((state) => state.pricing.displayTransactionFee);
    const loadingTransactionFee = useAppSelector((state) => state.pricing.loading);
    const displayCurrencyName = useCurrency();

    const {
        type,
        paymentTransferType,
        selectedPaymentLink,
        appliedWalletAmount,
        markup,
        transactionFee,
        displayTransactionFee,
        discountCode
    } = usePaymentOptions();

    // const simplePricing = salePrice !== 0 && markup === 1 && !smsFee && !transactionFee && !appliedWalletAmount && currency !== displayCurrencyName;
    const retailPricing = markup !== 1;
    // const retailSimplePricing = retailPricing && !smsFee && !transactionFee && !appliedWalletAmount;

    // const retailTotal = priceUtils.getTotal(salePrice, markup, smsFee) + transactionFee;
    // const billingTotal = _.min([
    //     priceUtils.getTotal(salePrice, 1, smsFee) + transactionFee - (appliedWalletAmount || 0),
    //     paymentPending + transactionFee - (appliedWalletAmount || 0)
    // ]);
    const displayTotal = priceUtils.getDisplayTotal(displaySalePrice, markup, displayDynamicTransactionFee || displayTransactionFee, displaySmsFee);

    const payDue = priceUtils.getPayDue(paymentPending, markup, discount, voucher, transactionFee, smsFee);

    const priceConversions: PriceConversion[] = useMemo(() => {
        const conversions: PriceConversion[] = [];

        if (discount && _.round(discount, 2) > 0) {
            conversions.push({
                label: t("price_p_discount"),
                value: -discount,
                displayValue: -discount
            });
        }

        if (voucher && _.round(voucher, 2) > 0) {
            conversions.push({
                label: t("price_p_voucher"),
                value: -voucher,
                displayValue: -voucher
            });
        }

        if (appliedWalletAmount && _.round(appliedWalletAmount, 2) > 0) {
            conversions.push({
                label: t("price_p_partial_wallet"),
                value: -appliedWalletAmount,
                displayValue: -appliedWalletAmount
            });
        }

        if (smsOrdered && displaySmsFee && displaySmsFee > 0) {
            conversions.push({
                label: t("price_p_sms_fee"),
                value: displaySmsFee,
                displayValue: displaySmsFee
            });
        }

        if ((transactionFee && displayTransactionFee) || (dynamicTransactionFee && displayDynamicTransactionFee)) {
            conversions.push({
                label: t("price_p_transaction_fee"),
                value: dynamicTransactionFee || transactionFee,
                displayValue: displayDynamicTransactionFee || displayTransactionFee,
                transactionFee: true
            });
        }

        if (discountCode && discountCode.valid && discountCode.discountSum) {
            conversions.push({
                label: discountCode.code,
                value: -discountCode.discountSum,
                displayValue: -discountCode.discountSum
            });
        }

        return conversions;
    }, [appliedWalletAmount, discount, discountCode, displayDynamicTransactionFee, displaySmsFee, displayTransactionFee, dynamicTransactionFee, smsOrdered, t, transactionFee, voucher]);

    const priceAfterConversions = useMemo(() => {
        let payDue = paymentPending;

        priceConversions.forEach((conversion) => {
            payDue = payDue + conversion.value;
        })

        return payDue;
    }, [paymentPending, priceConversions]);

    const getNewestTransactionFee = useCallback(() => {
        if (!selectedPaymentLink && !paymentTransferType) {
            return;
        }

        if (type === ReduxPaymentTypePropTypes.ANONYMOUS_MULTIBOOKING) {
            return;
        }

        let price = paymentPending;

        priceConversions
            .filter((conv) => !conv.transactionFee)
            .forEach((conv) => {
                price += conv.value;
            });

        dispatch(getTransactionFeeByPrice(+price.toFixed(2), selectedPaymentLink, paymentTransferType));
    }, [type, paymentPending, priceConversions, dispatch, selectedPaymentLink, paymentTransferType]);

    useEffect(() => {
        if (!transactionFee) {
            return;
        }

        getNewestTransactionFee();
    }, [getNewestTransactionFee, transactionFee, priceAfterConversions]);

    return (
        <div className={cx(styles.root, greyLabels && styles.greyLabels)}>
            {!hidePricingInformation && (
                <table>
                    <tbody>
                        <tr>
                            <td>
                                {customDisplaySalePriceLabel || (retailPricing ? t("price_p_retail_price") : t("price_p_booking_price"))}:
                            </td>

                            <td className={styles.price}>
                                {getPriceWithCurrencySymbol(displaySalePrice * markup, displayCurrencyName)}
                            </td>
                        </tr>

                        {retailPricing && (
                            <tr>
                                <td>
                                    {t("price_p_booking_price")}:
                                </td>

                                <td className={styles.price}>
                                    {getPriceWithCurrencySymbol(displaySalePrice, displayCurrencyName)}
                                </td>
                            </tr>
                        )}

                        {priceConversions && priceConversions.length > 0 && (
                            <>
                                {priceConversions.map((conversion) => (
                                    <tr>
                                        <td>{conversion.label}:</td>
                                        <td className={styles.price}>
                                            {conversion.transactionFee && loadingTransactionFee ? (
                                                <Spinner className={styles.TransactionFeeLoading}/>
                                            ) : getPriceWithCurrencySymbol(conversion.displayValue, displayCurrencyName)}
                                        </td>
                                    </tr>
                                ))}
                            </>
                        )}

                        {displayTotal && Currency.EUR !== displayCurrencyName ? (
                            <tr>
                                <td className={styles["pricing-label"]}>
                                    {retailPricing ? t("price_p_retail_total") : t("price_p_total")}:
                                </td>

                                <td className={styles.price}>
                                    {getPriceWithCurrencySymbol(displayTotal, displayCurrencyName)}
                                </td>
                            </tr>
                        ) : null}

                        <tr className={styles.total}>
                            {(payDue || payDue === 0) && priceConversions.length > 0 && (
                                <>
                                    <td className={styles["total-label"]}>
                                        {t("price_p_pay_due")}:
                                    </td>

                                    <td className={styles["total-price"]}>
                                        {getPriceWithCurrencySymbol(priceAfterConversions, Currency.EUR)}
                                    </td>
                                </>
                            )}
                        </tr>
                    </tbody>
                </table>
            )}

            {withButton && (
                <div className={styles.buttonContainer}>
                    {!!customElementAboveButton && customElementAboveButton}

                    {secondaryButton && (secondaryButton)}

                    {customButton && (customButton)}

                    {(!customButton && (
                        <UserButton
                            text={
                                customButtonLabel || t("price_p_continue")
                            }
                            variant={UserButtonVariant.PRIMARY}
                            disabledButton={disabledButton}
                            buttonProps={{
                                disabled: disabledButton,
                                style: {
                                    width: "160px"
                                }
                            }}
                        />
                    ))}
                </div>
            )}
        </div>
    );

    // return (
    //     <div className={cx(styles.root, greyLabels && styles.greyLabels)}>
    //         <table>
    //             <tbody>
    //                 {displaySalePrice && (transactionFee || smsFee || displaySalePrice || appliedWalletAmount) ? (
    //                     <tr>
    //                         <td className={styles["pricing-label"]}>
    //                             {retailPricing ? t("price_p_retail_price") : t("price_p_booking_price")}:
    //                         </td>
    //
    //                         <td className={styles.price}>
    //                             {getPriceWithCurrencySymbol(displaySalePrice * companyMarkup, displayCurrencyName)}
    //                         </td>
    //                     </tr>
    //                 ) : null}
    //
    //                 {appliedWalletAmount && appliedWalletAmount > 0 ? (
    //                     <tr>
    //                         <td className={styles["pricing-label"]}>
    //                             {t("Partial payment with wallet")}:
    //                         </td>
    //
    //                         <td className={styles.price}>
    //                             {getPriceWithCurrencySymbol(appliedWalletAmount, displayCurrencyName)}
    //                         </td>
    //                     </tr>
    //                 ) : null}
    //
    //                 {displaySmsFee && displaySmsFee > 0 ? (
    //                     <tr>
    //                         <td className={styles["pricing-label"]}>
    //                             {t("price_p_sms_fee")}:
    //                         </td>
    //
    //                         <td className={styles.price}>
    //                             {getPriceWithCurrencySymbol(displaySmsFee, displayCurrencyName)}
    //                         </td>
    //                     </tr>
    //                 ) : null}
    //
    //                 {transactionFee && displayTransactionFee ? (
    //                     <tr>
    //                         <td className={styles["pricing-label"]}>
    //                             {t("price_p_transaction_fee")}:
    //                         </td>
    //
    //                         <td className={styles.price}>
    //                             {getPriceWithCurrencySymbol(displayTransactionFee, displayCurrencyName)}
    //                         </td>
    //                     </tr>
    //                 ) : null}
    //
    //                 {displayTotal && companyCurrencyName !== displayCurrencyName ? (
    //                     <tr>
    //                         <td className={styles["pricing-label"]}>
    //                             {retailPricing ? t("price_p_retail_total") : t("price_p_total")}:
    //                         </td>
    //
    //                         <td className={styles.price}>
    //                             {getPriceWithCurrencySymbol(displayTotal, displayCurrencyName)}
    //                         </td>
    //                     </tr>
    //                 ) : null}
    //
    //                 {retailPricing && !retailSimplePricing && !(companyCurrencyName !== displayCurrencyName) ? (
    //                     <tr>
    //                         <td className={styles["pricing-label"]}>
    //                             {t("price_p_retail_total")}:
    //                         </td>
    //
    //                         <td className={styles.price}>
    //                             {getPriceWithCurrencySymbol(retailTotal, companyCurrencyName)}
    //                         </td>
    //                     </tr>
    //                 ) : undefined}
    //
    //                 <tr>
    //                     {!!billingTotal && (
    //                         <>
    //                             <td className={styles["pricing-label"]}>
    //                                 {simplePricing ? t("price_p_booking_price") : t("price_p_billing_total")}:
    //                                 {/*{t("price_p_booking_price")}:*/}
    //                             </td>
    //
    //                             <td className={styles.price}>
    //                                 {getPriceWithCurrencySymbol(billingTotal, companyCurrencyName)}
    //                             </td>
    //                         </>
    //                     )}
    //                 </tr>
    //
    //                 {discount && _.round(discount, 2) > 0 ? (
    //                     <tr>
    //                         <td className={styles["pricing-label"]}>
    //                             {t("price_p_discount")}:
    //                         </td>
    //
    //                         <td className={styles.price}>
    //                             {getPriceWithCurrencySymbol(-discount, companyCurrencyName)}
    //                         </td>
    //                     </tr>
    //                 ) : null}
    //
    //                 {voucher && _.round(voucher, 2) > 0 ? (
    //                     <tr>
    //                         <td className={styles["pricing-label"]}>
    //                             {t("price_p_voucher")}:
    //                         </td>
    //
    //                         <td className={styles.price}>
    //                             {getPriceWithCurrencySymbol(-voucher, companyCurrencyName)}
    //                         </td>
    //                     </tr>
    //                 ) : null}
    //
    //                 <tr className={styles.total}>
    //                     {(payDue || payDue === 0) && (voucher > 0 || discount > 0) && (
    //                         <>
    //                             <td className={styles["total-label"]}>
    //                                 {t("price_p_pay_due")}:
    //                             </td>
    //
    //                             <td className={styles["total-price"]}>
    //                                 {getPriceWithCurrencySymbol(payDue, companyCurrencyName)}
    //                             </td>
    //                         </>
    //                     )}
    //                 </tr>
    //             </tbody>
    //         </table>
    //
    //         {withButton && (
    //             <div className={styles.buttonContainer}>
    //                 {!!customElementAboveButton && customElementAboveButton}
    //
    //                 {backButton && (backButton)}
    //
    //                 {customButton && (customButton)}
    //
    //                 {(!customButton && (
    //                     <UserButton
    //                         text={
    //                             customButtonLabel || t("price_p_continue")
    //                         }
    //                         variant={UserButtonVariant.PRIMARY}
    //                         disabledButton={disabledButton}
    //                         buttonProps={{
    //                             disabled: disabledButton,
    //                             style: {
    //                                 width: "160px"
    //                             }
    //                         }}
    //                     />
    //                 ))}
    //             </div>
    //         )}
    //     </div>
    // );
}

export default withConfirmation(Pricing);
