import React, {
    createRef,
    ReactElement, RefObject, useCallback, useEffect, useMemo, useRef, useState
} from "react"
import {Trans, useTranslation} from "react-i18next";
import {NavLink} from "react-router-dom";
import _, {parseInt} from "lodash";
import cx from "classnames";
import {useAppSelector} from "../../../../redux/hooks";
import styles from "./MainLinks.module.scss";
import useClickOutside from "../../../../utils/hooks/useClickOutside";
import useDefaultFeatureEnabled from "../../../../utils/hooks/useDefaultFeatureEnabled";
import oldAppUrl from "../../../../api/constants/oldAppUrl";
import {useLocale} from "../../../utils/withLocalesAndCurrencies";
import useWindowResize from "../../../../utils/hooks/useWindowResize";
import Cookies from "js-cookie"
import {DEFAULT_WEB_VERSION_COOKIE_NAME} from "../../../../utils/cookie/cookie"
import createLuxonDate from "../../../../utils/date/createLuxonDate"

export type MainLinksProps = {
    linkClassName: string;

    afterLinkClick?: () => void;
    mobile?: boolean;
};

type MainLinkWrapperType = {
    linkElement: ReactElement,
    linkElementRef: RefObject<HTMLElement>;

    lastElementWidth: number;
};

const MainLinks = ({
    linkClassName,
    afterLinkClick,
    mobile
}: MainLinksProps): ReactElement => {
    const {t} = useTranslation();
    const locale = useLocale();
    const mainLinksContainerRef = useRef<HTMLDivElement>(null);
    const linkMenuContainerRef = useRef<HTMLDivElement>(null);

    const superAgent = useAppSelector((state) => state.auth.userData?.superAgent || false);
    const admin = useAppSelector((state) => state.auth.userData?.companyUser?.admin || false);
    // const superAgent = userData?.superAgent;
    // const admin = userData?.companyUser.admin;
    const {featureEnabled: travelRestrictionsEnabled} = useDefaultFeatureEnabled("travelRestrictions");

    const afterLinkClickCallback = useCallback(() => {
        if (afterLinkClick) {
            afterLinkClick();
        }
    }, [afterLinkClick]);

    const onTransitionLinkClicked = useCallback(() => {
        afterLinkClickCallback();

        Cookies.set(DEFAULT_WEB_VERSION_COOKIE_NAME, "1", {
            expires: createLuxonDate().plus({day: 30}).toJSDate(),
            domain: "hotelston.com"
        });
    }, [afterLinkClickCallback]);

    const allLinks = useMemo(() => [
        (<NavLink onClick={afterLinkClickCallback} to="/hotels">{t("head_hotels")}</NavLink>),
        (<NavLink onClick={afterLinkClickCallback} to="/transfers">{t("head_transfers")}</NavLink>),
        (<NavLink onClick={afterLinkClickCallback} to="/my-bookings">{t("head_my_bookings")}</NavLink>),
        admin && (<NavLink onClick={afterLinkClickCallback} to="/my-company">{t("head_my_company")}</NavLink>),
        admin && superAgent && (<NavLink onClick={afterLinkClickCallback} to="/subagents">{t("head_subagents")}</NavLink>),
        // travelRestrictionsEnabled && (<NavLink to="/travel-restrictions">{t("head_travel_restrictions")}</NavLink>),
        (<NavLink onClick={afterLinkClickCallback} to="/contacts">{t("head_contacts")}</NavLink>),
        (<a onClick={onTransitionLinkClicked} href={`${oldAppUrl}?lang=${locale}`} style={{textTransform: "initial"}}>{t("h_t_old_app_transition_label")}</a>)
    ].filter((link) => !!link) as ReactElement[], [admin, afterLinkClickCallback, locale, superAgent, t]);
    const allLinkRefs = useRef(allLinks.map(() => createRef<HTMLDivElement>()));
    const [linkMap, setLinkMap] = useState<MainLinkWrapperType[]>([]);

    const [visibleLinks, setVisibleLinks] = useState<MainLinkWrapperType[]>(allLinks.map((element, i) => ({
        linkElement: (<div key={_.uniqueId("MainLink_")} className={linkClassName} ref={allLinkRefs.current[i]}>{element}</div>),
        linkElementRef: allLinkRefs.current[i],

        lastElementWidth: 0
    } as MainLinkWrapperType)));
    const [overflownLinks, setOverflownLinks] = useState<MainLinkWrapperType[]>([]);
    const [moreLinksOpen, setMoreLinksOpen] = useState<boolean>();

    useClickOutside(linkMenuContainerRef, () => {
        setMoreLinksOpen(false);
    });

    const calculateElementWidth = useCallback((el: Element | null): number => {
        if (!el) {
            return 0;
        }

        const computedStyle = getComputedStyle(el);
        const width = parseInt(computedStyle.width);
        const marginLeft = parseInt(computedStyle.marginLeft);
        const marginRight = parseInt(computedStyle.marginRight);

        return width + marginLeft + marginRight;
    }, []);

    const calculateTotalLinkWidth = useCallback((links: MainLinkWrapperType[]): number => {
        let totalWidth = 0;

        links.forEach((link) => {
            totalWidth += link.lastElementWidth;
        });

        return totalWidth;
    }, []);

    const recalculateLinkMap = useCallback(() => {
        setLinkMap(allLinks.map((element, i) => ({
            linkElement: (<div key={_.uniqueId("MainLink_")} className={linkClassName} ref={allLinkRefs.current[i]}>{element}</div>),
            linkElementRef: allLinkRefs.current[i],

            lastElementWidth: 0
        } as MainLinkWrapperType)));
    }, [allLinks, linkClassName]);

    const onResizeCalculate = useCallback(() => {
        if (mobile) {
            return;
        }

        if (linkMap.length === 0) {
            recalculateLinkMap();
        }

        linkMap.forEach((link) => {
            const width = calculateElementWidth(link.linkElementRef?.current);

            if (width && width !== 0) {
                _.set(link, "lastElementWidth", width);
            }
        });

        const currentContainerWidth = calculateElementWidth(mainLinksContainerRef.current) - 100;
        const newVisibleLinks: MainLinkWrapperType[] = linkMap.slice();
        const leftoverLinks: MainLinkWrapperType[] = [];

        if (calculateTotalLinkWidth(newVisibleLinks) > currentContainerWidth) {
            for (let i = 0; i < linkMap.length; i++) {
                const el = newVisibleLinks.pop();

                if (el) {
                    leftoverLinks.unshift(el);
                }

                if (calculateTotalLinkWidth(newVisibleLinks) < currentContainerWidth) {
                    break;
                }
            }
        }

        if (leftoverLinks.length === 1 && newVisibleLinks.length > 0) {
            const secondEl = newVisibleLinks.pop();
            if (secondEl) {
                leftoverLinks.unshift(secondEl);
            }
        }

        setVisibleLinks(newVisibleLinks);
        setOverflownLinks(leftoverLinks);
    }, [calculateElementWidth, calculateTotalLinkWidth, linkMap, recalculateLinkMap]);

    // useResizeDetector({
    //     refreshRate: 100,
    //     onResize: onResizeCalculate,
    //     targetRef: headerContainerRef
    // });

    useWindowResize({
        listener: onResizeCalculate,
        method: "throttle",
        debounceTime: 100,
        callOnMount: true
    });

    // useLayoutEffect(() => {
    //     onResizeCalculate();
    // }, []);

    const overflownLinksCount = overflownLinks.length;

    return (
        <div className={styles.Root} ref={mainLinksContainerRef}>
            {/*{allLinks.map((link) => (<div key={_.uniqueId("MainLink_")} className={linkClassName}>{link}</div>))}*/}

            {visibleLinks.map((link) => link.linkElement)}

            {overflownLinks.length !== 0 && (
                <div
                    ref={linkMenuContainerRef}
                    className={cx(styles.MoreLinksContainer, moreLinksOpen && styles.MoreLinksContainerOpen)}
                    onClick={() => setMoreLinksOpen(!moreLinksOpen)}
                >
                    <span>
                        <Trans i18nKey="h_m_l_more" values={{count: overflownLinksCount}}>
                            + {{overflownLinksCount}} more
                        </Trans>
                    </span>

                    {moreLinksOpen && (
                        <div>
                            {overflownLinks.map((link) => link.linkElement)}
                        </div>
                    )}
                </div>
            )}
        </div>
    );
};

export default MainLinks;