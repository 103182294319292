import React, {
    ReactElement, useCallback, useEffect, useState
} from "react";
import Edit from "components/common/Edit";
import {useTranslation} from "react-i18next";
import SuccessModal from "components/common/ConfirmationModal/SuccessModal";
import {useUpdateBillingDetailsAction} from "components/utils/useCompanyData";
import EditBillingDetailsModal from "components/common/EditBillingDetailsModal";
import {Credentials} from "../../../proptypes/PropTypeObjects";
import UserButton from "../../../components/base/UserButton";
import {UserButtonVariant} from "../../../components/base/UserButton/UserButton";
import styles from "./EditBillingDetails.module.scss";
import {getQueryParams} from "../../../utils/url/queryParams";

type Props = {
    className?: string;
    credentials: Credentials
    updateBillingDetails: (credentials: Credentials) => void;
    hasSignedMainAgreement: boolean;
    afterLogin?: boolean;
    visible?: boolean;
};

function EditBillingDetails({
    className,
    credentials,
    updateBillingDetails,
    hasSignedMainAgreement,
    afterLogin,
    visible
}: Props): ReactElement {
    const [modalIsOpen, setModalIsOpen] = useState(visible || false);
    const [successModal, setSuccessModal] = useState(false);

    const onSuccess = useCallback(
        (credentials: Credentials) => {
            setModalIsOpen(false);
            setSuccessModal(true);
            updateBillingDetails(credentials);
        },
        [updateBillingDetails]
    );

    const {
        requesting,
        errors,
        updateBillingDetails: updateBillingDetailsAction
    } = useUpdateBillingDetailsAction(onSuccess);

    const {t} = useTranslation();

    useEffect(() => {
        const queryParams = getQueryParams();
        const fromNotification = queryParams.createBillingDetails;

        if (fromNotification) {
            setModalIsOpen(true);
        }
    }, []);

    return (
        <>
            {modalIsOpen && (
                <EditBillingDetailsModal
                    credentials={credentials}
                    onConfirm={(credentials: Credentials) => {
                        updateBillingDetailsAction(credentials);
                    }}
                    hasSignedMainAgreement={hasSignedMainAgreement}
                    onDecline={() => { setModalIsOpen(false); }}
                    errors={errors}
                    afterLogin={afterLogin}
                    spinner={requesting}
                />
            )}
            {successModal && (
                <SuccessModal
                    onClose={() => setSuccessModal(false)}
                    successMessage={t("mc_ebd_success_message_billing_details")}
                />
            )}

            {!visible && credentials.id && (
                <Edit
                    className={className}
                    onClick={() => setModalIsOpen(true)}
                    what={t("mc_ebd_edit_details_button").toLowerCase()}
                    style={{
                        fontWeight: 600,
                        lineHeight: "22px",
                        fontSize: "12px"
                    }}
                />
            )}

            {!visible && !credentials.id && (
                <UserButton
                    text={t("mc_ebd_create_details_button")}
                    variant={UserButtonVariant.PRIMARY}
                    className={styles.CreateBillingDetails}
                    buttonProps={{
                        onClick: () => setModalIsOpen(true)
                    }}
                />
            )}
        </>
    );
}

export default EditBillingDetails;
