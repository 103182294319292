import React, {ReactElement, useCallback} from "react";
import {useParams} from "react-router-dom";
import {useUpdateUserAction} from "components/utils/useCompanyUsers";
import useCompanyData from "../../../../components/utils/useCompanyData";
import {useAppDispatch, useAppSelector} from "../../../../redux/hooks";
import CompanyUserView from "./CompanyUserView";
import {CompanyUserPropTypes, CompanyUserWithPassword} from "../../../../proptypes/PropTypeObjects";
import {updateCompanyUser} from "../../../../redux/actions/company.actions";

export default function OtherCompanyUserView(): ReactElement {
    const dispatch = useAppDispatch();

    const {
        data,
        error: companyDataError,
        requestingCompanyDetails
    } = useCompanyData();

    const {
        requesting: requestingUserUpdate,
        errors: userUpdateErrors,
        updateUser: updateUserAction,
        clearState
    } = useUpdateUserAction();

    const {id} = useParams<{id: string}>();

    const isAdmin = useAppSelector((state) => state.auth.userData?.companyUser.admin);
    const companyUser = data?.companyUsers.find((companyUser) => id && companyUser.id === +id);
    const temporaryPassword = useAppSelector((state) => state.auth.userData?.companyUser.id) !== companyUser?.id;

    const updateUserCallback = useCallback((user: CompanyUserPropTypes) => {
        dispatch(updateCompanyUser(user));
    }, [dispatch]);

    return (
        <CompanyUserView
            companyUser={companyUser}
            requestingCompanyDetails={requestingCompanyDetails}
            companyDataError={companyDataError}
            isAdmin={isAdmin}
            temporaryPassword={temporaryPassword}
            requestingUserUpdate={requestingUserUpdate}
            userUpdateErrors={userUpdateErrors}
            updateUserAction={updateUserAction}
            updateCompanyUser={updateUserCallback}
            clearState={clearState}
        />
    );
}