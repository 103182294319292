import {useEffect} from "react";
import {useAppDispatch} from "redux/hooks";
import {ReduxPaymentTypePropTypes} from "proptypes/PropTypeRedux";
import {setPaymentType} from "redux/actions/payment.actions";
import {calculateTransactionFee} from "redux/actions/pricing.actions";

const usePaymentType = (type: ReduxPaymentTypePropTypes) => {
    const dispatch = useAppDispatch();

    useEffect(() => {
        dispatch(setPaymentType(type));
        dispatch(calculateTransactionFee());

        return () => {
            dispatch(setPaymentType(undefined));
        };
    }, [dispatch, type]);
}

export default usePaymentType;