import React, {
    useReducer, useCallback, ReactElement, useState, useEffect
} from "react";
import Input from "components/base/Input";
import ConfirmationModal from "components/common/ConfirmationModal";
import {useTranslation} from "react-i18next";
import CountriesDropdown from "components/base/Input/CustomReactSelect/CountriesDropdown";
import CustomCheckBox from "components/base/Input/CustomCheckBox";
import getNullishInput from "utils/getNullishInput";
import cx from "classnames";
import styles from "./EditBillingDetailsModal.module.scss";
import {EMAIL_PATTERN, PHONE_PATTERN} from "../../../utils/validateForm";
import {CountryPropTypes, Credentials} from "../../../proptypes/PropTypeObjects";
import {useAppSelector} from "../../../redux/hooks";

type ActionState = Credentials & {
    eu?: boolean;
};

type ActionProps =
    | {type: "HANDLE_INPUT", name: string, value: string | boolean}
    | {type: "HANDLE_COUNTRY", country: string, countryId: number, eu: boolean}
    | {type: "TOGGLE_NON_VAT"}
    ;

function reducer(state: ActionState, action: ActionProps): ActionState {
    switch (action.type) {
    case "HANDLE_INPUT":
        return {
            ...state,
            [action.name]: action.value
        };
    case "HANDLE_COUNTRY":
        return {
            ...state,
            country: action.country,
            countryId: action.countryId,
            eu: action.eu
        };
    case "TOGGLE_NON_VAT":
        return {
            ...state,
            nonVatPayer: !state.nonVatPayer
        };
    default:
        return state;
    }
}

function getVATLabelByCountry(country: number): string {
    switch (country) {
    case 1870156:
        // Italy
        return "ebdm_ebdm_vat_payer_number_italy";
    default:
        return "ebdm_ebdm_vat_payer_number";
    }
}

export type EditBillingDetailsContentProps = {
    credentials?: Credentials;
    hasSignedMainAgreement?: boolean;
    onCredentialsChanged: (credentials: Credentials) => void;
    afterBook?: boolean;
    afterLogin?: boolean;
    noExplanation?: boolean;
    className?: string;
};

export function EditBillingDetailsContent({
    credentials,
    hasSignedMainAgreement,
    onCredentialsChanged,
    afterBook,
    afterLogin,
    noExplanation,
    className
}: EditBillingDetailsContentProps): ReactElement {
    const {t} = useTranslation();
    const [localFormData, dispatch] = useReducer(reducer, credentials || {} as Credentials);

    useEffect(() => {
        onCredentialsChanged(localFormData);
    }, [localFormData, onCredentialsChanged]);

    const handleInput = useCallback(({target: {name, value}}: any) => {
        dispatch({type: "HANDLE_INPUT", name, value});
    }, [dispatch]);

    const handleCountry = useCallback((country?: CountryPropTypes) => {
        if (!country) {
            return;
        }

        dispatch({
            type: "HANDLE_COUNTRY",
            country: country.name,
            countryId: country.id,
            eu: country.eu
        });
    },
    [dispatch]);

    const toggleNonVat = useCallback(
        () => dispatch({
            type: "TOGGLE_NON_VAT"
        }),
        [dispatch]
    );

    return (
        <div className={cx(styles.root, className)}>
            {!noExplanation && (
                <p>
                    {afterLogin && t("ebdm_ebdm_billing_details_manual")}
                    {afterBook && t("ebdm_ebdm_billing_details_after_book")}
                    {!afterLogin && !afterBook && t("ebdm_ebdm_billing_details_manual")}
                </p>
            )}

            <Input
                fullHeight={false}
                variants={{labelPosition: "outlined"}}
                helperText={t("ebdm_ebdm_company_name_helper")}
                highlightOnEmpty
                inputProps={{
                    type: "text",
                    required: true,
                    name: "companyName",
                    placeholder: t("ebdm_ebdm_company_name"),
                    onChange: handleInput,
                    value: getNullishInput(localFormData.companyName, "text"),
                    disabled: hasSignedMainAgreement
                }}
            />

            <Input
                fullHeight={false}
                variants={{labelPosition: "outlined"}}
                helperText={t("ebdm_ebdm_signee_helper")}
                highlightOnEmpty
                inputProps={{
                    type: "text",
                    name: "signee",
                    required: true,
                    placeholder: t("ebdm_ebdm_signee"),
                    onChange: handleInput,
                    value: getNullishInput(localFormData.signee, "text")
                }}
            />

            <Input
                fullHeight={false}
                helperText={t("ebdm_ebdm_signeePosition_helper")}
                variants={{labelPosition: "outlined"}}
                highlightOnEmpty
                inputProps={{
                    type: "text",
                    name: "signeePosition",
                    required: true,
                    placeholder: t("ebdm_ebdm_signeePosition"),
                    onChange: handleInput,
                    value: getNullishInput(localFormData.signeePosition, "text")
                }}
            />

            <Input
                fullHeight={false}
                helperText={t("ebdm_ebdm_email_helper")}
                variants={{labelPosition: "outlined"}}
                highlightOnEmpty
                inputProps={{
                    type: "email",
                    name: "email",
                    required: true,
                    pattern: EMAIL_PATTERN,
                    placeholder: t("ebdm_ebdm_email"),
                    onChange: handleInput,
                    value: getNullishInput(localFormData.email, "text")
                }}
            />

            <Input
                fullHeight={false}
                helperText={t("ebdm_ebdm_phone_number_helper")}
                variants={{labelPosition: "outlined"}}
                highlightOnEmpty
                inputProps={{
                    type: "tel",
                    name: "phone",
                    required: true,
                    pattern: PHONE_PATTERN,
                    placeholder: t("ebdm_ebdm_phone_number"),
                    onChange: handleInput,
                    value: getNullishInput(localFormData.phone, "text")
                }}
            />

            <CountriesDropdown
                className={styles.CountryDropdown}
                required={true}
                currentCountryName={getNullishInput(localFormData.country, "text")}
                onCountryChange={handleCountry}
                onCountryInputChange={handleInput}
                countryInputName="country"
                disabled={hasSignedMainAgreement}
            />

            <Input
                fullHeight={false}
                variants={{labelPosition: "outlined"}}
                highlightOnEmpty
                inputProps={{
                    type: "text",
                    name: "companyCode",
                    required: true,
                    placeholder: t("ebdm_ebdm_company_code"),
                    onChange: handleInput,
                    value: getNullishInput(localFormData.companyCode, "text"),
                    disabled: hasSignedMainAgreement
                }}
            />

            {localFormData.eu && (
                <div className={styles.CheckboxWrapper}>
                    <Input
                        fullHeight={false}
                        variants={{labelPosition: "outlined"}}
                        highlightOnEmpty
                        inputProps={{
                            type: "text",
                            name: "vatCode",
                            required: true,
                            placeholder: t(getVATLabelByCountry(localFormData.countryId)),
                            onChange: handleInput,
                            value: getNullishInput(localFormData.vatCode, "text"),
                            disabled: hasSignedMainAgreement || localFormData.nonVatPayer
                        }}
                    >
                        <CustomCheckBox
                            label={t("ebdm_ebdm_not_vat_payer")}
                            className={styles.Checkbox}
                            inputProps={{
                                name: "nonVatPayer",
                                checked: getNullishInput(localFormData.nonVatPayer, "checkbox"),
                                onChange: toggleNonVat,
                                disabled: hasSignedMainAgreement || localFormData.nonVatPayer
                            }}
                        />
                    </Input>
                </div>
            )}

            <h4 className="mb-20">{t("ebdm_ebdm_address_title")}</h4>
            <div className="d-flex">
                <Input
                    fullHeight={false}
                    className="mr-10"
                    style={{
                        width: "70%"
                    }}
                    helperText={t("ebdm_ebdm_address_helper")}
                    variants={{labelPosition: "outlined"}}
                    highlightOnEmpty
                    inputProps={{
                        type: "text",
                        name: "street",
                        required: true,
                        placeholder: t("ebdm_ebdm_address"),
                        onChange: handleInput,
                        value: getNullishInput(localFormData.street, "text")

                    }}
                />

                <Input
                    fullHeight={false}
                    style={{
                        width: "35%"
                    }}
                    helperText={t("ebdm_ebdm_postal_code_helper")}
                    variants={{labelPosition: "outlined"}}
                    highlightOnEmpty
                    inputProps={{
                        type: "text",
                        name: "postalCode",
                        required: true,
                        placeholder: t("ebdm_ebdm_postal_code"),
                        onChange: handleInput,
                        value: getNullishInput(localFormData.postalCode, "text")
                    }}
                />
            </div>

            <Input
                fullHeight={false}
                helperText={t("ebdm_ebdm_city_helper")}
                variants={{labelPosition: "outlined"}}
                highlightOnEmpty
                inputProps={{
                    type: "text",
                    name: "city",
                    required: true,
                    placeholder: t("ebdm_ebdm_city"),
                    onChange: handleInput,
                    value: getNullishInput(localFormData.city, "text")
                }}
            />
        </div>
    );
}

type ModalProps = {
    onConfirm: (credentials: Credentials) => void;
    onDecline: () => void;
    credentials: Credentials;
    errors?: string[];
    spinner?: boolean;
    hasSignedMainAgreement?: boolean;
    afterBook?: boolean;
    afterLogin?: boolean;
};

export default function EditBillingDetailsModal({
    onConfirm,
    onDecline,
    credentials,
    errors,
    spinner,
    hasSignedMainAgreement,
    afterBook,
    afterLogin
}: ModalProps): ReactElement {
    const {t} = useTranslation();
    const [formData, setFormData] = useState<Credentials>(credentials);
    const [secondaryConfirmation, setSecondaryConfirmation] = useState<boolean>(false);
    // const bookingsCount = useAppSelector((state) => state.auth.userData?.bookings || 0);

    const declineCallback = useCallback(() => {
        if (afterBook) {
            setSecondaryConfirmation(true);
        } else {
            onDecline();
        }
    }, [afterBook, onDecline]);

    const secondaryDeclineCallback = useCallback(() => {
        onDecline();
    }, [onDecline]);

    const secondaryConfirmCallback = useCallback(() => {
        setSecondaryConfirmation(false);
    }, []);

    const confirmCallback = useCallback(() => onConfirm(formData), [formData, onConfirm]);

    return (
        <>
            {!secondaryConfirmation && (
                <ConfirmationModal
                    onConfirm={confirmCallback}
                    onDecline={declineCallback}
                    config={{
                        variants: {
                            type: "normal"
                        },
                        title: (!credentials || !credentials.id) ? t("ebdm_ebdm_create_billing_details") : t("ebdm_ebdm_edit_billing_details"),
                        //customHeader,
                        content: (
                            <EditBillingDetailsContent
                                credentials={formData}
                                hasSignedMainAgreement={hasSignedMainAgreement}
                                onCredentialsChanged={(newCredentials) => {
                                    setFormData(newCredentials);
                                }}
                                afterBook={afterBook}
                                afterLogin={afterLogin}
                                noExplanation={!(!credentials || !credentials.id)}
                            />
                        ),
                        isForm: true,
                        confirmButtonText: t("ebdm_ebdm_save_changes"),
                        // declineButtonText: afterBook && bookingsCount >= 5 ? t("ebdm_ebdm_cancel") : t("ebdm_ebdm_fill_later"),
                        declineButtonText: afterBook ? t("ebdm_ebdm_cancel") : t("ebdm_ebdm_fill_later"),
                        validateForm: true,
                        errors,
                        spinner
                    }}
                />
            )}

            {secondaryConfirmation && (
                <ConfirmationModal
                    onDecline={secondaryDeclineCallback}
                    onConfirm={secondaryConfirmCallback}
                    config={{
                        variants: {
                            type: "warn"
                        },
                        title: t("ebdm_ebdm_secondary_confirmation_header"),
                        content: t("ebdm_ebdm_secondary_confirmation_content"),
                        confirmButtonText: t("ebdm_ebdm_secondary_confirmation_confirm"),
                        declineButtonText: t("ebdm_ebdm_secondary_confirmation_decline")
                    }}
                />
            )}
        </>
    );
}
