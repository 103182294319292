enum PaymentTypes {
    REQ_OPTIONS = "payment/REQ_OPTIONS",
    REQ_OPTIONS_SUCCESS = "payment/REQ_OPTIONS_SUCCESS",
    REQ_OPTIONS_FAILURE = "payment/REQ_OPTIONS_FAILURE",

    SET_PAYMENT_TYPE = "payment/SET_PAYMENT_TYPE",
    SET_PAYMENT_OPTIONS = "payment/SET_PAYMENT_OPTIONS",
    SET_PROVIDER_PAYMENT = "payment/SET_PROVIDER_PAYMENT",
    // TODO: other actions

    REQ_PAY = "payment/REQ_PAY",
    REQ_PAY_SUCCESS = "payment/REQ_PAY_SUCCESS",
    REQ_PAY_FAILURE = "payment/REQ_PAY_FAILURE",

    HANDLE_PAYMENT_TRANSFER_TYPE = "payment/HANDLE_PAYMENT_TRANSFER_TYPE",
    HANDLE_SELECTED_PAYMENT_LINK = "payment/HANDLE_SELECTED_PAYMENT_LINK",

    TOGGLE_INVOICE_RECIPIENT = "payment/TOGGLE_INVOICE_RECIPIENT",
    HANDLE_INVOICE_RECIPIENT = "payment/HANDLE_INVOICE_RECIPIENT",

    TOGGLE_AGREE_TERMS = "payment/TOGGLE_AGREE_TERMS",
    SELECT_PAYMENT_VOUCHER = "payment/SELECT_PAYMENT_VOUCHER",
    REMOVE_PAYMENT_VOUCHER = "payment/REMOVE_PAYMENT_VOUCHER",

    UPDATE_DISCOUNT_DATA = "payment/UPDATE_DISCOUNT_DATA",
    UPDATE_VOUCHER_DATA = "payment/UPDATE_VOUCHER_DATA",
    UPDATE_VOUCHER_SUM = "payment/UPDATE_VOUCHER_SUM",
    UPDATE_CUSTOM_VOUCHER = "payment/UPDATE_CUSTOM_VOUCHER",

    WALLET_PARTIAL_PAY = "payment/WALLET_PARTIAL_PAY",
    SET_ANONYMOUS_ACTIVE_ORDERS = "payment/SET_ANONYMOUS_ACTIVE_ORDERS",

    SET_PAYMENT_LINK_TOKEN = "payment/SET_PAYMENT_LINK_TOKEN",
    SET_DISCOUNT_CODE = "payment/SET_DISCOUNT_CODE",

    TOGGLE_BILLING_DETAILS = "payment/TOGGLE_BILLING_DETAILS",

    HANDLE_SPLIT_PRICE_PART_SIZE_INPUT = "payment/HANDLE_SPLIT_PRICE_PART_SIZE_INPUT",

    SPLIT_PRICE_GET_OPTIONS_REQ = "payment/SPLIT_PRICE_GET_OPTIONS_REQ",
    SPLIT_PRICE_GET_OPTIONS_SUCCESS = "payment/SPLIT_PRICE_GET_OPTIONS_SUCCESS",
    SPLIT_PRICE_GET_OPTIONS_FAILURE = "payment/SPLIT_PRICE_GET_OPTIONS_FAILURE",

    SPLIT_PRICE_SELECT_PART_PAYMENT = "payment/SPLIT_PRICE_SELECT_PART_PAYMENT",

    SPLIT_PRICE_VALIDATE_SESSION_REQ = "payment/SPLIT_PRICE_VALIDATE_SESSION_REQ",
    SPLIT_PRICE_VALIDATE_SESSION_SUCCESS = "payment/SPLIT_PRICE_VALIDATE_SESSION_SUCCESS",
    SPLIT_PRICE_VALIDATE_SESSION_FAILURE = "payment/SPLIT_PRICE_VALIDATE_SESSION_FAILURE",

    SPLIT_PRICE_PAY_PART_REQ = "payment/SPLIT_PRICE_PAY_PART_REQ",
    SPLIT_PRICE_PAY_PART_SUCCESS = "payment/SPLIT_PRICE_PAY_PART_SUCCESS",
    SPLIT_PRICE_PAY_PART_FAILURE = "payment/SPLIT_PRICE_PAY_PART_FAILURE",
    SPLIT_PRICE_PAY_PART_SUCCESS_NOTIFICATION = "payment/SPLIT_PRICE_PAY_PART_SUCCESS_NOTIFICATION"
}

export default PaymentTypes;