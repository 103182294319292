import React, {ReactElement, useCallback, useState} from "react";
import {useTranslation} from "react-i18next";
import styles from "./ExpandableBox.module.scss";
import {ReactComponent as ArrowIcon} from "assets/icons/arrow.svg";
import cx from "classnames";

export type ExpandableBoxProps = {
    title: string;
    expanded?: boolean;
    active?: boolean;
    children: ReactElement | ReactElement[];
}

const ExpandableBox = (props: ExpandableBoxProps): ReactElement => {
    const {
        title,
        expanded,
        active,
        children
    } = props;

    const {t} = useTranslation();

    const [open, setOpen] = useState<boolean>(expanded || false);

    const headerClickCallback = useCallback(() => {
        setOpen(!open);
    }, [open]);

    return (
        <div
            className={cx(styles.Root, open && styles.Open, active && styles.Active)}
            onClick={headerClickCallback}
        >
            <div
                className={styles.Header}
                onClick={headerClickCallback}
            >
                <h4>{title}</h4>

                <ArrowIcon className={styles.ArrowIcon} />
            </div>

            {open && (
                <div
                    className={styles.Content}
                    onClick={(evt) => {
                        evt.stopPropagation();
                        evt.preventDefault();
                    }}
                >
                    {children}
                </div>
            )}
        </div>
    )
}

export default ExpandableBox;