import React, {ReactElement} from "react"
import {Trans, useTranslation} from "react-i18next"
import {useAppDispatch} from "redux/hooks"
import InfoBox from "components/common/InfoBox"
import types from "utils/paymentTransferTypes"
import {calculateTransactionFee} from "redux/actions/pricing.actions"
import styles from "components/common/Payment/PaymentContent/PaymentContent.module.scss"
import {PaymentTransferTypePropTypes} from "proptypes/PropTypeObjects"
import {ReactComponent as ShareIcon} from "assets/icons/share.svg"

type Props = {
    paymentTransferTypeEnum?: PaymentTransferTypePropTypes;
    afterDeadline?: boolean;
    toggleSelectedPayment: (paymentType: PaymentTransferTypePropTypes, keepSelected?: boolean) => void;
    children: ReactElement;
};

const PaymentLinkBlock = ({
                              paymentTransferTypeEnum,
                              afterDeadline,
                              toggleSelectedPayment,
                              children
                          }: Props): ReactElement => {
    const {t} = useTranslation();
    const dispatch = useAppDispatch();

    return (
        <InfoBox
            style={{marginBottom: 15}}
            active={paymentTransferTypeEnum === types[afterDeadline ? "after" : "before"].paymentLink}
            title={t("pc_pc_payment_link")}
            message={t("pc_pc_payment_link_promotional_message")}
            customIcon={<ShareIcon/>}
            // miniIcon
            onClick={() => {
                toggleSelectedPayment(types[afterDeadline ? "after" : "before"].paymentLink);
                dispatch(calculateTransactionFee());

                return false;
            }}
            type="custom"
        >
            <div className={styles.Option}>
                <div className={styles.OptionText}>
                    <p>{t("pc_pc_payment_link_detailed_message_1")}</p>
                    <p>{t("pc_pc_payment_link_detailed_message_2")}</p>

                    {afterDeadline && (
                        <>
                            <p>
                                <Trans i18nKey="pc_pc_payment_link_after_cxl">
                                    <b>ATTENTION!</b> This reservation has passed free cancellation deadline,
                                    therefore the payment must be completed before we can confirm this booking.
                                    Prices and/or availability may change until then.
                                </Trans>
                            </p>

                            <p>{t("pc_pc_payment_link_detailed_message_3")}</p>
                        </>
                    )}
                </div>

                <div
                    style={{
                        marginTop: 30,
                        marginBottom: 30
                    }}
                    className="h-divider"
                />

                {children}
            </div>
        </InfoBox>
    );
}

export default PaymentLinkBlock;