import React, {ReactElement} from "react";
import cx from "classnames";
import styles from "./ActionWithIcon.module.scss";

function ActionWithIcon({
    onClick,
    className,
    title,
    icon,
    ...rest
}: any): ReactElement {
    return (
        <div {...rest} className={cx(styles.root, className)}>
            <div onClick={onClick} className={styles.wrapper}>
                <span className={styles.icon}>{icon}</span>
                <span className={styles.title}>{title}</span>
            </div>
        </div>
    );
}

export default ActionWithIcon;
