import React, {ReactElement} from "react";
import MainContainer from "components/base/MainContainer";
import PaymentSummary from "views/Hotels/Payment/PaymentSummary";
import usePaymentType from "components/utils/usePaymentType";
import {ReduxPaymentTypePropTypes} from "proptypes/PropTypeRedux";
import SplitPaymentContent from "components/common/Payment/PaymentContent/SplitPaymentContent/SplitPaymentContent";

const SplitPaymentContainer = (): ReactElement => {
    usePaymentType(ReduxPaymentTypePropTypes.HOTEL);

    return (
        <MainContainer
            sidebar={<PaymentSummary/>}
        >
            <SplitPaymentContent/>
        </MainContainer>
    );
}

export default SplitPaymentContainer;