import React, {Component, RefObject} from "react";
import cx from "classnames";
import {Trans, withTranslation, WithTranslation} from "react-i18next";
import {
    RecentSearchPropTypes,
    TransferDestinationPropTypes,
    TransferDestinationsResultPropTypes,
    TransferSearchCriteriaPropTypes
} from "proptypes/PropTypeObjects";
import Input from "../../../../components/base/Input/TextInput/Input";
import {BOOKING_REF_PATTERN} from "utils/validateForm";
import OutlineInput from "../../../../components/base/Input/TextInput/OutlinedInput";
import UserButton from "../../../../components/base/UserButton";
import {UserButtonVariant} from "components/base/UserButton/UserButton";
import Pax from "../../../../components/common/Pax";
import TimeCustomSelect from "../../../../components/base/Input/CustomReactSelect/TimeCustomSelect";
import InfoBox from "../../../../components/common/InfoBox";
import scrollToFormError from "../../../../utils/scrollToFormError";
import Tippy from "../../../../components/common/Tippy/Tippy";
import CustomCheckBox from "../../../../components/base/Input/CustomCheckBox"
import SingleDatePicker from "../../../../components/base/Input/DatePicker/SingleDatePicker/SingleDatePicker"
import {DateTime} from "luxon"
import createLuxonDate from "../../../../utils/date/createLuxonDate"
import {dateWithSetTime} from "utils/dateUtils"
import StandaloneDropdownWithSearch, {
    DropdownWithSearchOption
} from "components/base/Input/DropdownWithSearch/StandaloneDropdownWithSearch";
import TransferDestinationInput from "components/common/TransferDestinationInput/TransferDestinationInput";
import styles from "./SearchBox.module.scss";

type Props = WithTranslation & {
    data: {
        formData: TransferSearchCriteriaPropTypes;
        error?: string;
        airportInput?: string;
        venueInput?: string;
        bookRoundtrip?: boolean;
        arrivalTime: string;
        departureTime: string;
        adults: number;
        recentSearches?: RecentSearchPropTypes[];
        transferDestinations?: TransferDestinationsResultPropTypes;
        transferOrigins?: TransferDestinationsResultPropTypes;
        children: number[];

        currentOrigin?: TransferDestinationPropTypes;
        currentDestination?: TransferDestinationPropTypes;
    };
    handleInputChange: (...args: any[]) => any;
    handleDestination: (...args: any[]) => any;
    handleOrigin: (...args: any[]) => any;
    handleArrivalDepartureTransfer: (...args: any[]) => any;
    handleArrivalDepartureTime: ({arrivalTime, departureTime}: { arrivalTime: string, departureTime: string }) => void;
    toggleRoundTrip: (checked: boolean, setArrivalTransfer?: boolean) => void;
    getSearchTransferDestination: (...args: any[]) => any;
    getSearchTransferOrigin: (...args: any[]) => any;
    handleAdultsChange: (...args: any[]) => any;
    handleChildrenChange: (...args: any[]) => any;
    handleRelatedBooking: (...args: any[]) => any;
    startSearchFromData: (...args: any[]) => any;
    handleSubmitSearch: (...args: any[]) => any;
};

class SearchBox extends Component<Props> {
    private originInputComponentRef: RefObject<OutlineInput>;

    constructor(props: Props) {
        super(props);
        this.originInputComponentRef = React.createRef();
    }

    handleInsertTimeToDate = ({
        momentDate,
        hoursMinutes = ""
    }: { momentDate: DateTime, hoursMinutes: string }, defaultValue: string): string => {
        const hours = hoursMinutes.toString().split(":")[0];
        const minutes = hoursMinutes.toString().split(":")[1];

        const HH = hours.length > 1 ? hours : `0${hours}`;
        const mm = minutes.length > 1 ? minutes : `0${minutes}`;

        const validTime = /^(0[0-9]|1[0-9]|2[0-3]|[0-9]):[0-5][0-9]$/.test(`${HH}:${mm}`);

        if (!validTime) {
            return defaultValue;
        }

        return dateWithSetTime(
            createLuxonDate(momentDate),
            +HH,
            +mm
        ).toISO({includeOffset: false}) as string;
    };

    handleCanBeTransferDestinationSet = (e: React.FocusEvent<HTMLInputElement>) => {
        if (!this.originInputComponentRef.current) {
            return;
        }
        const {
            data: {
                formData: {
                    originId,
                    transferOriginId
                }
            },
            getSearchTransferDestination,
            t
        } = this.props;

        //TODO fix validation
        if (transferOriginId || originId) {
            getSearchTransferDestination(e.target.value, transferOriginId || originId);
        } else {
            this.originInputComponentRef.current.updateValidity((input) => input.setCustomValidity(t("util_vf_error_select_this_before_to_continue")));
        }
    };

    handleValidationAndSubmit = (e?: React.FormEvent<HTMLFormElement>) => {
        if (!e) {
            return;
        }
        const form = e.target as HTMLFormElement;
        e.preventDefault();
        const valid = form.checkValidity();
        if (!valid) {
            scrollToFormError(form);
        }
        const {
            handleSubmitSearch
        } = this.props;

        if (valid) {
            handleSubmitSearch();
        }
    };

    handleStartDateChange = (momentDate: DateTime | null) => {
        if (!momentDate) {
            return;
        }

        const {
            data: {
                formData: {
                    arrivalTransfer,
                    departureTransfer
                },
                bookRoundtrip,
                departureTime,
                arrivalTime
            },
            handleArrivalDepartureTime
        } = this.props;

        let departure = createLuxonDate(departureTime);
        if (bookRoundtrip && !departureTransfer && departure.valueOf() <= momentDate.valueOf()) {
            departure = momentDate.plus({day: 1});
        }

        handleArrivalDepartureTime({
            arrivalTime: arrivalTransfer && arrivalTime
                ? this.handleInsertTimeToDate({
                    momentDate,
                    hoursMinutes: createLuxonDate(arrivalTime).toFormat("HH:mm")
                }, arrivalTime)
                : arrivalTime,
            departureTime: !bookRoundtrip && departureTransfer
                ? this.handleInsertTimeToDate({
                    momentDate,
                    hoursMinutes: createLuxonDate(departure).toFormat("HH:mm")
                }, departureTime)
                : departure.toISO({includeOffset: false}) as string
        });
    };

    handleDepartureTimeChanged = (momentDate: DateTime | null) => {
        if (!momentDate) {
            return;
        }

        const {
            data: {
                departureTime,
                arrivalTime
            },
            handleArrivalDepartureTime
        } = this.props;

        const arrivalDateFormed = createLuxonDate(arrivalTime);
        const departureDateFormed = createLuxonDate(departureTime);
        const departureTimeFormed = createLuxonDate(departureTime).toFormat("HH:mm");

        let arrival = arrivalTime;
        if (departureDateFormed.valueOf() <= arrivalDateFormed.valueOf()) {
            arrival = createLuxonDate(departureTime).minus({day: 1}).toISO({includeOffset: false}) as string;
        }

        handleArrivalDepartureTime({
            arrivalTime: arrival,
            departureTime: this.handleInsertTimeToDate({
                momentDate,
                hoursMinutes: departureTimeFormed
            }, departureTime)
        });
    };

    render() {
        const {
            data: {
                error,
                formData: {
                    arrivalTransfer,
                    departureTransfer,
                    originId,
                    transferOriginId,
                    relatedBooking
                },
                airportInput,
                transferOrigins,
                bookRoundtrip,
                adults,
                departureTime,
                arrivalTime,
                children,
                currentOrigin,
            },
            handleInputChange,
            handleDestination,
            handleOrigin,
            handleArrivalDepartureTransfer,
            handleArrivalDepartureTime,
            toggleRoundTrip,

            getSearchTransferDestination,
            getSearchTransferOrigin,

            handleAdultsChange,
            handleChildrenChange,
            handleRelatedBooking,
            t
        } = this.props;

        const tranferOriginsFormed = transferOrigins && transferOrigins.destinations && transferOrigins.destinations.map((value) => new DropdownWithSearchOption(
            `${value.iso ? value.iso + ", " : ""}${value.name}`,
            value,
            value.name,
            "transferOrigins"
        )
        ).filter((opt) => !!opt) as DropdownWithSearchOption<TransferDestinationPropTypes>[];

        const arrivalDateFormed = createLuxonDate(arrivalTime);
        const arrivalTimeFormed = createLuxonDate(arrivalTime).toFormat("HH:mm");

        const departureDateFormed = createLuxonDate(departureTime);
        const departureTimeFormed = createLuxonDate(departureTime).toFormat("HH:mm");

        // const listRecents = recentSearches&&recentSearches.map(
        //   ({criteria, id, destination  },i) => (criteria&&<SearchedItemsContainer
        //     onClick={()=>this.props.startSearchFromData(criteria, destination)}
        //     key={id}
        //     id={id}
        //     location={(
        //       destination.cityName || destination.countryName
        //       ? `${destination.cityName
        //           ?destination.cityName
        //           :''
        //         }${destination.countryName
        //           ?', '+destination.countryName
        //           :''}`
        //       : destination.hotelName
        //         ? destination.hotelName : ''
        //     )}
        //     rooms={criteria.rooms?criteria.rooms.length:0}
        //     paxs={criteria.rooms
        //     ?criteria.rooms.reduce((prev, curr)=>
        //     curr.adults +
        //     curr.children.length +
        //     prev, 0)
        //     :0
        //     } />
        //     )
        // ).slice(0, 4);

        return (
            <div className={styles.TransfersSearchBox}>
                <div className={styles.SearchTitle}>
                    <h1 className={styles.SearchTitleText}>{t("t_s_sb_transfers_search_box_title")}</h1>
                </div>

                <form
                    id="transfer-search-form"
                    autoComplete="off"
                    noValidate
                    onSubmit={this.handleValidationAndSubmit}
                    className={styles.SearchBox}
                >
                    {error && (
                        <InfoBox
                            type="error"
                            title={t(error)}
                            className={styles.SearchError}
                        />
                    )}
                    <div className={styles.Controls}>
                        <div
                            onClick={() => {
                                handleArrivalDepartureTransfer({
                                    arrivalTransfer: true,
                                    departureTransfer: false
                                });
                                toggleRoundTrip(false, false);
                            }}
                            className={cx(styles.Control, arrivalTransfer && styles.ControlActive)}
                        >
                            {t("t_s_sb_from_airport")}
                        </div>
                        <div
                            onClick={() => {
                                handleArrivalDepartureTransfer({
                                    arrivalTransfer: false,
                                    departureTransfer: true
                                });
                                toggleRoundTrip(false, false);
                            }}
                            className={cx(styles.Control, !bookRoundtrip && departureTransfer && styles.ControlActive)}
                        >
                            {t("t_s_sb_to_airport")}
                        </div>
                    </div>

                    <div
                        className={styles.FromAirportArrivalDateContainer}
                    >
                        <StandaloneDropdownWithSearch
                            smallFont
                            filterEmptyMatch={false}
                            // inputComponentRef={this.originInputComponentRef}
                            customErrorMessage={t("util_vf_error_not_valid_transfer_destination")}
                            className={styles.FromAirportInput}
                            inputProps={{
                                required: true,
                                name: "airportInput",
                                value: airportInput,
                                placeholder: arrivalTransfer ? t("t_s_sb_from_airport") + ":" : t("t_s_sb_to_airport") + ":",
                                onFocus: (e) => {
                                    getSearchTransferOrigin(e.target.value);
                                },
                                onChange: (e) => {
                                    handleInputChange(e);
                                    getSearchTransferOrigin(e.target.value);
                                }
                            }}
                            groups={[
                                {
                                    order: 1,
                                    name: "transferOrigins",
                                    label: arrivalTransfer
                                        ? t("t_s_sb_from_airport") + ":"
                                        : t("t_s_sb_to_airport") + ":"
                                }
                            ]}
                            onValueChange={(value: DropdownWithSearchOption<TransferDestinationPropTypes>) => {
                                handleOrigin(value.value);
                                handleInputChange({
                                    target: {
                                        name: "airportInput",
                                        value: value.label
                                    }
                                });
                                handleDestination(null);
                                handleInputChange({
                                    target: {
                                        name: "venueInput",
                                        value: null
                                    }
                                });
                            }}
                            options={tranferOriginsFormed || []}
                            currentValue={currentOrigin}
                        />

                        <Tippy
                            content={(
                                <span>{t("t_s_sb_arrival_flight_tooltip")}</span>
                            )}
                            delay={[1000, 0]}
                            hideOnClick
                        >
                            <div className={styles.ArrivalDateContainer}>
                                <SingleDatePicker
                                    label={arrivalTransfer
                                        ? t("t_s_sb_arrival_date")
                                        : t("t_s_sb_departure_date")}
                                    placeholder={arrivalTransfer
                                        ? t("t_s_sb_arrival_date")
                                        : t("t_s_sb_departure_date")}
                                    handleDateChange={this.handleStartDateChange}
                                    date={arrivalTransfer ? arrivalDateFormed : departureDateFormed}
                                    validFrom={createLuxonDate().plus({day: 1})}
                                    className={styles.ArrivalDate}
                                />

                                {/*<DateRangeInput*/}
                                {/*    label={arrivalTransfer*/}
                                {/*        ? t("t_s_sb_arrival_date")*/}
                                {/*        : t("t_s_sb_departure_date")}*/}
                                {/*    startDatePlaceholderText={arrivalTransfer*/}
                                {/*        ? t("t_s_sb_arrival_date")*/}
                                {/*        : t("t_s_sb_departure_date")}*/}
                                {/*    startDate={}*/}
                                {/*    withDateRange={false}*/}
                                {/*    double={false}*/}
                                {/*    onStartDateChange={}*/}
                                {/*    className={styles.ArrivalDate}*/}
                                {/*    startDateIsOutsideRange={(day) => moment().isSameOrAfter(day)}*/}
                                {/*/>*/}

                                <TimeCustomSelect
                                    required={true}
                                    hideErrors={true}
                                    className={styles.ArrivalTime}
                                    transferInput={true}
                                    hoursMinutes={
                                        arrivalTransfer
                                            ? arrivalTimeFormed
                                            : departureTimeFormed
                                    }
                                    onChange={(hoursMinutes: string) => handleArrivalDepartureTime({
                                        arrivalTime: arrivalTransfer
                                            ? this.handleInsertTimeToDate({
                                                momentDate: arrivalDateFormed,
                                                hoursMinutes
                                            }, arrivalTime)
                                            : arrivalTime,
                                        departureTime: !bookRoundtrip && departureTransfer
                                            ? this.handleInsertTimeToDate({
                                                momentDate: departureDateFormed,
                                                hoursMinutes
                                            }, departureTime)
                                            : departureTime
                                    })}
                                />
                            </div>
                        </Tippy>
                    </div>

                    <div className={styles.ToVenuePaxContainer}>
                        <TransferDestinationInput
                            handleCanBeTransferDestinationSet={(e: React.FocusEvent<HTMLInputElement, Element>) => this.handleCanBeTransferDestinationSet(e)}
                        />

                        {bookRoundtrip && (
                            <Tippy
                                content={(
                                    <span>{t("t_s_sb_departure_flight_tooltip")}</span>
                                )}
                                delay={[1000, 0]}
                                hideOnClick
                            >
                                <div className={styles.DepartureTimeContainer}>
                                    <SingleDatePicker
                                        label={t("t_s_sb_departure_date")}
                                        placeholder={t("t_s_sb_departure_date")}
                                        handleDateChange={this.handleDepartureTimeChanged}
                                        date={departureDateFormed}
                                        validFrom={arrivalDateFormed.plus({day: 1})}
                                        className={styles.DepartureDate}
                                    />

                                    {/*<DateRangeInput*/}
                                    {/*    label={t("t_s_sb_departure_date")}*/}
                                    {/*    startDatePlaceholderText={t("t_s_sb_departure_date")}*/}
                                    {/*    startDate={departureDateFormed}*/}
                                    {/*    withDateRange={false}*/}
                                    {/*    double={false}*/}
                                    {/*    onStartDateChange={this.handleDepartureTimeChanged}*/}
                                    {/*    className={styles.DepartureDate}*/}
                                    {/*    startDateIsOutsideRange={(day) => moment(day).isSameOrBefore(arrivalDateFormed)}*/}
                                    {/*/>*/}

                                    <TimeCustomSelect
                                        required={true}
                                        hideErrors={true}
                                        transferInput={true}
                                        hoursMinutes={departureTimeFormed}
                                        onChange={(hoursMinutes: string) => {
                                            handleArrivalDepartureTime({
                                                arrivalTime,
                                                departureTime: this.handleInsertTimeToDate({
                                                    momentDate: departureDateFormed,
                                                    hoursMinutes
                                                }, departureTime)
                                            });
                                        }}
                                        className={styles.DepartureTime}
                                    />
                                </div>
                            </Tippy>
                        )}

                        {!bookRoundtrip && (
                            <Pax
                                data={{
                                    children,
                                    adults,
                                    pax: Number(adults) + Number(children.length)
                                }}
                                handleAdultsChange={handleAdultsChange}
                                handleChildrenChange={handleChildrenChange}
                                className={styles.Pax}
                            />
                        )}
                    </div>

                    <div className={styles.RoundTripContainer}>
                        <div className={styles.Toggle}>
                            <CustomCheckBox
                                className="rsbc--small"
                                inputProps={{
                                    name: "roundTripToggle",
                                    checked: bookRoundtrip,
                                    onChange: (evt) => toggleRoundTrip(evt.target.checked)
                                }}
                                label={t("t_s_sb_round_trip")}
                            />
                        </div>

                        {bookRoundtrip && (
                            <Pax
                                data={{
                                    children,
                                    adults,
                                    pax: Number(adults) + Number(children.length)
                                }}
                                handleAdultsChange={handleAdultsChange}
                                handleChildrenChange={handleChildrenChange}
                                className={styles.Pax}
                            />
                        )}
                    </div>

                    <div className={styles.SearchBottom}>
                        <Input
                            variants={{labelPosition: "outlined"}}
                            className={styles.BookingRef}
                            inputProps={{
                                onChange: (e) => handleRelatedBooking(e.target.value),
                                type: "text",
                                autoComplete: "on",
                                name: "booking-ref",
                                pattern: BOOKING_REF_PATTERN,
                                value: relatedBooking || undefined,
                                placeholder: t("t_s_sb_bookingRef_placeholder")
                            }}
                        />

                        <p className={styles.Offer}>
                            <Trans i18nKey="t_s_sb_transfers_search_box_offer" values={{percent: 10}}>
                                <span className="green-color">Get 10% off</span> for a transfer to accommodation
                                (hotel, apartment, etc.) booked via Hotelston
                            </Trans>
                        </p>

                        {/*<CustomCheckBox*/}
                        {/*  label={(<span className="secondary-text">{t("t_s_sb_save_search_for_later")}</span>)}*/}
                        {/*  inputProps={{*/}
                        {/*      name: "saveSearch",*/}
                        {/*      checked: saveSearch,*/}
                        {/*      onChange: handleSaveSearch*/}
                        {/*  }}*/}
                        {/*/>*/}

                        <UserButton
                            searchButton
                            text={t("t_s_sb_search_transfers_button")}
                            variant={UserButtonVariant.PRIMARY}
                            className={styles.UserButton}
                            buttonProps={{
                                type: "submit"
                            }}
                        />
                    </div>
                </form>
            </div>
        );
    }
}

export default withTranslation()(SearchBox);
