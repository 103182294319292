import _ from "lodash";
import {HotelSearchResultsFiltersPropTypes} from "../../../proptypes/PropTypeObjects";
import {
    getUrlParamArray,
    getUrlParamPrimitive,
    POSSIBLE_ARRAY_PARAM_RESOLVER,
    setUrlParamArray, setUrlParamPrimitive
} from "../queryParamsUtils";
import {QueryParamSerializerDeserializerResolver, QueryParamsObject} from "../queryParamsTypes";
import {emptyReviewRatingFilter} from "../../../redux/reducers/hotelSearchResults.reducer";

function getReviewRatingFilter(query: QueryParamsObject) {
    const min = getUrlParamPrimitive(query.frrmi);
    const max = getUrlParamPrimitive(query.frrma);

    const sliderEnabled = !!min && !!max;

    return {
        fromRating: getUrlParamArray(query, "frr"),
        range: sliderEnabled ? {
            min: min,
            max: max
        } : emptyReviewRatingFilter.range,
        slider: sliderEnabled
    };
}

const FilterSerializer: QueryParamSerializerDeserializerResolver = {
    serializer: (query: QueryParamsObject, param: string, object: HotelSearchResultsFiltersPropTypes) => {
        setUrlParamPrimitive(query, param + "hn", _.toString(object.hotelNameFilter));
        setUrlParamPrimitive(query, param + "pi", _.toString(object.offerProviderFilter));
        setUrlParamPrimitive(query, param + "rt", _.toString(object.roomTypeFilter));
        setUrlParamPrimitive(query, param + "pmi", _.toString(object.priceFilter?.min));
        setUrlParamPrimitive(query, param + "pma", _.toString(object.priceFilter?.max));
        setUrlParamArray(query, param + "bt", object.boardTypeFilter);
        setUrlParamArray(query, param + "hc", object.hotelCategoryFilter);
        setUrlParamArray(query, param + "pt", object.propertyTypeFilter);

        if (object.reviewRatingFilter.slider) {
            setUrlParamPrimitive(query, param + "rrmi", _.toString(object.reviewRatingFilter.range.min));
            setUrlParamPrimitive(query, param + "rrma", _.toString(object.reviewRatingFilter.range.max));
            setUrlParamArray(query, param + "rr", []);
        } else {
            setUrlParamPrimitive(query, param + "rrmi", "");
            setUrlParamPrimitive(query, param + "rrma", "");
            setUrlParamArray(query, param + "rr", object.reviewRatingFilter.fromRating);
        }

        setUrlParamArray(query, param + "f", object.featuresFilter);
        setUrlParamPrimitive(query, param + "so", _.toString(object.specialOffersFilter));
        setUrlParamPrimitive(query, param + "mo", _.toString(object.memberOnlyFilter));
        setUrlParamPrimitive(query, param + "og", _.toString(object.onlyGoodCXLFilter));
        setUrlParamPrimitive(query, param + "or", _.toString(object.onlyRefundableFilter));
        setUrlParamPrimitive(query, param + "onr", _.toString(object.onlyNonRefundableFilter));
    },

    resolver: POSSIBLE_ARRAY_PARAM_RESOLVER(["hn", "pi", "rt", "pmi", "pma", "bt", "hc", "pt", "rr", "f", "so", "mo", "og", "or", "onr"], "f"),
    deserializer: (query: QueryParamsObject) => ({
        hotelNameFilter: getUrlParamPrimitive(query.fhn),
        offerProviderFilter: getUrlParamPrimitive(query.fpi),
        roomTypeFilter: getUrlParamPrimitive(query.frt),
        priceFilter: {
            min: getUrlParamPrimitive(query.fpmi),
            max: getUrlParamPrimitive(query.fpma)
        } as { min: number; max: number; },
        boardTypeFilter: getUrlParamArray(query, "fbt"),
        hotelCategoryFilter: getUrlParamArray(query, "fhc"),
        propertyTypeFilter: getUrlParamArray(query, "fpt"),
        reviewRatingFilter: getReviewRatingFilter(query),
        featuresFilter: getUrlParamArray(query, "ff"),
        specialOffersFilter: getUrlParamPrimitive(query.fso),
        memberOnlyFilter: getUrlParamPrimitive(query.fmo),
        onlyGoodCXLFilter: getUrlParamPrimitive(query.fog),
        onlyRefundableFilter: getUrlParamPrimitive(query.for),
        onlyNonRefundableFilter: getUrlParamPrimitive(query.fonr)
    } as HotelSearchResultsFiltersPropTypes),
    array: false
};

export default FilterSerializer;