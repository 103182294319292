import React, {
    ChangeEvent, ReactElement, useCallback, useEffect, useRef, useState
} from "react";
import cx from "classnames";
import {useTranslation} from "react-i18next";
import styles from "./FeaturesSearch.module.scss";
import useClickOutside from "../../../../utils/hooks/useClickOutside";
import OutlineInput from "../../../../components/base/Input/TextInput/OutlinedInput";

export type FeaturesSearchProps = {
    handleInput?: (newVal: ChangeEvent<HTMLInputElement>) => void;
    input?: string;
    inputName?: string;

    children?: Element | (false | ReactElement)[];
    className?: string;
};

const FeaturesSearch = ({
    handleInput,
    input,
    inputName,
    children,
    className
}: FeaturesSearchProps): ReactElement => {
    const {t} = useTranslation();

    return (
        <div className={cx(styles.Root, className)}>
            {/*{inputOpen && (*/}
            {/*    <Input*/}
            {/*        className={cx(styles.FeatureInput)}*/}
            {/*        variants={{ labelPosition: "inside" }}*/}
            {/*        fullHeight={false}*/}
            {/*        inputProps={{*/}
            {/*            autoFocus: true,*/}
            {/*            onChange: (e) => {*/}
            {/*                if (handleInput) {*/}
            {/*                    handleInput(e);*/}
            {/*                }*/}
            {/*            },*/}
            {/*            type: "text",*/}
            {/*            name: inputName,*/}
            {/*            value: input*/}
            {/*        }}*/}
            {/*    />*/}
            {/*)}*/}

            <OutlineInput
                inputProps={{
                    placeholder: t("h_h_h_search"),
                    value: input,
                    onChange: handleInput,
                    name: inputName
                }}
                labelPosition="outlined"
                helperAndErrorTextPosition="bottom"
            />

            {/*<a onClick={onClickCallback}>*/}
            {/*    <span>{t("h_h_h_search")}</span>*/}

            {/*    <SearchIcon />*/}
            {/*</a>*/}
        </div>
    );
};

export default FeaturesSearch;