import React, {ReactElement} from "react";
import introImg from "assets/photos/transfer-landing.png";
import PageIntro from "../../../components/common/PageIntro";
import SearchBoxContainer from "./SearchBox";
// saved box
const Search = (props: any): ReactElement => (
    <>
        <PageIntro introImg={introImg} style={{backgroundPositionY: "95%"}} />
        <SearchBoxContainer />
        {/* <SavedBoxContainer />   */}
    </>
);

export default Search;
