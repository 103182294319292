import React, {ReactElement} from "react"
import {useTranslation} from "react-i18next"
import Pricing from "components/common/Pricing/Pricing"
import CreditLineBlock from "./CreditLineBlock"
import {useCompanyCurrency, useCurrency} from "components/utils/withLocalesAndCurrencies"
import types from "utils/paymentTransferTypes"
import {SharedPaymentTypeProps} from "../SharedPaymentTypeProps"
import usePaymentOptions from "../usePaymentOptions"

export type CreditLineProps = SharedPaymentTypeProps;

const CreditLine = (props: CreditLineProps): ReactElement => {
    const {
        toggleSelectedPayment,
        paymentTransferTypeEnum
    } = props;

    const {t} = useTranslation();
    const currency = useCurrency();
    const companyCurrency = useCompanyCurrency();

    const {
        paymentOptions,
        discount,
        voucherSum,
        markup
    } = usePaymentOptions();

    const creditLine = paymentOptions?.paymentTransferTypes.includes(types[paymentOptions?.afterDeadline ? "after" : "before"].creditLine);
    if (!paymentOptions || !creditLine) {
        return (
            <></>
        );
    }

    const {
        paymentTransferTypes,
        canUseCreditLine,
        afterDeadline,
        allowToBook,
        sanctionedCompany,
        salePrice,
        displaySalePrice,
        smsFee,
        displaySmsFee,
        paymentPending,

        creditLineAmountBefore,
        creditLineAmountAfter,
        creditLineConfirmationDate,
        creditLineConfirmationTime,

        instantPaymentAfterCXLAvailable
    } = paymentOptions;

    return (
        <CreditLineBlock
            key="credit-line"
            paymentTransferTypeEnum={paymentTransferTypeEnum}
            afterDeadline={afterDeadline}
            toggleSelectedPayment={toggleSelectedPayment}
            creditLineAmountBefore={creditLineAmountBefore}
            creditLineAmountAfter={creditLineAmountAfter}
            creditLineConfirmationTime={creditLineConfirmationTime}
            creditLineConfirmationDate={creditLineConfirmationDate}
            instantPaymentAfterCXLAvailable={instantPaymentAfterCXLAvailable}
            paymentPending={paymentPending}
            canUseCreditLine={canUseCreditLine}
            paymentTransferTypes={paymentTransferTypes}
        >
            <Pricing
                withButton
                customButtonLabel={t("pc_pc_pay_now")}
                disabledButton={!canUseCreditLine || !allowToBook || sanctionedCompany}
                greyLabels
                salePrice={salePrice}
                displaySalePrice={displaySalePrice}
                smsFee={smsFee}
                displaySmsFee={displaySmsFee}
                discount={discount}
                voucher={voucherSum.used}
                paymentPending={paymentPending}
            />
        </CreditLineBlock>
    );
}

export default CreditLine;