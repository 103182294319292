export const FALLBACK_DATE_FORMAT = "yyyy-MM-dd";
export const FALLBACK_TIME_FORMAT = "HH:mm";
export const FALLBACK_DATE_TIME_FORMAT = "yyyy-MM-dd HH:mm";
export const FALLBACK_DATE_TIME_SECONDS_FORMAT = "yyyy-MM-dd HH:mm:ss";

export const LOCALE_DATE_FORMAT = new Map<string, string>([
    ["en", "yyyy-MM-dd"],
    ["it", "dd/MM/yyyy"],
    ["ru", "yyyy-MM-dd"],
    ["pl", "yyyy-MM-dd"],
    ["lt", "yyyy-MM-dd"],
    ["lv", "dd/MM/yyyy"],
    ["ee", "dd.MM.yyyy"],
    ["fi", "d.M.yyyy"],
    ["bg", "dd.MM.yyyy"],
    ["fr", "dd/MM/yyyy"],
    ["es", "dd/MM/yyyy"],
    ["se", "yyyy-MM-dd"],
    ["dk", "yyyy-MM-dd"],
    ["gr", "d/M/yy"]
]);

export const LOCALE_TIME_FORMAT = new Map<string, string>([
    ["en", "HH:mm"],
    ["it", "HH:mm"],
    ["ru", "HH:mm"],
    ["pl", "HH:mm"],
    ["lt", "HH:mm"],
    ["lv", "HH:mm"],
    ["ee", "HH:mm"],
    ["fi", "HH:mm"],
    ["bg", "HH:mm"],
    ["fr", "HH:mm"],
    ["es", "HH:mm"],
    ["se", "HH:mm"],
    ["dk", "HH:mm"],
    ["gr", "HH:mm"]
]);

export const LOCALE_DATE_TIME_FORMAT = new Map<string, string>([
    ["en", "yyyy-MM-dd HH:mm"],
    ["it", "dd/MM/yyyy HH:mm"],
    ["ru", "yyyy-MM-dd HH:mm"],
    ["pl", "yyyy-MM-dd HH:mm"],
    ["lt", "yyyy-MM-dd HH:mm"],
    ["lv", "dd.MM.yyyy HH:mm"],
    ["ee", "dd.MM.yyyy HH:mm"],
    ["fi", "d.M.yyyy HH:mm"],
    ["bg", "dd.MM.yyyy HH:mm"],
    ["fr", "dd/MM/yyyy HH:mm"],
    ["es", "dd/MM/yyyy HH:mm"],
    ["se", "yyyy-MM-dd HH:mm"],
    ["dk", "yyyy-MM-dd HH:mm"],
    ["gr", "dd/M/yy HH:mm"]
]);

export function getDateFormatByLocale(locale: string): string {
    return LOCALE_DATE_FORMAT.get(locale) || FALLBACK_DATE_FORMAT;
}

export function getTimeFormatByLocale(locale: string): string {
    return LOCALE_TIME_FORMAT.get(locale) || FALLBACK_TIME_FORMAT;
}

export function getDateTimeFormatByLocale(locale: string): string {
    return LOCALE_DATE_TIME_FORMAT.get(locale) || FALLBACK_DATE_TIME_FORMAT;
}
