import RouterTypes from "../constants/router.constants";
import {ReduxRouterPropTypes} from "../../proptypes/PropTypeRedux";
import RouterActions, {LastLocationKey, LocationBaseKey} from "./actions/router.reducer.actions";

export const INITIAL_STATE = {
    noGoBackPaths: [
        "/booking/:status"
    ],
    specialPaths: {
        "/payment/:status": {
            withQueryParams: true
        },
        "/booking/:status": {
            withQueryParams: true
        },
        "/hotels/search": {
            withQueryParams: true
        },
        "/transfers/search": {
            withQueryParams: true
        },
        "/my-bookings/booking/:id/overpayment": {
            baseKey: LocationBaseKey.MY_BOOKINGS,
            lastLocationKey: LastLocationKey.LAST_BOOKING_LOCATION
        },
        "/my-bookings/booking/:id/overpayment/reconcile": {
            baseKey: LocationBaseKey.MY_BOOKINGS,
            lastLocationKey: LastLocationKey.LAST_BOOKING_LOCATION
        },
        "/my-bookings/booking/:id/amendments/transfer": {
            baseKey: LocationBaseKey.MY_BOOKINGS,
            lastLocationKey: LastLocationKey.LAST_BOOKING_LOCATION
        },
        "/my-bookings/booking/:id/amendments/hotel": {
            baseKey: LocationBaseKey.MY_BOOKINGS,
            lastLocationKey: LastLocationKey.LAST_BOOKING_LOCATION
        },
        "/my-bookings/booking/:id": {
            baseKey: LocationBaseKey.MY_BOOKINGS,
            lastLocationKey: LastLocationKey.LAST_SEARCH_RESULTS_LOCATION
        },
        "/my-bookings/payment": {
            baseKey: LocationBaseKey.MY_BOOKINGS,
            lastLocationKey: LastLocationKey.LAST_BOOKING_LOCATION
        },
        "/my-bookings/booking/:id/payment": {
            baseKey: LocationBaseKey.MY_BOOKINGS,
            lastLocationKey: LastLocationKey.LAST_BOOKING_LOCATION
        },
        "/my-company/user/:id": {
            baseKey: LocationBaseKey.MY_COMPANY,
            lastLocationKey: LastLocationKey.LAST_MY_COMPANY_LOCATION
        },
        "/my-company/agreement/:id": {
            baseKey: LocationBaseKey.MY_COMPANY,
            lastLocationKey: LastLocationKey.LAST_MY_COMPANY_LOCATION
        },
        "/hotels/booking/changed": {
            baseKey: LocationBaseKey.HOTELS,
            lastLocationKey: LastLocationKey.LAST_SEARCH_RESULTS_LOCATION
        },
        "/hotels/booking/payment": {
            baseKey: LocationBaseKey.HOTELS,
            lastLocationKey: LastLocationKey.LAST_SEARCH_RESULTS_LOCATION
        },
        "/hotels/booking/confirm": {
            baseKey: LocationBaseKey.HOTELS,
            lastLocationKey: LastLocationKey.LAST_BOOKING_LOCATION
        },
        "/hotels/booking/confirmed": {
            baseKey: LocationBaseKey.HOTELS,
            lastLocationKey: LastLocationKey.LAST_SEARCH_RESULTS_LOCATION
        },
        "/hotels/booking/canceled": {
            baseKey: LocationBaseKey.HOTELS,
            lastLocationKey: LastLocationKey.LAST_SEARCH_RESULTS_LOCATION
        },
        "/hotels/hotel/:id": {
            withQueryParams: true,
            baseKey: LocationBaseKey.HOTELS,
            lastLocationKey: LastLocationKey.LAST_SEARCH_RESULTS_LISTING_LOCATION
        },
        "/hotels/booking": {
            baseKey: LocationBaseKey.HOTELS,
            lastLocationKey: LastLocationKey.LAST_SEARCH_RESULTS_LOCATION
        },
        "/transfers/booking/changed": {
            baseKey: LocationBaseKey.TRANSFERS,
            lastLocationKey: LastLocationKey.LAST_SEARCH_RESULTS_LOCATION
        },
        "/transfers/booking/payment": {
            baseKey: LocationBaseKey.TRANSFERS,
            lastLocationKey: LastLocationKey.LAST_SEARCH_RESULTS_LOCATION
        },
        "/transfers/booking/confirm": {
            baseKey: LocationBaseKey.TRANSFERS,
            lastLocationKey: LastLocationKey.LAST_BOOKING_LOCATION
        },
        "/transfers/booking/confirmed": {
            baseKey: LocationBaseKey.TRANSFERS,
            lastLocationKey: LastLocationKey.LAST_SEARCH_RESULTS_LOCATION
        },
        "/transfers/booking/canceled": {
            baseKey: LocationBaseKey.TRANSFERS,
            lastLocationKey: LastLocationKey.LAST_SEARCH_RESULTS_LOCATION
        },
        "/transfers/booking": {
            baseKey: LocationBaseKey.TRANSFERS,
            lastLocationKey: LastLocationKey.LAST_SEARCH_RESULTS_LOCATION
        },
        "/contacts/payment": {
            baseKey: LocationBaseKey.CONTACTS,
            lastLocationKey: LastLocationKey.LAST_CONTACTS_LOCATION
        }
    },
    disabledNav: false,
    lastLocations: {
        hotels: {
            lastSearchResultsLocation: undefined,
            lastSearchResultsListingLocation: undefined,
            lastBookingLocation: {
                pathname: "/hotels/booking",
                hash: "",
                search: ""
            }
        },
        transfers: {
            lastSearchResultsLocation: undefined,
            lastSearchResultsListingLocation: undefined,
            lastBookingLocation: {
                pathname: "/transfers/booking",
                hash: "",
                search: ""
            }
        },
        myBookings: {
            lastSearchResultsLocation: {
                pathname: "/my-bookings",
                hash: "",
                search: ""
            },
            lastBookingLocation: undefined
        },
        myCompany: {
            lastMyCompanyLocation: {
                pathname: "/my-company",
                hash: "",
                search: ""
            }
        }
    }
} as ReduxRouterPropTypes;

export default (state = INITIAL_STATE, action: RouterActions): ReduxRouterPropTypes => {
    switch (action.type) {
    case RouterTypes.SET_LAST_LOCATION:
        return {
            ...state,
            lastLocations: {
                ...state.lastLocations,
                [action.baseKey]: {
                    ...state.lastLocations[action.baseKey],
                    [action.lastLocationKey]: action.location
                }
            }
        };
    case RouterTypes.TOGGLE_DISABLED_NAV:
        return {
            ...state,
            disabledNav: action.disabledNav
        };
    default:
        return state;
    }
};
