import React, {ReactElement} from "react";
import {useTranslation} from "react-i18next";
import cx from "classnames";
import {ContactInfoPropTypes, ContactPersonPropTypes} from "../../../proptypes/PropTypeObjects";
import styles from "./ContactPersonBox.module.scss";

type Props = {
    contactInfo: ContactPersonPropTypes | ContactInfoPropTypes;
};

function ContactPersonBox({contactInfo}: Props): ReactElement {
    const {t} = useTranslation();

    return (
        <div className={cx(styles.Root)}>
            <p className={styles.Heading}>{t("cpb_cpb_contact_person")}</p>

            <dl>
                <dt>
                    {t("cpb_cpb_name")}:
                </dt>
                <dd className="capitalize">
                    {contactInfo.firstname}{" "}
                    {contactInfo.lastname}
                </dd>

                <dt>{t("cpb_cpb_email")}:</dt>
                <dd><a href={`mailto:${contactInfo.email}`}>{contactInfo.email}</a></dd>

                <dt>{t("cpb_cpb_phone")}:</dt>
                <dd>{contactInfo.phone}</dd>
            </dl>
        </div>
    );
}
export default ContactPersonBox;
