import React, {
    ChangeEvent, ReactElement, useCallback, useEffect, useMemo, useReducer, useState
} from "react";
import UserButton from "components/base/UserButton";
import InfoBox from "components/common/InfoBox";
import Amendments from "components/common/Amendments";
import Header from "components/common/Amendments/Header";
import {shallowEqual} from "react-redux";
import {getBooking, updateBooking} from "redux/actions/bookingDetails.actions";
import * as amendmentsAPI from "api/amendmentsAPI";
import isEqual from "lodash/isEqual";
import isEmpty from "lodash/isEmpty";
import omit from "lodash/omit";
import {useTranslation} from "react-i18next";
import Box from "components/common/Amendments/Box";
import Input from "components/base/Input";
import ActionsWrapper from "components/common/Amendments/ActionsWrapper";
import withConfirmation, {WithConfirmation} from "components/utils/withConfirmation";
import useIsMounted from "@hotelston_web_frontend_components/hooks/detection/useIsMounted";
import {cloneDeep} from "lodash/fp";
import _ from "lodash";
import ItemsWrapper from "components/common/Amendments/ItemsWrapper";
import Item from "components/common/Amendments/Item";
import cx from "classnames";
import {AdultsInput, ChildrenInput, LeadPassengerInputs} from "./PassengerInputs";
import DateWithTime from "./DateWithTime";
import Changes from "./Changes";
import titles from "../../../assets/json/titles.json";
import {WebTransferAmendRequestAmendments, WebTransferBookingDetails} from "proptypes/PropTypeObjects";
import {useAppDispatch, useAppSelector} from "redux/hooks";
import {UserButtonVariant} from "components/base/UserButton/UserButton";
import styles from "./TransferAmendments.module.scss";
import {useNavigate, useParams} from "react-router-dom";
import {globalAxiosController} from "api/axios/globalAxiosController";
import Currency from "@hotelston_web_frontend_parent/constants/currency";
import SmsLanguage from "views/Transfers/Booking/SmsLanguage";

function selectChangedState(currentState: WebTransferAmendRequestAmendments, prevState: WebTransferAmendRequestAmendments) {
    return Object.keys(currentState)
        .reduce((obj, key) => ({
            ...obj,
            ...(!isEqual(currentState[key], prevState[key]) && {
                [key]: currentState[key]
            })
        }), {});
}

function selectBoxState(state: WebTransferAmendRequestAmendments | undefined, name: string): WebTransferAmendRequestAmendments {
    if (!state) {
        return {};
    }

    const {
        ADULTS,
        AIRLINES_PROVIDER_ARRIVAL,
        AIRLINES_PROVIDER_DEPARTURE,
        CHILDREN,
        FLIGHT_CODE_ARRIVAL,
        FLIGHT_CODE_DEPARTURE,
        FLIGHT_DATETIME_ARRIVAL,
        FLIGHT_DATETIME_DEPARTURE,
        HOTEL_ADDRESS,
        HOTEL_NAME,
        IATA_ARRIVAL,
        IATA_DEPARTURE,
        PAX,
        SMS_ORDERED,
        SMS_PHONE_NUMBER,
        SMS_LANGUAGE
    } = state;

    switch (name) {
    case "HOTEL_INFO":
        return {
            HOTEL_ADDRESS,
            HOTEL_NAME
        };
    case "PASSENGERS":
        return {
            PAX,
            ADULTS,
            CHILDREN
        };
    case "ARRIVAL_FLIGHT":
        return {
            IATA_ARRIVAL,
            FLIGHT_CODE_ARRIVAL,
            AIRLINES_PROVIDER_ARRIVAL,
            FLIGHT_DATETIME_ARRIVAL
        };
    case "DEPARTURE_FLIGHT":
        return {
            IATA_DEPARTURE,
            FLIGHT_CODE_DEPARTURE,
            AIRLINES_PROVIDER_DEPARTURE,
            FLIGHT_DATETIME_DEPARTURE
        };
    case "SMS_REMINDER":
        return {
            SMS_ORDERED,
            SMS_PHONE_NUMBER,
            SMS_LANGUAGE
        }

    default:
        return {};
    }
}

type State = {
    requesting: boolean;
    amendments: WebTransferAmendRequestAmendments;
    temp: WebTransferAmendRequestAmendments;
    beforeChanges: WebTransferAmendRequestAmendments | undefined;
    changes: WebTransferAmendRequestAmendments | undefined;
    editingBoxes: string[];
    errors?: string[];
};

type Action =
    | {type: "REQ_TYPES"}
    | {type: "REQ_TYPES_SUCCESS", amendments: WebTransferAmendRequestAmendments, beforeChanges: WebTransferAmendRequestAmendments;}
    | {type: "REQ_TYPES_FAILURE", errors: string[];}
    | {type: "ON_EDIT", boxName: string;}
    | {type: "ON_CANCEL", boxName: string;}
    | {type: "ON_INPUT", name: string, value: string | number | number[], boxName: string}
    | {type: "HANDLE_PAX", name: string, value: string;}
    | {type: "SET_ERROR", errors: string[];}
;

const reducer = (state: State, action: Action): State => {
    switch (action.type) {
    case "REQ_TYPES":
        return {
            ...state,
            errors: undefined,
            requesting: true
        };
    case "REQ_TYPES_SUCCESS":
        return {
            ...state,
            requesting: false,
            amendments: {
                ...action.amendments,
                FLIGHT_DATETIME_ARRIVAL: action.amendments ? action.amendments.FLIGHT_DATETIME_ARRIVAL : undefined,
                FLIGHT_DATETIME_DEPARTURE: action.amendments ? action.amendments.FLIGHT_DATETIME_DEPARTURE : undefined
            },
            beforeChanges: {
                ...action.beforeChanges,
                FLIGHT_DATETIME_ARRIVAL: action.amendments ? action.beforeChanges.FLIGHT_DATETIME_ARRIVAL : undefined,
                FLIGHT_DATETIME_DEPARTURE: action.amendments ? action.beforeChanges.FLIGHT_DATETIME_DEPARTURE : undefined
            },
            changes: undefined,
            temp: {}
        };
    case "REQ_TYPES_FAILURE":
        return {
            ...state,
            requesting: false,
            errors: action.errors,
            changes: undefined,
            temp: {}
        };
    case "ON_EDIT": {
        const selectedState = selectBoxState(state.amendments, action.boxName);

        return {
            ...state,
            editingBoxes: [...state.editingBoxes, action.boxName],
            temp: {
                ...state.temp,
                ...selectedState,
                CHILDREN: action.boxName === "PASSENGERS" ? selectedState.CHILDREN?.slice(0) || [] : state.temp.CHILDREN
            }
        };
    }
    case "ON_CANCEL": {
        const temp = omit(state.temp, Object.keys(selectBoxState(state.temp, action.boxName)));

        if (state.changes) {
            const toNullValues = selectBoxState(state.beforeChanges, action.boxName);
            Object.keys(toNullValues).forEach((key) => {
                delete state.changes[key];
            });
        }

        return {
            ...state,
            editingBoxes: state.editingBoxes.filter((bn) => bn !== action.boxName),
            temp
        };
    }
    case "ON_INPUT": {
        const changes = selectChangedState(
            selectBoxState({
                ...state.temp,
                [action.name]: action.value
            }, action.boxName),
            selectBoxState(state.beforeChanges, action.boxName)
        );

        let newChanges = cloneDeep(state.changes);
        if (newChanges && isEmpty(changes)) {
            const toNullValues = selectBoxState(state.beforeChanges, action.boxName);
            Object.keys(toNullValues).forEach((key) => {
                if (!newChanges) {
                    return;
                }

                delete newChanges[key];
            });
        } else {
            newChanges = {
                ...newChanges,
                ...changes
            };
        }

        return {
            ...state,
            // amendments: { ...state.amendments, ...changes },
            temp: {
                ...state.temp,
                [action.name]: action.value
            },
            changes: newChanges
        };

        // return {
        //     ...state,
        //     temp: {
        //         ...state.temp,
        //         [action.name]: action.value
        //     }
        // };
    }
    case "HANDLE_PAX":
        return {
            ...state,
            temp: {
                ...state.temp,
                PAX: {
                    ...(state.temp && state.temp.PAX),
                    [action.name]: action.value
                } as {title: string, name: string, surname: string}
            },
            changes: {
                ...state.changes,
                PAX: {
                    ...(state.changes && state.changes.PAX),
                    [action.name]: action.value
                } as {title: string, name: string, surname: string}
            }
        };
    case "SET_ERROR":
        return {
            ...state,
            requesting: false,
            errors: action.errors
        };
    default:
        return state;
    }
};

const initialState = {
    requesting: true,
    amendments: {},
    temp: {},
    beforeChanges: undefined,
    changes: undefined,
    editingBoxes: [],
    errors: undefined
};

type Props = WithConfirmation;

export function TransferAmendments({setModal}: Props): ReactElement {
    const {t} = useTranslation();

    const {id} = useParams<{id: string}>();
    const navigate = useNavigate();

    const lastBookingLocation = useAppSelector((state) => state.router.lastLocations.myBookings.lastBookingLocation || "/my-bookings", shallowEqual);
    const locale = useAppSelector((state) => state.locale.currentLocale);
    const currency = useAppSelector((state) => state.currency.currentCurrency);
    const smsLocales = useAppSelector((state) => (state.bookingDetails.data as WebTransferBookingDetails)?.smsLocales);
    const smsLocale = useAppSelector((state) => (state.bookingDetails.data as WebTransferBookingDetails)?.smsReminderLocale);
    const [languageInput, setLanguageInput] = useState<string>(smsLocale);

    const dispatchRedux = useAppDispatch();

    const {data, bookingDetailsParams, requestingBookingDetails} = useAppSelector((state) => state.bookingDetails) as {data?: WebTransferBookingDetails, bookingDetailsParams?: {locale: string; currency: Currency;}, requestingBookingDetails: boolean};

    const isMounted = useIsMounted();

    useEffect(() => {
        if (!data && id) {
            dispatchRedux(getBooking(parseInt(id, 10)));
        }
    }, [data, dispatchRedux, id]);

    useEffect(() => {
        if (!requestingBookingDetails &&
            bookingDetailsParams &&
            !isEqual({
                locale,
                currency
            }, bookingDetailsParams)) {
            dispatchRedux(updateBooking());
        }
    }, [
        bookingDetailsParams,
        currency,
        locale,
        dispatchRedux,
        requestingBookingDetails
    ]);

    const [state, dispatch] = useReducer(reducer, initialState);

    const {
        requesting, amendments, editingBoxes, temp, changes, beforeChanges
    } = state;

    const hotelInfoEdit = useMemo(() => editingBoxes.includes("HOTEL_INFO"), [editingBoxes]);
    const passengersEdit = useMemo(() => editingBoxes.includes("PASSENGERS"), [editingBoxes]);
    const arrivalFlightEdit = useMemo(() => editingBoxes.includes("ARRIVAL_FLIGHT"), [editingBoxes]);
    const departureFlightEdit = useMemo(() => editingBoxes.includes("DEPARTURE_FLIGHT"), [editingBoxes]);
    const smsReminderEdit = useMemo(() => editingBoxes.includes("SMS_REMINDER"), [editingBoxes]);

    const handleInput = (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>, boxName: string) => dispatch({
        type: "ON_INPUT",
        name: e.target.name,
        value: e.target.value,
        boxName
    });

    const handlePax = (e: React.ChangeEvent<HTMLInputElement>) => dispatch({
        type: "HANDLE_PAX",
        name: e.target.name,
        value: e.target.value
    });

    const handleAdults = (value: number) => dispatch({
        type: "ON_INPUT",
        name: "ADULTS",
        value,
        boxName: "PASSENGERS"
    });

    const handleChildren = (ages: number[]) => dispatch({
        type: "ON_INPUT",
        name: "CHILDREN",
        value: ages,
        boxName: "PASSENGERS"
    });

    const handleTitle = (value: string) => dispatch({
        type: "HANDLE_PAX",
        name: "title",
        value: value
    });

    const handleArrivalTime = (value: string) => dispatch({
        type: "ON_INPUT",
        name: "FLIGHT_DATETIME_ARRIVAL",
        value,
        boxName: "ARRIVAL_FLIGHT"
    });

    const handleDepartureTime = (value: string) => dispatch({
        type: "ON_INPUT",
        name: "FLIGHT_DATETIME_DEPARTURE",
        value,
        boxName: "DEPARTURE_FLIGHT"
    });

    useEffect(() => {
        dispatch({type: "REQ_TYPES"});
        globalAxiosController.addRequest(amendmentsAPI.getAmendmentTypes(Number(id), locale))
            .then((data) => {
                if (isMounted) {
                    if (!data || !data.amendments) {
                        dispatch({
                            type: "REQ_TYPES_FAILURE",
                            errors: [t("mb_ta_ta_something_went_wrong")]
                        });
                    } else {
                        dispatch({
                            type: "REQ_TYPES_SUCCESS",
                            amendments: data.amendments,
                            beforeChanges: data.amendments
                        });
                    }
                }
            })
            .catch((error: unknown) => {
                if (isMounted) {
                    dispatch({
                        type: "REQ_TYPES_FAILURE",
                        errors: [error as string || t("mb_ta_ta_something_went_wrong")]
                    });
                }
            });
    }, [id, isMounted, locale, t]);

    const onEdit = useCallback((boxName: string) => dispatch({
        type: "ON_EDIT",
        boxName
    }), []);

    const onCancel = useCallback((boxName: string) => dispatch({
        type: "ON_CANCEL",
        boxName
    }), []);

    const onConfirm = useCallback(() => {
        if (changes) {
            globalAxiosController.addRequest(amendmentsAPI.addAmendments({amendments: changes}, Number(id), locale))
                .then((data) => {
                    if (data.success) {
                        navigate(lastBookingLocation);
                    } else {
                        dispatch({
                            type: "SET_ERROR",
                            errors: data.errors || [t("mb_ta_ta_something_went_wrong")]
                        });
                    }
                })
                .catch((error: unknown) => {
                    dispatch({
                        type: "SET_ERROR",
                        errors: [error as string || t("mb_ta_ta_something_went_wrong")]
                    });
                })
        }
    }, [changes, locale, id, navigate, lastBookingLocation, t, isMounted]);

    const onBack = useCallback(() => navigate(lastBookingLocation), [
        lastBookingLocation,
        history
    ]);

    return (
        <Amendments
            className={styles.Root}
            isSpinnerOn={requesting || requestingBookingDetails}
            footer={(
                <ActionsWrapper>
                    <UserButton
                        text={t("mb_ta_ta_back_to_booking")}
                        variant={UserButtonVariant.SECONDARY}
                        buttonProps={{
                            onClick: onBack,
                            type: "button",
                            style: {
                                height: "50px",
                                width: "160px",
                                marginRight: "20px"
                            }
                        }}
                    />
                    {!_.isEmpty(amendments) && (
                        <UserButton
                            text={t("mb_ta_ta_confirm_button")}
                            variant={UserButtonVariant.PRIMARY}
                            disabledButton={isEmpty(changes)}
                            buttonProps={{
                                type: "button",
                                onClick: () => setModal({
                                    onConfirm,
                                    onDecline: () => false,
                                    config: {
                                        variants: {
                                            type: "warn"
                                        },
                                        title: t("mb_ta_ta_changes"),
                                        content: <Changes oldValues={beforeChanges} newValues={changes} locales={smsLocales} />,
                                        disabledConfirmButton: !changes || isEmpty(changes)
                                    }
                                }),
                                style: {
                                    height: "50px",
                                    width: "160px"
                                }
                            }}
                        />
                    )}
                </ActionsWrapper>
            )}
        >
            <Header id={data?.reference || id} />

            {state.errors && (
                <InfoBox
                    style={{
                        marginBottom: 10
                    }}
                    title={t("mb_ta_ta_something_went_wrong")}
                    message={state.errors}
                    type="warn"
                />
            )}

            {!_.isEmpty(amendments) && (
                <>
                    <Box canEdit={false} title={t("mb_ta_ta_transfer_information")}>
                        <>
                            <Item label={t("mb_ta_ta_country") + ":"} value={!!data && data.origin.countryName} />
                            <Item label={t("mb_ta_ta_origin") + ":"} value={!!data && data.origin.name} />
                            <Item label={t("mb_ta_ta_destination") + ":"} value={!!data && data.destination.name} />
                        </>
                    </Box>

                    <Box
                        canEdit
                        edit={hotelInfoEdit}
                        onEdit={() => onEdit("HOTEL_INFO")}
                        title={t("mb_ta_ta_HOTEL_INFO")}
                    >
                        <>
                            {hotelInfoEdit && (
                                <InfoBox
                                    style={{
                                        marginBottom: 30,
                                        fontSize: "1rem"
                                    }}
                                    title={t("mb_ta_ta_amendments_hotel_info_warning")}
                                    type="warn"
                                />
                            )}

                            <Item
                                edit={hotelInfoEdit}
                                labelWithInputEl
                                label={t("mb_ta_ta_HOTEL_NAME") + ":"}
                                value={amendments.HOTEL_NAME}
                                inputEl={(
                                    <Input
                                        variants={{labelPosition: "outlined"}}
                                        fullHeight={false}
                                        inputProps={{
                                            required: true,
                                            name: "HOTEL_NAME",
                                            value: !!temp && temp.HOTEL_NAME,
                                            onChange: (e) => handleInput(e, "HOTEL_INFO"),
                                            placeholder: t("mb_ta_ta_HOTEL_NAME")
                                        }}
                                    />
                                )}
                            />

                            <Item
                                edit={hotelInfoEdit}
                                label={t("mb_ta_ta_HOTEL_ADDRESS") + ":"}
                                labelStyle={{
                                    flexBasis: "100%"
                                }}
                                containerStyle={{
                                    flexWrap: "wrap"
                                }}
                                inputContainerStyle={{
                                    maxWidth: "unset"
                                }}
                                value={amendments.HOTEL_ADDRESS}
                                inputEl={(
                                    <textarea
                                        onChange={(e) => handleInput(e, "HOTEL_INFO")}
                                        value={!!temp && temp.HOTEL_ADDRESS}
                                        name="HOTEL_ADDRESS"
                                        style={{
                                            width: "100%"
                                        }}
                                    />
                                )}
                            />

                            {hotelInfoEdit && (
                                <div className={styles.SaveCancelContainer}>
                                    <UserButton
                                        text={t("amend_b_cancel_button")}
                                        variant={UserButtonVariant.SECONDARY}
                                        buttonProps={{
                                            type: "button",
                                            onClick: () => onCancel("HOTEL_INFO"),
                                            style: {
                                                display: "flex",
                                                height: "40px",
                                                width: "146px"
                                            }
                                        }}
                                    />
                                </div>
                            )}
                        </>
                    </Box>
                    <Box
                        canEdit
                        edit={passengersEdit}
                        onEdit={() => onEdit("PASSENGERS")}
                        title={t("mb_ta_ta_PASSENGERS")}
                    >
                        <>
                            {amendments && titles.titles && !!temp && !!temp.PAX && (
                                <ItemsWrapper hasTitle title={t("mb_ta_ta_PAX")} wrap={passengersEdit}>
                                    <Item
                                        edit={passengersEdit}
                                        label={t("mb_ta_ta_PAX") + ":"}

                                        value={`${t(titles.titles.find((e) => e.value === amendments.PAX?.title)?.label || "")} ${amendments.PAX?.name || ""} ${amendments.PAX?.surname || ""}`}
                                        labelWithInputEl
                                        inputEl={(
                                            <LeadPassengerInputs
                                                handleTitle={handleTitle}
                                                handleInput={handlePax}
                                                title={temp.PAX.title}
                                                name={temp.PAX.name}
                                                surname={temp.PAX.surname}
                                            />
                                        )}
                                    />
                                </ItemsWrapper>
                            )}

                            <ItemsWrapper
                                wrap={passengersEdit}
                                hasTitle
                                inline
                                title={t("mb_ta_ta_passenger_count")}
                                containerClassName={styles.PassengersSelect}
                            >
                                <Item
                                    edit={passengersEdit}
                                    label={t("mb_ta_ta_ADULTS") + ":"}
                                    labelStyle={{
                                        minWidth: 72
                                    }}
                                    value={amendments.ADULTS}
                                    inputEl={(<AdultsInput adults={!!temp && temp.ADULTS} handleAdults={handleAdults} />)}
                                />

                                <Item
                                    edit={passengersEdit}
                                    label={t("mb_ta_ta_CHILDREN") + ":"}
                                    value={amendments.CHILDREN?.length}
                                    inline
                                    inputEl={(<ChildrenInput childrenVal={!!temp && temp.CHILDREN} handleChildren={handleChildren} />)}
                                />
                            </ItemsWrapper>

                            {passengersEdit && (
                                <div className={styles.SaveCancelContainer}>
                                    <UserButton
                                        text={t("amend_b_cancel_button")}
                                        variant={UserButtonVariant.SECONDARY}
                                        buttonProps={{
                                            type: "button",
                                            onClick: () => onCancel("PASSENGERS"),
                                            style: {
                                                height: "40px",
                                                width: "146px"
                                            }
                                        }}
                                    />
                                </div>
                            )}
                        </>
                    </Box>

                    {amendments.IATA_ARRIVAL && (
                        <Box
                            canEdit
                            edit={arrivalFlightEdit}
                            onEdit={() => onEdit("ARRIVAL_FLIGHT")}
                            title={t("mb_ta_ta_ARRIVAL_FLIGHT")}
                            contentClassName={cx(arrivalFlightEdit && styles.ArrivalDepartureBox)}
                        >
                            <>
                                <Item
                                    edit={arrivalFlightEdit}
                                    label={t("mb_ta_ta_IATA_ARRIVAL") + ":"}
                                    labelWithInputEl
                                    value={amendments.IATA_ARRIVAL}
                                    inputContainerClassName={styles.ArrivalDepartureEditableField}
                                    inputEl={(
                                        <Input
                                            variants={{labelPosition: "outlined"}}
                                            fullHeight={false}
                                            inputProps={{
                                                required: true,
                                                name: "IATA_ARRIVAL",
                                                value: temp ? temp.IATA_ARRIVAL : "",
                                                onChange: (e) => handleInput(e, "ARRIVAL_FLIGHT"),
                                                placeholder: t("mb_ta_ta_IATA_ARRIVAL")
                                            }}
                                        />
                                    )}
                                />

                                <Item
                                    edit={arrivalFlightEdit}
                                    labelWithInputEl
                                    label={t("mb_ta_ta_arrival_to") + ":"}
                                    inputContainerClassName={styles.ArrivalDepartureEditableField}
                                    inputEl={(
                                        <Input
                                            variants={{labelPosition: "outlined"}}
                                            fullHeight={false}
                                            inputProps={{
                                                disabled: true,
                                                name: "IATA_ARRIVAL",
                                                value: !!data && data.arrivalDetails ? data.arrivalDetails.airportTo : "",
                                                placeholder: t("mb_ta_ta_arrival_to")
                                            }}
                                        />
                                    )}
                                    value={!!data && data.arrivalDetails && data.arrivalDetails.airportTo}
                                />

                                <Item
                                    edit={arrivalFlightEdit}
                                    labelWithInputEl
                                    label={t("mb_ta_ta_FLIGHT_CODE_ARRIVAL") + ":"}
                                    value={amendments.FLIGHT_CODE_ARRIVAL}
                                    inputContainerClassName={styles.ArrivalDepartureEditableField}
                                    inputEl={(
                                        <Input
                                            variants={{labelPosition: "outlined"}}
                                            fullHeight={false}
                                            inputProps={{
                                                required: true,
                                                name: "FLIGHT_CODE_ARRIVAL",
                                                value: temp
                                                    ? temp.FLIGHT_CODE_ARRIVAL
                                                    : "",
                                                onChange: (e) => handleInput(e, "ARRIVAL_FLIGHT"),
                                                placeholder: t("mb_ta_ta_FLIGHT_CODE_ARRIVAL")
                                            }}
                                        />
                                    )}
                                />
                                <Item
                                    edit={arrivalFlightEdit}
                                    label={t("mb_ta_ta_AIRLINES_PROVIDER_ARRIVAL") + ":"}
                                    labelWithInputEl
                                    value={amendments.AIRLINES_PROVIDER_ARRIVAL}
                                    inputContainerClassName={styles.ArrivalDepartureEditableField}
                                    inputEl={(
                                        <Input
                                            variants={{labelPosition: "outlined"}}
                                            fullHeight={false}
                                            inputProps={{
                                                required: true,
                                                name: "AIRLINES_PROVIDER_ARRIVAL",
                                                value: temp
                                                    ? temp.AIRLINES_PROVIDER_ARRIVAL
                                                    : "",
                                                onChange: (e) => handleInput(e, "ARRIVAL_FLIGHT"),
                                                placeholder: t("mb_ta_ta_AIRLINES_PROVIDER_ARRIVAL")
                                            }}
                                        />
                                    )}
                                />
                                <DateWithTime
                                    className={styles.DateTimeField}
                                    required={true}
                                    label={t("mb_ta_ta_FLIGHT_DATETIME_ARRIVAL") + ":"}
                                    edit={arrivalFlightEdit}
                                    isoDateForValue={amendments.FLIGHT_DATETIME_ARRIVAL}
                                    isoDateForInput={temp ? temp.FLIGHT_DATETIME_ARRIVAL : undefined}
                                    handleDateAndTime={handleArrivalTime}
                                />

                                {arrivalFlightEdit && (
                                    <div className={styles.SaveCancelContainer}>
                                        <UserButton
                                            text={t("amend_b_cancel_button")}
                                            variant={UserButtonVariant.SECONDARY}
                                            buttonProps={{
                                                type: "button",
                                                onClick: () => onCancel("ARRIVAL_FLIGHT"),
                                                style: {
                                                    height: "40px",
                                                    width: "146px"
                                                }
                                            }}
                                        />
                                    </div>
                                )}
                            </>
                        </Box>
                    )}

                    {amendments.IATA_DEPARTURE && (
                        <Box
                            canEdit
                            edit={departureFlightEdit}
                            onEdit={() => onEdit("DEPARTURE_FLIGHT")}
                            title={t("mb_ta_ta_DEPARTURE_FLIGHT")}
                            contentClassName={cx(departureFlightEdit && styles.ArrivalDepartureBox)}
                        >
                            <>
                                <Item
                                    edit={departureFlightEdit}
                                    label={t("mb_ta_ta_departure_from") + ":"}
                                    labelWithInputEl
                                    inputContainerClassName={styles.ArrivalDepartureEditableField}
                                    inputEl={(
                                        <Input
                                            variants={{labelPosition: "outlined"}}
                                            fullHeight={false}
                                            inputProps={{
                                                disabled: true,
                                                name: "IATA_DEPARTURE",
                                                value: !!data && data.departureDetails ? data.departureDetails.airportFrom : "",
                                                placeholder: t("mb_ta_ta_departure_from")
                                            }}
                                        />
                                    )}
                                    value={!!data && data.departureDetails && data.departureDetails.airportFrom}
                                />

                                <Item
                                    edit={departureFlightEdit}
                                    label={t("mb_ta_ta_IATA_DEPARTURE") + ":"}
                                    labelWithInputEl
                                    value={amendments.IATA_DEPARTURE}
                                    inputContainerClassName={styles.ArrivalDepartureEditableField}
                                    inputEl={(
                                        <Input
                                            variants={{labelPosition: "outlined"}}
                                            fullHeight={false}
                                            inputProps={{
                                                required: true,
                                                name: "IATA_DEPARTURE",
                                                value: temp ? temp.IATA_DEPARTURE : "",
                                                onChange: (e) => handleInput(e, "DEPARTURE_FLIGHT"),
                                                placeholder: t("mb_ta_ta_IATA_DEPARTURE")
                                            }}
                                        />
                                    )}
                                />

                                <Item
                                    edit={departureFlightEdit}
                                    label={t("mb_ta_ta_FLIGHT_CODE_DEPARTURE") + ":"}
                                    labelWithInputEl
                                    value={amendments.FLIGHT_CODE_DEPARTURE}
                                    inputContainerClassName={styles.ArrivalDepartureEditableField}
                                    inputEl={(
                                        <Input
                                            variants={{labelPosition: "outlined"}}
                                            fullHeight={false}
                                            inputProps={{
                                                required: true,
                                                name: "FLIGHT_CODE_DEPARTURE",
                                                value: temp ? temp.FLIGHT_CODE_DEPARTURE : "",
                                                onChange: (e) => handleInput(e, "DEPARTURE_FLIGHT"),
                                                placeholder: t("mb_ta_ta_FLIGHT_CODE_DEPARTURE")
                                            }}
                                        />
                                    )}
                                />

                                <Item
                                    edit={departureFlightEdit}
                                    label={t("mb_ta_ta_AIRLINES_PROVIDER_DEPARTURE") + ":"}
                                    labelWithInputEl
                                    value={amendments.AIRLINES_PROVIDER_DEPARTURE}
                                    inputContainerClassName={styles.ArrivalDepartureEditableField}
                                    inputEl={(
                                        <Input
                                            variants={{labelPosition: "outlined"}}
                                            fullHeight={false}
                                            inputProps={{
                                                required: true,
                                                name: "AIRLINES_PROVIDER_DEPARTURE",
                                                value: temp ? temp.AIRLINES_PROVIDER_DEPARTURE : "",
                                                onChange: (e) => handleInput(e, "DEPARTURE_FLIGHT"),
                                                placeholder: t("mb_ta_ta_AIRLINES_PROVIDER_DEPARTURE")
                                            }}
                                        />
                                    )}
                                />
                                <DateWithTime
                                    className={styles.DateTimeField}
                                    required={true}
                                    label={t("mb_ta_ta_FLIGHT_DATETIME_DEPARTURE") + ":"}
                                    edit={departureFlightEdit}
                                    isoDateForValue={amendments.FLIGHT_DATETIME_DEPARTURE}
                                    isoDateForInput={temp ? temp.FLIGHT_DATETIME_DEPARTURE : undefined}
                                    handleDateAndTime={handleDepartureTime}
                                />

                                {departureFlightEdit && (
                                    <div className={styles.SaveCancelContainer}>
                                        <UserButton
                                            text={t("amend_b_cancel_button")}
                                            variant={UserButtonVariant.SECONDARY}
                                            buttonProps={{
                                                type: "button",
                                                onClick: () => onCancel("DEPARTURE_FLIGHT"),
                                                style: {
                                                    height: "40px",
                                                    width: "146px"
                                                }
                                            }}
                                        />
                                    </div>
                                )}
                            </>
                        </Box>
                    )}
                    {(amendments.SMS_LANGUAGE && amendments.SMS_PHONE_NUMBER) && (
                        <Box
                            canEdit
                            edit={smsReminderEdit}
                            onEdit={() => onEdit("SMS_REMINDER")}
                            title={t("mb_ta_ta_SMS_REMINDER_TITLE")}
                            contentClassName={cx(departureFlightEdit && styles.ArrivalDepartureBox)}
                        >
                            <>
                                <Item
                                    edit={smsReminderEdit}
                                    labelWithInputEl
                                    label={t("mb_ta_ta_SMS_REMINDER_PHONE") + ":"}
                                    value={amendments.SMS_PHONE_NUMBER}
                                    inputEl={(
                                        <Input
                                            variants={{labelPosition: "outlined"}}
                                            fullHeight={false}
                                            inputProps={{
                                                required: true,
                                                name: "SMS_PHONE_NUMBER",
                                                value: !!temp && temp.SMS_PHONE_NUMBER,
                                                onChange: (e) => handleInput(e, "SMS_REMINDER"),
                                                placeholder: t("mb_ta_ta_SMS_REMINDER_PHONE")
                                            }}
                                        />
                                    )}
                                />

                                <Item
                                    edit={smsReminderEdit}
                                    labelWithInputEl
                                    label={t("mb_ta_ta_SMS_LANGUAGE") + ":"}
                                    value={languageInput}
                                    inputEl={(
                                        <SmsLanguage
                                            fullHeight={false}
                                            setTextInputValue={(inputText) => setLanguageInput(inputText?.toString())}
                                            // setTextInputValue={(inputText) => console.log(inputText)}
                                            textInputValue={languageInput}
                                            handleSmsLocaleChange={(smsLangLocale) => handleInput({
                                                target: {
                                                    name: "SMS_LANGUAGE",
                                                    value: smsLangLocale
                                                }
                                            } as ChangeEvent<HTMLTextAreaElement | HTMLInputElement>, "SMS_REMINDER")}
                                            options={smsLocales}
                                        />
                                    )}
                                />
                                {smsReminderEdit && (
                                    <div className={styles.SaveCancelContainer}>
                                        <UserButton
                                            text={t("amend_b_cancel_button")}
                                            variant={UserButtonVariant.SECONDARY}
                                            buttonProps={{
                                                type: "button",
                                                onClick: () => onCancel("SMS_REMINDER"),
                                                style: {
                                                    height: "40px",
                                                    width: "146px"
                                                }
                                            }}
                                        />
                                    </div>
                                )}

                            </>
                        </Box>
                    )}
                </>
            )}

        </Amendments>
    );
}
export default withConfirmation(TransferAmendments);
