import React, {CSSProperties, ReactElement} from "react";
import cx from "classnames";
import styles from "./PageIntro.module.scss";

type Props = {
    introImg: string;
    swtichItem?: number;
    fadeIn?: boolean;
    style?: CSSProperties;
};

function PageIntro(
    {
        introImg,
        fadeIn = true,
        swtichItem = 1,
        style
    }: Props
): ReactElement {
    return (
        <div className={styles.root}>
            <div
                style={style ? {...style, ...{backgroundImage: `url(${introImg})`}} : {backgroundImage: `url(${introImg})`}}
                className={cx(styles.imgResponsive, fadeIn ? (swtichItem === 1 ? styles.imgResponsive_first : styles.imgResponsive_second) : styles.imgStatic)}
            />
        </div>
    );
}

export default PageIntro;
