import {ReduxTransferBookingPropTypes} from "../../proptypes/PropTypeRedux";
import {
    BookingOptionsPropTypes,
    ContactInfoPropTypes,
    InvoiceRecipientPropTypes,
    TransferBookingInfoPropTypes,
    WebBankLinkOptionPropTypes
} from "../../proptypes/PropTypeObjects";
import TransferBookingTypes from "../constants/transferBooking.constants";
import TransferBookingActionProps from "./actions/transferBooking.reducer.actions";

export const INITIAL_STATE: ReduxTransferBookingPropTypes = {
    data: {
        bookingInfo: undefined,
        transferAvailabilityCriteria: undefined,
        duplicateBookings: undefined,
        transferBookingInfo: undefined,
        route: "OK",
        errors: undefined
    },
    transfer: undefined,
    smsLanguagesmsReminderLocaleInput: "",
    savedPassengerDetails: undefined,
    firstTimeChanges: true,
    allowedInBookingPages: false,
    requestingValidation: false,
    requestingBooking: false,
    loadedData: false,
    error: undefined,
    bookingOptionsParams: undefined,
    allowBookSubmit: true
};

export default (state = INITIAL_STATE, action: TransferBookingActionProps): ReduxTransferBookingPropTypes => {
    switch (action.type) {
    case TransferBookingTypes.GET_OPTIONS:
        return {
            ...state,
            data: {
                ...state.data,
                transferAvailabilityCriteria: action.transferAvailabilityCriteria
            },
            transfer: action.transfer,
            bookingOptionsParams: action.bookingOptionsParams,
            allowedInBookingPages: true,
            allowBookSubmit: true,
            error: undefined
        };
    case TransferBookingTypes.GET_OPTIONS_SUCCESS: {
        return {
            ...state,
            data: {
                ...state.data,
                ...action.data,
                bookingInfo: {
                    ...action.data.bookingInfo
                }
            },
            loadedData: true,
            error: undefined
        };
    }
    case TransferBookingTypes.GET_OPTIONS_FAILURE:
        return {
            ...state,
            loadedData: false,
            error: action.error
        };
    case TransferBookingTypes.UPDATE_OPTIONS:
        return {
            ...state,
            bookingOptionsParams: action.bookingOptionsParams,
            allowedInBookingPages: true,
            error: undefined
        };
    case TransferBookingTypes.UPDATE_OPTIONS_SUCCESS: {
        const {bookingInfo, transferBookingInfo, ...restData} = action.data;
        return {
            ...state,
            data: {
                ...state.data,
                ...restData,
                bookingInfo: {
                    ...state.data?.bookingInfo
                }
            },
            loadedData: true,
            error: undefined
        };
    }
    case TransferBookingTypes.UPDATE_OPTIONS_FAILURE:
        return {
            ...state,
            loadedData: false,
            error: action.error
        };
    case TransferBookingTypes.REQUEST_TRANSFER_BOOKING:
        return {
            ...state,
            requestingBooking: true,
            allowBookSubmit: false,
            error: undefined
        };
    case TransferBookingTypes.TRANSFER_BOOKING_SUCCESS:
        return {
            ...state,
            data: {
                ...state.data,
                ...action.data
            },
            firstTimeChanges: false,
            requestingBooking: false,
            loadedData: true,
            allowBookSubmit: true,
            error: undefined
        };
    case TransferBookingTypes.TRANSFER_BOOKING_FAILURE:
        return {
            ...state,
            requestingBooking: false,
            loadedData: false,
            firstTimeChanges: false,
            allowBookSubmit: true,
            error: action.error
        };
    case TransferBookingTypes.REQUEST_VALIDATE_BOOKING:
        return {
            ...state,
            requestingValidation: true,
            data: {
                ...state.data,
                errors: undefined,
                route: "OK"
            },
            error: undefined
        };
    case TransferBookingTypes.VALIDATE_BOOKING_SUCCESS:
        return {
            ...state,
            data: {
                ...state.data,
                ...action.data
            },
            requestingValidation: false
        };
    case TransferBookingTypes.VALIDATE_BOOKING_FAILURE:
        return {
            ...state,
            requestingValidation: false,
            error: action.error
        };
    case TransferBookingTypes.CLEAR_BOOKING_DATA: {
        return {
            ...INITIAL_STATE
        };
    }
    case TransferBookingTypes.CLEAR_VALIDATION_DATA:
        return {
            ...state,
            data: {
                ...state.data,
                errors: undefined,
                route: "OK"
            }
        };
    case TransferBookingTypes.HANDLE_INPUT_CHANGE:
        return {
            ...state,
            [action.name]: action.value
        };
    case TransferBookingTypes.CONFIRM_DUPLICATE_BOOKING:
        return {
            ...state,
            data: {
                ...state.data,
                bookingInfo: {
                    ...state.data?.bookingInfo,
                    confirmedDuplicateBooking: true
                }
            }
        };
    case TransferBookingTypes.HANDLE_CONTACT_INFO:
        return {
            ...state,
            data: {
                ...state.data,
                bookingInfo: {
                    ...state.data?.bookingInfo,
                    contactInfo: {
                        ...state.data?.bookingInfo?.contactInfo,
                        [action.name]: action.value
                    } as ContactInfoPropTypes
                }
            }
        };
    case TransferBookingTypes.HANDLE_LEAD_PASSENGER:
        return {
            ...state,
            data: {
                ...state.data,
                transferBookingInfo: {
                    ...state.data?.transferBookingInfo,
                    leadPassenger: {
                        ...state.data?.transferBookingInfo?.leadPassenger,
                        [action.name]: action.value
                    }
                } as TransferBookingInfoPropTypes
            }
        };
    case TransferBookingTypes.HANDLE_TRANSFER_BOOKING_INFO_INPUT:
        return {
            ...state,
            data: {
                ...state.data,
                transferBookingInfo: {
                    ...state.data?.transferBookingInfo,
                    [action.name]: action.value
                } as TransferBookingInfoPropTypes
            }
        };
    case TransferBookingTypes.TOGGLE_SMS_ORDERED: {
        return {
            ...state,
            data: {
                ...state.data,
                transferBookingInfo: {
                    ...state.data?.transferBookingInfo,
                    smsOrdered: !state.data?.transferBookingInfo?.smsOrdered
                } as TransferBookingInfoPropTypes
            }
        };
    }
    case TransferBookingTypes.HANDLE_MEMO:
        return {
            ...state,
            data: {
                ...state.data,
                bookingInfo: {
                    ...state.data?.bookingInfo,
                    memo: action.memo
                }
            }
        };
    default:
        return state;
    }
};
