import {Notifications} from "../../proptypes/PropTypeObjects";
import WebSocketTypes from "../constants/webSocket.constants";
import IAppActions from "../store/store.actions";

export function setNotificationsData(notificationsData: Notifications): IAppActions {
    return {type: WebSocketTypes.SET_NOTIFICATIONS_DATA, notificationsData};
}

export function setSendMessage(sendMessage?: { topic: string, msg?: any }): IAppActions {
    return {type: WebSocketTypes.SET_SEND_MESSAGE, sendMessage};
}

export function resetNotifications(): IAppActions {
    return {type: WebSocketTypes.RESET_NOTIFICATIONS_DATA};
}
