import i18n from "../i18n";
import {SpecialRequestsPropTypes} from "../proptypes/PropTypeObjects";
import createLuxonDate from "./date/createLuxonDate"

export default function getSpecialRequestText(specialRequests: SpecialRequestsPropTypes | undefined): string[] | undefined {
    if (specialRequests) {
        const selectedRequests = Object.keys(specialRequests).filter((key) => !!specialRequests[key]);
        const translated: (string | undefined)[] = [];
        if (selectedRequests.includes("honeymoon")) {
            translated.push(i18n.t("util_srt_guests_honeymoon"));
        }
        if (selectedRequests.includes("lateArrival") && selectedRequests.includes("lateArrivalTime")) {
            let lateArrivalTime: string | undefined;
            if (specialRequests.lateArrivalTime) {
                lateArrivalTime = createLuxonDate(specialRequests.lateArrivalTime).toFormat("HH:mm");
            }

            const time = lateArrivalTime;
            translated.push(i18n.t("util_srt_lateArrival_with_time", {time}));
        }
        if (selectedRequests.includes("smoking")) {
            translated.push(i18n.t("util_srt_if_possible_smoking"));
        }
        if (selectedRequests.includes("nonSmoking")) {
            translated.push(i18n.t("util_srt_if_possible_nonSmoking"));
        }
        if (selectedRequests.includes("separateBeds")) {
            translated.push(i18n.t("util_srt_if_possible_separateBeds"));
        }
        if (selectedRequests.includes("doubleBed")) {
            translated.push(i18n.t("util_srt_if_possible_doubleBed"));
        }
        if (selectedRequests.includes("babyCot")) {
            translated.push(i18n.t("util_srt_if_possible_babyCot"));
        }

        if (selectedRequests.includes("text")) {
            translated.push(i18n.t("util_srt_text"));
            translated.push(specialRequests.text);
        }
        return translated as string[];
    }
    return undefined;
}
