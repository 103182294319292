import React, {ReactElement} from "react";

function ActionsWrapper({
    children
}: any): ReactElement {
    return (
        <div
            style={{
                display: "flex",
                justifyContent: "space-between",
                marginTop: 40
            }}
        >
            {children}
        </div>
    );
}

export default ActionsWrapper;
