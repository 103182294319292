import {DateTime, Duration, DurationObjectUnits} from "luxon";

export const createHumanDateString = (start: DateTime, end: DateTime): string => {
    const timeDifference = end.diff(start);
    // Better Duration.toHuman support https://github.com/moment/luxon/issues/1134
    const duration = timeDifference.shiftTo("days", "hours", "minutes", "seconds").toObject();
    const convertDurationValues = (duration: DurationObjectUnits) => {
        const {days, hours, minutes, seconds} = duration;

        if (days !== 0) {
            if (hours !== 0) {
                return timeDifference.shiftTo("days", "hours").toObject();
            }
            return timeDifference.shiftTo("days").toObject();
        }

        if (hours !== 0) {
            if (minutes !== 0) {
                return timeDifference.shiftTo("hours", "minutes").toObject();
            }
            return timeDifference.shiftTo("hours").toObject();
        }

        if (minutes !== 0) {
            if (seconds !== 0) {
                return timeDifference.shiftTo("minutes", "seconds").toObject();
            }
            return timeDifference.shiftTo("minutes").toObject();
        }

        return timeDifference.shiftTo("seconds").toObject();
    };

    const prefix = start > end ? "in " : "";
    const suffix = end > start ? " ago" : "";

    const cleanedDuration = Object.fromEntries(
        Object.entries(convertDurationValues(duration))
            .map(([key, value]) => [key, Math.floor(value)])
    ) as DurationObjectUnits;

    if (Object.keys(cleanedDuration).length === 0) {
        cleanedDuration.seconds = 0;
    }

    const human = Duration.fromObject(cleanedDuration).toHuman();
    return `${prefix}${human}${suffix}`;
};

export default createHumanDateString;