import React, {ReactElement, useCallback} from "react";
import {useTranslation} from "react-i18next";
import types from "utils/paymentTransferTypes";
import InfoBox from "components/common/InfoBox";
import {PaymentTransferTypePropTypes} from "proptypes/PropTypeObjects";
import styles from "components/common/Payment/PaymentContent/PaymentContent.module.scss";
import {ReduxPaymentTypePropTypes} from "proptypes/PropTypeRedux";
import {calculateTransactionFee} from "redux/actions/pricing.actions";
import usePaymentOptions from "components/base/Payment/PaymentType/usePaymentOptions";
import {useAppDispatch} from "redux/hooks";
import {ReactComponent as ApplePayIcon} from "assets/icons/payment/apple_pay.svg";

export type ApplePayBlockProps = {
    paymentTransferTypeEnum?: PaymentTransferTypePropTypes;
    toggleSelectedPayment: (paymentType: PaymentTransferTypePropTypes, keepSelected?: boolean) => void;
    afterDeadline?: boolean;
    children: ReactElement
};

const ApplePayBlock = (props: ApplePayBlockProps): ReactElement => {
    const {
        paymentTransferTypeEnum,
        toggleSelectedPayment,
        afterDeadline,
        children
    } = props;

    const {
        type,
        paymentOptions
    } = usePaymentOptions()

    const {t} = useTranslation();
    const dispatch = useAppDispatch();

    const infoBlockOnClick = useCallback(() => {
        toggleSelectedPayment(types[afterDeadline ? "after" : "before"].applePay);

        if (type === ReduxPaymentTypePropTypes.ANONYMOUS_MULTIBOOKING) {
            return false;
        }

        dispatch(calculateTransactionFee());

        return false;
    }, [afterDeadline, dispatch, toggleSelectedPayment, type]);

    if (!paymentOptions) {
        return (
            <></>
        );
    }

    const {
        freeCreditCard,
        paymentDeadlineDate,
        paymentDeadlineTime
    } = paymentOptions;

    return (
        <InfoBox
            style={{marginBottom: 15}}
            active={paymentTransferTypeEnum === types[afterDeadline ? "after" : "before"].applePay}
            title={t("pc_pc_apple_pay")}
            message={t("pc_pc_ptt_apple_pay_promotional_message")}
            customIcon={<ApplePayIcon className={styles.ApplePayIcon}/>}
            // miniIcon
            onClick={infoBlockOnClick}
            type="custom"
        >
            <div className={styles.Option}>
                <div className={styles.OptionText}>
                    {afterDeadline ? (
                        <>
                            {freeCreditCard ? (
                                <p>
                                    {t("pc_pc_ptt_apple_pay_without_fee_after_cxl")}
                                </p>
                            ) : (
                                <p>
                                    {t("pc_pc_ptt_apple_pay_after_cxl")}
                                </p>
                            )}
                            <br/>
                            <p>
                                {t("pc_pc_ptt_apple_pay_after_cxl_extra")}
                            </p>

                            {/*{paymentPromotion ? (*/}
                            {/*    <>*/}
                            {/*        <br />*/}
                            {/*        <p style={{color: "#EC4331"}}>*/}
                            {/*            <Trans i18nKey="pc_pc_ptt_payment_promotion_cc_text" values={{amount: getPriceWithCurrency(paymentPromotion.discountRatio * displaySalePrice, currency)}}>*/}
                            {/*                Attention! This reservation can provide you additional profit of {getPriceWithCurrency(paymentPromotion.discountRatio * displaySalePrice, currency)}.*/}
                            {/*                If you choose to pay by bank transfer, you will get {getPriceWithCurrency(paymentPromotion.discountRatio * displaySalePrice, currency)} to your user discount balance when the booking is complete (after the service-end date). You can then use it as a discount for any unpaid bookings, including your own.*/}
                            {/*            </Trans>*/}
                            {/*        </p>*/}
                            {/*    </>*/}
                            {/*) : undefined}*/}
                        </>
                    ) : freeCreditCard ? (
                        <p>
                            {t("pc_pc_ptt_apple_pay_without_fee", {
                                paymentDate: paymentDeadlineDate,
                                paymentTime: paymentDeadlineTime
                            })}
                        </p>
                    ) : (
                        <p>
                            {t("pc_pc_ptt_apple_pay", {
                                paymentDate: paymentDeadlineDate,
                                paymentTime: paymentDeadlineTime
                            })}
                        </p>
                    )}
                </div>

                <div
                    style={{
                        marginTop: 30,
                        marginBottom: 30
                    }}
                    className="h-divider"
                />

                {children}
            </div>
        </InfoBox>
    );
}

export default ApplePayBlock;