import React, {
    ReactElement,
    useCallback,
    useEffect,
    useMemo,
    useReducer,
    useState
} from "react";
import {Trans, useTranslation} from "react-i18next";
import _ from "lodash";
import {connect, ConnectedProps} from "react-redux";
import styles from "./UnsignedAgreement.module.scss";
import MainContainer from "../../../components/base/MainContainer";
import {useAppSelector} from "redux/hooks";
import {RootState} from "redux/store/store.init";
import {
    initialState,
    reducer,
    RequestType
} from "../../MyCompany/Agreements/Agreement/Agreement";
import * as companyDocumentsAPI from "../../../api/companyDocumentsAPI";
import i18n from "../../../i18n";
import {useLocale} from "components/utils/withLocalesAndCurrencies";
import baseUrl from "../../../api/constants/baseURL";
import UserButton, {
    UserButtonVariant
} from "../../../components/base/UserButton/UserButton";
import Browse from "../../../components/base/Browse";
import {ReduxActionRedirectType} from "proptypes/PropTypeObjects";
import {
    redirectComplete,
    redirectMounted
} from "redux/actions/actionRedirect.actions";
import downloadFile from "../../../utils/downloadFile";
import {ReactComponent as DeleteIcon} from "../../../assets/icons/delete.svg";
import Spinner from "../../../components/base/Loaders/Spinner";
import {Navigate, useNavigate} from "react-router-dom";
import {globalAxiosController} from "api/axios/globalAxiosController";
import cx from "classnames";

type UnsignedAgreementProps = ConnectedProps<typeof connector>;

type PayloadType = {
  agreement: number;
  salesManager: string;
  salesManagerEmail: string;
};

const UnsignedAgreement = ({
    redirectComplete,
    redirectMounted
}: UnsignedAgreementProps): ReactElement => {
    const {t} = useTranslation();
    const locale = useLocale();

    const userAdmin = useAppSelector(
        (state) => state.auth.userData?.companyUser.admin || false
    );
    const redirectType = useAppSelector(
        (state: RootState) => state.actionRedirect.actionRedirect
    );
    const payload = useAppSelector(
        (state: RootState) =>
      state.actionRedirect.actionRedirectPayload as PayloadType
    );

    const bookingCount = useAppSelector(
        (state: RootState) => state.auth?.userData?.bookings || 0
    );
    const [state, dispatch] = useReducer(reducer, initialState);
    const [loading, setLoading] = useState<boolean>(false);
    const [selectedFiles, setSelectedFiles] = useState<File[]>([]);
    const navigate = useNavigate();

    useEffect(() => {
        redirectMounted();
    }, [redirectMounted]);

    const getCompanyDocument = useCallback(() => {
        if (!userAdmin) {
            return;
        }

        dispatch({
            type: "SET_REQUEST",
            payload: RequestType.DOCUMENT
        });

        if (payload?.agreement) {
            globalAxiosController
                .addRequest(
                    companyDocumentsAPI.getCompanyDocumentById(payload.agreement, locale)
                )
                .then((data) => {
                    dispatch({
                        type: "GET_DOCUMENT_SUCCESS",
                        payload: data
                    });
                })
                .catch((error: unknown) => {
                    dispatch({
                        type: "SET_ERRORS",
                        errors: [String(error) || i18n.t("mc_a_a_something_went_wrong")]
                    });
                });
        }
    }, [userAdmin, payload.agreement, locale]);

    const signLaterCallback = useCallback(() => {
        if (!redirectComplete) {
            return;
        }

        redirectComplete();

        navigate("/");
    // if (bookingCount < 5) {
    //     router.navigate("/hotels/booking");
    // } else {
    //     router.navigate("/");
    // }
    }, [redirectComplete]);

    const signCallback = useCallback(() => {
        if (_.isEmpty(selectedFiles)) {
            return;
        }

        const formData = new FormData();
        selectedFiles.forEach((file: File) => {
            //let fileBlob = new Blob([file], { type: file.type });
            formData.append("file", file, file.name);
        });
        dispatch({
            type: "SET_REQUEST",
            payload: RequestType.DOCUMENT
        });
        setLoading(true);

        globalAxiosController
            .addRequest(companyDocumentsAPI.uploadFiles(payload.agreement, formData))
            .then((data) => {
                if (data.success) {
                    navigate(`/my-company/agreement/${payload.agreement}`);
                } else {
                    dispatch({
                        type: "SET_ERRORS",
                        errors: data.errors || [t("mc_a_a_something_went_wrong")]
                    });
                }
            })
            .catch((error: unknown) => {
                dispatch({
                    type: "SET_ERRORS",
                    errors: [String(error) || t("mc_a_a_something_went_wrong")]
                });
            });
    }, [getCompanyDocument, payload.agreement, selectedFiles, t, userAdmin]);

    useEffect(() => getCompanyDocument(), [getCompanyDocument, payload]);

    const sidebar = useMemo(() => {
        if (!payload || !state.data || !userAdmin) {
            return undefined;
        }

        return (
            <div className={styles.Sidebar}>
                <Trans
                    values={{
                        signee: state.data.credentials.signee,
                        signeePosition: state.data.credentials.signeePosition
                    }}
                    i18nKey="a_r_unsigned_agreement_sidebar"
                >
                    To sign this agreement, you must:
                    <br />
                    1. Print the{" "}
                    <a
                        rel="noopener noreferrer"
                        target="_blank"
                        href={`${baseUrl}/company/document/file/${state.data.document.agreement.id}`}
                        download={state.data.document.agreement.fileName}
                    >
                        agreement
                    </a>
                    <br />
                    2. Get it signed by your company representative{" "}
                    {state.data.credentials.signee},{" "}
                    {state.data.credentials.signeePosition} provided in the agreement.
                    <br />
                    3. Scan the signed document (all pages)
                    <br />
                    4. Upload the scanned file(s) below
                    <br />
                    Please note that the uploaded files must contain a valid signature by{" "}
                    {state.data.credentials.signee},{" "}
                    {state.data.credentials.signeePosition}.
                </Trans>
            </div>
        );
    }, [payload, state.data, userAdmin]);

    if (
        !redirectType ||
    ![
        ReduxActionRedirectType.UNSIGNED_AGREEMENT,
        ReduxActionRedirectType.UNSIGNED_AGREEMENT_AFTER_BOOK
    ].includes(redirectType)
    ) {
        return <Navigate to="/" />;
    }

    if (!payload || (userAdmin && !state.data) || loading) {
        return (
            <Spinner
                size="50px"
                style={{
                    width: "100%",
                    height: "calc( 100vh - 60px)"
                }}
            />
        );
    }

    return (
        <MainContainer
            containerClassName={cx(styles.Root, !userAdmin && styles.NonAdmin)}
            sidebar={sidebar}
        >
            <div className={styles.Content}>
                <h1>{t("a_r_unsigned_agreement_title")}</h1>

                {redirectType === ReduxActionRedirectType.UNSIGNED_AGREEMENT && (
                    <div
                        className={styles.Explanation}
                        dangerouslySetInnerHTML={{
                            __html: t("a_r_unsigned_agreement_explanation", {
                                salesManagerEmail: payload?.salesManagerEmail
                            })
                        }}
                    />
                )}

                {redirectType ===
          ReduxActionRedirectType.UNSIGNED_AGREEMENT_AFTER_BOOK && (
          <div
                        className={styles.Explanation}
                        dangerouslySetInnerHTML={{
                            __html: t("a_r_unsigned_agreement_explanation_after_book", {
                                salesManagerEmail: payload?.salesManagerEmail,
                                bookingCount
                            })
                        }}
                    />
                )}

                {!_.isEmpty(selectedFiles) && userAdmin && (
                    <div className={styles.UploadedFiles}>
                        <h4>{t("a_r_unsigned_agreement_submitted_files")}</h4>

                        <ol>
                            {selectedFiles.map((file, index) => (
                                <li>
                                    <a onClick={() => downloadFile(file)}>{file.name}</a>

                                    <DeleteIcon
                                        onClick={() =>
                                            setSelectedFiles(
                                                selectedFiles.filter((_, i) => i !== index)
                                            )}
                                    />
                                </li>
                            ))}
                        </ol>
                    </div>
                )}

                <Browse
                    disabled={!userAdmin}
                    className={styles.BrowseFile}
                    onFilesSelected={(newFiles: File[]) => {
                        setSelectedFiles(selectedFiles.concat(...newFiles));
                    }}
                    buttonVariant={
                        selectedFiles.length > 0
                            ? UserButtonVariant.SECONDARY
                            : UserButtonVariant.PRIMARY
                    }
                />

                <div className={styles.Actions}>
                    <UserButton
                        text={t("a_r_unsigned_agreement_sign_later")}
                        variant={UserButtonVariant.SECONDARY}
                        buttonProps={{
                            onClick: signLaterCallback
                        }}
                    />

                    <UserButton
                        text={t("a_r_unsigned_agreement_sign")}
                        variant={UserButtonVariant.PRIMARY}
                        disabledButton={_.isEmpty(selectedFiles) || !userAdmin}
                        buttonProps={{
                            onClick: signCallback
                        }}
                    />
                </div>
            </div>
        </MainContainer>
    );
};

const connector = connect(undefined, {
    redirectComplete,
    redirectMounted
});

export default connector(UnsignedAgreement);
