import React, {ReactElement, ReactNode, useState} from "react";
import cx from "classnames";
import styles from "./OpenableBox.module.scss";

type Props = {
    title: string,
    children: ReactNode,
    statusFlag?: string,
    withBorder?: boolean,
    className?: string
};

function OpenableBox({
    title = "",
    children,
    statusFlag = "",
    withBorder = false,
    className
}: Props): ReactElement {
    const [isOpen, handleOpen] = useState(false);

    if (!children || !React.Children.count(children)) {
        return <></>;
    }

    return (
        <div className={cx(styles.Root, withBorder && styles.WithBorder, className)}>
            <div
                onClick={() => handleOpen(!isOpen)}
                className={cx(
                    styles.Heading,
                    isOpen ? styles.ArrowUp : styles.ArrowDown
                )}
            >
                {title}
                {statusFlag && <>&nbsp;-<span>&nbsp;{statusFlag}</span></>}
            </div>
            {isOpen && (
                <div className={styles.Content}>
                    {children}
                </div>
            )}
        </div>
    );
}

export default OpenableBox;
