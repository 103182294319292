import React, {ReactElement} from "react";
import cx from "classnames";
import styles from "./SelectableCard.module.scss";
import {getPriceWithCurrencySymbol} from "@hotelston_web_frontend_utils/currency/getPriceWithCurrency";
import Currency from "@hotelston_web_frontend_parent/constants/currency";

type Props = {
    onClick?: () => void;
    active?: boolean;
    image: JSX.Element;
    fee: number;
    currency: Currency;
    className?: string;
};

function SelectableCard({
    active,
    image,
    fee,
    currency,
    className,
    ...rest
}: Props): ReactElement {
    return (
        <div {...rest} className={cx(styles.Root, active && styles.Active, className)}>
            <span className={styles.Image}>{image}</span>
            <span className={styles.fee}>+ {getPriceWithCurrencySymbol(fee, currency)}</span>
        </div>
    );
}

export default SelectableCard;
