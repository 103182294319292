import React, {useMemo, useRef} from "react";
import {withNotifications} from "components/utils/withNotifications";
import {Link} from "react-router-dom";
import {ReactComponent as BellIcon} from "assets/icons/bell.svg";
import cx from "classnames";
import {useTranslation} from "react-i18next";
import Truncate from "components/base/Truncate";
import DynamicTime from "components/common/DynamicTime";
import styles from "./NotificationsDropdown.module.scss";
import {Notifications} from "proptypes/PropTypeObjects"
import {LocaleDateFormatTypeEnum} from "components/utils/Internationalization/useLocaleDateTimeFormat";
import {useAppSelector} from "redux/hooks";
import useClickOutside from "utils/hooks/useClickOutside";

type Props = {
    setAsRead(id: string): void;
    setAsReadAll(): void;
    notificationsData: Notifications;
    open: boolean;
    setOpen: (value: boolean) => void;
};
function NotificationsDropdown({
    setAsRead,
    notificationsData,
    setAsReadAll,
    open,
    setOpen
}: Props) {
    const containerRef = useRef<HTMLDivElement>(null);

    const notificationsSortedAndSliced = useMemo(() => (notificationsData
        ? notificationsData.notifications
            .sort((a, b) => b.timestamp - a.timestamp)
            .slice(0, 8)
        : []), [notificationsData]);

    const newNotificationCount = notificationsData ? notificationsData.unread : 0;

    const {t} = useTranslation();

    useClickOutside(containerRef, () => {
        setOpen(false);
    });

    return (
        <div ref={containerRef} className={cx(styles.Root, open && styles.Open, newNotificationCount && styles.WithNotifications)}>
            <div
                className={cx(styles.AlarmPane, newNotificationCount && styles.HasMessage)}
                onClick={() => setOpen(!open)}
            >
                <BellIcon />
            </div>

            {open && (
                <div className={styles.Container}>
                    <div className={styles.header}>
                        {t("nd_nd_new_notifications")}
                        <span className={styles.newNotificationCount}>
                            {newNotificationCount}
                        </span>
                    </div>
                    <ul className={styles.List}>
                        {notificationsSortedAndSliced.map(({
                            id, title, body, link, status, timestamp
                        }, i) => (link ? (
                            <Link
                                key={`${id}-${i}`}
                                onClick={() => {
                                    if (status === "UNREAD") {
                                        setAsRead(id);
                                    }
                                    setOpen(false);
                                }}
                                to={link}
                            >
                                <li
                                    key={`${id}-${i}`}
                                    className={cx(styles.Item, status === "UNREAD" &&
                                        styles.greenItem)}
                                >
                                    <div className={styles.timestamp}>
                                        <DynamicTime
                                            time={timestamp}
                                            outputFormatType={LocaleDateFormatTypeEnum.DATE_TIME}
                                        />
                                    </div>
                                    <p className={styles.itemHeader}>
                                        {title}
                                    </p>
                                    <Truncate lineHeight="16px" lines={3} ellipsis="...">
                                        <p className={styles.itemBody}>
                                            {body}
                                        </p>
                                    </Truncate>
                                </li>
                            </Link>
                        ) : (
                            <li
                                key={id}
                                onClick={() => {
                                    if (status === "UNREAD") {
                                        setAsRead(id);
                                    }
                                    setOpen(false);
                                }}
                                className={cx(styles.item, status === "UNREAD" &&
                                    styles.greenItem)}
                            >
                                <div className={styles.timestamp}>
                                    <DynamicTime
                                        time={timestamp}
                                        outputFormatType={LocaleDateFormatTypeEnum.DATE_TIME}
                                    />
                                </div>
                                <p className={styles.itemHeader}>
                                    {title}
                                </p>
                                <Truncate lineHeight="16px" lines={3} ellipsis="...">
                                    <p className={styles.itemBody}>
                                        {body}
                                    </p>
                                </Truncate>
                            </li>
                        )))}
                    </ul>
                    <div className={styles.footer}>
                        <Link to="/notifications">
                            <span className={styles.action}>{t("nd_nd_see_all")}</span>
                        </Link>
                        <span onClick={setAsReadAll} className={styles.action}>
                            {t("nd_nd_mark_as_read")}
                        </span>
                    </div>
                </div>
            )}
        </div>
    );
}

export default withNotifications(NotificationsDropdown);
