enum MapTypes {
    RESET_MAP = "map/RESET_MAP",

    MAP_CAN_BE_LOADED = "map/MAP_CAN_BE_LOADED",
    TOGGLE_MAP_FILTER_BOX = "map/TOGGLE_MAP_FILTER_BOX",
    TOGGLE_MAP_WIDTH = "map/TOGGLE_MAP_WIDTH",
    CHANGE_DRAWING_MODE = "map/CHANGE_DRAWING_MODE",
    CHANGE_MAP_ZOOM = "map/CHANGE_MAP_ZOOM",
    CHANGE_MAP_CENTER = "map/CHANGE_MAP_CENTER",

    TOGGLE_MOBILE_DRAWING = "map/TOGGLE_MOBILE_DRAWING",

    CHANGE_PRECALCULATED_ZOOM = "map/CHANGE_PRECALCULATED_ZOOM",
    CHANGE_PRECALCULATED_CENTER = "map/CHANGE_PRECALCULATED_CENTER",
    CHANGE_MAP_TYPE_ID = "map/CHANGE_MAP_TYPE_ID",

    SET_FIGURE = "map/SET_FIGURE",

    SET_PLACES = "map/SET_PLACES"
}

export default MapTypes;
