import React, {ReactElement} from "react";
import {useTranslation} from "react-i18next";
import Modal from "../../base/Modal/Modal";
import styles from "./RedirectingModal.module.scss";
import Spinner from "../../base/Loaders/Spinner";

function RedirectingModal(): ReactElement {
    const {t} = useTranslation();

    return (
        <Modal>
            <div className={styles.Root}>
                <h2 className={styles.Title}>
                    {t("mod_rm_redirecting_to_paysera")}...
                </h2>

                <div className={styles.SpinnerContainer}>
                    <Spinner
                        size={50}
                    />
                </div>
            </div>
        </Modal>
    );
}

export default RedirectingModal;
