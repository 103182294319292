import React, {ReactElement, useCallback, useMemo, useState} from "react"
import {useTranslation} from "react-i18next";
import cx from "classnames";
import {ReactComponent as DelIcon} from "assets/icons/delete.svg";
import {useDeleteUserAction} from "components/utils/useCompanyUsers";
import ConfirmationModal from "components/common/ConfirmationModal";
import SuccessModal from "components/common/ConfirmationModal/SuccessModal";
import styles from "./DeleteUser.module.scss";

type Props = {
    className: string;
    deleteUser: (id: number) => void;
    userId: number;
};

function DeleteUser({
    className,
    deleteUser,
    userId
}: Props): ReactElement {
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [successModal, setSuccessModal] = useState(false);

    const onSuccess = useCallback(
        (userId: number) => {
            deleteUser(userId);
            setModalIsOpen(false);
            setSuccessModal(true);
        },
        [deleteUser]
    );

    const {
        requesting,
        errors,
        deleteUser: deleteUserAction,
        clearState
    } = useDeleteUserAction(onSuccess);

    const {t} = useTranslation();

    const title = useMemo(() => requesting ? t("mc_edu_du_deleting_user") : t("mc_edu_du_delete_user"), [t, requesting]);

    return (
        <div className={cx(styles.root, className)}>
            {modalIsOpen && (
                <ConfirmationModal
                    onConfirm={() => deleteUserAction(userId)}
                    onDecline={() => setModalIsOpen(false)}
                    config={{
                        variants: {
                            type: "error"
                        },
                        title: title,
                        content: (
                            <span dangerouslySetInnerHTML={{__html: t("mc_edu_du_confimation_question_delete_user")}} />
                        ),
                        errors,
                        spinner: requesting
                    }}
                />
            )}
            {successModal && (
                <SuccessModal
                    onClose={() => setSuccessModal(false)}
                    successMessage={t("mc_edu_du_success_message_delete_user")}
                />
            )}
            <DelIcon onClick={(evt) => {
                evt.stopPropagation();
                evt.preventDefault();

                clearState();
                setModalIsOpen(true);
            }}
            />
        </div>
    );
}

export default DeleteUser;
