import React, {ReactElement} from "react";
import {ReactComponent as PendingPaymentIcon} from "assets/icons/payment pending.svg";
import UserButton from "components/base/UserButton";
import {Trans, useTranslation} from "react-i18next";
import styles from "./PaymentPending.module.scss";
import {UserButtonVariant} from "../../base/UserButton/UserButton";

type Props = {
    paymentDeadlineDate: string;
    paymentDeadlineTime: string;
    autoCancellationDate: string;
    autoCancellationTime: string;
    daysLeft: number;
    onPayNow: () => void;
    createDate: number;
    paymentDeadline: number;
};

function PaymentPending({
    paymentDeadlineDate,
    paymentDeadlineTime,
    autoCancellationDate,
    autoCancellationTime,
    daysLeft,
    onPayNow,
    createDate,
    paymentDeadline
}: Props): ReactElement {
    const {t} = useTranslation();

    return (
        <div className={styles.Root}>
            <h3 className={styles.Title}>{t("bs_pp_payment_pending")}</h3>

            <div className={styles.Box}>
                <div className={styles.Wrapper}>
                    <div className={styles.Icon}>
                        <PendingPaymentIcon />
                    </div>

                    {createDate > paymentDeadline && (
                        <div className={styles.Content}>
                            <div className={styles.Heading}>
                                {t("bs_pp_pay_now")}
                            </div>

                            <p>
                                {t("bs_pp_pay_after_cxl", {phone: t("contact_phone_emergency")})}
                            </p>
                        </div>
                    )}

                    {createDate <= paymentDeadline && (
                        <div className={styles.Content}>
                            <div className={styles.Heading}>
                                {daysLeft < 0 ? t("bs_pp_day_late", {count: Math.abs(daysLeft)}) : t("bs_pp_day_left", {count: daysLeft})}
                            </div>

                            {/*<p>*/}
                            {/*    {t("bs_pp_pay_before_notice", {*/}
                            {/*        defaultValue: "Pay before {{date}} {{time}}.",*/}
                            {/*        date: paymentDeadlineDate,*/}
                            {/*        time: paymentDeadlineTime*/}
                            {/*    })}*/}
                            {/*</p>*/}

                            <div>
                                <Trans
                                    i18nKey="bs_pp_pay_before_notice_with_cancellation"
                                    values={{
                                        date: paymentDeadlineDate,
                                        time: paymentDeadlineTime,
                                        autoCancelDate: autoCancellationDate,
                                        autoCancelTime: autoCancellationTime
                                    }}
                                    components={{warning: <p style={{marginTop: "10px"}} />, red: <span className={styles.Warning} />}}
                                >
                                    Pay before {paymentDeadlineDate} {paymentDeadlineTime}.

                                    <p style={{marginTop: "10px"}}>
                                        <span className={styles.Warning}>Warning:</span> without full payment the booking is going to be cancelled at {autoCancellationDate} {autoCancellationTime}
                                    </p>
                                </Trans>
                            </div>
                        </div>
                    )}
                </div>

                <UserButton
                    text={t("bs_pp_pay_now_button")}
                    variant={UserButtonVariant.PRIMARY}
                    buttonProps={{
                        type: "button",
                        onClick: onPayNow,
                        style: {
                            height: "50px"
                        }
                    }}
                />
            </div>
        </div>
    );
}

export default PaymentPending;
