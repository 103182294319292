import {useEffect, useState} from "react";
import {useAppSelector} from "../../../redux/hooks";
import {
    FALLBACK_DATE_FORMAT,
    FALLBACK_DATE_TIME_FORMAT,
    FALLBACK_TIME_FORMAT,
    LOCALE_DATE_FORMAT,
    LOCALE_DATE_TIME_FORMAT,
    LOCALE_TIME_FORMAT
} from "./formats";

export enum LocaleDateFormatTypeEnum {
    DATE,
    TIME,
    DATE_TIME
}

function useLocaleDateTimeFormat(type: LocaleDateFormatTypeEnum): string {
    let formats: Map<string, string>;
    let fallbackFormat: string;
    switch (type) {
    case LocaleDateFormatTypeEnum.DATE:
        formats = LOCALE_DATE_FORMAT;
        fallbackFormat = FALLBACK_DATE_FORMAT;
        break;
    case LocaleDateFormatTypeEnum.TIME:
        formats = LOCALE_TIME_FORMAT;
        fallbackFormat = FALLBACK_TIME_FORMAT;
        break;
    case LocaleDateFormatTypeEnum.DATE_TIME:
        formats = LOCALE_DATE_TIME_FORMAT;
        fallbackFormat = FALLBACK_DATE_TIME_FORMAT;
        break;
    default:
        formats = LOCALE_DATE_FORMAT;
        fallbackFormat = FALLBACK_DATE_FORMAT;
        break;
    }

    const locale = useAppSelector((state) => state.locale.currentLocale);
    const [currentFormat, setCurrentFormat] = useState(formats.get(locale));

    useEffect(() => {
        const newFormat = formats.get(locale);
        if (newFormat && newFormat !== currentFormat) {
            setCurrentFormat(newFormat);
        }
    }, [currentFormat, formats, locale]);

    return currentFormat || fallbackFormat;
}

export default useLocaleDateTimeFormat;