import React, {ReactElement} from "react";
import {useLocation} from "react-router-dom";
import {connect} from "react-redux";
import {RootState} from "../../../../redux/store/store.init";
import MyCompanyBreadcrumbs from "./MyCompany/MyCompanyBreadcrumbs";
import withRouteInfo, {WithRouteInfo} from "../../../utils/withRouteInfo";
import MyBookingsBreadcrumbs from "./MyBookings/MyBookingsBreadcrumbs";
import ContactsBreadcrumbs from "./Contacts/ContactsBreadcrumbs";
import HotelBreadcrumbs from "./Hotels/HotelBreadcrumbs";
import TransfersBreadcrumbs from "./Transfers/TransfersBreadcrumbs";

export type HeaderBackLinkProps = WithRouteInfo;

const BreadcrumbsContainer = ({
    lastLocation
}: HeaderBackLinkProps): ReactElement | null => {
    const location = useLocation();

    if (!lastLocation) {
        return (
            <></>
        );
    }

    return (
        <>
            <HotelBreadcrumbs location={location} lastLocation={lastLocation}/>
            <TransfersBreadcrumbs location={location} lastLocation={lastLocation}/>
            <MyBookingsBreadcrumbs location={location} lastLocation={lastLocation}/>
            <MyCompanyBreadcrumbs location={location} lastLocation={lastLocation}/>
            <ContactsBreadcrumbs location={location} lastLocation={lastLocation}/>
        </>
    );
};

const mapStateToProps = (state: RootState) => ({
    expandedView: state.auth.expandedView
});

export default withRouteInfo(connect(mapStateToProps, undefined)(BreadcrumbsContainer));
