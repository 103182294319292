import React, {useEffect, useMemo} from "react"
import animatedLogo from "assets/images/logoAnimated.gif";
import {ReactComponent as HotelstonLogo} from "assets/images/logoHotel.svg";
import {connect} from "react-redux";
import * as searchActions from "redux/actions/hotelSearch.actions";

import {useTranslation} from "react-i18next";
import {changeCurrentDestination} from "redux/actions/hotelSearch.actions";
import styles from "./LoadingScreen.module.scss";
import {
    DestinationDestinationPropTypes,
    HotelDestinationPropTypes,
    RecentDestinationPropTypes
} from "proptypes/PropTypeObjects";
import {getDestination} from "api/destinationsAPI";
import {useAppDispatch} from "redux/hooks";
import {RootState} from "redux/store/store.init";
import {globalAxiosController} from "api/axios/globalAxiosController";
import BlackFridayScreen from "views/Hotels/Searching/LoadingScreen/CustomLoadingScreen/BlackFridayScreen/BlackFridayScreen"
import DefaultScreen from "views/Hotels/Searching/LoadingScreen/Default/DefaultScreen"

type LoadingScreenProps = {
  currentDestination?:
    | DestinationDestinationPropTypes
    | RecentDestinationPropTypes;
  destinationInput: string;

  cityId: number;
  currentLocale: string;
};

function LoadingScreen({
    currentDestination,
    destinationInput,
    cityId,
    currentLocale
}: LoadingScreenProps) {
    const {t} = useTranslation();
    const dispatch = useAppDispatch();

    useEffect(() => {
        if (!currentDestination && cityId !== 0) {
            void globalAxiosController
                .addRequest(getDestination(cityId, currentLocale))
                .then((result: HotelDestinationPropTypes) => {
                    dispatch(changeCurrentDestination(result));
                });
        }
    }, [cityId, currentDestination, currentLocale, dispatch]);

    const phrase = useMemo(() => {
        const currentDestinationStr =
        (currentDestination as DestinationDestinationPropTypes)?.name ||
        currentDestination?.cityName ||
        destinationInput;

        return t("h_s_ls_searching_page_notice", {
            destinationName: currentDestinationStr
        });
    }, [currentDestination, destinationInput, t]);

    return (
        <div className={styles.loadingScreen}>
            {/*<BlackFridayScreen phrase={phrase} />*/}
            <DefaultScreen phrase={phrase} />

            {/*<div className={styles.screenBox}>*/}
            {/*    <div className={styles.centeredBox}>*/}
            {/*        <p className={styles.notice}>*/}
            {/*            /!*<img loading="eager" src={animatedLogo} alt="animated logo" />*!/*/}

            {/*        </p>*/}
            {/*        <div className={cx(styles.notice, styles.blackFriday)}>*/}
            {/*            <HotelstonLogo />*/}
            {/*            <h4>BLACK FRIDAY</h4>*/}

            {/*            <div className={styles.discount} style={{}}>*/}
            {/*                UP TO 30% DISCOUNTS*/}
            {/*            </div>*/}
            {/*            <div className={styles.message}>*/}
            {/*                Inspire Your Clients for a New Adventure!*/}
            {/*            </div>*/}
            {/*        </div>*/}
            {/*    </div>*/}
            {/*</div>*/}
        </div>
    );
}

export default connect(
    (state: RootState) => ({
        currentDestination: state.hotelSearch.currentDestination,
        destinationInput: state.hotelSearch.destinationInput,

        cityId: state.hotelSearch.formData.cityId || 0,
        currentLocale: state.locale.currentLocale
    }),
    {
        ...searchActions
    }
)(LoadingScreen);
