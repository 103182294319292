import React, {ReactElement, useState} from "react";
import CustomCheckBox from "components/base/Input/CustomCheckBox";
import {useTranslation, Trans} from "react-i18next";
import styles from "./ConfirmOrder.module.scss";
import AgreeTermsAndConditionsModal from "../AgreeTermsModal/AgreeTermsAndConditions/AgreeTermsAndConditions";

type Props = {
    data: {
        errors: string[] | undefined;
        agreeTerms: boolean | undefined;
        allowToBook: boolean;
        allowSplitPayment?: boolean;
        agency: boolean;
        defaultPhone: string;
        defaultEmail: string;
        sanctionedCompany?: boolean;
    }
    toggleAgreeTerms: () => void;
};

function ConfirmOrder({
    data,
    toggleAgreeTerms
}: Props): ReactElement {
    const {
        agreeTerms,
        allowToBook,
        allowSplitPayment,
        agency,
        defaultPhone,
        defaultEmail,
        sanctionedCompany
    } = data;
    const {t} = useTranslation();
    const [termsAndConditionsVisible, setTermsAndConditionsVisible] = useState<boolean>(false);

    return (
        <div className={styles.root}>
            {termsAndConditionsVisible && (
                <AgreeTermsAndConditionsModal onClose={() => setTermsAndConditionsVisible(false)} />
            )}

            <div>
                <div className={styles.terms}>
                    <div className={styles.Heading}>
                        {t("co_co_terms_and_conditions")}
                    </div>
                    <div className={styles.agree}>
                        <CustomCheckBox
                            label={(
                                <Trans i18nKey="co_co_agree_to_terms_and_conditions">
                                    I agree with <a
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            e.preventDefault();

                                            setTermsAndConditionsVisible(true);
                                        }}
                                    >
                                        terms & conditions
                                    </a>
                                </Trans>
                            )}
                            errorMessage={t("util_vf_error_must_agree_to_terms")}
                            inputProps={{
                                checked: !!agreeTerms,
                                onChange: (e) => {
                                    toggleAgreeTerms();
                                },
                                required: true
                            }}
                        />
                    </div>

                    {!allowToBook && !allowSplitPayment && agency && (
                        <div className={styles.comment}>
                            {t("pc_pc_b_agencyContractNote", {
                                phone: defaultPhone,
                                email: defaultEmail
                            })}
                        </div>
                    )}

                    {sanctionedCompany && (
                        <div className={styles.comment}>
                            {t("pc_pc_b_agencySanctionedCountry")}
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
}
export default ConfirmOrder;
