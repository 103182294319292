import _ from "lodash";
import React, {ReactElement, useCallback, useMemo, useState} from "react"
import {useTranslation} from "react-i18next";
import {ReactComponent as TripAdvisorImage} from "assets/images/tripadvisor/Ollie_LogoSmall.svg";
import {ReactComponent as TripAdvisorBubbleEmpty} from "assets/images/tripadvisor/TripAdvisorBubble_Empty.svg";
import {ReactComponent as TripAdvisorBubbleHalf} from "assets/images/tripadvisor/TripAdvisorBubble_Half.svg";
import {ReactComponent as TripAdvisorBubbleFull} from "assets/images/tripadvisor/TripAdvisorBubble_Full.svg";
import cx from "classnames";
import Tippy from "../Tippy/Tippy";
import {HotelCustomerRating, TripAdvisorRating} from "proptypes/PropTypeObjects";
import styles from "./HotelCustomerReviews.module.scss";
import {ReactComponent as BookingComLogo} from "assets/icons/bookingcom.svg";
import {ReactComponent as ExpediaLogo} from "assets/icons/expedia.svg";
import {getNumberWithDecimalPoint} from "@hotelston_web_frontend_utils/currency/getPriceWithCurrency";
import useMobileSwitch from "@hotelston_web_frontend_components/hooks/detection/useMobileSwitch"
import UserModal from "components/base/UserModal/UserModal"

type Props = {
    customerRatings: HotelCustomerRating[];
    tripAdvisorRating?: TripAdvisorRating;
    reviewRating: number;
    className?: string;
    hotelName: string;
};

function HotelCustomerReviews({
    customerRatings = [],
    tripAdvisorRating,
    reviewRating,
    hotelName,
    className
}: Props): ReactElement {
    const {t} = useTranslation();
    const {isMobile} = useMobileSwitch();

    const [reviewRatingModal, setReviewRatingModal] = useState(false);

    const ratings = useMemo(() => {
        const ret = [...customerRatings];

        if (tripAdvisorRating && tripAdvisorRating.reviewsCount !== 0) {
            ret.push({
                provider: "TripAdvisor",
                customerCount: tripAdvisorRating.reviewsCount,
                location: tripAdvisorRating.location,
                room: tripAdvisorRating.room,
                staff: tripAdvisorRating.service,
                comfort: tripAdvisorRating.sleep,
                valueForMoney: tripAdvisorRating.value,
                cleanness: tripAdvisorRating.clean,
                overall: tripAdvisorRating.overall,
                rankingInLocation: tripAdvisorRating.rankingInLocation
            } as HotelCustomerRating);
        }

        return ret;
    }, [customerRatings, tripAdvisorRating]);

    const buildTripAdvisorRating = useCallback((value: number) => (
        [1, 2, 3, 4, 5].map((val) => (
            <span key={val}>
                {value < val - 0.5 && (
                    <TripAdvisorBubbleEmpty key={val} />
                )}

                {value >= val - 0.5 && value < val && (
                    <TripAdvisorBubbleHalf key={val} />
                )}

                {value >= val && (
                    <TripAdvisorBubbleFull key={val} />
                )}
            </span>
        ))
    ), []);

    // const buildTripAdvisorReviewRating = useCallback((value: number, max: number) => (
    //     <span className={styles.TripAdvisorRating}>
    //         <span
    //             className={styles.TripAdvisorRatingValue}
    //             style={{
    //                 width: `${(value / max) * 100}%`
    //             }}
    //         />
    //     </span>
    // ), []);

    const getProviderLogo = useCallback((provider: string) => {
        switch (provider) {
        case "Booking.com":
            return (<BookingComLogo />);
        case "Expedia":
            return (<ExpediaLogo />);
        case "TripAdvisor":
            return (<TripAdvisorImage />);
        default:
            return undefined;
        }
    }, []);

    const buildRow = useCallback((score: number | string | undefined, label: string, provider: string, logo?: boolean, withoutRatingMax?: boolean) => {
        if (score === undefined) {
            return undefined;
        }

        const scoreOutOf = !withoutRatingMax && provider === "TripAdvisor" ? "/5" : "/10";

        return (
            <div key={label} className={styles.TooltipItem}>
                {label.length !== 0 && <span className={styles.TooltipItemLabel}>{logo && getProviderLogo(provider)} {label}</span>}
                {score && <span className={styles.TooltipItemValue}>{_.isNumber(score) ? getNumberWithDecimalPoint(score, 1, true) : score}{logo ? (<span>{scoreOutOf}</span>) : undefined}</span>}
            </div>
        );
    }, [getProviderLogo]);

    const buildTooltip = useCallback((givenRatings: (HotelCustomerRating | undefined)[]) => (
        <div className={styles.TooltipContainer}>
            {[...givenRatings].reverse().map((r, i: number) => {
                if (!r) {
                    return undefined;
                }

                return (
                    <div key={r.provider} className={styles.TooltipItemContainer} style={{flexBasis: `${(100 / givenRatings.length) - 2}%`}}>
                        <div>
                            {buildRow(r.overall, t("hcr_overall", {provider: r.provider}), r.provider, true)}
                            {buildRow(r.room, t("hcr_room"), r.provider)}
                            {buildRow(r.facilities, t("hcr_facilities"), r.provider)}
                            {buildRow(r.amenities, t("hcr_amenities"), r.provider)}
                            {buildRow(r.cleanness, t("hcr_cleanness"), r.provider)}
                            {buildRow(r.food, t("hcr_food"), r.provider)}
                            {buildRow(r.staff, t("hcr_staff"), r.provider)}
                            {buildRow(r.checkIn, t("hcr_checkIn"), r.provider)}
                            {buildRow(r.valueForMoney, t("hcr_valueForMoney"), r.provider)}
                            {buildRow(r.comfort, t("hcr_comfort"), r.provider)}
                            {buildRow(r.location, t("hcr_location"), r.provider)}
                            {buildRow(r.condition, t("hcr_condition"), r.provider)}
                            {buildRow(r.quality, t("hcr_quality"), r.provider)}
                            {buildRow(r.amenities, t("hcr_neighborhood"), r.provider)}
                        </div>

                        <div className={styles.BottomAverages}>
                            {r.rankingInLocation && buildRow("", r.rankingInLocation || "", r.provider, false, true)}
                            {buildRow(r.recommendationPercent && r.recommendationPercent.toFixed(0) + "%", t("hcr_recommendationPercent"), r.provider, false, true)}
                            {buildRow(r.customerCount, t("hcr_customerCount"), r.provider, false, true)}
                        </div>
                    </div>
                );
            })}
        </div>
    ), [buildRow, t]);

    if (_.isEmpty(ratings)) {
        return <></>;
    }

    if (isMobile) {
        return (
            <div className={cx(styles.Root, styles.ReviewMobileContainer, className)} onClick={() => setReviewRatingModal(true)}>
                <span className={styles.Overall}>
                    {reviewRating && (<>{t("hcr_overall_rating")}: {reviewRating}<span>/10</span></>)}
                </span>

                {tripAdvisorRating && tripAdvisorRating.reviewsCount !== 0 && (
                    <div className={styles.TripAdvisorRating}>
                        <TripAdvisorImage className={styles.OllieLogo} />

                        {/*<span>{tripAdvisorRating.overall}/5</span>*/}

                        {buildTripAdvisorRating(tripAdvisorRating.overall)}
                    </div>
                )}

                {reviewRatingModal && (
                    <UserModal
                        header={(<h4>{hotelName}</h4>)}
                        onClose={() => setReviewRatingModal(false)}
                    >
                        {buildTooltip(ratings)}
                    </UserModal>
                )}
            </div>
        )
    }

    return (
        <div className={cx(styles.Root, className)}>
            <Tippy
                content={buildTooltip(ratings)}
            >
                <span className={styles.Overall}>
                    {reviewRating && (<>{t("hcr_overall_rating")}: {reviewRating}<span>/10</span></>)}
                </span>
            </Tippy>

            {/*{customerRatings.map((r) => (*/}
            {/*    <span key={r.provider}>*/}
            {/*        {r.provider === "Booking.com" && <img src={BookingComLogo} alt="booking.com" />}*/}
            {/*        {r.provider === "Expedia" && <img src={ExpediaLogo} alt="Expedia" />}*/}

            {/*        <span className={styles.Provider}>{r.provider}</span>*/}

            {/*        <span className={styles.Overall}>{r.overall.toFixed(1)}</span>*/}
            {/*        <span>/10</span>*/}
            {/*    </span>*/}
            {/*))}*/}

            {tripAdvisorRating && tripAdvisorRating.reviewsCount !== 0 && (
                <Tippy
                    content={buildTooltip([ratings.find((rating) => rating.provider === "TripAdvisor")])}
                >
                    <div className={styles.TripAdvisorRating}>
                        <TripAdvisorImage className={styles.OllieLogo} />

                        {/*<span>{tripAdvisorRating.overall}/5</span>*/}

                        {buildTripAdvisorRating(tripAdvisorRating.overall)}
                    </div>
                </Tippy>
            )}
        </div>
    );
}

export default HotelCustomerReviews;