import {PaymentTransferTypePropTypes} from "../proptypes/PropTypeObjects";

export default {
    before: {
        transfer: "TRANSFER" as PaymentTransferTypePropTypes,
        bankLink: "BANK_LINK" as PaymentTransferTypePropTypes,
        creditCard: "CREDIT_CARD" as PaymentTransferTypePropTypes,
        applePay: "APPLE_PAY" as PaymentTransferTypePropTypes,
        wallet: "HOTELSTON_WALLET" as PaymentTransferTypePropTypes,
        payLater: "PAY_LATER" as PaymentTransferTypePropTypes,
        creditLine: "CREDIT_LINE" as PaymentTransferTypePropTypes,
        mip: "MIP" as PaymentTransferTypePropTypes,
        paymentLink: PaymentTransferTypePropTypes.PAYMENT_LINK
    },

    after: {
        transfer: "TRANSFER_AFTER_CXL" as PaymentTransferTypePropTypes,
        bankLink: "BANK_LINK_AFTER_CXL" as PaymentTransferTypePropTypes,
        creditCard: "CREDIT_CARD_AFTER_CXL" as PaymentTransferTypePropTypes,
        applePay: "APPLE_PAY_AFTER_CXL" as PaymentTransferTypePropTypes,
        wallet: "HOTELSTON_WALLET_AFTER_CXL" as PaymentTransferTypePropTypes,
        payLater: "PAY_LATER_AFTER_CXL" as PaymentTransferTypePropTypes,
        creditLine: "CREDIT_LINE_AFTER_CXL" as PaymentTransferTypePropTypes,
        mip: "MIP_AFTER_CXL" as PaymentTransferTypePropTypes,
        paymentLink: PaymentTransferTypePropTypes.PAYMENT_LINK_AFTER_CXL
    }
};